import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Checkbox, Modal } from "antd";
import { Link } from "react-router-dom";
import logoImg from "../../assets/images/logo-img.png";
import playicon from "../../assets/images/login-play.svg";
import footerLogoImg from "../../assets/images/footer-logo.png";
import footerCompanyLogo1 from "../../assets/images/footerlogo1.png";
import footerCompanyLogo2 from "../../assets/images/footerlogo2.png";
import footerCompanyLogo3 from "../../assets/images/footerlogo3.png";
import footerCompanyLogo4 from "../../assets/images/footerlogo4.png";
import footerCompanyLogo5 from "../../assets/images/footerlogo5.png";
import OwlCarousel from "react-owl-carousel";
import { loginSubmit, setLoginToken } from "../../actions/login/login";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate, useLocation } from "react-router-dom";
import adminToolsService from "../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import { useCookies } from "react-cookie";

const Login = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = React.createRef();
  const search = useLocation().search;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [designerName, setDesignerName] = useState("");
  const [designerLogoData, setdesignerLogoData] = useState([]);
  const [couter, setCounter] = useState(0);
  const [cookies, setCookie] = useCookies(["userName"]);

  useEffect(() => {
    const username = new URLSearchParams(search).get("username");
    const IsImpersonate = new URLSearchParams(search).get("IsImpersonate");
    if (username && IsImpersonate == "1") {
      window.location.href = "dashboard";
    } else {
      handleGetDesignerData();
    }
    if (cookies.userName) {
      formRef.current.setFieldsValue({
        username: cookies.userName,
      });
    }
  }, []);
  useEffect(() => {
    setCounter(couter + 1);
  }, [designerLogoData]);

  const [resetPassword, setresetPassword] = useState({
    email: "",
  });
  const [validations, setValidations] = useState({
    emailVal: "",
  });
  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setresetPassword({
        ...resetPassword,
        [name]: e,
      });
    } else {
      setresetPassword({
        ...resetPassword,
        [e.target.name]: e.target.value,
      });
    }
    console.log(e, isSelect, name);
  };
  const handleValidation = () => {
    const validations = {};
    let isValid = true;

    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!resetPassword.email.trim()) {
      isValid = false;
      validations.emailVal = "Email is compulsory";
      // return false;
    } else {
      if (!resetPassword.email.match(validRegex)) {
        isValid = false;
        validations.emailVal = "Please Enter valid EmailId";
      }
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };
  const handleResetPassword = () => {
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        userEmail: resetPassword.email,
        websiteLink: window.location.origin + "/resetPassword",
      };
      adminToolsService
        .ForgotPasswordGeneration(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var errorMsg = response.data.responseData.errorMsg;
          if (message == "Success") {
            NotificationManager.success("Reset Password Succeeded");
          } else {
            NotificationManager.error(errorMsg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    console.log(resetPassword);
    handleCancel2();
  };

  const onFinish = (values) => {
    let inputData = {
      userName: values.username || null,
      userEmail: values.useremail || null,
      password: values.password || null,
    };
    setLoading(true);
    dispatch(loginSubmit(inputData))
      .then((response) => {
        setLoading(false);
        if (response.message == "True") {
          dispatch(setLoginToken(response));
          if (values.remember) {
            setCookie("userName", values.username);
          }
          navigate("administrationTools");
        } else {
          NotificationManager.error("Please Check UserName and Password.");
        }
      })
      .catch((e) => {
        NotificationManager.error(e);
        setLoading(false);
        console.log(e);
      });
  };
  const onFinishFailed = (errorInfo) => {};

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal2 = () => {
    setIsModalVisible2(true);
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
    setresetPassword({
      email: "",
    });
    setValidations({
      emailVal: "",
    });
  };
  const handleDealerLogin = (userName, password) => {
    navigate("dashboard");
    // let inputData = {
    //   userName: userName || null,
    //   userEmail: null,
    //   password: password || null,
    // };
    // setLoading(true);
    // dispatch(loginSubmit(inputData))
    //   .then((response) => {
    //     setLoading(false);
    //     console.log(inputData, "------request");
    //     console.log(response, "---------------login");
    //     if (response.message == "True") {
    //       dispatch(setLoginToken(response));
    //       navigate("administrationTools");
    //     } else {
    //       NotificationManager.error("Please Check UserName and Password.");
    //       navigate("/");
    //     }
    //   })
    //   .catch((e) => {
    //     setLoading(false);
    //     console.log(e);
    //   });
  };

  const options = {
    margin: 20,
    loop: true,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 3,
      },
      600: {
        items: 4,
      },
      1000: {
        items: 8,
      },
    },
  };

  const handleGetDesignerData = () => {
    try {
      adminToolsService
        .GetDesignerData()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setDesignerName(responseData.designername);
            setdesignerLogoData(responseData.designerLogoData);
          } else {
            setDesignerName("");
            setdesignerLogoData([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="login__section container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="login__header">
            <div className="logo__section">
              <img src={logoImg} />
            </div>

            <div className="right__text">
              <p>Social Product Network for the Jewelry Industry</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="left__section">
            <img src={playicon} onClick={showModal} className="circle-ripple" />
            <h4>
              JewelCloud, State of the art technology <br /> for the jewelry
              industry
            </h4>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form__section">
            <div className="login__block">
              <h3>Login to JewelCloud</h3>
              <Form
                name="basic"
                initialValues={{
                  remember: false,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                ref={formRef}
              >
                <Form.Item className="formlabel">
                  Username <span className="mandatory">*</span>
                </Form.Item>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input className="login-input" />
                </Form.Item>
                <Form.Item className="formlabel">
                  Password <span className="mandatory">*</span>
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password className="login-input" />
                </Form.Item>

                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  className="checkbox__block"
                >
                  <div className="checkbox__block">
                    <Checkbox className="formcheckbox">Remember me</Checkbox>
                    <a onClick={showModal2}>Forgot Password ?</a>
                  </div>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="primary-btn w-100 mb-3"
                    loading={loading}
                  >
                    Login
                  </Button>

                  <Button
                    type="primary"
                    htmlType="button"
                    className="primary-btn w-100"
                  >
                    <Link to={"/register"} className="manage__Link">
                      Register
                    </Link>
                  </Button>
                </Form.Item>
              </Form>
            </div>

            <div className="newto__block">
              <h3>
                New to JewelCloud: <span>{designerName}</span>
              </h3>
            </div>
          </div>
        </div>

        <div className="col-md-12 login__slider">
          <div className="slider__section">
            {designerLogoData ? (
              <OwlCarousel className="owl-theme" {...options}>
                {designerLogoData.map((item, i) => {
                  return item.logoImage ? (
                    <div class="item" key={i}>
                      <div className="slider__block">
                        <img src={item.logoImage} alt="" />
                      </div>
                    </div>
                  ) : null;
                })}
              </OwlCarousel>
            ) : null}
          </div>
        </div>

        <div className="col-md-12 p-0">
          <div className="footer__section login__footer">
            <div className="left__block">
              <div className="footer__logo">
                <img src={footerLogoImg} alt="" />
              </div>
              <div className="footer__menu">
                <ul>
                  <li>
                    <a href="#">Request Appointment</a>
                  </li>
                  <li>
                    <a href="#">Terms of Service</a>
                  </li>
                  <li>
                    <a href="#">Privacy Statement</a>
                  </li>
                  <li>
                    <a href="#">Copyright 2021 GemFind</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="right__block">
              <ul>
                <li>
                  <a href="#">
                    <img src={footerCompanyLogo1} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={footerCompanyLogo2} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={footerCompanyLogo3} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={footerCompanyLogo4} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={footerCompanyLogo5} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal
        visible={isModalVisible}
        className="ytvideo__modal"
        onCancel={handleCancel}
      >
        <div className="iframe__section">
          <iframe
            width="100%"
            height="450"
            src="https://www.youtube.com/embed/RQqc2onAX9M"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </Modal>

      {/* Forgot Password Modal Starts */}
      <Modal
        title="Forgot Password"
        visible={isModalVisible2}
        className="forgot__password"
        onCancel={handleCancel2}
        footer={
          <div className="input__block">
            <button className="primary-btn" onClick={handleResetPassword}>
              Reset Password
            </button>
          </div>
        }
      >
        <div className="form__fields p-0 border-0 mh-auto">
          <div className="input__block">
            <label>Email Address</label>
            <input
              type="text"
              placeholder="Enter Email Address"
              name="email"
              value={resetPassword.email}
              onChange={handleOnChange}
              className={validations.emailVal && "border__red"}
            />
            <div>
              {validations.emailVal && (
                <p className="error-color-red">{validations.emailVal}</p>
              )}
            </div>
          </div>
          {/* <div className="input__block">
            <button className="primary-btn" onClick={handleResetPassword}>
              Reset Password
            </button>
          </div> */}
        </div>
      </Modal>
      {/* Forgot Password Modal Starts */}
    </div>
  );
};

export default Login;
