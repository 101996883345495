import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Table,
  Modal,
  DatePicker,
  Switch,
  Space,
  Spin,
} from "antd";
import {
  EditOutlined,
  CalendarOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";

import Highlighter from "react-highlight-words";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";

import userIcon from "../../../assets/images/daimond-bg.png";
import traslate from "../../../i18n/translate";

const { Option } = Select;

export const PendingSync = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState({
    dealerID: "",
  });
  const [dealerList, setDealerList] = useState([]);
  const [pendingJewelrySyncList, setPendingJewelrySyncList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [discardData, setDiscardData] = useState({
    id: 0,
    dealerId: 0,
  });
  const [isDiscardModalVisible, setIsDiscardModalVisible] = useState(false);
  const [isDiscardAllModalVisible, setIsDiscardAllModalVisible] =
    useState(false);

  useEffect(() => {
    handleGetDealerDropDownList();
  }, []);

  useEffect(() => {
    if (data.dealerID) {
      handleGetPendingJewelrySyncList(data.dealerID);
    }
  }, [data.dealerID]);

  const handleGetPendingJewelrySyncList = (id) => {
    debugger;
    try {
      let inputData = {
        dealerID: id,
      };
      setLoading(true);
      AdminToolsDataService.GetPendingJewelrySyncList(inputData)
        .then((response) => {
          debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            setPendingJewelrySyncList(responseData);
          } else {
            setPendingJewelrySyncList([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleGetDealerDropDownList = () => {
    var inputData = {
      dealerID: 0,
    };
    try {
      AdminToolsDataService.GetDealerDropDownList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setDealerList(responseData);
          } else {
            setDealerList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setData({
        ...data,
        [name]: e,
      });
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleRunSyncForce = () => {
    try {
      let inputData = {
        dealerID: data.dealerID,
      };
      setLoading(true);
      AdminToolsDataService.RunSyncForce(inputData)
        .then((response) => {
          var message = response.data.message;
          setLoading(false);
          if (message == "Success") {
            NotificationManager.success("RunSyncForce Carried Successfully");
          } else {
            NotificationManager.error("RunSyncForce Carried Unsuccessfull");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDiscardItem = () => {
    debugger;
    const id = discardData.id;
    const dealerID = discardData.dealerId;
    const all = 0;
    handleDiscardItemApi(id, dealerID, all);
  };

  const handleDiscardAllItem = () => {
    const id = 0;
    const dealerID = data.dealerID;
    const all = 1;
    handleDiscardItemApi(id, dealerID, all);
  };

  const handleDiscardItemApi = (id, dealerID, all) => {
    debugger;
    try {
      let inputData = {
        id: id,
        dealerId: dealerID,
        all: all,
      };
      setLoading(true);
      AdminToolsDataService.UpdateDiscardStatus(inputData)
        .then((response) => {
          var message = response.data.message;
          setLoading(false);
          if (message == "Success") {
            NotificationManager.success("Discard Carried Successfully");
            handleGetPendingJewelrySyncList(dealerID);
            handleDiscardAllCancel();
            handleDiscardCancel();
          } else {
            NotificationManager.error("Discard Carried Unsuccessfull");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onSearch = (val) => {
    console.log("search:", val);
  };

  const showDiscardModal = (row) => {
    debugger;
    setIsDiscardModalVisible(true);
    setDiscardData({
      id: row.jewelryCSVHistoryID,
      dealerId: row.dealerId,
    });
  };

  const handleOk = () => {
    setIsDiscardModalVisible(false);
  };

  const handleDiscardCancel = () => {
    setIsDiscardModalVisible(false);
    setDiscardData({});
  };

  const showDiscardAllModal = () => {
    debugger;
    setIsDiscardAllModalVisible(true);
  };

  const handleDiscardAllOk = () => {
    setIsDiscardAllModalVisible(false);
  };

  const handleDiscardAllCancel = () => {
    setIsDiscardAllModalVisible(false);
  };

  return (
    <div className="form__fields">
      <div className="row magento__mapping">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0 mb-0">
              {" "}
              {traslate("PendingSync")}
            </h4>
          </div>
        </div>
        <div className="col-md-4">
          <div className="input__block">
            <label> {traslate("SelectDealer")} </label>
            <Select
              showSearch
              className="border__grey"
              optionFilterProp="children"
              placeholder="Select Dealer"
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              name="dealerID"
              value={data.dealerID ? data.dealerID : null}
              onChange={(e) => handleOnChange(e, "select", "dealerID")}
            >
              {dealerList.map((item) => {
                return (
                  <Option value={item.dealerID}>
                    {item.dealerCompany + " (" + item.dealerID + " )"}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        {data.dealerID ? (
          <div className="col-md-12 divForm">
            <div className="col-lg-12 text-right mb-2">
              {!pendingJewelrySyncList.length == 0 ? (
                <button className="primary-btn" onClick={showDiscardAllModal}>
                  {traslate("DiscardAll")}
                </button>
              ) : null}
              <button className="primary-btn ml-2" onClick={handleRunSyncForce}>
                {traslate("RunValidImageApplication")}
              </button>
            </div>
            <Spin spinning={loading} size="large">
              <Table
                columns={[
                  {
                    title: traslate("DealerID"),
                    dataIndex: "dealerId",
                  },
                  {
                    title: traslate("DealerCompany"),
                    dataIndex: "dealerCompany",
                  },
                  {
                    title: traslate("SyncID"),
                    dataIndex: "jewelryCSVHistoryID",
                  },
                  {
                    title: traslate("Type"),
                    dataIndex: "type",
                  },
                  {
                    title: traslate("Status"),
                    dataIndex: "status",
                  },
                  {
                    title: traslate("Discard"),
                    fixed: "right",
                    key: "7",
                    width: 100,
                    // render: (row, item) => {
                    //   // <span
                    //   //   style={{ color: "blue" }}
                    //   //   onClick={() => handleDiscardItem(row)}
                    //   // >
                    //   //   Discard
                    //   // </span>;

                    // },
                    render: (index, row) => (
                      <div>
                        <div>
                          <div onClick={() => showDiscardModal(row)}>
                            {traslate("Discard")}
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ]}
                locale={{ emptyText: "No Pending Syncs Found" }}
                dataSource={pendingJewelrySyncList}
                scroll={{ y: 300 }}
              />
            </Spin>
          </div>
        ) : null}
      </div>
      <Modal
        title="Discard"
        className="modal__addcolor"
        centered
        visible={isDiscardModalVisible}
        onOk={() => setIsDiscardModalVisible(false)}
        onCancel={() => setIsDiscardModalVisible(false)}
        width={400}
        footer={[
          <Button key="back" onClick={handleDiscardCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleDiscardItem}>
            Discard
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <p className="subheading">
                      Are you sure you want to discard ?
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title="Discard All"
        className="modal__addcolor"
        centered
        visible={isDiscardAllModalVisible}
        onOk={() => setIsDiscardAllModalVisible(false)}
        onCancel={() => setIsDiscardAllModalVisible(false)}
        width={400}
        footer={[
          <Button key="back" onClick={handleDiscardAllCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleDiscardAllItem}>
            Discard All
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <p className="subheading">
                      Are you sure you want to discard all ?
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PendingSync);
