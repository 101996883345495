import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table
} from "antd";

const SyncHistory = () => {
  const [switchValue, setswitchValue] = useState(true);

  

  const columns = [
    {
      title: "Status",
      dataIndex: "Status"
    },
    {
      title: "File Type",
      dataIndex: "FileType"
    },
    {
      title: "File Name",
      dataIndex: "FileName"
    },
    {
      title: "Folder",
      dataIndex: "Folder"
    },
    {
      title: "Date",
      dataIndex: "Date"
    },
  ];

  const data = [
    {
      key: "1",
      Status: <span>Completed</span>,
      FileType: <span>Full</span>,
      FileName: <span>GemfindInventory</span>,
      Folder: <span>56550</span>,
      Date: <span>01/01/2022</span>,
    },
    {
    key: "2",
    Status: <span>Pending</span>,
    FileType: <span>Delete</span>,
    FileName: <span>GemfindInventory</span>,
    Folder: <span>56550</span>,
    Date: <span>01/01/2022</span>,
    },
    {
    key: "3",
    Status: <span>Error</span>,
    FileType: <span>Full</span>,
    FileName: <span>GemfindInventory</span>,
    Folder: <span>56550</span>,
    Date: <span>01/01/2022</span>,
    },
  ];

  return (
    <React.Fragment>
      <div className="pendjewelreq__section magento__mapping">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
              Sync History
              </h4>
              <div>
                <button className="primary-btn mr-2">
                Generate Sync
                </button>
                <button className="primary-btn">
                Generate Full Sync
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12">
            <Table className="pendjewelreq__tbl" columns={columns} dataSource={data} scroll={{ x: 600 }} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SyncHistory;
