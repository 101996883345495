import React from "react";
import { connect } from "react-redux";
import Noun_profile_12 from "../../assets/images/noun_profile_12.png";
import Noun_rss_prple from "../../assets/images/noun_rss_prple.png";
import lft_chat from "../../assets/images/lft_chat.png";
import traslate from "../../i18n/translate";

export const Conversation = (props) => {
  return (
    <React.Fragment>
      <div className="catalog__list dashbo_maindiv form__fields">
        <div className="row">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
              {traslate("Conversation")}
              </h4>
            </div>
          </div>

          <div className="col-md-12">
            <div className="Notif_section">
              <div className="msg_div">
                <div>
                  <p className="msg_date">Monday, December 14, 2020</p>
                </div>
                <div className="msg_divtext">
                  <span className="msg_txt">
                    <img src={lft_chat} alt="abc" className="mr-1" />
                    <span className="msg_nametxt">Bhavin Patel</span> Started a  
                    <span className="msg_nametxt"> Conversation </span> 
                    with your store.
                  </span>

                  <span className="msg_timetxt">11:25PM</span>
                </div>
              </div>

              <div className="msg_div">
                <div>
                  <p className="msg_date">Monday, October 5, 2020</p>
                </div>
                <div className="msg_divtext">
                  <span className="msg_txt">
                    <img src={lft_chat} alt="abc" className="mr-1" />
                    <span className="msg_nametxt">Bhavin Patel</span> Started a  
                    <span className="msg_nametxt"> Conversation </span> 
                    with your store.
                  </span>

                  <span className="msg_timetxt">11:25PM</span>
                </div>
              </div>

              <div className="msg_div">
                <div>
                  <p className="msg_date">Tuesday, July 28, 2020</p>
                </div>
                <div className="msg_divtext">
                  <span className="msg_txt">
                    <img src={lft_chat} alt="abc" className="mr-1" />
                    <span className="msg_nametxt">Bhavin Patel</span> Started a  
                    <span className="msg_nametxt"> Conversation </span> 
                    with your store.
                  </span>

                  <span className="msg_timetxt">11:25PM</span>
                </div>
                <div className="msg_divtext">
                  <span className="msg_txt">
                    <img src={lft_chat} alt="abc" className="mr-1" />
                    <span className="msg_nametxt">Bhavin Patel</span> Started a  
                    <span className="msg_nametxt"> Conversation </span> 
                    with your store.
                  </span>

                  <span className="msg_timetxt">11:25PM</span>
                </div>
                <div className="msg_divtext">
                  <span className="msg_txt">
                    <img src={lft_chat} alt="abc" className="mr-1" />
                    <span className="msg_nametxt">Bhavin Patel</span> Started a  
                    <span className="msg_nametxt"> Conversation </span> 
                    with your store.
                  </span>

                  <span className="msg_timetxt">11:25PM</span>
                </div>
                <div className="msg_divtext">
                  <span className="msg_txt">
                    <img src={lft_chat} alt="abc" className="mr-1" />
                    <span className="msg_nametxt">Bhavin Patel</span> Started a  
                    <span className="msg_nametxt"> Conversation </span> 
                    with your store.
                  </span>

                  <span className="msg_timetxt">11:25PM</span>
                </div>
              </div>

              <div>
                <button className="primary-btn mt-3"> {traslate("LoadMore")} </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Conversation);
