import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Table,
  Modal,
  DatePicker,
  Switch,
  Space,
  Tooltip,
  Radio,
  Spin,
} from "antd";
import { Tabs, Tab, Accordion } from "react-bootstrap";
import Create_icon from "../../../assets/images/create_icon.png";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import noun_upload_import from "../../../assets/images/icons/noun_upload_import.svg";
import noun_upload_export from "../../../assets/images/icons/noun_upload_export.svg";
import close from "../../../assets/images/icons/close.svg";
import close_red from "../../../assets/images/icons/close_red.svg";
import Excel_icon from "../../../assets/images/Excel_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import CatalogJewelryDataService from "../../../services/catalog-jewelry.service";
import { NotificationManager } from "react-notifications";
import menuIcon from "../../../assets/images/menu-icon.svg";
import menuIconBlack from "../../../assets/images/icons/noun_hamburger.svg";
// import { Draggable, Droppable } from "rc-easyui";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  BlobToDownloadFile,
  DownloadFile,
} from "../../../helper/commanFunction";
import FileSaver from "file-saver";
import traslate from "../../../i18n/translate";

const { Option } = Select;
const { Search } = Input;

export const DataMaping = (props) => {
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loginUserDeatils, setLoginUserDetails] = useState({});
  const [mappedAttributesList, setMappedAttributesList] = useState([]);
  const [notMappedAttributesList, setNotMappedAttributesList] = useState([]);
  const [columnListByColumnId, setColumnListByColumnId] = useState([]);
  const [dataMappingList, setDataMappingList] = useState([]);
  const [rowDetails, setRowDetails] = useState({});
  const [mapCount, setMapCount] = useState(0);
  const [notMapCount, setNotMapCount] = useState(0);
  const [selectedColumnId, setSelectedColumnId] = useState(0);
  const [newColumnName, setNewColumnName] = useState(null);
  const [errorNewColumnName, setErrorNewColumnName] = useState(null);
  const [dealerColumnList, setDealerColumnList] = useState([]);
  const [loadingDealerColumnList, setLoadingDealerColumnList] = useState(false);
  const [editSelectedDealerColumn, setEditSelectedDealerColumn] = useState(0);
  const [columnId, setColumnId] = useState(14);
  const [imageUploadSetting, setImageUploadSetting] = useState(false);
  const [importMultiple, setImportMultiple] = useState(false);
  const [delimiterImages, setDelimiterImages] = useState("");
  const [errorDelimiterImages, setErrorDelimiterImages] = useState("");
  const [attributeColumnData, setAttributeColumnData] = useState([]);
  const [selectedAttibutes, setSelectedAttributes] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [attributeLoading, setAttributeLoading] = useState(false);
  const [priceTypeModal, setPriceTypeModal] = useState(false);
  const [priceType, setPriceType] = useState(null);
  const [allowOverride, setAllowOverride] = useState(false);
  const [importMappingFile, setImportMappingFile] = useState(null);
  const [chooseFormate, setChooseFormate] = useState(null);
  const [importMappingTemplate, setImportMappingTemplate] =
    useState("JewelCloud");
  const [errorImportMappingTemplate, setErrorImportMappingTemplate] =
    useState(null);

  const [dealerId, setDealerId] = useState(0);
  const [uploadMappingModal, setUploadMappingModal] = useState(false);
  const [uploadType, setUploadType] = useState(false);
  const [errorChooseFormate, setErrorChooseFormate] = useState(null);
  const [categoriesParentData, setCategoriesParentData] = useState([]);

  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setLoginUserDetails(loginDetials.responseData);
        setDealerId(loginDetials.responseData.dealerId);
        handleGetDealerMappedAttributesList(loginDetials.responseData.dealerId);
        handleGetDealerNotMappedAttributesList(
          loginDetials.responseData.dealerId
        );
        handleGetMappedUnMappedColumnCount(loginDetials.responseData.dealerId);
        handleGetAttributeListByColumnId(
          loginDetials.responseData.dealerId,

          14
        );
      }
    }
  }, []);
  useEffect(() => {
    if (location.state) {
      if (location.state.columnID) {
        handleGetAttributeListByColumnId(
          loginDetials.responseData.dealerId,
          location.state.columnID
        );
        setColumnId(location.state.columnID);
      }
    } else {
    }
  }, [location]);

  const handleGetDealerMappedAttributesList = (id) => {
    try {
      let inputData = {
        dealerid: id || 0,
      };
      CatalogJewelryDataService.GetDealerMappedAttributesList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setMappedAttributesList(responseData);
            } else {
              setMappedAttributesList([]);
            }
          } else {
            setMappedAttributesList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetDealerNotMappedAttributesList = (id) => {
    try {
      let inputData = {
        dealerid: id || 0,
      };
      CatalogJewelryDataService.GetDealerNotMappedAttributesList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setNotMappedAttributesList(responseData);
            } else {
              setNotMappedAttributesList([]);
            }
          } else {
            setNotMappedAttributesList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetDealerColumnListByColumnId = () => {
    try {
      let inputData = {
        // dealerid: 3852,
        dealerid: dealerId,
      };
      setVisible2(true);
      setLoadingDealerColumnList(true);
      CatalogJewelryDataService.GetDealerColumnListByColumnId(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setLoadingDealerColumnList(false);
            if (responseData) {
              setDealerColumnList(responseData);
            } else {
              setDealerColumnList([]);
            }
          } else {
            setDealerColumnList([]);
          }
        })
        .catch((error) => {
          setLoadingDealerColumnList(false);
          console.log(error);
        });
    } catch (error) {
      setLoadingDealerColumnList(false);
      console.log(error);
    }
  };
  const handleSaveNewDealerColumn = () => {
    if (!newColumnName) {
      setErrorNewColumnName("Please Enter Column Name.");
      return false;
    } else {
      setErrorNewColumnName(null);
    }
    try {
      let inputData = {
        dealerId: dealerId,
        columnName: newColumnName,
      };
      CatalogJewelryDataService.SaveNewDealerColumn(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setNewColumnName("");
              handleGetDealerColumnListByColumnId();
              NotificationManager.success("Record Added Successfully.");
            } else {
              NotificationManager.error("Record Not Added.");
            }
          } else {
            NotificationManager.error("Record Not Added.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleRemoveColumnMapping = (row) => {
    try {
      let inputData = {
        jewelryColumnMappingId: row.tblJewelryCoumnMasterId,
        hasValueMapping: row.haveColumnValue,
      };
      CatalogJewelryDataService.RemoveColumnMapping(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              handleGetAttributeListByColumnId(dealerId, columnId);
            } else {
              setColumnListByColumnId([]);
            }
          } else {
            setColumnListByColumnId([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleManageJewelryPriceTypeColumn = () => {
    try {
      let inputData = {
        dealerId: dealerId,
        priceType: Number(priceType),
        allowOverride: allowOverride,
      };
      setSubmitLoading(true);
      CatalogJewelryDataService.ManageJewelryPriceTypeColumn(inputData)
        .then((response) => {
          var message = response.data.message;
          setSubmitLoading(false);
          if (message == "Success") {
            NotificationManager.success("Price Type Saved Sucessfully.");
          } else {
            NotificationManager.error("Price Type Not Saved.");
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };
  const handleSaveImagePathSettings = () => {
    if (!delimiterImages) {
      setErrorDelimiterImages("Enter one character as Delimiter.");
      return false;
    } else {
      setErrorDelimiterImages(null);
    }
    try {
      let inputData = {
        dealerId: dealerId,
        multiImageTrueFalse: importMultiple,
        imageDelimiter: delimiterImages,
      };
      setSubmitLoading(true);
      CatalogJewelryDataService.SaveImagePathSettings(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setSubmitLoading(false);
          if (message == "Success") {
            if (responseData) {
              handleCloseImageuploadSettingModal();
              NotificationManager.success(
                "Image Upload Settings Save Succssfully."
              );
            } else {
              NotificationManager.success("Image Upload Settings Not Save.");
            }
          } else {
            NotificationManager.success("Image Upload Settings Not Save.");
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };
  const handleGetAttributeListByColumnId = (id, cid) => {
    try {
      let inputData = {
        dealerId: id,
        columnID: cid,
      };
      setLoading(true);
      CatalogJewelryDataService.GetAttributeListByColumnId(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            if (responseData) {
              setDataMappingList(responseData);
              handleGetAttributeDetailListByColumnId(responseData[0].columnId);
            } else {
              setDataMappingList({});
            }
          } else {
            setDataMappingList({});
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleGetAttributeDetailListByColumnId = (id) => {
    try {
      let inputData = {
        columnID: id,
      };
      setSelectedColumnId(id);
      CatalogJewelryDataService.GetAttributeDetailListByColumnId(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setRowDetails(responseData[0]);
            } else {
              setRowDetails({});
            }
          } else {
            setRowDetails({});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetJewelryImagePathValueList = () => {
    try {
      let inputData = {
        dealerId: dealerId,
      };
      CatalogJewelryDataService.GetJewelryImagePathValueList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setImportMultiple(responseData[0].hasMultiImages);
              setDelimiterImages(responseData[0].multiImagesDelimiter);
            } else {
            }
          } else {
            setColumnListByColumnId([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleMapDealerStockNumberColumn = () => {
    try {
      let inputData = {
        dealerId: 0,
        gemFindColumnId: 0,
        dealerColumnId: 0,
        mappingColumnID: 0,
      };
      CatalogJewelryDataService.MapDealerStockNumberColumn(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setColumnListByColumnId(responseData);
            } else {
              setColumnListByColumnId([]);
            }
          } else {
            setColumnListByColumnId([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleMapDealerColumn = () => {
    try {
      let inputData = {
        dealerId: 0,
        gemFindColumnId: 0,
        dealerColumnId: 0,
      };
      CatalogJewelryDataService.MapDealerColumn(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setColumnListByColumnId(responseData);
            } else {
              setColumnListByColumnId([]);
            }
          } else {
            setColumnListByColumnId([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateDealerColumn = () => {
    if (editSelectedDealerColumn) {
      var tempData = dealerColumnList.filter(
        (x) => x.columnId == editSelectedDealerColumn
      );
      try {
        let inputData = {
          columnID: tempData[0].columnId,
          columnName: tempData[0].columnName,
        };
        CatalogJewelryDataService.UpdateDealerColumn(inputData)
          .then((response) => {
            var message = response.data.message;
            var responseData = response.data.responseData;
            if (message == "Success") {
              if (responseData) {
                setDealerColumnList(responseData);
                setEditSelectedDealerColumn(null);
                NotificationManager.success("Record Updated Succssfully.");
              } else {
                NotificationManager.error("Record Not Updated.");
              }
            } else {
              NotificationManager.error("Record Not Updated.");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleGetMappedUnMappedColumnCount = (id) => {
    try {
      let inputData = {
        dealerid: id,
      };
      CatalogJewelryDataService.GetMappedUnMappedColumnCount(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setMapCount(responseData[0].totMappedCol);
              setNotMapCount(responseData[0].totNotMappedCol);
            } else {
              setMapCount(0);
              setNotMapCount(0);
            }
          } else {
            setMapCount(0);
            setNotMapCount(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleRefershData = () => {
    handleGetDealerMappedAttributesList(dealerId);
    handleGetDealerNotMappedAttributesList(dealerId);
    handleGetMappedUnMappedColumnCount(dealerId);
  };
  const handleEditDealerColumn = (id) => {
    setEditSelectedDealerColumn(id);
  };
  const handleEditDealerColumnValue = (id, e) => {
    const tempDealerColumnList = dealerColumnList.map((x) => {
      if (x.columnId === id) {
        return { ...x, columnName: e.target.value };
      } else return x;
    });
    setDealerColumnList(tempDealerColumnList);
  };
  const handleCloseAddNewColumnModal = () => {
    setVisible2(false);
    setNewColumnName(null);
    setErrorNewColumnName(null);
    setDealerColumnList([]);
    setSelectedColumnId(null);
  };
  const handleOpenModal = (e, row) => {
    e.stopPropagation();
    if (row.columnName === "ImagePath") {
      handleOpenImageuploadSettingModal();
      handleGetJewelryImagePathValueList();
    } else if (row.columnName === "PriceType") {
      setPriceTypeModal(true);
    } else {
      setVisible(true);
      handleGetAttributeColumnData(row.columnName, row.typeId);
    }
  };
  const handleOpenImageuploadSettingModal = () => {
    setImageUploadSetting(true);
  };
  const handleCloseImageuploadSettingModal = () => {
    setImageUploadSetting(false);
    setDelimiterImages(null);
    setErrorDelimiterImages(null);
    setSubmitLoading(false);
  };
  const handleGetAttributeColumnData = (name, id) => {
    try {
      let inputData = {
        name: name,
        typeId: id,
      };
      setSelectedAttributes(name);
      setAttributeLoading(true);
      CatalogJewelryDataService.GetAttributeColumnData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setAttributeLoading(false);
          if (message == "Success") {
            if (responseData) {
              if (name !== "Categories") {
                setAttributeColumnData(responseData);
              } else {
                var tempData = [
                  ...new Set(
                    responseData.map((obj) => {
                      return obj["parentCategory"];
                    })
                  ),
                ];
                setCategoriesParentData(tempData);
                setAttributeColumnData(responseData);
              }
            } else {
            }
          } else {
          }
        })
        .catch((error) => {
          setAttributeLoading(false);
          console.log(error);
        });
    } catch (error) {
      setAttributeLoading(false);
      console.log(error);
    }
  };
  const handleCloseAttributModal = () => {
    setVisible(false);
    setAttributeColumnData([]);
    setCategoriesParentData([]);
  };
  const handleClosePriceTypeModal = () => {
    setPriceTypeModal(false);
    setPriceType(null);
    setAllowOverride(false);
  };
  const handleImportFileUpload = (e) => {
    if (e.target.files[0].name.match(/.(xls|txt|csv)$/i)) {
      setImportMappingFile(e.target.files[0]);
      setErrorImportMappingTemplate(null);
    } else {
      setImportMappingFile(null);
      setErrorImportMappingTemplate("Please Select Give File Formate.");
    }
  };
  const handleCloseImportMappingModal = () => {
    setVisible1(false);
    setImportMappingFile(null);
    setChooseFormate(null);
    setImportMappingTemplate("JewelCloud");
    setErrorImportMappingTemplate(null);
    setChooseFormate(null);
    setUploadType(false);
  };
  const handleCloseUploadMappingModal = () => {
    setUploadMappingModal(false);
  };
  const handleOpenUploadMappingModal = (type) => {
    if (type === "LoadFile") {
      setUploadMappingModal(true);
      setUploadType(false);
    } else {
      var isValid = true;
      if (!importMappingFile) {
        setErrorImportMappingTemplate("Please select Data File.");
        isValid = false;
      } else {
        setErrorImportMappingTemplate("");
      }
      if (!chooseFormate) {
        isValid = false;
        setErrorChooseFormate("Please select Format.");
      } else {
        setErrorChooseFormate("");
      }
      if (isValid) {
        setUploadMappingModal(true);
        setUploadType(true);
      } else {
        return false;
      }
    }
  };
  const handleSaveDefaultValue = () => {
    try {
      setSubmitLoading(true);
      let inputData = [];
      attributeColumnData.forEach((element) => {
        if (selectedAttibutes == "Categories") {
          element.headerName = "Categories";
        }
        element.dValueID = Number(element.dValueID);
        inputData.push(element);
      });
      CatalogJewelryDataService.SaveDefaultValue(attributeColumnData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setSubmitLoading(false);
          if (message == "Success") {
            handleCloseAttributModal();
            NotificationManager.success("Attribute Value Saved Successfully.");
          } else {
            NotificationManager.error("Attribute Value Not Saved.");
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };
  const handleAttributeValueChanges = (e, id) => {
    var tempattributeColumnData = [];
    if (selectedAttibutes === "Categories") {
      tempattributeColumnData = attributeColumnData.map((x) => {
        if (x.dValueID === id) {
          return { ...x, dvalue: e.target.value };
        } else return x;
      });
    } else {
      tempattributeColumnData = attributeColumnData.map((x) => {
        if (x.id === id) {
          return { ...x, dvalue: e.target.value };
        } else return x;
      });
    }
    setAttributeColumnData(tempattributeColumnData);
  };
  const handleUploadSheet = () => {
    try {
      const formData = new FormData();
      formData.append("ExeclFileName", uploadType ? importMappingFile : null);
      formData.append("FileFormatName", uploadType ? chooseFormate : null);
      formData.append("hdnLoadFromTemplate", uploadType);
      formData.append("DealerID", dealerId);
      formData.append(
        "MappingTemplateddlSelectedValue",
        uploadType ? null : importMappingTemplate
      );
      setSubmitLoading(true);
      CatalogJewelryDataService.UploadSheet(formData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setSubmitLoading(false);

          if (message == "Success") {
            handleCloseUploadMappingModal();
            handleCloseImportMappingModal();
            NotificationManager.success("Data Map Reset Successfully.");
            handleGetAttributeListByColumnId(dealerId, columnId);
          } else {
            NotificationManager.error("Data Map Not Reset.");
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };
  const handleDownloadAttributesMapFile = () => {
    try {
      let inputData = {
        dealerID: Number(dealerId),
        columnID: Number(columnId),
      };
      setSubmitLoading(true);
      CatalogJewelryDataService.DownloadAttributesMapFile(inputData)
        .then((response) => {
          setSubmitLoading(false);
          var fileName =
            columnId == 1
              ? "ItemIdentity_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 2
              ? "ConfigurableProducts_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 3
              ? "ItemDetails_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 4
              ? "ItemPricing_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 5
              ? "RelationalPricing_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 6
              ? "CostBasisPricing_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 7
              ? "Item-PromoSpecials_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 8
              ? "ItemMedia_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 9
              ? "ItemSizing_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 10
              ? "GemstoneDetails_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 11
              ? "Semi-Mounts&Ring Builder_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 12
              ? "WatchDetails_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 13
              ? "AdditionalDetails_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : columnId == 14
              ? "Essential_(" +
                dealerId +
                ")_" +
                new Date().toJSON().slice(0, 10).replace(/-/g, "") +
                ".xlsx"
              : "";
          BlobToDownloadFile(response.data, fileName);
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };
  return (
    <div className="prodetail__section">
      <div className="form__fields">
        <div className="prod__maindiv prodmarkup__secion essential__section">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-lg-4 mt-2">
                  <h4 class="workarea__heading mt-0 mb-0">
                    {columnId == 1
                      ? traslate("ItemIdentity")
                      : columnId == 2
                      ? traslate("ConfigurableProducts")
                      : columnId == 3
                      ? traslate("ItemDetails")
                      : columnId == 4
                      ? traslate("ItemPricing")
                      : columnId == 5
                      ? traslate("RelationalPricing")
                      : columnId == 6
                      ? traslate("CostBasisPricing")
                      : columnId == 7
                      ? traslate("Item-PromoSpecials")
                      : columnId == 8
                      ? traslate("ItemMedia")
                      : columnId == 9
                      ? traslate("ItemSizing")
                      : columnId == 10
                      ? traslate("GemstoneDetails")
                      : columnId == 11
                      ? traslate("Semi-Mounts&RingBuilder")
                      : columnId == 12
                      ? traslate("WatchDetails")
                      : columnId == 13
                      ? traslate("AdditionalDetails")
                      : columnId == 14
                      ? traslate("Essential")
                      : ""}
                  </h4>
                </div>
                <div className="col-lg-8 mt-2">
                  <div className="btns__div">
                    {/* <Link to="uploadProductFile"> */}
                    <button
                      className="primary-btn"
                      onClick={() => setVisible1(true)}
                    >
                      <img src={noun_upload_export} alt="" /> {traslate("ImportMapping")}
                    </button>
                    {/* </Link> */}
                    <Button
                      type="primary"
                      className="primary-btn ml-2"
                      onClick={() => handleDownloadAttributesMapFile()}
                      loading={submitLoading}
                    >
                      <img src={noun_upload_import} alt="" /> {traslate("ExportMapping")}
                    </Button>

                    <button
                      className="primary-btn ml-2"
                      onClick={() => handleGetDealerColumnListByColumnId()}
                    >
                      <img src={Create_icon} alt="" /> {traslate("AddNewColumn")}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4 mb-5">
              <div className="row">
                <div className="col-lg-9 mt-4">
                  <Table
                    className="datamapping__tbl"
                    columns={[
                      {
                        title: (
                          <span>
                            {traslate("Attribute")}
                            <Tooltip
                              placement="right"
                              className="ml-1"
                              title={traslate("JewelClouddefinedcolumnname.")}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </span>
                        ),
                        dataIndex: "columnName",
                        width: 140,
                      },
                      {
                        title: (
                          <span>
                            {traslate("Type")}
                            <Tooltip
                              placement="right"
                              className="ml-1"
                              title={traslate("Thisdefineswhattypeofdataisallowed.FixedlablesmeansyoumustuseanapprovedJewelCloudvalue.Numericcanonlycontainnumbersanddecimal.")}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </span>
                        ),
                        dataIndex: "attributeType",
                        width: 140,
                      },
                      {
                        title: (
                          <span>
                            {traslate("Requirement")}
                            <Tooltip
                              placement="right"
                              className="ml-1"
                              title={traslate("Datauploadswillfailifthesecolumnsdonotcontainavalue.")}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </span>
                        ),
                        dataIndex: "attributeType",
                        width: 140,
                      },
                      {
                        title: (
                          <span>
                            {traslate("YourAttribute")}
                            {/* <Tooltip
                              placement="right"
                              className="ml-1"
                              title={"No content."}
                            >
                              <img src={UnionIcon} alt="" />
                            </Tooltip> */}
                          </span>
                        ),
                        dataIndex: "dealerColumnName",
                        render: (item, row) => {
                          return (
                            <ul className="tags__ul">
                              <li className="tags__lbl">
                                <span className="tag__name">{item}</span>
                                <span>
                                  <img
                                    onClick={() =>
                                      handleRemoveColumnMapping(row)
                                    }
                                    src={close}
                                    alt=""
                                    className="close__icn"
                                  />
                                </span>
                              </li>
                            </ul>
                          );
                        },
                      },
                      {
                        title: "",
                        dataIndex: "hamb__icn",
                        width: 40,
                        render: (item, row) => {
                          return (
                            <React.Fragment>
                              {row.haveColumnValue ? (
                                <img
                                  src={menuIconBlack}
                                  className="hamb__icn"
                                  onClick={(e) => handleOpenModal(e, row)}
                                />
                              ) : // <i
                              //   class="fa fa-align-justify"
                              //   onClick={(e) => handleOpenModal(e, row)}
                              // ></i>
                              null}
                            </React.Fragment>
                          );
                        },
                      },
                    ]}
                    onRow={(record, recordIndex) => ({
                      onClick: (event) => {
                        handleGetAttributeDetailListByColumnId(record.columnId);
                      },
                    })}
                    rowClassName={(record) =>
                      record.columnId == selectedColumnId ? "row-active" : ""
                    }
                    loading={loading}
                    dataSource={dataMappingList}
                    scroll={{ y: 300 }}
                    pagination={false}
                  />
                </div>
                <div className="col-lg-3 mt-4">
                  <div className="prodesc__div">
                    <Tabs
                      defaultActiveKey="mapped"
                      id="uncontrolled-tab-example"
                      className="tab__div"
                    >
                      <Tab eventKey="mapped" title={traslate("Mapped")}>
                        <div className="tab__contentdiv">
                          <div className="col-lg-12">
                            <ul className="tags__ul">
                              {mappedAttributesList.map((item, index) => {
                                return (
                                  <React.Fragment>
                                    <li className="tags__lbl active">
                                      <span
                                        className="tag__name"
                                        title={item.columnName}
                                      >
                                        {item.columnName}
                                      </span>
                                    </li>
                                  </React.Fragment>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="nonmapped" title={traslate("NonMapped")}>
                        <div className="tab__contentdiv">
                          <div className="col-lg-12">
                            <ul className="tags__ul">
                              {notMappedAttributesList.map((item) => {
                                return (
                                  <React.Fragment>
                                    <li className="tags__lbl active">
                                      <span
                                        className="tag__name"
                                        title={item.columnName}
                                      >
                                        {item.columnName}
                                      </span>
                                    </li>
                                  </React.Fragment>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-5">
              <div className="desc__div">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="mt-2">
                      <h4 className="head__txt"> {traslate("Name")} </h4>
                      <p className="desc__txt">{rowDetails.columnName}</p>
                    </div>
                    <div className="mt-4">
                      <h4 className="head__txt"> {traslate("AcceptedValues")} </h4>
                      <p className="desc__txt">
                        {rowDetails.attributeType === "Text Field" ||
                        rowDetails.attributeType === "Fixed Labels" ||
                        rowDetails.attributeType === "TextField"
                          ? "Alphanumeric"
                          : rowDetails.attributeType === "Decimal Field"
                          ? "Float,Int"
                          : rowDetails.attributeType === "Date Field"
                          ? "Date"
                          : rowDetails.attributeType === "Boolean Field" ||
                            rowDetails.attributeType === "Boolean"
                          ? "Yes/No"
                          : rowDetails.attributeType === "Numeric" ||
                            rowDetails.attributeType === "Numeric Field"
                          ? "Number"
                          : "-"}
                      </p>
                    </div>
                    <div className="mt-4">
                      <h4 className="head__txt"> {traslate("Required")} </h4>
                      <p className="desc__txt">
                        {rowDetails.fieldValidationn === "Required"
                          ? "Yes"
                          : "No"}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="mt-2">
                      <h4 className="head__txt"> {traslate("Example")} </h4>
                      <p className="desc__txt">{rowDetails.example}</p>
                    </div>
                    <div className="mt-4">
                      <h4 className="head__txt"> {traslate("FeildType")} </h4>
                      <p className="desc__txt">
                        {rowDetails.attributeType === "Text Field" ||
                        rowDetails.attributeType === "Fixed Labels" ||
                        rowDetails.attributeType === "TextField"
                          ? "Alphanumeric"
                          : rowDetails.attributeType === "Decimal Field"
                          ? "Decimal"
                          : rowDetails.attributeType === "Date Field"
                          ? "Date"
                          : rowDetails.attributeType === "Boolean Field" ||
                            rowDetails.attributeType === "Boolean"
                          ? "Boolean"
                          : rowDetails.attributeType === "Numeric" ||
                            rowDetails.attributeType === "Numeric Field"
                          ? "Integer"
                          : "-"}
                      </p>
                    </div>
                    <div className="mt-4">
                      <h4 className="head__txt"> {traslate("Configurable")} </h4>
                      <p className="desc__txt">
                        {rowDetails.columnName === "IsConfigurableProduct" ||
                        rowDetails.columnName === "ParentSKU" ||
                        rowDetails.columnName === "VisibleAs" ||
                        rowDetails.columnName === "SelectedAttibutes" ||
                        rowDetails.columnName === "ConfigurableControlType" ||
                        rowDetails.columnName === "ControlDisplayOrder" ||
                        rowDetails.columnName === "FinishingTechnique" ||
                        rowDetails.columnName === "Gender"
                          ? "Yes"
                          : "No"}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="mt-2">
                      <h4 className="head__txt"> {traslate("Definition")} </h4>
                      <p className="desc__txt">{rowDetails.definition}</p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="count__div">
                      <div className="count__indiv">
                        <span className="no__txt">{mapCount}</span>
                        <span className="counthead__txt"> {traslate("Mapped")} </span>
                      </div>
                      <div className="count__indiv">
                        <span className="no__txt">{notMapCount}</span>
                        <span className="counthead__txt"> {traslate("Remaining")} </span>
                      </div>
                      <div className="reset__div">
                        <i
                          class="fa fa-refresh"
                          onClick={handleRefershData}
                        ></i>
                      </div>
                    </div>
                    <div className="content__div mt-4">
                      <p className="conthead__txt">
                      {traslate("MapYourContent")} <br /> {traslate("Drag&DropYourAttributes")}
                      </p>
                      <span className="desc__txt">
                      {traslate("MatchYourattributeswiththejewelcloudindustrystandard")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Arrtibute column Modal */}
            <Modal
              title={traslate("AttributeValueMap")}
              className="datamapping__modal"
              visible={visible}
              width={600}
              onOk={() => handleSaveDefaultValue()}
              onCancel={() => handleCloseAttributModal(false)}
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  loading={submitLoading}
                  onClick={() => handleSaveDefaultValue()}
                >
                  {traslate("Save")}
                </Button>,
              ]}
            >
              <div className="form__fields row border-0 p-0">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <span>
                      {traslate("MatchYourVariablestothejewelCloudIndustryStandard")}
                      </span>
                    </div>
                    <div className="col-lg-6">
                      <div className="inptsrch__div input__block">
                        <input
                          placeholder="Input Search Text"
                          onChange={(e) => setSearchValue(e.target.value)}
                          style={{ width: 200 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mt-4">
                  <div className="head__div">
                    <span>{selectedAttibutes}</span>
                  </div>
                </div>
                <Spin spinning={attributeLoading}>
                  <div className="col-lg-12">
                    {selectedAttibutes === "Categories" ? (
                      <React.Fragment>
                        {categoriesParentData.map((item) => {
                          return (
                            <React.Fragment>
                              <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header>
                                    <div>
                                      <span> {item}</span>
                                    </div>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div className="col-md-12">
                                      <div className="row">
                                        {attributeColumnData
                                          .filter(
                                            (x) => x.parentCategory === item
                                          )
                                          .filter((x) =>
                                            x.value
                                              .toLowerCase()
                                              .includes(searchValue)
                                          )
                                          .map((citem, i) => {
                                            return (
                                              <React.Fragment>
                                                <div className="col-md-12">
                                                  <div className="input__block">
                                                    <div className="row">
                                                      <div className="col-md-3 align-self-center">
                                                        <label className="mb-0">
                                                          {citem.value}
                                                        </label>
                                                      </div>
                                                      <div className="col-md-9">
                                                        <input
                                                          type="text"
                                                          placeholder="Enter value"
                                                          name=""
                                                          value={citem.dvalue}
                                                          onChange={(e) =>
                                                            handleAttributeValueChanges(
                                                              e,
                                                              citem.dValueID
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    ) : (
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <div>
                              <span> {selectedAttibutes}</span>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="col-md-12">
                              <div className="row">
                                {attributeColumnData
                                  .filter((x) =>
                                    x.value.toLowerCase().includes(searchValue)
                                  )
                                  .map((item, i) => {
                                    return (
                                      <React.Fragment>
                                        <div className="col-md-12">
                                          <div className="input__block">
                                            <div className="row">
                                              <div className="col-md-3 align-self-center">
                                                <label className="mb-0">
                                                  {item.value}
                                                </label>
                                              </div>
                                              <div className="col-md-9">
                                                <input
                                                  type="text"
                                                  placeholder="Enter value"
                                                  name=""
                                                  value={item.dvalue}
                                                  onChange={(e) =>
                                                    handleAttributeValueChanges(
                                                      e,
                                                      item.id
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    );
                                  })}
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    )}
                  </div>
                </Spin>
              </div>
            </Modal>
            {/* Import Column Modal */}
            <Modal
              className="datamapping__modal"
              visible={visible1}
              width={600}
              onOk={() => setVisible1(false)}
              onCancel={() => handleCloseImportMappingModal()}
              footer={[]}
            >
              <div className="form__fields row border-0 p-0">
                <div className="col-lg-12">
                  <div className="imptmap__headdiv">
                    <span> {traslate("ImportMapping")} </span>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="excel__maindiv mt-4">
                    <div className="excel__icndiv">
                      <img src={Excel_icon} alt="" />
                    </div>
                    <div className="excel__headtxtdiv">
                      <span className="head__txt">
                      {traslate("Yoursheetjustneedsyourcolumnheaders.")}
                      </span>
                      <span className="extn__txt"> {traslate(".xls|.txt|.csv")} </span>
                    </div>
                  </div>
                  <div className="excelfileupl__div mt-3">
                    <label
                      htmlFor="file-upload"
                      class="primary-btn choose_file_upload"
                    >
                      {traslate("ChooseFile")}
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      onChange={(e) => handleImportFileUpload(e)}
                    />
                    <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                    <p className="filename__txt">
                      {importMappingFile ? (
                        <>
                          {importMappingFile.name}{" "}
                          <img
                            src={close_red}
                            alt=""
                            onClick={() => setImportMappingFile(null)}
                          />
                        </>
                      ) : null}
                    </p>
                    {errorImportMappingTemplate ? (
                      <p>
                        <span style={{ color: "red" }}>
                          {errorImportMappingTemplate}
                        </span>
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12 mt-4">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input__block">
                        <label className="">
                        {traslate("ChooseFormat")}
                          <span>*</span>
                          <Tooltip placement="right" title={traslate("Nocontent")}>
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Select
                          showSearch
                          className="border__grey"
                          placeholder="Choose Format"
                          name="availability"
                          optionFilterProp="children"
                          value={chooseFormate}
                          onChange={(e) => {
                            setChooseFormate(e);
                          }}
                        >
                          {/* <Option>Choose Format</Option> */}
                          <Option value="Comma"> {traslate("Comma")} </Option>
                          <Option value="Tab"> {traslate("Tab")} </Option>
                        </Select>
                        {errorChooseFormate ? (
                          <p>
                            <span style={{ color: "red" }}>
                              {errorChooseFormate}
                            </span>
                          </p>
                        ) : null}
                        <button
                          className="primary-btn mt-3"
                          onClick={() =>
                            handleOpenUploadMappingModal("UploadSheet")
                          }
                        >
                          {traslate("UploadSheet")}
                        </button>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input__block">
                        <label className="">
                        {traslate("ImportMappingTemplate")}
                          {/* <span>*</span> */}
                        </label>
                        <Select
                          showSearch
                          className="border__grey"
                          placeholder="Jewel Cloud"
                          name="availability"
                          optionFilterProp="children"
                          value={importMappingTemplate}
                          onChange={(e) => {
                            setImportMappingTemplate(e);
                          }}
                        >
                          <Option value="JewelCloud"> {traslate("JewelCloud")} </Option>
                          <Option value="Edge TPW"> {traslate("EdgeTPW")} </Option>
                          <Option value="Arms"> {traslate("Arms")} </Option>
                          <Option value="Edge EDT"> {traslate("EdgeEDT")} </Option>
                        </Select>
                        <button
                          className="primary-btn mt-3"
                          onClick={() =>
                            handleOpenUploadMappingModal("LoadFile")
                          }
                        >
                          {traslate("LoadFile")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Add New Column Modal */}
            <Modal
              title={traslate("AttributeANewMappableColumn")}
              className="datamapping__modal"
              visible={visible2}
              // onOk={() => setVisible2(false)}
              onCancel={() => handleCloseAddNewColumnModal()}
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => handleUpdateDealerColumn()}
                >
                  {traslate("Save")}
                </Button>,
              ]}
            >
              <div className="form__fields row border-0 p-0">
                <div className="col-lg-12">
                  <span> {traslate("AddASingleNewColumnHeader.")} </span>
                </div>
                <div className="col-lg-12 mt-3">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="input__block">
                        <input
                          type="text"
                          onChange={(e) => {
                            setNewColumnName(e.target.value);
                            setErrorNewColumnName(null);
                          }}
                          value={newColumnName}
                          placeholder="Add Attribute"
                        />
                        {errorNewColumnName ? (
                          <span className="error-color-red">
                            {errorNewColumnName}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input__block">
                        <button
                          className="primary-btn"
                          onClick={handleSaveNewDealerColumn}
                        >
                          {traslate("AddNew")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mt-2">
                  <Table
                    className="Addnewmap__tbl"
                    columns={[
                      {
                        title: traslate("ColumnID"),
                        dataIndex: "columnId",
                      },
                      {
                        title: traslate("ColumnName"),
                        dataIndex: "columnName",
                        width: 250,
                        render: (item, row) => {
                          return (
                            <React.Fragment>
                              <div>
                                {row.columnId == editSelectedDealerColumn ? (
                                  <input
                                    type={"text"}
                                    value={item}
                                    onChange={(e) => {
                                      handleEditDealerColumnValue(
                                        row.columnId,
                                        e
                                      );
                                    }}
                                  />
                                ) : (
                                  <span>{item}</span>
                                )}
                              </div>
                            </React.Fragment>
                          );
                        },
                      },
                      {
                        title: traslate("Action"),
                        render: (item, row) => {
                          return (
                            <div className="action__btns">
                              <div className="image__block">
                                <img
                                  src={Edit_icon}
                                  onClick={() =>
                                    handleEditDealerColumn(row.columnId)
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                          );
                        },
                      },
                    ]}
                    dataSource={dealerColumnList}
                    scroll={{ y: 300 }}
                    pagination={false}
                    loading={loadingDealerColumnList}
                  />
                </div>
              </div>
            </Modal>
            {/* Image Upload Setting Modal */}
            <Modal
              title={traslate("ImageUploadSettings")}
              className="datamapping__modal"
              visible={imageUploadSetting}
              onCancel={() => handleCloseImageuploadSettingModal()}
              footer={[
                <Button onClick={() => handleCloseImageuploadSettingModal()}>
                  {traslate("Cancel")}
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={submitLoading}
                  onClick={() => handleSaveImagePathSettings()}
                >
                  {traslate("Save")}
                </Button>,
              ]}
            >
              <div className="form__fields row border-0 p-0">
                <div className="col-lg-12 mt-3">
                  <div className="row">
                    <div className="col-md-6">
                      <label> {traslate("ImportMultiple")} </label>
                    </div>
                    <div className="col-md-6">
                      <Radio.Group
                        value={importMultiple}
                        onChange={(e) => setImportMultiple(e.target.value)}
                      >
                        <Radio value={true}> {traslate("True")} </Radio>
                        <Radio value={false}> {traslate("False")} </Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label> {traslate("SelectDelimiter")} </label>
                    </div>
                    <div className="col-md-6">
                      <input
                        type={"text"}
                        value={delimiterImages}
                        onChange={(e) => {
                          setDelimiterImages(e.target.value);
                          setErrorDelimiterImages(null);
                        }}
                      />
                      <Tooltip
                        placement="right"
                        title={traslate("(|(pipesign),(comma))UsethissingleDelimitertoseparateImages.")}
                      >
                        <img src={UnionIcon} alt="" />
                      </Tooltip>
                      {errorDelimiterImages ? (
                        <span className="error-color-red">
                          {errorDelimiterImages}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Price Type Modal */}
            <Modal
              title={traslate("AddPriceTypeOverride")}
              className="datamapping__modal"
              visible={priceTypeModal}
              onCancel={() => handleClosePriceTypeModal()}
              footer={[
                <Button onClick={() => handleClosePriceTypeModal()}>
                  {traslate("Cancel")}
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={submitLoading}
                  onClick={() => handleManageJewelryPriceTypeColumn()}
                >
                  {traslate("Save")}
                </Button>,
              ]}
            >
              <div className="form__fields row border-0 p-0">
                <div className="col-lg-12 mt-3">
                  <div className="row">
                    <div className="col-md-4">
                      <label> {traslate("PriceType")} </label>
                    </div>
                    <div className="col-md-8">
                      <Select
                        style={{ width: "100%" }}
                        showSearch
                        // className="border__grey"
                        placeholder="Price Type"
                        name="availability"
                        optionFilterProp="children"
                        onChange={(e) => setPriceType(e)}
                        value={priceType}
                      >
                        <Option value="1"> {traslate("EnforceMSRP")} </Option>
                        <Option value="2">
                        {traslate("SuggestedRetailPrice&AllowRetailMarkups")}
                        </Option>
                        <Option value="3"> {traslate("AllowRetailMarkups")} </Option>
                        <Option value="4"> {traslate("CallForPrice")} </Option>
                        <Option value="5"> {traslate("PricesStartingat")} </Option>
                      </Select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-8">
                      <Checkbox
                        onChange={(e) => {
                          setAllowOverride(e.target.checked);
                        }}
                        value={allowOverride}
                      >
                        {traslate("Allowpricetypeoverride")}
                      </Checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            {/* Conformation Modal */}
            <Modal
              className="datamapping__modal"
              width={"55%"}
              visible={uploadMappingModal}
              onCancel={() => handleCloseUploadMappingModal()}
              footer={[
                <Button
                  loading={submitLoading}
                  type="primary"
                  onClick={() => handleUploadSheet()}
                >
                  {traslate("ResetTheMap")}
                </Button>,
                <Button onClick={() => handleCloseUploadMappingModal()}>
                  {traslate("Yikes...GoBack")}
                </Button>,
              ]}
            >
              <div className="form__fields row border-0 p-0">
                <div className="col-lg-12" style={{ textAlign: "center" }}>
                  <br />
                  <h3> {traslate("AreyoursureyouwanttoresettheDataMap?")} </h3>
                  <h4>
                    <b> {traslate("THISCANNOTBEUNDONE")} ... {traslate("THEREISNOUNDO")} </b>
                  </h4>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataMaping;
