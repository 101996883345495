import React, { useState } from "react";
import { Input, Row, Col, Select, Checkbox, Button, Switch } from "antd";

const PendantBuilderShoppingIntegrations = () => {
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };
  return (
    <React.Fragment>
      <div className="shopintgrate__section">
        <div className="form__fields">
          <div className="col-md-12">
            <h4 className="workarea__heading mt-0 mb-0">Time pieces</h4>
          </div>

          <div className="col-lg-12 mt-5">
            <a href="#" className="link__txt">
              Click Here for GFLINK ECommerce Link Administration
            </a>

            <ul className="mt-4">
              <li>
                <a href="#" className="link__txt">
                  View/Update Your Online Dealer Inventory List
                </a>
              </li>
              <li>
                <a href="#" className="link__txt">
                  Change Inventory Mark-Up Values for Markup BY PRICE{" "}
                  <span className="text-secondary">
                    (e.g., $500.01-$1000.00, $1000.01-$1500.00, etc.)
                  </span>
                </a>
              </li>
              <li>
                <a href="#" className="link__txt">
                  Update Search Page Results Appearance
                </a>
              </li>
              <li>
                <a href="#" className="link__txt">
                  Update Search Page Results Preferences{" "}
                  <span className="text-secondary">(For Advanced Users)</span>
                </a>
              </li>
              <li>
                <a href="#" className="link__txt">
                  Change Your Shipping Charge
                </a>
              </li>
            </ul>

            <p className="headred__txt">Your GFLINK Addresses are:</p>

            <p>
              <span className="headred__txt">ADMIN NOTE :</span> Your GFLink
              addresses MUST contain the correct domain name,
              http://platform.stage.jewelcloud.com. Using
              http://platform.stage.jewelcloud.com or any variation of
              gemfind.com is NOT correct and no longer functions. Please make
              sure your GFWatchLink URL is correct. Use the URLs below. The
              links below display your inventory "
              <a href="#" className="link__txt">
                Marked Up
              </a>
              " a % determined by the Watch'S PRICE.
            </p>

            <p className="mt-2">
              <a href="#" className="link__txt">
                http://platform.stage.jewelcloud.com/dealerid_display/watchlink2.aspx?DealerLink=720{" "}
                <span className="text-secondary">(Search Only)</span>
              </a>
            </p>

            <h4 className="subheading mt-4">Watch Configurator</h4>

            <p>
              <a href="#" className="link__txt">
                http://watches.gemfind.net/default.asp?DealerID=720
              </a>
            </p>

            <p>
              Procedure to implement Watch Configurator: To integrate the Watch
              Configurator directly into one of your web pages on your website,
              you will need to follow the general procedure below. This
              procedure will vary depending upon the technology your website
              uses, and your web developer will need to make adjustments
              accordingly. This will require custom website development work on
              your end. You basically have two choices when it comes to
              seamlessly including Watch Configurator in your website: FRAMEs or
              IFRAMEs.
            </p>

            <ul className="mt-4">
              <li>
                FRAMEs: If your site uses FRAMEs in a FRAMESET, then the Watch
                Configurator may easily be included as one of the FRAME
                contents. This is one of the simplest methods of embedding
                results, however it is not as attractive as the other methods
                and may not be a feasible option for you if you do not already
                use FRAMEs. Scrollbars may be prgrammatically eliminated for a
                cleaner look.
              </li>
              <li>
                IFRAMEs / LAYERs: Define a floating IFRAME (include LAYER coding
                to accomodate Netscape) and place it on your page where you want
                the Watch Configurator to apear. This will look better than a
                FRAME defined as part of a FRAMESET. Some attention to the size
                of the IFRAME must be paid. The IFRAME window is not
                programmatically 'resizable' once it has been defined on a page,
                therefore you must create the IFRAME with a HEIGHT and WIDTH
                that will accommodate the entire contents of the Watch
                Configurator. The number of results/rows returned by the Watch
                Configurator is constant and will ensure a constant height (and
                fit) for your IFRAME window.
              </li>
            </ul>

            <h4 className="subheading mt-4">
              Search by GEMFIND INVENTORY ID #:
            </h4>

            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-3">
                  <div className="input__block">
                    <label>Enter GEMFIND ID # and CLICK 'GO'</label>
                    <input type="text" placeholder="Enter GEMFIND ID" />
                  </div>
                </div>
                <div className="col-lg-1 align-self-end">
                  <div className="input__block">
                    <button className="primary-btn w-100">Go</button>
                  </div>
                </div>
              </div>
            </div>

            <h4 className="subheading mt-4">Search by Dealer's Inventory #:</h4>

            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-3">
                  <div className="input__block">
                    <label>Enter Dealer Inventory # and CLICK 'GO'</label>
                    <input type="text" placeholder="Enter GEMFIND ID" />
                  </div>
                </div>

                <div className="col-lg-3 align-self-end">
                  <div className="input__block">
                    <label className=""></label>
                    <Select
                      showSearch
                      className="border__grey"
                      placeholder="A & M Diamonds"
                      optionFilterProp="children"
                      onChange={onChange}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="jack">A & M Diamonds</Option>
                      <Option value="lucy">A & W Gems</Option>
                      <Option value="tom">A & F Gems</Option>
                    </Select>
                  </div>
                </div>
                <div className="col-lg-1 align-self-end">
                  <div className="input__block">
                    <button className="primary-btn w-100">Go</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PendantBuilderShoppingIntegrations;
