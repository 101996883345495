import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import logoImg from "../../assets/images/logo-img.png";
import logoSmallImg from "../../assets/images/logo-small.png";
import { Menu } from "antd";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import helpIcon from "../../assets/images/icons/help-icon.svg";
import supportIcon from "../../assets/images/icons/support-icon.png";
import ideaIcon from "../../assets/images/icons/idea-icon.png";
import videoIcon from "../../assets/images/icons/video-icon.png";
import CatalogJewelryDataService from "../../services/catalog-jewelry.service";
import traslate from "./../../i18n/translate";
import { BlobToDownloadFile } from "../../helper/commanFunction";

// sidemenu icons

import ToolsIcon from "../../assets/images/sidemenu-icons/tools.svg";
import SettingIcon from "../../assets/images/sidemenu-icons/settings.svg";
import CampaignIcon from "../../assets/images/sidemenu-icons/campaigns.svg";
import ReportIcon1 from "../../assets/images/sidemenu-icons/report1.svg";
import ReportIcon from "../../assets/images/sidemenu-icons/Report.svg";
import DaimondReport from "../../assets/images/sidemenu-icons/diamond-report.svg";
import JewelryReport from "../../assets/images/sidemenu-icons/jewelry-report.svg";
import SearchFeature from "../../assets/images/sidemenu-icons/search-feature.svg";
import PurchaseOrder from "../../assets/images/sidemenu-icons/purchase-order.svg";
import MarketingIcon from "../../assets/images/sidemenu-icons/marketing.svg";
import AppsIcon from "../../assets/images/sidemenu-icons/apps.svg";
import CommunityIcon from "../../assets/images/sidemenu-icons/community.svg";
import CatalogIcon from "../../assets/images/sidemenu-icons/catalog.svg";
import ContactsIcon from "../../assets/images/sidemenu-icons/contacts.svg";
import RetailerIcon from "../../assets/images/sidemenu-icons/retailer.svg";

const { SubMenu } = Menu;

// import chevronDown from "../../assets/images/icons/chevron-down.svg"

const Sidemenu = (props) => {
  const { handleLink } = props;
  const [submenu, setSubmenu] = useState([]);
  const [selectedmenu, setSelectedmenu] = useState([]);
  const [dealerId, setDealerId] = useState(0);
  const [loginUser, setLoginUser] = useState({});

  const navigate = useNavigate();

  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setDealerId(loginDetials.responseData.dealerId);
        setLoginUser(loginDetials.responseData);
      }
    }
  }, []);
  useEffect(() => {
    handleSetmenu();
  }, [props]);
  useEffect(() => {
    handlekey(splitLocation[1]);
  }, [pathname]);

  const handleSetmenu = () => {
    if (props.checkLink == "Admin") {
      setSubmenu(["Tools"]);
      // setSelectedmenu(["administrationTools"]);
      navigate(window.location.pathname);
      setSelectedmenu([`${window.location.pathname.replace("/", "")}`]);
    }
  };

  const handlekey = (key) => {
    setSelectedmenu([`${key}`]);
  };
  const handleJewelryDownloadProdctFile = () => {
    try {
      // setLoading(true);
      let inputData = {
        dealerId: dealerId,
        jewelryUploads: 0,
        jewelryLink: 0,
      };
      CatalogJewelryDataService.DownloadProductFile(inputData)
        .then((response) => {
          var filename =
            loginUser.dealerCompany +
            "(" +
            loginUser.dealerId +
            ")" +
            new Date().toJSON().slice(0, 10).replace(/-/g, "") +
            ".csv";

          BlobToDownloadFile(response.data, filename);
        })
        .catch((error) => {
          // setLoading(false);
          console.log(error);
        });
    } catch (error) {
      // setLoading(false);
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <div className="sidemenu__section">
        <div className="logo__section">
          <img src={logoImg} className="logobig" />
          <img src={logoSmallImg} className="logosmall" />
        </div>
        <div className="main__menu">
          <Menu
            mode="inline"
            theme="dark"
            inlineCollapsed={props.toggle}
            selectedKeys={selectedmenu}
            openKeys={submenu}
            onOpenChange={(openKeys) => {
              setSubmenu(openKeys);
            }}
          >
            {/* Admin tabs for side menu starts  */}
            {props.checkLink == "Admin" && (
              <React.Fragment>
                <SubMenu
                  key="Tools"
                  icon={<img src={ToolsIcon} />}
                  title={traslate("Tools")}
                >
                  <Menu.Item key="administrationTools">
                    <NavLink
                      to="/administrationTools"
                      onClick={() => handlekey("administrationTools")}
                    >
                      <label>{traslate("AdministrationTools")}</label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="applicationPermissions">
                    <NavLink
                      to="/applicationPermissions"
                      onClick={() => handlekey("applicationPermissions")}
                    >
                      <label>{traslate("ApplicationPermission")}</label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="reportPermissions">
                    <NavLink
                      to="/reportPermissions"
                      onClick={() => handlekey("reportPermissions")}
                    >
                      <label>{traslate("ReportPermissions")}</label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="clientDataMapping">
                    <NavLink
                      to="/clientDataMapping"
                      onClick={() => handlekey("clientDataMapping")}
                    >
                      <label>{traslate("ClientDataMaps")}</label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="clientStatus">
                    <NavLink
                      to="/clientStatus"
                      onClick={() => handlekey("clientStatus")}
                    >
                      <label className="">{traslate("ClientStatus")}</label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="createNewClient">
                    <NavLink
                      to="/createNewClient"
                      onClick={() => handlekey("createNewClient")}
                    >
                      <label className="">{traslate("CreateNewClient")}</label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="diamondRapNetPermission">
                    <NavLink
                      to="/diamondRapNetPermission"
                      onClick={() => handlekey("diamondRapNetPermission")}
                    >
                      <label className="">
                        {traslate("3rdPartyDiamondImports")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="addNewRetailerToCore">
                    <NavLink
                      to="/addNewRetailerToCore"
                      onClick={() => handlekey("addNewRetailerToCore")}
                    >
                      <label className="">{traslate("SOLRCoreClient")}</label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="vendorRating">
                    <NavLink
                      to="/vendorRating"
                      onClick={() => handlekey("vendorRating")}
                    >
                      <label className="">{traslate("VendorRating")}</label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="limit">
                    <NavLink to="/limit" onClick={() => handlekey("limit")}>
                      <label className="">{traslate("Limits")}</label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="legacy">
                    <NavLink to="/legacy" onClick={() => handlekey("legacy")}>
                      <label>{traslate("Legacy")}</label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="consoleUpload">
                    <NavLink
                      to="/consoleUpload"
                      onClick={() => handlekey("consoleUpload")}
                    >
                      <label className="">{traslate("ConsoleUpload")}</label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="magentoMapping">
                    <NavLink
                      to="/magentoMapping"
                      onClick={() => handlekey("magentoMapping")}
                    >
                      <label className="">
                        {traslate("OpenSourceMapping")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="pendingSync">
                    <NavLink
                      to="/pendingSync"
                      onClick={() => handlekey("pendingSync")}
                    >
                      <label className="">{traslate("PendingSync")}</label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="transferZZGemfind">
                    <NavLink
                      to="/transferZZGemfind"
                      onClick={() => handlekey("transferZZGemfind")}
                    >
                      <label>{traslate("TransferZZ.Gemfind")}</label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="loginPreferences">
                    <NavLink
                      to="/loginPreferences"
                      onClick={() => handlekey("loginPreferences")}
                    >
                      <label>{traslate("LoginPreferences")}</label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="retailerlocator">
                    <NavLink
                      to="/retailerlocator"
                      onClick={() => handlekey("retailerlocator")}
                    >
                      <label>{traslate("RetailerLocator")}</label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="blockDesignersForDemo">
                    <NavLink
                      to="/blockDesignersForDemo"
                      onClick={() => handlekey("blockDesignersForDemo")}
                    >
                      <label>{traslate("BlockDesignersForDemo")}</label>
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="Settings"
                  icon={<img src={SettingIcon} />}
                  title={traslate("Settings")}
                >
                  <Menu.Item key="permissions">
                    <NavLink
                      to="/permissions"
                      onClick={() => handlekey("permissions")}
                    >
                      <label className="">{traslate("Permissions")}</label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="emailTemplate">
                    <NavLink
                      to="/emailTemplate"
                      onClick={() => handlekey("emailTemplate")}
                    >
                      <label className="">{traslate("EmailTemplate")}</label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="dataMaps">
                    <NavLink
                      to="/dataMaps"
                      onClick={() => handlekey("dataMaps")}
                    >
                      <label className="">{traslate("DataMaps")}</label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="metalMarket">
                    <NavLink
                      to="/metalMarket"
                      onClick={() => handlekey("metalMarket")}
                    >
                      <label className="">{traslate("MetalMarket")}</label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="tradeShows">
                    <NavLink
                      to="/tradeShows"
                      onClick={() => handlekey("tradeShows")}
                    >
                      <label className="">{traslate("TradeShows")}</label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="addcolor">
                    <NavLink
                      to="/addcolor"
                      onClick={() => handlekey("addcolor")}
                    >
                      <label className="">{traslate("AddColor")}</label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="ApiAccessToken">
                    <NavLink
                      to="/ApiAccessToken"
                      onClick={() => handlekey("ApiAccessToken")}
                    >
                      <label className="">{traslate("ApiAccessToken")}</label>
                    </NavLink>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="Campaigns"
                  icon={<img src={CampaignIcon} />}
                  title={traslate("Campaigns")}
                >
                  <Menu.Item key="mailingLists">
                    <NavLink
                      to="/mailingLists"
                      onClick={() => handlekey("mailingLists")}
                    >
                      <label className="menu__label">
                        {traslate("MailingLists")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="campaignData">
                    <NavLink
                      to="/campaignData"
                      onClick={() => handlekey("campaignData")}
                    >
                      <label className="menu__label">
                        {traslate("Campaigns")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="composeMail">
                    <NavLink
                      to="/composeMail"
                      onClick={() => handlekey("composeMail")}
                    >
                      <label className="menu__label">
                        {traslate("Compose")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="report"
                  icon={<img src={ReportIcon1} />}
                  title={traslate("Report")}
                >
                  <Menu.Item key="globalEvents">
                    <NavLink to="/globalEvents">
                      <label className="menu__label">
                        {traslate("GlobalEvents")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="sync">
                    <NavLink to="/sync">
                      <label className="menu__label">{traslate("Syncs")}</label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="toolsreports">
                    <NavLink to="/toolsreports">
                      <label className="menu__label">
                        {traslate("ToolsReports")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="clientsReports">
                    <NavLink to="/clientsReports">
                      <label className="menu__label">
                        {traslate("ClientsReports")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="">
                    <NavLink to="/#">
                      <label className="menu__label">
                        {traslate("PermissionReports")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="dataRelated">
                    <NavLink to="/dataRelated">
                      <label className="menu__label">
                        {traslate("DataRelated")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="jewelryLogReports">
                    <NavLink to="/jewelryLogReports">
                      <label className="menu__label">
                        {traslate("JewelryLogReports")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="diamondReport"
                  icon={<img src={DaimondReport} />}
                  title={traslate("DiamondReport")}
                >
                  <Menu.Item key="diamondClicks">
                    <NavLink
                      to="/diamondClicks"
                      onClick={() => handlekey("diamondClicks")}
                    >
                      <label className="menu__label">
                        {traslate("DiamondClicks")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="extendedDiamondChartReports">
                    <NavLink
                      to="/extendedDiamondChartReports"
                      onClick={() => handlekey("extendedDiamondChartReports")}
                    >
                      <label className="menu__label">
                        {traslate("ExtendedDiamondChart")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="diamondClarityReport">
                    <NavLink
                      to="/diamondClarityReport"
                      onClick={() => handlekey("diamondClarityReport")}
                    >
                      <label className="menu__label">
                        {traslate("DiamondClarityReport")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="diamondColor">
                    <NavLink
                      to="/diamondColor"
                      onClick={() => handlekey("diamondColor")}
                    >
                      <label className="menu__label">
                        {traslate("DiamondColorReport")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="diamondCutReport">
                    <NavLink
                      to="/diamondCutReport"
                      onClick={() => handlekey("diamondCutReport")}
                    >
                      <label className="menu__label">
                        {traslate("DiamondCutReport")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="diamondShapeReport">
                    <NavLink
                      to="/diamondShapeReport"
                      onClick={() => handlekey("diamondShapeReport")}
                    >
                      <label className="menu__label">
                        {traslate("DiamondShapeReport")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="diamondCertificateReport">
                    <NavLink
                      to="/diamondCertificateReport"
                      onClick={() => handlekey("diamondCertificateReport")}
                    >
                      <label className="menu__label">
                        {traslate("DiamondCertificateReport")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="diamondSizeReport">
                    <NavLink
                      to="/diamondSizeReport"
                      onClick={() => handlekey("diamondSizeReport")}
                    >
                      <label className="menu__label">
                        {traslate("DiamondSizeReport")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="jewelryReport"
                  icon={<img src={JewelryReport} />}
                  title={traslate("JewelryReport")}
                >
                  <Menu.Item key="jewelryChartReportAdmin">
                    <NavLink
                      to="/jewelryChartReportAdmin"
                      onClick={() => handlekey("jewelryChartReportAdmin")}
                    >
                      <label className="menu__label">
                        {traslate("JewelryChartReport")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="jewelryCategoryReportAdmin">
                    <NavLink
                      to="/jewelryCategoryReportAdmin"
                      onClick={() => handlekey("jewelryCategoryReportAdmin")}
                    >
                      <label className="menu__label">
                        {traslate("JewelryCategoryReport")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="jewelryMetalTypeReport">
                    <NavLink
                      to="/jewelryMetalTypeReport"
                      onClick={() => handlekey("jewelryMetalTypeReport")}
                    >
                      <label className="menu__label">
                        {traslate("JewelryMetalTypeReport")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="jewelryPriceReport">
                    <NavLink
                      to="/jewelryPriceReport"
                      onClick={() => handlekey("jewelryPriceReport")}
                    >
                      <label className="menu__label">
                        {traslate("JewelryPriceReport")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="jewelryRetailerClicksReport">
                    <NavLink
                      to="/jewelryRetailerClicksReport"
                      onClick={() => handlekey("jewelryRetailerClicksReport")}
                    >
                      <label className="menu__label">
                        {traslate("JewelryRetailerClicksReport")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="jewelryVendorClicksReport">
                    <NavLink
                      to="/jewelryVendorClicksReport"
                      onClick={() => handlekey("jewelryVendorClicksReport")}
                    >
                      <label className="menu__label">
                        {traslate("JewelryVendorClicksReport")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
              </React.Fragment>
            )}

            {/* Catalog  */}
            {props.checkLink == "Catalog" && (
              <SubMenu
                key="Catalog"
                icon={<img src={CatalogIcon} />}
                title={traslate("Catalog")}
              >
                <SubMenu key="diamond-sub" title={traslate("Diamond")}>
                  <Menu.Item key="viewMyMinedDiamond">
                    <NavLink
                      to="/viewMyMinedDiamond"
                      state={{ pageName: "Mined" }}
                      onClick={() => handlekey("viewMyMinedDiamond")}
                    >
                      <label className="menu__label">
                        {traslate("MinedDiamond")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="viewMyColorDiamond">
                    <NavLink
                      to="/viewMyColorDiamond"
                      onClick={() => handlekey("viewMyColorDiamond")}
                    >
                      <label className="menu__label">
                        {traslate("ColorDiamond")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="viewMyLabDiamond">
                    <NavLink
                      to="/viewMyLabDiamond"
                      onClick={() => handlekey("viewMyLabDiamond")}
                    >
                      <label className="menu__label">
                        {traslate("LabDiamond")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="diamonduploadHistory">
                    <NavLink
                      to="/diamonduploadHistory"
                      onClick={() => handlekey("diamonduploadHistory")}
                    >
                      <label className="menu__label">
                        {traslate("UploadHistory")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="addSignleDiamond">
                    <NavLink
                      to="/addSignleDiamond"
                      onClick={() => handlekey("addSignleDiamond")}
                    >
                      <label className="menu__label">
                        {traslate("AddIndividualDiamond")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="uploadDiamondFile">
                    <NavLink
                      to="/uploadDiamondFile"
                      onClick={() => handlekey("uploadDiamondFile")}
                    >
                      <label className="menu__label">
                        {traslate("UploadDiamondFile")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="diamondMapping">
                    <NavLink
                      to="/diamondMapping"
                      onClick={() => handlekey("diamondMapping")}
                    >
                      <label className="menu__label">
                        {traslate("DiamondMapping")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="ftpCredential">
                    <NavLink
                      to="/ftpCredential"
                      onClick={() => handlekey("ftpCredential")}
                    >
                      <label className="menu__label">
                        {traslate("ManageFTPCredentials")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key="DownloadProductFile"
                    onClick={handleJewelryDownloadProdctFile}
                  >
                    <NavLink to="#">
                      <label className="menu__label">
                        {traslate("DownloadProductFile")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
                <SubMenu key="Catalog-Jewelry" title={traslate("Jewelry")}>
                  <Menu.Item key="catalogList">
                    <NavLink
                      to="/catalogList"
                      onClick={() => handlekey("catalogList")}
                    >
                      <label className="menu__label">
                        {traslate("CatalogList")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="allProducts">
                    <NavLink
                      to="/allProducts"
                      onClick={() => handlekey("allProducts")}
                    >
                      <label className="menu__label">
                        {traslate("ViewMyProduct")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="addProducts">
                    <NavLink
                      to="/addProducts"
                      onClick={() => handlekey("addProducts")}
                    >
                      <label className="menu__label">
                        {traslate("AddProduct")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="uploadHistory">
                    <NavLink
                      to="/uploadHistory"
                      onClick={() => handlekey("uploadHistory")}
                    >
                      <label className="menu__label">
                        {traslate("UploadHistory")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="uploadProductFile">
                    <NavLink
                      to="/uploadProductFile"
                      onClick={() => handlekey("uploadProductFile")}
                    >
                      <label className="menu__label">
                        {traslate("UploadProductFile")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="manageCollection">
                    <NavLink
                      to="/manageCollection"
                      onClick={() => handlekey("manageCollection")}
                    >
                      <label className="menu__label">
                        {traslate("ManageCollection")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="ftpCredential">
                    <NavLink
                      to="/ftpCredential"
                      onClick={() => handlekey("ftpCredential")}
                    >
                      <label className="menu__label">
                        {traslate("ManageFTPCredentials")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item
                    key="JewelryDownloadProductFile"
                    onClick={handleJewelryDownloadProdctFile}
                  >
                    <NavLink to="#">
                      <label className="menu__label">
                        {traslate("DownloadProductFile")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="productMarkup">
                    <NavLink
                      to="/productMarkup"
                      onClick={() => handlekey("productMarkup")}
                    >
                      <label className="menu__label">
                        {traslate("ProductMarkup")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="addCollection">
                    <NavLink
                      to="/addCollection"
                      onClick={() => handlekey("addCollection")}
                    >
                      <label className="menu__label">
                        {traslate("AddCollection")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                  <SubMenu key="datamapping" title={traslate("DataMapping")}>
                    <Menu.Item key="Essential">
                      <NavLink
                        to={{
                          pathname: "/DataMaping",
                        }}
                        state={{ columnID: 14 }}
                        onClick={() => handlekey("Essential")}
                      >
                        <label className="menu__label">
                          {traslate("Essential")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="ItemIdentity">
                      <NavLink
                        to={{
                          pathname: "/DataMaping",
                        }}
                        state={{ columnID: 1 }}
                        onClick={() => handlekey("ItemIdentity")}
                      >
                        <label className="menu__label">
                          {traslate("ItemIdentity")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="ConfigurableProducts">
                      <NavLink
                        to={{
                          pathname: "/DataMaping",
                        }}
                        state={{ columnID: 2 }}
                        onClick={() => handlekey("ConfigurableProducts")}
                      >
                        <label className="menu__label">
                          {traslate("ConfigurableProducts")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="ItemDetails">
                      <NavLink
                        to={{
                          pathname: "/DataMaping",
                        }}
                        state={{ columnID: 3 }}
                        onClick={() => handlekey("ItemDetails")}
                      >
                        <label className="menu__label">
                          {traslate("ItemDetails")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="ItemPricing">
                      <NavLink
                        to={{
                          pathname: "/DataMaping",
                        }}
                        state={{ columnID: 4 }}
                        onClick={() => handlekey("ItemPricing")}
                      >
                        <label className="menu__label">
                          {traslate("ItemPricing")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="RelationalPricing">
                      <NavLink
                        to={{
                          pathname: "/DataMaping",
                        }}
                        state={{ columnID: 5 }}
                        onClick={() => handlekey("RelationalPricing")}
                      >
                        <label className="menu__label">
                          {traslate("RelationalPricing")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="CostBasisPricing">
                      <NavLink
                        to={{
                          pathname: "/DataMaping",
                        }}
                        state={{ columnID: 6 }}
                        onClick={() => handlekey("CostBasisPricing")}
                      >
                        <label className="menu__label">
                          {traslate("CostBasisPricing")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="Item-PromoSpecials">
                      <NavLink
                        to={{
                          pathname: "/DataMaping",
                        }}
                        state={{ columnID: 7 }}
                        onClick={() => handlekey("Item-PromoSpecials")}
                      >
                        <label className="menu__label">
                          {traslate("Item-PromoSpecials")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="ItemMedia">
                      <NavLink
                        to={{
                          pathname: "/DataMaping",
                        }}
                        state={{ columnID: 8 }}
                        onClick={() => handlekey("ItemMedia")}
                      >
                        <label className="menu__label">
                          {traslate("ItemMedia")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="ItemSizing">
                      <NavLink
                        to={{
                          pathname: "/DataMaping",
                        }}
                        state={{ columnID: 9 }}
                        onClick={() => handlekey("ItemSizing")}
                      >
                        <label className="menu__label">
                          {traslate("ItemSizing")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="GemstoneDetails">
                      <NavLink
                        to={{
                          pathname: "/DataMaping",
                        }}
                        state={{ columnID: 10 }}
                        onClick={() => handlekey("GemstoneDetails")}
                      >
                        <label className="menu__label">
                          {traslate("GemstoneDetails")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="Semi-Mounts&RingBuilder">
                      <NavLink
                        to={{
                          pathname: "/DataMaping",
                        }}
                        state={{ columnID: 11 }}
                        onClick={() => handlekey("Semi-Mounts&RingBuilder")}
                      >
                        <label className="menu__label">
                          {traslate("Semi-Mounts&RingBuilder")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="WatchDetails">
                      <NavLink
                        to={{
                          pathname: "/DataMaping",
                        }}
                        state={{ columnID: 12 }}
                        onClick={() => handlekey("WatchDetails")}
                      >
                        <label className="menu__label">
                          {traslate("WatchDetails")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="AdditionalDetails">
                      <NavLink
                        to={{
                          pathname: "/DataMaping",
                        }}
                        state={{ columnID: 13 }}
                        onClick={() => handlekey("AdditionalDetails")}
                      >
                        <label className="menu__label">
                          {traslate("AdditionalDetails")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>
                </SubMenu>
              </SubMenu>
            )}
            {/* Purchase Order Starts */}

            {props.checkLink == "PurchaseOrder" && (
              <React.Fragment>
                <SubMenu
                  key="PurchaseOrder"
                  icon={<img src={PurchaseOrder} />}
                  title={traslate("PurchaseOrder")}
                >
                  <Menu.Item key="purchaseOrder">
                    <NavLink
                      to="/purchaseOrder"
                      onClick={() => handlekey("purchaseOrder")}
                    >
                      <label className="menu__label">
                        {traslate("PurchaseOrder")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="purchaseOrderHistory">
                    <NavLink
                      to="/purchaseOrderHistory"
                      onClick={() => handlekey("purchaseOrderHistory")}
                    >
                      <label className="menu__label">
                        {traslate("PurchaseOrderHistory")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="updatePurchaseOrder">
                    <NavLink
                      to="/updatePurchaseOrder"
                      onClick={() => handlekey("updatePurchaseOrder")}
                    >
                      <label className="menu__label">
                        {traslate("UpdatePurchaseOrder")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
              </React.Fragment>
            )}
            {/* Purchase Order Ends */}
            {/* Marketing Starts */}

            {props.checkLink == "Marketing" && (
              <React.Fragment>
                <SubMenu
                  key="Marketing"
                  icon={<img src={MarketingIcon} />}
                  title={traslate("Marketing")}
                >
                  <Menu.Item key="uploadMarketingMaterial">
                    <NavLink
                      to="/uploadMarketingMaterial"
                      onClick={() => handlekey("uploadMarketingMaterial")}
                    >
                      <label className="menu__label">
                        {traslate("UploadMarketingMaterial")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="Appointment">
                    <NavLink
                      to="/appointment"
                      onClick={() => handlekey("Appointment")}
                    >
                      <label className="menu__label">
                        {traslate("Appointment")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
              </React.Fragment>
            )}
            {/* Marketing Ends */}
            {/* App */}
            {props.checkLink == "Apps" && (
              <React.Fragment>
                <SubMenu
                  key="Apps"
                  icon={<img src={AppsIcon} />}
                  title={traslate("Apps")}
                >
                  <SubMenu key="diamondsLink" title={traslate("DiamondLinks")}>
                    <Menu.Item key="applicationPreview">
                      <NavLink
                        to="/applicationPreview"
                        onClick={() => handlekey("applicationPreview")}
                      >
                        <label className="menu__label">
                          {traslate("ApplicationPreview")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="dealers">
                      <NavLink
                        to="/dealers"
                        onClick={() => handlekey("dealers")}
                      >
                        <label className="menu__label">
                          {traslate("Dealers")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="minedDiamondsMarkup">
                      <NavLink
                        to="/minedDiamondsMarkup"
                        onClick={() => handlekey("minedDiamondsMarkup")}
                      >
                        <label className="menu__label">
                          {traslate("MinedDiamondMarkup")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="fancyColorMarkup">
                      <NavLink
                        to="/fancyColorMarkup"
                        onClick={() => handlekey("fancyColorMarkup")}
                      >
                        <label className="menu__label">
                          {traslate("FancyColorMarkup")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="labGrowMarkup">
                      <NavLink
                        to="/labGrowMarkup"
                        onClick={() => handlekey("labGrowMarkup")}
                      >
                        <label className="menu__label">
                          {traslate("LabGrowMarkup")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="availableFilterSettings">
                      <NavLink
                        to="/availableFilterSettings"
                        onClick={() => handlekey("availableFilterSettings")}
                      >
                        <label className="menu__label">
                          {traslate("AvailableFilterSettings")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="initialFilterSettings">
                      <NavLink
                        to="/initialFilterSettings"
                        onClick={() => handlekey("initialFilterSettings")}
                      >
                        <label className="menu__label">
                          {traslate("InitialFilterSettings")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="options">
                      <NavLink
                        to="/options"
                        onClick={() => handlekey("options")}
                      >
                        <label className="menu__label">
                          {traslate("Options")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="shipping">
                      <NavLink
                        to="/shipping"
                        onClick={() => handlekey("shipping")}
                      >
                        <label className="menu__label">
                          {traslate("Shipping")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="navigation">
                      <NavLink
                        to="/navigation"
                        onClick={() => handlekey("navigation")}
                      >
                        <label className="menu__label">
                          {traslate("Navigation")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="diamondLinkLegacy">
                      <NavLink
                        to="/diamondLinkLegacy"
                        onClick={() => handlekey("diamondLinkLegacy")}
                      >
                        <label className="menu__label">
                          {traslate("Legacy")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="shoppingIntegrations">
                      <NavLink
                        to="/shoppingIntegrations"
                        onClick={() => handlekey("shoppingIntegrations")}
                      >
                        <label className="menu__label">
                          {traslate("ShoppingCartIntegration")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="diamondLinkApiEndPoint">
                      <NavLink
                        to="/diamondLinkApiEndPoint"
                        onClick={() => handlekey("diamondLinkApiEndPoint")}
                      >
                        <label className="menu__label">
                          {traslate("ApiEndPointAndAuthentication.")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu key="ringbuilder" title={traslate("RingBuilder")}>
                    <Menu.Item key="vendors">
                      <NavLink
                        to="/vendors"
                        onClick={() => handlekey("vendors")}
                      >
                        <label className="menu__label">
                          {traslate("Vendors")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="archiveVendors">
                      <NavLink
                        to="/archiveVendors"
                        onClick={() => handlekey("archiveVendors")}
                      >
                        <label className="menu__label">
                          {traslate("ArchiveVendors")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="ringBuilderManageVendors">
                      <NavLink
                        to="/ringBuilderManageVendors"
                        onClick={() => handlekey("ringBuilderManageVendors")}
                      >
                        <label className="menu__label">
                          {traslate("ManageVendors")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="markups">
                      <NavLink
                        to="/markups"
                        onClick={() => handlekey("markups")}
                      >
                        <label className="menu__label">
                          {traslate("DiamondMarkup")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="markups">
                      <NavLink
                        to="/ringBuilderSettingmarkups"
                        onClick={() => handlekey("markups")}
                      >
                        <label className="menu__label">
                          {traslate("SettingMarkups")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="settings">
                      <NavLink
                        to="/settings"
                        onClick={() => handlekey("settings")}
                      >
                        <label className="menu__label">
                          {traslate("Settings")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="ringBuilderOptions">
                      <NavLink
                        to="/ringBuilderOptions"
                        onClick={() => handlekey("ringBuilderOptions")}
                      >
                        <label className="menu__label">
                          {traslate("Options")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="ringbuilderNavigation">
                      <NavLink
                        to="/ringbuilderNavigation"
                        onClick={() => handlekey("ringbuilderNavigation")}
                      >
                        <label className="menu__label">
                          {traslate("Navigation")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="ringbuilderLegacy">
                      <NavLink
                        to="/ringbuilderLegacy"
                        onClick={() => handlekey("ringbuilderLegacy")}
                      >
                        <label className="menu__label">
                          {traslate("Legacy")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="ringBuilderEmailTemplate">
                      <NavLink
                        to="/ringBuilderEmailTemplate"
                        onClick={() => handlekey("ringBuilderEmailTemplate")}
                      >
                        <label className="menu__label">
                          {traslate("EmailTemplate")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="ringbuilderShoppingIntegrations">
                      <NavLink
                        to="/ringbuilderShoppingIntegrations"
                        onClick={() =>
                          handlekey("ringbuilderShoppingIntegrations")
                        }
                      >
                        <label className="menu__label">
                          {traslate("ShoppingCartIntegration")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="ringBuilderApiEndPoint">
                      <NavLink
                        to="/ringBuilderApiEndPoint"
                        onClick={() => handlekey("ringBuilderApiEndPoint")}
                      >
                        <label className="menu__label">
                          {traslate("ApiEndPointAndAuthentication.")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu key="masterlink" title={traslate("MasterLink")}>
                    <Menu.Item key="masterlinkLegacy">
                      <NavLink
                        to="/masterlinkLegacy"
                        onClick={() => handlekey("masterlinkLegacy")}
                      >
                        <label className="menu__label">
                          {traslate("Legacy")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="masterlinkOptions">
                      <NavLink
                        to="/masterlinkOptions"
                        onClick={() => handlekey("masterlinkOptions")}
                      >
                        <label className="menu__label">
                          {traslate("Options")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="masterlinkShipping">
                      <NavLink
                        to="/masterlinkShipping"
                        onClick={() => handlekey("masterlinkShipping")}
                      >
                        <label className="menu__label">
                          {traslate("Shipping")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="masterlinkEmailTemplate">
                      <NavLink
                        to="/masterlinkEmailTemplate"
                        onClick={() => handlekey("masterlinkEmailTemplate")}
                      >
                        <label className="menu__label">
                          {traslate("E-mailTemplates")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="masterlinkShoppingIntegration">
                      <NavLink
                        to="/masterlinkShoppingIntegration"
                        onClick={() =>
                          handlekey("masterlinkShoppingIntegration")
                        }
                      >
                        <label className="menu__label">
                          {traslate("ShoppingCartIntegration")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu key="studbuilder" title={traslate("StudBuilder")}>
                    <Menu.Item key="studBuilderApplicationPreview">
                      <NavLink
                        to="/studBuilderApplicationPreview"
                        onClick={() =>
                          handlekey("studBuilderApplicationPreview")
                        }
                      >
                        <label className="menu__label">
                          {traslate("ApplicationPreview")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="studBuildermanageVendors">
                      <NavLink
                        to="/studBuildermanageVendors"
                        onClick={() => handlekey("studBuildermanageVendors")}
                      >
                        <label className="menu__label">
                          {traslate("ManageVendors")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="studBuilderMarkup">
                      <NavLink
                        to="/studBuilderMarkup"
                        onClick={() => handlekey("studBuilderMarkup")}
                      >
                        <label className="menu__label">
                          {traslate("SettingMarkup")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="studBuilderDaimondMarkup">
                      <NavLink
                        to="/studBuilderDaimondMarkup"
                        onClick={() => handlekey("studBuilderDaimondMarkup")}
                      >
                        <label className="menu__label">
                          {traslate("DiamondMarkup")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="studBuilderShoppingIntegrations">
                      <NavLink to="/studBuilderShoppingIntegrations">
                        <label className="menu__label">
                          {traslate("ShoppingCartIntegrations")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="studBuilderOptions">
                      <NavLink to="/studBuilderOptions">
                        <label className="menu__label">
                          {traslate("Options")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="studBuilderSettings">
                      <NavLink to="/studBuilderSettings">
                        <label className="menu__label">
                          {traslate("Settings")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="studBuilderEmailTemplate">
                      <NavLink
                        to="/studBuilderEmailTemplate"
                        onClick={() => handlekey("studBuilderEmailTemplate")}
                      >
                        <label className="menu__label">
                          {traslate("EmailTemplate")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu key="WebsiteSync" title={traslate("WebsiteSync")}>
                    <Menu.Item key="syncHistory">
                      <NavLink to="/syncHistory">
                        <label className="menu__label">
                          {traslate("SyncHistory")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="outBoundMapping">
                      <NavLink to="/outBoundMapping">
                        <label className="menu__label">
                          {traslate("OutBoundMapping")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="ftpDetails">
                      <NavLink to="/ftpDetails">
                        <label className="menu__label">
                          {traslate("FTPDetails")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu
                    key="watchCustomizerMain"
                    title={traslate("WatchCustomizer")}
                  >
                    <Menu.Item key="watchCustomizer">
                      <NavLink to="/watchCustomizer">
                        <label className="menu__label">
                          {traslate("WatchCustomizer")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="watchCustomizerOptions">
                      <NavLink to="/watchCustomizerOptions">
                        <label className="menu__label">Options</label>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>

                  <Menu.Item key="timepieces">
                    <NavLink to="/timepieces">
                      <label className="menu__label">
                        {traslate("TimePieces")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <SubMenu
                    key="pendantbuilder"
                    title={traslate("PendantBuilder")}
                  >
                    <Menu.Item key="pendantBuilderApplicationPreview">
                      <NavLink
                        to="/pendantBuilderApplicationPreview"
                        onClick={() =>
                          handlekey("studBuilderApplicationPreview")
                        }
                      >
                        <label className="menu__label">
                          {traslate("ApplicationPreview")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="pendantBuilderManageVendors">
                      <NavLink
                        to="/pendantBuilderManageVendors"
                        onClick={() => handlekey("pendantBuilderManageVendors")}
                      >
                        <label className="menu__label">
                          {traslate("ManageVendors")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="pendantBuilderSettingMarkup">
                      <NavLink
                        to="/pendantBuilderSettingMarkup"
                        onClick={() => handlekey("pendantBuilderSettingMarkup")}
                      >
                        <label className="menu__label">
                          {traslate("SettingMarkup")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="pendantBuilderDiamondMarkup">
                      <NavLink
                        to="/pendantBuilderDiamondMarkup"
                        onClick={() => handlekey("pendantBuilderDiamondMarkup")}
                      >
                        <label className="menu__label">
                          {traslate("DiamondMarkup")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="pendantBuilderOptions">
                      <NavLink
                        to="/pendantBuilderOptions"
                        onClick={() => handlekey("pendantBuilderOptions")}
                      >
                        <label className="menu__label">
                          {traslate("Options")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="pendantBuilderSettings">
                      <NavLink
                        to="/pendantBuilderSettings"
                        onClick={() => handlekey("pendantBuilderSettings")}
                      >
                        <label className="menu__label">
                          {traslate("Settings")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="pendantBuilderEmailTemplate">
                      <NavLink
                        to="/pendantBuilderEmailTemplate"
                        onClick={() => handlekey("pendantBuilderEmailTemplate")}
                      >
                        <label className="menu__label">
                          {traslate("EmailTemplate")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="pendantBuilderNavigation">
                      <NavLink
                        to="/pendantBuilderNavigation"
                        onClick={() => handlekey("pendantBuilderNavigation")}
                      >
                        <label className="menu__label">
                          {traslate("Navigation")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="pendantBuilderShoppingIntegrations">
                      <NavLink
                        to="/pendantBuilderShoppingIntegrations"
                        onClick={() =>
                          handlekey("pendantBuilderShoppingIntegrations")
                        }
                      >
                        <label className="menu__label">
                          {traslate("ShoppingCartIntegration")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>
                </SubMenu>
              </React.Fragment>
            )}

            {/* Community */}
            {props.checkLink == "Community" && (
              <React.Fragment>
                <SubMenu
                  key="community"
                  icon={<img src={CommunityIcon} />}
                  title={traslate("Community")}
                >
                  <SubMenu
                    key="vendormanager"
                    title={traslate("VendorManager")}
                  >
                    <Menu.Item key="myvendors">
                      <NavLink
                        to="/myvendors"
                        onClick={() => handlekey("myvendors")}
                      >
                        <label className="menu__label">
                          {traslate("MyVendors")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="network">
                      <NavLink
                        to="/network"
                        onClick={() => handlekey("network")}
                      >
                        <label className="menu__label">
                          {traslate("Network")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="outOfNetwork">
                      <NavLink
                        to="/outOfNetwork"
                        onClick={() => handlekey("outOfNetwork")}
                      >
                        <label className="menu__label">
                          {traslate("OutOfNetwork")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="new">
                      <NavLink to="/new" onClick={() => handlekey("new")}>
                        <label className="menu__label">{traslate("New")}</label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="recentlyUpdated">
                      <NavLink
                        to="/recentlyUpdated"
                        onClick={() => handlekey("recentlyUpdated")}
                      >
                        <label className="menu__label">
                          {traslate("RecentlyUpdated")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="inviteyourvendor">
                      <NavLink
                        to="/inviteyourvendor"
                        onClick={() => handlekey("inviteyourvendor")}
                      >
                        <label className="menu__label">
                          {traslate("InviteYourVendor")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="vendormanagerpopular">
                      <NavLink
                        to="/vendorManagerPopular"
                        onClick={() => handlekey("vendormanagerpopular")}
                      >
                        <label className="menu__label">
                          {traslate("Popular")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="vendormanagerarchived">
                      <NavLink
                        to="/vendorManagerArchived"
                        onClick={() => handlekey("vendormanagerarchived")}
                      >
                        <label className="menu__label">
                          {traslate("Archived")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="vendormanagerPendingRequests">
                      <NavLink
                        to="/vendorManagerPendingRequests"
                        onClick={() =>
                          handlekey("vendormanagerPendingRequests")
                        }
                      >
                        <label className="menu__label">
                          {traslate("PendingRequests")}
                        </label>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>
                </SubMenu>
              </React.Fragment>
            )}
            {/* Retailers */}
            {props.checkLink == "Retailer" && (
              <React.Fragment>
                <SubMenu
                  key="Retailer"
                  icon={<img src={RetailerIcon} />}
                  title={traslate("Retailer")}
                >
                  <Menu.Item key="jewelryRetailerPermission">
                    <NavLink
                      to="/jewelryRetailerPermission"
                      onClick={() => handlekey("jewelryRetailerPermission")}
                    >
                      <label className="menu__label">
                        {traslate("JewelryRetailer&Permission")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="pendingJewelryRequest">
                    <NavLink
                      to="/pendingJewelryRequest"
                      onClick={() => handlekey("pendingJewelryRequest")}
                    >
                      <label className="menu__label">
                        {traslate("PendingJewelryRequest")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="diamondRetailerPermission">
                    <NavLink
                      to="/diamondRetailerPermission"
                      onClick={() => handlekey("diamondRetailerPermission")}
                    >
                      <label className="menu__label">
                        {traslate("DiamondRetailer&Permission")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="pendingDiamondRequest">
                    <NavLink
                      to="/pendingDiamondRequest"
                      onClick={() => handlekey("pendingDiamondRequest")}
                    >
                      <label className="menu__label">
                        {traslate("PendingDiamondRequest")}
                      </label>
                    </NavLink>
                  </Menu.Item>

                  {/* <Menu.Item key="ringBuilderRetailerPermission">
                    <NavLink
                      to="/ringBuilderRetailerPermission"
                      onClick={() => handlekey("ringBuilderRetailerPermission")}
                    >
                      <label className="menu__label">
                        {traslate("RingBuilderRetailer&Permission")}
                      </label>
                    </NavLink>
                  </Menu.Item> */}

                  {/* <Menu.Item key="pendingRingBuilderRequest">
                    <NavLink
                      to="/pendingRingBuilderRequest"
                      onClick={() => handlekey("pendingRingBuilderRequest")}
                    >
                      <label className="menu__label">
                        {traslate("PendingRingBuilderRequest")}
                      </label>
                    </NavLink>
                  </Menu.Item> */}

                  <Menu.Item key="retailerProfile">
                    <NavLink
                      to="/retailerProfile"
                      onClick={() => handlekey("retailerProfile")}
                    >
                      <label className="menu__label">
                        {traslate("RetailerProfile")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
              </React.Fragment>
            )}
            {props.checkLink == "Contact" && (
              <React.Fragment>
                <SubMenu
                  key="Contacts"
                  icon={<img src={ContactsIcon} />}
                  title="Contacts"
                >
                  <Menu.Item key="customer">
                    <NavLink
                      to="/customer"
                      onClick={() => handlekey("customer")}
                    >
                      <label className="menu__label">Customer</label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="conversation">
                    <NavLink
                      to="/conversation"
                      onClick={() => handlekey("conversation")}
                    >
                      <label className="menu__label">Conversation</label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="appointments">
                    <NavLink
                      to="/appointments"
                      onClick={() => handlekey("appointments")}
                    >
                      <label className="menu__label">Appointments</label>
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="">
                    {/* <NavLink to="/"> */}
                    <label className="menu__label">Export</label>
                    {/* </NavLink> */}
                  </Menu.Item>
                </SubMenu>
              </React.Fragment>
            )}
            {props.checkLink == "Report" && (
              <React.Fragment>
                <SubMenu
                  key="MainReport"
                  icon={<img src={ReportIcon} />}
                  title={traslate("Report")}
                >
                  <SubMenu key="Reportdiamond" title="Diamonds">
                    <Menu.Item key="diamondChartReportsMain">
                      <NavLink to="/diamondChartReportsMain">
                        <label className="menu__label">
                          Diamond Chart Reports
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="extendedDiamondChartReportsMain">
                      <NavLink to="/extendedDiamondChartReportsMain">
                        <label className="menu__label">
                          Extended Diamond Chart Reports
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="diamondClarityReportMain">
                      <NavLink to="/diamondClarityReportMain">
                        <label className="menu__label">
                          Diamond Clarity Report
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="diamondColorMain">
                      <NavLink to="/diamondColorMain">
                        <label className="menu__label">
                          Diamond Color Report
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="diamondCutGradeReportMain">
                      <NavLink to="/diamondCutGradeReportMain">
                        <label className="menu__label">
                          Diamond Cut Grade Report
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="diamondCertificateReportMain">
                      <NavLink to="/diamondCertificateReportMain">
                        <label className="menu__label">
                          Diamond Certificate Report
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="diamondShapeReportMain">
                      <NavLink to="/diamondShapeReportMain">
                        <label className="menu__label">
                          Diamond Shape Report
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="diamondSizeReportMain">
                      <NavLink to="/diamondSizeReportMain">
                        <label className="menu__label">
                          Diamond Size Report
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="locationActivity">
                      <NavLink to="/locationActivity">
                        <label className="menu__label">Location Activity</label>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu key="ReportJewelry" title={traslate("Jewelry")}>
                    <Menu.Item key="jewelryChartReport">
                      <NavLink to="/jewelryChartReport">
                        <label className="menu__label">
                          {traslate("JewelryChartReports")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="JewelryCategoryReport">
                      <NavLink to="/jewelryCategoryReport">
                        <label className="menu__label">
                          {traslate("JewelryCategoryReport")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="JewelryMetalTypeReportMain">
                      <NavLink to="/JewelryMetalTypeReportMain">
                        <label className="menu__label">
                          {traslate("JewelryMetalTypeReport")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="JewelryPriceReportMain">
                      <NavLink to="/JewelryPriceReportMain">
                        <label className="menu__label">
                          {traslate("JewelryPriceReport")}
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="jewelryRetailerClickReportMain">
                      <NavLink to="/jewelryRetailerClickReportMain">
                        <label className="menu__label">
                          Jewelry Retailer Click Report
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="jewelryVendorClickReportMain">
                      <NavLink to="/jewelryVendorClickReportMain">
                        <label className="menu__label">
                          Jewelry Vendor Click Report
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="jewelryLocationActivity">
                      <NavLink to="/jewelryLocationActivity">
                        <label className="menu__label">Location Activity</label>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu key="ReportGlobalDiamond" title="Global Diamond">
                    <Menu.Item key="globalExtendedDiamondChartReports">
                      <NavLink to="/globalExtendedDiamondChartReports">
                        <label className="menu__label">
                          Extended Diamond Chart Report
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="globalDiamondClarityReport">
                      <NavLink to="/globalDiamondClarityReport">
                        <label className="menu__label">
                          Diamond Clarity Report
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="globalDiamondColorReport">
                      <NavLink to="/globalDiamondColorReport">
                        <label className="menu__label">
                          Diamond Color Report
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="globalDiamondCutReport">
                      <NavLink to="/globalDiamondCutReport">
                        <label className="menu__label">
                          Diamond Cut Report
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="globalDiamondShapeReport">
                      <NavLink to="/globalDiamondShapeReport">
                        <label className="menu__label">
                          Diamond Shape Report
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="globalDiamondCertificateReport">
                      <NavLink to="/globalDiamondCertificateReport">
                        <label className="menu__label">
                          Diamond Certificate Report
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="globalDiamondSizeReport">
                      <NavLink to="/globalDiamondSizeReport">
                        <label className="menu__label">
                          Diamond Size Report
                        </label>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu key="ReportGlobalJewelry" title="Global Jewelry">
                    <Menu.Item key="gobalJewelryChartReports">
                      <NavLink to="/gobalJewelryChartReports">
                        <label className="menu__label">
                          Jewelry Chart Reports
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="globalJewelryCategoryReport">
                      <NavLink to="/globalJewelryCategoryReport">
                        <label className="menu__label">
                          Jewelry Category Reports
                        </label>
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item key="globalJewelryMetalTypeReport">
                      <NavLink to="/globalJewelryMetalTypeReport">
                        <label className="menu__label">
                          Jewelry Metal Type Reports
                        </label>
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>
                </SubMenu>
              </React.Fragment>
            )}

            {/* Search */}
            {props.checkLink == "Search" && (
              <React.Fragment>
                <SubMenu
                  key="searchFeature"
                  icon={<img src={SearchFeature} />}
                  title={traslate("SearchFeature")}
                >
                  <Menu.Item key="diamondSearch">
                    <NavLink
                      to="/diamondSearch"
                      onClick={() => handlekey("diamondSearch")}
                    >
                      <label className="menu__label">
                        {traslate("DiamondSearch")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="colorDiamondSearch">
                    <NavLink
                      to="/colorDiamondSearch"
                      onClick={() => handlekey("colorDiamondSearch")}
                    >
                      <label className="menu__label">
                        {traslate("ColorDiamondSearch")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="labDiamondSearch">
                    <NavLink
                      to="/labDiamondSearch"
                      onClick={() => handlekey("labDiamondSearch")}
                    >
                      <label className="menu__label">
                        {traslate("LabDiamondSearch")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="WatchesSearch">
                    <NavLink
                      to="/WatchesSearch"
                      onClick={() => handlekey("WatchesSearch")}
                    >
                      <label className="menu__label">
                        {traslate("WatchesSearch")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="JewelrySearch">
                    <NavLink
                      to="/JewelrySearch"
                      onClick={() => handlekey("JewelrySearch")}
                    >
                      <label className="menu__label">
                        {traslate("JewelrySearch")}
                      </label>
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
              </React.Fragment>
            )}
            {/* Common tabs for side menu starts  */}
            <SubMenu
              key="Help"
              className="help__menu"
              icon={<img src={helpIcon} />}
              title={traslate("Help")}
            >
              <Menu.Item key="25">
                <NavLink to="/">
                  <button className="menu__button">
                    <img src={supportIcon} />
                    <span>{traslate("ReportAnIssue")}</span>
                  </button>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="26">
                <NavLink to="/">
                  <button className="menu__button">
                    <img src={ideaIcon} />
                    <span>{traslate("SuggestANewFeature")}</span>
                  </button>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="27">
                <NavLink to="/">
                  <button className="menu__button">
                    <img src={videoIcon} />
                    <span>{traslate("WatchTrainingVideos")}</span>
                  </button>
                </NavLink>
              </Menu.Item>

              {/* Catalog Sample Starts */}
              {/* <Menu.Item key="">
                <NavLink to="/catalog">
                  <button className="menu__button">
                    <span>catalog</span>
                  </button>
                </NavLink>
              </Menu.Item> */}
              {/* Catalog Sample Ends */}

              {/* <Menu.Item key="productDetails">
                <NavLink to="/productDetails">
                  <button className="menu__button">
                    <span>Product Details</span>
                  </button>
                </NavLink>
              </Menu.Item> */}
            </SubMenu>
          </Menu>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Sidemenu);
