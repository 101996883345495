import React, { useEffect, useState } from "react";
import Header from "./header";
import Sidemenu from "./sidemenu";
import footerLogoImg from "../../assets/images/footer-logo.png";
import footerCompanyLogoImg from "../../assets/images/company-img.png";
import footerCompanyLogo1 from "../../assets/images/footerlogo1.png";
import footerCompanyLogo2 from "../../assets/images/footerlogo2.png";
import footerCompanyLogo3 from "../../assets/images/footerlogo3.png";
import footerCompanyLogo4 from "../../assets/images/footerlogo4.png";
import footerCompanyLogo5 from "../../assets/images/footerlogo5.png";
import traslate from "../../i18n/translate";

export const Layout = (props) => {
  const [toggle, setToggle] = useState(false);
  const [show, setShow] = useState(false);
  const [checkLink, setCheckLink] = useState("Admin");
  const [mobileMenuToggle, setMobileMenuToggle] = useState(true);
   
  const handleLink = (link, bool) => {
    setCheckLink(link);
  };

  const showTopNav = () => {
    setShow(!show);
  };

  const toggleCollapsed = () => {
    setToggle(!toggle);
  };

  const toggleMobileMenuCollapsed = () => {
    setMobileMenuToggle(!mobileMenuToggle);
  };
  return (
    <React.Fragment>
      <div
        className={
          mobileMenuToggle
            ? "main__section hideMobileMenu"
            : "main__section showMobileMenu"
        }
      >
        <div
          className={
            toggle ? "sidenav__section partialwidth" : "sidenav__section"
          }
        >
          <Sidemenu
            toggle={toggle}
            checkLink={checkLink}
            handleLink={handleLink}
            mobileMenuToggle={mobileMenuToggle}
            toggleMobileMenuCollapsed={toggleMobileMenuCollapsed}
          />
        </div>
        <div
          className={toggle ? "right__section partialwidth" : "right__section"}
        >
          <div className="header__section">
            <Header
              checkLink={checkLink}
              toggle={toggle}
              toggleCollapsed={toggleCollapsed}
              handleLink={handleLink}
              showTopNav={showTopNav}
              show={show}
            />
          </div>
          {/* <div  className="working__area">
            <div className="catalog__list d-none"> */}
          <div className="working__area">
            <div className="catalog__list d-none">
              <div className="catalog__topnav">
                <ul>
                  <li>
                    {" "}
                    <a href="#">
                    {traslate("Conversations")} <span>27</span>
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">
                    {traslate("Conversations")} <span>27</span>
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href="#">
                    {traslate("Conversations")} <span>27</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="content__area">{props.children}</div>
          </div>
          <div className="footer__section">
            <div className="left__block">
              <div className="footer__logo">
                <img src={footerLogoImg} />
              </div>
              <div className="footer__menu">
                <ul>
                  <li>
                    <a href="#">{traslate("TermsofService")}</a>
                  </li>
                  <li>
                    <a href="#">{traslate("PrivacyStatement")}</a>
                  </li>
                  <li>
                    <a href="#">{traslate("Copyright2021GemFind")}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="right__block">
              <ul>
                <li>
                  <a href="#">
                    <img src={footerCompanyLogo1} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={footerCompanyLogo2} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={footerCompanyLogo3} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={footerCompanyLogo4} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={footerCompanyLogo5} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
