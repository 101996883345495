export const SizingData = [
  {
    key: 1,
    size: "3",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 2,
    size: "3.25",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 3,
    size: "3.5",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 4,
    size: "3.75",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 5,
    size: "4.25",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 6,
    size: "4.5",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 7,
    size: "4.75",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 8,
    size: "5",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 9,
    size: "5.25",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 10,
    size: "5.5",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 11,
    size: "5.75",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 12,
    size: "6",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 13,
    size: "6.25",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 14,
    size: "6.75",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 15,
    size: "7",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 16,
    size: "7.25",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 17,
    size: "7.5",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 18,
    size: "8",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 19,
    size: "8.25",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 20,
    size: "8.5",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 21,
    size: "8.75",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 22,
    size: "9",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 23,
    size: "9.25",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 24,
    size: "9.5",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 25,
    size: "9.75",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 26,
    size: "10",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 27,
    size: "10.25",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 28,
    size: "10.5",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 29,
    size: "10.75",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 30,
    size: "10.75",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 31,
    size: "11",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 32,
    size: "11.25",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 33,
    size: "11.5",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 34,
    size: "11.75",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 35,
    size: "12",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 36,
    size: "12.25",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 37,
    size: "12.5",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 38,
    size: "12.75",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 39,
    size: "13",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 40,
    size: "13.25",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 41,
    size: "13.5",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 42,
    size: "13.75",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 43,
    size: "14",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 44,
    size: "14.25",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 45,
    size: "14.5",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 46,
    size: "14.5",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 47,
    size: "14.75",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 48,
    size: "15",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 49,
    size: "15.25",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 50,
    size: "15.5",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 51,
    size: "15.75",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 52,
    size: "16",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 53,
    size: "16.25",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 54,
    size: "16.5",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 55,
    size: "16.75",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 56,
    size: "17",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 57,
    size: "17.25",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 58,
    size: "17.25",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 59,
    size: "17.5",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 60,
    size: "17.75",
    priceType: "Fixed",
    additionalPrice: "",
  },
  {
    key: 61,
    size: "18",
    priceType: "Fixed",
    additionalPrice: "",
  },
];

export const RingbuilderData = [
  {
    key: "1",

    stoneShapes: "Round",
    stoneSizeMin: "",
    stoneSizeMax: "",
  },
  {
    key: "2",

    stoneShapes: "Princess",
    stoneSizeMin: "",
    stoneSizeMax: "",
  },
  {
    key: "3",

    stoneShapes: "Radiant",
    stoneSizeMin: "",
    stoneSizeMax: "",
  },
  {
    key: "4",

    stoneShapes: "Emerald Cut",
    stoneSizeMin: "",
    stoneSizeMax: "",
  },
  {
    key: "5",

    stoneShapes: "Pear",
    stoneSizeMin: "",
    stoneSizeMax: "",
  },
  {
    key: "6",

    stoneShapes: "Oval",
    stoneSizeMin: "",
    stoneSizeMax: "",
  },
  {
    key: "7",

    stoneShapes: "Marquise",
    stoneSizeMin: "",
    stoneSizeMax: "",
  },
  {
    key: "8",

    stoneShapes: "Asscher",
    stoneSizeMin: "",
    stoneSizeMax: "",
  },
  {
    key: "9",
    stoneShapes: "Cushion",
    stoneSizeMin: "",
    stoneSizeMax: "",
  },
  {
    key: "10",

    stoneShapes: "Heart",
    stoneSizeMin: "",
    stoneSizeMax: "",
  },
];
export const NavigationData = [
  {
    key:"1",
    data:"Loose Daimonds",
    TextBox:"",
    Dropdown:null,
  },
  {
    key:"2",
    data:"Our Signature Daimonds",
    TextBox:"",
    Dropdown:null,
  },
  {
    key:"3",
    data:"Best Value Daimonds",
    TextBox:"",
    Dropdown:null,
  },
  {
    key:"4",
    data:"Private Reserve Daimonds",
    TextBox:"",
    Dropdown:null,
  },
  {
    key:"5",
    data:"HeartStar Daimonds",
    TextBox:"",
    Dropdown:null,
  },

];
