import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table
} from "antd";

import Highlighter from "react-highlight-words";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";

import { Doughnut } from "react-chartjs-2";
import traslate from "../../../i18n/translate";

const JewelryMetalTypeReport = () => {
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;

  const style = <span>content not provided.</span>;

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };

  const columns = [
    {
      title: traslate("ProductImage"),
      dataIndex: "ProductImage",
      width: 120
    },
    {
      title: traslate("UserIPAddress"),
      dataIndex: "UserIPAddress",
      width: 120
    },
    {
      title: traslate("Vendor"),
      dataIndex: "Vendor",
      width: 120
    },
    {
      title: traslate("Retailer"),
      dataIndex: "Retailer",
      width: 120
    },
    {
      title: traslate("Collection"),
      dataIndex: "Collection",
      width: 120
    },
    {
      title: traslate("Category"),
      dataIndex: "Category",
      width: 120
    },
    {
      title: traslate("Date"),
      dataIndex: "Date",
      width: 120
    },
    {
      title: traslate("StyleNumber"),
      dataIndex: "StyleNumber",
      width: 120
    },
    {
      title: traslate("MetalType"),
      dataIndex: "MetalType",
      width: 120
    },
    {
      title: traslate("MetalColor"),
      dataIndex: "MetalColor",
      width: 120
    },
    {
      title: traslate("Price"),
      dataIndex: "Price",
      width: 120
    }
  ];

  const data = [
    {
      key: "1",
      ProductImage: <span></span>,
      UserIPAddress: <span></span>,
      Vendor: <span>RB</span>,
      Retailer: <span>GemFind</span>,
      Collection: <span>Solitaire</span>,
      Category: <span>Engagement Ring - Semi Mount</span>,
      Date: <span>01/01/2022</span>,
      StyleNumber: <span>F1626-6.5X4.5-18KW-1</span>,
      MetalType: <span>18K Gold</span>,
      MetalColor: <span>White</span>,
      Price: <span>Call For Price</span>
    }
  ];

  return (
    <React.Fragment>
      <div className="pendjewelreq__section">
        <div className="row form__fields magento__mapping">
          <div className="col-md-12">
            {/* <h4 className="workarea__heading">Jewelry Category Report</h4> */}
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
              {traslate("JewelryMetalTypeReport")}
              </h4>
              <button className="primary-btn">
                <i className="fa fa-download mr-1"></i>
                {traslate("DownloadCSV")}
              </button>
            </div>
          </div>
          <div className="col-md-3">
            <div className="input__block">
              <label> {traslate("FromDate")} </label>
              <input type="date" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="input__block">
              <label> {traslate("ToDate")} </label>
              <input type="date" />
            </div>
          </div>

          <div className="col-md-3">
              <div className="input__block">
                <label> {traslate("Vendor(s)")} </label>
                <Select
                  showSearch
                  className="border__grey"
                  placeholder="Select one or more vendors "
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="jack">Round to the nearest 1</Option>
                  <Option value="lucy">Round to the nearest 1</Option>
                  <Option value="tom">Round to the nearest 1</Option>
                </Select>
              </div>
            </div>

            <div className="col-md-3">
              <div className="input__block">
                <label> {traslate("Retailer(s)")} </label>
                <Select
                  showSearch
                  className="border__grey"
                  placeholder="Select one or more retailer "
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="jack">Round to the nearest 1</Option>
                  <Option value="lucy">Round to the nearest 1</Option>
                  <Option value="tom">Round to the nearest 1</Option>
                </Select>
              </div>
            </div>

          <div className="col-md-4">
            <div className="input__block">
              <button className="primary-btn mt-2"> {traslate("ApplyFilter")} </button>
            </div>
          </div>

          <div className="col-lg-12 col-md-6">
            <div className="input__block">
              <label>
                <b> {traslate("TotalJewelryClicks")} : 32</b>
                <Tooltip
                  placement="right"
                  title={traslate("Displaysthetotalnumberofjewelryclicksthroughthecurrentlyspecifieddaterangeabove")}
                >
                  <img src={UnionIcon} alt="" />
                </Tooltip>
              </label>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="chart__section">
              <Doughnut
                data={{
                  labels: [
                    "Red",
                    "Blue",
                    "Yellow",
                    "Green",
                    "Purple",
                    "Orange"
                  ],
                  datasets: [
                    {
                      label: "# of Votes",
                      data: [12, 19, 3, 5, 2, 3],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(54, 162, 235, 0.2)",
                        "rgba(255, 206, 86, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                        "rgba(153, 102, 255, 0.2)",
                        "rgba(255, 159, 64, 0.2)"
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                        "rgba(255, 206, 86, 1)",
                        "rgba(75, 192, 192, 1)",
                        "rgba(153, 102, 255, 1)",
                        "rgba(255, 159, 64, 1)"
                      ],
                      borderWidth: 1
                    }
                  ]
                }}
                width={300}
                height={300}
                options={{
                  maintainAspectRatio: false
                }}
              />
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <Table
              className="pendjewelreq__tbl"
              columns={columns}
              dataSource={data}
              scroll={{ x: 600 }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JewelryMetalTypeReport;
