import React, { useEffect, useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Modal,
  Spin,
  Form,
} from "antd";
import Main_bg from "../../../assets/images/main_bg.jpg";
import ResizeLogo from "../../../assets/images/ResizeLogo.jfif";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { useDispatch } from "react-redux";
import { setImpersonateData } from "../../../actions/login/login";
import LoginService from "../../../services/login.service";
import { NotificationManager } from "react-notifications";
import { BlobToDownloadFile } from "../../../helper/commanFunction.js";
import traslate from "../../../i18n/translate";
const { Option } = Select;

const Administrationtools = () => {
  const dispatch = useDispatch();
  const initialState = {
    clientTypeList: [
      {
        id: 1,
        value: null,
        label: "All",
      },
      {
        id: 2,
        value: "vendordiamond",
        label: "Diamond Dealers",
      },
      {
        id: 3,
        value: "retailer",
        label: "Retailers",
      },
      {
        id: 4,
        value: "vendor",
        label: "Vendors",
      },
    ],
    clientType: null,

    clientName: null,
    clientToolsData: {},
    userList: [],
    resetPasswordModal: false,
    viewProfileModal: false,
    viewProfileData: {},
    resetPasswordData: {
      dealerID: 0,
      name: "",
      id: "",
      userName: "",
      passwordHash: "",
      email: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    resetPasswordError: {},
  };
  const [state, setState] = useState(initialState);
  const [viewProfileModal, setViewProfileModal] = useState(
    initialState.viewProfileModal
  );
  const [Data, setData] = useState([]);
  const [resetPasswordModal, setResetPasswordModal] = useState(
    initialState.resetPasswordModal
  );
  const [clientNameList, setClientNameList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [showPassword,setShowPassword] = useState(false);

  useEffect(() => {
    handleGetUsersList();
  }, []);

  // show Password 

  const handlePassShowHide = ()=>{
    setShowPassword(!showPassword)

  }

  const handleGetUsersList = () => {
    let inputData = {
      clientType: state.clientType || "",
    };
    try {
      AdminToolsDataService.GetUsersList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          debugger;
          if (message == "Success") {
            setClientNameList(responseData);
          } else {
            setClientNameList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetUsersListByDealerId = () => {
    let inputData = {
      dealerID: state.clientName,
    };
    try {
      AdminToolsDataService.GetUsersListByDealerId(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              var tempState = state;
              tempState["clientToolsData"] = responseData[0];
              
              setState((prevState) => ({
                ...prevState,
                ...tempState,
                // clientToolsData:responseData[0],
              }));
            }
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleImporsonateToJewelCloud = () => {
    let inputData = {
      dealerID: state.clientName,
    };
    try {
      AdminToolsDataService.ImporsonateToJewelCloud(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            var tempState = state;
            debugger;
            tempState["userList"] = responseData;
            tempState["resetPasswordData"] = responseData
              ? responseData[0]
              : null;
            
            tempState["resetPasswordData"]["newPassword"] = ""
            tempState["resetPasswordData"]["confirmPassword"] = ""
            setState((prevState) => ({
              ...prevState,
              ...tempState,
            }));
          } else {
            tempState["userList"] = null;
            tempState["resetPasswordData"] = null;
            setState((prevState) => ({
              ...prevState,
              ...tempState,
            }));
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  function onChange(e, name) {
    let tempState = state;
    debugger;
    if (e) {
      if (e.target) {
        if (name === "newPassword" || name == "confirmPassword") {
          tempState["resetPasswordData"][name] = e.target.value;
          tempState["resetPasswordError"][name] = "";
        } else {
          tempState[name] = e.target.value;
        }
      } else {
        if (name == "userId") {
          tempState["resetPasswordData"] = tempState.userList.filter(
            (x) => x.id == e
          )[0];
        } else if (name == "clientType") {
          tempState[name] = e;
          tempState["clientToolsData"] = null;
          tempState["clientName"] = null;
          setClientNameList([]);
        } else {
          tempState[name] = e;
        }
      }
    } else {
      tempState[name] = e;
      tempState["clientToolsData"] = null;
      tempState["clientName"] = null;
    }
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    if (name == "clientType") {
      handleGetUsersList();
    }
    if (name == "clientName") {
      setLoading(true);
      handleGetUsersListByDealerId();
      handleImporsonateToJewelCloud();
    }
  }
  const handleImpersonateJewelCloud = () => {
    dispatch(setImpersonateData(state.resetPasswordData));
    var url = window.location.origin + "/dashboard";
    window.open(url, "_blank");
  };
  const handleReturnAdmin = () => {
    dispatch(setImpersonateData(null));
    setState(initialState);
    handleGetUsersList();
  };
  const handleAdminResetPassword = () => {
    if (handleResetPasswordValidation()) {
      let inputData = {
        userName: state.resetPasswordData.userName,
        password: state.resetPasswordData.newPassword,
      };
      try {
        LoginService.AdminResetPassword(inputData)
          .then((response) => {
            var message = response.data.message;
            var responseData = response.data.responseData;
            if (message == "True") {
              NotificationManager.success("Password Reset Successfully.");
              handleResetModalCancel();
            } else {
              NotificationManager.error("Password Not Reset.");
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };
  const handleResetPasswordValidation = () => {
    var error = state.resetPasswordError;
    var resetData = state.resetPasswordData;
    var isValid = true;
    if (!resetData.newPassword) {
      error["newPassword"] = "Please Enter Password.";
      isValid = false;
    } else {
      error["newPassword"] = "";
    }
    if (!resetData.confirmPassword) {
      error["confirmPassword"] = "Please Enter Confirm Password.";
      isValid = false;
    } else {
      error["confirmPassword"] = "";
    }
    if (resetData.confirmPassword && resetData.newPassword) {
      if (resetData.confirmPassword !== resetData.newPassword) {
        error["confirmPassword"] = "Password and Confirm Password Do Not Match.";
        isValid = false;
      } else {
      }
    }
    setState((prevState) => ({
      ...prevState,
      resetPasswordError: error,
    }));
    return isValid;
  };

  const handleResetModalCancel = () => {
    debugger;
    var tempState = state;
    tempState["resetPasswordData"]["newPassword"] = "";
    tempState["resetPasswordData"]["confirmPassword"] = "";
    tempState["resetPasswordError"] = {};
    
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    setResetPasswordModal(false);
    
  };
  const handleGetAdminDealerProfile = () => {
    // let inputData = {
    //   dealerId: Number(state.clientName),
    //   vendorId: Number(state.clientName),
    //   // dealerType: state.clientToolsData.vendorMembershipType,
    //   dealerType: "",
    // };
    setViewProfileModal(true);
    let inputData = {
      dealerId: parseInt(state.clientName),
      vendorId: parseInt(state.clientName),
      dealerType: "",
    };
    try {
      AdminToolsDataService.GetAdminDealerProfile(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setData(responseData);
          } else {
            setData([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleAdminToolsDownloadInventoryFile = () => {
    try {
      let inputData = {
        dealerId: state.resetPasswordData.dealerID,
        dealerCompany: state.clientToolsData.dealerCompany,
        // type: "Jewelry",
      };
      AdminToolsDataService.AdminToolsDownloadInventoryFile(inputData)
        .then((response) => {
          var fileName =
            "Inventory" +
            state.clientName +
            "_" +
            new Date().toJSON().slice(0, 10).replace(/-/g, "") +
            ".xlsx";
          debugger;
          BlobToDownloadFile(response.data, fileName);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <div className="admintools__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {traslate("AdministrationTools")}
              </h4>
              <p className="servup__txt">
                {traslate(
                  "-ServerUp-Time:Uptimeinfonotavailable-pleasetryreloadingthispage"
                )}{" "}
                <a href="#" className="jewspeed__txt">
                  {traslate("JewelCloudSpeedTest")}
                </a>
              </p>
              <p className="rapnet__txt">
                {traslate("-RapNetPricesUpdated:")}{" "}
                <span className="date__txt">{traslate("8/11/2017")}</span>
              </p>
            </div>
            <div className="col-md-12 mt-4">
              <p className="clienttool__txt">{traslate("ClientTools")}</p>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="input__block">
                <label>
                  {" "}
                  {traslate("SelectClientType")} <span>*</span>
                </label>
                <Select
                  showSearch
                  className="border__grey"
                  placeholder="Select Client Type"
                  optionFilterProp="children"
                  value={state.clientType}
                  onChange={(e) => {
                    onChange(e, "clientType");
                  }}
                >
                  {state.clientTypeList.map((item, i) => {
                    return <Option value={item.value}>{item.label}</Option>;
                  })}
                </Select>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="input__block">
                <label>
                  {" "}
                  {traslate("ClientName")} <span>*</span>
                </label>
                <Select
                  showSearch
                  className="border__grey"
                  placeholder="Select Client Name"
                  optionFilterProp="children"
                  value={state.clientName}
                  onChange={(e) => {
                    onChange(e, "clientName");
                  }}
                >
                  <Option key={0} value="">
                  Select Client Name
                  </Option>
                  {clientNameList.map((item) => {
                    return (
                      <Option value={item.dealerID}>
                        {item.dealerCompany + " ( " + item.dealerID + " )"}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
            <Spin spinning={loading} size={"large"}>
              {state.clientToolsData ? (
                state.clientToolsData.dealerCompany ? (
                  <React.Fragment>
                    <div className="col-lg-12">
                      <p className="selctclient__txt">
                        {traslate("SelectedClientTools")}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-12 mt-2">
                          <p className="name__txt">
                            {traslate("Name")} :{" "}
                            <span>
                              {state.clientToolsData.dealerCompany +
                                " ( " +
                                state.clientToolsData.dealerId +
                                " )"}
                            </span>
                          </p>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <p className="phone__txt">
                            {traslate("Phone")} :{" "}
                            <span>{state.clientToolsData.dealerPhone}</span>
                          </p>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <p className="type__txt">
                            {traslate("Type")} :{" "}
                            <span>
                              {" "}
                              {state.clientToolsData.vendorMembershipType}
                            </span>
                          </p>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <p className="verify__txt">
                            {traslate("Verified")} :{" "}
                            <span>
                              {" "}
                              {state.clientToolsData.verified ? "Yes" : "No"}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="approve__txt">
                            {traslate("ApprovedApps")} :{" "}
                            <span>{state.clientToolsData.asApprovedApps}</span>
                          </p>
                        </div>
                        <div className="col-lg-6">
                          <div className="input__block">
                            <label>
                              {" "}
                              {traslate("Users")}: <span>*</span>
                            </label>
                            <Select
                              showSearch
                              className="border__grey"
                              placeholder="Select Users"
                              optionFilterProp="children"
                              value={state.resetPasswordData.id}
                              onChange={(e) => {
                                onChange(e, "userId");
                              }}
                            >
                              {state.userList.map((item, i) => {
                                return (
                                  <Option key={item.id} value={item.id}>
                                    {item.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <p className="uname__txt">
                            {traslate("Username")} :{" "}
                            <span> {state.resetPasswordData.userName}</span>
                          </p>
                          <p className="psd__txt">
                            {traslate("Password")} :{" "}
                            <span> {"***********"}</span>
                            <span
                              className="edit__txt"
                              onClick={() => setResetPasswordModal(true)}
                            >
                              {traslate("Edit")}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <p
                        className="viewp__txt"
                        onClick={() => handleGetAdminDealerProfile()}
                      >
                        {traslate("ViewProfile")}
                      </p>
                      <a
                        href="#"
                        className="dwnload__txt"
                        onClick={() => handleAdminToolsDownloadInventoryFile()}
                      >
                        {traslate("DownloadInventory")}
                      </a>
                    </div>
                    <div className="col-lg-6">
                      <button
                        className="primary-btn"
                        onClick={handleImpersonateJewelCloud}
                        target="_blank"
                      >
                        {traslate("ImpersonateJewelCloud")}
                      </button>

                      <a
                        href="#"
                        className="returnjewel__txt"
                        onClick={handleReturnAdmin}
                      >
                        {traslate("ReturntoJewelCloudasDealer720")}
                      </a>
                    </div>
                    <div className="col-lg-6 mt-2">
                      <button
                        className="primary-btn"
                        onClick={handleImpersonateJewelCloud}
                        target="_blank"
                      >
                        {traslate("ImpersonateGemfindnet")}
                      </button>

                      <a
                        href="#"
                        className="returnjewel__txt"
                        onClick={handleReturnAdmin}
                      >
                        {traslate("ReturntoGemfindnetasDealer720")}
                      </a>
                    </div>
                  </React.Fragment>
                ) : null
              ) : null}
            </Spin>
            <div className="col-lg-12">
              <Modal
                title={Data.dealerName}
                className="modal__section"
                centered
                visible={viewProfileModal}
                onOk={() => setViewProfileModal(false)}
                onCancel={() => setViewProfileModal(false)}
                width={1000}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="modal__bannerdiv">
                        <img src={Main_bg} alt="" />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-3 mt-4">
                          <div className="modal__imgdiv">
                            <img src={Data.logoPath || ResizeLogo} alt="" />
                            <p>
                              {traslate("Updated")} | {Data.modifiedDate}
                            </p>
                            <span>
                              {traslate("DataRating")} | {Data.dataReting}
                            </span>
                            <span>
                              {Data.adminDealrProfileLocations
                                ? Data.adminDealrProfileLocations.length
                                : ""}{" "}
                              {traslate("Location")} |
                              {Data.adminDealrProfileLocations
                                ? Data.adminDealrProfileLocations
                                    .map((x) => x.locationName)
                                    .join("|")
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-9 mt-4">
                          <div className="row">
                            <div className="col-lg-12">
                              <p className="favdiamond__txt">
                                {state.viewProfileData.companyAbout}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4">
                              <p className="activ__txt">{traslate("Active")}</p>
                              <p className="prod__txt">
                                {Data.productLovesCount}{" "}
                                {traslate("ProductLoves")}
                              </p>
                              <p className="prod__txt">
                                {Data.collectionsCount}{" "}
                                {traslate("Collections")}
                              </p>
                              <p className="prod__txt">
                                {Data.itemsCount} {traslate("Items")}
                              </p>
                            </div>
                            <div className="col-lg-4">
                              <p className="cont__txt">
                                {traslate("ContactName")}
                              </p>
                              <a
                                className="mailid__txt"
                                href="mailto:sales3@amipi.com"
                              >
                                {Data.email}
                              </a>
                              <p className="contnum__txt">{Data.dealerPhone}</p>
                              <p className="contnum__txt">
                                {Data.websiteAddress}
                              </p>
                            </div>
                            <div className="col-lg-4">
                              <p className="vendtag__txt">
                                {traslate("Vendortags")}
                              </p>
                              <span className="notag__txt">
                                {traslate("Notagtodisplay")}
                                {Data.tags}
                              </span>
                              <p className="vendtag__txt">
                                {traslate("Brands")}
                              </p>
                              <span className="notag__txt">
                                {Data.brandNames}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                title={traslate("ResetPassword")}
                className="modal__section"
                centered
                visible={resetPasswordModal}
                onOk={handleAdminResetPassword}
                onCancel={() => handleResetModalCancel()}
                width={400}
                footer={[
                  <Button key="back" onClick={handleResetModalCancel}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button key="submit" type="primary" onClick={handleAdminResetPassword}>
                    {traslate("Save")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="form__fields border-0 m-0 p-0">
                      <div className="col-lg-12">
                        <div className="input__block">
                          <label>{traslate("UserName")} :</label>
                          <input
                            type="text"
                            onChange={(e) => {
                              onChange(e, "resetUsername");
                            }}
                            disabled={true}
                            value={state.resetPasswordData.userName}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input__block">
                          <label>{traslate("Password")}:</label>
                          <input
                            type={showPassword ? "text" : "password"}
                            onChange={(e) => {
                              onChange(e, "newPassword");
                            }}
                            value={state.resetPasswordData.newPassword}
                          />
                          {state.resetPasswordError["newPassword"] ? (
                            <span className="error-color-red">
                              {state.resetPasswordError["newPassword"]}
                            </span>
                          ) : null}
                          <span
                            class="p-viewer"
                            onClick={handlePassShowHide}
                          >
                            <i
                              class={
                                showPassword
                                  ? "fa fa-eye"
                                  : "fa fa-eye-slash"
                              }
                              aria-hidden="true"
                            ></i>
                          </span>    
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input__block">
                          <label>{traslate("ConfirmPassword")}:</label>
                          <input
                            type="password"
                            onChange={(e) => {
                              onChange(e, "confirmPassword");
                            }}
                            value={state.resetPasswordData.confirmPassword}
                          />
                          {state.resetPasswordError["confirmPassword"] ? (
                            <span className="error-color-red">
                              {state.resetPasswordError["confirmPassword"]}
                            </span>
                          ) : null}
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Administrationtools;
