import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Search,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Modal,
  Table,
} from "antd";
import AdminCampaignsService from "../../../services/admin-campaigns.service";
import { DownloadFile } from "../../../helper/commanFunction";
import traslate from "../../../i18n/translate";
const MailingLists = () => {
  const initialState = {
    designerID: null,
    designerID2: null,
    fileName: null,
    fileN: [],
    Image: null,
    Error: {},
  };
  const { Option } = Select;
  const [mailingList, setmailingList] = useState([]);
  const [mailingCount, setmailingCount] = useState(0);
  const [mailingName, setmailingName] = useState(null);
  const [recipientList, setrecipientList] = useState([]);
  const [recipientCount, setrecipientCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(initialState);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [fileName, setFileName] = useState("MailingLists.xlsx");
  const [fileNameRecipients, setFileNameRecipients] =
    useState("Recipients.xlsx");

  useEffect(() => {
    handleGetMailingList();
  }, []);
  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }
  const mailingData =
    '{  "items": [    {      "access_level": "readonly",      "address": "test_retailer@sandbox831af67e46b14b26be456d792b1391f6.mailgun.org",      "created_at": "Tue, 10 Mar 2015 15:29:58 -0000",      "description": "Retailer",      "members_count": 1,      "name": "Retailer"    },    {      "access_level": "readonly",      "address": "test_vendor@sandbox831af67e46b14b26be456d792b1391f6.mailgun.org",      "created_at": "Tue, 10 Mar 2015 15:29:34 -0000",      "description": "Vendor",      "members_count": 1,      "name": "Vendor"    },    {      "access_level": "readonly",      "address": "test_vendordiamond@sandbox831af67e46b14b26be456d792b1391f6.mailgun.org",      "created_at": "Tue, 10 Mar 2015 15:28:41 -0000",      "description": "VendorDiamond",      "members_count": 1,      "name": "VendorDiamond"    },    {      "access_level": "readonly",      "address": "test_pendant@sandbox831af67e46b14b26be456d792b1391f6.mailgun.org",      "created_at": "Tue, 10 Mar 2015 15:27:59 -0000",      "description": "PendantBuilder",      "members_count": 1,      "name": "PendantBuilder"    }  ],  "total_count": 8}';

  const columns1 = [
    {
      title: "Recipient Address",
      dataIndex: "recipient_address",
      width: 500,
    },
    {
      title: "Client",
      dataIndex: "client",
    },
  ];

  const data1 = [
    {
      key: "1",
      recipient_address: (
        <span>
          test_retailer@sandbox831af67e46b14b26be456d792b1391f6.mailgun.org
        </span>
      ),
      client: <span>Abc</span>,
    },
    {
      key: "2",
      recipient_address: (
        <span>
          test_retailer@sandbox831af67e46b14b26be456d792b1391f6.mailgun.org
        </span>
      ),
      client: <span>Abc</span>,
    },
    {
      key: "3",
      recipient_address: (
        <span>
          test_retailer@sandbox831af67e46b14b26be456d792b1391f6.mailgun.org
        </span>
      ),
      client: <span>Abc</span>,
    },
    {
      key: "4",
      recipient_address: (
        <span>
          test_retailer@sandbox831af67e46b14b26be456d792b1391f6.mailgun.org
        </span>
      ),
      client: <span>Abc</span>,
    },
  ];

  const handleGetMailingList = () => {
    debugger;
    setLoading(true);
    try {
      AdminCampaignsService.GetMailingList()
        .then((response) => {
          setLoading(false);
          debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            var data = JSON.parse(responseData);
            setmailingList(data.items);
            setmailingCount(data.total_count);
          } else {
            var data = JSON.parse(mailingData);
            setmailingList(data.items);
            setmailingCount(data.total_count);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleGetRecepientList = (Email, Name) => {
    debugger;
    setLoading(true);
    setmailingName(Name);
    let inputData = {
      address: Email,
      page: "first",
      nextRecipientAddress: "",
    };
    try {
      AdminCampaignsService.GetRecepientList(inputData)
        .then((response) => {
          debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            var data = JSON.parse(responseData);
            setrecipientList(data.items);
            setrecipientCount(data.items.length);
            setLoading(false);
          } else {
            setrecipientList([]);
            setrecipientCount(0);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleGetRecepientListByMailingListName = () => {
    debugger;
    setSubmitLoading(true);
    let inputData = {
      mailingListName: mailingName,
      orderBy: "mailingListName",
      orderDir: "",
    };
    try {
      AdminCampaignsService.GetRecepientListByMailingListName(inputData)
        .then((response) => {
          setSubmitLoading(false);
          debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            const link = document.createElement("a");
            link.href = responseData;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };
  const handleDownloadMailList = () => {
    DownloadFile(mailingList, fileName);
  };
  const handleDownloadRecipients = () => {
    DownloadFile(recipientList, fileNameRecipients);
  };
  return (
    <React.Fragment>
      <div className="mailinglist__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0"> {traslate("MailingLists")} </h4>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="dwnld__div">
                <div>
                  <span className="mailist__txt">
                    <span className="no__txt">{mailingCount}</span> 
                    {traslate("MailingLists")}
                  </span>
                </div>
                <div>
                  <button
                    className="primary-btn"
                    onClick={handleDownloadMailList}
                  >
                    {" "}
                    {traslate("Download")}
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="mailist_tbldiv">
                {/* <Table columns={columns} dataSource={data} scroll={{ y: 300 }} /> */}
                <Table
                  const
                  columns={[
                    {
                      title: traslate("AliasAddress"),

                      dataIndex: "address",
                      key: "address",
                      fixed: "left",
                      //onCellClick:handleGetRecepientList(),
                    },
                    {
                      title: traslate("Name"),
                      dataIndex: "name",
                      key: "name",
                    },
                    {
                      title: traslate("NumberOfRecipients"),
                      dataIndex: "members_count",
                      width:120,
                      key: "members_count",
                    },
                    {
                      title: traslate("Created"),
                      dataIndex: "created_at",
                      key: "created_at",
                    },
                  ]}
                  onRow={(record, recordIndex) => ({
                    onClick: (event) => {
                      handleGetRecepientList(record.address, record.name);
                    },
                  })}
                  loading={loading}
                  dataSource={mailingList}
                  scroll={{ x: 600 }}
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="dwnld__div">
                <div>
                  <span className="mailist__txt">
                    <span className="no__txt">{recipientCount}</span> {traslate("Recipients")}
                  </span>
                </div>
                <div>
                  <button className="primary-btn mr-2"> {traslate("Sync")} </button>
                  <Button
                    className="primary-btn"
                    // onClick={handleGetRecepientListByMailingListName}
                    onClick={handleDownloadRecipients}
                    loading={submitLoading}
                  >
                    {" "}
                    {traslate("Download")}
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="mailist_tbldiv">
                {/* <Table columns={columns1} dataSource={data1} scroll={{ y: 300 }} /> */}
                <Table
                  const
                  columns={[
                    {
                      title: traslate("RecipientAddress"),

                      dataIndex: "address",
                      key: "address",
                      fixed: "left",
                    },
                    {
                      title: traslate("Client"),
                      dataIndex: "name",
                      key: "name",
                    },
                  ]}
                  loading={loading}
                  dataSource={recipientList}
                  scroll={{ x: 300 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MailingLists;
