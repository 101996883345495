import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Select, Spin, Button } from "antd";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../../i18n/translate";
const { Option } = Select;

const ApplicationPermissions = () => {
  const initialState = {
    dealerId: null,
    posSystem: null,
    appActivationControl: {},
  };
  const [state, setState] = useState(initialState);
  const [dealerList, setDealerList] = useState([]);
  const [posSystemList, setPosSystemList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    handleFillDealerDropDown();
  }, []);

  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      if (location.state.key) {
        var temp = state;
        temp.dealerId = location.state.key;
        setState(temp);
        handleGetMenuList(location.state.key);
      }
    }
  }, []);

  const onChange = (e, name) => {
    let tempState = state;
    debugger;
    if (e.target) {
      tempState[name] = e.target.value;
    } else {
      tempState[name] = e;
    }
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    if (name === "dealerId") {
      handleGetMenuList(e);
    }
  };
  const handleFillDealerDropDown = () => {
    try {
      AdminToolsDataService.FillDealerDropDown()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setDealerList(responseData);
          } else {
            setDealerList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetMenuList = (Id) => {
    try {
      let inputData = {
        dealerId: Id,
      };
      setLoading(true);
      AdminToolsDataService.GetMenuList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            setPosSystemList(responseData.posSystemResponse);
            let appActivationControl = {
              ...responseData.diamondSearchMenu,
              ...responseData.parentCheckbox,
            };
            const tempState = state;
            tempState["appActivationControl"] = appActivationControl;
            tempState["posSystem"] = appActivationControl.posSystemsMasterId;
            setState((prevState) => ({
              ...prevState,
              ...tempState,
            }));
          } else {
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleChangeCheckBox = (e, name) => {
    let tempState = state;
    if (name === "chkDiamondLink" && e.target.checked === false) {
      tempState.appActivationControl["chkDiamondEcomm"] = false;
      tempState.appActivationControl["chkDiamondSsl"] = false;
      tempState.appActivationControl["chkDSSPBy"] = false;
      tempState.appActivationControl["chkLabGrownDiamonds"] = false;
    }
    if (name === "chkRingBuilder" && e.target.checked === false) {
      tempState.appActivationControl["chkRingBuilderEcom"] = false;
      tempState.appActivationControl["chkRingBuilderSsl"] = false;
      tempState.appActivationControl["chkRBSPBy"] = false;
      tempState.appActivationControl["chkTryon"] = false;
    }
    if (name === "chkStudBuilder" && e.target.checked === false) {
      tempState.appActivationControl["chkStudBuilderEcom"] = false;
      tempState.appActivationControl["chkStudBuilderSsl"] = false;
      tempState.appActivationControl["chkSBSPBy"] = false;
    }
    if (name === "chkPendantBuilder" && e.target.checked === false) {
      tempState.appActivationControl["chkPendantBuilderEcom"] = false;
      tempState.appActivationControl["chkPendantBuilderSsl"] = false;
      tempState.appActivationControl["chkPBSPBy"] = false;
    }
    if (name === "chkML3" && e.target.checked === false) {
      tempState.appActivationControl["chkMLEcomm"] = false;
      tempState.appActivationControl["chkMLSsl"] = false;
      tempState.appActivationControl["chkMLSPBy"] = false;
    }
    if (name === "chkML3" && e.target.checked === true) {
      tempState.appActivationControl["chkMLEcomm"] = true;
      tempState.appActivationControl["chkMLSsl"] = true;
    }
    if (name === "chkMLEcomm" || name === "chkMLSsl" || name === "chkMLSPBy" && e.target.checked === true) {
      tempState.appActivationControl["chkML3"] = true;
    }
    if (name === "chkRingBuilderEcom" || name === "chkRingBuilderSsl" || name === "chkRBSPBy" || name === "chkTryon" && e.target.checked === true) {
      tempState.appActivationControl["chkRingBuilder"] = true;
    }
    if (name === "chkStudBuilderEcom" || name === "chkStudBuilderSsl" || name === "chkSBSPBy" && e.target.checked === true) {
      tempState.appActivationControl["chkStudBuilder"] = true;
    }
    if (name === "chkPendantBuilderEcom" || name === "chkPendantBuilderSsl" || name === "chkPBSPBy" && e.target.checked === true) {
      tempState.appActivationControl["chkPendantBuilder"] = true;
    }
    if (e.target) {
      tempState.appActivationControl[name] = e.target.checked;
    } else {
      tempState[name] = e;
    }
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const handleSaveMenuList = () => {
    try {
      let inputData = {
        loginUserId: "",
        posId: state.posSystem,
        menus: state.appActivationControl,
        dealerId: state.dealerId,
      };
      setSubmitLoading(true);
      AdminToolsDataService.SaveMenuList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setSubmitLoading(false);
          debugger;
          if (message == "Success") {
            NotificationManager.success(
              "Designer Menu Preferences Submited Sucessfully."
            );
            handleGetMenuList(state.dealerId);
          } else {
            NotificationManager.error(
              "Designer Menu Preferences Not Submited."
            );
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <div className="appermission__section ApplicationPermission">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {traslate("AppActivationControl")}
              </h4>
            </div>
            <div className="col-lg-3 col-md-6 mt-4">
              <div className="input__block">
                <label>
                  {" "}
                  {traslate("SelectDealer")}
                </label>
                <Select
                  showSearch
                  className={"border__grey"}
                  placeholder="Select Dealer"
                  optionFilterProp="children"
                  notFoundContent="No Data Found"
                  value={state.dealerId}
                  onChange={(e) => {
                    onChange(e, "dealerId");
                  }}
                >
                  <Option value="">Select Dealer</Option>
                  {dealerList.map((item) => {
                    return (
                      <Option value={item.dealerID}>
                        {item.dealerCompany + " (" + item.dealerID + " )"}
                      </Option>
                    );
                  })}
                </Select>
                {/* {state.dealerId ? (
                  <a href="#" className="viewsroom__txt">
                    View Your Showroom
                  </a>
                ) : null} */}
              </div>
            </div>
            {loading ? (
              <Spin size="large" />
            ) : state.dealerId ? (
              <React.Fragment>
                <div className="col-lg-12">
                  <h3 className="subheading">
                    {traslate("AppActivationControl")}
                  </h3>
                </div>

                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-4 mt-4">
                      <div className="chkbox__maindiv">
                        <div className="chkbox__headdiv">
                          <input
                            type="checkbox"
                            id="chkDiamondLink"
                            checked={state.appActivationControl.chkDiamondLink}
                            onChange={(e) => {
                              handleChangeCheckBox(e, "chkDiamondLink");
                            }}
                          />
                          <label htmlFor="chkDiamondLink">
                            {traslate("DiamondLink")}
                          </label>
                        </div>
                        <div className="chckbox__subdiv">
                          <div>
                            <span className="diamsrch__txt">
                              {traslate("DiamondSearchLinks")}
                            </span>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkDiamondEcomm"
                              checked={
                                state.appActivationControl.chkDiamondEcomm
                              }
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkDiamondEcomm");
                              }}
                            />
                            <label htmlFor="chkDiamondEcomm">
                              {traslate("DSEcommerce")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkDiamondSsl"
                              checked={state.appActivationControl.chkDiamondSsl}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkDiamondSsl");
                              }}
                            />
                            <label htmlFor="chkDiamondSsl">
                              {traslate("DSSSL")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkDSSPBy"
                              checked={state.appActivationControl.chkDSSPBy}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkDSSPBy");
                              }}
                            />
                            <label htmlFor="chkDSSPBy">
                              {traslate("DSShowPoweredBy")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkLabGrownDiamonds"
                              checked={
                                state.appActivationControl.chkLabGrownDiamonds
                              }
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkLabGrownDiamonds");
                              }}
                            />
                            <label htmlFor="chkLabGrownDiamonds">
                              {traslate("DSLabGrownDiamonds")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 mt-4">
                      <div className="chkbox__maindiv">
                        <div className="chckbox__subdiv">
                          <div>
                            <span className="diamsrch__txt">
                              {traslate("JewelCloud")}
                            </span>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkApi"
                              checked={state.appActivationControl.chkApi}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkApi");
                              }}
                            />
                            <label htmlFor="chkApi">{traslate("API")}</label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkDirectFeed"
                              checked={state.appActivationControl.chkDirectFeed}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkDirectFeed");
                              }}
                            />
                            <label htmlFor="chkDirectFeed">
                              {traslate("Directfeed")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkWindowsApp"
                              checked={state.appActivationControl.chkWindowsApp}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkWindowsApp");
                              }}
                            />
                            <label htmlFor="chkWindowsApp">
                              {traslate("WindowsApp")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 mt-4">
                      <div className="chkbox__maindiv">
                        <div className="chckbox__subdiv">
                          <div>
                            <span className="diamsrch__txt">
                              {traslate("MasterLink")}
                            </span>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkML1"
                              checked={state.appActivationControl.chkML1}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkML1");
                              }}
                            />
                            <label htmlFor="chkML1">
                              {traslate("MLLegacy")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkML2"
                              checked={state.appActivationControl.chkML2}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkML2");
                              }}
                            />
                            <label htmlFor="chkML2">{traslate("ML2")}</label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkML3"
                              checked={state.appActivationControl.chkML3}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkML3");
                              }}
                            />
                            <label htmlFor="chkML3">{traslate("ML3")}</label>
                          </div>
                          <div className="chckbox__subdiv">
                            <div>
                              <input
                                type="checkbox"
                                id="chkMLEcomm"
                                checked={state.appActivationControl.chkMLEcomm}
                                onChange={(e) => {
                                  handleChangeCheckBox(e, "chkMLEcomm");
                                }}
                              />
                              <label htmlFor="chkMLEcomm">
                                {traslate("MLEcommerce")}
                              </label>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                id="chkMLSsl"
                                checked={state.appActivationControl.chkMLSsl}
                                onChange={(e) => {
                                  handleChangeCheckBox(e, "chkMLSsl");
                                }}
                              />
                              <label htmlFor="chkMLSsl">
                                {traslate("MLSSL")}
                              </label>
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                id="chkMLSPBy"
                                checked={state.appActivationControl.chkMLSPBy}
                                onChange={(e) => {
                                  handleChangeCheckBox(e, "chkMLSPBy");
                                }}
                              />
                              <label htmlFor="chkMLSPBy">
                                {traslate("MLShowPoweredBy")}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 mt-4">
                      <div className="chkbox__maindiv">
                        <div className="chkbox__headdiv">
                          <input
                            type="checkbox"
                            id="chkRingBuilder"
                            checked={state.appActivationControl.chkRingBuilder}
                            onChange={(e) => {
                              handleChangeCheckBox(e, "chkRingBuilder");
                            }}
                          />
                          <label htmlFor="chkRingBuilder">
                            {traslate("RingBuilder")}
                          </label>
                        </div>
                        <div className="chckbox__subdiv">
                          <div>
                            <input
                              type="checkbox"
                              id="chkRingBuilderEcom"
                              checked={
                                state.appActivationControl.chkRingBuilderEcom
                              }
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkRingBuilderEcom");
                              }}
                            />
                            <label htmlFor="chkRingBuilderEcom">
                              {traslate("RBEcommerce")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkRingBuilderSsl"
                              checked={
                                state.appActivationControl.chkRingBuilderSsl
                              }
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkRingBuilderSsl");
                              }}
                            />
                            <label htmlFor="chkRingBuilderSsl">
                              {traslate("RBSSL")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkRBSPBy"
                              checked={state.appActivationControl.chkRBSPBy}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkRBSPBy");
                              }}
                            />
                            <label htmlFor="chkRBSPBy">
                              {traslate("RBShowPoweredBy")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkTryon"
                              checked={state.appActivationControl.chkTryon}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkTryon");
                              }}
                            />
                            <label htmlFor="chkTryon">
                              {traslate("Tryon")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 mt-4">
                      <div className="chkbox__maindiv">
                        <div className="chkbox__headdiv">
                          <input
                            type="checkbox"
                            id="chkStudBuilder"
                            checked={state.appActivationControl.chkStudBuilder}
                            onChange={(e) => {
                              handleChangeCheckBox(e, "chkStudBuilder");
                            }}
                          />
                          <label htmlFor="chkStudBuilder">
                            {traslate("StudBuilder")}
                          </label>
                        </div>
                        <div className="chckbox__subdiv">
                          <div>
                            <input
                              type="checkbox"
                              id="chkStudBuilderEcom"
                              checked={
                                state.appActivationControl.chkStudBuilderEcom
                              }
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkStudBuilderEcom");
                              }}
                            />
                            <label htmlFor="chkStudBuilderEcom">
                              {traslate("SBEcommerce")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkStudBuilderSsl"
                              checked={
                                state.appActivationControl.chkStudBuilderSsl
                              }
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkStudBuilderSsl");
                              }}
                            />
                            <label htmlFor="chkStudBuilderSsl">
                              {traslate("SBSSL")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkSBSPBy"
                              checked={state.appActivationControl.chkSBSPBy}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkSBSPBy");
                              }}
                            />
                            <label htmlFor="chkSBSPBy">
                              {traslate("SBShowPoweredBy")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 mt-4">
                      <div className="chkbox__maindiv">
                        <div className="chkbox__headdiv">
                          <input
                            type="checkbox"
                            id="chkPendantBuilder"
                            checked={
                              state.appActivationControl.chkPendantBuilder
                            }
                            onChange={(e) => {
                              handleChangeCheckBox(e, "chkPendantBuilder");
                            }}
                          />
                          <label htmlFor="chkPendantBuilder">
                            {traslate("PendantBuilder")}
                          </label>
                        </div>
                        <div className="chckbox__subdiv">
                          <div>
                            <input
                              type="checkbox"
                              id="chkPendantBuilderEcom"
                              checked={
                                state.appActivationControl.chkPendantBuilderEcom
                              }
                              onChange={(e) => {
                                handleChangeCheckBox(
                                  e,
                                  "chkPendantBuilderEcom"
                                );
                              }}
                            />
                            <label htmlFor="chkPendantBuilderEcom">
                              {traslate("PBEcommerce")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkPendantBuilderSsl"
                              checked={
                                state.appActivationControl.chkPendantBuilderSsl
                              }
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkPendantBuilderSsl");
                              }}
                            />
                            <label htmlFor="chkPendantBuilderSsl">
                              {traslate("PBSSL")}
                            </label>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              id="chkPBSPBy"
                              checked={state.appActivationControl.chkPBSPBy}
                              onChange={(e) => {
                                handleChangeCheckBox(e, "chkPBSPBy");
                              }}
                            />
                            <label htmlFor="chkPBSPBy">
                              {traslate("PBShowPoweredBy")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 mt-4">
                      <div className="chkbox__maindiv">
                        <div className="chkbox__div">
                          <input
                            type="checkbox"
                            id="chkGemfindTV"
                            checked={state.appActivationControl.chkGemfindTV}
                            onChange={(e) => {
                              handleChangeCheckBox(e, "chkGemfindTV");
                            }}
                          />
                          <label htmlFor="chkGemfindTV">
                            {traslate("Gemfind.TV")}
                          </label>
                        </div>
                        <div className="chkbox__div">
                          <input
                            type="checkbox"
                            id="chkWatchBuilder"
                            checked={state.appActivationControl.chkWatchBuilder}
                            onChange={(e) => {
                              handleChangeCheckBox(e, "chkWatchBuilder");
                            }}
                          />
                          <label htmlFor="chkWatchBuilder">
                            {traslate("WatchCustomizer")}
                          </label>
                        </div>
                        <div className="chkbox__div">
                          <input
                            type="checkbox"
                            id="chkPO"
                            checked={state.appActivationControl.chkPO}
                            onChange={(e) => {
                              handleChangeCheckBox(e, "chkPO");
                            }}
                          />
                          <label htmlFor="chkPO">
                            {traslate("PurchaseOrder(PO)")}
                          </label>
                        </div>
                        <div className="chkbox__div">
                          <input
                            type="checkbox"
                            id="chkBridalFashion"
                            checked={
                              state.appActivationControl.chkBridalFashion
                            }
                            onChange={(e) => {
                              handleChangeCheckBox(e, "chkBridalFashion");
                            }}
                          />
                          <label htmlFor="chkBridalFashion">
                            {traslate("Bridal/FashionSeparation")}
                          </label>
                        </div>
                        <div className="chkbox__div">
                          <input
                            type="checkbox"
                            id="chkDiamondGroupDiscount"
                            checked={
                              state.appActivationControl.chkDiamondGroupDiscount
                            }
                            onChange={(e) => {
                              handleChangeCheckBox(
                                e,
                                "chkDiamondGroupDiscount"
                              );
                            }}
                          />
                          <label htmlFor="chkDiamondGroupDiscount">
                            {traslate("DiamondGroupDiscount")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="col-lg-3 col-md-6 mt-4">
                    <div className="input__block">
                      <label>
                        {" "}
                        {traslate("POSSystem")}
                      </label>
                      <Select
                        showSearch
                        className="border__grey"
                        placeholder="POS System"
                        optionFilterProp="children"
                        notFoundContent="No Data Found"
                        value={state.posSystem}
                        onChange={(e) => {
                          onChange(e, "posSystem");
                        }}
                      >
                        <Option value="">POS System</Option>
                        {posSystemList.map((item) => {
                          return (
                            <Option value={item.posSystemsMasterId}>
                              {item.posSystemsText}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <Button
                    className="primary-btn"
                    loading={submitLoading}
                    onClick={handleSaveMenuList}
                  >
                    {traslate("Submit")}
                  </Button>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ApplicationPermissions;
