import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Modal, Select } from "antd";
import { Link } from "react-router-dom";
import logoImg from "../../assets/images/logo-img.png";
import footerLogoImg from "../../assets/images/footer-logo.png";
import footerCompanyLogo1 from "../../assets/images/footerlogo1.png";
import footerCompanyLogo2 from "../../assets/images/footerlogo2.png";
import footerCompanyLogo3 from "../../assets/images/footerlogo3.png";
import footerCompanyLogo4 from "../../assets/images/footerlogo4.png";
import footerCompanyLogo5 from "../../assets/images/footerlogo5.png";
import AdminToolsDataService from "../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";

const Register = (props) => {
  const [visible, setVisible] = useState(false);

  const { Option } = Select;

  const [RegisterData, setRegisterData] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
    userName: "",
    password: "",
    accountType: "",
    contactNo: "",
  });
  const [validations, setValidations] = useState({
    companyNameVal: "",
    firstNameVal: "",
    lastNameVal: "",
    emailVal: "",
    addressVal: "",
    cityVal: "",
    stateVal: "",
    countryVal: "",
    zipCodeVal: "",
    userNameVal: "",
    passwordVal: "",
    accountTypeVal: "",
    contactNoVal: "",
  });
  const [countryList, setCountryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isCompanyName, setIsCompanyName] = useState(true);
  const [isEmail, setIsEmail] = useState(true);
  const [isUserName, setIsUserName] = useState(true);
  const [showPassword, setshowPassword] = useState(false);

  useEffect(() => {
    GetCountryDetail();
  }, []);

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setRegisterData({
        ...RegisterData,
        [name]: e,
      });
    } else {
      setRegisterData({
        ...RegisterData,
        [e.target.name]: e.target.value,
      });
    }
    console.log(e, isSelect, name);
  };

  // Show Password

  const handlePassShowHide = () => {
    setshowPassword(!showPassword);
  };

  const handleValidation = () => {
    const validations = {};
    let isValid = true;

    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var regex = /^\d{10}$/;

    if (!RegisterData.companyName.trim()) {
      isValid = false;
      validations.companyNameVal = "Company name is compulsory";
    } else {
      validations.companyNameVal = "";
    }
    if (!RegisterData.firstName.trim()) {
      isValid = false;
      validations.firstNameVal = "First name is compulsory";
    } else {
      validations.firstNameVal = "";
    }
    if (!RegisterData.lastName.trim()) {
      isValid = false;
      validations.lastNameVal = "Last name is compulsory";
    } else {
      validations.lastNameVal = "";
    }
    if (!RegisterData.email.trim()) {
      isValid = false;
      validations.emailVal = "Email is compulsory";
    } else {
      if (!RegisterData.email.match(validRegex)) {
        isValid = false;
        validations.emailVal = "Enter proper Email";
      } else {
        validations.emailVal = "";
      }
    }
    if (!RegisterData.address.trim()) {
      isValid = false;
      validations.addressVal = "Address is compulsory";
    } else {
      validations.addressVal = "";
    }
    if (!RegisterData.city.trim()) {
      isValid = false;
      validations.cityVal = "City is compulsory";
    } else {
      validations.cityVal = "";
    }
    if (!RegisterData.state.trim()) {
      isValid = false;
      validations.stateVal = "State is compulsory";
    } else {
      validations.stateVal = "";
    }
    if (!RegisterData.country) {
      isValid = false;
      validations.countryVal = "Kindly select the Country ";
    } else {
      validations.countryVal = "";
    }
    if (!RegisterData.zipCode.trim()) {
      isValid = false;
      validations.zipCodeVal = "Zip Code is compulsory";
    } else {
      validations.zipCodeVal = "";
    }
    if (!RegisterData.userName.trim()) {
      isValid = false;
      validations.userNameVal = "User name is compulsory";
    } else {
      validations.userNameVal = "";
    }
    if (!RegisterData.password.trim()) {
      isValid = false;
      validations.passwordVal = "Password is compulsory";
    } else {
      validations.passwordVal = "";
    }
    if (!RegisterData.accountType) {
      isValid = false;
      validations.accountTypeVal = "Kindly select the Account Type ";
    } else {
      validations.accountTypeVal = "";
    }
    if (!RegisterData.contactNo.trim()) {
      isValid = false;
      validations.contactNoVal = "Contact No is compulsory";
    } else {
      if (!RegisterData.contactNo.match(regex)) {
        isValid = false;
        validations.contactNoVal = "Enter proper Contact No";
      } else {
        validations.contactNoVal = "";
      }
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };
  const GetCountryDetail = () => {
    try {
      setLoading(true);
      AdminToolsDataService.GetCountryDetail()
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setCountryList(responseData);
          } else {
            setCountryList([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleCheckDealerCompanyName = async () => {
    try {
      let inputData = {
        companyName: RegisterData.companyName,
      };
      var isValid = true;
      setLoading(true);
      await AdminToolsDataService.CheckDealerCompanyName(inputData)
        .then((response) => {
          debugger;
          // var message = response.data.message;
          var checkMessage = response.data.responseData.isCompanyExist;
          setLoading(false);
          setValidations((prevState) => ({
            ...prevState,
            companyNameVal: checkMessage ? "CompanyName is Taken" : "",
          }));
          setIsCompanyName(checkMessage);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleCheckDealerEmail = async () => {
    try {
      let inputData = {
        email: RegisterData.email,
      };
      setLoading(true);
      var isValid = true;
      await AdminToolsDataService.CheckDealerEmail(inputData)
        .then((response) => {
          // var message = response.data.message;
          var checkMessage = response.data.responseData.isEmailExist;
          setLoading(false);
          setValidations((prevState) => ({
            ...prevState,
            emailVal: checkMessage ? "Email is Taken" : "",
          }));
          setIsEmail(checkMessage);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    return isValid;
  };

  const handleCheckDealerUser = () => {
    try {
      let inputData = {
        userName: RegisterData.email,
      };
      setLoading(true);
      var isValid = true;
      AdminToolsDataService.CheckDealerUser(inputData)
        .then((response) => {
          // var message = response.data.message;
          var checkMessage = response.data.responseData.isUserNameExist;
          setLoading(false);
          isValid = checkMessage;
          setValidations((prevState) => ({
            ...prevState,
            userNameVal: checkMessage && "Username is Taken",
          }));
          setIsUserName(checkMessage);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    return isValid;
  };
  const handleRegister = async () => {
    var isValid = handleValidation();
    setIsEmail(true);
    setIsCompanyName(true);
    setIsUserName(true);
    if (!isValid) {
      return false;
    } else {
      await handleCheckDealerCompanyName();
      await handleCheckDealerEmail();
      await handleCheckDealerUser();
      if (!isCompanyName && !isEmail && !isUserName) {
        try {
          let inputData = {
            dealerId: 0,
            companyName: RegisterData.companyName,
            streetAddress: RegisterData.streetAddress,
            cityId: 0,
            stateId: 0,
            dealerCity: RegisterData.city,
            dealerState: RegisterData.state,
            zipCode: RegisterData.zipCode,
            countryId: RegisterData.zipCode,
            contactName: RegisterData.firstName + RegisterData.lastName,
            emailAddress: RegisterData.email,
            phoneNumber: RegisterData.contactNo,
            userName: RegisterData.userName,
            password: RegisterData.password,
            permissionTypeId: 0,
            isVerified: false,
            retailerStatusId: "",
            vendorStatusId: "",
            ipAddress: "noneknown",
            logInUser: 0,
            logInUserName: "datapending",
            mailBody: "",
            mode: "",
          };
          setSubmitLoading(true);
          AdminToolsDataService.CreateNewClient(inputData)
            .then((response) => {
              var message = response.data.message;
              setSubmitLoading(false);
              if (message == "Success") {
                NotificationManager.success("New Client Added Successfully.");
              } else {
                NotificationManager.success("New Client Not Added.");
              }
            })
            .catch((error) => {
              console.log(error);
              setSubmitLoading(false);
            });
        } catch (error) {
          console.log(error);
          setSubmitLoading(false);
        }
        setVisible(true);
        setRegisterData({
          companyName: "",
          firstName: "",
          lastName: "",
          email: "",
          address: "",
          city: "",
          state: "",
          country: "",
          zipCode: "",
          userName: "",
          password: "",
          accountType: "",
          contactNo: "",
        });
        setValidations({
          companyNameVal: "",
          firstNameVal: "",
          lastNameVal: "",
          emailVal: "",
          addressVal: "",
          cityVal: "",
          stateVal: "",
          countryVal: "",
          zipCodeVal: "",
          userNameVal: "",
          passwordVal: "",
          accountTypeVal: "",
          contactNoVal: "",
        });
      }
    }
  };

  const handleOk = (id) => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  function onSearch(val) {
    console.log("search:", val);
  }

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  return (
    <div className="login__section register__section container-fluid h-100">
      <div className="form__fields">
        <div className="row">
          <div className="col-md-12">
            <div className="login__header">
              <div className="logo__section">
                <img src={logoImg} />
              </div>

              <div className="right__text">
                <p>Social Product Network for the Jewelry Industry</p>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form__section">
              <div className="login__block">
                <h3>Welcome to JewelCloud</h3>
                {/*<Form name="basic">
                  <Form.Item className="formlabel">
                    Username <span className="mandatory">*</span>
                  </Form.Item>
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!"
                      }
                    ]}
                  >
                    <Input className="login-input" />
                  </Form.Item>
                  <Form.Item className="formlabel">
                    Password <span className="mandatory">*</span>
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!"
                      }
                    ]}
                  >
                    <Input.Password className="login-input" />
                  </Form.Item>

                  <Form.Item
                    name="remember"
                    valuePropName="checked"
                    className="checkbox__block"
                  >
                    <div className="checkbox__block">
                      <Checkbox className="formcheckbox">Remember me</Checkbox>
                      <a>Forgot Password ?</a>
                    </div>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="button"
                      className="primary-btn w-100"
                    >
                      Register
                    </Button>
                  </Form.Item>
                </Form>*/}

                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <div className="input__block">
                      <label>
                        Company Name
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Company Name"
                        name="companyName"
                        value={RegisterData.companyName}
                        onChange={handleOnChange}
                        className={validations.companyNameVal && "border__red"}
                      />
                      <div>
                        {validations.companyNameVal && (
                          <p className="error-color-red">
                            {validations.companyNameVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div className="input__block">
                      <label>
                        First Name
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter First Name"
                        name="firstName"
                        value={RegisterData.firstName}
                        onChange={handleOnChange}
                        className={validations.firstNameVal && "border__red"}
                      />
                      <div>
                        {validations.firstNameVal && (
                          <p className="error-color-red">
                            {validations.firstNameVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div className="input__block">
                      <label>
                        Last Name
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Last Name"
                        name="lastName"
                        value={RegisterData.lastName}
                        onChange={handleOnChange}
                        className={validations.lastNameVal && "border__red"}
                      />
                      <div>
                        {validations.lastNameVal && (
                          <p className="error-color-red">
                            {validations.lastNameVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-4">
                    <div className="input__block">
                      <label>
                        Email
                        <span>*</span>
                      </label>
                      <input
                        type="email"
                        placeholder="Enter Email"
                        name="email"
                        value={RegisterData.email}
                        onChange={handleOnChange}
                        className={validations.emailVal && "border__red"}
                      />
                      <div>
                        {validations.emailVal && (
                          <p className="error-color-red">
                            {validations.emailVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-4">
                    <div className="input__block">
                      <label>
                        Address
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Address"
                        name="address"
                        value={RegisterData.address}
                        onChange={handleOnChange}
                        className={validations.addressVal && "border__red"}
                      />
                      <div>
                        {validations.addressVal && (
                          <p className="error-color-red">
                            {validations.addressVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div className="input__block">
                      <label>
                        City
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter City"
                        name="city"
                        value={RegisterData.city}
                        onChange={handleOnChange}
                        className={validations.cityVal && "border__red"}
                      />
                      <div>
                        {validations.cityVal && (
                          <p className="error-color-red">
                            {validations.cityVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div className="input__block">
                      <label>
                        State
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter State"
                        name="state"
                        value={RegisterData.state}
                        onChange={handleOnChange}
                        className={validations.stateVal && "border__red"}
                      />
                      <div>
                        {validations.stateVal && (
                          <p className="error-color-red">
                            {validations.stateVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div className="input__block">
                      <label>
                        Country
                        <span>*</span>
                      </label>
                      <Select
                        showSearch
                        // className=""
                        name="country"
                        placeholder="Select Country "
                        value={
                          RegisterData.country ? RegisterData.country : null
                        }
                        optionFilterProp="children"
                        // onChange={onChange}
                        onChange={(e) => handleOnChange(e, "select", "country")}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        className={
                          validations.countryVal
                            ? "border__red"
                            : "border__grey"
                        }
                      >
                        {countryList.map((item) => {
                          return (
                            <Option key={item.countryID} value={item.countryID}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                      <div>
                        {validations.countryVal && (
                          <p className="error-color-red">
                            {validations.countryVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div className="input__block">
                      <label>
                        Zip Code
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Zip Code"
                        onKeyPress={onKeyPressEvent}
                        name="zipCode"
                        value={RegisterData.zipCode}
                        onChange={handleOnChange}
                        className={validations.zipCodeVal && "border__red"}
                      />
                      <div>
                        {validations.zipCodeVal && (
                          <p className="error-color-red">
                            {validations.zipCodeVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div className="input__block">
                      <label>
                        User Name
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter User Name"
                        name="userName"
                        value={RegisterData.userName}
                        onChange={handleOnChange}
                        className={validations.userNameVal && "border__red"}
                      />
                      <div>
                        {validations.userNameVal && (
                          <p className="error-color-red">
                            {validations.userNameVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div className="input__block">
                      <label>
                        Password
                        <span>*</span>
                      </label>
                      <input
                        // type="password"
                        placeholder="Enter Password"
                        name="password"
                        value={RegisterData.password}
                        onChange={handleOnChange}
                        type={showPassword ? "text" : "password"}
                        className={validations.passwordVal && "border__red"}
                      />
                      <span
                        class="p-viewer"
                        onClick={handlePassShowHide}
                      >
                        <i
                          class={
                            showPassword
                              ? "fa fa-eye"
                              : "fa fa-eye-slash"
                          }
                          aria-hidden="true"
                        ></i>
                      </span>
                      <div>
                        {validations.passwordVal && (
                          <p className="error-color-red">
                            {validations.passwordVal}
                          </p>
                        )}
                      </div>
                      {/*<span className="text-white">Requires at least 8 character, including 1 no</span>*/}
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div className="input__block">
                      <label>
                        Account Type
                        <span>*</span>
                      </label>
                      <Select
                        showSearch
                        placeholder="Select AccountType"
                        name="accountType"
                        optionFilterProp="children"
                        onChange={(e) =>
                          handleOnChange(e, "select", "accountType")
                        }
                        value={
                          RegisterData.accountType
                            ? RegisterData.accountType
                            : null
                        }
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        className={
                          validations.accountTypeVal
                            ? "border__red"
                            : "border__grey"
                        }
                      >
                        <Option value="1">Retailer</Option>
                        <Option value="2">Jewelry Vendor</Option>
                        <Option value="3">Diamond Vendor</Option>
                      </Select>
                      <div>
                        {validations.accountTypeVal && (
                          <p className="error-color-red">
                            {validations.accountTypeVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div className="input__block">
                      <label>
                        Contact No
                        <span>*</span>
                      </label>
                      <div className="contno__inptdiv">
                        {/* <input type="text" className="inpt__num" placeholder="+1" disabled /> */}
                        <input
                          type="text"
                          placeholder="Enter Contact No"
                          name="contactNo"
                          value={RegisterData.contactNo}
                          onChange={handleOnChange}
                          className={validations.contactNoVal && "border__red"}
                        />
                      </div>
                      <div>
                        {validations.contactNoVal && (
                          <p className="error-color-red">
                            {validations.contactNoVal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 mx-auto mt-4">
                    <div className="input__block">
                      <Button
                        type="primary"
                        htmlType="button"
                        className="primary-btn w-100"
                        onClick={handleRegister}
                      >
                        Register
                      </Button>

                      <p className="login__btn">
                        Already have a account?
                        <Link to={"/login"} className="manage__Link">
                          {" "}
                          Login Here{" "}
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 p-0">
            <div className="footer__section login__footer position-relative">
              <div className="left__block">
                <div className="footer__logo">
                  <img src={footerLogoImg} />
                </div>
                <div className="footer__menu">
                  <ul>
                    <li>
                      <a href="#">Terms of Service</a>
                    </li>
                    <li>
                      <a href="#">Privacy Statement</a>
                    </li>
                    <li>
                      <a href="#">Copyright 2021 GemFind</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="right__block">
                <ul>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo1} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo2} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo3} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo4} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo5} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className=""
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" className="d-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => setVisible(false)}>
            Ok
          </Button>,
        ]}
      >
        <div className="col-lg-12 text-center">
          <h4 className="subheading">Thank You For Registration</h4>
          <p>Please check your mail for an activation link</p>
        </div>
      </Modal>
    </div>
  );
};

export default Register;
