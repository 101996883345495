import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table,
  Radio,
  Spin,
} from "antd";
import { Accordion } from "react-bootstrap";
import { Tabs, Tab } from "react-bootstrap";
import Noun_arrow from "../../assets/images/noun_arrow.png";
import Rectangle from "../../assets/images/Rectangle.png";
import userIcon from "../../assets/images/user-icon.png";
import UnionIcon from "../../assets/images/icons/Union_5.svg";
import catalogJewelryService from "../../services/catalog-jewelry.service";
import { SizingData } from "../../helper/commanData";
import { NotificationManager } from "react-notifications";
import { useSelector, useDispatch } from "react-redux";
import traslate from "../../i18n/translate";
import { useLocation } from "react-router-dom";

const { Option } = Select;
const AddProducts = () => {
  // const initialState = { CheckBoxlist: {} };
  // const [state, setState] = useState(initialState);
  const [catlogData, setCatlogData] = useState({
    productName: "",
    retailDescription: "",
    retailPrice: "",
    style: "",
    dealerStock: "",
    collection: "",
    materialType: "",
    visibility: true,
    materialColor: "",
    Period: "",
    CheckBoxlist: [],
    file: null,
  });
  const [validations, setValidations] = useState({
    productNameVal: "",
    materialTypeVal: "",
    retailDescriptionVal: "",
    retailPriceVal: "",
    EssentialTabVal: "",
    ImageVal: "",
    CheckBoxVal: "",
    ImageMediaVal: "",
    VideoMediaVal: "",
  });
  const [additionalData, setAdditionalData] = useState({
    QtyOnHand: "",
    DisplayOrder: "",
    Gender: "",
    Setting: "",
    Status: "",
    FinishingTechnique: "",
    ClassType: "",
    ChainType: "",
    BackFinding: "",
    AdditionalInformation: "",
    AdditionalInformation2: "",
  });
  const [sizingData, setSizingData] = useState({
    Weight: "",
    WeightType: "dwt",
    Thickness: "",
    ThicknessType: "mm",
    Width: "",
    WidthType: "mm",
    Dimensions: "",
    UnitOfMeasure: "",
    StockFingerSize: "",
    MinFingerSize: "",
    MaxFingerSize: "",
    FingerSizeIncrement: "",
    VaryingSizeBySizeFinger: [],
  });
  const [pricingData, setPriceingData] = useState({
    PriceMethod: "",
    Retail: "",
    Wholesale: "",
    Msrp: "",
    WholesalePriceMethod: "",
    WholesaleBasePrice: "",
    BaseMetalMarket: "",
    MetalType: "",
    PriceFactor: "",
    RelationalPrice: "",
    RelationalMetalMarket: "",
    MetalLaborCode: "",
    OtherLaborCode: "",
    MetalFactorCode: "",

    RetailPriceMethod: "",
    MSRP: "",
    PriceType: "",
  });
  const [relatedData, setRelatedData] = useState({
    MatchingStyles: "",
    UpSellStyles: "",
    GroupedProductStyles: "",
  });
  const [mediaData, setMediaData] = useState({
    Label: "",
    loadImageFromURL: "",
    loadVideoFromURL: "",
    AddVideoType: "",
    VideoFile: null,
    imageFile: null,
  });
  const [detailsdData, setDetailsdData] = useState({
    BulletPoint1: "",
    BulletPoint2: "",
    BulletPoint3: "",
    BulletPoint4: "",
    BulletPoint5: "",
    DiscountA: "",
    Qty1: "",
    Qty2: "",
    Qty3: "",
    Qty4: "",
    Qty5: "",
    RetailerBrandName: "",
    VendorName: "",
    SecondaryMetalType: "",
    aprod: false,
  });
  const [gemstoneData, setGemstoneData] = useState([
    {
      TotalDiamondWeight: "",
      TotalGemstoneWeight: "",
      GemstoneType: "",
      DimensionUnitOfMeasure: "",
      NumberOfGemstones: "",
      GemstoneShape: "",
      GemstoneOrigin: "",
      GemstoneCaratWeight: "",
      GemstoneDimensions: "",
      GemstoneQuality: "",
    },
  ]);

  const [watchData, setWatchData] = useState({
    BandMaterial: "",
    NumberType: "",
    BandType: "",
    Size: "",
    CaseMaterial: "",
    Type: "",
    CaseShape: "",
    ComesPackagedIn: "",
    CrystalType: "",
    Warranty: "",
    Bezel: "",
    WatchCondition: "",
    DialColor: "",
    ManufactureDate: "",
    DisplayType: "",
    Certification: "",
    Movement: "",
    Energy: "",
  });
  const [selectedTab, setSelectedTab] = useState("essential");
  const [counter, setCounter] = useState(0);

  useEffect(() => {}, [counter]);

  /// Dropdown Lists
  const [gemStoneTypeList, setGemStoneTypeList] = useState([]);
  const [gemStoneShapeList, setGemStoneShapeList] = useState([]);
  const [metalTypeList, setMetalTypeList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [settingList, setSettingList] = useState([]);
  const [deliveryList, setDeliveryList] = useState([]);
  const [collectionList, setCollectionList] = useState([]);
  const [jewelryMetalTypeList, setJewelryMetalTypeList] = useState([]);
  const [jewelryMetalColorList, setJewelryMetalColorList] = useState([]);
  const [watchBandMaterialList, setWatchBandMaterialList] = useState([]);
  const [bandTypeList, setBandTypeList] = useState([]);
  const [caseMaterialList, setCaseMaterialList] = useState([]);
  const [caseShapeList, setCaseShapeList] = useState([]);
  const [crystalTypeList, setCrystalTypeList] = useState([]);
  const [watchDialColorList, setWatchDialColorList] = useState([]);
  const [displayTypeList, setDisplayTypeList] = useState([]);
  const [watchMovementList, setWatchMovementList] = useState([]);
  const [watchNumberTypeList, setWatchNumberTypeList] = useState([]);
  const [watchSizeList, setWatchSizeList] = useState([]);
  const [watchTypeList, setWatchTypeList] = useState([]);
  const [watchEnergyList, setWatchEnergyList] = useState([]);
  const [jewelryAttributeList, setJewelryAttributeList] = useState([]);

  const [sizingDataTable, setSizingDataTable] = useState(SizingData);

  const [loading, setLoading] = useState(false);
  const [CategoryLoading, setCategoryLoading] = useState(false);
  const [data, setData] = useState([]);

  const [dealerId, setDealerId] = useState();
  const [dealerCompany, setdealerCompany] = useState("");
  const loginDetials = useSelector((state) => state.loginReducer);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [jewelryCategoryList, setjewelryCategoryList] = useState([]);
  const [selectSizingRow, setSelectSizingRow] = useState([]);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          setdealerCompany(
            loginDetials.loginDetials.responseData.dealerCompany
          );
        }
      }
    }
  }, []);

  useEffect(() => {
    handleGetAdditionalToGemStoneTabData();
    handleGetEssentialTabData();
    handleGetWatchToAttributeListTabData();
    GetProductCategoryList();
  }, []);

  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      if (location.state.key) {
        handleGetProductDetailsByID(location.state.key);
      }
    }
  }, []);

  const handleGetProductDetailsByID = (id) => {
    var inputData = {
      gfInventoryID: id,
    };
    try {
      setLoading(true);
      catalogJewelryService
        .GetProductDetailsByID(inputData)
        .then((response) => {
          var responseData = response.data.responseData;
          var message = response.data.message;
          setLoading(false);
          if (message == "Success") {
            setCatlogData({
              productName: responseData.productName,
              retailDescription: responseData.productDescription,
              retailPrice: responseData.retailPrice,
              style: responseData.styleNumber,
              dealerStock: responseData.dealerStockNumber,
              collection: responseData.collectionID,
              materialType: responseData.metalTypeID,
              visibility: responseData.visibleAs,
              materialColor: responseData.metalColorID,
              Period: responseData.periodID,
              CheckBoxlist: [],
              file: responseData.imagePath,
            });
            setAdditionalData({
              QtyOnHand: responseData.quantity,
              DisplayOrder: responseData.displayOrder,
              Gender: responseData.genderID,
              Setting: responseData.setting,
              Status: responseData.statusID,
              FinishingTechnique: responseData.finishingTechnique,
              ClassType: responseData.claspType,
              ChainType: responseData.chainType,
              BackFinding: responseData.backFinding,
              AdditionalInformation: responseData.additionalInformation,
              AdditionalInformation2: responseData.additionalInformation2,
            });
            setSizingData({
              Weight: responseData.weightUnit,
              WeightType: responseData.weight_gm,
              Thickness: responseData.thicknessUnit,
              ThicknessType: responseData.thickness_mm,
              Width: responseData.widthUnit,
              WidthType: responseData.width_mm,
              Dimensions: responseData.dimensions,
              UnitOfMeasure: responseData.dimensionUnitOfMeasure,
              StockFingerSize: responseData.fingerSize,
              MinFingerSize: responseData.fingerSizeMinRange,
              MaxFingerSize: responseData.fingerSizeMaxRange,
              FingerSizeIncrement: responseData,
              VaryingSizeBySizeFinger: [],
            });
            setPriceingData({
              PriceMethod: responseData.wholesalePriceMethod,
              Retail:
                responseData.wholesalePriceMethod == 1
                  ? responseData.wholesalePriceDetails
                  : 0,
              Wholesale:
                responseData.wholesalePriceMethod == 2
                  ? responseData.wholesalePriceDetails
                  : 0,
              Msrp:
                responseData.wholesalePriceMethod == 3
                  ? responseData.wholesalePriceDetails
                  : 0,
            });
          } else {
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const GetProductCategoryList = () => {
    try {
      setLoading(true);
      catalogJewelryService
        .GetProductCategoryList()
        .then((response) => {
          var responseData = response.data.responseData;
          var message = response.data.message;
          setLoading(false);
          if (message == "Success") {
            setjewelryCategoryList(responseData.jewelryCategoryList);
          } else {
            setjewelryCategoryList([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleGetWatchToAttributeListTabData = () => {
    var inputData = {
      dealerID: 0,
    };
    try {
      catalogJewelryService
        .GetWatchToAttributeListTabData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var WatchBandMaterialList =
            response.data.responseData.getWatchBandMaterialList;
          var BandTypeList = response.data.responseData.getBandTypeList;
          var CaseMaterialList = response.data.responseData.getCaseMaterialList;
          var CaseShapeList = response.data.responseData.getCaseShapeList;
          var CrystalTypeList = response.data.responseData.getCrystalTypeList;
          var WatchDialColorList =
            response.data.responseData.getWatchDialColorList;
          var DisplayTypeList = response.data.responseData.getDisplayTypeList;
          var WatchMovementList =
            response.data.responseData.getWatchMovementList;
          var WatchNumberTypeList =
            response.data.responseData.getWatchNumberTypeList;
          var WatchSizeList = response.data.responseData.getWatchSizeList;
          var WatchTypeList = response.data.responseData.getWatchTypeList;
          var WatchEnergyList = response.data.responseData.getWatchEnergyList;
          var JewelryAttributeList =
            response.data.responseData.getJewelryAttributeList;

          if (message == "Success") {
            setWatchBandMaterialList(WatchBandMaterialList);
            setBandTypeList(BandTypeList);
            setCaseMaterialList(CaseMaterialList);
            setCaseShapeList(CaseShapeList);
            setCrystalTypeList(CrystalTypeList);
            setWatchDialColorList(WatchDialColorList);
            setDisplayTypeList(DisplayTypeList);
            setWatchMovementList(WatchMovementList);
            setWatchNumberTypeList(WatchNumberTypeList);
            setWatchSizeList(WatchSizeList);
            setWatchTypeList(WatchTypeList);
            setWatchEnergyList(WatchEnergyList);
            setJewelryAttributeList(JewelryAttributeList);
          } else {
            setWatchBandMaterialList([]);
            setBandTypeList([]);
            setCaseMaterialList([]);
            setCaseShapeList([]);
            setCrystalTypeList([]);
            setWatchDialColorList([]);
            setDisplayTypeList([]);
            setWatchMovementList([]);
            setWatchNumberTypeList([]);
            setWatchSizeList([]);
            setWatchTypeList([]);
            setWatchEnergyList([]);
            setJewelryAttributeList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetEssentialTabData = () => {
    var inputData = {
      dealerID: 0,
    };
    try {
      catalogJewelryService
        .GetEssentialTabData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var DeliveryTimeList = response.data.responseData.getDeliveryTimeList;
          var CollectionList = response.data.responseData.getCollectionList;
          var JewelryMetalTypeList =
            response.data.responseData.getJewelryMetalTypeList;
          var JewelryMetalColorList =
            response.data.responseData.getJewelryMetalColorList;
          if (message == "Success") {
            setDeliveryList(DeliveryTimeList);
            setCollectionList(CollectionList);
            setJewelryMetalTypeList(JewelryMetalTypeList);
            setJewelryMetalColorList(JewelryMetalColorList);
          } else {
            setDeliveryList([]);
            setCollectionList([]);
            setJewelryMetalColorList([]);
            setJewelryMetalTypeList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAdditionalToGemStoneTabData = () => {
    var inputData = {
      productID: 0,
    };
    try {
      catalogJewelryService
        .GetAdditionalToGemStoneTabData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var GemStoneTypeList = response.data.responseData.getGemstoneTypeList;
          var GemstoneShapeList =
            response.data.responseData.getGemstoneShapeList;
          var MetalTypeList = response.data.responseData.getMetalTypeList;
          var GenderList = response.data.responseData.getGenderList;
          var SettingList = response.data.responseData.getSettingList;

          if (message == "Success") {
            setGemStoneTypeList(GemStoneTypeList);
            setGemStoneShapeList(GemstoneShapeList);
            setMetalTypeList(MetalTypeList);
            setGenderList(GenderList);
            setSettingList(SettingList);
          } else {
            setGemStoneTypeList([]);
            setGemStoneShapeList([]);
            setMetalTypeList([]);
            setGenderList([]);
            setSettingList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const retail = <span>Tooltip content not given.</span>;

  const style = (
    <span>
      {traslate("Thismustbeauniqueitemnumberidentifyingtheparticularitemorthe")}
      "{traslate("parent")}"{" "}
      {traslate(
        "itemwhichhasmanyvariations-ifthisisastylerootthenyouwillwanttosetthisiemasa"
      )}
      "{traslate("configurableproduct")}"{" "}
      {traslate(
        "toconfigurethevariablessuchascaratweight,metaltype,diamondquality,lengthetc."
      )}
    </span>
  );

  const dealer_stock = (
    <span>
      {" "}
      {traslate("Thisisauniqueidentificationnumberofthisparticularitem.")}
    </span>
  );

  const availability = (
    <span>Standard delivery time is 2 weeks unless you specify otherwise.</span>
  );

  const sku = <span>Leave blank, unless adding a configurable product.</span>;

  const handleChangeCheckBox = (e, Id) => {
    const tempProductList = jewelryCategoryList.map((x) => {
      if (x.jewelryCategoryID === Id) {
        return { ...x, ["isChecked"]: e.target.checked };
      } else return x;
    });
    setjewelryCategoryList(tempProductList);
    console.log(tempProductList);
  };

  const handleSizingTableChange = (name, row, e) => {
    if (name === "additionalPrice") {
      const tempDealerColumnList = sizingDataTable.map((x) => {
        if (x.key === row.key) {
          return { ...x, additionalPrice: e.target.value };
        } else return x;
      });
      setSizingDataTable(tempDealerColumnList);
    }
    if (name === "priceType") {
      const tempDealerColumnList = sizingDataTable.map((x) => {
        if (x.key === row.key) {
          return { ...x, priceType: e };
        } else return x;
      });
      setSizingDataTable(tempDealerColumnList);
    }
  };

  const handleValidation = () => {
    const validations = {};
    let isValid = true;
    if (!catlogData.file) {
      isValid = false;
      validations.ImageVal = "Please select the image";
    } else {
      validations.ImageVal = "";
    }
    if (!catlogData.productName) {
      isValid = false;
      validations.productNameVal = "Product name is compulsory";
    } else {
      validations.productNameVal = "";
    }
    if (!catlogData.retailDescription) {
      isValid = false;
      validations.retailDescriptionVal = "Retail Description is compulsory";
    } else {
      validations.retailDescriptionVal = "";
    }
    if (!catlogData.retailPrice) {
      isValid = false;
      validations.retailPriceVal = "Retail Price is compulsory";
    } else {
      validations.retailPriceVal = "";
    }
    if (!catlogData.materialType) {
      isValid = false;
      validations.materialTypeVal = "Kindly select the Material Type ";
    } else {
      validations.materialTypeVal = "";
    }
    if (jewelryCategoryList.filter((x) => x.isChecked === true).length == 0) {
      isValid = false;
      validations.CheckBoxVal = "Kindly select the Category";
    } else {
      validations.CheckBoxVal = "";
    }
    if (
      validations.productNameVal ||
      validations.retailDescriptionVal ||
      validations.retailPriceVal ||
      validations.materialTypeVal ||
      validations.ImageVal ||
      validations.CheckBoxVal
    ) {
      isValid = false;
      validations.EssentialTabVal =
        "Kindly select all the necessary Field from essential";
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const handleSubmit = () => {
    var obj = catlogData.CheckBoxlist;
    var keys = Object.keys(obj);
    var filtered = keys.filter(function (key) {
      return obj[key];
    });
    var CategoryData = filtered + ",";
    console.log(CategoryData);

    // var gemstoneDataApi = gemstoneData.map((data) => {
    //   return data;
    // });

    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    const formData = new FormData();
    formData.append("GFInventoryID", 0);
    formData.append("dealerID", Number(dealerId));
    formData.append("StyleNumber", Number(catlogData.style));
    formData.append("DealerStockNumber", catlogData.dealerStock);
    formData.append("RetailerStockNumber", catlogData.retailStock);
    formData.append("ProductName", catlogData.productName);
    formData.append("ShortDescription", additionalData.WholesaleDescription);
    formData.append("ProductDescription", catlogData.retailDescription);
    formData.append("DisplayOrder", Number(additionalData.DisplayOrder));
    formData.append("StatusID", Number(additionalData.Status));
    formData.append("MetalTypeID", Number(catlogData.materialType));
    formData.append("MetalColorID", Number(catlogData.materialColor));
    formData.append("IsCostBaseWholesale", true);
    formData.append("WholesalePrice", 0);
    formData.append("Terms", "");
    formData.append("IsStockBalancing", true);
    formData.append("RetailPriceCodeID", 0);
    formData.append("RetailPriceCodeID2", 0);
    formData.append("RetailPrice", catlogData.retailPrice);
    formData.append("IsCalculatedRetailPrice", 0);
    formData.append("MSRP", Number(pricingData.MSRP));
    formData.append("MSRP2", 0);
    formData.append("MSRPTypeID", Number(pricingData.PriceType));
    formData.append("IsCalculatedMSRP", 0);
    formData.append("DeliveryTimeID", 0);
    formData.append("ImagePath", 0);
    formData.append("productImage", catlogData.file);
    formData.append("FlashFileCode", mediaData.FlashFileCode);
    formData.append("videoFile", mediaData.VideoFile);
    formData.append("VideoURL", mediaData.loadVideoFromURL);
    formData.append("VideoType", mediaData.AddVideoType);
    formData.append(
      "AdditionalInformation",
      additionalData.AdditionalInformation
    );
    formData.append("BrandName", "");
    formData.append("StyleID", 0);
    formData.append("Theme", "");
    formData.append("FinishingTechnique", additionalData.FinishingTechnique);
    formData.append("Setting", additionalData.Setting);
    formData.append("GenderID", Number(additionalData.Gender));
    formData.append("PrimaryDiamondShape", "");
    formData.append("NumberOfPrimaryDiamonds", 0);
    formData.append("PrimaryDiamondTotalWeight", 0);
    formData.append("PrimaryDiamondClarity", 0);
    formData.append("PrimaryDiamondColor", "");
    formData.append("PrimaryDiamondSecondaryColor", "");
    formData.append("PrimaryDiamondSaturation", "");
    formData.append("PrimaryDiamondCertificateType", "");
    formData.append("PrimaryDiamondCertificateNumber", "");
    formData.append("SecondaryDiamondShape", "");
    formData.append("NumberOfSecondaryDiamonds", 0);
    formData.append("SecondaryDiamondTotalWeight", 0);
    formData.append("SecondaryDiamondClarity", "");
    formData.append("SecondaryDiamondColor", "");
    formData.append("SecondaryDiamondSecondaryColor", "");
    formData.append("SecondaryDiamondSaturation", "");
    formData.append("OtherDiamondInfo", "");
    formData.append("OtherDiamondTotalWeight", 0);
    formData.append("TotalDiamondWeight", 0);
    formData.append("PrimaryGemstoneType", "");
    formData.append("PrimaryGemstoneShape", "");
    formData.append("NumberOfPrimaryGemstones", 0);
    formData.append("PrimaryGemstoneCaratWeight", 0);
    formData.append("PrimaryGemstoneDimensions", "");
    formData.append("PrimaryGemstoneOrigin", "");
    formData.append("PrimaryGemstoneQuality", "");
    formData.append("SecondaryGemstoneType", "");
    formData.append("SecondaryGemstoneShape", "");
    formData.append("NumberOfSecondaryGemstones", 0);
    formData.append("SecondaryGemstoneCaratWeight", 0);
    formData.append("SecondaryGemstoneDimensions", "");
    formData.append("SecondaryGemstoneOrigin", "");
    formData.append("SecondaryGemstoneQuality", "");
    formData.append("OtherGemstoneInfo", "");
    formData.append("OtherGemstoneCaratWeight", 0);
    formData.append("OtherGemstoneQuality", "");
    formData.append("TotalGemstoneWeight", 0);
    formData.append("Width_mm", Number(sizingData.Width));
    formData.append("Thickness_mm", Number(sizingData.Thickness));
    formData.append("Length_in", 0);
    formData.append("Weight_gm", Number(sizingData.Weight));
    formData.append("FingerSize", Number(sizingData.StockFingerSize));
    formData.append("FingerSizeMinRange", Number(sizingData.MinFingerSize));
    formData.append("FingerSizeMaxRange", Number(sizingData.MaxFingerSize));
    formData.append("MatchingSKUs", relatedData.MatchingStyles);
    formData.append("UpSellSKUs", relatedData.UpSellStyles);
    formData.append("GroupedProductSKUs", relatedData.GroupedProductStyles);
    formData.append("AllowableCenterStoneShapes", "");
    formData.append("AllowableCenterStoneSizes", "");
    formData.append("DesignerID", 0);
    formData.append("FlgIsBlocked", true);
    formData.append("StartingAt", true);
    formData.append("MetalMarket", "");
    formData.append("PriceLastUpdated", "2016-01-04 10:34:23");
    formData.append("AllowableMinCenterStoneSizes", "");
    formData.append("AllowableMaxCenterStoneSizes", "");
    formData.append("Quantity", Number(additionalData.QtyOnHand));
    formData.append("HallMark", "");
    formData.append("ConditionID", 0);
    formData.append("IsShortForm", true);
    formData.append("PriceType", 0);
    formData.append("DiscountLevelType1", 0);
    formData.append("DiscountLevelValue1", 0);
    formData.append("DiscountLevelType2", 0);
    formData.append("DiscountLevelValue2", 0);
    formData.append("RetailDiscountType", 0);
    formData.append("RetailDiscountValue", 0);
    formData.append("SelectedAttributes", "");
    formData.append(
      "ProductType",
      Number(catlogData.productType) ? Number(catlogData.productType) : 0
    );
    formData.append("ParentSKU", catlogData.parentSKU);
    formData.append("WatchBandMaterialID", Number(watchData.BandMaterial));
    formData.append("WatchBandTypeID", Number(watchData.BandType));
    formData.append("WatchCaseMaterialID", Number(watchData.CaseMaterial));
    formData.append("WatchCaseShapeID", Number(watchData.CaseShape));
    formData.append("WatchCrystalTypeID", Number(watchData.CrystalType));
    formData.append("WatchBezel", watchData.Bezel);
    formData.append("WatchDialColorID", Number(watchData.DialColor));
    formData.append("WatchDisplayTypeID", Number(watchData.DisplayType));
    formData.append("WatchMovementID", Number(watchData.Movement));
    formData.append("WatchNumberTypeID", Number(watchData.NumberType));
    formData.append("WatchSizeID", Number(watchData.Size));
    formData.append("WatchTypeID", Number(watchData.Type));
    formData.append(
      "ComesPackagedIn",
      watchData.ComesPackagedIn ? watchData.ComesPackagedIn : ""
    );
    formData.append("Warranty", watchData.Warranty);
    formData.append("WatchCondition", watchData.WatchCondition);
    formData.append("WatchManufactureDate", watchData.ManufactureDate);
    formData.append("WatchCertification", watchData.Certification);
    formData.append("WatchEnergyID", Number(watchData.Energy));
    formData.append("WholesalePriceFactor", Number(pricingData.PriceFactor));
    formData.append("WholesaleBasePrice", 0);
    formData.append("BaseMetalMarket", Number(pricingData.BaseMetalMarket));
    formData.append(
      "RelationalWholesalePrice",
      Number(pricingData.RelationalPrice)
    );
    formData.append(
      "RelationalMarketBase",
      Number(pricingData.RelationalMetalMarket)
    );
    formData.append(
      "IsCalcualtedWholesale",
      Number(pricingData.WholesalePriceMethod)
    );
    formData.append("MetalLaborCode", pricingData.MetalLaborCode);
    formData.append("OtherLaborCode", pricingData.OtherLaborCode);
    formData.append("VisibleAs", catlogData.visibility);
    formData.append("BaseMetalID", Number(pricingData.MetalType));
    formData.append("WeightUnit", sizingData.WeightType);
    formData.append("WidthUnit", sizingData.WidthType);
    formData.append("ThicknessUnit", sizingData.ThicknessType);
    formData.append("CustomAttribute", "");
    formData.append("CustomAttributeLabel", "");
    formData.append("Dimensions", sizingData.Dimensions);
    formData.append("HasImage", true);
    formData.append("ImageLabel", mediaData.Label);
    formData.append("GPMCode", pricingData.GPMCode);
    formData.append("MetalFactorCode", pricingData.MetalFactorCode);
    formData.append("DiscountA", detailsdData.DiscountA);
    formData.append("Qty1", Number(detailsdData.Qty1));
    formData.append("Qty2", Number(detailsdData.Qty2));
    formData.append("Qty3", Number(detailsdData.Qty3));
    formData.append("Qty4", Number(detailsdData.Qty4));
    formData.append("Qty5", Number(detailsdData.Qty5));
    formData.append(
      "FingerSizeIncrement",
      Number(sizingData.FingerSizeIncrement)
        ? Number(sizingData.FingerSizeIncrement)
        : 0
    );
    formData.append("VendorName", detailsdData.VendorName);
    formData.append("RetailerBrandName", detailsdData.RetailerBrandName);
    formData.append("DimensionUnitOfMeasure", sizingData.UnitOfMeasure);
    formData.append("ClaspType", additionalData.ClassType);
    formData.append("ChainType", additionalData.ChainType);
    formData.append("BackFinding", additionalData.BackFinding);
    formData.append(
      "AdditionalInformation2",
      additionalData.AdditionalInformation2
    );

    formData.append("AmazonProduct", detailsdData.aprod);
    formData.append("SecondaryMetalType", detailsdData.SecondaryMetalType);
    formData.append("inventorypostdate", "");
    formData.append("publicviewing", true);
    formData.append("outonmemo", "");
    formData.append("inventoryregion", "");
    formData.append("dealerinventoryno", "");
    formData.append("employeename", "");
    formData.append("employeeid", "");
    formData.append("style", "");
    formData.append("gender", "");
    formData.append("stonesetting", "");
    formData.append("metaltype", "");
    formData.append("centerstone", "");
    formData.append("centerstoneshape", "");
    formData.append("centerstoneweight", "");
    formData.append("totalweight", "");
    formData.append("occasion", "");
    formData.append("chainlength", 0);
    formData.append("backingtype", "");
    formData.append("price", 0);
    formData.append("comments", "");
    formData.append("image", "");
    formData.append("image2", "");
    formData.append("image3", "");
    formData.append("image4", "");
    formData.append("LargeImage", "");
    formData.append("IsImageAvailable", true);
    formData.append("Availability", catlogData.availability);
    formData.append("Keywords", "");
    formData.append("CenterStoneSize", 0);
    formData.append("ChainWidth", 0);
    formData.append("SideMultipleStoneType", "");
    formData.append("SideMultipleStoneShape", "");
    formData.append("SideMultipleStoneTotalWeight", 0);
    formData.append("SideMultipleStoneSize", 0);
    formData.append("MetalWeight", 0);
    formData.append("FlgIsBlockedByAdmin", true);
    formData.append("DetailsLink", "");
    formData.append("IsCartEnabled", true);
    formData.append("IsPriceEnabled", true);
    formData.append("SecMetalType", "");
    formData.append("AddInfo", "");
    formData.append("Title", "");
    formData.append("AvailablePriceOnline", true);
    formData.append("IsGiftIdea", true);
    formData.append("MSRPPrice", 0);
    formData.append("NoOfDiamonds", 0);
    formData.append("CenterStoneColor", "");
    formData.append("CenterStoneClarity", "");
    formData.append("CenterStoneCut", "");
    formData.append("CenterStoneCertificate", "");
    formData.append("SideStoneShapes", "");
    formData.append("SideStonesAverageColor", "");
    formData.append("SideStonesAverageClarity", "");
    formData.append("SideStonesAverageCut", "");
    formData.append("GemStone", "");
    formData.append("GemStoneShape", "");
    formData.append("GemStoneColor", "");
    formData.append("GemStoneCarats", 0);
    formData.append("GemStoneDimension", "");
    formData.append("NumberofGemstones", 0);
    formData.append("SideStonesWeight", "");
    formData.append("BlockComment", "");
    formData.append("CartLink", "");
    formData.append("RingSize", "");
    formData.append("RelatedSKU", "");
    formData.append("Collection", catlogData.collection);
    formData.append("Createdby", true);
    formData.append(
      "WholesalePriceDetails",
      pricingData.PriceMethod == 1
        ? pricingData.Retail
        : pricingData.PriceMethod == 2
        ? pricingData.Wholesale
        : pricingData.PriceMethod == 3
        ? pricingData.Msrp
        : 0
    );
    formData.append("WholesalePriceMethod", Number(pricingData.PriceMethod));

    formData.append("UserName", "");
    formData.append("AddProductFields.strControlID", "");
    formData.append("AddProductFields.strControlDisplayOrder", "");
    formData.append("AddProductFields.strAttributeID", "");
    var strCategories = jewelryCategoryList
      .filter((x) => x.isChecked == true)
      .map((x) => x.jewelryCategoryID)
      .join(",");
    formData.append("AddProductFields.strCategories", strCategories);
    formData.append("AddProductFields.strCollections", "");
    formData.append("AddProductFields.chkAsscher", true);
    formData.append("AddProductFields.txtAsscherMax", "");
    formData.append("AddProductFields.txtAsscherMin", "");
    formData.append("AddProductFields.chkCusion", true);
    formData.append("AddProductFields.txtCusionMax", "");
    formData.append("AddProductFields.txtCusionMin", "");
    formData.append("AddProductFields.chkEmeraldCut", true);
    formData.append("AddProductFields.txtEmeraldCutMax", "");
    formData.append("AddProductFields.txtEmeraldCutMin", "");
    formData.append("AddProductFields.chkHeart", true);
    formData.append("AddProductFields.txtHeartMax", "");
    formData.append("AddProductFields.txtHeartMin", "");
    formData.append("AddProductFields.chkMarquise", true);
    formData.append("AddProductFields.txtMarquiseMax", "");
    formData.append("AddProductFields.txtMarquiseMin", "");
    formData.append("AddProductFields.chkOval", true);
    formData.append("AddProductFields.txtOvalMax", "");
    formData.append("AddProductFields.txtOvalMin", "");
    formData.append("AddProductFields.chkPear", true);
    formData.append("AddProductFields.txtPearMax", "");
    formData.append("AddProductFields.txtPearMin", "");
    formData.append("AddProductFields.chkPrincess", true);
    formData.append("AddProductFields.txtPrincessMax", "");
    formData.append("AddProductFields.txtPrincessMax", "");
    formData.append("AddProductFields.chkRadiant", true);
    formData.append("AddProductFields.txtRadiantMax", "");
    formData.append("AddProductFields.txtRadiantMin", "");
    formData.append("AddProductFields.chkRound", true);
    formData.append("AddProductFields.txtRoundMax", "");
    formData.append("AddProductFields.txtRoundMin", "");
    formData.append("AddProductFields.ImagePath", "");
    formData.append("AddProductFields.ImageLabel", "");
    formData.append(
      "AddProductFields.GetGemstoneDataList",
      JSON.stringify(gemstoneData)
    );
    formData.append(
      "AddProductFields.FingerSizeList",
      JSON.stringify(selectSizingRow)
    );
    formData.append("AssBulletPoint.BulletPoint1", detailsdData.BulletPoint1);
    formData.append("AssBulletPoint.BulletPoint2", detailsdData.BulletPoint2);
    formData.append("AssBulletPoint.BulletPoint3", detailsdData.BulletPoint3);
    formData.append("AssBulletPoint.BulletPoint4", detailsdData.BulletPoint4);
    formData.append("AssBulletPoint.BulletPoint5", detailsdData.BulletPoint5);

    try {
      setSubmitLoading(true);
      catalogJewelryService
        .ManageAddProduct(formData)
        .then((response) => {
          var message = response.data.message;
          setLoading(false);
          if (message == "Success") {
            NotificationManager.success("Data Added Successfully.");
            setCounter(counter + 1);
            GetProductCategoryList();
            setSubmitLoading(false);
            setSelectSizingRow(null);
            setCatlogData({
              productName: "",
              retailDescription: "",
              retailPrice: "",
              style: "",
              dealerStock: "",
              collection: "",
              materialType: "",
              visibility: true,
              materialColor: "",
              Period: "",
              CheckBoxlist: [],
              file: null,
            });
            setValidations({
              productNameVal: "",
              materialTypeVal: "",
              retailDescriptionVal: "",
              retailPriceVal: "",
              EssentialTabVal: "",
              ImageVal: "",
              CheckBoxVal: "",
              ImageMediaVal: "",
              VideoMediaVal: "",
            });
            setAdditionalData({
              QtyOnHand: "",
              DisplayOrder: "",
              Gender: "",
              Setting: "",
              Status: "",
              FinishingTechnique: "",
              ClassType: "",
              ChainType: "",
              BackFinding: "",
              AdditionalInformation: "",
              AdditionalInformation2: "",
            });
            setSizingData({
              Weight: "",
              WeightType: "dwt",
              Thickness: "",
              ThicknessType: "mm",
              Width: "",
              WidthType: "mm",
              Dimensions: "",
              UnitOfMeasure: "",
              StockFingerSize: "",
              MinFingerSize: "",
              MaxFingerSize: "",
              FingerSizeIncrement: "",
              VaryingSizeBySizeFinger: [],
            });

            setPriceingData({
              WholesalePriceMethod: "",
              WholesaleBasePrice: "",
              BaseMetalMarket: "",
              MetalType: "",
              PriceFactor: "",
              RelationalPrice: "",
              RelationalMetalMarket: "",
              MetalLaborCode: "",
              OtherLaborCode: "",
              MetalFactorCode: "",

              RetailPriceMethod: "",
              MSRP: "",
              PriceType: "",
            });
            setRelatedData({
              MatchingStyles: "",
              UpSellStyles: "",
              GroupedProductStyles: "",
            });
            setMediaData({
              Label: "",
              loadImageFromURL: "",
              loadVideoFromURL: "",
              AddVideoType: "",
              VideoFile: null,
              imageFile: null,
            });
            setDetailsdData({
              BulletPoint1: "",
              BulletPoint2: "",
              BulletPoint3: "",
              BulletPoint4: "",
              BulletPoint5: "",
              DiscountA: "",
              Qty1: "",
              Qty2: "",
              Qty3: "",
              Qty4: "",
              Qty5: "",
              RetailerBrandName: "",
              VendorName: "",
              SecondaryMetalType: "",
              aprod: false,
            });
            setGemstoneData([
              {
                TotalDiamondWeight: "",
                TotalGemstoneWeight: "",
                GemstoneType: "",
                DimensionUnitOfMeasure: "",
                NumberOfGemstones: "",
                GemstoneShape: "",
                GemstoneOrigin: "",
                GemstoneCaratWeight: "",
                GemstoneDimensions: "",
                GemstoneQuality: "",
              },
            ]);
            setWatchData({
              BandMaterial: "",
              NumberType: "",
              BandType: "",
              Size: "",
              CaseMaterial: "",
              Type: "",
              CaseShape: "",
              ComesPackagedIn: "",
              CrystalType: "",
              Warranty: "",
              Bezel: "",
              WatchCondition: "",
              DialColor: "",
              ManufactureDate: "",
              DisplayType: "",
              Certification: "",
              Movement: "",
              Energy: "",
            });
            setSelectedTab("essential");
          } else {
            NotificationManager.error("Data Not Added.");
            setSubmitLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setSubmitLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSubmitLoading(false);
    }
  };

  const [switchValue, setswitchValue] = useState(true);

  function onSearch(val) {
    console.log("search:", val);
  }

  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    if (selectedTab == "essential") {
      const tempCatlogData = catlogData;
      tempCatlogData[name] = value;
      setCatlogData((prevState) => ({
        ...prevState,
        ...tempCatlogData,
      }));
    }
    if (selectedTab == "Additional") {
      const tempAdditionalData = additionalData;
      tempAdditionalData[name] = value;
      setAdditionalData((prevState) => ({
        ...prevState,
        ...tempAdditionalData,
      }));
    }
    if (selectedTab == "sizing") {
      const tempSizingData = sizingData;
      tempSizingData[name] = value;
      setSizingData((prevState) => ({
        ...prevState,
        ...tempSizingData,
      }));
    }
    if (selectedTab == "pricing") {
      const tempPricingData = pricingData;
      tempPricingData[name] = value;
      setPriceingData((prevState) => ({
        ...prevState,
        ...tempPricingData,
      }));
    }
    if (selectedTab == "related") {
      const tempRelatedData = relatedData;
      tempRelatedData[name] = value;
      setRelatedData((prevState) => ({
        ...prevState,
        ...tempRelatedData,
      }));
    }
    if (selectedTab == "media") {
      const tempMediaData = mediaData;
      tempMediaData[name] = value;
      setMediaData((prevState) => ({
        ...prevState,
        ...tempMediaData,
      }));
    }
    if (selectedTab == "details") {
      const tempDetailsData = detailsdData;
      tempDetailsData[name] = value;
      setDetailsdData((prevState) => ({
        ...prevState,
        ...tempDetailsData,
      }));
    }
    if (selectedTab == "Gemstone") {
      const tempGemstoneData = gemstoneData;
      tempGemstoneData[name] = value;
      setGemstoneData((prevState) => ({
        ...prevState,
        ...tempGemstoneData,
      }));
    }

    if (selectedTab == "watch") {
      const tempWatchData = watchData;
      tempWatchData[name] = value;
      setWatchData((prevState) => ({
        ...prevState,
        ...tempWatchData,
      }));
    }
  };
  const handleSelectChange = (name, value) => {
    if (selectedTab == "essential") {
      const tempCatlogData = catlogData;
      tempCatlogData[name] = value;
      setCatlogData((prevState) => ({
        ...prevState,
        ...tempCatlogData,
      }));
    }
    if (selectedTab == "Additional") {
      const tempAdditionalData = additionalData;
      tempAdditionalData[name] = value;
      setAdditionalData((prevState) => ({
        ...prevState,
        ...tempAdditionalData,
      }));
    }
    if (selectedTab == "sizing") {
      const tempSizingData = sizingData;
      tempSizingData[name] = value;
      setSizingData((prevState) => ({
        ...prevState,
        ...tempSizingData,
      }));
    }
    if (selectedTab == "pricing") {
      const tempPricingData = pricingData;
      tempPricingData[name] = value;
      setPriceingData((prevState) => ({
        ...prevState,
        ...tempPricingData,
      }));
    }
    if (selectedTab == "related") {
      const tempRelatedData = relatedData;
      tempRelatedData[name] = value;
      setRelatedData((prevState) => ({
        ...prevState,
        ...tempRelatedData,
      }));
    }
    if (selectedTab == "media") {
      const tempMediaData = mediaData;
      tempMediaData[name] = value;
      setMediaData((prevState) => ({
        ...prevState,
        ...tempMediaData,
      }));
    }
    if (selectedTab == "details") {
      const tempDetailsData = detailsdData;
      tempDetailsData[name] = value;
      setDetailsdData((prevState) => ({
        ...prevState,
        ...tempDetailsData,
      }));
    }
    if (selectedTab == "Gemstone") {
      const tempGemstoneData = gemstoneData;
      tempGemstoneData[name] = value;
      setGemstoneData((prevState) => ({
        ...prevState,
        ...tempGemstoneData,
      }));
    }

    if (selectedTab == "watch") {
      const tempWatchData = watchData;
      tempWatchData[name] = value;
      setWatchData((prevState) => ({
        ...prevState,
        ...tempWatchData,
      }));
    }
  };
  const handleTabsChange = (e) => {
    setSelectedTab(e);
  };

  const handleCheckBoxChange = (e) => {
    if (selectedTab == "sizing") {
      const tempDetailsData = sizingData;
      tempDetailsData[e.target.name] = e.target.value;
      setSizingData((prevState) => ({
        ...prevState,
        ...tempDetailsData,
      }));
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectSizingRow(selectedRows);
    },
  };

  const handleCancel = () => {
    setCatlogData({
      productName: "",
      retailDescription: "",
      retailPrice: "",
      style: "",
      dealerStock: "",
      collection: "",
      materialType: "",
      visibility: true,
      materialColor: "",
      Period: "",
      CheckBoxlist: [],
      file: null,
    });
    setValidations({
      productNameVal: "",
      materialTypeVal: "",
      retailDescriptionVal: "",
      retailPriceVal: "",
      EssentialTabVal: "",
      ImageVal: "",
      CheckBoxVal: "",
      ImageMediaVal: "",
      VideoMediaVal: "",
    });
    setAdditionalData({
      QtyOnHand: "",
      DisplayOrder: "",
      Gender: "",
      Setting: "",
      Status: "",
      FinishingTechnique: "",
      ClassType: "",
      ChainType: "",
      BackFinding: "",
      AdditionalInformation: "",
      AdditionalInformation2: "",
    });
    setSizingData({
      Weight: "",
      WeightType: "dwt",
      Thickness: "",
      ThicknessType: "mm",
      Width: "",
      WidthType: "mm",
      Dimensions: "",
      UnitOfMeasure: "",
      StockFingerSize: "",
      MinFingerSize: "",
      MaxFingerSize: "",
      FingerSizeIncrement: "",
      VaryingSizeBySizeFinger: [],
    });
    setPriceingData({
      WholesalePriceMethod: "",
      WholesaleBasePrice: "",
      BaseMetalMarket: "",
      MetalType: "",
      PriceFactor: "",
      RelationalPrice: "",
      RelationalMetalMarket: "",
      MetalLaborCode: "",
      OtherLaborCode: "",
      MetalFactorCode: "",

      RetailPriceMethod: "",
      MSRP: "",
      PriceType: "",
    });
    setRelatedData({
      MatchingStyles: "",
      UpSellStyles: "",
      GroupedProductStyles: "",
    });
    setMediaData({
      Label: "",
      loadImageFromURL: "",
      loadVideoFromURL: "",
      AddVideoType: "",
      VideoFile: null,
      imageFile: null,
    });
    setDetailsdData({
      BulletPoint1: "",
      BulletPoint2: "",
      BulletPoint3: "",
      BulletPoint4: "",
      BulletPoint5: "",
      DiscountA: "",
      Qty1: "",
      Qty2: "",
      Qty3: "",
      Qty4: "",
      Qty5: "",
      RetailerBrandName: "",
      VendorName: "",
      SecondaryMetalType: "",
      aprod: false,
    });
    setGemstoneData([
      {
        TotalDiamondWeight: "",
        TotalGemstoneWeight: "",
        GemstoneType: "",
        DimensionUnitOfMeasure: "",
        NumberOfGemstones: "",
        GemstoneShape: "",
        GemstoneOrigin: "",
        GemstoneCaratWeight: "",
        GemstoneDimensions: "",
        GemstoneQuality: "",
      },
    ]);
    setWatchData({
      BandMaterial: "",
      NumberType: "",
      BandType: "",
      Size: "",
      CaseMaterial: "",
      Type: "",
      CaseShape: "",
      ComesPackagedIn: "",
      CrystalType: "",
      Warranty: "",
      Bezel: "",
      WatchCondition: "",
      DialColor: "",
      ManufactureDate: "",
      DisplayType: "",
      Certification: "",
      Movement: "",
      Energy: "",
    });
    setSelectedTab("essential");
  };

  const handleCancelImage = () => {
    setCatlogData((prevState) => ({
      ...prevState,
      file: null,
    }));
  };

  const handleCancelImageFile = () => {
    setMediaData((prevState) => ({
      ...prevState,
      imageFile: null,
    }));
  };

  const fileVideoUpload = async (e) => {
    if (
      e.target.files[0].name.match(/.(MP4|MOV|WMV|FLV|AVI|AVCHD|WebM|MKV)$/i)
    ) {
      setMediaData({
        ...mediaData,
        VideoFile: e.target.files[0],
      });
      setValidations({
        ...validations,
        VideoMediaVal: null,
      });
    } else {
      setValidations({
        ...validations,
        VideoMediaVal: "Please Select Only Videos.",
      });
      setMediaData({
        ...mediaData,
        VideoFile: null,
      });
    }
  };

  const fileImageUpload = async (e) => {
    if (e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
      let img = new Image();
      img.src = window.URL.createObjectURL(e.target.files[0]);
      img.onload = () => {
        if (img.width <= 500 && img.height <= 500) {
          setMediaData({
            ...mediaData,
            imageFile: e.target.files[0],
          });
          setValidations({
            ...validations,
            ImageMediaVal: null,
          });
        } else {
          setValidations({
            ...validations,
            ImageMediaVal: "Please Select Correct Image Size.",
          });
        }
      };
    } else {
      setValidations({
        ...validations,
        ImageMediaVal: "Please Select Only Images.",
      });
      setMediaData({
        ...mediaData,
        imageFile: null,
      });
    }
  };

  const fileUpload = async (e) => {
    if (e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
      let img = new Image();
      img.src = window.URL.createObjectURL(e.target.files[0]);
      img.onload = () => {
        if (img.width <= 500 && img.height <= 500) {
          setCatlogData({
            ...catlogData,
            ["file"]: e.target.files[0],
          });
          setValidations({
            ...validations,
            ["file"]: null,
          });
        } else {
          setValidations({
            ...validations,
            ImageVal: "Please Select Correct Image Size.",
          });
        }
      };
    } else {
      setValidations({
        ...validations,
        ImageVal: "Please Select Only Images.",
      });
      setCatlogData({
        ...catlogData,
        ["file"]: null,
      });
    }
  };

  const handleGemInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...gemstoneData];
    list[index][name] = value;
    setGemstoneData(list);
  };

  const handleGemSelectChange = (name, e, index) => {
    const list = [...gemstoneData];
    list[index][name] = e;
    setGemstoneData(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...gemstoneData];
    list.splice(index, 1);
    setGemstoneData(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setGemstoneData([
      ...gemstoneData,
      {
        TotalDiamondWeight: "",
        TotalGemstoneWeight: "",
        GemstoneType: "",
        DimensionUnitOfMeasure: "",
        NumberOfGemstones: "",
        GemstoneShape: "",
        GemstoneOrigin: "",
        GemstoneCaratWeight: "",
        GemstoneDimensions: "",
        GemstoneQuality: "",
      },
    ]);
  };

  return (
    <React.Fragment>
      <div className="prodetail__section form__fields ">
        <div className="col-md-12">
          <div className="prod__maindiv data__maping">
            <div className="row">
              <div className="col-md-12">
                <h4 className="workarea__heading mt-0">
                  {" "}
                  {traslate("AddProduct")}{" "}
                </h4>
              </div>
              {validations.EssentialTabVal && (
                <p className="error-color-red">{validations.EssentialTabVal}</p>
              )}
              <div className="col-lg-12 col-md-12">
                <div className="prodesc__div">
                  <Tabs
                    defaultActiveKey="essential"
                    className="tab__div mb-3"
                    onSelect={(e) => {
                      handleTabsChange(e);
                    }}
                    activeKey={selectedTab}
                  >
                    <Tab eventKey="essential" title={traslate("Essential")}>
                      {/* <p>Essential Here</p> */}
                      <div className="form__fields">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("AddImage")}{" "}
                                <span className="text-red">*</span>
                              </label>
                              <div className="col-md-12 image_block">
                                <div class="row">
                                  {catlogData.file ? (
                                    <Fragment>
                                      <div class="col-lg-5 col-md-12 divimage_block">
                                        <span
                                          onClick={handleCancelImage}
                                          className="close_image"
                                        >
                                          x
                                        </span>

                                        <img
                                          src={URL.createObjectURL(
                                            catlogData.file
                                          )}
                                          className="img-fluid"
                                        />
                                      </div>
                                    </Fragment>
                                  ) : (
                                    <Fragment>
                                      <div
                                        className={
                                          validations.ImageVal
                                            ? " col-lg-5 col-md-12 divimage_block border__red "
                                            : "col-lg-5 col-md-12 divimage_block"
                                        }
                                      >
                                        <label
                                          htmlFor="file-upload"
                                          className="primary-btn choose_file_upload"
                                        >
                                          {traslate("ChooseImage")}
                                        </label>
                                        <input
                                          id="file-upload"
                                          type="file"
                                          onChange={(e) => fileUpload(e)}
                                        />
                                        {validations.ImageVal ? (
                                          <div className="text-danger">
                                            {validations.ImageVal}
                                          </div>
                                        ) : null}
                                        <span className="mb-4">
                                          {traslate("NoFileChoosen")}{" "}
                                        </span>

                                        <span className="">
                                          500 X 500 {traslate("Pixels")}
                                        </span>
                                        <span className="">
                                          {" "}
                                          {traslate("JPGFormat")}{" "}
                                        </span>
                                      </div>
                                      <div class="col-lg-7 col-md-12">
                                        <p className="mt-2">
                                          {" "}
                                          {traslate("OR")}{" "}
                                        </p>
                                        <label className="mt-2">
                                          {traslate("LoadImageFromURL")}
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Enter Url"
                                        />
                                        <button className="primary-btn mt-3">
                                          {traslate("LoadImage")}
                                        </button>
                                      </div>
                                    </Fragment>
                                  )}
                                </div>
                                <div className="row d-none">
                                  <div className="choose_file_divimg">
                                    <img src={userIcon} alt="img" />
                                    <span>x</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("ProductName")} <span>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Product Name"
                                name="productName"
                                value={catlogData.productName}
                                onChange={(e) => handleTextChange(e)}
                                className={
                                  validations.productNameVal && "border__red"
                                }
                              />
                              <div>
                                {validations.productNameVal && (
                                  <p className="error-color-red">
                                    {validations.productNameVal}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("RetailDescription")} <span>*</span>
                              </label>
                              <textarea
                                id=""
                                name="retailDescription"
                                value={catlogData.retailDescription}
                                onChange={(e) => handleTextChange(e)}
                                rows="6"
                                cols="50"
                                placeholder="Enter Retail Description"
                                className={
                                  validations.retailDescriptionVal &&
                                  "border__red"
                                }
                              ></textarea>
                              <div>
                                {validations.retailDescriptionVal && (
                                  <p className="error-color-red">
                                    {validations.retailDescriptionVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Style#")}
                                <Tooltip placement="right" title={style}>
                                  <img src={UnionIcon} alt="" />
                                </Tooltip>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Style"
                                name="style"
                                value={catlogData.style}
                                onChange={(e) => handleTextChange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("DealerStock#")}
                                <Tooltip placement="right" title={dealer_stock}>
                                  <img src={UnionIcon} alt="" />
                                </Tooltip>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Dealer Stock"
                                name="dealerStock"
                                value={catlogData.dealerStock}
                                onChange={(e) => handleTextChange(e)}
                              />
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("RetailPrice")} <span>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Retail Price"
                                name="retailPrice"
                                value={catlogData.retailPrice}
                                onChange={(e) => handleTextChange(e)}
                                className={
                                  validations.retailPriceVal && "border__red"
                                }
                              />
                              <div>
                                {validations.retailPriceVal && (
                                  <p className="error-color-red">
                                    {validations.retailPriceVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6">
                            <div className="input__block">
                              <label> {traslate("Collection")} </label>
                              <Select
                                showSearch
                                className="border__grey"
                                placeholder="Select Collection"
                                name="collection"
                                optionFilterProp="children"
                                onChange={(e) =>
                                  handleSelectChange("collection", e)
                                }
                                value={
                                  catlogData.collection
                                    ? catlogData.collection
                                    : null
                                }
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {collectionList &&
                                  collectionList.map((item) => {
                                    return (
                                      <Option
                                        value={item.collectionID}
                                        key={item.collectionID}
                                      >
                                        {item.collection}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("MaterialType")} <span>*</span>
                              </label>
                              <Select
                                showSearch
                                placeholder="Select Material Type"
                                name="materialType"
                                optionFilterProp="children"
                                onChange={(e) =>
                                  handleSelectChange("materialType", e)
                                }
                                value={
                                  catlogData.materialType
                                    ? catlogData.materialType
                                    : null
                                }
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                className={
                                  validations.materialTypeVal
                                    ? "border__red"
                                    : "border__grey"
                                }
                              >
                                {jewelryMetalTypeList &&
                                  jewelryMetalTypeList.map((item) => {
                                    return (
                                      <Option
                                        key={item.metalTypeID}
                                        value={item.metalTypeID}
                                      >
                                        {item.metalType}
                                      </Option>
                                    );
                                  })}
                              </Select>
                              <div>
                                {validations.materialTypeVal && (
                                  <p className="error-color-red">
                                    {validations.materialTypeVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <div className="input__block">
                              <label> {traslate("Period")} </label>
                              <input
                                type="text"
                                placeholder="Enter Period"
                                name="Period"
                                value={catlogData.Period}
                                onChange={(e) => handleTextChange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <div className="input__block">
                              <label> {traslate("MaterialColor")} </label>
                              <Select
                                showSearch
                                className="border__grey"
                                placeholder="Select Material Color"
                                name="materialColor"
                                optionFilterProp="children"
                                onChange={(e) =>
                                  handleSelectChange("materialColor", e)
                                }
                                value={
                                  catlogData.materialColor
                                    ? catlogData.materialColor
                                    : null
                                }
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {jewelryMetalColorList &&
                                  jewelryMetalColorList.map((item) => {
                                    return (
                                      <Option
                                        key={item.metalColorID}
                                        value={item.metalColorID}
                                      >
                                        {item.metalColor}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-6">
                            <div className="input__block">
                              <label> {traslate("Visibility")} </label>
                              <Switch
                                name="visibility"
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                defaultChecked
                                onChange={(e) =>
                                  handleSelectChange("visibility", e)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <h3 className="subheading">
                            {" "}
                            {traslate("Category")}{" "}
                          </h3>
                        </div>
                        <div>
                          {validations.CheckBoxVal && (
                            <p className="error-color-red">
                              {validations.CheckBoxVal}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form__fields">
                        <Spin spinning={loading}>
                          <div className="row">
                            {jewelryCategoryList
                              ? jewelryCategoryList
                                  .filter((y) => y.parentCategoryID === 0)
                                  .map((item, i) => {
                                    return (
                                      <div className="col-md-6">
                                        <Accordion>
                                          <Accordion.Item
                                            eventKey={item.parentCategoryID}
                                            key={i}
                                          >
                                            <Accordion.Header>
                                              <div>
                                                <input
                                                  type="checkbox"
                                                  name={item.jewelryCategory}
                                                  // checked={
                                                  //   catlogData.CheckBoxlist
                                                  //     .jewelryCategory
                                                  // }
                                                  checked={item.isChecked}
                                                  onChange={(e) => {
                                                    handleChangeCheckBox(
                                                      e,
                                                      item.jewelryCategoryID
                                                    );
                                                  }}
                                                  id={item.parentCategoryID}
                                                />
                                                <label
                                                  for={item.parentCategoryID}
                                                >
                                                  {" "}
                                                  {item.jewelryCategory}
                                                </label>
                                              </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              {jewelryCategoryList
                                                .filter(
                                                  (x) =>
                                                    x.parentCategoryID ===
                                                    item.jewelryCategoryID
                                                )
                                                .map((bdata, k) => {
                                                  return (
                                                    <div>
                                                      {jewelryCategoryList.filter(
                                                        (x) =>
                                                          x.parentCategoryID ===
                                                          bdata.jewelryCategoryID
                                                      ).length > 0 ? (
                                                        <Accordion>
                                                          <Accordion.Item
                                                            eventKey={
                                                              bdata.parentCategoryID
                                                            }
                                                          >
                                                            <Accordion.Header>
                                                              <div>
                                                                <input
                                                                  type="checkbox"
                                                                  name={
                                                                    bdata.jewelryCategory
                                                                  }
                                                                  checked={
                                                                    bdata.isChecked
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    handleChangeCheckBox(
                                                                      e,
                                                                      bdata.jewelryCategoryID
                                                                    );
                                                                  }}
                                                                  id={
                                                                    bdata.jewelryCategoryID
                                                                  }
                                                                />
                                                                <label
                                                                  for={
                                                                    bdata.jewelryCategoryID
                                                                  }
                                                                >
                                                                  {" "}
                                                                  {
                                                                    bdata.jewelryCategory
                                                                  }
                                                                </label>
                                                              </div>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                              {jewelryCategoryList
                                                                .filter(
                                                                  (x) =>
                                                                    x.parentCategoryID ===
                                                                    bdata.jewelryCategoryID
                                                                )
                                                                .map(
                                                                  (
                                                                    ndata,
                                                                    j
                                                                  ) => {
                                                                    return (
                                                                      <div>
                                                                        <input
                                                                          type="checkbox"
                                                                          name={
                                                                            ndata.jewelryCategory
                                                                          }
                                                                          checked={
                                                                            ndata.isChecked
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            handleChangeCheckBox(
                                                                              e,
                                                                              ndata.jewelryCategoryID
                                                                            );
                                                                          }}
                                                                          id={
                                                                            ndata.jewelryCategoryID
                                                                          }
                                                                        />
                                                                        <label
                                                                          for={
                                                                            ndata.jewelryCategoryID
                                                                          }
                                                                        >
                                                                          {" "}
                                                                          {
                                                                            ndata.jewelryCategory
                                                                          }
                                                                        </label>
                                                                      </div>
                                                                    );
                                                                  }
                                                                )}
                                                            </Accordion.Body>
                                                          </Accordion.Item>
                                                        </Accordion>
                                                      ) : (
                                                        <div>
                                                          <input
                                                            type="checkbox"
                                                            name={
                                                              data.jewelryCategory
                                                            }
                                                            checked={
                                                              catlogData
                                                                .CheckBoxlist
                                                                .jewelryCategoryID
                                                            }
                                                            onChange={(e) => {
                                                              handleChangeCheckBox(
                                                                e,
                                                                bdata.jewelryCategoryID
                                                              );
                                                            }}
                                                            id={
                                                              bdata.jewelryCategoryID
                                                            }
                                                          />
                                                          <label
                                                            for={
                                                              bdata.jewelryCategoryID
                                                            }
                                                          >
                                                            {" "}
                                                            {
                                                              bdata.jewelryCategory
                                                            }
                                                          </label>
                                                        </div>
                                                      )}
                                                    </div>
                                                  );
                                                })}
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>
                                      </div>
                                    );
                                  })
                              : null}
                          </div>
                        </Spin>
                      </div>
                    </Tab>

                    <Tab eventKey="Additional" title={traslate("Additional")}>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="input__block">
                            <label>
                              {traslate("QtyOnHand")}
                              <Tooltip
                                placement="right"
                                title={traslate(
                                  "Askusabouthowwecansetanautomatedconnectiontoyourinventorysystemanddynamicallytracktheavailableofyourvirtualinventory."
                                )}
                              >
                                <img src={UnionIcon} alt="" />
                              </Tooltip>
                            </label>
                            <input
                              type="text"
                              name="QtyOnHand"
                              value={additionalData.QtyOnHand}
                              onChange={(e) => handleTextChange(e)}
                              placeholder="Enter Qty On Hand"
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input__block">
                            <label>
                              {traslate("DisplayOrder")}
                              <Tooltip
                                placement="right"
                                title={traslate(
                                  "Thisreferstotheorderinwhichtheitemwillappearwithinitscategory-lowestnumberfirst.Itemswiththesamenumberaresortedbasedonleastexpensivetomostexpensive."
                                )}
                              >
                                <img src={UnionIcon} alt="" />
                              </Tooltip>
                            </label>
                            <input
                              type="text"
                              name="DisplayOrder"
                              value={additionalData.DisplayOrder}
                              onChange={(e) => handleTextChange(e)}
                              placeholder="Enter Display Order"
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input__block">
                            <label> {traslate("Gender")} </label>
                            <Select
                              className="border__grey"
                              placeholder="Gender"
                              name="availability"
                              optionFilterProp="children"
                              value={
                                additionalData.Gender
                                  ? additionalData.Gender
                                  : null
                              }
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(e) => handleSelectChange("Gender", e)}
                            >
                              {genderList &&
                                genderList.map((item) => {
                                  return (
                                    <Option
                                      key={item.genderID}
                                      value={item.genderID}
                                    >
                                      {item.gender}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input__block">
                            <label> {traslate("Setting")} </label>
                            <Select
                              className="border__grey"
                              placeholder="Setting"
                              name="availability"
                              optionFilterProp="children"
                              value={
                                additionalData.Setting
                                  ? additionalData.Setting
                                  : null
                              }
                              onChange={(e) => handleSelectChange("Setting", e)}
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {settingList &&
                                settingList.map((item) => {
                                  return (
                                    <Option
                                      key={item.settingID}
                                      value={item.settingID}
                                    >
                                      {item.settingName}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input__block">
                            <label> {traslate("Status")} </label>
                            <Select
                              className="border__grey"
                              placeholder="Status"
                              name="availability"
                              optionFilterProp="children"
                              value={
                                additionalData.Status
                                  ? additionalData.Status
                                  : null
                              }
                              onChange={(e) => handleSelectChange("Status", e)}
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option value="1">InActive</Option>
                              <Option value="2">Active</Option>
                            </Select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input__block">
                            <label> {traslate("FinishingTechnique")} </label>
                            <input
                              type="text"
                              name="FinishingTechnique"
                              value={additionalData.FinishingTechnique}
                              onChange={(e) => handleTextChange(e)}
                              placeholder="Enter Finishing Technique"
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input__block">
                            <label> {traslate("ClassType")} </label>
                            <input
                              type="text"
                              name="ClassType"
                              value={additionalData.ClassType}
                              onChange={(e) => handleTextChange(e)}
                              placeholder="Enter Class Type"
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input__block">
                            <label> {traslate("ChainType")} </label>
                            <input
                              type="text"
                              name="ChainType"
                              value={additionalData.ChainType}
                              onChange={(e) => handleTextChange(e)}
                              placeholder="Enter Chain Type"
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input__block">
                            <label> {traslate("BackFinding")} </label>
                            <input
                              type="text"
                              name="BackFinding"
                              value={additionalData.BackFinding}
                              onChange={(e) => handleTextChange(e)}
                              placeholder="Enter Back Finding"
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input__block">
                            <label> {traslate("AdditionalInformation")} </label>
                            <textarea
                              type="text"
                              name="AdditionalInformation"
                              value={additionalData.AdditionalInformation}
                              onChange={(e) => handleTextChange(e)}
                              placeholder="Enter Additional Information"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input__block">
                            <label>
                              {" "}
                              {traslate("AdditionalInformation2")}{" "}
                            </label>
                            <textarea
                              type="text"
                              name="AdditionalInformation2"
                              value={additionalData.AdditionalInformation2}
                              onChange={(e) => handleTextChange(e)}
                              placeholder="Enter Additional Information 2"
                            />
                          </div>
                        </div>
                      </div>
                    </Tab>

                    <Tab eventKey="sizing" title={traslate("Sizing")}>
                      <div className="row">
                        <div className="col-md-12">
                          <h5> {traslate("General")} </h5>
                        </div>
                        <div className="col-md-4">
                          <div className="input__block mb-1">
                            <label> {traslate("Weight")} </label>
                            <input
                              type="text"
                              name="Weight"
                              value={sizingData.Weight}
                              onChange={(e) => handleTextChange(e)}
                              placeholder="Enter Weight"
                            />
                          </div>
                          <div class="radio__block">
                            <Radio.Group
                              name="WeightType"
                              value={sizingData.WeightType}
                              onChange={(e) => handleCheckBoxChange(e)}
                            >
                              <Radio value="grams"> {traslate("grams")} </Radio>
                              <Radio value="dwt"> {traslate("dwt")} </Radio>
                            </Radio.Group>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input__block mb-1">
                            <label> {traslate("Thickness")} </label>
                            <input
                              type="text"
                              name="Thickness"
                              value={sizingData.Thickness}
                              onChange={(e) => handleTextChange(e)}
                              placeholder="Enter Thickness"
                            />
                          </div>
                          <div class="radio__block">
                            <Radio.Group
                              name="ThicknessType"
                              value={sizingData.ThicknessType}
                              onChange={(e) => handleCheckBoxChange(e)}
                            >
                              <Radio value={"mm"}> {traslate("mm")} </Radio>
                              <Radio value={"inches"}>
                                {" "}
                                {traslate("inches")}{" "}
                              </Radio>
                            </Radio.Group>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input__block mb-1">
                            <label> {traslate("Width")} </label>
                            <input
                              type="text"
                              name="Width"
                              value={sizingData.Width}
                              onChange={(e) => handleTextChange(e)}
                              placeholder="Enter Width"
                            />
                          </div>
                          <div class="radio__block">
                            <Radio.Group
                              name="WidthType"
                              value={sizingData.WidthType}
                              onChange={(e) => handleCheckBoxChange(e)}
                            >
                              <Radio value={"mm"}> {traslate("mm")} </Radio>
                              <Radio value={"inches"}>
                                {" "}
                                {traslate("inches")}{" "}
                              </Radio>
                            </Radio.Group>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input__block">
                            <label> {traslate("Dimensions")} </label>
                            <input
                              type="text"
                              name="Dimensions"
                              value={sizingData.Dimensions}
                              onChange={(e) => handleTextChange(e)}
                              placeholder="Enter Dimensions"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input__block">
                            <label> {traslate("UnitOfMeasure")} </label>
                            <input
                              type="text"
                              name="UnitOfMeasure"
                              value={sizingData.UnitOfMeasure}
                              onChange={(e) => handleTextChange(e)}
                              placeholder="Enter Unit Of Measure"
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <h5> {traslate("FingerSize")} </h5>
                        </div>

                        <div className="col-md-4">
                          <div className="input__block">
                            <label> {traslate("StockFingerSize")} </label>
                            <Select
                              showSearch
                              className="border__grey"
                              placeholder="Finger Size"
                              name="availability"
                              optionFilterProp="children"
                              value={
                                sizingData.StockFingerSize
                                  ? sizingData.StockFingerSize
                                  : null
                              }
                              onChange={(e) =>
                                handleSelectChange("StockFingerSize", e)
                              }
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option value="3">3</Option>
                              <Option value="3.25">3.25</Option>
                              <Option value="3.5">3.5</Option>
                              <Option value="3.75">3.75</Option>
                              <Option value="4">4</Option>
                              <Option value="4.25">4.25</Option>
                              <Option value="4.5">4.5</Option>
                              <Option value="4.75">4.75</Option>
                              <Option value="5">5</Option>
                              <Option value="5.25">5.25</Option>
                              <Option value="5.5">5.5</Option>
                              <Option value="5.75">5.75</Option>
                              <Option value="6">6</Option>
                              <Option value="6.25">6.25</Option>
                              <Option value="6.5">6.5</Option>
                              <Option value="6.75">6.75</Option>
                              <Option value="7">7</Option>
                              <Option value="7.25">7.25</Option>
                              <Option value="7.5">7.5</Option>
                              <Option value="7.75">7.75</Option>
                              <Option value="8">8</Option>
                              <Option value="8.25">8.25</Option>
                              <Option value="8.5">8.5</Option>
                              <Option value="8.75">8.75</Option>
                              <Option value="9">9</Option>
                              <Option value="9.25">9.25</Option>
                              <Option value="9.5">9.5</Option>
                              <Option value="9.75">9.75</Option>
                              <Option value="10">10</Option>
                              <Option value="10.25">10.25</Option>
                              <Option value="10.5">10.5</Option>
                              <Option value="10.75">10.75</Option>
                              <Option value="11">11</Option>
                              <Option value="11.25">11.25</Option>
                              <Option value="11.5">11.5</Option>
                              <Option value="11.75">11.75</Option>
                              <Option value="12">12</Option>
                              <Option value="12.25">12.25</Option>
                              <Option value="12.5">12.5</Option>
                              <Option value="12.75">12.75</Option>
                              <Option value="13">13</Option>
                              <Option value="13.25">13.25</Option>
                              <Option value="13.5">13.5</Option>
                              <Option value="13.75">13.75</Option>
                              <Option value="14">14</Option>
                              <Option value="14.25">14.25</Option>
                              <Option value="14.5">14.5</Option>
                              <Option value="14.75">14.75</Option>
                              <Option value="15">15</Option>
                              <Option value="15.25">15.25</Option>
                              <Option value="15.5">15.5</Option>
                              <Option value="15.75">15.75</Option>
                              <Option value="16">16</Option>
                              <Option value="16.25">16.25</Option>
                              <Option value="16.5">16.5</Option>
                              <Option value="16.75">16.75</Option>
                              <Option value="17">17</Option>
                              <Option value="17.25">17.25</Option>
                              <Option value="17.5">17.5</Option>
                              <Option value="17.75">17.75</Option>
                              <Option value="18">18</Option>
                            </Select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input__block">
                            <label> {traslate("MinFingerSize")} </label>
                            <Select
                              showSearch
                              className="border__grey"
                              placeholder="Finger Size"
                              name="availability"
                              optionFilterProp="children"
                              value={
                                sizingData.MinFingerSize
                                  ? sizingData.MinFingerSize
                                  : null
                              }
                              onChange={(e) =>
                                handleSelectChange("MinFingerSize", e)
                              }
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option value="3">3</Option>
                              <Option value="3.25">3.25</Option>
                              <Option value="3.5">3.5</Option>
                              <Option value="3.75">3.75</Option>
                              <Option value="4">4</Option>
                              <Option value="4.25">4.25</Option>
                              <Option value="4.5">4.5</Option>
                              <Option value="4.75">4.75</Option>
                              <Option value="5">5</Option>
                              <Option value="5.25">5.25</Option>
                              <Option value="5.5">5.5</Option>
                              <Option value="5.75">5.75</Option>
                              <Option value="6">6</Option>
                              <Option value="6.25">6.25</Option>
                              <Option value="6.5">6.5</Option>
                              <Option value="6.75">6.75</Option>
                              <Option value="7">7</Option>
                              <Option value="7.25">7.25</Option>
                              <Option value="7.5">7.5</Option>
                              <Option value="7.75">7.75</Option>
                              <Option value="8">8</Option>
                              <Option value="8.25">8.25</Option>
                              <Option value="8.5">8.5</Option>
                              <Option value="8.75">8.75</Option>
                              <Option value="9">9</Option>
                              <Option value="9.25">9.25</Option>
                              <Option value="9.5">9.5</Option>
                              <Option value="9.75">9.75</Option>
                              <Option value="10">10</Option>
                              <Option value="10.25">10.25</Option>
                              <Option value="10.5">10.5</Option>
                              <Option value="10.75">10.75</Option>
                              <Option value="11">11</Option>
                              <Option value="11.25">11.25</Option>
                              <Option value="11.5">11.5</Option>
                              <Option value="11.75">11.75</Option>
                              <Option value="12">12</Option>
                              <Option value="12.25">12.25</Option>
                              <Option value="12.5">12.5</Option>
                              <Option value="12.75">12.75</Option>
                              <Option value="13">13</Option>
                              <Option value="13.25">13.25</Option>
                              <Option value="13.5">13.5</Option>
                              <Option value="13.75">13.75</Option>
                              <Option value="14">14</Option>
                              <Option value="14.25">14.25</Option>
                              <Option value="14.5">14.5</Option>
                              <Option value="14.75">14.75</Option>
                              <Option value="15">15</Option>
                              <Option value="15.25">15.25</Option>
                              <Option value="15.5">15.5</Option>
                              <Option value="15.75">15.75</Option>
                              <Option value="16">16</Option>
                              <Option value="16.25">16.25</Option>
                              <Option value="16.5">16.5</Option>
                              <Option value="16.75">16.75</Option>
                              <Option value="17">17</Option>
                              <Option value="17.25">17.25</Option>
                              <Option value="17.5">17.5</Option>
                              <Option value="17.75">17.75</Option>
                              <Option value="18">18</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="input__block">
                            <label> {traslate("MaxFingerSize")} </label>
                            <Select
                              showSearch
                              className="border__grey"
                              placeholder="Finger Size"
                              name="availability"
                              optionFilterProp="children"
                              value={
                                sizingData.MaxFingerSize
                                  ? sizingData.MaxFingerSize
                                  : null
                              }
                              onChange={(e) =>
                                handleSelectChange("MaxFingerSize", e)
                              }
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option value="3">3</Option>
                              <Option value="3.25">3.25</Option>
                              <Option value="3.5">3.5</Option>
                              <Option value="3.75">3.75</Option>
                              <Option value="4">4</Option>
                              <Option value="4.25">4.25</Option>
                              <Option value="4.5">4.5</Option>
                              <Option value="4.75">4.75</Option>
                              <Option value="5">5</Option>
                              <Option value="5.25">5.25</Option>
                              <Option value="5.5">5.5</Option>
                              <Option value="5.75">5.75</Option>
                              <Option value="6">6</Option>
                              <Option value="6.25">6.25</Option>
                              <Option value="6.5">6.5</Option>
                              <Option value="6.75">6.75</Option>
                              <Option value="7">7</Option>
                              <Option value="7.25">7.25</Option>
                              <Option value="7.5">7.5</Option>
                              <Option value="7.75">7.75</Option>
                              <Option value="8">8</Option>
                              <Option value="8.25">8.25</Option>
                              <Option value="8.5">8.5</Option>
                              <Option value="8.75">8.75</Option>
                              <Option value="9">9</Option>
                              <Option value="9.25">9.25</Option>
                              <Option value="9.5">9.5</Option>
                              <Option value="9.75">9.75</Option>
                              <Option value="10">10</Option>
                              <Option value="10.25">10.25</Option>
                              <Option value="10.5">10.5</Option>
                              <Option value="10.75">10.75</Option>
                              <Option value="11">11</Option>
                              <Option value="11.25">11.25</Option>
                              <Option value="11.5">11.5</Option>
                              <Option value="11.75">11.75</Option>
                              <Option value="12">12</Option>
                              <Option value="12.25">12.25</Option>
                              <Option value="12.5">12.5</Option>
                              <Option value="12.75">12.75</Option>
                              <Option value="13">13</Option>
                              <Option value="13.25">13.25</Option>
                              <Option value="13.5">13.5</Option>
                              <Option value="13.75">13.75</Option>
                              <Option value="14">14</Option>
                              <Option value="14.25">14.25</Option>
                              <Option value="14.5">14.5</Option>
                              <Option value="14.75">14.75</Option>
                              <Option value="15">15</Option>
                              <Option value="15.25">15.25</Option>
                              <Option value="15.5">15.5</Option>
                              <Option value="15.75">15.75</Option>
                              <Option value="16">16</Option>
                              <Option value="16.25">16.25</Option>
                              <Option value="16.5">16.5</Option>
                              <Option value="16.75">16.75</Option>
                              <Option value="17">17</Option>
                              <Option value="17.25">17.25</Option>
                              <Option value="17.5">17.5</Option>
                              <Option value="17.75">17.75</Option>
                              <Option value="18">18</Option>
                            </Select>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="input__block">
                            <label> {traslate("FingerSizeIncrement")} </label>
                            <input
                              type="text"
                              name="FingerSizeIncrement"
                              value={sizingData.FingerSizeIncrement}
                              onChange={(e) => handleTextChange(e)}
                              placeholder="Enter Finger Size Increment"
                            />
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <Table
                            className="vdiamond__table"
                            columns={[
                              {
                                title: traslate("Size"),
                                dataIndex: "size",
                              },
                              {
                                title: traslate("PriceType"),
                                dataIndex: "priceType",
                                width: 250,
                                render: (item, row) => {
                                  return (
                                    <div className="input__block mb-0">
                                      <Select
                                        name="priceType"
                                        showSearch
                                        placeholder="Select Option"
                                        optionFilterProp="children"
                                        value={
                                          row.priceType ? row.priceType : null
                                        }
                                        onChange={(e) =>
                                          handleSizingTableChange(
                                            "priceType",
                                            row,
                                            e
                                          )
                                        }
                                        className="border__grey"
                                      >
                                        <Option value="%">Percentage</Option>
                                        <Option value="Fixed">Fixed</Option>
                                      </Select>
                                    </div>
                                  );
                                },
                              },
                              {
                                title: traslate("AdditionalPrice"),
                                dataIndex: "additionalPrice",
                                width: 250,
                                render: (item, row) => {
                                  return (
                                    <div className="input__block mb-0">
                                      <input
                                        name="additionalPrice"
                                        input="text"
                                        value={row.additionalPrice}
                                        onChange={(e) =>
                                          handleSizingTableChange(
                                            "additionalPrice",
                                            row,
                                            e
                                          )
                                        }
                                      />
                                    </div>
                                  );
                                },
                              },
                            ]}
                            dataSource={sizingDataTable}
                            scroll={{ y: 300 }}
                            pagination={false}
                            rowSelection={rowSelection}
                            rowKey={(record) => record.key}
                          ></Table>
                        </div>
                      </div>
                    </Tab>

                    <Tab eventKey="pricing" title={traslate("Pricing")}>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="input__block">
                            <label>
                              {traslate("PriceMethod")}
                              <Tooltip
                                placement="right"
                                title={traslate(
                                  "'Manual'meansthatyoumustsetthepricemanually.'Relational'meansthattheGFsystemusesa'RelationalPriceFactor'toadjustthepricebasedoncurrentmetalmarketpricing.'CostBasis'meansthatyousupplythecomponentbreakdownoftheitemandadjustglobalpricefactorstorecalculatepriceeachdaybasedoncurrentmetalmarketconditions.IfthewholesalepricefieldORtheretailpricefieldsareblank,thenthesystemwilldisplay'CallForPrice'"
                                )}
                              >
                                <img src={UnionIcon} alt="" />
                              </Tooltip>
                            </label>
                            <Select
                              className="border__grey"
                              placeholder="Select Price Method"
                              name="availability"
                              optionFilterProp="children"
                              value={
                                pricingData.PriceMethod
                                  ? pricingData.PriceMethod
                                  : null
                              }
                              onChange={(e) =>
                                handleSelectChange("PriceMethod", e)
                              }
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option value="1">Retail</Option>
                              <Option value="2">WholeSale</Option>
                              <Option value="3">MSRP</Option>
                              <Option value="4">Call For Price</Option>
                            </Select>
                          </div>
                        </div>
                        {pricingData.WholesalePriceMethod == 1 ? (
                          <div className="row manual__inputs">
                            <div className="col-md-4">
                              <div className="input__block">
                                <label> {traslate("RetailPrice")} </label>
                                <input
                                  type="text"
                                  name="Retail"
                                  value={pricingData.Retail}
                                  onChange={(e) => handleTextChange(e)}
                                  placeholder="Enter Retail Price"
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {pricingData.WholesalePriceMethod == 2 ? (
                          <div class="row relation__inputs">
                            <div className="col-md-4">
                              <div className="input__block">
                                <label> {traslate("WholesalePrice")} </label>
                                <input
                                  type="text"
                                  name="Wholesale"
                                  value={pricingData.Wholesale}
                                  onChange={(e) => handleTextChange(e)}
                                  placeholder="Enter Wholesale Price"
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {pricingData.WholesalePriceMethod == 3 ? (
                          <div class="row cost__inputs">
                            <div className="col-md-4">
                              <div className="input__block">
                                <label> {traslate("Msrp")} </label>
                                <input
                                  type="text"
                                  name="Msrp"
                                  value={pricingData.Msrp}
                                  onChange={(e) => handleTextChange(e)}
                                  placeholder="Enter Msrp"
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </Tab>

                    <Tab eventKey="related" title={traslate("Related")}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="input__block">
                            <label>
                              {traslate("MatchingStyles")}
                              <Tooltip
                                placement="right"
                                className="ml-2"
                                title={traslate(
                                  "MatchingSku'sareitemsfromthesameensamblesuchasamatchingringearringsandpendant.EnterDealerIDorStyle#'separatedwithcommas."
                                )}
                              >
                                <img src={UnionIcon} alt="" />
                              </Tooltip>
                            </label>
                            <textarea
                              id="1"
                              rows="4"
                              cols="50"
                              name="MatchingStyles"
                              value={relatedData.MatchingStyles}
                              onChange={(e) => handleTextChange(e)}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input__block">
                            <label>
                              {traslate("UpSellStyles")}
                              <Tooltip
                                placement="right"
                                className="ml-2"
                                title={traslate(
                                  "UpsellSku'sareitemsthatarerelatedbyatslightlyhigherpricepoints."
                                )}
                              >
                                <img src={UnionIcon} alt="" />
                              </Tooltip>
                            </label>
                            <textarea
                              id="1"
                              rows="4"
                              cols="50"
                              name="UpSellStyles"
                              value={relatedData.UpSellStyles}
                              onChange={(e) => handleTextChange(e)}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input__block">
                            <label>
                              {traslate("GroupedProductStyles")}
                              <Tooltip
                                placement="right"
                                className="ml-2"
                                title={traslate("Thereisnocontent.")}
                              >
                                <img src={UnionIcon} alt="" />
                              </Tooltip>
                            </label>
                            <textarea
                              id="1"
                              rows="4"
                              cols="50"
                              name="GroupedProductStyles"
                              value={relatedData.GroupedProductStyles}
                              onChange={(e) => handleTextChange(e)}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </Tab>

                    <Tab eventKey="media" title={traslate("Media")}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="input__block">
                            <div className="col-md-12 image_block">
                              <div class="row">
                                {mediaData.imageFile ? (
                                  <Fragment>
                                    <div class="col-lg-5 col-md-12 divimage_block">
                                      <span
                                        onClick={handleCancelImageFile}
                                        className="close_image"
                                      >
                                        x
                                      </span>

                                      <img
                                        src={URL.createObjectURL(
                                          mediaData.imageFile
                                        )}
                                        className="img-fluid"
                                      />
                                    </div>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    <div
                                      className={
                                        validations.ImageVal
                                          ? " col-lg-5 col-md-12 divimage_block border__red "
                                          : "col-lg-5 col-md-12 divimage_block"
                                      }
                                    >
                                      <label
                                        htmlFor="file-upload"
                                        className="primary-btn choose_file_upload"
                                      >
                                        {traslate("ChooseImage")}
                                      </label>
                                      <input
                                        id="file-upload"
                                        type="file"
                                        onChange={(e) => fileImageUpload(e)}
                                      />
                                      {validations.ImageMediaVal ? (
                                        <div className="text-danger">
                                          {validations.ImageMediaVal}
                                        </div>
                                      ) : null}
                                      <span className="mb-4">
                                        {traslate("NoFileChoosen")}{" "}
                                      </span>

                                      <span className="">
                                        {" "}
                                        500 X 500 {traslate("Pixels")}{" "}
                                      </span>
                                      <span className="">
                                        {" "}
                                        {traslate("JPGFormat")}{" "}
                                      </span>
                                    </div>
                                    <div class="col-lg-7 col-md-12">
                                      <p className="mt-2"> {traslate("OR")} </p>
                                      <label className="mt-2">
                                        {traslate("LoadImageFromURL")}
                                      </label>
                                      <input
                                        type="text"
                                        placeholder="Enter Url"
                                        name="loadImageFromURL"
                                        value={mediaData.loadImageFromURL}
                                        onChange={(e) => handleTextChange(e)}
                                      />
                                      <button className="primary-btn mt-3">
                                        {traslate("LoadImage")}
                                      </button>
                                    </div>
                                  </Fragment>
                                )}
                              </div>
                              <div className="row d-none">
                                <div className="choose_file_divimg">
                                  <img src={userIcon} alt="img" />
                                  <span>x</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-8">
                          <div className="input__block">
                            <div className="col-md-12 image_block">
                              <div class="row">
                                <div class="col-lg-5 col-md-12 divimage_block">
                                  <div>
                                    <label
                                      htmlFor="file-upload"
                                      class="primary-btn choose_file_upload"
                                    >
                                      {traslate("ChooseVideo")}
                                    </label>
                                    <input
                                      id="file-upload"
                                      type="file"
                                      onChange={(e) => fileVideoUpload(e)}
                                    />
                                    <span className="mt-2">
                                      {traslate("NoFileChoosen")}{" "}
                                    </span>
                                  </div>
                                </div>
                                <div class="col-lg-7 col-md-12">
                                  <div>
                                    <p className="mt-2"> {traslate("OR")} </p>
                                    <label className="mt-2">
                                      {traslate("LoadVideoFromURL")}
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Enter Url"
                                      name="LoadVideoFromURL"
                                      value={mediaData.loadVideoFromURL}
                                      onChange={(e) => handleTextChange(e)}
                                    />
                                    <button className="primary-btn mt-3">
                                      {traslate("LoadVideo")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 mt-2">
                          <div className="input__block">
                            <label> {traslate("AddVideoType")} </label>
                            <Select
                              className="border__grey selct__inpt"
                              placeholder="Video Type"
                              value={
                                mediaData.AddVideoType
                                  ? mediaData.AddVideoType
                                  : null
                              }
                              onChange={(e) =>
                                handleSelectChange("AddVideoType", e)
                              }
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option value="1">1</Option>
                              <Option value="1">2</Option>
                              <Option value="1">3</Option>
                            </Select>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="input__block">
                            <label> {traslate("Label")} </label>
                            <input
                              type="text"
                              placeholder="Enter Label"
                              name="Label"
                              value={mediaData.Label}
                              onChange={(e) => handleTextChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 mt-2">
                          <button className="primary-btn mt-3">
                            {" "}
                            {traslate("Upload")}{" "}
                          </button>
                        </div>
                      </div>
                    </Tab>

                    {/*Details Section*/}

                    <Tab eventKey="details" title={traslate("Details")}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="col-lg-4 mt-2">
                              <div className="input__block">
                                <label> {traslate("BulletPoint1")} </label>
                                <input
                                  type="text"
                                  placeholder="Enter BulletPoint1"
                                  name="BulletPoint1"
                                  value={detailsdData.BulletPoint1}
                                  onChange={(e) => handleTextChange(e)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 mt-2">
                              <div className="input__block">
                                <label> {traslate("BulletPoint2")} </label>
                                <input
                                  type="text"
                                  placeholder="Enter BulletPoint2"
                                  name="BulletPoint2"
                                  value={detailsdData.BulletPoint2}
                                  onChange={(e) => handleTextChange(e)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 mt-2">
                              <div className="input__block">
                                <label> {traslate("BulletPoint3")} </label>
                                <input
                                  type="text"
                                  placeholder="Enter BulletPoint3"
                                  name="BulletPoint3"
                                  value={detailsdData.BulletPoint3}
                                  onChange={(e) => handleTextChange(e)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 mt-2">
                              <div className="input__block">
                                <label> {traslate("BulletPoint4")} </label>
                                <input
                                  type="text"
                                  placeholder="Enter BulletPoint4"
                                  name="BulletPoint4"
                                  value={detailsdData.BulletPoint4}
                                  onChange={(e) => handleTextChange(e)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 mt-2">
                              <div className="input__block">
                                <label> {traslate("BulletPoint5")} </label>
                                <input
                                  type="text"
                                  placeholder="Enter BulletPoint5"
                                  name="BulletPoint5"
                                  value={detailsdData.BulletPoint5}
                                  onChange={(e) => handleTextChange(e)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 mt-2">
                              <div className="input__block">
                                <label> {traslate("DiscountA")} </label>
                                <input
                                  type="text"
                                  placeholder="Enter DiscountA"
                                  name="DiscountA"
                                  value={detailsdData.DiscountA}
                                  onChange={(e) => handleTextChange(e)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 mt-2">
                              <div className="input__block">
                                <label> {traslate("Qty1")} </label>
                                <input
                                  type="text"
                                  placeholder="Enter Qty1"
                                  name="Qty1"
                                  value={detailsdData.Qty1}
                                  onChange={(e) => handleTextChange(e)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 mt-2">
                              <div className="input__block">
                                <label> {traslate("Qty2")} </label>
                                <input
                                  type="text"
                                  placeholder="Enter Qty2"
                                  name="Qty2"
                                  value={detailsdData.Qty2}
                                  onChange={(e) => handleTextChange(e)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 mt-2">
                              <div className="input__block">
                                <label> {traslate("Qty3")} </label>
                                <input
                                  type="text"
                                  placeholder="Enter Qty3"
                                  name="Qty3"
                                  value={detailsdData.Qty3}
                                  onChange={(e) => handleTextChange(e)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 mt-2">
                              <div className="input__block">
                                <label> {traslate("Qty4")} </label>
                                <input
                                  type="text"
                                  placeholder="Enter Qty4"
                                  name="Qty4"
                                  value={detailsdData.Qty4}
                                  onChange={(e) => handleTextChange(e)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 mt-2">
                              <div className="input__block">
                                <label> {traslate("Qty5")} </label>
                                <input
                                  type="text"
                                  placeholder="Enter Qty5"
                                  name="Qty5"
                                  value={detailsdData.Qty5}
                                  onChange={(e) => handleTextChange(e)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 mt-2">
                              <div className="input__block">
                                <label> {traslate("RetailerBrandName")} </label>
                                <input
                                  type="text"
                                  placeholder="Enter Retailer Brand Name"
                                  name="RetailerBrandName"
                                  value={detailsdData.RetailerBrandName}
                                  onChange={(e) => handleTextChange(e)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 mt-2">
                              <div className="input__block">
                                <label> {traslate("VendorName")} </label>
                                <input
                                  type="text"
                                  placeholder="Enter Retailer Vendor Name"
                                  name="VendorName"
                                  value={detailsdData.VendorName}
                                  onChange={(e) => handleTextChange(e)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 mt-2">
                              <div className="input__block">
                                <label>
                                  {" "}
                                  {traslate("SecondaryMetalType")}{" "}
                                </label>
                                <input
                                  type="text"
                                  placeholder="Enter Retailer Secondary Metal Type"
                                  name="SecondaryMetalType"
                                  value={detailsdData.SecondaryMetalType}
                                  onChange={(e) => handleTextChange(e)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 mt-2">
                              <div className="mt-4">
                                <label for="aprod">
                                  {" "}
                                  {traslate("AmazonProduct")}{" "}
                                </label>
                                <Switch
                                  name="aprod"
                                  checkedChildren="Yes"
                                  unCheckedChildren="No"
                                  checked={detailsdData.aprod}
                                  onChange={(e) =>
                                    handleSelectChange("aprod", e)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    {/*Details Section*/}

                    <Tab eventKey="Gemstone" title={traslate("Gemstone")}>
                      <div className="">
                        <div className="row d-none">
                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("TotalDiamondWeight")} </label>
                              <input
                                type="text"
                                name="TotalDiamondWeight"
                                value={detailsdData.TotalDiamondWeight}
                                onChange={(e) => handleTextChange(e)}
                                placeholder="Enter Total Diamond Weight"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("TotalGemstoneWeight")} </label>
                              <input
                                type="text"
                                name="TotalGemstoneWeight"
                                value={detailsdData.TotalGemstoneWeight}
                                onChange={(e) => handleTextChange(e)}
                                placeholder="Enter Total Gemstone Weight"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("GemstoneType")} </label>
                              <Select
                                className="border__grey"
                                placeholder="Select GemstoneType"
                                optionFilterProp="children"
                                value={
                                  gemstoneData.GemstoneType
                                    ? gemstoneData.GemstoneType
                                    : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("GemstoneType", e)
                                }
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {gemStoneTypeList &&
                                  gemStoneTypeList.map((item) => {
                                    return (
                                      <Option
                                        key={item.primaryGemstoneTypeID}
                                        value={item.primaryGemstoneTypeID}
                                      >
                                        {item.primaryGemstoneTypeName}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("DimensionUnitOfMeasure")} :{" "}
                              </label>
                              <input
                                type="text"
                                name="DimensionUnitOfMeasure"
                                value={gemstoneData.DimensionUnitOfMeasure}
                                onChange={(e) => handleTextChange(e)}
                                placeholder="Enter Dimension Unit Of Measure"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("NumberOfGemstones")} </label>
                              <input
                                type="text"
                                name="NumberOfGemstones"
                                value={gemstoneData.NumberOfGemstones}
                                onChange={(e) => handleTextChange(e)}
                                placeholder="Enter Number Of Gemstones"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("GemstoneShape")} </label>
                              <Select
                                className="border__grey"
                                placeholder="Select GemstoneShape"
                                optionFilterProp="children"
                                value={
                                  gemstoneData.GemstoneShape
                                    ? gemstoneData.GemstoneShape
                                    : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("GemstoneShape", e)
                                }
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {gemStoneShapeList &&
                                  gemStoneShapeList.map((item) => {
                                    return (
                                      <Option
                                        key={item.primaryDiamondID}
                                        value={item.primaryDiamondID}
                                      >
                                        {item.primaryDiamondName}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("GemstoneOrigin")} </label>
                              <input
                                type="text"
                                name="GemstoneOrigin"
                                value={gemstoneData.GemstoneOrigin}
                                onChange={(e) => handleTextChange(e)}
                                placeholder="Enter Gemstone Origin"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("GemstoneCaratWeight")} </label>
                              <input
                                type="text"
                                name="GemstoneCaratWeight"
                                value={gemstoneData.GemstoneCaratWeight}
                                onChange={(e) => handleTextChange(e)}
                                placeholder="Enter Gemstone Carat Weight"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("GemstoneDimensions")} </label>
                              <input
                                type="text"
                                name="GemstoneDimensions"
                                value={gemstoneData.GemstoneDimensions}
                                onChange={(e) => handleTextChange(e)}
                                placeholder="Enter Gemstone Dimensions"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("GemstoneQuality")} </label>
                              <input
                                type="text"
                                name="GemstoneQuality"
                                value={gemstoneData.GemstoneQuality}
                                onChange={(e) => handleTextChange(e)}
                                placeholder="Enter Gemstone Quality"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {gemstoneData.map((x, i) => {
                            return (
                              <Fragment>
                                <div className="col-md-4 ">
                                  <div className="input__block">
                                    <label>
                                      {" "}
                                      {traslate("TotalDiamondWeight")} {i + 1}
                                    </label>
                                    <input
                                      name="TotalDiamondWeight"
                                      value={x.TotalDiamondWeight}
                                      onChange={(e) =>
                                        handleGemInputChange(e, i)
                                      }
                                      placeholder="Enter Total Diamond Weight"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="input__block">
                                    <label>
                                      {" "}
                                      {traslate("TotalGemstoneWeight")} {i + 1}
                                    </label>
                                    <input
                                      name="TotalGemstoneWeight"
                                      value={x.TotalGemstoneWeight}
                                      onChange={(e) =>
                                        handleGemInputChange(e, i)
                                      }
                                      placeholder="Enter Total Gemstone Weight"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="input__block">
                                    <label>
                                      {" "}
                                      {traslate("GemstoneType")} {i + 1}
                                    </label>
                                    <Select
                                      className="border__grey"
                                      placeholder="Select GemstoneType"
                                      optionFilterProp="children"
                                      value={
                                        x.GemstoneType ? x.GemstoneType : null
                                      }
                                      onChange={(e) =>
                                        handleGemSelectChange(
                                          "GemstoneType",
                                          e,
                                          i
                                        )
                                      }
                                      showSearch
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {gemStoneTypeList &&
                                        gemStoneTypeList.map((item) => {
                                          return (
                                            <Option
                                              key={item.primaryGemstoneTypeID}
                                              value={item.primaryGemstoneTypeID}
                                            >
                                              {item.primaryGemstoneTypeName}
                                            </Option>
                                          );
                                        })}
                                    </Select>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="input__block">
                                    <label>
                                      {traslate("DimensionUnitOfMeasure")}{" "}
                                      {i + 1}
                                    </label>
                                    <input
                                      type="text"
                                      name="DimensionUnitOfMeasure"
                                      value={x.DimensionUnitOfMeasure}
                                      onChange={(e) =>
                                        handleGemInputChange(e, i)
                                      }
                                      placeholder="Enter Dimension Unit Of Measure"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="input__block">
                                    <label>
                                      {" "}
                                      {traslate("NumberOfGemstones")} {i + 1}
                                    </label>
                                    <input
                                      type="text"
                                      name="NumberOfGemstones"
                                      value={x.NumberOfGemstones}
                                      onChange={(e) =>
                                        handleGemInputChange(e, i)
                                      }
                                      placeholder="Enter Number Of Gemstones"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="input__block">
                                    <label>
                                      {" "}
                                      {traslate("GemstoneShape")} {i + 1}
                                    </label>
                                    <Select
                                      className="border__grey"
                                      placeholder="Select GemstoneShape"
                                      optionFilterProp="children"
                                      value={
                                        x.GemstoneShape ? x.GemstoneShape : null
                                      }
                                      onChange={(e) =>
                                        handleGemSelectChange(
                                          "GemstoneShape",
                                          e,
                                          i
                                        )
                                      }
                                      showSearch
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {gemStoneShapeList &&
                                        gemStoneShapeList.map((item) => {
                                          return (
                                            <Option
                                              key={item.primaryDiamondID}
                                              value={item.primaryDiamondID}
                                            >
                                              {item.primaryDiamondName}
                                            </Option>
                                          );
                                        })}
                                    </Select>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="input__block">
                                    <label>
                                      {" "}
                                      {traslate("GemstoneOrigin")} {i + 1}
                                    </label>
                                    <input
                                      type="text"
                                      name="GemstoneOrigin"
                                      value={x.GemstoneOrigin}
                                      onChange={(e) =>
                                        handleGemInputChange(e, i)
                                      }
                                      placeholder="Enter Gemstone Origin"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="input__block">
                                    <label>
                                      {" "}
                                      {traslate("GemstoneCaratWeight")} {i + 1}
                                    </label>
                                    <input
                                      type="text"
                                      name="GemstoneCaratWeight"
                                      value={x.GemstoneCaratWeight}
                                      onChange={(e) =>
                                        handleGemInputChange(e, i)
                                      }
                                      placeholder="Enter Gemstone Carat Weight"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="input__block">
                                    <label>
                                      {" "}
                                      {traslate("GemstoneDimensions")} {i + 1}
                                    </label>
                                    <input
                                      type="text"
                                      name="GemstoneDimensions"
                                      value={x.GemstoneDimensions}
                                      onChange={(e) =>
                                        handleGemInputChange(e, i)
                                      }
                                      placeholder="Enter Gemstone Dimensions"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="input__block">
                                    <label>
                                      {" "}
                                      {traslate("GemstoneQuality")} {i + 1}
                                    </label>
                                    <input
                                      type="text"
                                      name="GemstoneQuality"
                                      value={x.GemstoneQuality}
                                      onChange={(e) =>
                                        handleGemInputChange(e, i)
                                      }
                                      placeholder="Enter Gemstone Quality"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-8 mb-2 mt-4">
                                  {gemstoneData.length !== 1 && (
                                    <button
                                      className="primary-btn"
                                      onClick={() => handleRemoveClick(i)}
                                    >
                                      {traslate("Remove")}
                                    </button>
                                  )}
                                  {gemstoneData.length - 1 === i && (
                                    <button
                                      className={
                                        gemstoneData.length == 5
                                          ? "disabled-btn mx-2"
                                          : "primary-btn mx-2"
                                      }
                                      onClick={handleAddClick}
                                      disabled={
                                        gemstoneData.length == 5 ? true : false
                                      }
                                    >
                                      {traslate("Add")}
                                    </button>
                                  )}
                                </div>
                              </Fragment>
                            );
                          })}
                        </div>
                      </div>
                    </Tab>

                    <Tab eventKey="watch" title={traslate("Watch")}>
                      <div className="">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("BandMaterial")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Band Material"
                                optionFilterProp="children"
                                value={
                                  watchData.BandMaterial
                                    ? watchData.BandMaterial
                                    : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("BandMaterial", e)
                                }
                                showSearch
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {watchBandMaterialList &&
                                  watchBandMaterialList.map((item) => {
                                    return (
                                      <Option value={item.id}>
                                        {item.material}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("NumberType")} </label>
                              <Select
                                className="border__grey"
                                placeholder="Number Type"
                                optionFilterProp="children"
                                value={
                                  watchData.NumberType
                                    ? watchData.NumberType
                                    : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("NumberType", e)
                                }
                                showSearch
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {watchNumberTypeList &&
                                  watchNumberTypeList.map((item) => {
                                    return (
                                      <Option key={item.id} value={item.id}>
                                        {item.numberType}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("BandType")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Band Type:"
                                optionFilterProp="children"
                                value={
                                  watchData.BandType ? watchData.BandType : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("BandType", e)
                                }
                                showSearch
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {bandTypeList &&
                                  bandTypeList.map((item) => {
                                    return (
                                      <Option value={item.id} key={item.id}>
                                        {item.bandType}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("Size")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Size"
                                optionFilterProp="children"
                                value={watchData.Size ? watchData.Size : null}
                                onChange={(e) => handleSelectChange("Size", e)}
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {watchSizeList &&
                                  watchSizeList.map((item) => {
                                    return (
                                      <Option value={item.id} key={item.id}>
                                        {item.watchSize}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("CaseMaterial")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Case Material"
                                optionFilterProp="children"
                                value={
                                  watchData.CaseMaterial
                                    ? watchData.CaseMaterial
                                    : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("CaseMaterial", e)
                                }
                                showSearch
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {caseMaterialList &&
                                  caseMaterialList.map((item) => {
                                    return (
                                      <Option value={item.id} key={item.id}>
                                        {item.caseMaterial}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("Type")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Type"
                                optionFilterProp="children"
                                value={watchData.Type ? watchData.Type : null}
                                onChange={(e) => handleSelectChange("Type", e)}
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {watchTypeList &&
                                  watchTypeList.map((item) => {
                                    return (
                                      <Option value={item.id} key={item.id}>
                                        {item.watchType}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("CaseShape")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Case Shape"
                                optionFilterProp="children"
                                value={
                                  watchData.CaseShape
                                    ? watchData.CaseShape
                                    : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("CaseShape", e)
                                }
                                showSearch
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {caseShapeList &&
                                  caseShapeList.map((item) => {
                                    return (
                                      <Option value={item.id}>
                                        {item.caseShape}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("ComesPackagedIn")} ? : </label>
                              <Select
                                className="border__grey"
                                placeholder="Select Comes Packaged In "
                                optionFilterProp="children"
                                value={
                                  watchData.ComesPackagedIn
                                    ? watchData.ComesPackagedIn
                                    : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("ComesPackagedIn", e)
                                }
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value="Yes">Yes</Option>
                                <Option value="No">No</Option>
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("CrystalType")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Crystal Type"
                                optionFilterProp="children"
                                value={
                                  watchData.CrystalType
                                    ? watchData.CrystalType
                                    : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("CrystalType", e)
                                }
                                showSearch
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {crystalTypeList &&
                                  crystalTypeList.map((item) => {
                                    return (
                                      <Option value={item.id}>
                                        {item.crystalType}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("DialColor")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Dial Color"
                                optionFilterProp="children"
                                value={
                                  watchData.DialColor
                                    ? watchData.DialColor
                                    : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("DialColor", e)
                                }
                                showSearch
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {watchDialColorList &&
                                  watchDialColorList.map((item) => {
                                    return (
                                      <Option value={item.id}>
                                        {item.watchDialColor}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("ManufactureDate")}: </label>
                              <input
                                type="text"
                                name="ManufactureDate"
                                value={pricingData.ManufactureDate}
                                onChange={(e) => handleTextChange(e)}
                                placeholder="Enter Manufacture Date"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("DisplayType")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Display Type"
                                optionFilterProp="children"
                                value={
                                  watchData.DisplayType
                                    ? watchData.DisplayType
                                    : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("DisplayType", e)
                                }
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {displayTypeList &&
                                  displayTypeList.map((item) => {
                                    return (
                                      <Option value={item.id} key={item.id}>
                                        {item.displayType}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("Certification")}: </label>
                              <input
                                type="text"
                                name="Certification"
                                value={pricingData.Certification}
                                onChange={(e) => handleTextChange(e)}
                                placeholder="Enter Certification"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("Movement")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Movement"
                                optionFilterProp="children"
                                value={
                                  watchData.Movement ? watchData.Movement : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("Movement", e)
                                }
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {watchMovementList &&
                                  watchMovementList.map((item) => {
                                    return (
                                      <Option value={item.id} key={item.id}>
                                        {item.watchMovement}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="input__block">
                              <label> {traslate("Energy")}: </label>
                              <Select
                                className="border__grey"
                                placeholder="Energy"
                                optionFilterProp="children"
                                value={
                                  watchData.Energy ? watchData.Energy : null
                                }
                                onChange={(e) =>
                                  handleSelectChange("Energy", e)
                                }
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {watchEnergyList &&
                                  watchEnergyList.map((item) => {
                                    return (
                                      <Option value={item.id} key={item.id}>
                                        {item.watchEngergy}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                  <div className="col-md-6 mt-4">
                    <Button
                      className="primary-btn"
                      onClick={handleSubmit}
                      loading={submitLoading}
                    >
                      {traslate("SaveProduct")}
                    </Button>
                    <Button className="primary-btn ml-2" onClick={handleCancel}>
                      {traslate("CancelProduct")}
                    </Button>
                    {/* <button class="primary-btn ml-2" onClick={handleCancel}>
                          {traslate("CancelProduct")}
                        </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddProducts;
