import React, { useState, useRef, useEffect, useDebugValue } from "react";
import {
  Input,
  Space,
  Row,
  Col,
  Select,
  Search,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Modal,
  Table,
  Spin,
} from "antd";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import Highlighter from "react-highlight-words";
import {
  EditOutlined,
  CalendarOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import adminToolsService from "../../../services/admin-tools.service";
import traslate from "../../../i18n/translate";

const ConsoleUpload = () => {
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [consoleUploadData, setConsoleUploadData] = useState({
    dealerID: "",
    fTPUserName: "",
    fTPPassword: "",
    fileTypeId: "",
    addUpdate: true,
    path: "",
  });
  const [validations, setValidations] = useState({
    dealerIDVal: "",
    fTPUserNameVal: "",
    fTPPasswordVal: "",
    fileTypeVal: "",
    pathVal: "",
  });
  const [ftpData, setFtpData] = useState({
    userName: "",
    password: "",
    folder: "",
  });
  const [ftpValidations, setFtpValidations] = useState({
    userNameVal: "",
    passwordVal: "",
    folderVal: "",
  });
  const [editConsoleUploadData, setEditConsoleUploadData] = useState({
    consoleUploadID: "",
    company: "",
    dealerID: "",
    userName: "",
    password: "",
    fileTypeId: "",
    addUpdate: true,
    path: "",
    oldFileType: "",
  });
  const [editvalidations, setEditValidations] = useState({
    editfTPUserNameVal: "",
    editfTPPasswordVal: "",
    editfileTypeVal: "",
    editpathVal: "",
  });
  const [delConsoleData, setDelConsoleData] = useState({
    id: "",
    dealerID: "",
  });
  const { Option } = Select;
  const { Search } = Input;
  const [dealerList, setdealerList] = useState([]);
  const [fileTypeData, setFileTypeData] = useState([]);
  const [getconsoleUploadData, setGetConsoleUploadData] = useState([]);
  const [permisssionTypeMaster, setPermisssionTypeMaster] = useState([]);
  const [retailerStatusMaster, setRetailerStatusMaster] = useState([]);
  const [vendorStatusMaster, setVendorStatusMaster] = useState([]);

  useEffect(() => {
    GetPermisssionTypeMaster();
    GetRetailerStatusMaster();
    GetVendorStatusMaster();
  }, []);

  const GetPermisssionTypeMaster = () => {
    try {
      setLoading(true);
      AdminToolsDataService.GetPermisssionTypeMaster()
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setPermisssionTypeMaster(responseData);
          } else {
            setPermisssionTypeMaster([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const GetRetailerStatusMaster = () => {
    try {
      setLoading(true);
      AdminToolsDataService.GetRetailerStatusMaster()
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setRetailerStatusMaster(responseData);
          } else {
            setRetailerStatusMaster([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const GetVendorStatusMaster = () => {
    try {
      setLoading(true);
      AdminToolsDataService.GetVendorStatusMaster()
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setVendorStatusMaster(responseData);
          } else {
            setVendorStatusMaster([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setConsoleUploadData({
      dealerID: "",
      fTPUserName: "",
      fTPPassword: "",
      fileTypeId: "",
      addUpdate: true,
      path: "",
    });
    setValidations({
      dealerIDVal: "",
      fTPUserNameVal: "",
      fTPPasswordVal: "",
      fileTypeVal: "",
      pathVal: "",
    });
  };

  const handleCancel1 = () => {
    setVisible1(false);
    setshowPassword(false);
    setFtpData({
      userName: "",
      password: "",
      folder: "",
    });
    setFtpValidations({
      userNameVal: "",
      passwordVal: "",
      folderVal: "",
    });
  };

  const handleCancel2 = () => {
    setVisible2(false);
    setEditValidations({
      editfTPUserNameVal: "",
      editfTPPasswordVal: "",
      editfileTypeVal: "",
      editpathVal: "",
    });
    setEditConsoleUploadData({
      company: "",
      dealerID: "",
      userName: "",
      password: "",
      fileTypeId: "",
      addUpdate: true,
      path: "",
    });
  };

  const handleCancel3 = () => {
    setVisible3(false);
  };

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  useEffect(() => {
    handleGetConsoleUploadList();
  }, []);

  const handleGetConsoleUploadList = () => {
    try {
      setLoading(true);
      AdminToolsDataService.GetConsoleUploadList()
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var dealerData = response.data.responseData[0];
          var fileTypeData = response.data.responseData[1];
          var responseData = response.data.responseData[2];
          if (message == "Success") {
            setdealerList(dealerData);
            setFileTypeData(fileTypeData);
            setGetConsoleUploadData(responseData);
          } else {
            setGetConsoleUploadData([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {traslate("Search")}
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              {traslate("Reset")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          // setTimeout(() => this.searchInput.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    };
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters, confirm) {
    clearFilters();
    setSearchText("");
    confirm();
  }

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setConsoleUploadData({
        ...consoleUploadData,
        [name]: e,
      });
    } else {
      setConsoleUploadData({
        ...consoleUploadData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    const { dealerID, fTPUserName, fTPPassword, fileTypeId, addUpdate, path } =
      consoleUploadData;
    e.preventDefault();
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    console.log(consoleUploadData);
    try {
      let inputData = {
        id: 0,
        dealerID: dealerID.toString(),
        userName: fTPUserName,
        password: fTPPassword,
        fileTypeId: fileTypeId,
        addUpdate: addUpdate,
        path: path,
        loginUserId: "",
        loginUserName: "",
        ipAddress: "",
      };
      setLoading(true);
      AdminToolsDataService.UpdateConsoleUpload(inputData)
        .then((response) => {
          var message = response.data.message;
          var ShowMessage = response.data.responseData.returnMessage;
          setLoading(false);
          if (message == "Success") {
            NotificationManager.success(ShowMessage);
            handleGetConsoleUploadList();
          } else {
            NotificationManager.error(ShowMessage);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleCancel();
  };

  const handleValidation = () => {
    const { dealerID, fTPUserName, fTPPassword, fileType, path } =
      consoleUploadData;
    const validations = {
      dealerIDVal: "",
      fTPUserNameVal: "",
      fTPPasswordVal: "",
      fileTypeVal: "",
      pathVal: "",
    };
    let isValid = true;

    if (!dealerID) {
      isValid = false;
      validations.dealerIDVal = "Kindly select Dealer ID ";
    }
    if (!fTPUserName.trim()) {
      isValid = false;
      validations.fTPUserNameVal = "FTP Username is compulsory";
    }
    if (!fTPPassword.trim()) {
      isValid = false;
      validations.fTPPasswordVal = "FTP Password is compulsory";
    }
    if (!fileTypeId) {
      isValid = false;
      validations.fileTypeVal = "Kindly select File Type";
    }
    if (!path) {
      isValid = false;
      validations.pathVal = "Path is Compulsory";
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const handleEditValidation = () => {
    const { userName, password, fileTypeId, path } = editConsoleUploadData;
    const editvalidations = {
      editfTPUserNameVal: "",
      editfTPPasswordVal: "",
      editfileTypeVal: "",
      editpathVal: "",
    };
    let isValid = true;
    if (!userName.trim()) {
      isValid = false;
      editvalidations.editfTPUserNameVal = "FTP Username is compulsory";
    }
    if (!password.trim()) {
      isValid = false;
      editvalidations.editfTPPasswordVal = "FTP Password is compulsory";
    }
    if (!fileTypeId) {
      isValid = false;
      editvalidations.editfileTypeVal = "Kindly select File Type";
    }
    if (!path) {
      isValid = false;
      editvalidations.editpathVal = "Path is Compulsory";
    }

    if (!isValid) {
      setEditValidations(editvalidations);
    }

    return isValid;
  };

  const edithandleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setEditConsoleUploadData({
        ...editConsoleUploadData,
        [name]: e,
      });
    } else {
      setEditConsoleUploadData({
        ...editConsoleUploadData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleUpdate = () => {
    debugger;
    const {
      consoleUploadID,
      dealerID,
      userName,
      password,
      fileTypeId,
      addUpdate,
      path,
    } = editConsoleUploadData;
    const isValid = handleEditValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        id: consoleUploadID,
        dealerID: dealerID.toString(),
        userName: userName,
        password: password,
        fileTypeId: fileTypeId,
        addUpdate: addUpdate,
        path: path,
        loginUserId: "",
        loginUserName: "",
        ipAddress: "",
      };
      setLoading(true);
      AdminToolsDataService.UpdateConsoleUpload(inputData)
        .then((response) => {
          var message = response.data.message;
          var NotiMsg = response.data.responseData.returnMessage;
          setLoading(false);
          if (message == "Success") {
            NotificationManager.success("Record Updated Succssfully.");
            handleGetConsoleUploadList();
          } else {
            NotificationManager.error("Record Not Updated.");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleCancel2();
  };

  const DeleteConsoleUploadData = () => {
    debugger;
    try {
      let inputData = {
        id: parseInt(delConsoleData.id),
        dealerID: parseInt(delConsoleData.dealerID),
      };
      setLoading(true);
      adminToolsService
        .DeleteConsoleUpload(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(true);
          if (message == "Success") {
            NotificationManager.success("Record Deleted Succssfully.");
            handleGetConsoleUploadList();
            setLoading(false);
          } else {
            NotificationManager.error("Record Not Deleted.");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setVisible3(false);
    setDelConsoleData({});
  };

  const handleFtpOnchange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setFtpData({
        ...ftpData,
        [name]: e,
      });
    } else {
      setFtpData({
        ...ftpData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleFtpValitdation = () => {
    const { userName, password } = ftpData;
    const ftpValidations = {
      userNameVal: "",
      passwordVal: "",
    };
    let isValid = true;

    if (!userName.trim()) {
      isValid = false;
      ftpValidations.userNameVal = "FTP Username is compulsory";
    }
    if (!password.trim()) {
      isValid = false;
      ftpValidations.passwordVal = "FTP Password is compulsory";
    }

    if (!isValid) {
      setFtpValidations(ftpValidations);
    }

    return isValid;
  };

  const handleFtpSumbit = (e) => {
    e.preventDefault();
    const isValid = handleFtpValitdation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        userName: ftpData.userName,
        password: ftpData.password,
        folderName: ftpData.folder,
      };
      setLoading(true);
      console.log(inputData);
      AdminToolsDataService.CreateFTP(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          if (message == "Success") {
            NotificationManager.success("FTP Data Added Successfully");
          } else {
            NotificationManager.success("FTP Data Not Added");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleCancel1();
  };

  const handleEditModal = (row) => {
    setVisible2(true);
    console.log(row);
    setEditConsoleUploadData({
      consoleUploadID: row.id,
      dealerID: row.dealerID,
      company: row.company,
      userName: row.userName,
      password: row.password,
      fileTypeId: row.fileTypeId,
      addUpdate: row.addUpdate,
      path: row.path,
    });
  };

  const handleDeleteModal = (row) => {
    setVisible3(true);
    setDelConsoleData({
      id: row.id,
      dealerID: row.dealerID,
    });
    console.log(row);
  };

  const handlePassShowHide = () => {
    setshowPassword(!showPassword);
  };
  const { dealerID, fTPUserName, fTPPassword, fileTypeId, addUpdate, path } =
    consoleUploadData;

  const { dealerIDVal, fTPUserNameVal, fTPPasswordVal, fileTypeVal, pathVal } =
    validations;

  const { userName, password, folder } = ftpData;
  const { userNameVal, passwordVal } = ftpValidations;

  console.log(delConsoleData);
  return (
    <React.Fragment>
      <div className="consupld__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">{traslate("ConsoleUpload")}</h4>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="row">
                <div className="col-lg-6 mt-4">
                  <div className="addcons__btndiv">
                    <button
                      className="primary-btn"
                      onClick={() => setVisible(true)}
                    >
                      {" "}
                      <img src={Create_icon} alt="" />
                      {traslate("AddConsoleUploadDealerData")}
                    </button>
                    <button
                      className="primary-btn"
                      onClick={() => setVisible1(true)}
                    >
                      {" "}
                      <img src={Create_icon} alt="" />{traslate("CreateFTP")} {" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="consupld_tbldiv">
                <Spin spinning={loading} size="large">
                  <Table
                    columns={[
                      {
                        title: traslate("Dealer") ,
                        ...getColumnSearchProps("company"),
                        dataIndex: "company",
                        key: "company",
                        sorter: (a, b) => a.company.length - b.company.length,
                        render: (text, record) => {
                          return (
                            <span>
                              {"( " + record.dealerID + " ) " + record.company}
                            </span>
                          );
                        },
                      },
                      {
                        title: traslate("FTPUserName") ,
                        dataIndex: "userName",
                        key: "userName",
                        sorter: (a, b) => a.userName.length - b.userName.length,
                      },
                      {
                        title: traslate("FTPPassword") ,
                        dataIndex: "password",
                        key: "password",
                        sorter: (a, b) => a.password.length - b.password.length,
                      },
                      {
                        title: traslate("FileType") ,
                        dataIndex: "fileType",
                        key: "fileType",
                        // sorter: (a, b) => a.fileType.length - b.fileType.length,
                        sorter: (a, b) => a.fileType.localeCompare(b.fileType),
                        width: 120
                      },
                      {
                        title: traslate("Path") ,
                        dataIndex: "path",
                        key: "path",
                        sorter: (a, b) => a.path.length - b.path.length,
                      },
                      {
                        title: traslate("AddUpdate") ,
                        dataIndex: "addUpdate",
                        key: "addUpdate",
                        render: (text) => String(text),
                        sorter: (a, b) =>
                          a.addUpdate.length - b.addUpdate.length,
                        width: 150
                      },

                      {
                        title: traslate("Action") ,
                        key: "7",
                        fixed: "right",
                        width: 100,
                        render: (index, row) => (
                          // <a>
                          //   <EditOutlined onClick={showModal} />
                          //   <span className="mleft mx-2">
                          //     <CalendarOutlined onClick={showModal2} />
                          //   </span>
                          // </a>
                          <div className="action__btns">
                            <div className="image__block">
                              <img
                                src={Edit_icon}
                                onClick={() => {
                                  handleEditModal(row);
                                }}
                                alt=""
                              />
                            </div>
                            <div className="image__block">
                              <img
                                src={Delete_iconbl}
                                onClick={() => {
                                  handleDeleteModal(row);
                                }}
                                alt=""
                              />
                            </div>
                          </div>
                        ),
                      },
                    ]}
                    dataSource={getconsoleUploadData}
                    scroll={{ x: 900 }}
                  />
                </Spin>
              </div>
            </div>

            <div className="col-lg-12">
              <Modal
                title= {traslate("ConsoleUploadInfo")}
                className="modalconsupld__section"
                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={600}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button key="submit" type="primary" onClick={handleSubmit}>
                    {traslate("Save")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("DealerID")} : <span>*</span>
                              </label>
                              <Select
                                name="dealerID"
                                value={dealerID ? dealerID : null}
                                showSearch
                                placeholder=" Select Dealer ID"
                                optionFilterProp="children"
                                onChange={(e) =>
                                  handleOnChange(e, "select", "dealerID")
                                }
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                className={
                                  dealerIDVal ? "border__red" : "border__grey"
                                }
                              >
                                {dealerList.map((item) => {
                                  return (
                                    <Option
                                      key={item.dealerID}
                                      value={item.dealerID}
                                    >
                                      {item.dealerCompany +
                                        " (" +
                                        item.dealerID +
                                        " )"}
                                    </Option>
                                  );
                                })}
                              </Select>
                              <div>
                                {dealerIDVal && (
                                  <p className="error-color-red">
                                    {dealerIDVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("FTPUserName")}: <span>*</span>
                              </label>
                              <input
                                name="fTPUserName"
                                value={fTPUserName}
                                onChange={handleOnChange}
                                type="text"
                                placeholder="Enter User Name"
                                className={fTPUserNameVal && "border__red"}
                              />
                              <div>
                                {fTPUserNameVal && (
                                  <p className="error-color-red">
                                    {fTPUserNameVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("FTPPassword")}: <span>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Password"
                                name="fTPPassword"
                                value={fTPPassword}
                                onChange={handleOnChange}
                                className={fTPPasswordVal && "border__red"}
                              />

                              <div>
                                {fTPPasswordVal && (
                                  <p className="error-color-red">
                                    {fTPPasswordVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("FileType")}: <span>*</span>
                              </label>
                              <Select
                                name="fileTypeId"
                                value={fileTypeId ? fileTypeId : null}
                                placeholder=" Select FileType"
                                onChange={(e) =>
                                  handleOnChange(e, "select", "fileTypeId")
                                }
                                className={
                                  fileTypeVal ? "border__red" : "border__grey"
                                }
                              >
                                {fileTypeData.map((item) => {
                                  return (
                                    <Option
                                      key={item.fileTypeId}
                                      value={item.fileTypeId}
                                    >
                                      {item.fileType}
                                    </Option>
                                  );
                                })}
                              </Select>
                              <div>
                                {fileTypeVal && (
                                  <p className="error-color-red">
                                    {fileTypeVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Path")}: <span>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Path"
                                name="path"
                                value={path}
                                onChange={handleOnChange}
                                className={pathVal && "border__red"}
                              />
                              <div>
                                {pathVal && (
                                  <p className="error-color-red">{pathVal}</p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>{traslate("AddUpdate")}</label>
                              <Switch
                                name="addUpdate"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={addUpdate}
                                onChange={(e) =>
                                  handleOnChange(e, "select", "addUpdate")
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>

              <Modal
                title= {traslate("ConsoleUploadEditInfo")}
                className="modalconsupld__section"
                centered
                visible={visible2}
                onOk={() => setVisible2(false)}
                onCancel={() => setVisible2(false)}
                width={600}
                footer={[
                  <Button key="back" onClick={handleCancel2}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button key="submit" type="primary" onClick={handleUpdate}>
                    {traslate("Save")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-12 mb-4">
                            <h4>
                              {editConsoleUploadData.company} -{" "}
                              {editConsoleUploadData.dealerID}
                            </h4>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("FTPUserName")}: <span>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter User Name"
                                onChange={edithandleOnChange}
                                name="userName"
                                value={editConsoleUploadData.userName}
                                className={
                                  editvalidations.editfTPUserNameVal &&
                                  "border__red"
                                }
                              />
                              <div>
                                {editvalidations.editfTPUserNameVal && (
                                  <p className="error-color-red">
                                    {editvalidations.editfTPUserNameVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("FTPPassword")}: <span>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Password"
                                onChange={edithandleOnChange}
                                name="password"
                                value={editConsoleUploadData.password}
                                className={
                                  editvalidations.editfTPPasswordVal &&
                                  "border__red"
                                }
                              />
                              <div>
                                {editvalidations.editfTPPasswordVal && (
                                  <p className="error-color-red">
                                    {editvalidations.editfTPPasswordVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("FileType")}: <span>*</span>
                              </label>
                              <Select
                                placeholder=" Select FileType"
                                value={editConsoleUploadData.fileTypeId}
                                name="fileTypeId"
                                onChange={(e) =>
                                  edithandleOnChange(e, "select", "fileTypeId")
                                }
                                className={
                                  editvalidations.editfileTypeVal
                                    ? "border__red"
                                    : "border__grey"
                                }
                              >
                                {fileTypeData.map((item) => {
                                  return (
                                    <Option
                                      key={item.fileTypeId}
                                      value={item.fileTypeId}
                                    >
                                      {item.fileType}
                                    </Option>
                                  );
                                })}
                              </Select>
                              <div>
                                {editvalidations.editfileTypeVal && (
                                  <p className="error-color-red">
                                    {editvalidations.editfileTypeVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Path")}: <span>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Path"
                                value={editConsoleUploadData.path}
                                onChange={edithandleOnChange}
                                name="path"
                                className={
                                  editvalidations.editpathVal && "border__red"
                                }
                              />
                              <div>
                                {editvalidations.editpathVal && (
                                  <p className="error-color-red">
                                    {editvalidations.editpathVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>{traslate("AddUpdate")}:</label>
                              <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={editConsoleUploadData.addUpdate}
                                onChange={(e) =>
                                  edithandleOnChange(e, "select", "addUpdate")
                                }
                                name="addUpdate"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>

              <Modal
                title={traslate("CreateFTP")}
                className="modalconsupld__section"
                centered
                visible={visible1}
                onOk={() => setVisible1(false)}
                onCancel={handleCancel1}
                width={400}
                footer={[
                  <Button key="back" onClick={handleCancel1}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button key="submit" type="primary" onClick={handleFtpSumbit}>
                    {traslate("Save")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("EnterUserName")}: <span>*</span>
                              </label>
                              <input
                                name="userName"
                                value={userName}
                                onChange={handleFtpOnchange}
                                type="text"
                                placeholder="Enter User Name"
                                className={userNameVal && "border__red"}
                              />
                              <div>
                                {userNameVal && (
                                  <p className="error-color-red">
                                    {userNameVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("EnterPassword")}: <span>*</span>
                              </label>
                              <input
                                name="password"
                                value={password}
                                onChange={handleFtpOnchange}
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter Password"
                                className={passwordVal && "border__red"}
                              />
                              <span
                                class="p-viewer"
                                onClick={handlePassShowHide}
                              >
                                <i
                                  class={
                                    showPassword
                                      ? "fa fa-eye"
                                      : "fa fa-eye-slash"
                                  }
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <div>
                                {passwordVal && (
                                  <p className="error-color-red">
                                    {passwordVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="input__block">
                              <label> {traslate("EnterFolderName")} :</label>
                              <input
                                name="folder"
                                value={folder}
                                onChange={handleFtpOnchange}
                                type="text"
                                placeholder="Enter Folder Name"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>

              <Modal
                title=""
                className="modalconsupld__section"
                centered
                visible={visible3}
                onOk={() => setVisible3(false)}
                onCancel={() => setVisible3(false)}
                width={400}
                footer={[
                  <Button key="back" onClick={handleCancel3}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={DeleteConsoleUploadData}
                  >
                    {traslate("Delete")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-12 mt-4 text-center">
                            <p>
                            {traslate("Areyousureyouwanttodelete")} "
                              {delConsoleData.dealerID}" {traslate("DealerData")}
                            </p>
                            <h5>{traslate("THISCANNOTBEUNDONE")}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConsoleUpload;
