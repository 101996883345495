import React, { useState, useEffect } from "react";
import { Input, Select, Button, Table, Modal, Tooltip } from "antd";
import { Tabs, Tab, Accordion } from "react-bootstrap";

import Create_icon from "../../../assets/images/create_icon.png";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import noun_upload_import from "../../../assets/images/icons/noun_upload_import.svg";
import noun_upload_export from "../../../assets/images/icons/noun_upload_export.svg";
import close from "../../../assets/images/icons/close.svg";
import close_red from "../../../assets/images/icons/close_red.svg";
import Excel_icon from "../../../assets/images/Excel_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import CatalogJewelryDataService from "../../../services/catalog-jewelry.service";

const { Option } = Select;
const { Search } = Input;

export const DiamondDataMaping = () => {
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loginUserDeatils, setLoginUserDetails] = useState({});
  const [mappedAttributesList, setMappedAttributesList] = useState([]);
  const [notMappedAttributesList, setNotMappedAttributesList] = useState([]);
  const [columnListByColumnId, setColumnListByColumnId] = useState([]);
  const [dataMappingList, setDataMappingList] = useState([]);

  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setLoginUserDetails(loginDetials.responseData);
        handleGetDealerMappedAttributesList(loginDetials.responseData.dealerId);
        handleGetDealerNotMappedAttributesList(
          loginDetials.responseData.dealerId
        );
      }
    }
  }, []);
  const onSearch = (value) => console.log(value);

  const handleGetDealerMappedAttributesList = (id) => {
    try {
      let inputData = {
        dealerid: id || 0,
      };
      CatalogJewelryDataService.GetDealerMappedAttributesList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setMappedAttributesList(responseData);
            } else {
              setMappedAttributesList([]);
            }
          } else {
            setMappedAttributesList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetDealerNotMappedAttributesList = (id) => {
    try {
      let inputData = {
        dealerid: id || 0,
      };
      CatalogJewelryDataService.GetDealerNotMappedAttributesList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setNotMappedAttributesList(responseData);
            } else {
              setNotMappedAttributesList([]);
            }
          } else {
            setNotMappedAttributesList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAttributeListByColumnId = (id, cid) => {
    try {
      let inputData = {
        dealerId: id,
        columnID: cid,
      };
      CatalogJewelryDataService.GetAttributeListByColumnId(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setDataMappingList(responseData);
            } else {
              setDataMappingList([]);
            }
          } else {
            setDataMappingList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: (
        <span>
          Attribute
          <Tooltip
            placement="right"
            className="ml-1"
            title={"JewelCloud defined column name."}
          >
            <img src={UnionIcon} alt="" />
          </Tooltip>
        </span>
      ),
      dataIndex: "attribute",
      width: 140,
    },
    {
      title: (
        <span>
          Type
          <Tooltip
            placement="right"
            className="ml-1"
            title={
              "This defines what type of data is allowed. Fixed lables means you must use an approved JewelCloud value. Numeric can only contain numbers and decimal."
            }
          >
            <img src={UnionIcon} alt="" />
          </Tooltip>
        </span>
      ),
      dataIndex: "type",
      width: 140,
    },
    {
      title: (
        <span>
          Requirement
          <Tooltip
            placement="right"
            className="ml-1"
            title={
              "Data uploads will fail if these columns do not contain a value."
            }
          >
            <img src={UnionIcon} alt="" />
          </Tooltip>
        </span>
      ),
      dataIndex: "requirement",
      width: 140,
    },
    {
      title: (
        <span>
          Your Attribute
          <Tooltip placement="right" className="ml-1" title={"No content."}>
            <img src={UnionIcon} alt="" />
          </Tooltip>
        </span>
      ),
      dataIndex: "yourattribute",
    },
    {
      title: "",
      dataIndex: "hamb__icn",
      width: 40,
    },
  ];

  const columns1 = [
    {
      title: "Column ID",
      dataIndex: "columnid",
    },
    {
      title: "Column Name",
      dataIndex: "columnname",
    },
    {
      title: "",
      dataIndex: "action",
      width: 200,
    },
  ];

  const data1 = [
    {
      key: "1",
      columnid: <span>63052</span>,
      columnname: <span>Additionalinfo1</span>,
      action: (
        <div className="action__btns">
          <div className="image__block">
            <img src={Edit_icon} alt="" />
          </div>
        </div>
      ),
    },
    {
      key: "2",
      columnid: <span>63052</span>,
      columnname: (
        <div>
          <span className="tags__lbl active">
            <span className="tag__name">Categories</span>
            <span>
              <img src={close} alt="" className="close__icn" />
            </span>
          </span>
        </div>
      ),
      action: (
        <div className="action__btns">
          <div className="image__block">
            <img src={Edit_icon} alt="" />
          </div>
        </div>
      ),
    },
    {
      key: "3",
      columnid: <span>63052</span>,
      columnname: <span>Additionalinfo1</span>,
      action: (
        <div className="action__btns">
          <div className="image__block">
            <img src={Edit_icon} alt="" />
          </div>
        </div>
      ),
    },
    {
      key: "4",
      columnid: <span>63052</span>,
      columnname: <span>Additionalinfo1</span>,
      action: (
        <div className="action__btns">
          <div className="image__block">
            <img src={Edit_icon} alt="" />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="prodetail__section">
      <div className="form__fields">
        <div className="prod__maindiv prodmarkup__secion essential__section">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-lg-4 mt-2">
                  <h4 class="workarea__heading mt-0 mb-0">Diamond Mapping</h4>
                </div>
                <div className="col-lg-8 mt-2">
                  <div className="btns__div">
                    <button
                      className="primary-btn"
                      onClick={() => setVisible1(true)}
                    >
                      <img src={noun_upload_export} alt="" /> Import Mapping
                    </button>
                    <button className="primary-btn ml-2">
                      <img src={noun_upload_import} alt="" /> Export Mapping
                    </button>
                    <button
                      className="primary-btn ml-2"
                      onClick={() => setVisible2(true)}
                    >
                      <img src={Create_icon} alt="" /> Add New Column
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4 mb-5">
              <div className="row">
                <div className="col-lg-9 mt-4">
                  <Table
                    className="datamapping__tbl"
                    columns={columns}
                    dataSource={dataMappingList}
                    scroll={{ y: 300 }}
                    pagination={false}
                  />
                </div>
                <div className="col-lg-3 mt-4">
                  <div className="prodesc__div">
                    <Tabs
                      defaultActiveKey="mapped"
                      id="uncontrolled-tab-example"
                      className="tab__div"
                    >
                      <Tab eventKey="mapped" title="Mapped">
                        <div className="tab__contentdiv">
                          <div className="col-lg-12">
                            <ul className="tags__ul">
                              {mappedAttributesList.map((item) => {
                                return (
                                  <React.Fragment>
                                    <li className="tags__lbl active ">
                                      <span
                                        className="tag__name"
                                        title={item.columnName}
                                      >
                                        {item.columnName}
                                      </span>
                                    </li>
                                  </React.Fragment>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="nonmapped" title="Non Mapped">
                        <div className="tab__contentdiv">
                          <div className="col-lg-12">
                            <ul className="tags__ul">
                              {notMappedAttributesList.map((item) => {
                                return (
                                  <React.Fragment>
                                    <li className="tags__lbl active">
                                      <span
                                        className="tag__name"
                                        title={item.columnName}
                                      >
                                        {item.columnName}
                                      </span>
                                    </li>
                                  </React.Fragment>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-5">
              <div className="desc__div">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="mt-2">
                      <h4 className="head__txt">Name</h4>
                      <p className="desc__txt">Product Name</p>
                    </div>
                    <div className="mt-4">
                      <h4 className="head__txt">Accepted Values</h4>
                      <p className="desc__txt">Alphanumeric</p>
                    </div>
                    <div className="mt-4">
                      <h4 className="head__txt">Required</h4>
                      <p className="desc__txt">Yes</p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="mt-2">
                      <h4 className="head__txt">Example</h4>
                      <p className="desc__txt">ProductName1</p>
                    </div>
                    <div className="mt-4">
                      <h4 className="head__txt">Feild Type</h4>
                      <p className="desc__txt">Alphanumeric</p>
                    </div>
                    <div className="mt-4">
                      <h4 className="head__txt">Configurable</h4>
                      <p className="desc__txt">No</p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="mt-2">
                      <h4 className="head__txt">Definition</h4>
                      <p className="desc__txt">
                        Short title for the product. This will be displayed on
                        product pages. If listing child products, please include
                        color and size in the name to differentiate.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="count__div">
                      <div className="count__indiv">
                        <span className="no__txt">243</span>
                        <span className="counthead__txt">Mapped</span>
                      </div>
                      <div className="count__indiv">
                        <span className="no__txt">-1</span>
                        <span className="counthead__txt">Remaining</span>
                      </div>
                      <div className="reset__div">
                        <i class="fa fa-refresh"></i>
                      </div>
                    </div>
                    <div className="content__div mt-4">
                      <p className="conthead__txt">
                        Map Your Content <br /> Drag & Drop Your Attributes
                      </p>
                      <span className="desc__txt">
                        Match Your attributes with the jewel cloud industry
                        standard
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Modal
              title="Attribute Value Map"
              className="datamapping__modal"
              visible={visible}
              width={600}
              onOk={() => setVisible(false)}
              onCancel={() => setVisible(false)}
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => setVisible(false)}
                >
                  Save
                </Button>,
              ]}
            >
              <div className="form__fields row border-0 p-0">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <span>
                        Match Your Variables to the jewelCloud Industry Standard
                      </span>
                    </div>
                    <div className="col-lg-6">
                      <div className="inptsrch__div">
                        <Search
                          placeholder="input search text"
                          onSearch={onSearch}
                          style={{ width: 200 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mt-4">
                  <div className="head__div">
                    <span>Categories</span>
                  </div>
                </div>
                <div className="col-lg-12">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div>
                          <span> Accessories</span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="input__block">
                                <div className="row">
                                  <div className="col-md-3 align-self-center">
                                    <label className="mb-0">
                                      Accessories <span>*</span>
                                    </label>
                                  </div>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      placeholder="Enter Accessories"
                                      name=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input__block">
                                <div className="row">
                                  <div className="col-md-3 align-self-center">
                                    <label className="mb-0">
                                      Tiaras <span>*</span>
                                    </label>
                                  </div>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      placeholder="Enter Tiaras"
                                      name=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input__block">
                                <div className="row">
                                  <div className="col-md-3 align-self-center">
                                    <label className="mb-0">
                                      Womans Fashion Bag <span>*</span>
                                    </label>
                                  </div>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      placeholder="Enter Womans Fashion Bag"
                                      name=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <div>
                          <span> Atia 81</span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="col-md-12">
                          <div className="accordian_chkbxdiv">
                            <div>
                              <input type="checkbox" id="abc" />
                              <label for="abc"> Fashion Earrings</label>
                            </div>
                            <div>
                              <input type="checkbox" id="asd" />
                              <label for="asd"> Button</label>
                            </div>
                            <div>
                              <input type="checkbox" id="sdg" />
                              <label for="sdg"> Chandelier</label>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <div>
                          <span> Beads/Charms</span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="col-md-12">
                          <div className="accordian_chkbxdiv">
                            <div>
                              <input type="checkbox" id="abc" />
                              <label for="abc"> Fashion Earrings</label>
                            </div>
                            <div>
                              <input type="checkbox" id="asd" />
                              <label for="asd"> Button</label>
                            </div>
                            <div>
                              <input type="checkbox" id="sdg" />
                              <label for="sdg"> Chandelier</label>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <div>
                          <span> Bracelets</span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="col-md-12">
                          <div className="accordian_chkbxdiv">
                            <div>
                              <input type="checkbox" id="abc" />
                              <label for="abc"> Fashion Earrings</label>
                            </div>
                            <div>
                              <input type="checkbox" id="asd" />
                              <label for="asd"> Button</label>
                            </div>
                            <div>
                              <input type="checkbox" id="sdg" />
                              <label for="sdg"> Chandelier</label>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </Modal>

            <Modal
              className="datamapping__modal"
              visible={visible1}
              width={600}
              onOk={() => setVisible1(false)}
              onCancel={() => setVisible1(false)}
              footer={[]}
            >
              <div className="form__fields row border-0 p-0">
                <div className="col-lg-12">
                  <div className="imptmap__headdiv">
                    <span>Import Mapping</span>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="excel__maindiv mt-4">
                    <div className="excel__icndiv">
                      <img src={Excel_icon} alt="" />
                    </div>
                    <div className="excel__headtxtdiv">
                      <span className="head__txt">
                        Your sheet just needs your column headers.
                      </span>
                      <span className="extn__txt">.xls | .txt | .csv</span>
                    </div>
                  </div>
                  <div className="excelfileupl__div mt-3">
                    <label
                      for="file-upload"
                      class="primary-btn choose_file_upload"
                    >
                      Choose File
                    </label>
                    <input id="file-upload" type="file" />
                    <p className="filename__txt">
                      Product Name1.xls <img src={close_red} alt="" />
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 mt-4">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input__block">
                        <label className="">
                          Choose Format
                          <span>*</span>
                          <Tooltip placement="right" title={"No content"}>
                            <img src={UnionIcon} alt="" />
                          </Tooltip>
                        </label>
                        <Select
                          showSearch
                          className="border__grey"
                          placeholder="Choose Format"
                          name="availability"
                          optionFilterProp="children"
                        >
                          <Option value="Delivery Time">Delivery Time</Option>
                          <Option value="24-48 hours">24-48 hours</Option>
                        </Select>
                        <button className="primary-btn mt-3">
                          Upload Sheet
                        </button>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input__block">
                        <label className="">
                          Import Mapping Template
                          <span>*</span>
                        </label>
                        <Select
                          showSearch
                          className="border__grey"
                          placeholder="Jewel Cloud"
                          name="availability"
                          optionFilterProp="children"
                        >
                          <Option value="Delivery Time">Delivery Time</Option>
                          <Option value="24-48 hours">24-48 hours</Option>
                        </Select>
                        <button className="primary-btn mt-3">Load File</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>

            <Modal
              title="Attribute A New Mappable Column"
              className="datamapping__modal"
              visible={visible2}
              onOk={() => setVisible2(false)}
              onCancel={() => setVisible2(false)}
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => setVisible2(false)}
                >
                  Save
                </Button>,
              ]}
            >
              <div className="form__fields row border-0 p-0">
                <div className="col-lg-12">
                  <span>Add A Single New Column Header.</span>
                </div>
                <div className="col-lg-12 mt-3">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="input__block">
                        <input type="text" placeholder="Add Attribute" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="input__block">
                        <button className="primary-btn">Add New</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mt-2">
                  <Table
                    className="Addnewmap__tbl"
                    columns={columns1}
                    dataSource={data1}
                    scroll={{ y: 300 }}
                    pagination={false}
                  />
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiamondDataMaping;
