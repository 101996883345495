import React, { useState } from "react";
import { Input, Row, Col, Select, Checkbox, Button, Switch } from "antd";

const StudBuilderShoppingIntegrations = () => {
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };
  return (
    <React.Fragment>
      <div className="shopintgrate__section">
        <div className="form__fields">
          <div className="col-md-12 mt-2">
            <h4 className="workarea__heading mt-0 mb-0">Stud Builder Shopping cart integration instructions:</h4>
          </div>

          <div className="col-lg-12 mt-5">
            <p>We will be sending parameters to the page on your website as below url</p>

            <a href="#" className="link__txt">"http://www.yourwebsite.com/shoppingcart.aspx?DealerID=&DiamondID= Selected Diamond1 ID , Selected Diamond2 ID & DesignerProductID="</a>

            <p className="subheading mt-4">Example Url:</p>

            <a href="#" className="link__txt">http://www.yourwebsite.com/shoppingcart.aspx?DealerID=3030&DiamondID=38267238, 38267239&DesignerProductID=123456</a>

            <p>There will be two diamond id passed comma separated so you will need to get diamond details twice once for each diamond as stud has pair of stones</p>

            <p className="subheading mt-4">To Get Diamond Detail:</p>

            <span><a href="#" className="link__txt mt-2">Click Here</a> to access API to get diamond detail and checkout how it works online.</span>

            <p className="subheading mt-4">Below is the list of parameters with respective possible values which can be supplied to each parameter.</p>

            <p className="mt-4"><span className="headred__txt">DiamondId:-</span> Pass diamond number of diamond. (you will get this as DiamondID on query string of the url as shown above)</p>

            <p className="mt-4"><span className="headred__txt">DealerLink:-</span> Pass GemFind # which is assigned to your account. For Example, 720 for GemFind.</p>

            <p className="mt-4">You will get XML formatted response from the API response. You will need to parse and use that data to create diamond as product on your website.</p>

            <p className="subheading mt-4">To Get Stud Mounting Detail:</p>

            <span><a href="#" className="link__txt mt-2">Click Here</a> to access API to get Stud Mounting detail and checkout how it works online.</span>

            <p className="subheading mt-4">Below is the list of parameters with respective possible values which can be supplied to each parameter.</p>

            <p className="mt-4"><span className="headred__txt">DealerID:-</span> Pass GemFind # which is assigned to your account. For Example, 720 for GemFind.</p>

            <p className="mt-4"><span className="headred__txt">JewelryID:-</span> Pass JewelryID # for which you want to get data. (DesignerProductID from the query string parameter as shown on example url)</p>

          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StudBuilderShoppingIntegrations;
