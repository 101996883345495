import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Table,
  Modal,
  DatePicker,
  Switch,
  Space,
  Tooltip
} from "antd";
import {
  EditOutlined,
  CalendarOutlined,
  SearchOutlined
} from "@ant-design/icons";

import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import traslate from "../../../i18n/translate";

const { Option } = Select;

export const MyVendors = props => {
  const style = (
    <span>
     {traslate(
        "Ifselectedyouwillreceivenewcollectionsfromthisvendor,Ifnotselected,youwillonlyrecieveupdatestoexistingcollections."
      )}
    </span>
  );

  const [switchValue, setswitchValue] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };

  const columns = [
    {
      title: traslate("Vendor"),
      dataIndex: "vendor"
    },
    {
      title: traslate("Type"),
      dataIndex: "type"
    },
    {
      title: traslate("DataRating"),
      dataIndex: "dataRating"
    },
    {
      title: traslate("ActiveProducts"),
      dataIndex: "activeproducts"
    },
    {
      title: traslate("Updated"),
      dataIndex: "updated"
    },
    {
      title: (
        <span>
          {traslate("AcceptNewCollection")}
          <Tooltip placement="right" className="ml-2" title={style}>
            <img src={UnionIcon} alt="" />
          </Tooltip>
        </span>
      ),
      dataIndex: "acceptnew"
    }
  ];

  const data = [
    {
      key: "1",
      vendor: "Overnight Mountings Ring Builder",
      type: "Active",
      dataRating: (
        <div className="star-ratings">
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star-half-o" aria-hidden="true"></i>
        </div>
      ),
      activeproducts: "381 / 101",
      updated: "Over 90 Days",
      acceptnew: (
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          defaultChecked
          onChange={handleSwitch}
        />
      )
    },
    {
      key: "2",
      vendor: "Overnight Mountings Ring Builder",
      type: "Active",
      dataRating: (
        <div className="star-ratings">
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star-half-o" aria-hidden="true"></i>
        </div>
      ),
      activeproducts: "381 / 101",
      updated: "Over 90 Days",
      acceptnew: (
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          defaultChecked
          onChange={handleSwitch}
        />
      )
    },
    {
      key: "3",
      vendor: "Overnight Mountings Ring Builder",
      type: "Active",
      dataRating: (
        <div className="star-ratings">
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star-half-o" aria-hidden="true"></i>
        </div>
      ),
      activeproducts: "381 / 101",
      updated: "Over 90 Days",
      acceptnew: (
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          defaultChecked
          onChange={handleSwitch}
        />
      )
    },
    {
      key: "4",
      vendor: "Overnight Mountings Ring Builder",
      type: "Active",
      dataRating: (
        <div className="star-ratings">
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star" aria-hidden="true"></i>
          <i class="fa fa-star-half-o" aria-hidden="true"></i>
        </div>
      ),
      activeproducts: "381 / 101",
      updated: "Over 90 Days",
      acceptnew: (
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          defaultChecked
          onChange={handleSwitch}
        />
      )
    }
  ];

  return (
    <div className="form__fields">
      <div className="row">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0 mb-0"> {traslate("InviteYourVendors")} </h4>
          </div>
        </div>
        <div className="col-lg-12 mt-5">
          <div className="row">
            <div className="col-lg-4">
              <div className="input__block">
                <label>
                {traslate("CompanyName")}
                  <span>*</span>
                </label>
                <input type="textbox" placeholder="Enter Company Name" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input__block">
                <label>
                {traslate("City")}
                  <span>*</span>
                </label>
                <input type="textbox" placeholder="Enter City" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input__block">
                <label>
                {traslate("State")}
                  <span>*</span>
                </label>
                <input type="textbox" placeholder="Enter State" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input__block">
                <label>
                {traslate("Country")}
                  <span>*</span>
                </label>
                <input type="textbox" placeholder="Enter Country" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input__block">
                <label>
                {traslate("ContactName")}
                  <span>*</span>
                </label>
                <input type="textbox" placeholder="Enter Contact Name" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input__block">
                <label>
                {traslate("E-MailAddress")}
                  <span>*</span>
                </label>
                <input type="email" placeholder="Enter E-Mail Address" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="input__block">
                <label>
                {traslate("PhoneNumber")}
                  <span>*</span>
                </label>
                <input type="number" placeholder="Enter Phone Number" />
              </div>
            </div>
            <div className="col-lg-12">
                <button className="primary-btn"> {traslate("SendInvitation")} </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyVendors);
