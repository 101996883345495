import { LOCALES } from "../locales";

export default {
  [LOCALES.ENGLISH]: {
    Tools: "Tools",
    AdministrationTools: "Administration Tools",
    ApplicationPermission: "Application Permission",
    ReportPermissions: "Report Permissions",
    ClientDataMaps: "Client Data Maps",
    ClientStatus: "Client Status",
    CreateNewClient: "Create New Client",
    ["3rdPartyDiamondImports"]: "3rd Party Diamond Imports",
    SOLRCoreClient: "SOLR Core Client",
    VendorRating: "Vendor Rating",
    Limits: "Limits",
    Legacy: "Legacy",
    ConsoleUpload: "Console Upload",
    OpenSourceMapping: "Open Source Mapping",
    PendingSync: "Pending Sync",
    ["TransferZZ.Gemfind"]: "Transfer ZZ.Gemfind",
    LoginPreferences: "Login Preferences",
    RetailerLocator: "Retailer Locator",
    BlockDesignersForDemo: "Block Designers For Demo",
    Settings: "Settings",
    Permissions: "Permissions",
    EmailTemplate: "Email Template",
    DataMaps: "Data Maps",
    MetalMarket: "Metal Market",
    TradeShows: "Trade Shows",
    AddColor: "Add Color",
    ApiAccessToken: "Api Access Token",
    Campaigns: "Campaigns",
    MailingList: "Mailing List",
    Compose: "Compose",
    Report: "Report",
    GlobalEvents: "Global Events",
    Syncs: "Syncs",
    ToolsReports: "Tools Reports",
    ClientsReports: "Clients Reports",
    PermissionReports: "Permission Reports",
    DataRelated: "Data Related",
    JewelryLogReports: "Jewelry Log Reports",
    DiamondReport: "Diamond Report",
    DiamondClicks: "Diamond Clicks",
    ExtendedDiamondChart: "Extended Diamond Chart",
    DiamondClarityReport: "Diamond Clarity Report",
    DiamondColorReport: "Diamond Color Report",
    DiamondCutReport: "Diamond Cut Report",
    DiamondShapeReport: "Diamond Shape Report",
    DiamondCertificateReport: "Diamond Certificate Report",
    DiamondSizeReport: "Diamond Size Report",
    JewelryReport: "Jewelry Report",
    JewelryChartReport: "Jewelry Chart Report",
    JewelryCategoryReport: "Jewelry Category Report",
    JewelryMetalTypeReport: "Jewelry Metal Type Report",
    JewelryPriceReport: "Jewelry Price Report",
    JewelryRetailerClicksReport: "Jewelry Retailer Clicks Report",
    JewelryVendorClicksReport: "Jewelry Vendor Clicks Report",
    Catalog: "Catalog",
    Diamond: "Diamond",
    MinedDiamond: "Mined Diamond",
    ColorDiamond: "Color Diamond",
    LabDiamond: "Lab Diamond",
    UploadHistory: "Upload History",
    AddIndividualDiamond: "Add Individual Diamond",
    AddDiamond: "Add A Diamond",
    UploadDiamondFile: "Upload Diamond File",
    DiamondMapping: "Diamond Mapping",
    ManageFTPCredentials: "Manage FTP Credentials",
    DownloadProductFile: "Download Product File",
    Jewelry: "Jewelry",
    CatalogList: "Catalog List",
    ViewMyProduct: "View My Product",
    AddProduct: "Add Product",
    UploadProductFile: "Upload Product File",
    ManageCollection: "Manage Collection",
    ProductMarkup: "Product Markup",
    AddCollection: "Add Collection",
    DataMapping: "Data Mapping",
    Essential: "Essential",
    ItemIdentity: "Item Identity",
    ConfigurableProducts: "Configurable Products",
    ItemDetails: "Item Details",
    ItemPricing: "Item Pricing",
    RelationalPricing: "Relational Pricing",
    CostBasisPricing: "Cost Basis Pricing",
    ["Item-PromoSpecials"]: "Item-Promo Specials",
    ItemMedia: "Item Media",
    ItemSizing: "Item Sizing",
    GemstoneDetails: "Gemstone Details",
    ["Semi-Mounts&RingBuilder"]: "Semi-Mounts & Ring Builder",
    WatchDetails: "Watch Details",
    AdditionalDetails: "Additional Details",
    PurchaseOrder: "Purchase Order",
    PurchaseOrderHistory: "Purchase Order History",
    UpdatePurchaseOrder: "Update Purchase Order",
    Marketing: "Marketing",
    UploadMarketingMaterial: "Upload Marketing Material",
    Appointment: "Appointment",
    Apps: "Apps",
    DiamondLinks: "Diamond Links",
    ApplicationPreview: "Application Preview",
    Dealers: "Dealers",
    MinedDiamondMarkup: "Mined Diamond Markup",
    FancyColorMarkup: "Fancy Color Markup",
    LabGrowMarkup: "Lab Grow Markup",
    AvailableFilterSettings: "Available Filter Settings",
    InitialFilterSettings: "Initial Filter Settings",
    Options: "Options",
    Shipping: "Shipping",
    Navigation: "Navigation",
    ShoppingCartIntegration: "Shopping Cart Integration",
    ["ApiEndPointAndAuthentication."]: "Api EndPoint And Authentication.",
    RingBuilder: "Ring Builder",
    Vendors: "Vendors",
    ArchiveVendors: "Archive Vendors",
    ManageVendors: "Manage Vendors",
    DiamondMarkup: "Diamond Markup",
    SettingMarkups: "Setting Markups",
    MasterLink: "Master Link",
    ["E-mailTemplates"]: "E-mail Templates",
    StudBuilder: "Stud Builder",
    ShoppingCartIntegrations: "Shopping Cart Integrations",
    SettingMarkup: "Setting Markup",
    WebsiteSync: "Website Sync",
    SyncHistory: "Sync History",
    OutBoundMapping: "OutBound Mapping",
    FTPDetails: "FTP Details",
    WatchCustomizer: "Watch Customizer",
    TimePieces: "Time Pieces",
    PendantBuilder: "Pendant Builder",
    SearchFeature: "Search Feature",
    DiamondSearch: "Diamond Search",
    ColorDiamondSearch: "Color Diamond Search",
    LabDiamondSearch: "Lab Diamond Search",
    WatchesSearch: "Watches Search",
    JewelrySearch: "Jewelry Search",
    Community: "Community",
    VendorManager: "Vendor Manager",
    MyVendors: "My Vendors",
    Network: "Network",
    OutOfNetwork: "Out Of Network",
    New: "New",
    RecentlyUpdated: "Recently Updated",
    InviteYourVendor: "Invite Your Vendor",
    Popular: "Popular",
    Archived: "Archived",
    PendingRequests: "Pending Requests",
    Retailer: "Retailer",
    ["JewelryRetailer&Permission"]: "Jewelry Data & Permission",
    PendingJewelryRequest: "Pending Jewelry Request",
    ["DiamondRetailer&Permission"]: "Diamond Data & Permission",
    PendingDiamondRequest: "Pending Diamond Request",
    ["RingBuilderRetailer&Permission"]: "Ring Builder Retailer & Permission",
    PendingRingBuilderRequest: "Pending Ring Builder Request",
    RetailerProfile: "Retailer Profile",
    JewelryChartReports: "Jewelry Chart Reports",
    Help: "Help",
    ReportAnIssue: "Report An Issue",
    SuggestANewFeature: "Suggest A New Feature",
    WatchTrainingVideos: "Watch Training Videos",

    EditProfile: "Edit Profile",
    ManageUser: "Manage User",
    ManageAccount: "Manage Account",
    ManageLanguage: "Manage Language",
    ManageCurrency: "Manage Currency",
    Logout: "Logout",
    TopMenu: "Top Menu",
    Dashboard: "Dashboard",
    Contact: "Contact",
    Admin: "Admin",

    Conversations: "Conversations",
    TermsofService: "Terms of Service",
    PrivacyStatement: "Privacy Statement",
    ["Copyright2021GemFind"]: "Copyright 2021 GemFind",

    ["-ServerUp-Time:Uptimeinfonotavailable-pleasetryreloadingthispage"]:
      "-Server Up-Time: Uptime info not available - please try reloading this page",
    JewelCloudSpeedTest: "JewelCloud Speed Test",
    ["-RapNetPricesUpdated:"]: "-RapNet Prices Updated:",
    [8 / 11 / 2017]: "8/11/2017",
    ClientTools: "Client Tools",
    SelectClientType: "Select Client Type",
    ClientName: "Client Name",
    SelectedClientTools: "Selected Client Tools",
    Name: "Name",
    Phone: "Phone",
    Type: "Type",
    Verified: "Verified",
    ApprovedApps: "Approved Apps",
    Users: "Users",
    Username: "Username",
    Password: "Password",
    Edit: "Edit",
    ViewProfile: "View Profile",
    DownloadInventory: "Download Inventory",
    ImpersonateJewelCloud: "Impersonate JewelCloud",
    ["ReturntoJewelCloudasDealer720"]: "Return to JewelCloud as Dealer 720",
    ImpersonateGemfindnet: "Impersonate Gemfind.net",
    ["ReturntoGemfindnetasDealer720"]: "Return to Gemfind.net as Dealer 720",
    Updated: "Updated",
    DataRating: "Data Rating",
    Location: "Location",
    Active: "Active",
    ProductLoves: "Product Loves",
    Collections: "Collections",
    Items: "Items",
    ContactName: "Contact Name",
    Vendortags: "Vendor tags",
    Notagtodisplay: "No tag to display",
    Brands: "Brands",
    ResetPassword: "Reset Password",
    UserName: "User Name",
    ConfirmPassword: "Confirm Password",

    AppActivationControl: "App Activation Control",
    SelectDealer: "Select Dealer",
    DiamondLink: "Diamond Link",
    DiamondSearchLinks: "Diamond Search Links",
    DSEcommerce: "DS Ecommerce",
    DSSSL: "DS SSL",
    DSShowPoweredBy: "DS Show PoweredBy",
    DSLabGrownDiamonds: "DS LabGrownDiamonds",
    JewelCloud: "Jewel Cloud",
    API: "API",
    Directfeed: "Direct feed",
    WindowsApp: "Windows App",
    MLLegacy: "ML Legacy",
    ML2: "ML2",
    ML3: "ML3",
    MLEcommerce: "ML Ecommerce",
    MLSSL: "ML SSL",
    MLShowPoweredBy: "ML Show PoweredBy",
    RBEcommerce: "RB Ecommerce",
    RBSSL: "RB SSL",
    RBShowPoweredBy: "RB Show PoweredBy",
    Tryon: "Tryon",
    SBEcommerce: "SB Ecommerce",
    SBSSL: "SB SSL",
    SBShowPoweredBy: "SB Show PoweredBy",
    PBEcommerce: "PB Ecommerce",
    PBSSL: "PB SSL",
    PBShowPoweredBy: "PB Show PoweredBy",
    ["Gemfind.TV"]: "Gemfind.TV",
    ["PurchaseOrder(PO)"]: "Purchase Order(PO)",
    ["Bridal/FashionSeparation"]: "Bridal/Fashion Separation",
    DiamondGroupDiscount: "Diamond Group Discount",
    POSSystem: "POS System",
    Submit: "Submit",

    ReportsPermissions: "Reports Permissions",
    All: "All",
    DiamondDealers: "Diamond Dealers",
    Retailers: "Retailers",
    DiamondReports: "Diamond Reports",
    JewelryReports: "Jewelry Reports",
    GlobalDiamondReports: "Global Diamond Reports",
    GlobalJewelryReports: "Global Jewelry Reports",

    ClientDataMapping: "Client Data Mapping",
    DealerID: "Dealer ID",
    DealerCompany: "Dealer Company",
    WebsiteAddress: "Website Address",
    Upload: "Upload",
    InboundDataMap: "InBound Data Map",
    OutgoingDataMap: "Outgoing Data Map",
    OutboundSync: "OutBound Sync",
    SetDefaultMapping: "Set Default Mapping",

    Search: "Search",
    Reset: "Reset",
    InActive: "InActive",
    PermissionType: "Permission Type",
    VendorDiamond: "VendorDiamond",
    Vendor: "Vendor",
    VendorStatus: "Vendor Status",
    ProfileOnly: "Profile Only",
    RetailerStatus: "Retailer Status",
    ProductType: "Product Type",
    Action: "Action",
    Cancel: "Cancel",
    Save: "Save",
    UpdateClientStatusInformation: "Update Client Status Information",
    UpdateDateonwhichClientNameisCreated:
      "Update Date on which Client Name is Created",

    AddNewClient: "Add New Client",
    CompanyName: "Company Name",
    StreetAddress: "Street Address",
    City: "City",
    State: "State",
    Zipcode: "Zip code",
    Country: "Country",
    Contactname: "Contact name",
    EmailAddress: "Email Address",
    PhoneNumber: "Phone Number",
    GeneratePassword: "Generate Password",
    CreateClient: "Create Client",

    Enable3rdPartyDiamondImports: "Enable 3rd Party Diamond Imports",
    DealerName: "Dealer Name",
    Enabled: "Enabled",

    AddNewRetailerToCore: "Add New Retailer To Core",
    SelectCore: "Select Core",
    EnterRetailerID: "Enter Retailer ID",
    Add: "Add",
    id: "id",
    CoreName: "Core Name",
    RetailerID: "Retailer ID",
    RetailerName: "Retailer Name",

    VendorRatings: "Vendor Ratings",
    VendorID: "Vendor ID",
    VendorName: "Vendor Name",
    SystemRatings: "System Ratings",
    ManualRatings: "Manual Ratings",
    ModifiedDate: "Modified Date",
    SaveVendorRating: "Save Vendor Rating",

    LimitRetailerToDiamondDealer: "Limit Retailer To Diamond Dealer",
    LimitDiamondDealerToRetailer: "Limit Diamond Dealer To Retailer",
    LimitRetailerToVendor: "Limit Retailer To Vendor",
    LimitVendorToRetailer: "Limit Vendor To Retailer",
    ApplyFilter: "Apply Filter",
    Rules: "Rules",
    Selected: "Selected",
    Delete: "Delete",
    Create: "Create",
    Download: "Download",
    RuleType: "Rule Type",
    ["Vendor/Dealer"]: "Vendor/Dealer",
    CreatedDate: "Created Date",
    Next: "Next",
    DiamondDealer: "Diamond Dealer",
    AreyousuretodeletethisRule: "Are you sure to delete this Rule",
    THISCANNOTBEUNDONE: "THIS CAN NOT BE UNDONE",
    DeleteLimitRule: "Delete Limit Rule",

    ["(DatabaseadministrationtoolsforDealerID720(Alex)&712(Morgan))"]:
      "(Database administration tools for DealerID 720 (Alex) & 712 (Morgan))",
    ["AddaDealertoallGFLinks:"]: "Add a Dealer to all GFLinks:",
    SelecttheDealerIDyouwantto: "Select the DealerID you want to",
    toallGFLinks: "to all GFLinks",
    ["RemoveaDealerfromallGFLinks:"]: "Remove a Dealer from all GFLinks:",
    Remove: "Remove",
    ["AddaDealertoallGFLinksLabDiamonds:"]:
      "Add a Dealer to all GFLinks Lab Diamonds:",
    toallGFLinksLabDiamonds: "to all GFLinks Lab Diamonds",
    ["RemoveaDealerfromallGFLinksLabDiamonds:"]:
      "Remove a Dealer from all GFLinks Lab Diamonds:",
    AddDealerID: "Add DealerID",
    ["RemoveaDealerfromallGFLinks:SelecttheDealerIDyouwanttoremovetoallGFLinks."]:
      "Remove a Dealer from all GFLinks: Select the DealerID you want to remove to all GFLinks.",
    RemoveDealerID: "Remove DealerID",
    ["AddaDealertoallGFLinksLabDiamonds:SelecttheDealerIDyouwanttoaddtoallGFLinksLabDiamonds."]:
      "Add a Dealer to all GFLinks Lab Diamonds: Select the DealerID you want to add to all GFLinks Lab Diamonds.",
    ["RemoveaDealerfromallGFLinksLabDiamonds:SelecttheDealerIDyouwanttoremovetoallGFLinksLabDiamonds."]:
      "Remove a Dealer from all GFLinks Lab Diamonds: Select the DealerID you want to remove to all GFLinks Lab Diamonds.",

    AddConsoleUploadDealerData: "Add Console Upload Dealer Data",
    CreateFTP: "Create FTP",
    Dealer: "Dealer",
    FTPUserName: "FTP User Name",
    FTPPassword: "FTP Password",
    FileType: "File Type",
    Path: "Path",
    AddUpdate: "Add Update",
    ConsoleUploadInfo: "Console Upload Info",
    ConsoleUploadEditInfo: "Console Upload Edit Info",
    EnterUserName: "Enter User Name",
    EnterPassword: "Enter Password",
    EnterFolderName: "Enter Folder Name",
    Areyousureyouwanttodelete: "Are you sure you want to delete",
    DealerData: "Dealer Data",

    MagentoMapping: "Magento Mapping",
    APIURL: "API URL",
    APIKey: "API Key",
    IsActive: "Is Active",
    EditMegentoMappingInfo: "Edit Megento Mapping Info",
    PortNo: "Port No",
    DeleteDealerMapping: "Delete Dealer Mapping",
    MegentoMappingInfo: "Megento Mapping Info",

    DiscardAll: "Discard All",
    RunValidImageApplication: "Run Valid Image Application",
    SyncID: "Sync ID",
    Status: "Status",
    Discard: "Discard",

    SourceJCID: "Source JC ID",
    DestinationJCID: "Destination JC ID",
    ReplaceAll: "Replace All",
    ["Add&Update"]: "Add & Update",
    Transfer: "Transfer",
    Areyousureyouwanttomovedatafrom: "Are you sure you want to move data from",
    accountto: "account to",
    ["account?-Youhaveselect"]: "account ? - You have select",
    Option: "Option",

    SelectDesignerforNew: "Select Designer for New",
    Note: "Note",
    Thiswillfeatureaspecificvendorforthe:
      "This will feature a specific vendor for the",
    NewtoJewelCloud: "New to JewelCloud",
    sectionontheloginpage: "section on the login page",
    UploadLogoImageforDesigners: "Upload Logo Image for Designers",
    ChooseImage: "Choose Image",
    NoFileChoosen: "No File Choosen",
    Pixels: "Pixels",
    PngFormat: "Png Format",
    SelectDesigner: "Select Designer",
    UploadImage: "Upload Image",
    SelectDesignerToShowonLoginPage: "Select Designer To Show on Login Page",

    AdminPreferences: "Admin Preferences",
    MasterLinkTheme: "MasterLink Theme",
    DefaultTheme: "Default Theme",
    RetailerLocaterBlackTheme: "Retailer Locater Black Theme",

    BlockDesignersForDemoRetailer: "Block Designers For Demo Retailer",
    DesignerName: "Designer Name",
    ["IsBlocked?"]: "Is Blocked ?",
    UpdateSelection: "Update Selection",

    AddNew: "Add New",
    PageNumber: "Page Number",
    ["1TierPageName"]: "1 Tier PageName",
    ["2TierPageName"]: "2 Tier PageName",
    ["3TierPageName"]: "3 Tier PageName",
    VendorSuper: "Vendor Super",
    VendorAdmin: "Vendor Admin",
    VendorUser: "Vendor User",
    ["Vendor-DiamondSuper"]: "Vendor-Diamond Super",
    ["Vendor-DiamondAdmin"]: "Vendor-Diamond Admin",
    ["Vendor-DiamondUser"]: "Vendor-Diamond User",
    RetailerSuper: "Retailer Super",
    RetailerAdmin: "Retailer Admin",
    RetailerUser: "Retailer User",
    AdminSuper: "Admin Super",
    AdminAdmin: "Admin Admin",
    AdminUser: "Admin User",
    CombinedSuper: "Combined Super",
    CombinedAdmin: "Combined Admin",
    CombinedUser: "Combined User",
    OrganizationSuper: "Organization Super",

    EmailTemplates: "Email Templates",
    AllDealers: "All Dealers",
    RegistrationEmails: "Registration Emails",
    NewAccount: "New Account",
    ForgotPassword: "Forgot Password",
    ForgotUserName: "Forgot UserName",
    AccountLocked: "Account Locked",
    RetailerEmails: "Retailer Emails",
    InviteNewRetailer: "Invite New Retailer",
    CommunityEmails: "Community Emails",
    ["RetailerRequeste-Mailto"]: "Retailer Request e-Mail to",
    ACTIVE: "ACTIVE",
    GFMembers: "GF Members",
    ["Retailere-Mailto"]: "Retailer e-Mail to",
    COMMUNITYMANAGED: "COMMUNITY MANAGED",
    NonGFMembers: "Non GF Members",
    PROFILEONLY: "PROFILE ONLY",
    LIMITEDACCOUNT: "LIMITED ACCOUNT",
    VendorDeclineeMail: "Vendor Decline eMail",
    VendorAccepteMail: "Vendor Accept eMail",
    RequesttoUpdateProductData: "Request to Update Product Data",
    VendorAppointment: "Vendor Appointment",
    InviteNewVendor: "Invite New Vendor",
    ["ContactEmails(MasterLink)"]: "Contact Emails (MasterLink)",
    CustomerRegistration: "Customer Registration",
    NewAppointments: "New Appointments",
    ["Inquires(RequestMoreInfo)"]: "Inquires (Request More Info)",
    SendMailtoRetailer: "Send Mail to Retailer",
    SendMailtoSender: "Send Mail to Sender",
    ["SendEmail(EmailToFriend)"]: "Send Email (Email To Friend)",
    SendMailtoFriend: "Send Mail to Friend",
    DropAHint: "Drop A Hint",
    SendMailtoRecipient: "Send Mail to Recipient",
    NewFavoriteList: "New Favorite List",
    NewFavoriteItemsAdded: "New Favorite Items Added",
    CustomerResetPassword: "Customer Reset Password",
    ["CustomerResetPassword(New)"]: "Customer Reset Password (New)",
    DiamondSearchEmails: "Diamond Search Emails",
    RequestMoreInfo: "Request More Info",
    EmailToFriend: "Email To Friend",
    ScheduleView: "Schedule View",
    DiamondDetail: "Diamond Detail",
    DiamondCompare: "Diamond Compare",
    DiamondCustomerCompare: "Diamond Customer Compare",
    FancyColorDiamondSearchEmails: "Fancy Color Diamond Search Emails",
    ColorDiamondDetail: "Color Diamond Detail",
    RingBuilderEmails: "Ring Builder Emails",
    ["DropAHint(Infinity)"]: "Drop A Hint (Infinity)",
    ["BuyNow(Infinity)"]: "Buy Now (Infinity)",
    SettingDetail: "Setting Detail",
    CompleteRingSpecifications: "Complete Ring Specifications",
    PendantBuilderEmails: "Pendant Builder Emails",
    DiamondRequestMoreInfo: "Diamond Request More Info",
    ComplatePendantSetting: "Complate Pendant Setting",
    StudBuilderEmails: "Stud Builder Emails",
    RequestMoreRetailer: "Request More Retailer",
    SBToScheduleView: "SB To ScheduleView",
    SBCompleteEarringToScheduleView: "SB Complete Earring To ScheduleView",
    DiamondEmailToFriend: "Diamond Email To Friend",
    Store: "Store",
    BigEmails: "Big Emails",
    EmailToCustomer: "Email To Customer",
    SendMailtoCustomer: "Send Mail to Customer",
    SendEmail: "Send Email",

    DefaultDataMap: "Default Data Map",
    ["ProductID&Description"]: "Product ID & Description",
    GemfindColumn: "Gemfind Column",
    ActiveStatus: "Active Status",
    Category: "Category",
    Categories: "Categories",
    Attributes: "Attributes",
    Gemstones: "Gemstones",
    Watches: "Watches",
    SaveDetails: "Save Details",
    Create: "Create",

    ManageCurrentMetalMarket: "Manage Current Metal Market",
    CurrentMetalPrice: "Current Metal Price",
    Silver: "Silver",
    Gold: "Gold",
    Platinum: "Platinum",
    Palladium: "Palladium",
    EnterNewMetalType: "Enter New Metal Type",

    TradeShow: "Trade Show",
    Show: "Show",
    Dates: "Dates",
    AddTradeShow: "Add Trade Show",
    EditTradeShow: "Edit Trade Show",
    JPGFormat: "JPG Format",
    ShowName: "Show Name",
    Website: "Website",
    ShowStartTime: "Show Start Time",
    ShowEndTime: "Show End Time",
    AM: "AM",
    Closed: "Closed",
    PM: "PM",
    ShowStartDates: "Show Start Dates",
    ShowEndDates: "Show End Dates",
    DeleteTradeShow: "Delete Trade Show",
    AreYouSureWanttodelete: "Are You Sure Want to delete",

    ["Sr.No."]: "Sr. No.",
    ColorName: "Color Name",
    EditColor: "Edit Color",

    APIType: "API Type",
    Update: "Update",

    MailingLists: "Mailing Lists",
    AliasAddress: "Alias Address",
    NumberOfRecipients: "Number Of Recipients",
    Created: "Created",
    Recipients: "Recipients",
    Sync: "Sync",
    RecipientAddress: "Recipient Address",
    Client: "Client",

    SelectCampaign: "Select Campaign",
    CampaignName: "Campaign Name",
    Submitted: "Submitted",
    Delivered: "Delivered",
    TotalOpens: "Total Opens",
    TotalClicks: "Total Clicks",

    SelectCompose: "Select Compose",
    FromName: "From Name",
    FromEmail: "From Email",
    TypeYourSubject: "Type Your Subject",
    Send: "Send",

    FromDate: "From Date",
    ToDate: "To Date",
    Type: "Type",
    Initiated: "Initiated",
    Complete: "Complete",

    SyncType: "Sync Type",
    ShowFullSyncs: "Show Full Syncs",
    ShowUpdateSyncs: "Show Update Syncs",
    ShowDeleteSyncs: "Show Delete Syncs",
    ShowPriceSyncs: "Show Price Syncs",
    SyncStatus: "Sync Status",
    ShowSuccessSyncs: "Show Success Syncs",
    ShowFailedSyncs: "Show Failed Syncs",
    ShowPendingSyncs: "Show Pending Syncs",

    Mastelink: "Mastelink",
    WatchBuilder: "Watch Builder",
    Version: "Version",

    Diamonds: "Diamonds",
    OpenRequests: "Open Requests",
    LastUpdated: "Last Updated",
    DaysOld: "Days Old",
    Details: "Details",

    MetalTypes: "Metal Types",
    MetalColors: "Metal Colors",
    DiamondPairs: "Diamond Pairs",
    DefaultMappingValues: "Default Mapping Values",
    Heirachy: "Heirachy",

    Count: "Count",
    LastUploadDate: "Last Upload Date",

    SelectDiamond: "Select Diamond",
    TopDiamondAttributeValues: "Top Diamond Attribute Values",
    RecentDiamondAttributeSearches: "Recent Diamond Attribute Searches",
    Date: "Date",
    Shape: "Shape",
    Size: "Size",
    Color: "Color",
    Cut: "Cut",
    Clarity: "Clarity",
    Price: "Price",
    UsersIPAddress: "UsersIPAddress",
    ["Day&Time"]: "Day & Time",
    Certificate: "Certificate",
    RetailPrice: "Retail Price",
    Cost: "Cost",

    ExtendedDiamondChartReports: "Extended Diamond Chart Reports",
    ["Vendor(s)"]: "Vendor(s)",
    ["Retailer(s)"]: "Retailer(s)",
    TotalClicks: "Total Clicks",
    DiamondCutSearches: "Diamond Cut Searches",
    DiamondShapeSearches: "Diamond Shape Searches",
    DiamondClaritySearches: "Diamond Clarity Searches",
    DiamondCertificateSearches: "Diamond Certificate Searches",
    Displaysthetotalnumberofjewelryclicksthroughthecurrentlyspecifieddaterangeabove:
      "Displays the total number of jewelry clicks through the currently specified date range above",

    DownloadCSV: "Download CSV",
    TotalJewelryClicks: "Total Jewelry Clicks",
    JewelryClicksBasedonCategory: "Jewelry Clicks Based on Category",
    JewelryClicksBasedonPrice: "Jewelry Clicks Based on Price",
    JewelryClicksBasedonVendor: "Jewelry Clicks Based on Vendor",

    ProductImage: "Product Image",
    UserIPAddress: "User IP Address",
    Collection: "Collection",
    StyleNumber: "Style Number",
    MetalType: "Metal Type",
    MetalColor: "Metal Color",

    ShowFilter: "Show Filter",
    HideFilter: "Hide Filter",
    Shapes: "Shapes",
    Round: "Round",
    Oval: "Oval",
    Circel: "Circel",
    Heart: "Heart",
    Emarald: "Emarald",
    Radiant: "Radiant",
    Certificates: "Certificates",
    Caret: "Caret",
    Carat: "Carat",
    Depth: "Depth",
    Table: "Table",
    Polish: "Polish",
    ["Length(mm)"]: "Length(mm)",
    ["Width(mm)"]: "Width(mm)",
    ["height(mm)"]: "height(mm)",
    Symmetry: "Symmetry",
    Girdle: "Girdle",
    CrownAngle: "Crown Angle",
    PavilionAngle: "Pavilion Angle",
    Culet: "Culet",
    CuletCondition: "Culet Condition",
    Fluorescence: "Fluorescence",
    FancyColor: "Fancy Color",
    Intensity: "Intensity",
    Overtone: "Overtone",
    MatchedPairs: "Matched Pairs",
    HasVideo: "Has Video",
    ResetFilters: "Reset Filters",
    ApplyFilters: "Apply Filters",
    YouHave: "You Have",
    View: "View",
    Print: "Print",
    ViewDetails: "View Details",
    ContactDealer: "Contact Dealer",
    ["MessagetoB&HDiamonds"]: "Message to B&H Diamonds",
    Contactthedealerregarding: "Contact the dealer regarding",
    GemFind: "GemFind",
    CaratWeight: "Carat Weight",
    Measurements: "Measurements",
    Treatment: "Treatment",
    Crown: "Crown",
    Lab: "Lab",
    MatchedPairStock: "Matched Pair Stock",
    Seller: "Seller",
    Stock: "Stock",
    ["Stock#"]: "Stock #",
    ["Off%"]: "Off %",
    Flour: "Flour",
    ["Dep."]: "Dep.",
    Measure: "Measure",
    ["Cert."]: "Cert.",
    Sym: "Sym",
    ["Image/Video"]: "Image/Video",
    Video: "Video",
    Close: "Close",

    Colors: "Colors",
    Blue: "Blue",
    Orange: "Orange",
    Green: "Green",
    Yellow: "Yellow",
    Red: "Red",
    Maroon: "Maroon",
    Permission: "Permission",
    FancyIntensity: "Fancy Intensity",
    ["Overtone1"]: "Overtone 1",
    ["Overtone2"]: "Overtone 2",
    OverallPrice: "Overall Price",
    PriceperCaretRange: "Price per Caret Range",
    EyeClean: "Eye Clean",
    FluorescenceIntensity: "Fluorescence Intensity",
    FluorescenceColor: "Fluorescence Color",
    ["L/WRatioMeasurements"]: "L/W Ratio Measurements",
    LWRatio: "LW Ratio",
    Any: "Any",
    Square: "Square",
    Rectangle: "Rectangle",
    Shade: "Shade",
    CentralInclusionRange: "Central Inclusion Range",
    BlackInclusionRange: "Black Inclusion Range",
    MilkyInclusionRange: "Milky Inclusion Range",
    GirdleRange: "Girdle Range",
    ["CrownAngle%Range"]: "Crown Angle % Range",
    ["PavilionAngle%Range"]: "Pavilion Angle % Range",
    KeytoSymbols: "Key to Symbols",
    CuletRange: "Culet Range",
    CuletConditionRange: "Culet Condition Range",
    ["price(Cost)"]: "price(Cost)",

    Thereare: "There are",
    resultsforyoursearch: "results for your search",
    MaterialType: "Material Type",
    Gender: "Gender",
    MaterialColor: "Material Color",
    Gemstone: "Gemstone",
    ["RetaileStock#"]: "Retaile Stock #",
    Retail: "Retail",
    ["Genderisnotarequiredfieldforvendordata,sobyleavingthisblank,themajorityofproductresultswillbeconsideredLadies."]:
      "Gender is not a required field for vendor data, so by leaving this blank, the majority of product results will be considered Ladies.",
    ["Pleaseselectavendorbeforechoosingacollection."]:
      "Please select a vendor before choosing a collection.",
    ["RetailStock#"]: "Retail Stock #",
    ["$cost"]: "$ cost",
    ["$Retail"]: "$ Retail",

    StoreName: "Store Name",
    RetailerType: "Retailer Type",
    RequestedDate: "Requested Date",
    MyRetailer: "My Retailer",
    JewelryAPI: "Jewelry API",
    AllRetailer: "All Retailer",

    Profile: "Profile",
    Conversation: "Conversation",
    Notes: "Notes",
    Appointments: "Appointments",
    Date: "Date",
    Time: "Time",
    BuyerName: "Buyer Name",
    Location: "Location",

    ActionRequest: "Action Request",

    Activity: "Activity",
    Conversation: "Conversation",
    Customer: "Customer",

    LoadMore: "Load More",

    ViewMyMinedDiamond: "View My Mined Diamond",
    Areyousuretodeletethisdiamond: "Are you sure to delete this diamond",

    ViewMyLabDiamond: "View My Lab Diamond",

    ViewMyColorDiamond: "View My Color Diamond",

    DiamondUploadHistory: "Diamond Upload History",
    DataUpload: "Data Upload",
    ImageUpload: "Image Upload",
    File: "File",
    Records: "Records",

    ["Inventory#"]: "Inventory #",
    ["MatchedPairStock#"]: "Matched Pair Stock #",
    ["Stones(Parcel)"]: "Stones (Parcel)",
    Separable: "Separable",
    FancyCodes: "Fancy Codes",
    FancyNames: "Fancy Names",
    Other: "Other",
    CutGrade: "Cut Grade",
    ["Certificate#"]: "Certificate #",
    CuletCondition: "Culet Condition",
    PavillionAngle: "Pavillion Angle",
    Comments: "Comments",
    StoreLocation: "Store Location",
    EmployeeName: "Employee Name",
    EmployeeID: "Employee ID",
    OutOnMemo: "Out On Memo",
    SeenbyOtherDealers: "Seen by Other Dealers",
    AddVideo: "Add Video",

    UploadYourDataFile: "Upload Your Data File",
    AddIndividual: "Add Individual",
    UploadCerts: "Upload Certs",
    DownloadData: "Download Data",
    ["Pleasekeepyourinventorycurrent.Yourdiamondinventorywillberemovedifnotupdatedatleastevery48hours."]:
      "Please keep your inventory current. Your diamond inventory will be removed if not updated at least every 48 hours.",
    ChooseFile: "Choose File",
    ["Xls/Txt/CsvFormat"]: "Xls/Txt/Csv Format",
    UploadData: "Upload Data",
    ["UploadDiamondImages&Videos"]: "Upload Diamond Images & Videos",
    ["Jpg/PngFormat"]: "Jpg/Png Format",
    ["Compressimagestogether&uploadina.zipformat."]:
      "Compress images together & upload in a .zip format.",
    Downloadourimageguidelinesdocumentation:
      "Download our image guidelines documentation",
    ImportDataFrom3rdPartyProvider: "Import Data From 3rd Party Provider",
    ["Diamonduploadsarelimitedto50,000diamondstoensuresystemspeedandreliability.DiamondFileswithmorethan50,000diamondswillbecutoffsopleasereviewyourdiamondvendorstoendureyouarewithinthediamondlimit."]:
      "Diamond uploads are limited to 50,000 diamonds to ensure system speed and reliability. Diamond Files with more than 50,000 diamonds will be cut off so please review your diamond vendors to endure you are within the diamond limit",
    Addnewdiamondsinbulkormakemassitemupdates:
      "Add new diamonds in bulk or make mass item updates",
    ["Firsttimeimporting?"]: "First time importing?",
    ImportYourContent: "Import Your Content",
    ["Choose&uploadyourdataorconnecttoa3rdparty"]:
      "Choose & upload your data or connect to a 3rd party",
    WaitForConfirmation: "Wait For Confirmation",
    ["Youwillreceiveane-mailconfirmationwhenyourcontenthasbeenuploaded."]:
      "You will receive an e-mail confirmation when your content has been uploaded.",
    ["InventorywithDealerStock#notincludedintheuploadwillbedeleted."]:
      "Inventory with Dealer Stock # not included in the upload will be deleted.",
    ["InventorywithNewDealerStock#willbeaddedandexistingDealerStock#willbeupdated.DealerStock#notincludedwillremainuntouched."]:
      "Inventory with New Dealer Stock # will be added and existing Dealer Stock # will be updated. Dealer Stock # not included will remain untouched.",

    SelectDatafiletobe: "Select Data file to be",
    Mapped: "Mapped",
    UploadFile: "Upload File",
    Resetthedatamap: "Reset the data map",
    ["View/Editthemap"]: "View / Edit the map",
    ManageFTPsettings: "Manage FTP settings",
    GenerateFile: "Generate File",
    GFColumnID: "GF Column ID",
    YourColumn: "Your Column",

    ManageFTPCredential: "Manage FTP Credential",
    FTPURL: "FTP URL",

    RequestAccess: "Request Access",
    YourDiamonds: "Your Diamonds",
    ConnectedDiamonds: "Connected Diamonds",
    TotalDiamonds: "Total Diamonds",
    LabDiamonds: "Lab Diamonds",
    ColorDiamondCount: "Color Diamond Count",
    YourProducts: "Your Products",
    ConnectedProducts: "Connected Products",
    ActiveProducts: "Active Products",
    EDGE: "EDGE",
    ImportType: "Import Type",

    UpdateHistory: "Update History",

    CreateProduct: "Create Product",
    MoreActions: "More Actions",
    Total: "Total",
    Product: "Product",
    SelectAll: "Select All",
    Images: "Images",
    StyleNo: "Style No",
    GFinventoryNo: "GFinventory No",
    ProductName: "Product Name",
    WholesalePrice: "Wholesale Price",
    ["Gemfind#"]: "Gemfind#",
    Feature: "Feature",
    ["AreyousureyouwanttodeletethisProduct(s)?"]:
      "Are you sure you want to delete this Product(s)?",

    AddProduct: "Add Product",
    AddImage: "Add Image",
    OR: "OR",
    LoadImageFromURL: "Load Image From URL",
    LoadImage: "Load Image",
    RetailDescription: "Retail Description",
    ["Style#"]: "Style #",
    Thismustbeauniqueitemnumberidentifyingtheparticularitemorthe:
      "This must be a unique item number identifying the particular item or the",
    parent: "parent",
    ["itemwhichhasmanyvariations-ifthisisastylerootthenyouwillwanttosetthisiemasa"]:
      "item which has many variations - if this is a style root then you will want to set this iem as a",
    configurableproduct: "configurable product",
    ["toconfigurethevariablessuchascaratweight,metaltype,diamondquality,lengthetc."]:
      "to configure the variables such as carat weight, metal type, diamond quality, length etc.",
    ["DealerStock#"]: "Dealer Stock #",
    ["Thisisauniqueidentificationnumberofthisparticularitem."]:
      "This is a unique identification number of this particular item.",
    Period: "Period",
    Visibility: "Visibility",

    Additional: "Additional",
    QtyOnHand: "Qty On Hand",
    ["Askusabouthowwecansetanautomatedconnectiontoyourinventorysystemanddynamicallytracktheavailableofyourvirtualinventory."]:
      "Ask us about how we can set an automated connection to your inventory system and dynamically track the available of your virtual inventory.",
    DisplayOrder: "Display Order",
    ["Thisreferstotheorderinwhichtheitemwillappearwithinitscategory-lowestnumberfirst.Itemswiththesamenumberaresortedbasedonleastexpensivetomostexpensive."]:
      "This refers to the order in which the item will appear within its category - lowest number first. Items with the same number are sorted based on least expensive to most expensive.",
    Setting: "Setting",
    FinishingTechnique: "Finishing Technique",
    ClassType: "Class Type",
    ChainType: "Chain Type",
    BackFinding: "Back Finding",
    AdditionalInformation: "Additional Information",
    AdditionalInformation2: "Additional Information 2",

    Sizing: "Sizing",
    General: "General",
    Weight: "Weight",
    grams: "grams",
    dwt: "dwt",
    Thickness: "Thickness",
    mm: "mm",
    inches: "inches",
    Width: "Width",
    Dimensions: "Dimensions",
    UnitOfMeasure: "Unit Of Measure",
    FingerSize: "Finger Size",
    StockFingerSize: "Stock Finger Size",
    MinFingerSize: "Min Finger Size",
    MaxFingerSize: "Max Finger Size",
    FingerSizeIncrement: "Finger Size Increment",
    PriceType: "Price Type",
    AdditionalPrice: "Additional Price",

    Pricing: "Pricing",
    WholesalePriceMethod: "Wholesale Price Method",
    ["'Manual'meansthatyoumustsetthepricemanually.'Relational'meansthattheGFsystemusesa'RelationalPriceFactor'toadjustthepricebasedoncurrentmetalmarketpricing.'CostBasis'meansthatyousupplythecomponentbreakdownoftheitemandadjustglobalpricefactorstorecalculatepriceeachdaybasedoncurrentmetalmarketconditions.IfthewholesalepricefieldORtheretailpricefieldsareblank,thenthesystemwilldisplay'CallForPrice'"]:
      "'Manual' means that you must set the price manually. 'Relational' means that the GF system uses a 'Relational Price Factor' to adjust the price based on current metal market pricing. 'Cost Basis' means that you supply the component breakdown of the item and adjust global price factors to recalculate price each day based on current metal market conditions.If the wholesale price field OR the retail price fields are blank, then the system will display 'Call For Price'",
    WholesaleBasePrice: "Wholesale Base Price",
    BaseMetalMarket: "Base Metal Market",
    PriceFactor: "Price Factor",
    Calculate: "Calculate",
    RelationalPrice: "Relational Price",
    RelationalMetalMarket: "Relational Metal Market",
    MetalLaborCode: "Metal Labor Code",
    OtherLaborCode: "Other Labor Code",
    MetalFactorCode: "Metal Factor Code",
    GPMCode: "GPM Code",
    RetailPriceMethod: "Retail Price Method",
    MSRP: "MSRP",
    ["Toimprovedataquality,pleaseletusknowhowyouwantthepricingofthisproductmanaged.1-EnforceMSRP.2-Providea'SuggestedRetailPrice'&AllowRetailMarkups.3-AllowRetailMarkups.4-CallForPrice.5-PricingStartingAt."]:
      "To improve data quality, please let us know how you want the pricing of this product managed. 1 - Enforce MSRP. 2 - Provide a 'Suggested Retail Price' & Allow Retail Markups. 3 - Allow Retail Markups. 4 - Call For Price. 5 - Pricing Starting At.",

    Related: "Related",
    MatchingStyles: "Matching Styles",
    ["MatchingSku'sareitemsfromthesameensamblesuchasamatchingringearringsandpendant.EnterDealerIDorStyle#'separatedwithcommas."]:
      "Matching Sku's are items from the same ensamble such as a matching ring earrings and pendant. Enter Dealer ID or Style #' separated with commas.",
    UpSellStyles: "Up Sell Styles",
    ["UpsellSku'sareitemsthatarerelatedbyatslightlyhigherpricepoints."]:
      "Up sell Sku's are items that are related by at slightly higher price points.",
    GroupedProductStyles: "Grouped Product Styles",
    ["Thereisnocontent."]: "There is no content.",

    Media: "Media",
    ChooseVideo: "Choose Video",
    LoadVideoFromURL: "Load Video From URL",
    LoadVideo: "Load Video",
    AddVideoType: "Add Video Type",
    Label: "Label",

    BulletPoint1: "Bullet Point 1",
    BulletPoint2: "Bullet Point 2",
    BulletPoint3: "Bullet Point 3",
    BulletPoint4: "Bullet Point 4",
    BulletPoint5: "Bullet Point 5",
    DiscountA: "Discount A",
    Qty1: "Qty 1",
    Qty2: "Qty 2",
    Qty3: "Qty 3",
    Qty4: "Qty 4",
    Qty5: "Qty 5",
    RetailerBrandName: "Retailer Brand Name",
    SecondaryMetalType: "Secondary Metal Type",
    AmazonProduct: "Amazon Product",

    TotalDiamondWeight: "Total Diamond Weight",
    TotalGemstoneWeight: "Total Gemstone Weight",
    GemstoneType: "Gemstone Type",
    DimensionUnitOfMeasure: "Dimension Unit Of Measure",
    NumberOfGemstones: "Number Of Gemstones",
    GemstoneShape: "Gemstone Shape",
    GemstoneOrigin: "Gemstone Origin",
    GemstoneCaratWeight: "Gemstone Carat Weight",
    GemstoneDimensions: "Gemstone Dimensions",
    GemstoneQuality: "Gemstone Quality",

    Watch: "Watch",
    BandMaterial: "Band Material",
    NumberType: "Number Type",
    BandType: "Band Type",
    CaseMaterial: "Case Material",
    CaseShape: "Case Shape",
    ComesPackagedIn: "Comes Packaged In",
    CrystalType: "Crystal Type",
    DialColor: "Dial Color",
    ManufactureDate: "Manufacture Date",
    DisplayType: "Display Type",
    Certification: "Certification",
    Movement: "Movement",
    Energy: "Energy",

    SaveProduct: "Save Product",
    CancelProduct: "Cancel Product",

    UploadYourImages: "Upload Your Images",
    ["PleasewaitwhilefileisUploading.."]:
      "Please wait while file is Uploading..",
    ["Backgroundsshouldbewhiteortransparent."]:
      "Backgrounds should be white or transparent.",
    ["Imagesshouldbelargerthan500x500pixels."]:
      "Images should be larger than 500 x 500 pixels.",
    "Productsshouldoccupymorethan80%ofcanvas.":
      "Products should occupy more than 80% of canvas.",
    ["Questions?Viewthe"]: "Questions? View the",
    ImageGuidelines: "Image Guidelines",
    HelpDoc: "Help Doc",
    YourFileisInProgress: "Your File is InProgress",
    Pleaseclickheretoseehistory: "Please click here to see history",
    LoadImagesFromMyServer: "Load Images From My Server",
    ["Ifyouwanttoaddjewelryimagesdirectfromserverlike:https://www.gemfind.com/zoomimages/80785.jpgthenmarkthischeckboxaschecked.AlsoverifythatyouhavealsosetcolumnIsImageFromUrlinyourfile.Ifyouhaveset1tothiscolumnthenimagewillloaddirectfromserverandifyouhaveset0thenimagepathwillbetreatedassimple"]:
      "If you want to add jewelry images direct from server like: https://www.gemfind.com/zoomimages/80785.jpg then mark this checkbox as checked. Also verify that you have also set column IsImageFromUrl in your file. If you have set 1 to this column then image will load direct from server and if you have set 0 then imagepath will be treated as simple",
    ["InventorywithDealerStock#notincludedintheuploadwillbedeleted."]:
      "Inventory with Dealer Stock # not included in the upload will be deleted.",
    ["InventorywithNewDealerStock#willbeaddedandexistingDealerStock#willbeupdated.DealerStock#notincludedwillremainuntouched."]:
      "Inventory with New Dealer Stock # will be added and existing Dealer Stock # will be updated. Dealer Stock # not included will remain untouched.",
    JewelryImport: "Jewelry Import",
    ["Addnewjewelryitemsinbulkormakemassitemupdates."]:
      "Add new jewelry items in bulk or make mass item updates",
    MapYourContent: "Map Your Content",
    ["MatchyourcategorieswiththeJewelCloudindustrystandardmakingyourcontentcompatiblewithournetworkandapplications."]:
      "Match your categories with the JewelCloud industry standard making your content compatible with our network and applications.",
    ["Requiredifyourdatafileisnotinourformat."]:
      "Required if your data file is not in our format.",
    DownloadTemplates: "Download Templates",
    RequiredFieldsForm: "Required Fields Form",
    SimpleProductForms: "Simple Product Forms",
    RingBuilderDataForm: "Ring Builder Data Form",
    ConfigurableProductForm: "Configurable Product Form",
    HelpDocs: "Help Docs",
    ImageGuideline: "Image Guideline",
    Progress: "Progress",
    Ok: "Ok",
    Uploaded: "Uploaded",
    Uploadingfile: "Uploading file",

    CreateCollection: "Create Collection",
    AllCollections: "All Collections",
    HouseBrandCollections: "House Brand Collections",
    VendorCollections: "Vendor Collections",
    VendorExtensions: "Vendor Extensions",
    CollectionName: "Collection Name",
    BrandName: "Brand Name",
    Override: "Override",
    Enable: "Enable",
    MarkupOverride: "Markup Override",
    Disable: "Disable",
    Percentage: "Percentage",
    DeleteCollection: "Delete Collection",
    ["Areyousure,youwanttodeletethisCollection."]:
      "Are you sure , you want to delete this Collection.",

    ["contentnotprovided."]: "content not provided.",
    GeneralSettings: "General Settings",
    Currency: "Currency",
    Rounding: "Rounding",
    DefaultMarkup: "Default Markup",
    Markup: "Markup",
    Mrk: "Mrk",
    MarkupByPriceRange: "Markup By Price Range",
    MinPrice: "Min Price",
    MaxPrice: "Max Price",
    MarkUp: "Mark Up",

    Overrides: "Overrides",
    SavePricing: "Save Pricing",

    EditCollection: "Edit Collection",
    CreateACollection: "Create A Collection",
    ["Itemsyouaddforyourstore'spersonaluse.CollectionscanbeaddedtoaJewelCloudVendorasanExtensionoftheirofficialprofileoryourownHouseBrand"]:
      "Items you add for your store's personal use. Collections can be added to a JewelCloud Vendor as an Extension of their official profile or your own House Brand",
    ViewTheTrainingVideo: "ViewThe Training Video",
    CollectionDescription: "Collection Description",
    CollectionDisplayOrder: "Collection Display Order",
    NavigationCategory: "Navigation Category",
    Bridal: "Bridal",
    Fashion: "Fashion",
    Giftware: "Giftware",
    SaveCollection: "Save Collection",

    ImportMapping: "Import Mapping",
    ExportMapping: "Export Mapping",
    AddNewColumn: "Add New Column",
    Attribute: "Attribute",
    ["JewelClouddefinedcolumnname."]: "JewelCloud defined column name.",
    ["Thisdefineswhattypeofdataisallowed.FixedlablesmeansyoumustuseanapprovedJewelCloudvalue.Numericcanonlycontainnumbersanddecimal."]:
      "This defines what type of data is allowed. Fixed lables means you must use an approved JewelCloud value. Numeric can only contain numbers and decimal.",
    Requirement: "Requirement",
    ["Datauploadswillfailifthesecolumnsdonotcontainavalue."]:
      "Data uploads will fail if these columns do not contain a value.",
    YourAttribute: "Your Attribute",
    NonMapped: "Non Mapped",
    AcceptedValues: "Accepted Values",
    Required: "Required",
    Example: "Example",
    FeildType: "Feild Type",
    Configurable: "Configurable",
    Definition: "Definition",
    Remaining: "Remaining",
    ["Drag&DropYourAttributes"]: "Drag & Drop Your Attributes",
    MatchYourattributeswiththejewelcloudindustrystandard:
      "Match Your attributes with the jewel cloud industry standard",
    AttributeValueMap: "Attribute Value Map",
    MatchYourVariablestothejewelCloudIndustryStandard:
      "Match Your Variables to the jewelCloud Industry Standard",
    ["Yoursheetjustneedsyourcolumnheaders."]:
      "Your sheet just needs your column headers.",
    [".xls|.txt|.csv"]: ".xls | .txt | .csv",
    ChooseFormat: "Choose Format",
    Nocontent: "No content",
    Comma: "Comma",
    Tab: "Tab",
    UploadSheet: "Upload Sheet",
    ImportMappingTemplate: "Import Mapping Template",
    EdgeTPW: "Edge TPW",
    Arms: "Arms",
    EdgeEDT: "Edge EDT",
    LoadFile: "Load File",
    AttributeANewMappableColumn: "Attribute A New Mappable Column",
    ["AddASingleNewColumnHeader."]: "Add A Single New Column Header.",
    ColumnID: "Column ID",
    ColumnName: "Column Name",
    ImageUploadSettings: "Image Upload Settings",
    ImportMultiple: "Import Multiple",
    True: "True",
    False: "False",
    SelectDelimiter: "Select Delimiter",
    ["(|(pipesign),(comma))UsethissingleDelimitertoseparateImages."]:
      "( |(pipe sign) ,(comma) ) Use this single Delimiter to separate Images.",
    AddPriceTypeOverride: "Add Price Type Override",
    EnforceMSRP: "Enforce MSRP",
    ["SuggestedRetailPrice&AllowRetailMarkups"]:
      "SuggestedRetail Price & Allow Retail Markups",
    AllowRetailMarkups: "Allow Retail Markups",
    CallForPrice: "CallFor Price",
    PricesStartingat: "Prices Starting at",
    Allowpricetypeoverride: "Allow price type override",
    ResetTheMap: "Reset The Map",
    ["Yikes...GoBack"]: "Yikes...Go Back",
    ["AreyoursureyouwanttoresettheDataMap?"]:
      "Are your sure you want to reset the Data Map?",
    THEREISNOUNDO: "THERE IS NO UNDO",

    DiamondChartReports: "Diamond Chart Reports",
    ExporttoPDF: "Export to PDF",
    ["DiamondClicksforGemFind(720)"]: "Diamond Clicks for GemFind (720)",
    Events: "Events",
    DownloadPDF: "Download PDF",
    IPAddress: "IPAddress",
    LabGrownDiamond: "Lab Grown Diamond",
    Roundtothenearest1: "Round to the nearest 1",

    DiamondCutGradeReport: "Diamond Cut Grade Report",

    LocationActivity: "Location Activity",
    Types: "Types",

    AcceptNewCollection: "Accept New Collection",
    ["Ifselectedyouwillreceivenewcollectionsfromthisvendor,Ifnotselected,youwillonlyrecieveupdatestoexistingcollections."]:
      "If selected you will receive new collections from this vendor, If not selected, you will only recieve updates to existing collections",

    RetailerAffirmation: "Retailer Affirmation",
    CurrentAuthorizedReseller: "Current Authorized Reseller",
    ["BycheckingthisboxandclickingthesubmitbuttonbelowIherebyaffirmthatGemFindhasanopenandactiveaccountwithA&DGemCorp,thatIamanauthorizedresellerofA&DGemCorp,andthatIampermittedtohavetheirvirtualinventoryonmywebsite."]:
      "By checking this box and clicking the submit button below I hereby affirm that GemFind has an open and active account with A & D Gem Corp, that I am an authorized reseller of A & D Gem Corp, and that I am permitted to have their virtual inventory on my website.",
    NewAccountRequest: "New Account Request",
    ["IaminterestedinopeninganewaccountwithA&DGemCorpandbecominganauthorizedreseller.Bycheckingthisboxandclickingthesubmitbuttonbelow,yournameandstoreinformationwillbesenttoA&DGemCorp."]:
      "I am interested in opening a new account with A & D Gem Corp and becoming an authorized reseller. By checking this box and clicking the submit button below, your name and store information will be sent to A & D Gem Corp.",

    InviteYourVendors: "Invite Your Vendors",
    ["E-MailAddress"]: "E-Mail Address",
    SendInvitation: "Send Invitation",
    LastRequested: "Last Requested",

    SKU: "SKU",
    InHouse: "InHouse",
    Measurement: "Measurement",
    Cert: "Cert",
    Mined: "Mined",
    Princess: "Princess",
    Pear: "Pear",
    Marquise: "Marquise",
    Ideal: "Ideal",
    Excellent: "Excellent",
    VeryGood: "Very Good",
    Good: "Good",
    Fair: "Fair",
    DiamondPreview: "Diamond Preview",
    SampleImage: "Sample Image",
    StockNumber: "Stock Number",
    AddToCompare: "Add To Compare",
    SIMILARDIAMONDS: "SIMILAR DIAMONDS",
    Compare: "Compare",
    FancyColored: "Fancy Colored",
    D: "D",
    E: "E",
    F: "F",
    G: "G",
    I: "I",
    J: "J",
    K: "K",
    L: "L",
    M: "M",
    N: "N",
    O: "O",
    P: "P",
    LabGrowm: "Lab Growm",
    NoDiamondsListedInThatRange: "No Diamonds Listed In That Range",
    ["Pleaseenteryourcontactinformation.Oneofourdiamondspecialistswillcontactyouandassistyouwithyourneeds."]:
      "Please enter your contact information. One of our diamond specialists will contact you and assist you with your needs.",
    FirstName: "First Name",
    LastName: "Last Name",
    AddCommentsHere: "Add Comments Here",
    Request: "Request",
    ["Pleaseenterthedetailsofthetypeofdiamondyouarelookingforandyourcontactinformation."]:
      "Please enter the details of the type of diamond you are looking for and your contact information.",
    ["Yourinformationwillbesubmittedtooneofourdiamondspecialiststhatwillcontactyou."]:
      "Your information will be submitted to one of our diamond specialists that will contact you.",
    Advance: "Advance",
    Useyourdiamondcomparisontosaveallthedetailsofthediamondsyouareconsideringandviewthemalltogether:
      "Use your diamond comparison to save all the details of the diamonds you are considering and view them all together",
    ["Thismakesiteasytochoosetherightdiamond."]:
      "This makes it easy to choose the right diamond.",

    MinedDiamonds: "Mined Diamonds",
    LabGrown: "Lab Grown",
    ColorDiamonds: "Color Diamonds",

    ID: "ID",
    Certs: "Certs",
    MinedDiamondsMarkup: "Mined Diamonds Markup",
    Method: "Method",
    CustomMethod: "Custom Method",
    ["Inordertoprotecttheintegrityofthediamondandthejewelryindustry,theminimummarkupontheDiamondlinkshouldbenolessthan7%"]:
      "In order to protect the integrity of the diamond and the jewelry industry, the minimum mark up on the Diamond link should be no less than 7%",
    MarkupByVendor: "Markup By Vendor",
    MarkupByPriceRangeWithCerts: "Markup By PriceRange With Certs",
    CL: "CL",
    UpdateMarkup: "Update Markup",
    GIA: "GIA",
    AGS: "AGS",
    EGL: "EGL",
    IGI: "IGI",

    EnableDiamondswith: "Enable Diamonds with",
    VideoOnly: "Video Only",
    EnableDynamicRange: "Enable Dynamic Range",
    EGLBelgium: "EGL Belgium",
    EGLInternational: "EGL International",
    EGLIsrael: "EGL Israel",
    EGLSouthAfrica: "EGL South Africa",
    EGLUSA: "EGL USA",
    HRD: "HRD",
    GCAL: "GCAL",
    None: "None",

    FNT: "FNT",
    MED: "MED",
    ST: "ST",
    ALL: "ALL",

    ConfigurableAttribute: "Configurable Attribute",
    ShippingCharge: "Shipping Charge",
    UpdateSettings: "Update Settings",

    StandardSearch: "Standard Search",
    AdvancedSearch: "Advanced Search",
    FancyColoredSearch: "Fancy Colored Search",
    LabGrownSearch: "Lab Grown Search",
    RequestaDiamond: "Request a Diamond",
    CompareDiamonds: "Compare Diamonds",
    LeftNavigationforMasterLink: "Left Navigation for MasterLink",
    LooseDiamonds: "Loose Diamonds",
    SameWindow: "Same Window",
    NewWindow: "New Window",
    Inframe: "Inframe",
    OurSignatureDiamonds: "Our Signature Diamonds",
    BestValueDiamonds: "Best Value Diamonds",
    PrivateReserveDiamonds: "Private Reserve Diamonds",
    HeartStarDiamonds: "HeartStar Diamonds",

    ["ChangethisvaluetosettheTITLEatthetopofthesearchresultspage.Leaveblankfordefaultvalue."]:
      "Change this value to set the TITLE at the top of the search results page. Leave blank for default value.",
    ["ChangethisvaluetosettheTITLEatthetopofthediamonddetailspage.Leaveblankfordefaultvalue."]:
      "Change this value to set the TITLE at the top of the diamond details page. Leave blank for default value.",
    ["ChangethisvaluetosettheTEXTofthe#1linkatthebottomofthesearchrestulspage.Leaveblankfordefaultvalue(none)."]:
      "Change this value to set the TEXT of the #1 link at the bottom of the search restuls page. Leave blank for default value (none).",
    ["ChangethisvaluetosettheURLforthe#1linkatthebottomofthesearchrestulspage.Leaveblankfordefaultvalue(none)."]:
      "Change this value to set the URL for the #1 link at the bottom of the search restuls page. Leave blank for default value (none).",
    ["ChangethisvaluetosettheTEXTofthe#2linkatthebottomofthesearchrestulspage.Leaveblankfordefaultvalue(none)."]:
      "Change this value to set the TEXT of the #2 link at the bottom of the search restuls page. Leave blank for default value (none).",
    ["ChangethisvaluetosettheURLforthe#2linkatthebottomofthesearchrestulspage.Leaveblankfordefaultvalue(none)."]:
      "Change this value to set the URL for the #2 link at the bottom of the search restuls page. Leave blank for default value (none).",
    ["ChangethisvaluetosettheTEXTofthe#3linkatthebottomofthesearchrestulspage.Leaveblankfordefaultvalue(none)."]:
      "Change this value to set the TEXT of the #3 link at the bottom of the search restuls page. Leave blank for default value (none).",
    ["ChangethisvaluetosettheURLforthe#3linkatthebottomofthesearchrestulspage.Leaveblankfordefaultvalue(none)."]:
      "Change this value to set the URL for the #3 link at the bottom of the search restuls page. Leave blank for default value (none).",
    ["ChangethisvaluetoallowyourGFLinkuserstosearchformatchedpairsofstonesinadditiontosinglestones(alsoALLstones).Leaveblankfordefaultvalue(singlestonesonly)."]:
      "Change this value to allow your GFLink users to search for matched pairs of stones in addition to single stones (also ALL stones). Leave blank for default value (single stones only).",
    ["UsethisoptiontoEnable(Yes)orDisable(No)displayofLogo(i.e.RESULTSPAGEIMAGEURL)onthetopofSearchResultPage."]:
      "Use this option to Enable (Yes) or Disable (No) display of Logo (i.e. RESULTS PAGE IMAGE URL) on the top of Search Result Page.",
    ["ChangethisvaluetoaltertheURLassociatedwiththeimagedisplayedatthetopofthesearchresultspage.Leaveblankfordefaultvalue(none).IfyousendyourimagetoGemFindtoplaceonaGemFindserver,youcanputinthefilenameonly."]:
      "Change this value to alter the URL associated with the image displayed at the top of the search results page. Leave blank for default value (none). If you send your image to GemFind to place on a GemFind server, you can put in the filename only.",
    ["Changethisvaluetoaltertheactualhyperlinkassociatedwiththeimagedisplayedatthetopofthesearchresultspage.Leaveblankfordefaultvalue(none)."]:
      "Change this value to alter the actual hyperlink associated with the image displayed at the top of the search results page. Leave blank for default value (none).",
    ["ChangethisvaluetoaltertheURLassociatedwiththeimagedisplayedinthemiddleofthedefaultdiamonddetailspage.Leaveblankfordefaultvalue(none).IfyousendyourimagetoGemFindtoplaceonaGemFindserver,youcanputinthefilenameonly."]:
      "Change this value to alter the URL associated with the image displayed in the middle of the default diamond details page. Leave blank for default value (none). If you send your image to GemFind to place on a GemFind server, you can put in the filename only.",
    ["Changethisvaluetoalterthevisibletextoftheactualhyperlinktothediamonddetails.Leaveblankfordefaultvalue."]:
      "Change this value to alter the visible text of the actual hyperlink to the diamond details. Leave blank for default value.",
    ["Bydefault,yoursearchwilldisplaybasicdiamonddetails(Cut,Color,Clarity,Size,Price&Certificate)onthesearchresultspage.Youmaychoosetodisplaymoredetailedinformation(Table,Depth,Measurements)aswellasthebasicinformationonthesearchresultspage.Nomatterwhichmethodyouselect,eachdiamond'scompletedetailsarealwaysavailablebyclicking'MoreInfo'onthesearchresultspage."]:
      "By default, your search will display basic diamond details (Cut, Color, Clarity, Size, Price & Certificate) on the search results page. You may choose to display more detailed information (Table, Depth, Measurements) as well as the basic information on the search results page. No matter which method you select, each diamond's complete details are always available by clicking 'More Info' on the search results page.",
    ["YoumaychoosetosearchbyCutGradeifdesired.Table&DepthsearchingisenabledaswellastheyaredirectlyrelatedtoCutGrade."]:
      "You may choose to search by Cut Grade if desired. Table & Depth searching is enabled as well as they are directly related to Cut Grade.",
    ["Choosethenumberofdiamondsdisplayedoneachpageofthesearchresults.Forvaluesotherthanshown,youmustcontactGemFinddirectly."]:
      "Choose the number of diamonds displayed on each page of the search results. For values other than shown, you must contact GemFind directly.",
    ["Choosewhethertoshowmoreobscurediamondcuts(asscher,oldminer)onthesearchform."]:
      "Choose whether to show more obscure diamond cuts (asscher, old miner) on the search form.",
    ["ChoosewhethertousetheinteractiveMacromediaFlashsearchforminsteadoftheregularsearchform."]:
      "Choose whether to use the interactive Macromedia Flash search form instead of the regular search form.",
    ["Choosewhethertouseshowpopupofdiamonddetailsonthediamondinveotrypage."]:
      "Choose whether to use show popup of diamond details on the diamond inveotry page.",
    ["Choosewhethertodisplaysharebutton(FaceBook,Twitter,Diggetc.)ondiamonddetailspage."]:
      "Choose whether to display share button (Face Book, Twitter, Digg etc.) on diamond details page.",
    ["Choosewhethertolinkdiamondsearchwithnewringbuilder."]:
      "Choose whether to link diamond search with new ring builder.",
    ["ChoosewhethertodisplayRingBuilderimageattherightcolumnofnewdiamondlink."]:
      "Choose whether to display Ring Builder image at the right column of new diamond link.",
    SearchPageTitle: "Search Page Title",
    DiamondDetailpageTitle: "Diamond Detail page Title",
    ["Hyperlink#1TEXTatbottomofsearchresultspage"]:
      "Hyperlink #1 TEXT at bottom of search results page",
    ["Hyperlink#1URLatbottom"]: "Hyperlink #1 URL at bottom",
    ["Hyperlink#2TEXTatbottom"]: "Hyperlink #2 TEXT at bottom",
    ["Hyperlink#2URLatbottomofsearchresultspage"]:
      "Hyperlink #2 URL at bottomof search results page",
    ["Hyperlink#3TEXTatbottomofsearchresultspage"]:
      "Hyperlink #3 TEXT at bottom of search results page",
    ["Hyperlink#3URLatbottomofsearchresultspage"]:
      "Hyperlink #3 URL at bottom of search results page",
    LetuserssearchforMATCHEDPAIRS: "Let users search for MATCHED PAIRS",
    ShowLogoonResultsPage: "Show Logo on Results Page",
    ShowDiamondEducationonResultsPage: "Show Diamond Education on Results Page",
    ResultspageimageURL: "Results page image URL",
    ResultspageimagehyperlinkURL: "Results page image hyperlink URL",
    DiamonddetailspageimageURL: "Diamond details page image URL",
    Alternatediamonddetailslinktext: "Alternate diamond details link text",
    Enhanceddetailsinsearchresults: "Enhanced details in search results",
    SearchByCutGrade: "Search By Cut Grade",
    Numberofdiamondsdisplayedperpage: "Number of diamonds displayed per page",
    Showodddiamondcutsonsearchform: "Show odd diamond cuts on search form",
    DynamicFlashsearchform: "Dynamic Flash search form",
    ShowpopuponDiamondinventorydisplaypage:
      "Show popup on Diamond inventory display page",
    ["ShowShareButtononDetailsPage(forNewGFLinkonly"]:
      "Show Share Button on Details Page (for New GF Link only)",
    UseNewRingbuilder: "Use New Ringbuilder",
    ["ShowRingBuilderImage(forNewGFLinkonly)"]:
      "Show Ring Builder Image (for New GF Link only)",

    GenerateAPIKeytogetdata: "Generate API Key to get data",
    Generate: "Generate",
    APIDocumentation: "API Documentation",

    ["Markup="]: "Markup=",
    Markups: "Markups",

    ["Setting#"]: "Setting #",
    ["Vendor#"]: "Vendor #",
    Metal: "Metal",
    $Price: "$ Price",
    $Markup: "$ Markup",

    Setup: "Setup",
    RingBuilderURL: "Ring Builder URL",
    ["EntertheURLofthistoolonyoursitetoenablesocialsharinganddynamicsizing."]:
      "Enter the URL of this tool on your site to enable social sharing and dynamic sizing.",
    InternalUseLink: "Internal Use Link",
    ["ChooseWeathertodisplaythe“ForInternalUseOnly”linkatthebottomoftheSettingDetailspage,whichwillallowyoutolookupthevendor’sstock#andcontactinfofortheviewedsetting."]:
      "Choose Weather to display the “For Internal Use Only” link at the bottom of the Setting Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed setting.",
    Display: "Display",
    RingSizeType: "RingSize Type",
    ["ChoosewhichRingSizeformatyouwouldliketodisplay.(American-1,1.5,2,etc.orBritish-A,A.5,B,B.5etc.)."]:
      "Choose which Ring Size format you would like to display. (American - 1, 1.5, 2, etc. or British - A, A.5, B, B.5 etc.).",
    American: "American",
    British: "British",
    Roman: "Roman",
    Europe: "Europe",
    DiamondInitiallySortResultOn: "Diamond Initially Sort Result On",
    ["Selectadiamondattributethatyouwouldliketoinitiallysortthediamondsby."]:
      "Select a diamond attribute that you would like to initially sort the diamonds by.",
    Ascending: "Ascending",
    Descending: "Descending",
    DefaultMetalTypeonSettingpage: "Default Metal Type on Setting page",
    ["14KWhiteGold"]: "14K White Gold",
    ["14KYellowGold"]: "14K Yellow Gold",
    ShowAdvancedSearchOptionsByDefault:
      "Show Advanced Search Options By Default",
    ["ChooseWhethertodisplaytheAdvancedoptionalfilteringbydefault(Polish,Symmetry,Flourescence,Certs…etc."]:
      "Choose Whether to display the Advanced optional filtering by default (Polish, Symmetry, Flourescence, Certs… etc.",
    ["Rings-ShowScheduleAViewing"]: "Rings - Show Schedule A Viewing",
    ["ChooseWhethertodisplaythelinkwhichallowsthecustomertheabilitytoscheduleanappointment."]:
      "Choose Whether to display the link which allows the customer the ability to schedule an appointment.",
    ShowAddresses: "Show Addresses",
    ["ChooseWhethertodisplayyourcompanyaddressandcontactinformationwithintheRingBuilder."]:
      "Choose Whether to display your company address and contact information within the RingBuilder.",
    ShowCenterStoneSize: "Show Center Stone Size",
    ["Warning:DisablingthisoptionwillremovetheCaratRangerestrictionforRingSettings,andmaycauseanincreaseincostpricesfromwhatisstatedfortheselectedRingSetting.FormoreinformationfollowupwithOvernightMountings."]:
      "Warning: Disabling this option will remove the Carat Range restriction for Ring Settings, and may cause an increase in cost prices from what is stated for the selected Ring Setting. For more information follow up with Overnight Mountings.",
    ShowPrice: "Show Price",
    ["ChooseWhethertoshowpricesforringsettingsornot.IfyouchooseNO,thenitwillinsteaddisplay“CallforPrice”."]:
      "Choose Whether to show prices for ring settings or not. If you choose NO, then it will instead display “Call for Price”.",
    ApplyGSTTax: "Apply GST Tax",
    ["Toggletheabilitytoapplyinternationaltax."]:
      "Toggle the ability to apply international tax.",
    GSTTaxValue: "GST Tax Value",
    ["SetthevaluewhenapplyingtheGSTTaxoption."]:
      "Set the value when applying the GST Tax option.",
    Social: "Social",
    ShowPinterestShare: "Show Pinterest Share",
    ["ChoosewhethertodisplaythePinterest“Pinit”buttonornot."]:
      "Choose whether to display the Pinterest “Pin it” button or not.",
    ShowTwitterShare: "Show Twitter Share",
    ["ChoosewhethertodisplaytheTwitter“Tweet”buttonornot."]:
      "Choose whether to display the Twitter “Tweet” button or not.",
    ShowFacebookShare: "Show Facebook Share",
    ["ChoosewhethertodisplaytheFacebook“Share”buttonornot."]:
      "Choose whether to display the Facebook “Share” button or not.",
    ShowFacebookLike: "Show Facebook Like",
    ["ChoosewhethertodisplaytheFacebook“Like”buttonornot"]:
      "Choose whether to display the Facebook “Like” button or not",
    ShowGooglePlus: "Show Google Plus",
    ["ChoosewhethertodisplaytheGoogle“G+1”buttonornot."]:
      "Choose whether to display the Google “G+1” button or not.",
    ["E-Commerce"]: "E-Commerce",
    ShowAddToCartButton: "Show Add To Cart Button",
    AlternateShoppingCart: "Alternate Shopping Cart",
    ["Changethisvaluetoaltertheactualhyperlinktotheshoppingcart.Thiscouldbechangedtolinktoyourownshoppingcartpage.AdditionaldiamondandsettingdetailparametersarepassedintheURLwhenthisvalueispresent.Leaveblankfordefaultvalue."]:
      "Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page. Additional diamond and setting detail parameters are passed in the URL when this value is present. Leave blank for default value.",
    ShowBuySettingOnly: "Show Buy Setting Only",
    ["ChooseWhethertodisplaythe“BuySettingOnly”buttonornot.ThisallowsthecustomertousetheRingBuildertoonlypurchasetheringsettingasopposedtothecompletedringwithdiamond."]:
      "Choose Whether to display the “Buy Setting Only” button or not. This allows the customer to use the RingBuilder to only purchase the ring setting as opposed to the completed ring with diamond.",
    ShowBuyDiamondOnly: "Show Buy Diamond Only",
    ["ChooseWhethertodisplaythe“BuyDiamondOnly”buttonornot.ThisallowsthecustomertousetheRingBuildertoonlypurchasethediamondasopposedtothecompletedring."]:
      "Choose Whether to display the “Buy Diamond Only” button or not. This allows the customer to use the RingBuilder to only purchase the diamond as opposed to the completed ring.",
    ViewCart: "View Cart",
    Button: "Button",
    ["ChooseWhethertodisplaythePayPalshoppingcart'sviewbuttonorNot"]:
      "Choose Whether to display the PayPal shopping cart's view button or Not",

    ["Nocontent."]: "No content.",
    PageAlignment: "Page Alignment",
    Center: "Center",
    Left: "Left",
    Right: "Right",
    ShowSocialSharingButtons: "Show Social Sharing Buttons",
    ShowViewInStore: "Show View In Store",
    ShowPopUpOnDiamondSearch: "Show Pop Up On Diamond Search",
    DiamondSearchResultPageSize: "Diamond Search Result Page Size",
    ["Rings-SearchResultPageSize"]: "Rings - Search Result Page Size",
    ["Rings-InitiallySortResultOn"]: "Rings - Initially Sort Result On",
    SettingNumber: "Setting Number",

    SendEmailToFriend: "Send Email To Friend",
    SendEmailToRetailer: "Send Email To Retailer",
    SendEmailToSender: "Send Email To Sender",
    RequestADiamondEmail: "Request A Diamond Email",
    RequestADiamond: "Request A Diamond",
    SendMailtoRequestADiamond: "Send Mail to Request A Diamond",

    Wishlist: "Wishlist",
    BridalRegistry: "Bridal Registry",
    ShoppingCart: "Shopping Cart",
    ShowStoreHours: "Show Store Hours",
    WebsiteURL: "Website URL",
    GoogleAnalyticID: "Google Analytic ID",
    ShowOnlyMSRPPrices: "Show Only MSRP Prices",
    MasterlinkTheme: "Masterlink Theme",
    ShowView: "Show View",
    ["NewMasterLinkUseInIframe?"]: "New MasterLink Use In Iframe?",
    LoginURL: "Login URL",
    ["ShowWholeSalePriceInternalUseLink?"]:
      "Show WholeSale Price Internal Use Link?",
    ["ShowAddress/PhoneDetails?"]: "Show Address/Phone Details?",
    ["DiamondSearch/RingbuilderloadfromMasterlink?"]:
      "Diamond Search / Ringbuilder load from Masterlink?",
    DiamondImageURL: "Diamond Image URL",
    VendorDetailsopenorclosedbydefaultinnewmasterlink:
      "Vendor Details open or closed by default in new masterlink",
    ShowAdditionalPopUpinnewmasterlink:
      "Show Additional PopUp in new masterlink",
    ShoppingCartpagemessage: "Shopping Cart page message",
    HeartsOnFireLink: "Hearts On Fire Link",
    ShowSettingNameatSettingGrid: "Show Setting Name at Setting Grid",
    ShowRingBuilderwithOneStep: "Show Ring Builder with One Step",
    ShowDefaultRingSize: "Show Default Ring Size",
    ShopDiamonds: "Shop Diamonds",
    ["ShopBridal&Engagement"]: "Shop Bridal & Engagement",
    ShopDesigners: "Shop Designers",
    ShopJewelry: "Shop Jewelry",
    ShopWatches: "Shop Watches",
  },
};
