import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Table,
  Modal,
  DatePicker,
  Switch,
  Space,
  Spin,
} from "antd";
import {
  EditOutlined,
  CalendarOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import traslate from "../../../i18n/translate";

const { Option } = Select;

export const MagentoMapping = (props) => {
  const [getMagentoData, setGetMagentoData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [isModalVisible3, setIsModalVisible3] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(false);
  const [dealerList, setdealerList] = useState([]);
  const [magentoData, setMagentoData] = useState({
    dealer: "",
    apiUrl: "",
    portNo: "",
    userName: "",
    apiKey: "",
    isActive: true,
  });
  const [validations, setValidations] = useState({
    dealerVal: "",
    apiUrlVal: "",
    userNameVal: "",
    apiKeyVal: "",
  });
  const [editmagentoData, setEditMagentoData] = useState({
    id: "",
    dealerID: "",
    dealerCompany: "",
    apiurl: "",
    portNo: "",
    userName: "",
    apiKey: "",
    isActive: true,
  });
  const [delMagentoData, setDelMagentoData] = useState({
    id: "",
    dealerID: "",
  });

  const [editvalidations, setEditValidations] = useState({
    editapiurlVal: "",
    edituserNameVal: "",
    editapiKey: "",
  });

  const handleEditValidation = () => {
    const { apiurl, userName, apiKey } = editmagentoData;
    const editvalidations = {
      editapiurlVal: "",
      edituserNameVal: "",
      editapiKey: "",
    };
    let isValid = true;
    if (!apiurl.trim()) {
      isValid = false;
      editvalidations.editapiurlVal = "API Url is compulsory";
    }
    if (!userName.trim()) {
      isValid = false;
      editvalidations.editfTPPasswordVal = "Username is compulsory";
    }
    if (!apiKey) {
      isValid = false;
      editvalidations.editapiKey = "API Key is compulsory";
    }

    if (!isValid) {
      setEditValidations(editvalidations);
    }

    return isValid;
  };
  const searchInput = useRef(null);

  useEffect(() => {
    handleGetOpenSourceMappingList();
  }, []);

  const handleGetOpenSourceMappingList = () => {
    try {
      setLoading(true);
      AdminToolsDataService.GetOpenSourceMappingList()
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData[1];
          var dealerData = response.data.responseData[0];
          if (message == "Success") {
            setGetMagentoData(responseData);
            setdealerList(dealerData);
          } else {
            setGetMagentoData([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const editShowModal = (row) => {
    debugger;
    setIsModalVisible(true);
    setEditMagentoData({
      id: row.id,
      dealerID: row.dealerID,
      dealerCompany: row.dealerCompany,
      apiurl: row.apiurl,
      portNo: row.portNo,
      userName: row.userName,
      apiKey: row.apiKey,
      isActive: row.isActive,
    });
    console.log(row);
  };

  const handleEditSubmit = () => {
    debugger;
    const {
      id,
      dealerID,
      dealerCompany,
      apiurl,
      portNo,
      apiKey,
      isActive,
      userName,
    } = editmagentoData;
    const isValid = handleEditValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        id: id,
        dealerID: dealerID,
        dealerCompany: dealerCompany,
        apiURL: apiurl,
        userName: userName,
        apiKey: apiKey,
        isActive: isActive,
        portNo: portNo,
        loginUserId: "",
        loginUserName: "",
        ipAddress: "",
      };
      setLoading(true);
      AdminToolsDataService.UpdateOpenSourceMapping(inputData)
        .then((response) => {
          var message = response.data.message;
          var ShowMessage = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            NotificationManager.success(ShowMessage);
            handleGetOpenSourceMappingList();
          } else {
            NotificationManager.error(ShowMessage);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleCancel();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditMagentoData({});
    setEditValidations({});
  };

  const delModal = (row) => {
    setIsModalVisible2(true);
    setDelMagentoData({
      id: row.id,
      dealerID: row.dealerID,
    });
  };

  const handlemagentoData = () => {
    setIsModalVisible2(true);
    try {
      let inputData = {
        id: parseInt(delMagentoData.id),
        dealerID: parseInt(delMagentoData.dealerID),
      };
      setLoading(true);
      AdminToolsDataService.DeleteOpenSourceMapping(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(true);
          if (message == "Success") {
            NotificationManager.success("Record Deleted Succssfully.");
            handleGetOpenSourceMappingList();
            setLoading(false);
          } else {
            NotificationManager.error("Record Not Deleted.");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleCancel2();
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
    setDelMagentoData({});
  };

  const showModal3 = () => {
    setIsModalVisible3(true);
  };

  const handleSubmit = (e) => {
    debugger;
    e.preventDefault();
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        id: 0,
        dealerID: parseInt(magentoData.dealer),
        apiURL: magentoData.apiUrl,
        userName: magentoData.userName,
        apiKey: magentoData.apiKey,
        isActive: magentoData.isActive,
        portNo: magentoData.portNo,
      };
      setLoading(true);
      AdminToolsDataService.SaveOpenSourceMapping(inputData)
        .then((response) => {
          var message = response.data.message;
          var ShowMessage = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            NotificationManager.success(ShowMessage);
            handleGetOpenSourceMappingList();
          } else {
            NotificationManager.error(ShowMessage);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    console.log(magentoData);
    handleCancel3();
  };

  const handleCancel3 = () => {
    setIsModalVisible3(false);
    setMagentoData({
      dealer: "",
      apiUrl: "",
      portNo: "",
      userName: "",
      apiKey: "",
      isActive: true,
    });
    setValidations({});
  };

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {traslate("Search")}
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              {traslate("Reset")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          // setTimeout(() => this.searchInput.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    };
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters, confirm) {
    clearFilters();
    setSearchText("");
    confirm();
  }

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setMagentoData({
        ...magentoData,
        [name]: e,
      });
    } else {
      setMagentoData({
        ...magentoData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleValidation = () => {
    const { dealer, apiUrl, userName, apiKey } = magentoData;
    const validations = {
      dealerVal: "",
      apiUrlVal: "",
      userNameVal: "",
      apiKeyVal: "",
    };
    let isValid = true;

    if (!dealer) {
      isValid = false;
      validations.dealerVal = "Dealer ID is compulsory";
    }
    if (!apiUrl.trim()) {
      isValid = false;
      validations.apiUrlVal = "Api Url is compulsory";
    }
    if (!userName.trim()) {
      isValid = false;
      validations.userNameVal = "Username is compulsory";
    }
    if (!apiKey.trim()) {
      isValid = false;
      validations.apiKeyVal = "Api Key is compulsory";
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const edithandleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setEditMagentoData({
        ...editmagentoData,
        [name]: e,
      });
    } else {
      setEditMagentoData({
        ...editmagentoData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const { dealer, apiUrl, portNo, userName, apiKey, isActive } = magentoData;
  const { dealerVal, apiUrlVal, userNameVal, apiKeyVal } = validations;

  console.log(getMagentoData);
  return (
    <div className="form__fields">
      <div className="row magento__mapping">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0 mb-0">{traslate("MagentoMapping")}</h4>
            <button className="primary-btn" onClick={showModal3}>
              {" "}
              <img src={Create_icon} alt="" /> {traslate("MagentoMapping")}
            </button>
          </div>
        </div>
        <div className="divForm">
          <Spin spinning={loading} size="large">
            <Table
              const
              columns={[
                {
                  title: traslate("Dealer") ,
                  ...getColumnSearchProps("dealerCompany"),
                  dataIndex: "dealerCompany",
                  key: "dealerCompany",
                  render: (text, record) => {
                    return (
                      <span>
                        {"( " + record.dealerID + " ) " + record.dealerCompany}
                      </span>
                    );
                  },
                },
                {
                  title: traslate("APIURL") ,
                  dataIndex: "apiurl",
                  key: "apiurl",
                },
                {
                  title: traslate("UserName") ,
                  dataIndex: "userName",
                  key: "userName",
                },
                {
                  title: traslate("APIKey") ,
                  dataIndex: "apiKey",
                  key: "apiKey",
                },
                {
                  title: traslate("IsActive") ,
                  dataIndex: "isActive",
                  key: "isActive",
                  render: (text) => String(text),
                  width: 100
                },
                {
                  title: traslate("Action") ,
                  key: "7",
                  fixed: "right",
                  width: 100,
                  render: (item, row) => (
                    // <a>
                    //   <EditOutlined onClick={showModal} />
                    //   <span className="mleft mx-2">
                    //     <CalendarOutlined onClick={showModal2} />
                    //   </span>
                    // </a>
                    <div className="action__btns">
                      <div className="image__block">
                        <img
                          src={Edit_icon}
                          onClick={() => editShowModal(row)}
                          alt=""
                        />
                      </div>
                      <div className="image__block">
                        <img
                          src={Delete_iconbl}
                          onClick={() => delModal(row)}
                          alt=""
                        />
                      </div>
                    </div>
                  ),
                },
              ]}
              dataSource={getMagentoData}
              scroll={{ x: 600 }}
            />
          </Spin>
        </div>

        <Modal
          title={traslate("EditMegentoMappingInfo")}
          centered
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              {traslate("Cancel")}
            </Button>,
            <Button key="submit" type="primary" onClick={handleEditSubmit}>
              {traslate("Save")}
            </Button>,
          ]}
        >
          <div className="form__fields mt-3 p-0 border-0">
            <div className="row">
              <div className="col-md-12">
                <div className="input__block">
                  <label>
                    {editmagentoData.dealerCompany}-{editmagentoData.dealerID}{" "}
                  </label>
                </div>
              </div>

              <div className="col-md-12">
                <div className="input__block">
                  <label>
                    {" "}
                    {traslate("APIURL")}<span>*</span>{" "}
                  </label>
                  <input
                    type="text"
                    onChange={edithandleOnChange}
                    name="apiurl"
                    value={editmagentoData.apiurl}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="input__block">
                  <label> {traslate("PortNo")}  </label>
                  <input
                    type="number"
                    onChange={edithandleOnChange}
                    name="portNo"
                    value={editmagentoData.portNo}
                    onKeyPress={onKeyPressEvent}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="input__block">
                  <label>
                    {" "}
                    {traslate("APIKey")}<span>*</span>{" "}
                  </label>
                  <input
                    type="text"
                    onChange={edithandleOnChange}
                    name="apiKey"
                    value={editmagentoData.apiKey}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="input__block">
                  <label>
                    {" "}
                    {traslate("Username")}<span>*</span>{" "}
                  </label>
                  <input
                    type="text"
                    onChange={edithandleOnChange}
                    name="userName"
                    value={editmagentoData.userName}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="input__block">
                  <label> {traslate("IsActive")}  </label>
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={editmagentoData.isActive}
                    onChange={(e) =>
                      edithandleOnChange(e, "select", "isActive")
                    }
                    name="isActive"
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          title= {traslate("DeleteDealerMapping")}
          centered
          visible={isModalVisible2}
          onCancel={handleCancel2}
          footer={[
            <Button key="back" onClick={handleCancel2}>
              {traslate("Cancel")}
            </Button>,
            <Button key="submit" type="primary" onClick={handlemagentoData}>
              {traslate("Delete")}
            </Button>,
          ]}
        >
          <h4 className="subheading">
          {traslate("Areyousureyouwanttodelete")} "{delMagentoData.dealerID}" {traslate("DealerData")} 
          </h4>
        </Modal>

        <Modal
          title= {traslate("MegentoMappingInfo")}
          centered
          visible={isModalVisible3}
          onCancel={handleCancel3}
          footer={[
            <Button key="back" onClick={handleCancel3}>
              {traslate("Cancel")}
            </Button>,
            <Button key="submit" type="primary" onClick={handleSubmit}>
              {traslate("Save")}
            </Button>,
          ]}
        >
          <div className="form__fields mt-3 p-0 border-0">
            <div className="row">
              <div className="col-md-6">
                <div className="input__block">
                  <label>
                  {traslate("DealerID")} <span>*</span>{" "}
                  </label>
                  <Select
                    showSearch
                    placeholder="Select Dealer ID"
                    optionFilterProp="children"
                    value={dealer ? dealer : null}
                    name="dealer"
                    onChange={(e) => handleOnChange(e, "select", "dealer")}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    className={dealerVal ? "border__red" : "border__grey"}
                  >
                    <Option value="">Select Dealer ID</Option>
                    {dealerList.map((item) => {
                      return (
                        <Option value={item.dealerID}>
                          {item.dealerCompany + " (" + item.dealerID + " )"}
                        </Option>
                      );
                    })}
                  </Select>
                  <div>
                    {dealerVal && (
                      <p className="error-color-red">{dealerVal}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="input__block">
                  <label>
                    {" "}
                    {traslate("APIURL")}<span>*</span>{" "}
                  </label>
                  <input
                    type="text"
                    placeholder="Enter API URL"
                    name="apiUrl"
                    value={apiUrl}
                    onChange={handleOnChange}
                    className={apiUrlVal && "border__red"}
                  />
                  <div>
                    {apiUrlVal && (
                      <p className="error-color-red">{apiUrlVal}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="input__block">
                  <label> {traslate("PortNo")} </label>
                  <input
                    type="number"
                    placeholder="Enter Port No"
                    name="portNo"
                    value={portNo}
                    onChange={handleOnChange}
                    onKeyPress={onKeyPressEvent}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="input__block">
                  <label>
                    {" "}
                    {traslate("APIKey")} <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter API Key"
                    name="apiKey"
                    value={apiKey}
                    onChange={handleOnChange}
                    className={apiKeyVal && "border__red"}
                  />
                  <div>
                    {apiKeyVal && (
                      <p className="error-color-red">{apiKeyVal}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="input__block">
                  <label>
                    {" "}
                    {traslate("Username")} <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Username"
                    name="userName"
                    value={userName}
                    onChange={handleOnChange}
                    className={userNameVal && "border__red"}
                  />
                  <div>
                    {userNameVal && (
                      <p className="error-color-red">{userNameVal}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="input__block">
                  <label> {traslate("IsActive")} </label>
                  <Switch
                    name="isActive"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={isActive}
                    onChange={(e) => handleOnChange(e, "select", "isActive")}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MagentoMapping);
