import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Table,
  Modal,
  DatePicker,
  Switch,
  Space,
} from "antd";
import {
  EditOutlined,
  CalendarOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";

import userIcon from "../../../assets/images/daimond-bg.png";
import traslate from "../../../i18n/translate";

const { Option } = Select;

export const LabGrowMarkup = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [LabGrowData, setLabGrowData] = useState({
    Method:null,
    Currency:null,
    CustomMethod:null,
    DefaultMarkup:"",
    SelectManufacturer:null,
    MarkupPercent:"",
    CertCl:"",
    CertClValue:"",
    CertClPercent:"",
    Radio:"",
    
    
    });

  const columns = [
    {
      title: traslate("ID"),
      dataIndex: "vendorName",
    },
    {
      title: traslate("Dealer"),
      dataIndex: "minedDiamonds",
    },
    {
      title: traslate("Markup"),
      dataIndex: "labGrown",
    },
  ];

  const data = [];

  const columns2 = [
    {
      title: traslate("MinPrice"),
      dataIndex: "minPrice",
    },
    {
      title: traslate("MaxPrice"),
      dataIndex: "maxPrice",
    },
    {
      title: traslate("Certs"),
      dataIndex: "certs",
    },
    {
      title: traslate("Markup"),
      dataIndex: "markup",
    },
    {
      title: "",
      fixed: "right",
      width: 100,
      render: () => (
        <div className="action__btns">
          <div className="image__block">
            <img src={Delete_iconbl} alt="" />
          </div>
        </div>
      ),
    },
  ];

  const data2 = [
    {
      key: "1",
      minPrice: "1.00",
      maxPrice: "1000.00",
      certs: "",
      markup: "70%",
    },
  ];

  function onSearch(val) {
    console.log("search:", val);
  }

  const handleOnChange = (e, isSelect, name) => {
    
    if (isSelect === "select") {
      setLabGrowData({
        ...LabGrowData,
        [name]: e,
      });
    } else {
      setLabGrowData({
        ...LabGrowData,
        [e.target.name]: e.target.value,
      });
    }
    // console.log(e, isSelect, name);
  };

  const handleOnChangeRadio = (event) => {
    setLabGrowData({
      ...LabGrowData,
      Radio: event.target.name,
    });
    console.log(event.target.name);
  };

  return (
    <div className="form__fields markup__section">
      <div className="row ">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0">
              {" "}
              {traslate("LabGrowMarkup")}{" "}
            </h4>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="input__block">
            <label> {traslate("Method")} </label>
            <Select
              className="border__grey"
              showSearch
              placeholder="Any"
              name="Method"
              value={LabGrowData.Method ? LabGrowData.Method : []}
              optionFilterProp="children"
              // onChange={onChange}
              onChange={(e) =>
                handleOnChange(e, "select", "Method")
              }
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="Custom">Custom</Option>
              <Option value="Price">Price</Option>
              <Option value="size">size</Option>
            </Select>
          </div>
        </div>

        <div className="col-md-4">
          <div className="input__block">
            <label> {traslate("Currency")} </label>
            <Select
              className="border__grey"
              showSearch
              placeholder="Any"
              name="Currency"
              value={LabGrowData.Currency ? LabGrowData.Currency : []}
              optionFilterProp="children"
              // onChange={onChange}
              onChange={(e) =>
                handleOnChange(e, "select", "Currency")
              }
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="United States - Dollar - $">United States - Dollar - $</Option>
              <Option value="Great Britan - Pound - £">Great Britan - Pound - £</Option>
              <Option value="Australia - Dollar - $">Australia - Dollar - $</Option>
            </Select>
          </div>
        </div>

        <div className="col-md-4">
          <div className="input__block">
            <label> {traslate("CustomMethod")} </label>
            <Select
              className="border__grey"
              showSearch
              placeholder="Any"
              name="CustomMethod"
              value={LabGrowData.CustomMethod ? LabGrowData.CustomMethod : []}
              optionFilterProp="children"
              // onChange={onChange}
              onChange={(e) =>
                handleOnChange(e, "select", "CustomMethod")
              }
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="ByPrice">By Price</Option>
              <Option value="BySize">By Size</Option>
              
            </Select>
          </div>
        </div>
        <div className="col-md-12">
          <hr />
        </div>
      </div>

      <div className="row ">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0">
              {" "}
              {traslate("DefaultMarkup")}{" "}
            </h4>
          </div>
        </div>
        <div className="col-md-4">
          <div className="input__block">
            <input 
            type="number" 
            name="DefaultMarkup"
            value={LabGrowData.DefaultMarkup ? LabGrowData.DefaultMarkup : null} 
            onChange={(e) =>
                handleOnChange(e, "DefaultMarkup")
              }
            />
            <label className="mt-2">
              {" "}
              {traslate(
                "Inordertoprotecttheintegrityofthediamondandthejewelryindustry,theminimummarkupontheDiamondlinkshouldbenolessthan7%"
              )}{" "}
            </label>
          </div>
        </div>
        <div className="col-md-12">
          <hr />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0">
              {" "}
              {traslate("MarkupByVendor")}{" "}
            </h4>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="input__block">
                <Select
                  className="border__grey"
                  showSearch
                  placeholder="Select Manufacturer"
                  name="SelectManufacturer"
                  value={LabGrowData.SelectManufacturer ? LabGrowData.SelectManufacturer : []}
                  optionFilterProp="children"
                  // onChange={onChange}
                  onChange={(e) =>
                    handleOnChange(e, "select", "SelectManufacturer")
                  }
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Apex Diamonds Inc (3504)">Apex Diamonds Inc (3504)</Option>
                  <Option value="AVA Diamonds Corp. (3388)">AVA Diamonds Corp. (3388)</Option>
                  <Option value="B&H Diamonds (2875)">B&H Diamonds (2875)</Option>
                </Select>
              </div>
            </div>

            <div className="col-md-4">
              <div className="input__block">
                <input 
                  type="text" 
                  placeholder="%" 
                  className="w-auto"
                  name="MarkupPercent"
                  value={LabGrowData.MarkupPercent ? LabGrowData.MarkupPercent : null} 
                  onChange={(e) =>
                  handleOnChange(e, "MarkupPercent")
                  }
                />
                <button className="primary-btn ml-2">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 divForm">
          <Table columns={columns} dataSource={data} />
          <hr />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0">
              {" "}
              {traslate("MarkupByPriceRangeWithCerts")}{" "}
            </h4>
          </div>

          <div className="row">
            <div className="col-md-2">
              <div className="input__block d-flex align-items-center">
                <input 
                  type="text" 
                  className="mr-2"              
                  name="CertCl"
                  value={LabGrowData.CertCl ? LabGrowData.CertCl : null} 
                  onChange={(e) =>
                  handleOnChange(e, "CertCl")
                  }
                />
                <label> {traslate("CL")} </label>
              </div>
            </div>

            <div className="col-md-2">
              <div className="input__block d-flex align-items-center">
                <input 
                  type="text" 
                  className="mr-2"              
                  name="CertClValue"
                  value={LabGrowData.CertClValue ? LabGrowData.CertClValue : null} 
                  onChange={(e) =>
                  handleOnChange(e, "CertClValue")
                  }


                />
                <label> {traslate("CL")} </label>
              </div>
            </div>

            <div className="col-md-2">
              <div className="input__block d-flex align-items-center">
                <input 
                  type="text" 
                  className="mr-2"              
                  name="CertClPercent"
                  value={LabGrowData.CertClPercent ? LabGrowData.CertClPercent : null} 
                  onChange={(e) =>
                  handleOnChange(e, "CertClPercent")
                  }
                />
                <label>%</label>
              </div>
            </div>

            <div className="col-md-1">
              <div className="input__block d-flex">
                <button className="primary-btn ml-2">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
            </div>

            <div className="col-md-2">
              <div className="input__block">
                <button className="primary-btn ml-2">
                  {" "}
                  {traslate("UpdateMarkup")}{" "}
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="row mt-3 mb-3 markup__checkbkox">
                <div className="col-md-1 checkbox__block">
                  <input 
                    type="checkbox" 
                    className="mr-1"
                    id="MarkupGIA"
                    name="GIA"
                    value={LabGrowData.Radio}
                    checked={
                      LabGrowData.Radio === "GIA"
                        ? true
                        : false
                    }
                    onChange={handleOnChangeRadio}

                  />
                  <span> {traslate("GIA")} </span>
                </div>

                <div className="col-md-1 checkbox__block">
                  <input 
                  
                    type="checkbox" 
                    className="mr-1"
                    id="MarkupAGS"
                    name="AGS"
                    value={LabGrowData.Radio}
                    checked={
                      LabGrowData.Radio === "AGS"
                        ? true
                        : false
                    }
                    onChange={handleOnChangeRadio}

                
                  />
                  <span> {traslate("AGS")} </span>
                </div>

                <div className="col-md-1 checkbox__block">
                  <input 
                    
                    type="checkbox" 
                    className="mr-1"
                    id="MarkupEGL"
                    name="EGL"
                    value={LabGrowData.Radio}
                    checked={
                      LabGrowData.Radio === "EGL"
                        ? true
                        : false
                    }
                    onChange={handleOnChangeRadio}

                
                  />
                  <span> {traslate("EGL")} </span>
                </div>

                <div className="col-md-1 checkbox__block">
                  <input 
                    
                    type="checkbox" 
                    className="mr-1"
                    id="MarkupIGI"
                    name="IGI"
                    value={LabGrowData.Radio}
                    checked={
                      LabGrowData.Radio === "IGI"
                        ? true
                        : false
                    }
                    onChange={handleOnChangeRadio}

                
                  />
                  <span> {traslate("IGI")} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 divForm">
          <Table columns={columns2} dataSource={data2} />
          <hr />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LabGrowMarkup);
