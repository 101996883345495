import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Tabs, Tab } from "react-bootstrap";
import CatalogJewelryDataService from "../../services/catalog-jewelry.service";
import { useSelector } from "react-redux";
import traslate from "../../i18n/translate";
const UploadHistory = () => {
  const [dataUploadList, setDataUploadList] = useState([]);
  const [imageUploadList, setImageUploadList] = useState([]);
  const [diamondUploadList, setDiamondUploadList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("DataUpload");
  const [dealerId, setDealerId] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [loginUser, setLoginUser] = useState({});
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setDealerId(loginDetials.responseData.dealerId);
        handleGetDataUploadList(
          loginDetials.responseData.dealerId,
          pageNo,
          pageSize
        );
        setLoginUser(loginDetials.responseData);
      }
    }
  }, []);
  const handleTabsChange = (e) => {
    setSelectedTab(e);
    if (e === "DataUpload") {
      setPageNo(1);
      setPageSize(10);
      setTotalPage(0);
      handleGetDataUploadList(dealerId, 1, 10);
    }
    if (e === "ImageUpload") {
      setPageNo(1);
      setPageSize(10);
      setTotalPage(0);
      handleGetImageUploadList(dealerId, 1, 10);
    }
    if (e === "Diamond") {
      setPageNo(1);
      setPageSize(10);
      setTotalPage(0);
      handleGetDiamondUploadList(dealerId, 1, 10);
    }
  };
  const handleGetDataUploadList = (id, no, size) => {
    try {
      setLoading(true);
      let inputData = {
        pageIndex: no,
        pageSize: size,
        dealerId: id,
        uploadType: "",
      };
      CatalogJewelryDataService.GetDataUploadList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            if (responseData) {
              setDataUploadList(responseData[0]);
              setTotalPage(responseData[1].TotalRecords);
            } else {
              setDataUploadList([]);
              setTotalPage(0);
            }
          } else {
            setDataUploadList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetImageUploadList = (id, no, size) => {
    try {
      setLoading(true);
      let inputData = {
        pageIndex: no,
        pageSize: size,
        dealerId: id,
        uploadType: "",
      };
      CatalogJewelryDataService.GetImageUploadList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            if (responseData) {
              setImageUploadList(responseData[0]);
              setTotalPage(responseData[1].TotalRecords);
            } else {
              setImageUploadList([]);
              setTotalPage(0);
            }
          } else {
            setImageUploadList([]);
            setTotalPage(0);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleGetDiamondUploadList = (id, no, size) => {
    try {
      setLoading(true);
      let inputData = {
        pageIndex: no,
        pageSize: size,
        dealerId: id,
        uploadType: "",
      };
      CatalogJewelryDataService.GetDiamondUploadList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            if (responseData) {
              setDiamondUploadList(responseData[0]);
              setTotalPage(responseData[1].TotalRecords);
            } else {
              setDiamondUploadList([]);
              setTotalPage(0);
            }
          } else {
            setDiamondUploadList([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleDownloadReport = (row, type) => {
    try {
      let inputData = {
        summaryId: row.summaryID,
        dealerId: row.dealerID,
        type: type,
      };
      CatalogJewelryDataService.DownloadReport(inputData)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          var filename =
            loginUser.dealerCompany +
            "(" +
            loginUser.dealerId +
            ")" +
            row.summaryID +
            "_" +
            new Date().toJSON().slice(0, 10).replace(/-/g, "") +
            ".csv";
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleRefershUpload = () => {
    if (selectedTab === "DataUpload") {
      handleGetDataUploadList(dealerId, pageNo, pageSize);
    }
    if (selectedTab === "ImageUpload") {
      handleGetImageUploadList(dealerId, pageNo, pageSize);
    }
    if (selectedTab === "Diamond") {
      handleGetDiamondUploadList(dealerId, pageNo, pageSize);
    }
  };
  const handlePageNoChange = (page, pageSize) => {
    setPageNo(page);
    if (selectedTab === "DataUpload") {
      handleGetDataUploadList(dealerId, page, pageSize);
    }
    if (selectedTab === "ImageUpload") {
      handleGetImageUploadList(dealerId, page, pageSize);
    }
    if (selectedTab === "Diamond") {
      handleGetDiamondUploadList(dealerId, page, pageSize);
    }
  };
  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
    if (selectedTab === "DataUpload") {
      handleGetDataUploadList(dealerId, current, size);
    }
    if (selectedTab === "ImageUpload") {
      handleGetImageUploadList(dealerId, current, size);
    }
    if (selectedTab === "Diamond") {
      handleGetDiamondUploadList(dealerId, current, size);
    }
  };
  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv data__maping">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="workarea__heading mt-0"> {traslate("UploadHistory")} </h4>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="prodesc__div">
                    <Tabs
                      id="uncontrolled-tab-example"
                      className="tab__div mb-3"
                      onSelect={(e) => {
                        handleTabsChange(e);
                      }}
                      activeKey={selectedTab}
                    >
                      <Tab eventKey="DataUpload" title={traslate("DataUpload")}>
                        <div className="tabs__content">
                          <Table
                            columns={[
                              {
                                title: traslate("Date"),
                                dataIndex: "dateOnly",
                              },
                              {
                                title: traslate("Time"),
                                dataIndex: "timeOnly",
                              },
                              {
                                title: traslate("File"),
                                dataIndex: "fileName",
                              },
                              {
                                title: traslate("Type"),
                                dataIndex: "type",
                              },
                              {
                                title: traslate("Status"),
                                dataIndex: "status",
                              },
                              {
                                title: traslate("Records"),
                                dataIndex: "records",
                              },
                              {
                                title: traslate("Report"),
                                dataIndex: "report",
                                render: (item, row) => {
                                  return (
                                    <React.Fragment>
                                      {row.status != "Completed" ? (
                                        "InProcess"
                                      ) : (
                                        <a
                                          href="#"
                                          onClick={() => {
                                            handleDownloadReport(
                                              row,
                                              "Jewelry"
                                            );
                                          }}
                                        >
                                          {traslate("Download")}
                                        </a>
                                      )}
                                    </React.Fragment>
                                  );
                                },
                              },
                            ]}
                            dataSource={dataUploadList}
                            loading={loading}
                            scroll={{ x: 600 }}
                            pagination={{
                              current: pageNo,
                              pageSize: pageSize,
                              pageSizeOptions: [10, 20, 50, 100],
                              responsive: true,
                              showSizeChanger: true,
                              total: totalPage,
                              onChange: handlePageNoChange,
                              onShowSizeChange: handlePageSizeChange,
                            }}
                          />
                        </div>
                      </Tab>
                      <Tab eventKey="ImageUpload" title={traslate("ImageUpload")}>
                        <Table
                          columns={[
                            {
                              title: traslate("Date"),
                              dataIndex: "dateOnly",
                            },
                            {
                              title: traslate("Time"),
                              dataIndex: "timeOnly",
                            },
                            {
                              title: traslate("File"),
                              dataIndex: "fileName",
                            },
                            {
                              title: traslate("Status"),
                              dataIndex: "status",
                            },
                            {
                              title: traslate("Records"),
                              dataIndex: "records",
                            },
                            {
                              title: traslate("Report"),
                              dataIndex: "report",
                              render: (item, row) => {
                                return (
                                  <React.Fragment>
                                    {row.status != "Completed" ? (
                                      "InProcess"
                                    ) : (
                                      <a
                                        href="#"
                                        onClick={() => {
                                          handleDownloadReport(row, "Image");
                                        }}
                                      >
                                        {traslate("Download")}
                                      </a>
                                    )}
                                  </React.Fragment>
                                );
                              },
                            },
                          ]}
                          dataSource={imageUploadList}
                          loading={loading}
                          scroll={{ x: 600 }}
                          pagination={{
                            current: pageNo,
                            pageSize: pageSize,
                            pageSizeOptions: [10, 20, 50, 100],
                            responsive: true,
                            showSizeChanger: true,
                            total: totalPage,
                            onChange: handlePageNoChange,
                            onShowSizeChange: handlePageSizeChange,
                          }}
                        />
                      </Tab>
                      <Tab eventKey="Diamond" title={traslate("Diamond")}>
                        <Table
                          columns={[
                            {
                              title: traslate("Date"),
                              dataIndex: "dateOnly",
                            },
                            {
                              title: traslate("Time"),
                              dataIndex: "timeOnly",
                            },
                            {
                              title: traslate("File"),
                              dataIndex: "fileName",
                            },
                            {
                              title: traslate("Type"),
                              dataIndex: "type",
                            },
                            {
                              title: traslate("Status"),
                              dataIndex: "status",
                            },
                            {
                              title: traslate("Records"),
                              dataIndex: "records",
                            },
                            {
                              title: traslate("Report"),
                              dataIndex: "report",
                              render: (item, row) => {
                                return (
                                  <React.Fragment>
                                    {row.status != "Completed" ? (
                                      "InProcess"
                                    ) : (
                                      <a
                                        href="#"
                                        onClick={() => {
                                          handleDownloadReport(row, "Diomond");
                                        }}
                                      >
                                        {traslate("Download")}
                                      </a>
                                    )}
                                  </React.Fragment>
                                );
                              },
                            },
                          ]}
                          dataSource={diamondUploadList}
                          loading={loading}
                          scroll={{ x: 600 }}
                          pagination={{
                            current: pageNo,
                            pageSize: pageSize,
                            pageSizeOptions: [10, 20, 50, 100],
                            responsive: true,
                            showSizeChanger: true,
                            total: totalPage,
                            onChange: handlePageNoChange,
                            onShowSizeChange: handlePageSizeChange,
                          }}
                        />
                      </Tab>
                    </Tabs>

                    <div className="col-md-6 mt-3">
                      <button
                        className="primary-btn"
                        onClick={handleRefershUpload}
                      >
                        <i
                          className="fa fa-refresh"
                          style={{ color: "white", marginRight: "10px" }}
                        ></i>
                        {traslate("UploadHistory")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UploadHistory;
