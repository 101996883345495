import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table
} from "antd";

import UnionIcon from "../../assets/images/icons/Union_5.svg";

const appointment = () => {
  //const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;

  const style = <span>content not provided.</span>;

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  const columns = [
    {
      title: "Time",
      dataIndex: "time",
    },
    {
      title: "Store Name",
      dataIndex: "storename",
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
    },
    {
      title: "Location",
      dataIndex: "location",
    }
  ];

  const data = [
    {
      key: "1",
      time: <span>10/10/2022</span>,
      storename: <span>Company Temp</span>,
      buyer: <span>Name</span>,
      location: <span>US</span>
    },
    {
      key: "2",
      time: <span>10/10/2022</span>,
      storename: <span>Company Temp</span>,
      buyer: <span>Name</span>,
      location: <span>US</span>
    },
    {
      key: "3",
      time: <span>10/10/2022</span>,
      storename: <span>Company Temp</span>,
      buyer: <span>Name</span>,
      location: <span>US</span>
    },
    {
      key: "4",
      time: <span>10/10/2022</span>,
      storename: <span>Company Temp</span>,
      buyer: <span>Name</span>,
      location: <span>US</span>
    }
  ];

  return (
    <React.Fragment>
      <div className="pendjewelreq__section">
        <div className="row form__fields magento__mapping">
          <div className="col-md-12 mb-4">
            <h4 class="workarea__heading mt-0">Appointment</h4>
          </div>
          <div className="col-md-3">
            <div className="input__block">
              <label>From Date</label>
              <input type="date" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="input__block">
              <label>To Date</label>
              <input type="date" />
            </div>
          </div>

          <div className="col-md-3">
            <div className="input__block">
              <label>Filter By Trade Show</label>
              <Select
                showSearch
                className="border__grey"
                placeholder="Trade Show 1 "
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="jack">Trade Show 1</Option>
                <Option value="lucy">Trade Show 2</Option>
                <Option value="tom">Trade Show 3</Option>
              </Select>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 mt-4">
            <Table
              className="pendjewelreq__tbl"
              columns={columns}
              dataSource={data}
              scroll={{ y: 300 }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default appointment;
