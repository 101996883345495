import React, { useEffect, useState } from "react";
import { Button, Select, Spin, Switch } from "antd";
import { Tabs, Tab } from "react-bootstrap";
import AdminSettingsDataService from "../../../services/admin-settings.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../../i18n/translate";
const { Option } = Select;

const DataMaps = () => {
  const [productIdDescriptionsData, setproductIdDescriptionsData] = useState(
    []
  );
  const [productIdDescriptionsSets, setproductIdDescriptionsSets] = useState(
    []
  );
  const [CategoryData, setCategoryData] = useState([]);
  const [CategorySets, setCategorySets] = useState([]);
  const [AttributesData, setAttributesData] = useState([]);
  const [AttributesSets, setAttributesSets] = useState([]);
  const [GemstonesData, setGemstonesData] = useState([]);
  const [GemstonesSets, setGemstonesSets] = useState([]);
  const [WatchesData, setWatchesData] = useState([]);
  const [WatchesSets, setWatchesSets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [key, setKey] = useState("productId");
  useEffect(() => {
    handleGetProductIdDataList();
  }, []);
  const handleOnChange = (e, id) => {
    if (key === "productId") {
      const tempData = productIdDescriptionsData.map((x) => {
        if (x.tempID === id) {
          return { ...x, dvalue: e.target.value };
        } else return x;
      });
      setproductIdDescriptionsData(tempData);
    }
    if (key === "category") {
      const tempData = CategoryData.map((x) => {
        if (x.tempID === id) {
          return { ...x, dvalue: e.target.value };
        } else return x;
      });
      setCategoryData(tempData);
    }
    if (key === "Attributes") {
      const tempData = AttributesData.map((x) => {
        if (x.tempID === id) {
          return { ...x, dvalue: e.target.value };
        } else return x;
      });
      setAttributesData(tempData);
    }
    if (key === "Gemstones") {
      const tempData = GemstonesData.map((x) => {
        if (x.tempID === id) {
          return { ...x, dvalue: e.target.value };
        } else return x;
      });
      setGemstonesData(tempData);
    }
    if (key === "watches") {
      const tempData = WatchesData.map((x) => {
        if (x.tempID === id) {
          return { ...x, dvalue: e.target.value };
        } else return x;
      });
      setWatchesData(tempData);
    }
  };
  const handlTabChanges = (e) => {
    setKey(e);
    if (e === "productId") {
      handleGetProductIdDataList();
    }
    if (e === "category") {
      handleGetCategoryDataList();
    }
    if (e === "Attributes") {
      handleGetAttributesDataList();
    }
    if (e === "Gemstones") {
      handleGetGemStonesDataList();
    }
    if (e === "watches") {
      handleGetWatchInfoList();
    }
  };
  const handleGetProductIdDataList = () => {
    try {
      setLoading(true);
      AdminSettingsDataService.GetProductIdDataList()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            var tempProductIdDescriptionsSets = [
              ...new Set(responseData.map(({ headerName }) => headerName)),
            ];

            setproductIdDescriptionsSets(tempProductIdDescriptionsSets);
            setproductIdDescriptionsData(responseData);
          } else {
            setproductIdDescriptionsSets([]);
            setproductIdDescriptionsData([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleGetCategoryDataList = () => {
    try {
      setLoading(true);
      AdminSettingsDataService.GetCategoryDataList()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            var tempCategorySets = [
              ...new Set(responseData.map(({ headerName }) => headerName)),
            ];
            setCategorySets(tempCategorySets);
            setCategoryData(responseData);
          } else {
            setCategorySets([]);
            setCategoryData([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleGetAttributesDataList = () => {
    try {
      setLoading(true);
      AdminSettingsDataService.GetAttributesDataList()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            var tempAttributesSets = [
              ...new Set(responseData.map(({ headerName }) => headerName)),
            ];
            setAttributesSets(tempAttributesSets);
            setAttributesData(responseData);
          } else {
            setAttributesSets([]);
            setAttributesData([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleGetGemStonesDataList = () => {
    try {
      setLoading(true);
      AdminSettingsDataService.GetGemStonesDataList()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            var tempGemstonesSets = [
              ...new Set(responseData.map(({ headerName }) => headerName)),
            ];
            setGemstonesSets(tempGemstonesSets);
            setGemstonesData(responseData);
          } else {
            setGemstonesSets([]);
            setGemstonesData([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleGetWatchInfoList = () => {
    try {
      setLoading(true);
      AdminSettingsDataService.GetWatchInfoList()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            var tempWatchesSets = [
              ...new Set(responseData.map(({ headerName }) => headerName)),
            ];
            setWatchesSets(tempWatchesSets);
            setWatchesData(responseData);
          } else {
            setWatchesSets([]);
            setWatchesData([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleSaveDataMaps = () => {
    try {
      setSubmitLoading(true);
      let inputData =
        key == "productId"
          ? productIdDescriptionsData
          : key == "category"
          ? CategoryData
          : key == "Attributes"
          ? AttributesData
          : key == "Gemstones"
          ? GemstonesData
          : WatchesData;
      AdminSettingsDataService.SaveDataMaps(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setSubmitLoading(false);
          if (message == "Success") {
            NotificationManager.success("Record Saved Succssfully.");
            if (key === "productId") {
              handleGetProductIdDataList();
            }
            if (key === "category") {
              handleGetCategoryDataList();
            }
            if (key === "Attributes") {
              handleGetAttributesDataList();
            }
            if (key === "Gemstones") {
              handleGetGemStonesDataList();
            }
            if (key === "watches") {
              handleGetWatchInfoList();
            }
          } else {
            NotificationManager.error("Record Not Saved.");
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="col-md-12">
          <div className="prod__maindiv data__maping">
            <div className="row">
              <div className="col-md-12">
                <h4 className="workarea__heading">{traslate("DefaultDataMap")}</h4>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="prodesc__div">
                  <Tabs
                    defaultActiveKey="productId"
                    id="uncontrolled-tab-example"
                    className="tab__div mb-3"
                    activeKey={key}
                    onSelect={(k) => handlTabChanges(k)}
                  >
                    <Tab eventKey="productId" title={traslate("ProductID&Description")}>
                      <Spin spinning={loading} size={"large"}>
                        <div className="tab__contentdiv">
                          <div className="head__div">
                            <h4> {traslate("GemfindColumn")} </h4>
                          </div>
                          <div className="desc__maindiv">
                            {productIdDescriptionsSets.map((header) => {
                              return (
                                <React.Fragment>
                                  <h6 className="subhead">
                                    {header ? header : traslate("Status") }
                                  </h6>
                                  {productIdDescriptionsData
                                    .filter((x) => x.headerName == header)
                                    .map((item) => {
                                      return (
                                        <React.Fragment>
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              {item.value}
                                            </span>
                                            <span className="desc__txt">
                                              <input
                                                type="text"
                                                value={item.dvalue}
                                                onChange={(e) =>
                                                  handleOnChange(e, item.tempID)
                                                }
                                                className="input__field"
                                              />
                                            </span>
                                          </div>
                                        </React.Fragment>
                                      );
                                    })}
                                </React.Fragment>
                              );
                            })}

                            <div className="desc__div">
                              <span className="head__txt"> {traslate("ActiveStatus")} </span>
                              <span className="desc__txt">
                                <Switch
                                  name="ActiveStatus"
                                  checkedChildren="On"
                                  unCheckedChildren="Off"
                                  defaultChecked
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "ActiveStatus")
                                  }
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </Spin>
                    </Tab>
                    <Tab eventKey="category" title= {traslate("Category")}>
                      <Spin spinning={loading} size={"large"}>
                        <div className="tab__contentdiv">
                          <div className="head__div">
                            <h4> {traslate("GemfindColumn")} </h4>
                          </div>
                          <div className="desc__maindiv">
                            {CategorySets.map((header) => {
                              return (
                                <React.Fragment>
                                  <h6 className="subhead">
                                    {header ? header : traslate("Categories") }
                                  </h6>
                                  <div className="scroll__div">
                                    {CategoryData.filter(
                                      (x) => x.headerName == header
                                    ).map((item) => {
                                      return (
                                        <React.Fragment>
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              {item.value}
                                            </span>
                                            <span className="desc__txt">
                                              <input
                                                type="text"
                                                value={item.dvalue}
                                                onChange={(e) =>
                                                  handleOnChange(e, item.tempID)
                                                }
                                                className="input__field"
                                              />
                                            </span>
                                          </div>
                                        </React.Fragment>
                                      );
                                    })}
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </Spin>
                    </Tab>
                    <Tab eventKey="Attributes" title={traslate("Attributes")}>
                      <Spin spinning={loading} size={"large"}>
                        <div className="tab__contentdiv">
                          <div className="head__div">
                            <h4> {traslate("GemfindColumn")} </h4>
                          </div>
                          <div className="desc__maindiv">
                            {AttributesSets.map((header) => {
                              return (
                                <React.Fragment>
                                  <h6 className="subhead">
                                    {header ? header : traslate("Attributes")}
                                  </h6>
                                  <div className="scroll__div">
                                    {AttributesData.filter(
                                      (x) => x.headerName == header
                                    ).map((item) => {
                                      return (
                                        <React.Fragment>
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              {item.value}
                                            </span>
                                            <span className="desc__txt">
                                              <input
                                                type="text"
                                                value={item.dvalue}
                                                onChange={(e) =>
                                                  handleOnChange(e, item.tempID)
                                                }
                                                className="input__field"
                                              />
                                            </span>
                                          </div>
                                        </React.Fragment>
                                      );
                                    })}
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </Spin>
                    </Tab>
                    <Tab eventKey="Gemstones" title={traslate("Gemstones")}>
                      <Spin spinning={loading} size={"large"}>
                        <div className="tab__contentdiv">
                          <div className="head__div">
                            <h4> {traslate("GemfindColumn")} </h4>
                          </div>
                          <div className="desc__maindiv">
                            {GemstonesSets.map((header) => {
                              return (
                                <React.Fragment>
                                  <h6 className="subhead">
                                    {header ? header : traslate("Gemstones")}
                                  </h6>
                                  <div className="scroll__div">
                                    {GemstonesData.filter(
                                      (x) => x.headerName == header
                                    ).map((item) => {
                                      return (
                                        <React.Fragment>
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              {item.value}
                                            </span>
                                            <span className="desc__txt">
                                              <input
                                                type="text"
                                                value={item.dvalue}
                                                onChange={(e) =>
                                                  handleOnChange(e, item.tempID)
                                                }
                                                className="input__field"
                                              />
                                            </span>
                                          </div>
                                        </React.Fragment>
                                      );
                                    })}
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </Spin>
                    </Tab>
                    <Tab eventKey="watches" title={traslate("Watches")}>
                      <Spin spinning={loading} size={"large"}>
                        <div className="tab__contentdiv">
                          <div className="head__div">
                            <h4> {traslate("GemfindColumn")} </h4>
                          </div>
                          <div className="desc__maindiv">
                            {WatchesSets.map((header) => {
                              return (
                                <React.Fragment>
                                  <h6 className="subhead">
                                    {header ? header : traslate("Watches")}
                                  </h6>
                                  <div className="scroll__div">
                                    {WatchesData.filter(
                                      (x) => x.headerName == header
                                    ).map((item) => {
                                      return (
                                        <React.Fragment>
                                          <div className="desc__div">
                                            <span className="head__txt">
                                              {item.value}
                                            </span>
                                            <span className="desc__txt">
                                              <input
                                                type="text"
                                                value={item.dvalue}
                                                onChange={(e) =>
                                                  handleOnChange(e, item.tempID)
                                                }
                                                className="input__field"
                                              />
                                            </span>
                                          </div>
                                        </React.Fragment>
                                      );
                                    })}
                                  </div>
                                </React.Fragment>
                              );
                            })}

                            {/* <h6 className="subhead">WatchBandType</h6> */}
                          </div>
                        </div>
                      </Spin>
                    </Tab>
                  </Tabs>

                  <div className="col-md-6 mt-3">
                    <Button
                      className="primary-btn"
                      onClick={handleSaveDataMaps}
                      loading={submitLoading}
                    >
                      {traslate("SaveDetails")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DataMaps;
