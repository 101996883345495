import React, { Fragment } from "react";
import PendingJewelryRequest from "./PendingJewelryRequest";

const PendingDiamondRequest = () => {
  return (
    <Fragment>
      <PendingJewelryRequest type="Diamond" />
    </Fragment>
  );
};

export default PendingDiamondRequest;
