import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Table,
  Modal,
  DatePicker,
  Switch,
  Space,
} from "antd";
import {
  EditOutlined,
  CalendarOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";

import userIcon from "../../../assets/images/daimond-bg.png";
import traslate from "../../../i18n/translate";

const { Option } = Select;

/*Custom Binding Starts*/
const initialMarkupData = {
  method: null,
  currency: null,
  customMethod: null,
  defaultMarkup: "",
};
/*Custom Binding Ends*/
export const FancyColorMarkup = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  // const data = [

  // ];

  const columns2 = [
    {
      title: traslate("MinPrice"),
      dataIndex: "minPrice",
    },
    {
      title: traslate("MaxPrice"),
      dataIndex: "maxPrice",
    },
    {
      title: traslate("Certs"),
      dataIndex: "certs",
    },
    {
      title: traslate("Markup"),
      dataIndex: "markup",
    },
    {
      title: "",
      fixed: "right",
      width: 100,
      render: () => (
        <div className="action__btns">
          <div className="image__block">
            <img src={Delete_iconbl} alt="" />
          </div>
        </div>
      ),
    },
  ];

  const data2 = [
    {
      key: "1",
      minPrice: "1.00",
      maxPrice: "1000.00",
      certs: "",
      markup: "70%",
    },
  ];

  /*Binding starts*/
  const [markupData, setMarkupData] = useState(initialMarkupData);
  const [markupByVendorData, setMarkupByVendorData] = useState([]);

  const [state, setState] = useState({
    manufacturer: null,
    markupByVendorPercent: "",
  });
  const addMarkupByVendor = () => {
    debugger;
    setState({
      manufacturer: state.manufacturer,
      markupByVendorPercent: state.markupByVendorPercent,
    });
    var array = markupByVendorData;
    var tempState = {
      key: Math.random(),
      manufacturer: state.manufacturer,
      markupByVendorPercent: state.markupByVendorPercent,
    };
    array.push(tempState);
    // setState({
    //   manufacturer: "",
    //   markupByVendorPercent: "",
    // });
    setMarkupByVendorData(array);
    console.log(markupByVendorData.length);
  };

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };
  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setMarkupData({
        ...markupData,
        [name]: e,
      });
    } else {
      setMarkupData({
        ...markupData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onChangeDrop = (e, name) => {
    let tempState = state;
    tempState[name] = e;

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const onNumberChange = (e, name) => {
    // debugger
    let tempState = state;
    tempState[name] = e.target.value;

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const [markupByPriceRange, setMarkupByPriceRange] = useState([]);
  const [state2, setState2] = useState({
    minMarkupPrice: null,
    maxMarkupPrice: null,
    markupPricePercent: null,
  });
  const addMarkupByPriceRange = () => {
    debugger;
    setState2((prevState) => ({
      ...prevState,
      ...state2,
    }));
    const temp2 = markupByPriceRange.map((x) => {
      debugger;
      return {
        ...x,
        minMarkupPrice: state2.minMarkupPrice,
        maxMarkupPrice: state2.maxMarkupPrice,
        markupPricePercent: state2.markupPricePercent,
      };
    });
    setMarkupByPriceRange(temp2);

    console.log(state2);
  };

  const onKeyPressEvent2 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };
  // const handleOnChange2 = (e, isSelect, name) => {
  //   if (isSelect === "select") {
  //     setMarkupData({
  //       ...markupData,
  //       [name]: e,
  //     });
  //   } else {
  //     setMarkupData({
  //       ...markupData,
  //       [e.target.name]: e.target.value,
  //     });
  //   }
  // };

  const onChangeDrop2 = (e, name) => {
    let tempState = state2;
    tempState[name] = e;

    setState2((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const onNumberChange2 = (e, name) => {
    // debugger
    let tempState = state2;
    tempState[name] = e.target.value;

    setState2((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };
  /*2nd table ends */
  /*Binding ends*/
  console.log(markupByVendorData);
  return (
    <div className="form__fields markup__section">
      <div className="row ">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0">
              {" "}
              {traslate("FancyColorMarkup")}{" "}
            </h4>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="input__block">
            <label> {traslate("Method")} </label>
            <Select
              className="border__grey"
              optionFilterProp="children"
              placeholder="Custom"
              name="method"
              value={markupData.method ? markupData.method : null}
              onChange={(e) => handleOnChange(e, "select", "method")}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="custom">Custom</Option>
              <Option value="price">Price</Option>
              <Option value="size">Size</Option>
            </Select>
          </div>
        </div>

        <div className="col-md-4">
          <div className="input__block">
            <label> {traslate("Currency")} </label>
            <Select
              className="border__grey"
              optionFilterProp="children"
              placeholder="US Dollar $"
              name="currency"
              value={markupData.currency ? markupData.currency : null}
              onChange={(e) => handleOnChange(e, "select", "currency")}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="usd">US Dollars</Option>
              <Option value="gbp">Great Britain Pound</Option>
            </Select>
          </div>
        </div>

        <div className="col-md-4">
          <div className="input__block">
            <label> {traslate("CustomMethod")} </label>
            <Select
              className="border__grey"
              optionFilterProp="children"
              placeholder="By Price"
              name="customMethod"
              value={markupData.customMethod ? markupData.customMethod : null}
              onChange={(e) => handleOnChange(e, "select", "customMethod")}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="by-size">By Price</Option>
              <Option value="by-price">By Size</Option>
            </Select>
          </div>
        </div>
        <div className="col-md-12">
          <hr />
        </div>
      </div>

      <div className="row ">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0">
              {" "}
              {traslate("DefaultMarkup")}{" "}
            </h4>
          </div>
        </div>
        <div className="col-md-4">
          <div className="input__block">
            {/* <input type="number" value="100" /> */}
            <input
              type="text"
              onKeyPress={onKeyPressEvent}
              placeholder="6%"
              name="defaultMarkup"
              value={markupData.defaultMarkup}
              // value={ViewMyColorData.crownAngleRangeTo}
              onChange={(e) => {
                e.target.value = e.target.value.slice(0, 3);
                onNumberChange(e, "defaultMarkup");
              }}
            />
            <label className="mt-2">
              {" "}
              {traslate(
                "Inordertoprotecttheintegrityofthediamondandthejewelryindustry,theminimummarkupontheDiamondlinkshouldbenolessthan7%"
              )}{" "}
            </label>
          </div>
        </div>
        <div className="col-md-12">
          <hr />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0">
              {" "}
              {traslate("MarkupByVendor")}{" "}
            </h4>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="input__block">
                <Select
                  className="border__grey"
                  optionFilterProp="children"
                  placeholder="Select Manufacturer"
                  name="manufacturer"
                  value={state.manufacturer}
                  onChange={(e) => onChangeDrop(e, "manufacturer")}
                  // onChange={(e) =>
                  //   handleStateOnChange(e, "select", "manufacturer")
                  // }
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="bhDiamonds">B & H Diamonds</Option>
                  <Option value="apex">Apex Diamonds</Option>
                </Select>
              </div>
            </div>

            <div className="col-md-4">
              <div className="input__block">
                <input
                  type="text"
                  placeholder="%"
                  className="w-auto"
                  value={state.markupByVendorPercent}
                  onChange={(e) => {
                    e.target.value = e.target.value.slice(0, 3);
                    onNumberChange(e, "markupByVendorPercent");
                  }}
                  // value={markupByVendorData[0].markupByVendorPercent ? markupByVendorData[0].markupByVendorPercent : null}
                />
                <button
                  className="primary-btn ml-2"
                  onClick={() => addMarkupByVendor()}
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 divForm">
          <Table
            columns={[
              {
                title: traslate("ID"),
                dataIndex: "markupByVendorId",
              },
              {
                title: traslate("Dealer"),
                dataIndex: "manufacturer",
              },
              {
                title: traslate("Markup"),
                dataIndex: "markupByVendorPercent",
              },
            ]}
            dataSource={markupByVendorData}
          />
          <hr />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0">
              {" "}
              {traslate("MarkupByPriceRangeWithCerts")}{" "}
            </h4>
          </div>

          <div className="row">
            <div className="col-md-2">
              <div className="input__block d-flex align-items-center">
                <input
                  type="text"
                  className="mr-2"
                  value={state2.minMarkupPrice}
                  onChange={(e) => {
                    // e.target.value = e.target.value.slice(0, 3);
                    onNumberChange2(e, "minMarkupPrice");
                  }}
                />
                <label> {traslate("CL")} </label>
              </div>
            </div>

            <div className="col-md-2">
              <div className="input__block d-flex align-items-center">
                <input
                  type="text"
                  className="mr-2"
                  value={state2.maxMarkupPrice}
                  onChange={(e) => {
                    // e.target.value = e.target.value.slice(0, 3);
                    onNumberChange2(e, "maxMarkupPrice");
                  }}
                />
                <label> {traslate("CL")} </label>
              </div>
            </div>

            <div className="col-md-2">
              <div className="input__block d-flex align-items-center">
                <input
                  type="text"
                  className="mr-2"
                  value={state2.markupPricePercent}
                  onKeyPress={onKeyPressEvent2}
                  onChange={(e) => {
                    e.target.value = e.target.value.slice(0, 3);
                    onNumberChange2(e, "markupPricePercent");
                  }}
                />
                <label>%</label>
              </div>
            </div>

            <div className="col-md-1">
              <div className="input__block d-flex">
                <button
                  className="primary-btn ml-2"
                  onClick={addMarkupByPriceRange}
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
            </div>

            <div className="col-md-2">
              <div className="input__block">
                <button className="primary-btn ml-2">
                  {" "}
                  {traslate("UpdateMarkup")}{" "}
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="row mt-3 mb-3 markup__checkbkox">
                <div className="col-md-1 checkbox__block">
                  <input type="checkbox" className="mr-1" />
                  <span> {traslate("GIA")} </span>
                </div>

                <div className="col-md-1 checkbox__block">
                  <input type="checkbox" className="mr-1" />
                  <span> {traslate("AGS")} </span>
                </div>

                <div className="col-md-1 checkbox__block">
                  <input type="checkbox" className="mr-1" />
                  <span> {traslate("EGL")} </span>
                </div>

                <div className="col-md-1 checkbox__block">
                  <input type="checkbox" className="mr-1" />
                  <span> {traslate("IGI")} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 divForm">
          <Table columns={columns2} dataSource={data2} />
          <hr />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FancyColorMarkup);
