import React, { useState } from "react";
import { Input, Row, Col, Select, Checkbox, Button, Switch } from "antd";

const PendantBuilderNavigation = () => {
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };
  return (
    <React.Fragment>
      <div className="navigation__section">
        <div className="form__fields">
          <div className="col-md-12 mt-2">
            <h4 className="workarea__heading mt-0 mb-0">Navigation</h4>
          </div>

          <div className="col-lg-12 mt-4">
            <div className="form__section">
              <div className="desc__div">
                <div className="headtxt__div">
                  <span className="">Standard Search </span>
                </div>

                <div className="inpt__div">
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    defaultChecked
                    onChange={handleSwitch}
                  />
                </div>
              </div>
              <div className="desc__div">
                <div className="headtxt__div">
                  <span className="">Advanced Search </span>
                </div>

                <div className="inpt__div">
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    defaultChecked
                    onChange={handleSwitch}
                  />
                </div>
              </div>
              <div className="desc__div">
                <div className="headtxt__div">
                  <span className="">Lab Grown Search </span>
                </div>

                <div className="inpt__div">
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    defaultChecked
                    onChange={handleSwitch}
                  />
                </div>
              </div>
              <div className="desc__div">
                <div className="headtxt__div">
                  <span className="">Request a Diamond </span>
                </div>

                <div className="inpt__div">
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    defaultChecked
                    onChange={handleSwitch}
                  />
                </div>
              </div>
              <div className="desc__div">
                <div className="headtxt__div">
                  <span className="">Compare Diamonds </span>
                </div>

                <div className="inpt__div">
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    defaultChecked
                    onChange={handleSwitch}
                  />
                </div>
              </div>
              <div className="col-lg-12 mt-5">
                <button class="primary-btn"> Update Settings</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PendantBuilderNavigation;
