import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, Link, useNavigate } from "react-router-dom";
import menuIcon from "../../assets/images/menu-icon.svg";
import userIcon from "../../assets/images/user-icon.png";
import edit from "../../assets/images/icons/edit.svg";
import noun_user from "../../assets/images/icons/noun_user.svg";
import noun_settings from "../../assets/images/icons/noun_settings.svg";
import noun_language from "../../assets/images/icons/noun_language.svg";
import noun_dollar from "../../assets/images/icons/noun_dollar.svg";
import logout from "../../assets/images/icons/logout.svg";
import { setLoginToken } from "../../actions/login/login";
import { Menu, Dropdown } from "antd";
import traslate from "../../i18n/translate";

export const Header = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    toggleCollapsed,
    handleLink,
    show,
    showTopNav,
    toggleMobileMenuCollapsed,
  } = props;
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [dealerId, setDealerId] = useState();
  const [checkLink, setCheckLink] = useState(props.checkLink);
  const loginDetials = useSelector((state) => state.loginReducer);

  useEffect(() => {
    debugger;
    if (loginDetials.impresonateData) {
      var tempData = loginDetials.impresonateData;
      setName(tempData.name);
      setUserName(tempData.userName);
      setDealerId(tempData.dealerID);
      setCheckLink("Dashboard");
      handleLink("Dashboard");
    } else {
      if (loginDetials) {
        if (loginDetials.loginDetials) {
          if (!loginDetials.impresonateData) {
            setName(loginDetials.loginDetials.responseData.dealerName);
            setUserName(loginDetials.loginDetials.responseData.dealerCompany);
            setDealerId(loginDetials.loginDetials.responseData.dealerId);
            navigate(window.location.pathname);
          } else if (loginDetials.loginDetials.responseData.logInSucceeded) {
            setName(loginDetials.loginDetials.responseData.dealerName);
            setUserName(loginDetials.loginDetials.responseData.dealerCompany);
            setDealerId(loginDetials.loginDetials.responseData.dealerId);
            navigate("administrationTools");
          } else {
            navigate("/");
          }
        }
      } else {
        navigate("/");
      }
    }
  }, []);

  const handleLoginValueFalse = async () => {
    dispatch(setLoginToken(null));
    navigate("/");
  };
  const handleHeadeMenuClick = (header) => {
    setCheckLink(header);
    handleLink(header);
  };

  //Avatar Dropdown
  const menu = (
    <Menu className="dropdown-menu-sp">
      <Menu.Item key="0">
        <NavLink to="/editProfile">
          <label>
            <img src={edit} alt="" /> {traslate("EditProfile")}
          </label>
        </NavLink>
      </Menu.Item>

      <Menu.Item key="1">
        <NavLink to="/manageUser">
          <label>
            <img src={noun_user} alt="" /> {traslate("ManageUser")}
          </label>
        </NavLink>
      </Menu.Item>

      <Menu.Item key="2">
        <NavLink to="/manageAccount">
          <label>
            <img src={noun_settings} alt="" /> {traslate("ManageAccount")}
          </label>
        </NavLink>
      </Menu.Item>

      <Menu.Item key="3">
        <NavLink to="/manageLanguage">
          <label>
            <img src={noun_language} alt="" /> {traslate("ManageLanguage")}
          </label>
        </NavLink>
      </Menu.Item>

      <Menu.Item key="4">
        <NavLink to="/manageCurrency">
          <label>
            <img src={noun_dollar} alt="" /> {traslate("ManageCurrency")}
          </label>
        </NavLink>
      </Menu.Item>

      <Menu.Item key="5">
        <label onClick={handleLoginValueFalse} className="logout__txt">
          <img src={logout} alt="" /> {traslate("Logout")}
        </label>
      </Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
      <div className="left__block">
        <div className="menu__icon">
          <img
            src={menuIcon}
            className="img-fluid desktop__icon"
            onClick={() => toggleCollapsed()}
          />
          <img
            src={menuIcon}
            className="img-fluid mobile__icon"
            onClick={() => toggleMobileMenuCollapsed()}
          />
        </div>

        <div className="topnav__toggle">
          <h3 onClick={() => showTopNav()}>
            {traslate("TopMenu")}{" "}
            <i class="fa fa-chevron-down" aria-hidden="true"></i>{" "}
          </h3>
        </div>

        <div className={show ? "top__nav show" : "top__nav hide"}>
          <ul>
            <li
              className={checkLink === "Dashboard" ? "active" : ""}
              onClick={() => handleHeadeMenuClick("Dashboard")}
            >
              <NavLink to="/dashboard">
                <label>{traslate("Dashboard")}</label>
              </NavLink>
            </li>
            <li
              className={checkLink === "Search" ? "active" : ""}
              onClick={() => handleHeadeMenuClick("Search")}
            >
              <NavLink to="/diamondSearch">
                <label>{traslate("Search")}</label>
              </NavLink>
            </li>
            <li
              className={checkLink === "Retailer" ? "active" : ""}
              onClick={() => handleHeadeMenuClick("Retailer")}
            >
              <NavLink to="/jewelryRetailerPermission">
                <label>{traslate("Retailers")}</label>
              </NavLink>
            </li>
            <li
              className={checkLink === "Contact" ? "active" : ""}
              onClick={() => handleHeadeMenuClick("Contact")}
            >
              <NavLink to="/customer">
                <label>{traslate("Contact")}</label>
              </NavLink>
            </li>
            <li
              className={checkLink === "Catalog" ? "active" : ""}
              onClick={() => handleHeadeMenuClick("Catalog")}
            >
              <NavLink to="/catalog">
                <label>{traslate("Catalog")}</label>
              </NavLink>
            </li>
            <li
              className={checkLink === "Report" ? "active" : ""}
              onClick={() => handleHeadeMenuClick("Report")}
            >
              <NavLink to="/diamondChartReportsMain">
                <label>{traslate("Report")}</label>
              </NavLink>
            </li>
            <li
              className={checkLink === "Community" ? "active" : ""}
              onClick={() => handleHeadeMenuClick("Community")}
            >
              <NavLink to="/myvendors">
                <label>{traslate("Community")}</label>
              </NavLink>
            </li>
            <li
              className={checkLink === "Apps" ? "active" : ""}
              onClick={() => handleHeadeMenuClick("Apps")}
            >
              <NavLink to="/dealers">
                <label>{traslate("Apps")}</label>
              </NavLink>
            </li>
            <li
              className={checkLink === "PurchaseOrder" ? "active" : ""}
              onClick={() => handleHeadeMenuClick("PurchaseOrder")}
            >
              <NavLink to="/purchaseOrder">
                <label>{traslate("PurchaseOrder")}</label>
              </NavLink>
            </li>
            <li
              className={checkLink === "Marketing" ? "active" : ""}
              onClick={() => handleHeadeMenuClick("Marketing")}
            >
              <NavLink to="/uploadMarketingMaterial">
                <label>{traslate("Marketing")}</label>
              </NavLink>
            </li>
            <li
              className={checkLink === "Admin" ? "active" : ""}
              onClick={() => handleHeadeMenuClick("Admin")}
            >
              <NavLink to="AdministrationTools">
                <label>{traslate("Admin")}</label>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <div className="right__block">
        <div className="search__block">
          <input className="search__input" type="text" />
          <button className="search__button">
            <i class="fa fa-search" aria-hidden="true"></i>
          </button>
        </div>
        <div className="user__block">
          <div className="user__details">
            <span style={{ color: "#fff" }}>{name}</span>
            <h4>
              {userName}| {dealerId}
            </h4>
            {/* <span
              style={{ color: "#fff", cursor: "pointer" }}
              onClick={handleLoginValueFalse}
            >
              Logout
            </span> */}
          </div>

          <Dropdown overlay={menu} trigger={["click"]}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <div className="profile__img">
                <img src={userIcon} />
              </div>
            </a>
          </Dropdown>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
