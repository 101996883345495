import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table,
  Modal,
  Spin,
} from "antd";
import { Tabs, Tab } from "react-bootstrap";
import traslate from "../../i18n/translate";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import RetailerDataService from "../../services/retailer-retailer.service";
import { NotificationManager } from "react-notifications";

const RetailerProfile = () => {
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;

  const [visible, setVisible] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [contactData, setContactData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [retailerId, setRetailerId] = useState("");
  const [accessData, setAccessData] = useState([]);
  const [appointment, setAppointment] = useState("");
  const [appointmentData, setAppointmentData] = useState("");
  const [notes, setNotes] = useState({
    title: "",
    description: "",
  });
  const [notestData, setNotesData] = useState("");
  const [selectedTab, setSelectedTab] = useState("Profile");

  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      if (location.state.key) {
        if (location.state.key == "Profile" && location.state.id) {
          setSelectedTab("Profile");
          handleGetRetailerProfile(location.state.id);
        } else if (location.state.key == "Conversation") {
          setSelectedTab("Conversation");
        } else if (location.state.key == "Appointments") {
          setSelectedTab("Appointments");
        } else if (location.state.key == "Notes") {
          setSelectedTab("Notes");
        }
      }
      setRetailerId(location.state.id);
      handleRetailerContactDetail(location.state.id);
      handleGetRetailerCollectionAccess(location.state.id);
      handleLoadRetAppData(location.state.id);

      handleLoadDataNotesList(location.state.id);
    }
  }, []);

  const handleUpdateCollection = (saveRow) => {
    try {
      let inputData = {
        dealerID: "2704",
        retailerID: retailerId,
        isSelected: saveRow.isassigned.toString(),
        collectionID: saveRow.collectionid.toString(),
      };
      setLoading(true);
      RetailerDataService.UpdateCollection(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message == "Success") {
            setLoading(false);
            handleGetRetailerCollectionAccess(retailerId);
            NotificationManager.success("Record Updated Succssfully.");
          } else {
            setLoading(false);
            NotificationManager.error("Record Not Updated.");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleGetRetailerCollectionAccess = (id) => {
    try {
      let inputData = {
        dealerID: "2704",
        retailerID: id,
      };
      setLoading(true);
      RetailerDataService.GetRetailerCollectionAccess(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setLoading(false);
              setAccessData(responseData);
            } else {
              setLoading(false);
              setAccessData([]);
            }
          } else {
            setLoading(false);
            setAccessData([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleRetailerContactDetail = (id) => {
    try {
      let inputData = {
        dealerID: "2704",
        retailerID: id,
      };
      setLoading(true);
      RetailerDataService.RetailerContactDetail(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setLoading(false);
              setContactData(responseData);
            } else {
              setLoading(false);
              setContactData([]);
            }
          } else {
            setLoading(false);
            setContactData([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleGetRetailerProfile = (id) => {
    try {
      let inputData = {
        retailerID: id,
      };
      setLoading(true);
      RetailerDataService.GetRetailerProfile(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData[0];
          if (message == "Success") {
            if (responseData) {
              setLoading(false);
              var storeBio = responseData.companyAboutUs;
              var stripedHtml = storeBio.replace(/<[^>]+>/g, "");
              setProfileData(responseData);
              setProfileData((prevState) => ({
                ...prevState,
                companyAboutUs: stripedHtml,
              }));
            } else {
              setLoading(false);
              setProfileData({});
            }
          } else {
            setLoading(false);
            setProfileData({});
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSaveNote = () => {
    try {
      let inputData = {
        noteName: notes.title,
        noteContent: notes.description,
        vendorID: "2704",
        retailerID: retailerId,
        productIds: "",
        fileIds: "",
      };
      setLoading(true);
      RetailerDataService.AddNewNote(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message == "Success") {
            setLoading(false);
            NotificationManager.success("Note Updated Succssfully.");
          } else {
            setLoading(false);
            NotificationManager.error("Note not Added.");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setVisible(false);
    handleCancel();
  };

  const handleLoadDataNotesList = (id) => {
    try {
      let inputData = {
        retailerID: id,
        dealerID: "2704",
      };
      setLoading(true);
      RetailerDataService.LoadDataNotesList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setLoading(false);
              setNotesData(responseData);
            } else {
              setLoading(false);
              setNotesData([]);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setNotes({
      title: "",
      description: "",
    });
  };

  const columns = [
    {
      title: traslate("Date"),
      dataIndex: "date",
      width: 200,
    },
    {
      title: traslate("Time"),
      dataIndex: "time",
      width: 200,
    },
    {
      title: traslate("BuyerName"),
      dataIndex: "buyername",
    },
    {
      title: traslate("Location"),
      dataIndex: "location",
      width: 200,
    },
  ];

  const data = [
    {
      key: "1",
      date: <span>12/14/2021</span>,
      time: <span>11:25PM</span>,
      buyername: <span>John lewis</span>,
      location: <span>USA</span>,
    },
    {
      key: "2",
      date: <span>12/14/2021</span>,
      time: <span>11:25PM</span>,
      buyername: <span>John lewis</span>,
      location: <span>USA</span>,
    },
  ];

  const columns1 = [
    {
      title: traslate("Collection"),
      dataIndex: "collection",
    },
    {
      title: traslate("Items"),
      dataIndex: "total",
    },
    {
      title: traslate("Active"),
      dataIndex: "total",
    },
    {
      title: traslate("Updated"),
      dataIndex: "modifiedDate",
    },
    {
      title: traslate("Access"),
      dataIndex: "isassigned",
      width: 150,
      render: (item, row) => {
        return (
          <div className="action__btns">
            <Switch
              name="isassigned"
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={row.isassigned === "1" ? true : false}
              onChange={(e) => handleOnChange(row, e, "isassigned")}
            />
          </div>
        );
      },
    },
  ];

  const handleOnChange = (row, e, name) => {
    debugger;
    if (name === "isassigned") {
      const temp = accessData.map((x) => {
        if (x.collectionid === row.collectionid) {
          return { ...x, isassigned: e == true ? "1" : "0" };
        } else return x;
      });
      let saveRow = temp.filter(
        (obj) => obj.collectionid == row.collectionid
      )[0];
      setAccessData(temp);
      console.log(saveRow);
      handleUpdateCollection(saveRow);
    }
  };

  const columns2 = [
    {
      title: traslate("Avatar"),
      dataIndex: "collectionImage",
      render: (index, row) => (
        <div className="action__btns">
          <div className="image__block">
            <img src={row.collectionImage} />
          </div>
        </div>
      ),
    },
    {
      title: traslate("Name"),
      dataIndex: "dealerName",
    },
    {
      title: traslate("Job Title"),
      dataIndex: "jobTitle",
    },
    {
      title: traslate("E-Mail"),
      dataIndex: "dealerEmail",
    },
    {
      title: traslate("Phone No."),
      dataIndex: "dealerPhone",
      width: 150,
    },
  ];

  /*Change tabs starts */

  const handleTabsChange = (e) => {
    setSelectedTab(e);
  };

  const handleAppointmentSave = () => {
    console.log(appointment);
    try {
      let inputData = {
        retailerID: retailerId,
        dealerID: "2704",
        appId: "undefind",
        productIds: "",
        fileIds: "",
        noteId: "0",
        noteDesc: appointment,
        rowid: "/",
      };
      setLoading(true);
      RetailerDataService.SaveUpdateAppointmentNote(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message == "Success") {
            setLoading(false);
            handleLoadRetAppData(retailerId);
            NotificationManager.success("Appointement added Succssfully.");
          } else {
            setLoading(false);
            NotificationManager.error("Appointement not Added.");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setAppointment("");
  };

  const handleLoadRetAppData = (id) => {
    console.log(appointment);
    try {
      let inputData = {
        retailerID: id,
        dealerID: "2704",
      };
      setLoading(true);
      RetailerDataService.LoadRetAppData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setLoading(false);
              setAppointmentData(responseData);
            } else {
              setLoading(false);
              setAppointment([]);
            }
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleNotesChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setNotes({
        ...notes,
        [name]: e,
      });
    } else {
      setNotes({
        ...notes,
        [e.target.name]: e.target.value,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv prodmarkup__secion retailerprof__section">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="workarea__heading mt-0">
                    {" "}
                    {traslate("RetailerProfile")}{" "}
                  </h4>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="prodesc__div">
                    <Tabs
                      defaultActiveKey="Profile"
                      id="uncontrolled-tab-example"
                      className="tab__div mb-3"
                      onSelect={(e) => {
                        handleTabsChange(e);
                      }}
                      activeKey={selectedTab}
                    >
                      <Tab eventKey="Profile" title={traslate("Profile")}>
                        <Spin spinning={loading}>
                          <div className="tab__contentdiv">
                            <div className="col-lg-12">
                              <div className="desc__maindiv">
                                <div className="col-lg-12 p-3">
                                  <div className="col-lg-12">
                                    <h4 className="workarea__heading mt-0 mb-2">
                                      {profileData.dealerCompany
                                        ? profileData.dealerCompany
                                        : null}
                                    </h4>
                                  </div>
                                  <div className="col-lg-12">
                                    <p className="m-0">
                                      {profileData.dealerPhone
                                        ? profileData.dealerPhone
                                        : null}
                                    </p>
                                    <a href="#">
                                      {profileData.websiteAddress
                                        ? profileData.websiteAddress
                                        : null}
                                    </a>
                                  </div>
                                  {/* <div className="col-lg-12 mt-4">
                                    <h4 className="subheading mb-0">Tags</h4>
                                    <p>
                                      Guild Store, American Gem Society, Amber
                                      Jewelry, Buyers Intelligence Group
                                    </p>
                                  </div> */}
                                  <div className="col-lg-12 mt-4">
                                    <h4 className="subheading mb-0">Brands</h4>
                                    <p>
                                      {" "}
                                      {profileData.dealerCompany
                                        ? profileData.dealerCompany
                                        : null}
                                    </p>
                                  </div>
                                  <div className="col-lg-12 mt-4">
                                    <p className="subheading mb-0">
                                      {profileData.dealerAddress ? "1" : "0"}{" "}
                                      Locations
                                    </p>
                                    {profileData.dealerAddress
                                      ? profileData.dealerAddress
                                      : null}
                                  </div>
                                  <div className="col-lg-12 mt-4">
                                    <p className="subheading mb-0">Store Bio</p>
                                    {profileData.companyAboutUs
                                      ? profileData.companyAboutUs
                                      : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Spin>
                      </Tab>
                      <Tab eventKey="Details" title={traslate("Details")}>
                        <div className="tab__contentdiv">
                          <div className="col-lg-12">
                            <div className="desc__maindiv">
                              <div className="col-lg-12 p-3">
                                <div className="col-lg-12">
                                  <h4 className="workarea__heading mt-0 mb-0">
                                    {profileData.dealerCompany
                                      ? profileData.dealerCompany
                                      : null}
                                  </h4>
                                </div>
                                <div className="col-lg-12 mt-4">
                                  <h4 className="workarea__heading mt-0 mb-1">
                                    Years In Business
                                  </h4>
                                  <p>33</p>
                                </div>
                                <div className="col-lg-12 mt-4">
                                  <p className="subheading mb-0">
                                    {profileData.dealerAddress ? "1" : "0"}{" "}
                                    Locations
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="Conversation"
                        title={traslate("Conversation")}
                      >
                        <div className="tab__contentdiv">
                          <div className="desc__maindiv">
                            <div className="col-lg-12 p-3">
                              <div className="col-lg-12 mt-2">
                                <div className="editor__section">
                                  <CKEditor
                                    editor={ClassicEditor}
                                    onReady={(editor) => {
                                      // You can store the "editor" and use when it is needed.
                                      console.log(
                                        "Editor is ready to use!",
                                        editor
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 mt-4">
                                <Button className="primary-btn">
                                  {traslate("Send")}
                                </Button>
                              </div>
                              <div className="col-lg-12">
                                <div className="notedesc__maindiv">
                                  <div className="date__headdiv">
                                    <div>
                                      <span>
                                        76 Minutes ago - Alex Fetanat @{" "}
                                        <span className="text-primary">
                                          GemFind
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="msg__headdiv">
                                    <span>test abc 123</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="Notes" title={traslate("Notes")}>
                        <div className="tab__contentdiv">
                          <div className="desc__maindiv">
                            <div className="col-lg-12 p-3">
                              <div className="col-lg-6 mt-2">
                                <div className="note__headdiv">
                                  <span className="note__txt">2 Notes</span>
                                  <span className="line__txt">|</span>
                                  <span
                                    className="createnote__txt"
                                    onClick={setVisible}
                                  >
                                    Create A New Note
                                  </span>
                                </div>
                              </div>
                              <div className="col-lg-12 mt-5">
                                <div className="notedesc__maindiv">
                                  <div className="date__headdiv">
                                    <div>
                                      <span>test - Thursday, April 21</span>
                                    </div>
                                    <div>
                                      <span
                                        className="edit__txt"
                                        onClick={setVisible}
                                      >
                                        Edit
                                      </span>
                                      <span className="line__txt">|</span>
                                      <span className="del__txt">Delete</span>
                                    </div>
                                  </div>
                                  <div className="msg__headdiv">
                                    <span>test abc 123</span>
                                  </div>
                                </div>
                                <div className="notedesc__maindiv">
                                  <div className="date__headdiv">
                                    <div>
                                      <span>test - Thursday, April 21</span>
                                    </div>
                                    <div>
                                      <span
                                        className="edit__txt"
                                        onClick={setVisible}
                                      >
                                        Edit
                                      </span>
                                      <span className="line__txt">|</span>
                                      <span className="del__txt">Delete</span>
                                    </div>
                                  </div>
                                  <div className="msg__headdiv">
                                    <span>test abc 123</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="Appointments"
                        title={traslate("Appointments")}
                      >
                        <div className="tab__contentdiv">
                          <div className="desc__maindiv">
                            <div className="col-lg-12 p-3">
                              <div className="col-lg-12 col-md-12">
                                <Table
                                  className="appointment__tbl"
                                  columns={columns}
                                  dataSource={data}
                                  scroll={{ x: 600 }}
                                />
                              </div>
                              <div className="col-lg-12 mt-4">
                                <div className="editor__section">
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={appointment}
                                    // config={editorConfiguration}
                                    onReady={(editor) => {
                                      // You can store the "editor" and use when it is needed.
                                      console.log(
                                        "Editor is ready to use!",
                                        editor
                                      );
                                    }}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      setAppointment(data);
                                      console.log({ event, editor, data });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 mt-4">
                                <Button
                                  className="primary-btn"
                                  onClick={handleAppointmentSave}
                                >
                                  {traslate("Send")}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="Access" title={traslate("Access")}>
                        <div className="tab__contentdiv">
                          <div className="desc__maindiv">
                            <div className="col-lg-12 p-3">
                              <div className="col-lg-12 col-md-12">
                                <Table
                                  className="appointment__tbl"
                                  columns={columns1}
                                  dataSource={accessData}
                                  scroll={{ x: 600 }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="Contact" title={traslate("Contact")}>
                        <div className="tab__contentdiv">
                          <div className="desc__maindiv">
                            <div className="col-lg-12 p-3">
                              <div className="col-lg-12 col-md-12">
                                <Table
                                  className="contact__tbl"
                                  columns={columns2}
                                  dataSource={contactData}
                                  scroll={{ x: 600 }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
                <div className="col-lg-12">
                  <Modal
                    title={traslate("Create A New Note")}
                    className="modalretailerprof__section"
                    centered
                    visible={visible}
                    onOk={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                    footer={[
                      <Button key="back" onClick={handleCancel}>
                        {traslate("Cancel")}
                      </Button>,
                      <Button
                        key="submit"
                        type="primary"
                        onClick={handleSaveNote}
                      >
                        {traslate("Submit")}
                      </Button>,
                    ]}
                  >
                    <div className="col-lg-12">
                      <div className="modal__body">
                        <div className="col-lg-12">
                          <div className="form__fields border-0 p-0">
                            <div className="row">
                              <div className="col-lg-12">
                                <input
                                  type="text"
                                  name="title"
                                  placeholder="Enter Title"
                                  value={notes.title}
                                  onChange={handleNotesChange}
                                />
                                <div className="editor__section">
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={notes.description}
                                    // config={editorConfiguration}
                                    onReady={(editor) => {
                                      // You can store the "editor" and use when it is needed.
                                      console.log(
                                        "Editor is ready to use!",
                                        editor
                                      );
                                    }}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      setNotes((prevState) => ({
                                        ...prevState,
                                        description: data,
                                      }));
                                      console.log({ event, editor, data });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RetailerProfile;
