import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Table,
  Modal,
  DatePicker,
  Switch,
  Space,
  Spin,
  Radio,
} from "antd";
import {
  EditOutlined,
  CalendarOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import Highlighter from "react-highlight-words";
import Create_icon from "../../assets/images/create_icon.png";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";
import CatalogJewelryDataService from "../../services/catalog-jewelry.service";
import { NotificationManager } from "react-notifications";
import Moment from "moment";
import traslate from "../../i18n/translate";

const { Option } = Select;

export const ManageCollection = (props) => {
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState("All");
  const [allFilterValue, setAllFilterValue] = useState("");
  const [collectionList, setCollectionList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [state, setState] = useState([]);
  const [delConsoleData, setDelConsoleData] = useState({
    collectionID: "",
  });
  const [disableBtn, setDisableBtn] = useState(true);
  const loginDetials = useSelector((state) => state.loginReducer);
  const [locationState, setLocationState] = useState(false);
  const [dealerMemberTypeName, setDealerMemberTypeName] = useState("");

  useEffect(() => {
    handleGetCollectionsList();
    handleGetBrandListbyDealer();
  }, []);

  useEffect(() => {
    debugger;
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerMemberTypeName(
            loginDetials.loginDetials.responseData.dealerMemberTypeName
          );
        }
      }
    }
  }, []);

  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      if (location.state.key) {
        var newfilterValue = location.state.key;
        if (location.state.key == "Vendor") {
          setLocationState(true);
        }
        setFilterValue(newfilterValue);
        handleGetCollectionsList(location.state.key);
      }
    }
  }, []);

  useEffect(() => {
    if (filterValue === "HomeOnly") {
      setAllFilterValue("");
    }
    if (filterValue === "VendorExtension") {
      setAllFilterValue("");
    }
  });

  const handleApplyFilters = () => {
    handleGetCollectionsList();
  };

  const handleGetBrandListbyDealer = () => {
    try {
      let inputData = {
        dealerID: 720,
      };
      setLoading(true);
      console.log(inputData);
      CatalogJewelryDataService.GetBrandListbyDealer(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setBrandList(responseData);
            setLoading(false);
          } else {
            setBrandList([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleGetCollectionsList = (key) => {
    try {
      let inputData = {
        dealerID: 720,
        collectionType: location.state ? key : filterValue,
        brandID: 0,
        sortBy: allFilterValue,
      };
      setLoading(true);
      console.log(inputData);
      CatalogJewelryDataService.GetCollectionsList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            setCollectionList(responseData);
            setLoading(false);
            setDisableBtn(true);
          } else {
            setCollectionList([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function onSearch(val) {
    console.log("search:", val);
  }

  const handleDeleteModal = (row) => {
    setIsModalVisible(true);
    setDelConsoleData({
      collectionID: row.collectionID,
    });
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDelConsoleData({});
  };

  const DeleteConsoleUploadData = () => {
    debugger;
    try {
      let inputData = {
        collectionID: parseInt(delConsoleData.collectionID),
      };

      CatalogJewelryDataService.DeleteCollectionStatusById(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(true);
          if (message == "Success") {
            NotificationManager.success("Record Deleted Succssfully.");
            handleGetCollectionsList();
          } else {
            NotificationManager.error("Record Not Deleted.");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    handleCancel();
  };

  const handleIsSelectChange = (saveRow) => {
    debugger;
    try {
      let inputData = {
        collectionID: parseInt(saveRow.collectionID),
        dealerID: parseInt(saveRow.dealerID),
        status: parseInt(saveRow.isSelected === false ? 0 : 1),
      };
      CatalogJewelryDataService.UpdateVisibleStatus(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(true);
          if (message == "Success") {
            NotificationManager.success("Record Updated Succssfully.");
            handleGetCollectionsList();
          } else {
            NotificationManager.error("Record Not Updated.");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCollectionVendorEnable = (saveRow) => {
    try {
      let inputData = {
        collectionID: parseInt(saveRow.collectionID),
        retailerID: parseInt(saveRow.dealerID),
        enable: saveRow.enabled === false ? 0 : 1,
      };
      CatalogJewelryDataService.ChangeCollectionVendorEnable(inputData)
        .then((response) => {
          var message = response.data.message;
          setLoading(true);
          if (message == "Success") {
            NotificationManager.success("Record Updated Succssfully.");
            handleGetCollectionsList();
          } else {
            NotificationManager.error("Record Not Updated.");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handlePricingVendorChange = (saveRow) => {
    console.log(saveRow);
    debugger;
    try {
      let inputData = {
        collectionID: parseInt(saveRow.collectionID),
        retailerID: parseInt(saveRow.dealerID),
        override: parseInt(
          saveRow.overrideEnabled === false
            ? 0
            : saveRow.markupOverride
            ? saveRow.markupOverride
            : saveRow.override
        ),
        pricing: parseInt(saveRow.pricing === false ? 0 : 1),
        overrideValue: saveRow.overrideEnabled === false ? "0" : "1",
      };
      CatalogJewelryDataService.ChangeCollectionVendorPricing(inputData)
        .then((response) => {
          var message = response.data.message;
          setLoading(true);
          if (message == "Success") {
            NotificationManager.success("Record Updated Succssfully.");
            handleGetCollectionsList();
          } else {
            NotificationManager.error("Record Not Updated.");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (row, e, name) => {
    debugger;
    if (name === "isSelected") {
      const temp = collectionList.map((x) => {
        if (x.collectionID === row.collectionID) {
          return { ...x, isSelected: e };
        } else return x;
      });
      let saveRow = temp.filter(
        (obj) => obj.collectionID == row.collectionID
      )[0];
      setCollectionList(temp);
      handleIsSelectChange(saveRow);
    }
    if (name === "pricing") {
      const temp = collectionList.map((x) => {
        if (x.collectionID === row.collectionID) {
          return { ...x, pricing: e };
        } else return x;
      });
      let saveRow = temp.filter(
        (obj) => obj.collectionID == row.collectionID
      )[0];
      setCollectionList(temp);
      handlePricingVendorChange(saveRow);
    }
    if (name === "enabled") {
      const temp = collectionList.map((x) => {
        if (x.collectionID === row.collectionID) {
          return { ...x, enabled: e };
        } else return x;
      });
      let saveRow = temp.filter(
        (obj) => obj.collectionID == row.collectionID
      )[0];
      setCollectionList(temp);

      handleCollectionVendorEnable(saveRow);
    }
    if (name === "overrideEnabled") {
      debugger;
      const temp = collectionList.map((x) => {
        if (x.collectionID === row.collectionID) {
          return {
            ...x,
            overrideEnabled: e.target.value === "percentage" ? true : false,
            // override: row.overrideEnabled === false ? "" : row.override,
          };
        } else return x;
      });

      setCollectionList(temp);
    }
    if (name === "markupOverride") {
      const temp = collectionList.map((x) => {
        if (x.collectionID === row.collectionID) {
          return {
            ...x,
            markupOverride: e.target.value,
          };
        } else return x;
      });
      setCollectionList(temp);
    }
  };

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const handleOnSelectChange = (e, name) => {
    setDisableBtn(false);
    if (name == "filterValue") {
      setFilterValue(e);
    }
    if (name == "allFilterValue") {
      setAllFilterValue(e);
    }
  };
  return (
    <div className="form__fields">
      <div className="row magento__mapping">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0 mb-0"> {traslate("ManageCollection")} </h4>
            <button className="primary-btn">
              {" "}
              <img src={Create_icon} alt="" />{" "}
              <Link to={"/addCollection"} className="manage__Link">
              {traslate("CreateCollection")}
              </Link>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="input__block col-md-4">
            <Select
              name="filterValue"
              placeholder="Select Collections"
              optionFilterProp="children"
              className="border__grey"
              onChange={(e) => handleOnSelectChange(e, "filterValue")}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={filterValue}
            >
              <Option value="All"> {traslate("AllCollections")} </Option>
              <Option value="HomeOnly"> {traslate("HouseBrandCollections")} </Option>
              <Option value="Vendor"> {traslate("VendorCollections")} </Option>
              <Option value="VendorExtension"> {traslate("VendorExtensions")} </Option>
            </Select>
          </div>
          {(filterValue === "All") | (filterValue === "Vendor") ? (
            <div className="input__block col-md-4">
              <Select
                name="allFilterValue"
                optionFilterProp="children"
                placeholder="Select Vendor"
                className="border__grey"
                onChange={(e) => handleOnSelectChange(e, "allFilterValue")}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                value={allFilterValue}
              >
                <Option value="">Vendor</Option>
                {brandList.map((item) => {
                  return (
                    <Option key={item.designerName} value={item.designerName}>
                      {item.designerName}
                    </Option>
                  );
                })}
              </Select>
            </div>
          ) : null}
          <div
            className="col-md-4 aplyfilt__btndiv"
            onClick={handleApplyFilters}
          >
            <button
              className={disableBtn ? "disabled-btn" : "primary-btn"}
              disabled={disableBtn}
            >
              {" "}
              <i className="fa fa-search"></i> {traslate("ApplyFilter")}
            </button>
          </div>
        </div>

        <div className="divForm">
          <Spin spinning={loading} size="large">
            <Table
              pagination={{
                showSizeChanger: true,
                responsive: true,
              }}
              columns={[
                {
                  title: traslate("CollectionName"),
                  dataIndex: "collection",
                },
                {
                  title: locationState ? traslate("BrandName") : traslate("Vendor"),
                  dataIndex: "brandName",
                },
                {
                  title: traslate("Category"),
                  dataIndex: "collectionCategory",
                  // width: 100,
                  className: locationState
                    ? "display__tablecell"
                    : "display__none",
                  render: (index, row) => <span>{row.collectionCategory}</span>,
                },
                {
                  title: traslate("Override"),
                  dataIndex: "override",
                  render: (index, row) => (
                    <span>
                      {row.overrideEnabled == false
                        ? "Disabled"
                        : row.override + "%"}
                    </span>
                  ),
                },
                {
                  title: traslate("Items"),
                  dataIndex: "items",
                },
                {
                  title: traslate("Updated"),
                  dataIndex: "modifiedDate",
                  render: (item, row) => (
                    <div>
                      <span>
                        {Moment(row.modifiedDate).format("DD-MM-YYYY")}
                      </span>
                    </div>
                  ),
                },

                {
                  title: traslate("Pricing"),
                  dataIndex: "pricing",
                  width: 100,
                  render: (item, row) => (
                    <div className="action__btns">
                      <Switch
                        name="pricing"
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        checked={row.pricing}
                        onChange={(e) => handleOnChange(row, e, "pricing")}
                      />
                    </div>
                  ),
                },
                {
                  title: traslate("Visibility"),
                  dataIndex: "isSelected",
                  width: 100,
                  render: (item, row) => (
                    <div className="action__btns">
                      <Switch
                        name="isSelected"
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        checked={row.isSelected}
                        onChange={(e) => handleOnChange(row, e, "isSelected")}
                      />
                    </div>
                  ),
                },
                {
                  title: traslate("Enable"),
                  dataIndex: "enabled",
                  // width: 100,
                  className:
                    dealerMemberTypeName == "Vendor"
                      ? "display__tablecell"
                      : "display__none",
                  render: (item, row) => (
                    <div className="action__btns">
                      <Switch
                        name="enabled"
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        checked={row.enabled}
                        onChange={(e) => handleOnChange(row, e, "enabled")}
                      />
                    </div>
                  ),
                },
                {
                  title: traslate("MarkupOverride"),
                  dataIndex: "overrideEnabled",
                  width: 350,
                  render: (item, row) => (
                    <div className="row">
                      <div className="radio__block col-md-6 p-0">
                        <Radio
                          checked={row.overrideEnabled == false}
                          onChange={(e) =>
                            handleOnChange(row, e, "overrideEnabled")
                          }
                          value="disabled"
                          option="Disable"
                        >
                          {traslate("Disable")}
                        </Radio>
                      </div>
                      <div className="radio__block col-md-6 p-0">
                        <Radio
                          checked={row.overrideEnabled == true}
                          onChange={(e) =>
                            handleOnChange(row, e, "overrideEnabled")
                          }
                          value="percentage"
                          option="Percentage"
                        >
                          {traslate("Percentage")}
                        </Radio>
                      </div>
                      <div className="input__block  row mb-0 p-0">
                        <div className="col-md-6">
                          <input
                            type="text"
                            onKeyPress={onKeyPressEvent}
                            disabled={
                              row.overrideEnabled == false ? true : false
                            }
                            onChange={(e) => {
                              e.target.value = e.target.value.slice(0, 3);
                              handleOnChange(row, e, "markupOverride");
                            }}
                            value={
                              row.overrideEnabled == false
                                ? ""
                                : row.markupOverride === undefined
                                ? row.override
                                : row.markupOverride !== "" ||
                                  row.markupOverride === ""
                                ? row.markupOverride
                                : row.override
                            }
                            className={
                              row.overrideEnabled == false ? "disabled" : ""
                            }
                          />
                        </div>

                        <div className="col-md-6">
                          <button
                            className="primary-btn w-100"
                            onClick={() => {
                              handlePricingVendorChange(row);
                            }}
                          >
                            {traslate("Save")}
                          </button>
                        </div>
                      </div>
                    </div>
                  ),
                },
                {
                  title: traslate("Action"),
                  className:
                    filterValue == "All"
                      ? "display__none"
                      : filterValue == "Vendor" && locationState
                      ? "display__tablecell"
                      : filterValue == "HomeOnly"
                      ? "display__tablecell"
                      : filterValue == "VendorExtension"
                      ? "display__tablecell"
                      : "display__none",
                  render: (index, row) => (
                    <div className="action__btns">
                      <div className="image__block">
                        <div>
                          <Link
                            to={"/addCollection"}
                            state={{
                              key: row.collectionID,
                            }}
                          >
                            <img src={Edit_icon} alt="" />
                          </Link>
                        </div>
                      </div>
                      <div className="image__block">
                        <img
                          src={Delete_iconbl}
                          onClick={() => {
                            handleDeleteModal(row);
                          }}
                          alt=""
                        />
                      </div>
                    </div>
                  ),
                },
              ]}
              dataSource={collectionList}
              // scroll={{ x: 1200 }}
            />
          </Spin>
        </div>
        <Modal
          title={traslate("DeleteCollection")}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              {traslate("Cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={DeleteConsoleUploadData}
            >
              {traslate("Delete")}
            </Button>,
          ]}
        >
          {traslate("Areyousure,youwanttodeletethisCollection.")}
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCollection);
