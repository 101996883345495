import axios from "axios";
import config from "./config";

export const http = axios.create({
  baseURL: config.AdminAPIURL,
  headers: {
    "Content-Type": "application/json,multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  },
});
export const httpDealer = axios.create({
  baseURL: config.DealerAPIURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
export const httpCatalog = axios.create({
  baseURL: config.CatalogAPIURL,
  headers: {
    "Content-Type": "application/json,application/octet-stream",
    "Access-Control-Allow-Origin": "*",
  },
});
