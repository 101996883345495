import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Switch,
  Table,
  Menu,
  Dropdown,
  Button,
  Space,
  Tooltip,
} from "antd";
import Filter_icon from "../../../assets/images/filter_icon.png";
import Rectangle from "../../../assets/images/Rectangle.png";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import Close from "../../../assets/images/close.png";
import MultiRangeSlider from "../../contacts/MultiRangeSlider";
import traslate from "../../../i18n/translate";

const JewelrySearch = () => {
  const [productChange, setProductChange] = useState(true);
  const [viewItem, setViewItem] = useState("list");
  const [hideShowFilter, setHideShowFilter] = useState(true);

  const gender = (
    <span>
      {traslate("Genderisnotarequiredfieldforvendordata,sobyleavingthisblank,themajorityofproductresultswillbeconsideredLadies.")}
    </span>
  );

  const collection = (
    <span> {traslate("Pleaseselectavendorbeforechoosingacollection.")} </span>
  );

  const columns = [
    {
      title: (
        <div>
          <input type="checkbox" />
        </div>
      ),
      dataIndex: "checkinpt",
      width: 40,
    },
    {
      title: "",
      dataIndex: "image",
      width: 60,
    },
    {
      title: traslate("Setting#"),
      dataIndex: "settings",
      width: 300,
    },
    {
      title: traslate("Vendor#"),
      dataIndex: "vendor",
    },
    {
      title: traslate("Metal"),
      dataIndex: "metal",
    },
    {
      title: traslate("$Price"),
      dataIndex: "price",
    },
    {
      title: traslate("$Markup"),
      dataIndex: "markup",
    },
  ];

  const data = [
    {
      key: "1",
      checkinpt: <input type="checkbox" />,
      image: <img src={Rectangle} className="prod__img" alt="" />,
      settings: <span>Cathedral Setting 478139</span>,
      vendor: <span>SH1101</span>,
      metal: <span>Platinum</span>,
      price: <span>$95.00</span>,
      markup: <span></span>,
    },

    {
      key: "2",
      checkinpt: <input type="checkbox" />,
      image: <img src={Rectangle} className="prod__img" alt="" />,
      settings: <span>Cathedral Setting 478139</span>,
      vendor: <span>SH1101</span>,
      metal: <span>Platinum</span>,
      price: <span>$95.00</span>,
      markup: <span></span>,
    },

    {
      key: "3",
      checkinpt: <input type="checkbox" />,
      image: <img src={Rectangle} className="prod__img" alt="" />,
      settings: <span>Cathedral Setting 478139</span>,
      vendor: <span>SH1101</span>,
      metal: <span>Platinum</span>,
      price: <span>$95.00</span>,
      markup: <span></span>,
    },

    {
      key: "4",
      checkinpt: <input type="checkbox" />,
      image: <img src={Rectangle} className="prod__img" alt="" />,
      settings: <span>Cathedral Setting 478139</span>,
      vendor: <span>SH1101</span>,
      metal: <span>Platinum</span>,
      price: <span>$95.00</span>,
      markup: <span></span>,
    },
  ];

  const { Option } = Select;

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }
  const handleProductChangeOpen = () => {
    setProductChange(false);
  };

  const handleProductChangeClose = () => {
    setProductChange(true);
  };

  const handleHideShowFilter = () => {
    setHideShowFilter(!hideShowFilter);
  };

  const handleViewChange = (viewname) => {
    setViewItem(viewname);
  };

  return (
    <React.Fragment>
      <div className="allprod_section settings__section form__fields">
        <div className="row">
          <div className="col-md-12">
            <div className="filter_section m-0 p-0">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="workarea__heading mt-0 mb-0">
                      {traslate("Settings")}
                    </h4>
                  </div>
                  <div className="col-md-6 text-right">
                    <button
                      className="primary-btn"
                      onClick={handleHideShowFilter}
                    >
                      {/* <img src={Filter_icon} alt="" /> */}
                      {hideShowFilter ? (
                        <span>
                          {" "}
                          {traslate("ShowFilter")}{" "}
                          <i className="fa fa-angle-down"></i>
                        </span>
                      ) : (
                        <span>
                          {" "}
                          {traslate("HideFilter")}{" "}
                          <i className="fa fa-angle-up"></i>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div
                  className={
                    hideShowFilter
                      ? "form__fields filter_inpsection hide"
                      : "form__fields filter_inpsection"
                  }
                >
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {traslate("Category")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Select Category"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="jack">Jack</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="tom">Tom</Option>
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {traslate("MaterialType")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Select Material Type"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="jack">Jack</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="tom">Tom</Option>
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label>
                            {" "}
                            {traslate("Gender")}
                            <Tooltip placement="right" title={gender}>
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Select Gender"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="male">All</Option>
                            <Option value="female">Child(0)</Option>
                            <Option value="female">Ladies(2344)</Option>
                            <Option value="female">Mens(1234)</Option>
                            <Option value="female">Teen(4)</Option>
                            <Option value="female">Unisex(2172)</Option>
                            <Option value="female">Womens(5576)</Option>
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {traslate("Vendor")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Select Vendor"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="male">Abc</Option>
                            <Option value="female">Xyz</Option>
                            <Option value="male">Abc</Option>
                            <Option value="female">Xyz</Option>
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {traslate("MaterialColor")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Select Material Color"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="jack">Jack</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="tom">Tom</Option>
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label>
                            {" "}
                            {traslate("Collection")}
                            <Tooltip placement="right" title={collection}>
                              <img src={UnionIcon} alt="" />
                            </Tooltip>
                          </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Select Collection"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="male">Abc</Option>
                            <Option value="female">Xyz</Option>
                            <Option value="male">Abc</Option>
                            <Option value="female">Xyz</Option>
                          </Select>
                        </div>
                      </div>
                      {/* <div className="col-lg-3 col-md-6">
                                                <div className="sliderrange__maindiv">
                                                    <label> Price </label>
                                                    <div className="col-md-12 mt-3">
                                                    <MultiRangeSlider
                                                        min={0}
                                                        max={10000}
                                                        onChange={({ min, max }) =>
                                                            console.log(`min = ${min}, max = ${max}`)
                                                        }
                                                    />
                                                    </div>
                                                    
                                                </div>
                                            </div> */}
                    </div>
                    <div className="row">
                      <div className="col-md-12 filtbtn_div">
                        <button className="primary-btn mr-2">
                          {" "}
                          {traslate("ResetFilters")}
                        </button>
                        <button className="primary-btn"> {traslate("ApplyFilters")} </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-4">
                <div className="settings__tbl">
                  <Table
                    columns={columns}
                    dataSource={data}
                    scroll={{ y: 300 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JewelrySearch;
