import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Table,
  Modal,
  DatePicker,
  Switch,
  Space,
} from "antd";
import {
  EditOutlined,
  CalendarOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";

import userIcon from "../../../assets/images/daimond-bg.png";
import traslate from "../../../i18n/translate";

const { Option } = Select;

export const Dealers = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const columns = [
    {
      title: traslate("VendorName"),
      dataIndex: "vendorName",
    },
    {
      title: traslate("MinedDiamonds"),
      dataIndex: "minedDiamonds",
    },
    {
      title: traslate("LabGrown"),
      dataIndex: "labGrown",
    },
    {
      title: traslate("ColorDiamonds"),
      dataIndex: "colorDiamonds",
    },
    {
      title: traslate("Updated"),
      dataIndex: "updated",
    },
    {
      title: (
        <div className="text-center">
          <input type="checkbox" />
        </div>
      ),
      width: 50,
      render: () => (
        <div className="action__btns">
          <input type="checkbox" />
        </div>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      vendorName: "Your Diamond Inventory",
      minedDiamonds: "511",
      labGrown: "0",
      colorDiamonds: "1607",
      updated: "04/06/2012",
    },
    {
      key: "2",
      vendorName: "Diamond Trading Company",
      minedDiamonds: "521",
      labGrown: "0",
      colorDiamonds: "0",
      updated: "04/06/2012",
    },
    {
      key: "3",
      vendorName: "Apex Diamonds Inc",
      minedDiamonds: "1211",
      labGrown: "1800",
      colorDiamonds: "0",
      updated: "04/06/2012",
    },
    {
      key: "4",
      vendorName: "Real Gems Corp",
      minedDiamonds: "1011",
      labGrown: "1000",
      colorDiamonds: "1200",
      updated: "04/06/2012",
    },
  ];

  return (
    <div className="form__fields">
      <div className="row magento__mapping">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0 mb-0"> {traslate("Dealers")} </h4>
          </div>
        </div>
        <div className="divForm">
          <Table columns={columns} dataSource={data} scroll={{ x: 600 }} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dealers);
