import { httpDealer } from "../helper/http-common";
class RetailerDataService {
  GetMyRetailerList(inputData) {
    return httpDealer.post("/Retailer/GetMyRetailerList", inputData);
  }
  EnableDisableIsRestricted(inputData) {
    return httpDealer.post("/Retailer/EnableDisableIsRestricted", inputData);
  }
  EnableDisablePrice(inputData) {
    return httpDealer.post("/Retailer/EnableDisablePrice", inputData);
  }
  EnableDisableMarkup(inputData) {
    return httpDealer.post("/Retailer/EnableDisableMarkup", inputData);
  }
  EnableDisableRingBuilder(inputData) {
    return httpDealer.post("/Retailer/EnableDisableRingBuilder", inputData);
  }
  EnableDisableIsAutoPermission(inputData) {
    return httpDealer.post(
      "/Retailer/EnableDisableIsAutoPermission",
      inputData
    );
  }
  EnableDisablePurchaseOrder(inputData) {
    return httpDealer.post("/Retailer/EnableDisablePurchaseOrder", inputData);
  }
  GetRetailerProfile(inputData) {
    return httpDealer.post("/Retailer/GetRetailerProfile", inputData);
  }
  GetALLRetailerList(inputData) {
    return httpDealer.post("/Retailer/GetALLRetailerList", inputData);
  }
  GetPendingRequests(inputData) {
    return httpDealer.post("/Retailer/GetPendingRequests", inputData);
  }
  RetailerContactDetail(inputData) {
    return httpDealer.post("/Retailer/RetailerContactDetail", inputData);
  }
  GetRetailerCollectionAccess(inputData) {
    return httpDealer.post("/Retailer/GetRetailerCollectionAccess", inputData);
  }
  UpdateCollection(inputData) {
    return httpDealer.post("/Retailer/UpdateCollection", inputData);
  }
  SaveUpdateAppointmentNote(inputData) {
    return httpDealer.post("/Retailer/SaveUpdateAppointmentNote", inputData);
  }
  LoadRetAppData(inputData) {
    return httpDealer.post("/Retailer/LoadRetAppData", inputData);
  }
  LoadDataNotesList(inputData) {
    return httpDealer.post("/Retailer/LoadDataNotesList", inputData);
  }
  AddNewNote(inputData) {
    return httpDealer.post("/Retailer/AddNewNote", inputData);
  }
}

export default new RetailerDataService();
