import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Modal,
  Table,
} from "antd";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AdminCampaignsService from "../../../services/admin-campaigns.service";
import traslate from "../../../i18n/translate";

const ComposeMail = () => {
  const initialState = {
    ComposeID: null,
    FromName: null,
    FromEmail: null,

    Subject: null,
    Summary: null,
    Error: {},
  };
  const [state, setState] = useState(initialState);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [campaignDropList, setcampaignDropList] = useState([]);
  const { Option } = Select;
  useEffect(() => {
    handleGetCampaignList();
  }, []);

  const onChange = (e, name) => {
    debugger;
    let tempState = state;
    tempState[name] = e.target.value;
    tempState.Error[name] = null;
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const onChangeDrop = (e, name) => {
    let tempState = state;
    tempState[name] = e;
    tempState.Error[name] = null;
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const handleGetCampaignList = () => {
    debugger;

    try {
      AdminCampaignsService.GetCampaignList()
        .then((response) => {
          debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            var data = JSON.parse(responseData);
            setcampaignDropList(data.items);
          } else {
            setcampaignDropList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeEditor = (e, name) => {
    let tempState = state;
    tempState[name] = e.getData();
    tempState.Error[name] = null;
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };
  const handleValidation = () => {
    debugger;
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    var isValid = true;
    if (!state.ComposeID) {
      state.Error["ComposeID"] = "Please select compose.";
      isValid = false;
    }
    if (!state.FromName) {
      state.Error["FromName"] = "Please enter name.";
      isValid = false;
    } else if (!state.FromName.trim()) {
      state.Error["FromName"] = "Please enter name.";
      isValid = false;
    }
    if (!state.FromEmail) {
      state.Error["FromEmail"] = "Please enter email.";
      isValid = false;
    } else if (!state.FromEmail.trim()) {
      state.Error["FromEmail"] = "Please enter email.";
      isValid = false;
    } else if (regex.test(state.FromEmail) === false) {
      state.Error["FromEmail"] = "Email is not valid.";
      isValid = false;
    }
    if (!state.Subject) {
      state.Error["Subject"] = "Please enter subject.";
      isValid = false;
    } else if (!state.Subject.trim()) {
      state.Error["Subject"] = "Please enter subject.";
      isValid = false;
    }
    if (!state.Summary) {
      state.Error["Summary"] = "Please enter summary.";
      isValid = false;
    } else if (!state.Summary.trim()) {
      state.Error["Summary"] = "Please enter summary.";
      isValid = false;
    }

    setState((prevState) => ({
      ...prevState,
      ...state,
    }));

    return isValid;
  };

  const handleSendCampaignMail = () => {
    debugger;
    if (handleValidation()) {
      setSubmitLoading(true);
      let inputData = {
        campaignID: state.ComposeID,
        fromName: state.FromName,
        to: state.ToEmail,
        fromEmail: state.FromEmail,
        subject: state.Subject,
        message: state.Summary,
      };
      try {
        AdminCampaignsService.SendCampaignMail(inputData)
          .then((response) => {
            setSubmitLoading(false);
            debugger;
            var message = response.data.message;
            var responseData = response.data.responseData;
            if (message == "Success") {
              alert(responseData);
            } else {
              alert(message);
            }
          })
          .catch((error) => {
            setSubmitLoading(false);
            console.log(error);
          });
      } catch (error) {
        setSubmitLoading(false);
        console.log(error);
      }
    }
  };

  function onSearch(val) {
    console.log("search:", val);
  }

  return (
    <React.Fragment>
      <div className="compmail__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {" "}
                {traslate("Compose")}{" "}
              </h4>
            </div>

            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-3 mt-4">
                  <div className="input__block">
                    <label>
                      {" "}
                      {/* {traslate("SelectCompose")} :  */}
                      Select Campaign 
                      <span>*</span>
                    </label>
                    <Select
                      className="border__grey"
                      showSearch
                      placeholder="Select Campaign"
                      optionFilterProp="children"
                      value={state.ComposeID}
                      onChange={(e) => onChangeDrop(e, "ComposeID")}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="">Select Campaign</Option>
                      {campaignDropList.map((item, i) => {
                        return <Option value={item.id}>{item.name}</Option>;
                      })}
                    </Select>
                    {state.Error ? (
                      <div className="text-danger">
                        {state.Error["ComposeID"]}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-3 mt-4">
                  <div className="input__block">
                    <label>
                      {" "}
                      {traslate("FromName")}  <span>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      onChange={(e) => onChange(e, "FromName")}
                      value={state.FromName}
                    />
                    {state.Error ? (
                      <div className="text-danger">
                        {state.Error["FromName"]}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-3 mt-4">
                  <div className="input__block">
                    <label>
                      {" "}
                      {traslate("FromEmail")}  <span>*</span>
                    </label>
                    <input
                      type="email"
                      placeholder="Enter Email"
                      onChange={(e) => onChange(e, "FromEmail")}
                      value={state.FromEmail}
                    />
                    {state.Error ? (
                      <div className="text-danger">
                        {state.Error["FromEmail"]}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-3 mt-4">
                  <div className="input__block">
                    <label>
                      {" "}
                      {traslate("TypeYourSubject")} <span>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Your Subject"
                      onChange={(e) => onChange(e, "Subject")}
                      value={state.Subject}
                    />
                    {state.Error ? (
                      <div className="text-danger">
                        {state.Error["Subject"]}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="editor__section">
                {state.Error ? (
                  <div className="text-danger">{state.Error["Summary"]}</div>
                ) : null}
                <CKEditor
                  editor={ClassicEditor}
                  data={state.Summary}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  // onChange={ ( event, editor ) => {
                  //     const data = editor.getData();
                  //     console.log( { event, editor, data } );
                  // } }
                  onChange={(event, editor) =>
                    onChangeEditor(editor, "Summary")
                  }
                />
              </div>
            </div>
            <div className="col-lg-12 mt-4">
              <div className="compmail__btndiv">
                <Button
                  className="primary-btn"
                  onClick={handleSendCampaignMail}
                  loading={submitLoading}
                >
                  {" "}
                  {traslate("Send")}{" "}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ComposeMail;
