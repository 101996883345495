import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Checkbox, Switch, Table, Spin } from "antd";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../../i18n/translate";

const DiamondRapNetPermission = () => {
  const [diamondRapNetPermissionData, setDiamondRapNetPermission] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);

  useEffect(() => {
    GetDiamondImportRightsList();
  }, []);

  const GetDiamondImportRightsList = () => {
    try {
      setLoading(true);
      AdminToolsDataService.GetDiamondImportRightsList()
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setDiamondRapNetPermission(responseData);
          } else {
            setDiamondRapNetPermission([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleOnChange = (row, e, name) => {
    setDisableBtn(false);
    // if (name === "completed") {
    //   const temp = (data1.filter((x) => x.id === row.id)[0].completed = e);
    //   console.log(temp);
    // }
    // if (name === "completed") {
    //   const temp = (data1.map((x) => x.id === row.id)[0].completed = e);
    //   console.log(temp);
    // }

    if (name === "enabled") {
      const temp = diamondRapNetPermissionData.map((x) => {
        if (x.dealerID === row.dealerID) {
          return { ...x, enabled: e };
        } else return x;
      });
      setDiamondRapNetPermission(temp);
    }
  };

  const handleSubmit = () => {
    debugger;
    var selectedList = [];
    selectedList = diamondRapNetPermissionData.map((x) => {
      return x;
    });
    try {
      setLoading(true);
      AdminToolsDataService.SaveDiamondImportRights(selectedList)
        .then((response) => {
          var message = response.data.message;
          setLoading(false);
          if (message == "Success") {
            NotificationManager.success("Data Updated Successfully.");
            GetDiamondImportRightsList();
          } else {
            NotificationManager.success("Data Not Updated.");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    console.log(diamondRapNetPermissionData);
    setDisableBtn(true);
  };

  return (
    <React.Fragment>
      <div className="diamond_section">
        <div className="form__fields">
          <div className="row">
            <div className="col-md-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {traslate("Enable3rdPartyDiamondImports")}
              </h4>
            </div>
            <div className="col-md-12 mt-4">
              <div className="dealer_tbldiv">
                <Spin spinning={loading} size="large">
                  <Table
                    columns={[
                      {
                        dataIndex: "dealerID",
                        title: traslate("DealerID"),
                        width: 120,
                      },
                      {
                        dataIndex: "dealerName",
                        title: traslate("DealerName"),
                      },
                      {
                        dataIndex: "dealerCompany",
                        title: traslate("DealerCompany"),
                      },
                      {
                        dataIndex: "enabled",
                        title: traslate("Enabled"),
                        width: 120,
                        Key: "completed",
                        render: (item, row) => {
                          return (
                            <Switch
                              checkedChildren="Yes"
                              unCheckedChildren="No"
                              checked={row.enabled}
                              onChange={(e) =>
                                handleOnChange(row, e, "enabled")
                              }
                            />
                          );
                        },
                      },
                    ]}
                    dataSource={diamondRapNetPermissionData}
                    // scroll={{ y: 300 }}
                  />
                </Spin>
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <div className="dealer_btndiv">
                <button
                  onClick={handleSubmit}
                  className={
                    disableBtn ? "disabled-btn mt-3" : "primary-btn mt-3"
                  }
                  disabled={disableBtn}
                >
                  {traslate("Save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiamondRapNetPermission;
