import React, { useState, useEffect } from "react";
import { Select, Table, Spin } from "antd";
import { Image } from "../../../helper/ImagesImport";
import Create_icon from "../../../assets/images/create_icon.png";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../../i18n/translate";
const AddNewRetailerToCore = () => {
  const [retailerData, setRetailer] = useState({
    core: "",
    retailerId: "",
  });
  const [validations, setValidations] = useState({
    coreVal: "",
    retailerIdVal: "",
  });
  const [solarList, setSolarList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    handleGetSolarCoreClientList();
  }, []);

  const handleGetSolarCoreClientList = () => {
    try {
      setLoading(true);
      AdminToolsDataService.GetRetailerCoreList()
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setSolarList(responseData);
          } else {
            setSolarList([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const { Option } = Select;

  function onSearch(val) {
    console.log("search:", val);
  }

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setRetailer({
        ...retailerData,
        [name]: e,
      });
    } else {
      setRetailer({
        ...retailerData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    console.log(retailerData);
    try {
      let inputData = {
        retailerID: retailerData.retailerId,
        coreName: retailerData.core,
      };
      setLoading(true);
      AdminToolsDataService.SaveRetailerCore(inputData)
        .then((response) => {
          var message = response.data.message;
          setLoading(false);
          if (message == "Success") {
            NotificationManager.success("Solar Client Added Successfully.");
            handleGetSolarCoreClientList();
          } else {
            NotificationManager.success("Solar Client Not Added.");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setRetailer({
      core: "",
      retailerId: "",
    });
    setValidations({
      coreVal: "",
      retailerIdVal: "",
    });
  };

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const handleValidation = () => {
    debugger;
    const { core, retailerId } = retailerData;
    const validations = { coreVal: "", retailerIdVal: "" };

    let isValid = true;

    if (!retailerId.trim()) {
      isValid = false;
      validations.retailerIdVal = "Retailer Id is compulsory";
    }
    if (retailerId) {
      isValid = handleGetDealerDetails();
      if (isValid == false) {
        validations.retailerIdVal = "Retailer Id is Invalid";
      } else {
        validations.retailerIdVal = "";
      }
    }
    if (!core) {
      isValid = false;
      validations.coreVal = "Kindly select the core ";
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const handleGetDealerDetails = (e) => {
    let isValid = true;
    try {
      let inputData = {
        dealerID: retailerData.retailerId,
      };
      setLoading(true);
      AdminToolsDataService.GetDealerDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            debugger;
            if (responseData == []) {
              isValid = false;
            } else {
              isValid = true;
            }
          } else {
            console.log(123);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    return isValid;
  };

  console.log(solarList);
  const { core, retailerId } = retailerData;
  const { coreVal, retailerIdVal } = validations;
  return (
    <React.Fragment>
      <div className="newretailer_section SolrCore">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {traslate("AddNewRetailerToCore")}
              </h4>
              {/* <img src={Image} alt="" /> */}
            </div>
            <div className="col-lg-3 mt-4">
              <div className="input__block">
                <label>
                  {" "}
                  {traslate("SelectCore")} <span className="mandatory">*</span>
                </label>
                <Select
                  value={core ? core : null}
                  name="core"
                  showSearch
                  placeholder="Select Core"
                  optionFilterProp="children"
                  onChange={(e) => handleOnChange(e, "select", "core")}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  className={coreVal ? "border__red" : "border__grey"}
                >
                  <Option value="RetailerCore1">RetailerCore 1</Option>
                  <Option value="RetailerCore2">RetailerCore 2</Option>
                  <Option value="RetailerCore3">RetailerCore 3</Option>
                  <Option value="RetailerCore4">RetailerCore 4</Option>
                  <Option value="RetailerCore5">RetailerCore 5</Option>
                  <Option value="RetailerCore6">RetailerCore 6</Option>
                  <Option value="RetailerCore7">RetailerCore 7</Option>
                  <Option value="RetailerCore8">RetailerCore 8</Option>
                  <Option value="RetailerCore9">RetailerCore 9</Option>
                  <Option value="RetailerCore10">RetailerCore 10</Option>
                  <Option value="RetailerCore11">RetailerCore 11</Option>
                  <Option value="RetailerCore12">RetailerCore 12</Option>
                  <Option value="RetailerCore13">RetailerCore 13</Option>
                </Select>
                <div>
                  {coreVal && <p className="error-color-red">{coreVal}</p>}
                </div>
              </div>
            </div>

            <div className="col-lg-3 mt-4">
              <div className="input__block">
                <label>
                  {" "}
                  {traslate("EnterRetailerID")}{" "}
                  <span className="mandatory">*</span>{" "}
                </label>
                <input
                  value={retailerId}
                  type="text"
                  name="retailerId"
                  min="0"
                  placeholder="Enter Retailer ID"
                  onChange={(e) => {
                    e.target.value = e.target.value.slice(0, 5);
                    handleOnChange(e);
                  }}
                  onKeyPress={onKeyPressEvent}
                  className={retailerIdVal && "border__red"}
                />
                <div>
                  {retailerIdVal && (
                    <p className="error-color-red">{retailerIdVal}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-3 mt-4">
              <div className="retailer__btndiv">
                <button onClick={handleSubmit} className="primary-btn">
                  {/* <img src={Create_icon} alt="" />  */}
                  {traslate("Add")}
                </button>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="retailer_tbldiv">
                <Spin spinning={loading} size="large">
                  <Table
                    columns={[
                      {
                        dataIndex: "id",
                        title: traslate("ID"),
                        width: 100,
                      },
                      {
                        dataIndex: "coreName",
                        title: traslate("CoreName"),
                      },
                      {
                        dataIndex: "retailerID",
                        title: traslate("RetailerID"),
                      },
                      {
                        dataIndex: "dealerCompany",
                        title: traslate("RetailerName"),
                      },
                    ]}
                    dataSource={solarList}
                    scroll={{ x: 600 }}
                  />
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddNewRetailerToCore;
