import React, { useState,useEffect } from "react";
import { Input, Row, Col, Select, Checkbox, Button, Switch } from "antd";
import {Tabs, Tab,} from 'react-bootstrap';
import Noun_arrow from "../../assets/images/noun_arrow.png";
import Rectangle from "../../assets/images/Rectangle.png";
import { Accordion } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import CatalogJewelryDataService from "../../services/catalog-jewelry.service";
import { NotificationManager } from "react-notifications";

const Catlog = () => {
    const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
    const location = useLocation();
    const [switchValue, setswitchValue] = useState(true);
    const { Option } = Select;
    const [DealerId, setDealerId] = useState(null);
    const [ProductDetails, setProductDetails] = useState({});

    useEffect(() => {
        debugger;
        if (location.state) {
          if (location.state.productData) {

            setProductDetails(location.state.productData);
            console.log(ProductDetails);
            if (loginDetials) {
                if (loginDetials.responseData) {
                  if (loginDetials.responseData.logInSucceeded) {
                    setDealerId(loginDetials.responseData.dealerId);
                  }
                }
              }

          }
        } else {
         
        }
      }, []);



      const handleChangeJewelryPricingType = (id,Status) => {
        debugger;
        let inputData = {
          dealerID: id + "" || DealerId + "",
          productID: ProductDetails.gfInventoryID+"",
          status:Status,
          currentLoggedIn:id + "" || DealerId + "",
        };
      
        try {
          CatalogJewelryDataService.ChangeJewelryPricingType(inputData)
            .then((response) => {
              debugger;
              var message = response.data.message;
              var responseData = response.data.responseData;
             
              if (message == "Success") {
                NotificationManager.success("Updated Successfully.");
              
              } else {
                NotificationManager.error("Data Not Updated.");
              }
            })
            .catch((error) => {
          
              console.log(error);
            });
        } catch (error) {
      
          console.log(error);
        }
    
      };

    function onChange(value) {
        console.log(`selected ${value}`);
    }

    function onSearch(val) {
        console.log("search:", val);
    }

    function onChange(e) {
        console.log(`checked = ${e.target.checked}`);
    }

    const handleSwitch = (e) => {
        debugger;
        let value="0";
        if(e){
            value="1";
        }
        setProductDetails((prevState) => ({
            ...prevState,
            showPricing:e,
          }));
       
        //setswitchValue(!switchValue);
        handleChangeJewelryPricingType(DealerId,value)
    };
    return (
        <React.Fragment>
        <div className="prodetail__section">
            <div className="col-md-12">
                <div className="prod__headdiv">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <h3 className="subheading"> <a href="Allproducts"><img src={Noun_arrow} alt="" /></a> Product Details</h3>
                        </div>
                        <div className="col-lg-6 col-md-6 prod__switchdiv">
                            <span className="price__txt"> Pricing </span>
                            <Switch
                                checkedChildren="ON"
                                unCheckedChildren="OFF"
                               
                                checked={ProductDetails.showPricing}
                                onChange={(e)=>handleSwitch(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <div className="prod__maindiv">
                    <div className="row">
                        <div className="col-lg-3 col-md-3">
                            <div className="prodimg__div">
                                <img src={ProductDetails.imagepath} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-9">
                            <div className="prodesc__div">
                                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="tab__div mb-3">
                                    <Tab eventKey="home" title="Items Details">
                                        <div className="tab__contentdiv">
                                            <div className="head__div">
                                                <h4>Jewelery Characteristics</h4>
                                            </div>
                                            <div className="desc__maindiv">
                                                <div className="desc__div">
                                                    <span className="head__txt">Inventory #</span>
                                                    <span className="desc__txt">{ProductDetails.gfInventoryID}</span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">SKU #</span>
                                                    <span className="desc__txt">{ProductDetails.retailerStockNumber}</span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Category </span>
                                                    <span className="desc__txt">{ProductDetails.categoryName}</span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Style </span>
                                                    <span className="desc__txt">{ProductDetails.styleNumber}</span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Product Name</span>
                                                    <span className="desc__txt">{ProductDetails.productName}</span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Collection</span>
                                                    <span className="desc__txt">{ProductDetails.collectionName}</span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Gender </span>
                                                    <span className="desc__txt"></span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Setting </span>
                                                    <span className="desc__txt"></span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Metal Type </span>
                                                    <span className="desc__txt">{ProductDetails.metalType}</span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Metal Color </span>
                                                    <span className="desc__txt">{ProductDetails.metalColor}</span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Cost ($) </span>
                                                    <span className="desc__txt">{ProductDetails.wholesaleprice}</span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Retail Price </span>
                                                    <span className="desc__txt">{ProductDetails.retailPrice}</span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">MSRP ($) </span>
                                                    <span className="desc__txt">{ProductDetails.msrp}</span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Width (mm) </span>
                                                    <span className="desc__txt"></span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Thickness (mm) </span>
                                                    <span className="desc__txt"></span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Length (in) </span>
                                                    <span className="desc__txt"></span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Weight (gm) </span>
                                                    <span className="desc__txt"></span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Last Updated On </span>
                                                    <span className="desc__txt"></span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Description </span>
                                                    <span className="desc__txt"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="profile" title="Dealer Info">
                                        <div className="tab__contentdiv">
                                            <div className="head__div">
                                                <h4>Dealer Information</h4>
                                            </div>
                                            <div className="desc__maindiv">
                                                <div className="desc__div">
                                                    <span className="head__txt">Dealer ID</span>
                                                    <span className="desc__txt">{ProductDetails.dealerID}</span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Company</span>
                                                    <span className="desc__txt"></span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Contact Person </span>
                                                    <span className="desc__txt"></span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Address </span>
                                                    <span className="desc__txt"></span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">City</span>
                                                    <span className="desc__txt"></span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">State</span>
                                                    <span className="desc__txt"></span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Country</span>
                                                    <span className="desc__txt"></span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Phone</span>
                                                    <span className="desc__txt">{ProductDetails.dealerPhone}</span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">Fax</span>
                                                    <span className="desc__txt"></span>
                                                </div>
                                                <div className="desc__div">
                                                    <span className="head__txt">E-mail</span>
                                                    <span className="desc__txt"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default Catlog
