import React, { useState } from "react";
import { Input, Row, Col, Select, Checkbox, Button, Switch, Tooltip } from "antd";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import traslate from "../../../i18n/translate";

const MasterLinkLegacy = () => {
    const [switchValue, setswitchValue] = useState(true);
    const { Option } = Select;
    const { TextArea } = Input;

    const pagetitle = <span>Change this value to set the TITLE at the top of the search results page. Leave blank for default value.</span>;


    const dpagetitle = <span>Change this value to set the TITLE at the top of the diamond details page. Leave blank for default value.</span>;

    const hyplink1txt = <span>Change this value to set the TEXT of the #1 link at the bottom of the search restuls page. Leave blank for default value (none).</span>;

    const hyplink1url = <span>Change this value to set the URL for the #1 link at the bottom of the search restuls page. Leave blank for default value (none).</span>;

    const hyplink2txt = <span>Change this value to set the TEXT of the #2 link at the bottom of the search restuls page. Leave blank for default value (none).</span>;

    const hyplink2url = <span>Change this value to set the URL for the #2 link at the bottom of the search restuls page. Leave blank for default value (none).</span>;

    const hyplink3txt = <span>Change this value to set the TEXT of the #3 link at the bottom of the search restuls page. Leave blank for default value (none).</span>;

    const hyplink3url = <span>Change this value to set the URL for the #3 link at the bottom of the search restuls page. Leave blank for default value (none).</span>;

    const matchpaired = <span>Change this value to allow your GFLink users to search for matched pairs of stones in addition to single stones (also ALL stones). Leave blank for default value (single stones only).</span>;

    const logorpage = <span>Use this option to Enable (Yes) or Disable (No) display of Logo (i.e. RESULTS PAGE IMAGE URL) on the top of Search Result Page.</span>;

    const erpage = <span>Use this option to Enable (Yes) or Disable (No) display of Logo (i.e. RESULTS PAGE IMAGE URL) on the top of Search Result Page.</span>;

    const rpageimg = <span>Change this value to alter the URL associated with the image displayed at the top of the search results page. Leave blank for default value (none). If you send your image to GemFind to place on a GemFind server, you can put in the filename only.</span>;

    const rpagehypimg = <span>Change this value to alter the actual hyperlink associated with the image displayed at the top of the search results page. Leave blank for default value (none).</span>;

    const dpageimgurl = <span>Change this value to alter the URL associated with the image displayed in the middle of the default diamond details page. Leave blank for default value (none). If you send your image to GemFind to place on a GemFind server, you can put in the filename only.</span>;

    const dlinktxt = <span>Change this value to alter the visible text of the actual hyperlink to the diamond details. Leave blank for default value.</span>;

    const edsrchresult = <span>By default, your search will display basic diamond details (Cut, Color, Clarity, Size, Price & Certificate) on the search results page. You may choose to display more detailed information (Table, Depth, Measurements) as well as the basic information on the search results page. No matter which method you select, each diamond's complete details are always available by clicking 'More Info' on the search results page.</span>;

    const srchbycgrade = <span>You may choose to search by Cut Grade if desired. Table & Depth searching is enabled as well as they are directly related to Cut Grade.</span>;

    const nodisplaypage = <span>Choose the number of diamonds displayed on each page of the search results. For values other than shown, you must contact GemFind directly.</span>;

    const soddsrchform = <span>Choose whether to show more obscure diamond cuts (asscher, old miner) on the search form.</span>;

    const dfsrchform = <span>Choose whether to use the interactive Macromedia Flash search form instead of the regular search form.</span>;

    const dinvpage = <span>Choose whether to use show popup of diamond details on the diamond inveotry page.</span>;

    const sbtndpage = <span>Choose whether to display share button (Face Book, Twitter, Digg etc.) on diamond details page.</span>;

    const newringb = <span>Choose whether to link diamond search with new ring builder.</span>;

    const sringbimg = <span>Choose whether to display Ring Builder image at the right column of new diamond link.</span>;

    function onChange(value) {
        console.log(`selected ${value}`);
    }

    function onSearch(val) {
        console.log("search:", val);
    }

    function onChange(e) {
        console.log(`checked = ${e.target.checked}`);
    }

    const handleSwitch = () => {
        setswitchValue(!switchValue);
    };
    return (
        <React.Fragment>
            <div className="navigation__section dlegacy__section">
                <div className="form__fields">
                    <div className="col-md-12 mt-2">
                        <h4 className="workarea__heading mt-0 mb-0"> {traslate("Legacy")} </h4>
                    </div>

                    <div className="col-lg-12 mt-4">
                        <div className="form__section">

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("Appointments")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            defaultChecked
                                            onChange={handleSwitch}
                                        />
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("Wishlist")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            defaultChecked
                                            onChange={handleSwitch}
                                        />
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("BridalRegistry")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            defaultChecked
                                            onChange={handleSwitch}
                                        />
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("ShoppingCart")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            defaultChecked
                                            onChange={handleSwitch}
                                        />
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("ShowStoreHours")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            defaultChecked
                                            onChange={handleSwitch}
                                        />
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("WebsiteURL")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <input type="textbox" placeholder="" />
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("GoogleAnalyticID")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <input type="textbox" placeholder="" />
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("ShowOnlyMSRPPrices")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            defaultChecked
                                            onChange={handleSwitch}
                                        />
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("MasterlinkTheme")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <Select
                                            className="border__grey selct__inpt"
                                            showSearch
                                            placeholder="Select Theme"
                                            optionFilterProp="children"
                                            onChange={onChange}
                                            onSearch={onSearch}
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Option value="jack">10</Option>
                                            <Option value="lucy">25</Option>
                                            <Option value="tom">30</Option>
                                        </Select>
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("ShowView")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <Select
                                            className="border__grey selct__inpt"
                                            showSearch
                                            placeholder="Select View"
                                            optionFilterProp="children"
                                            onChange={onChange}
                                            onSearch={onSearch}
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Option value="jack">List View</Option>
                                            <Option value="lucy">3 Items View</Option>
                                            <Option value="tom">4 Items View</Option>
                                            <Option value="jack">5 Items View</Option>
                                        </Select>
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("NewMasterLinkUseInIframe?")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            defaultChecked
                                            onChange={handleSwitch}
                                        />
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>


                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("Currency")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <Select
                                            className="border__grey selct__inpt"
                                            showSearch
                                            placeholder="USD"
                                            optionFilterProp="children"
                                            onChange={onChange}
                                            onSearch={onSearch}
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <Option value="jack">USD</Option>
                                            <Option value="lucy">GBP (Pound)</Option>
                                            <Option value="tom">AUD</Option>
                                        </Select>
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("LoginURL")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <input type="textbox" placeholder="" />
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>


                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("ShowWholeSalePriceInternalUseLink?")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            defaultChecked
                                            onChange={handleSwitch}
                                        />
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("ShowAddress/PhoneDetails?")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            defaultChecked
                                            onChange={handleSwitch}
                                        />
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("DiamondSearch/RingbuilderloadfromMasterlink?")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            defaultChecked
                                            onChange={handleSwitch}
                                        />
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("DiamondImageURL")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <input type="textbox" placeholder="" />
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("VendorDetailsopenorclosedbydefaultinnewmasterlink")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <Switch
                                            checkedChildren="Open"
                                            unCheckedChildren="Close"
                                            defaultChecked
                                            onChange={handleSwitch}
                                        />
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("ShowAdditionalPopUpinnewmasterlink")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            defaultChecked
                                            onChange={handleSwitch}
                                        />
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("ShoppingCartpagemessage")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <TextArea rows={2} />
                                        {/* <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip> */}
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("HeartsOnFireLink")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <input type="textbox" placeholder="" />
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("ShowSettingNameatSettingGrid")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            defaultChecked
                                            onChange={handleSwitch}
                                        />
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("ShowRingBuilderwithOneStep")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            defaultChecked
                                            onChange={handleSwitch}
                                        />
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("ShowDefaultRingSize")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <Switch
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            defaultChecked
                                            onChange={handleSwitch}
                                        />
                                        <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("ShopDiamonds")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <TextArea rows={2} />
                                        {/* <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip> */}
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("ShopBridal&Engagement")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <TextArea rows={2} />
                                        {/* <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip> */}
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("ShopDesigners")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <TextArea rows={2} />
                                        {/* <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip> */}
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("ShopJewelry")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <TextArea rows={2} />
                                        {/* <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip> */}
                                    </div>
                                </div>
                            </div>

                            <div className="desc__div">
                                <div className="headtxt__div">
                                    <span className=""> {traslate("ShopWatches")} </span>
                                </div>

                                <div className="inpt__div">
                                    <div className="input__block">
                                        <TextArea rows={2} />
                                        {/* <Tooltip className="ml-1" placement="right">
                                            <img src={UnionIcon} alt="" />
                                        </Tooltip> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default MasterLinkLegacy;
