import React, { useState } from "react";
import { Input, Row, Col, Select, Checkbox, Switch, Table, Menu, Dropdown, Button, Space } from "antd";
import { Tabs, Tab } from "react-bootstrap";



import roundDiamond from "../../assets/images/Round.jpg";
// import colorDiamond from "../../../assets/images/yellow-diamond.jpg";

import { Accordion } from "react-bootstrap";

const { Option } = Select;


const EditProfile = () => {
    return (
        <React.Fragment>
            <div className="prodetail__section">
                <div className="form__fields">
                    <div className="col-md-12">
                        <div className="prod__maindiv app__preview p-0 border-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="workarea__heading mt-0">Company Detail</h4>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="prodesc__div">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="row mt-4">
                                                    <div className="col-md-6">
                                                        <div className="input__block">
                                                            <label>Select Company</label>
                                                            <Select
                                                                className="border__grey"
                                                                placeholder="Company 1"
                                                                optionFilterProp="children"
                                                            >
                                                                <Option value="">Company 1</Option>
                                                                <Option value="">Option 2</Option>
                                                                <Option value="">Option 3</Option>
                                                            </Select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input__block">
                                                            <label>Company Name</label>
                                                            <input type="text" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input__block">
                                                            <label>Contact Name</label>
                                                            <input type="text" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input__block">
                                                            <label>Tag Line</label>
                                                            <input type="text" />
                                                        </div>
                                                    </div>                                                    

                                                    <div className="col-md-6">
                                                        <div className="input__block">
                                                            <label>Phone Number</label>
                                                            <input type="text" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input__block">
                                                            <label>Web Site</label>
                                                            <input type="text" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="input__block">
                                                            <label>Email</label>
                                                            <input type="text" />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="input__block">
                                                            <label>Company Bio</label>
                                                            <textarea
                                                                id="1"
                                                                name="1"                                                                
                                                            ></textarea>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="image__block">
                                                    <h3>Company Name</h3>
                                                    <img src={roundDiamond} />
                                                    <div>
                                                        <button className="primary-btn">Edit</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>





                        </div>

                    </div>



                </div>
            </div>

        </React.Fragment>
    )
}

export default EditProfile
