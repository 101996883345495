import React, { useEffect, useState } from "react";
import { Table, Modal, Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import AdminToolsService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import traslate from "../../../i18n/translate";
const { confirm } = Modal;

const ClientDataMapping = () => {
  const [loading, setLoading] = useState(false);
  const [clientDataMappingList, setClientDataMapList] = useState([]);
  useEffect(() => {
    handleGetClientDataMappingList();
  }, []);
  function showConfirm(id) {
    confirm({
      title: "Set Default Mapping",
      icon: <ExclamationCircleOutlined />,
      content:
        "Are you Sure By Submit Exisitng Mapping replace with Default mapping. ?",
      onOk() {
        handleSetDefaultMapping(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const handleGetClientDataMappingList = () => {
    try {
      setLoading(true);
      AdminToolsService.GetClientDataMappingList()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            setClientDataMapList(responseData);
          } else {
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleSetDefaultMapping = (id) => {
    try {
      setLoading(true);
      let inputData = {
        dealerID: id,
      };
      AdminToolsService.SetDefaultMapping(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            handleGetClientDataMappingList();
            NotificationManager.success("Defualt Mapping Created Successfully.");
          } else {
            NotificationManager.error("Not Set Defualt Mapping.");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <div className="clientdata_section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
              {traslate("ClientDataMapping")} 
              </h4>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="clientdata_tbldiv clientDataMapTable">
                <Table
                  loading={loading}
                  columns={[
                    {
                      title: traslate("DealerID") ,
                      dataIndex: "dealerID",
                      width:100,
                    },
                    {
                      title: traslate("DealerCompany") ,
                      dataIndex: "dealerCompany",
                    },
                    {
                      title: traslate("WebsiteAddress") ,
                      dataIndex: "websiteAddress",
                    },
                    {
                      title: traslate("Upload") ,
                      dataIndex: "upload",
                      width:100,
                      render: (row, item) => {
                        return (
                          <React.Fragment>
                            <div className="action__btns">
                              <div className="image__block">
                                <Link
                                  to={"/uploadProductFile"}
                                  state={{ dealerData: item }}
                                >
                                  <img src={Edit_icon} alt="" />
                                </Link>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      },
                      width: 100
                    },
                    {
                      title: traslate("InboundDataMap") ,
                      dataIndex: "inbound_datamap",
                      width:100,
                      render: (row, item) => {
                        return (
                          <React.Fragment>
                            <div className="action__btns">
                              <div className="image__block">
                                <Link
                                  to={"/DataMaping"}
                                  state={{ dealerID: item.dealerID }}
                                >
                                  <img src={Edit_icon} alt="" />
                                </Link>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      },
                      width: 100
                    },
                    {
                      title: traslate("OutgoingDataMap") ,
                      dataIndex: "outgoing_datamap",
                      width:100,
                      render: (row, item) => {
                        return (
                          <React.Fragment>
                            <div className="action__btns">
                              <div className="image__block">
                                <Link
                                  to={"/outGoingDataMap"}
                                  state={{ dealerID: item.dealerID }}
                                >
                                  <img src={Edit_icon} alt="" />
                                </Link>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      },
                      width: 100
                    },
                    {
                      title: traslate("OutboundSync") ,
                      dataIndex: "outbound_sync",
                      width:100,
                      render: (row, item) => {
                        return (
                          <React.Fragment>
                            <div className="action__btns">
                              <div className="image__block">
                                <Link
                                  to={"/outBoundSync"}
                                  state={{ dealerID: item.dealerID }}
                                >
                                  <img src={Edit_icon} alt="" />
                                </Link>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      },
                      width: 100
                    },
                    {
                      title: traslate("SetDefaultMapping") ,
                      dataIndex: "setdefault_mapping",
                      width:120,
                      render: (row, item) => {
                        return (
                          <React.Fragment>
                            <Button
                              className="primary-btn"
                              onClick={(e) => {
                                showConfirm(item.dealerID);
                              }}
                            >
                              {traslate("Submit")} 
                            </Button>
                          </React.Fragment>
                        );
                      },
                      width: 120
                    },
                  ]}
                  dataSource={clientDataMappingList}
                  // scroll={{ x: 1400 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ClientDataMapping;
