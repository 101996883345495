import { httpCatalog } from "../helper/http-common";
class CatalogDiamondDataService {
  DeleteDiamondByIds(inputData) {
    return httpCatalog.post("/Diamond/DeleteDiamondByIds", inputData);
  }
  GetFancyDiamondList(inputData) {
    return httpCatalog.post("/Diamond/GetFancyDiamondList", inputData); //API for Initial Page load and apply filters
  }
  GetDealersForDiamondCatalogList(inputData) {
    return httpCatalog.post(
      "/Diamond/GetDealersForDiamondCatalogList",
      inputData
    );
  }
  GetCertificatesForDiamondCatalogList(inputData) {
    return httpCatalog.post(
      "/Diamond/GetCertificatesForDiamondCatalogList",
      inputData
    );
  }
  GetOverToneForDiamondCatalogList(inputData) {
    return httpCatalog.post(
      "/Diamond/GetOverToneForDiamondCatalogList",
      inputData
    );
  }
  GetPermissionsForDiamondCatalogList(inputData) {
    return httpCatalog.post(
      "/Diamond/GetPermissionsForDiamondCatalogList",
      inputData
    );
  }

  GetAllDropdownData(inputData) {
    return httpCatalog.post("/Diamond/GetAllDropdownData", inputData);
  }
  GetAllMINEDropDownList(inputData) {
    return httpCatalog.post("/Diamond/GetAllMINEDropDownList", inputData);
  }
  SendInviteMailForDiamonds(inputData) {
    return httpCatalog.post("/Diamond/SendInviteMailForDiamonds", inputData);
  }
  GetAllFancyDropDownList() {
    return httpCatalog.post("/Diamond/GetAllFancyDropDownList");
  }
  GetLocationsForDiamondCatalogList(inputData) {
    return httpCatalog.post(
      "/Diamond/GetLocationsForDiamondCatalogList",
      inputData
    );
  }
  SaveDiamond(inputData) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return httpCatalog.post("/Diamond/SaveDiamond", inputData, {
      headers,
    });
  }
  GetDiamonds(inputData, type) {
    if (type === "LAB") {
      return httpCatalog.post("/Diamond/GetLabGrownDiamondList", inputData);
    } else {
      return httpCatalog.post("/Diamond/GetMinedDiamonds", inputData);
    }
  }
  GetDynamicData(inputData) {
    return httpCatalog.post("/Diamond/GetDynamicData", inputData);
  }

  // Manage Ftp Credentials
  MangeFtpDetails(inputData) {
    return httpCatalog.post("MangeFtp/MangeFtpDetails", inputData);
  }

  // Data Mapping
  GetDiamondMappingDropdownDetails(inputData) {
    return httpCatalog.post(
      "/Mapping/GetDiamondMappingDropdownDetails",
      inputData
    );
  }
  GetDiProductList(inputData) {
    return httpCatalog.post("/Mapping/GetDiProductList", inputData);
  }
  ResetDiamondDataMapping(inputData) {
    return httpCatalog.post("/Mapping/ResetDiamondDataMapping", inputData);
  }
  UploadDiamondMappingFile(inputData) {
    return httpCatalog.post("/Mapping/UploadDiamondMappingFile", inputData);
  }
  GenerateDiamondMappingFile(inputData) {
    return httpCatalog.post("/Mapping/GenerateDiamondMappingFile", inputData);
  }

  GetDiamondColumnsOfDealerUpload(inputData) {
    return httpCatalog.post(
      "/Mapping/GetDiamondColumnsOfDealerUpload",
      inputData
    );
  }
  UpdateDiamondColumnsOfDealerUpload(inputData) {
    return httpCatalog.post(
      "/Mapping/UpdateDiamondColumnsOfDealerUpload",
      inputData
    );
  }
  SaveDiProductList(inputData) {
    return httpCatalog.post("/Mapping/SaveDiProductList", inputData);
  }
}

export default new CatalogDiamondDataService();
