import React from "react";
import ViewMyMinedDiamond from "./ViewMyMinedDiamond";

const ViewMyLabDiamond = () => {
  return (
    <React.Fragment>
      <ViewMyMinedDiamond type="LAB" />
    </React.Fragment>
  );
};

export default ViewMyLabDiamond;
