import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";

import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Spin,
  Modal,
} from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { UserAddOutlined } from "@ant-design/icons";
import traslate from "../../../i18n/translate";
import { useSelector, useDispatch } from "react-redux";

const string = "ABCDEFGHIJKLMNOPQRSTUVXYZ";
const lowerstring = "abcdefghijklmnopqrstuvwxyz";
const numeric = "0123456789";
const punctuation = "!@#$&}{[]:;?><,./-=";

export const CreateNewClient = (props) => {
  const [length, setLength] = useState(10);
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [clientData, setClientData] = useState({
    companyName: "",
    streetAddress: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    contactName: "",
    emailAddress: "",
    phoneNumber: "",
    username: "",
    password: "",
    permissionType: "",
    verified: true,
    retailerStatus: "",
    vendorStatus: "",
  });
  const [validations, setValidations] = useState({
    companyNameVal: "",
    streetAddressVal: "",
    cityVal: "",
    stateVal: "",
    zipcodeVal: "",
    countryVal: "",
    contactNameVal: "",
    emailAddressVal: "",
    phoneNumberVal: "",
    usernameVal: "",
    passwordVal: "",
    permissionTypeVal: "",
    retailerStatusVal: "",
    vendorStatusVal: "",
  });

  const [permisssionTypeMaster, setPermisssionTypeMaster] = useState([]);
  const [retailerStatusMaster, setRetailerStatusMaster] = useState([]);
  const [vendorStatusMaster, setVendorStatusMaster] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [checkData, setCheckData] = useState("");
  const [isCompanyName, setIsCompanyName] = useState(true);
  const [isEmail, setIsEmail] = useState(true);
  const [isUserName, setIsUserName] = useState(true);
  const { Option } = Select;
  const [dealerId, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);

  useEffect(() => {
    debugger;
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
        }
      }
    }
  }, []);

  const onSearch = (val) => {
    console.log("search:", val);
  };

  useEffect(() => {
    GetPermisssionTypeMaster();
    GetRetailerStatusMaster();
    GetVendorStatusMaster();
    GetCountryDetail();
  }, []);
  useEffect(() => {
    if (!isUserName && !isEmail && !isCompanyName) {
      handleGetEmailTemplatesById();
    }
  }, [isCompanyName, isEmail, isUserName]);

  const GetPermisssionTypeMaster = () => {
    try {
      setLoading(true);
      AdminToolsDataService.GetPermisssionTypeMaster()
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setPermisssionTypeMaster(responseData);
          } else {
            setPermisssionTypeMaster([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const GetRetailerStatusMaster = () => {
    try {
      setLoading(true);
      AdminToolsDataService.GetRetailerStatusMaster()
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setRetailerStatusMaster(responseData);
          } else {
            setRetailerStatusMaster([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const GetVendorStatusMaster = () => {
    try {
      setLoading(true);
      AdminToolsDataService.GetVendorStatusMaster()
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setVendorStatusMaster(responseData);
          } else {
            setVendorStatusMaster([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const GetCountryDetail = () => {
    try {
      setLoading(true);
      AdminToolsDataService.GetCountryDetail()
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setCountryList(responseData);
          } else {
            setCountryList([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setClientData({
        ...clientData,
        [name]: e,
      });
    } else {
      setClientData({
        ...clientData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleGetEmailTemplatesById = () => {
    try {
      let inputParams = {
        DealerID: 0,
        TypeName: "NewAccount",
      };
      setContent("");
      setVisible(true);
      setLoading(true);
      AdminToolsDataService.GetHTMLMailTemplet(inputParams)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            if (responseData[0]) {
              var tempContent = responseData[0].emailBody;
              tempContent = tempContent.replace(
                "@@CopanyName",
                clientData.companyName
              );
              tempContent = tempContent.replace(
                "@@CompanyEmail",
                clientData.emailAddress
              );
              setContent(tempContent);
            }
          } else {
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const generatePassword = (e) => {
    e.preventDefault();
    let character = "";
    let password = "";
    while (password.length < 10) {
      const entity1 = Math.ceil(string.length * Math.random() * Math.random());
      const entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
      const entity3 = Math.ceil(
        lowerstring.length * Math.random() * Math.random()
      );
      const entity4 = Math.ceil(
        punctuation.length * Math.random() * Math.random()
      );
      character += string.charAt(entity1);
      character += numeric.charAt(entity2);
      character += lowerstring.charAt(entity3);
      character += punctuation.charAt(entity4);
      password = character;
    }
    password = password;
    setClientData((prevState) => ({
      ...prevState,
      password: password.substring(0, 10),
    }));
  };

  const handleSubmit = async (e) => {
    var isValid = handleValidation();
    setIsEmail(true);
    setIsCompanyName(true);
    setIsUserName(true);
    if (!isValid) {
      return false;
    } else {
      await handleCheckDealerCompanyName();
      await handleCheckDealerEmail();
      await handleCheckDealerUser();
      if (!isCompanyName && !isEmail && !isUserName) {
        handleGetEmailTemplatesById();
      }
    }
  };

  const handleCheckDealerCompanyName = async () => {
    try {
      let inputData = {
        companyName: clientData.companyName,
      };
      var isValid = true;
      setLoading(true);
      await AdminToolsDataService.CheckDealerCompanyName(inputData)
        .then((response) => {
          // var message = response.data.message;
          var checkMessage = response.data.responseData.isCompanyExist;
          setLoading(false);
          setValidations((prevState) => ({
            ...prevState,
            companyNameVal: checkMessage ? "CompanyName is Taken" : "",
          }));
          setIsCompanyName(checkMessage);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleCheckDealerEmail = async () => {
    try {
      let inputData = {
        email: clientData.emailAddress,
      };
      setLoading(true);
      var isValid = true;
      await AdminToolsDataService.CheckDealerEmail(inputData)
        .then((response) => {
          // var message = response.data.message;
          var checkMessage = response.data.responseData.isEmailExist;
          setLoading(false);
          setValidations((prevState) => ({
            ...prevState,
            emailAddressVal: checkMessage ? "Email is Taken" : "",
          }));
          setIsEmail(checkMessage);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    return isValid;
  };

  const handleCheckDealerUser = () => {
    try {
      let inputData = {
        userName: clientData.username,
      };
      setLoading(true);
      var isValid = true;
      AdminToolsDataService.CheckDealerUser(inputData)
        .then((response) => {
          // var message = response.data.message;
          var checkMessage = response.data.responseData.isUserNameExist;
          setLoading(false);
          isValid = checkMessage;
          setValidations((prevState) => ({
            ...prevState,
            usernameVal: checkMessage && "Username is Taken",
          }));
          setIsUserName(checkMessage);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    return isValid;
  };

  const handleCancel = () => {
    setVisible(false);
    setIsEmail(true);
    setIsCompanyName(true);
    setIsUserName(true);
  };

  const handleValidation = () => {
    const {
      companyName,
      streetAddress,
      city,
      state,
      zipcode,
      country,
      contactName,
      emailAddress,
      phoneNumber,
      username,
      password,
      permissionType,
      verified,
      retailerStatus,
      vendorStatus,
    } = clientData;
    const validations = {
      companyNameVal: "",
      streetAddressVal: "",
      cityVal: "",
      stateVal: "",
      zipcodeVal: "",
      countryVal: "",
      contactNameVal: "",
      emailAddressVal: "",
      phoneNumberVal: "",
      usernameVal: "",
      passwordVal: "",
      permissionTypeVal: "",
      retailerStatusVal: "",
      vendorStatusVal: "",
    };
    let isValid = true;

    if (!companyName.trim()) {
      isValid = false;
      validations.companyNameVal = "Company name is compulsory";
    } else {
      validations.companyNameVal = "";
    }

    if (!streetAddress.trim()) {
      isValid = false;
      validations.streetAddressVal = "Street Address is compulsory";
    } else {
      validations.streetAddressVal = "";
    }
    if (!city.trim()) {
      isValid = false;
      validations.cityVal = "City is compulsory";
    } else if (!/^[A-Za-z . ,'-]+$/.test(city)) {
      isValid = false;
      validations.cityVal = "City is Invalid";
    } else {
      validations.cityVal = "";
    }
    if (!state.trim()) {
      isValid = false;
      validations.stateVal = "State is compulsory";
    } else if (!/^[A-Za-z . ,'-]+$/.test(state)) {
      isValid = false;
      validations.stateVal = "State is Invalid";
    } else {
      validations.stateVal = "";
    }
    if (!zipcode.trim()) {
      isValid = false;
      validations.zipcodeVal = "Zipcode is compulsory";
    } else if (!/^\d{5}-\d{4}|\d{5}|[A-Z]\d[A-Z] \d[A-Z]\d$/.test(zipcode)) {
      isValid = false;
      validations.zipcodeVal = "Zipcode is Invalid";
    } else {
      validations.zipcodeVal = "";
    }
    if (!country) {
      isValid = false;
      validations.countryVal = "Kindly select the country ";
    } else {
      validations.countryVal = "";
    }
    if (!contactName.trim()) {
      isValid = false;
      validations.contactNameVal = "Contact Name is compulsory";
    } else {
      validations.contactNameVal = "";
    }
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!emailAddress.trim()) {
      isValid = false;
      validations.emailAddressVal = "Email Address is compulsory";
    } else if (!emailAddress.match(validRegex)) {
      isValid = false;
      validations.emailAddressVal = "Email Address is Invalid";
    } else {
      validations.emailAddressVal = "";
    }
    if (!phoneNumber.trim()) {
      isValid = false;
      validations.phoneNumberVal = "Phone Number is compulsory";
    } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(phoneNumber)) {
      isValid = false;
      validations.phoneNumberVal = "Phone Number is Invalid";
    } else {
      validations.phoneNumberVal = "";
    }
    if (!username.trim()) {
      isValid = false;
      validations.usernameVal = "Username is compulsory";
    } else {
      validations.usernameVal = "";
    }
    // var passwordRegex =
    //   "^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[@$!%*?&])[A-Za-zd@$!%*?&]{8,}$";
    if (!password.trim()) {
      isValid = false;
      validations.passwordVal = "Password is compulsory";
    } else if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{0,9}$/.test(password)) {
      isValid = false;
      validations.passwordVal = "Password should have a spe";
    } else {
      validations.passwordVal = "";
    }
    if (!permissionType) {
      isValid = false;
      validations.permissionTypeVal = "Kindly select the Permission Type";
    } else {
      validations.permissionTypeVal = "";
    }
    if (!retailerStatus) {
      isValid = false;
      validations.retailerStatusVal = "Kindly select the Retailer Status";
    } else {
      validations.retailerStatusVal = "";
    }
    if (!vendorStatus) {
      isValid = false;
      validations.vendorStatusVal = "Kindly select the Vendor Status";
    } else {
      validations.vendorStatusVal = "";
    }
    setValidations(validations);
    return isValid;
  };

  const handleSaveClientDetails = () => {
    const {
      companyName,
      streetAddress,
      city,
      state,
      zipcode,
      country,
      contactName,
      emailAddress,
      phoneNumber,
      username,
      password,
      permissionType,
      verified,
      retailerStatus,
      vendorStatus,
    } = clientData;
    console.log(content);
    console.log(clientData);
    setVisible(false);
    try {
      let inputData = {
        dealerId: dealerId,
        companyName: companyName,
        streetAddress: streetAddress,
        cityId: 0,
        stateId: 0,
        dealerCity: city,
        dealerState: state,
        zipCode: zipcode,
        countryId: country,
        contactName: contactName,
        emailAddress: emailAddress,
        phoneNumber: phoneNumber,
        userName: username,
        password: password,
        permissionTypeId: permissionType,
        isVerified: verified,
        retailerStatusId: retailerStatus,
        vendorStatusId: vendorStatus,
        ipAddress: "noneknown",
        logInUser: 0,
        logInUserName: "datapending",
        mailBody: content,
        mode: "",
      };
      setLoading(true);
      AdminToolsDataService.CreateNewClient(inputData)
        .then((response) => {
          var message = response.data.message;

          setLoading(false);
          if (message == "Success") {
            NotificationManager.success("New Client Added Successfully.");
          } else {
            NotificationManager.success("New Client Not Added.");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleCancel();
    setClientData({
      companyName: "",
      streetAddress: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
      contactName: "",
      emailAddress: "",
      phoneNumber: "",
      username: "",
      password: "",
      permissionType: null,
      verified: true,
      retailerStatus: "",
      vendorStatus: "",
      tags: "",
    });
    setValidations({
      companyNameVal: "",
      streetAddressVal: "",
      cityVal: "",
      stateVal: "",
      zipcodeVal: "",
      countryVal: "",
      contactNameVal: "",
      emailAddressVal: "",
      phoneNumberVal: "",
      usernameVal: "",
      passwordVal: "",
      permissionTypeVal: "",
      retailerStatusVal: "",
      vendorStatusVal: "",
    });
  };

  const {
    companyName,
    streetAddress,
    city,
    state,
    zipcode,
    country,
    contactName,
    emailAddress,
    phoneNumber,
    username,
    password,
    permissionType,
    verified,
    retailerStatus,
    vendorStatus,
  } = clientData;
  const {
    companyNameVal,
    streetAddressVal,
    cityVal,
    stateVal,
    zipcodeVal,
    countryVal,
    contactNameVal,
    emailAddressVal,
    phoneNumberVal,
    usernameVal,
    passwordVal,
    permissionTypeVal,
    retailerStatusVal,
    vendorStatusVal,
  } = validations;
  return (
    <div className="form__fields CreatNewClientChanges">
      <div className="row">
        <div className="col-md-12">
          <h4 className="workarea__heading mt-0">{traslate("AddNewClient")}</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6">
          <div className="input__block">
            <label>
              {" "}
              {traslate("CompanyName")}
              <span className="mandatory">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter Company Name"
              name="companyName"
              value={companyName}
              onChange={handleOnChange}
              className={companyNameVal && "border__red"}
            />
            <div>
              {companyNameVal && (
                <p className="error-color-red">{companyNameVal}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="input__block">
            <label>
              {" "}
              {traslate("StreetAddress")}
              <span className="mandatory">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter Street Address"
              name="streetAddress"
              value={streetAddress}
              onChange={handleOnChange}
              className={streetAddressVal && "border__red"}
            />
            <div>
              {streetAddressVal && (
                <p className="error-color-red">{streetAddressVal}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="input__block">
            <label>
              {" "}
              {traslate("City")}
              <span className="mandatory">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter City"
              name="city"
              value={city}
              onChange={handleOnChange}
              className={cityVal && "border__red"}
            />
            <div>{cityVal && <p className="error-color-red">{cityVal}</p>}</div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="input__block">
            <label>
              {" "}
              {traslate("State")}
              <span className="mandatory">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter State"
              name="state"
              value={state}
              onChange={handleOnChange}
              className={stateVal && "border__red"}
            />
            <div>
              {stateVal && <p className="error-color-red">{stateVal}</p>}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="input__block">
            <label>
              {" "}
              {traslate("Zipcode")}
              <span className="mandatory">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter Zip code"
              name="zipcode"
              value={zipcode}
              onChange={handleOnChange}
              className={zipcodeVal && "border__red"}
            />
            <div>
              {zipcodeVal && <p className="error-color-red">{zipcodeVal}</p>}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="input__block">
            <label>
              {" "}
              {traslate("Country")}
              <span className="mandatory">*</span>
            </label>
            <Select
              placeholder="Select Country"
              value={country ? country : null}
              name="country"
              showSearch
              optionFilterProp="children"
              onChange={(e) => handleOnChange(e, "select", "country")}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              className={countryVal ? "border__red" : "border__grey"}
            >
              {countryList.map((item) => {
                return <Option value={item.countryID}>{item.name}</Option>;
              })}
            </Select>
            <div>
              {countryVal && <p className="error-color-red">{countryVal}</p>}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="input__block">
            <label>
              {" "}
              {traslate("Contactname")}
              <span className="mandatory">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter Contact Name"
              name="contactName"
              value={contactName}
              onChange={handleOnChange}
              className={contactNameVal && "border__red"}
            />
            <div>
              {contactNameVal && (
                <p className="error-color-red">{contactNameVal}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="input__block">
            <label>
              {" "}
              {traslate("EmailAddress")}
              <span className="mandatory">*</span>
            </label>
            <input
              type="email"
              placeholder="Enter Email Address"
              name="emailAddress"
              value={emailAddress}
              onChange={handleOnChange}
              className={emailAddressVal && "border__red"}
            />
            <div>
              {emailAddressVal && (
                <p className="error-color-red">{emailAddressVal}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="input__block">
            <label>
              {" "}
              {traslate("PhoneNumber")}
              <span className="mandatory">*</span>
            </label>
            <input
              type="tel"
              placeholder="Enter Phone Number"
              name="phoneNumber"
              value={phoneNumber}
              onChange={handleOnChange}
              className={phoneNumberVal && "border__red"}
            />
            <div>
              {phoneNumberVal && (
                <p className="error-color-red">{phoneNumberVal}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="input__block">
            <label>
              {" "}
              {traslate("Username")}
              <span className="mandatory">*</span>
            </label>
            <input
              type="text"
              placeholder="Enter Username"
              name="username"
              value={username}
              onChange={handleOnChange}
              className={usernameVal && "border__red"}
            />
            <div>
              {usernameVal && <p className="error-color-red">{usernameVal}</p>}
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-12">
          <div className="input__block">
            <label>
              {" "}
              {traslate("Password")}
              <span className="mandatory">*</span>
            </label>
            <div className="row">
              <div className="col-md-6">
                <input
                  name="password"
                  type="text"
                  placeholder="Enter Password"
                  value={password}
                  onChange={handleOnChange}
                  maxlength="10"
                  className={passwordVal && "border__red"}
                />
                <div>
                  {passwordVal && (
                    <p className="error-color-red">{passwordVal}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <button
                  className="primary-btn mobilebtn"
                  onClick={generatePassword}
                >
                  {traslate("GeneratePassword")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="input__block">
            <label>
              {" "}
              {traslate("PermissionType")}
              <span className="mandatory">*</span>{" "}
            </label>
            <Select
              value={permissionType ? permissionType : null}
              name="permissionType"
              placeholder="Select Permission Type"
              onChange={(e) => handleOnChange(e, "select", "permissionType")}
              onSearch={onSearch}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              className={permissionTypeVal ? "border__red" : "border__grey"}
            >
              {permisssionTypeMaster.map((item) => {
                return (
                  <Option value={item.membeshipTypeId}>
                    {item.membeshipTypeText}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div>
            {permissionTypeVal && (
              <p className="error-color-red">{permissionTypeVal}</p>
            )}
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="input__block">
            <label>{traslate("Verified")}</label>
            <Switch
              name="verified"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={verified}
              onChange={(e) => handleOnChange(e, "select", "verified")}
            />
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="input__block">
            <label>
              {" "}
              {traslate("RetailerStatus")}
              <span className="mandatory">*</span>{" "}
            </label>
            <Select
              value={retailerStatus ? retailerStatus : null}
              name="retailerStatus"
              showSearch
              placeholder="Select Retailer Status "
              optionFilterProp="children"
              onChange={(e) => handleOnChange(e, "select", "retailerStatus")}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              className={retailerStatusVal ? "border__red" : "border__grey"}
            >
              {retailerStatusMaster.map((item) => {
                return <Option value={item.statusId}>{item.statusText}</Option>;
              })}
            </Select>
            <div>
              {retailerStatusVal && (
                <p className="error-color-red">{retailerStatusVal}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="input__block">
            <label>
              {traslate("VendorStatus")}
              <span className="mandatory">*</span>{" "}
            </label>
            <Select
              value={vendorStatus ? vendorStatus : null}
              name="vendorStatus"
              showSearch
              placeholder="Select Vendor Status"
              optionFilterProp="children"
              onChange={(e) => handleOnChange(e, "select", "vendorStatus")}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              className={vendorStatusVal ? "border__red" : "border__grey"}
            >
              {vendorStatusMaster.map((item) => {
                return <Option value={item.statusId}>{item.statusText}</Option>;
              })}
            </Select>
            <div>
              {vendorStatusVal && (
                <p className="error-color-red">{vendorStatusVal}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <Button
          onClick={handleSubmit}
          // loading={submitLoading}
          className="primary-btn"
        >
          {traslate("CreateClient")}
        </Button>
      </div>
      <div className="col-lg-12">
        <Modal
          title="Send Email"
          className="modalvdiamond__section"
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => handleCancel()}
          width={600}
          footer={[
            <Button key="back" onClick={handleCancel}>
              {traslate("Cancel")}
            </Button>,
            <Button key="Save" onClick={handleSaveClientDetails}>
              {traslate("Save")}
            </Button>,
          ]}
        >
          <div className="editor__section">
            <Spin spinning={loading}>
              <CKEditor
                editor={ClassicEditor}
                data={content}
                // config={editorConfiguration}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setContent(data);
                  console.log({ event, editor, data });
                }}
                // onBlur={(event, editor) => {
                //   console.log("Blur.", editor);
                // }}
                // onFocus={(event, editor) => {
                //   console.log("Focus.", editor);
                // }}
              />
            </Spin>
          </div>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewClient);
