import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table
} from "antd";
import traslate from "../../i18n/translate";

const PendingJewelryRequest = () => {
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;

  const style = <span>content not provided.</span>;

  function onSearch(val) {
    console.log("search:", val);
  }

  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };

  const columns = [
    {
      title: traslate("StoreName"),
      dataIndex: "storename"
    },
    {
      title: traslate("RetailerType"),
      dataIndex: "retailertype"
    },
    {
      title: traslate("City"),
      dataIndex: "city"
    },
    {
      title: traslate("State"),
      dataIndex: "state"
    },
    {
        title: traslate("RequestedDate"),
        dataIndex: "requesteddate"
    },
    {
      title: traslate("ActionRequest"),
      dataIndex: "actionrequest"
    }
  ];

  const data = [
    {
      key: "1",
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
      actionrequest: (
        <div className="icn__div">
            <i className="fa fa-check chck_icn"></i>
            <i className="fa fa-close clse_icn"></i>
        </div>
      )
    },
    {
      key: "2",
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
      actionrequest: (
        <div className="icn__div">
            <i className="fa fa-check chck_icn"></i>
            <i className="fa fa-close clse_icn"></i>
        </div>
      )
    },
    {
      key: "3",
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
      actionrequest: (
        <div className="icn__div">
            <i className="fa fa-check chck_icn"></i>
            <i className="fa fa-close clse_icn"></i>
        </div>
      )
    },
    {
      key: "4",
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
      actionrequest: (
        <div className="icn__div">
            <i className="fa fa-check chck_icn"></i>
            <i className="fa fa-close clse_icn"></i>
        </div>
      )
    }
  ];

  return (
    <React.Fragment>
      <div className="pendjewelreq__section">
        <div className="form__fields">
          <div className="col-md-12">
            <h4 className="workarea__heading mt-0"> {traslate("PendingRingBuilderRequest")} </h4>
          </div>
          <div className="col-lg-12 col-md-12">
            <Table className="pendjewelreq__tbl" columns={columns} dataSource={data} scroll={{ x: 600 }} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PendingJewelryRequest;
