import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Search,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Modal,
  Table,
} from "antd";
import AdminCampaignsService from "../../../services/admin-campaigns.service";
import traslate from "../../../i18n/translate";
const CampaignData = () => {
  const initialState = {
    ComposeID: null,
  };
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [campaignDropList, setcampaignDropList] = useState([]);
  const [campaignTableList, setcampaignTableList] = useState([]);
  const { Option } = Select;
  useEffect(() => {
    handleGetCampaignList();
  }, []);
  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  const onChangeDrop = (e, name) => {
    setcampaignTableList([]);
    let tempState = state;
    tempState[name] = e;

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    handleMailingListByCampaignName();
  };

  const handleGetCampaignList = () => {
    debugger;
    setLoading(true);
    try {
      AdminCampaignsService.GetCampaignList()
        .then((response) => {
          setLoading(false);
          debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            var data = JSON.parse(responseData);
            setcampaignDropList(data.items);
            setcampaignTableList(data.items);
          } else {
            setcampaignDropList([]);
            setcampaignTableList([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleMailingListByCampaignName = () => {
    debugger;
    setLoading(true);

    let inputData = {
      campaignID: state.ComposeID,
    };
    try {
      AdminCampaignsService.GetMailingListByCampaignName(inputData)
        .then((response) => {
          setLoading(false);
          debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            var data = JSON.parse(responseData);
            setcampaignTableList(data.items);
          } else {
            setcampaignTableList([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className="campaign__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {" "}
                {traslate("Campaigns")}{" "}
              </h4>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="input__block">
                    <label> {traslate("SelectCampaign")} </label>
                    <Select
                      showSearch
                      className="border__grey"
                      placeholder="Select Campaign"
                      optionFilterProp="children"
                      value={state.ComposeID}
                      onChange={(e) => onChangeDrop(e, "ComposeID")}
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="">Select Campaign</Option>
                      {campaignDropList.map((item, i) => {
                        return <Option value={item.id}>{item.name}</Option>;
                      })}
                    </Select>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="campaign_tbldiv">
                {/* <Table columns={columns} dataSource={data} scroll={{ y: 300 }} /> */}
                <Table
                  const
                  columns={[
                    {
                      title: traslate("CampaignName"),

                      dataIndex: "name",
                      key: "name",
                      fixed: "left",
                      width: 160,
                    },
                    {
                      title: traslate("Submitted"),
                      dataIndex: "submitted_count",
                      width: 120,
                      key: "submitted_count",
                    },
                    {
                      title: traslate("Delivered"),
                      width: 120,
                      dataIndex: "delivered_count",
                      key: "delivered_count",
                    },
                    {
                      title: traslate("TotalOpens"),
                      width: 120,
                      dataIndex: "opened_count",
                      key: "opened_count",
                    },
                    {
                      title: traslate("Total Clicks"),
                      width: 120,
                      dataIndex: "clicked_count",
                      key: "clicked_count",
                    },
                    {
                      title: traslate("Created"),
                      width: 300,
                      dataIndex: "created_at",
                      key: "created_at",
                    },
                  ]}
                  loading={loading}
                  dataSource={campaignTableList}
                  scroll={{ x: 600 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CampaignData;
