import React, { useState } from "react";
import { Input, Row, Col, Select, Checkbox, Button, Switch, Tooltip } from "antd";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import traslate from "../../../i18n/translate";

const RingbuilderLegacy = () => {
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;

  const nocontent = <span> {traslate("Nocontent.")} </span>;

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };
  return (
    <React.Fragment>
      <div className="navigation__section dlegacy__section">
        <div className="form__fields">
          <div className="col-md-12 mt-2">
            <h4 className="workarea__heading mt-0 mb-0"> {traslate("Legacy")} </h4>
          </div>

          <div className="col-lg-12 mt-4">
            <div className="form__section">
              <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {traslate("PageAlignment")} </span>
                </div>

                <div className="inpt__div">
                    <div className="input__block">
                        <Select
                        className="border__grey selct__inpt"
                        showSearch
                        placeholder="Center"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                            option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        >
                        <Option value="jack"> {traslate("Center")} </Option>
                        <Option value="lucy"> {traslate("Left")} </Option>
                        <Option value="tom"> {traslate("Right")} </Option>
                        </Select>
                        <Tooltip className="ml-1" placement="right" title={nocontent}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                </div>
              </div>

              <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {traslate("ShowSocialSharingButtons")} </span>
                </div>

                <div className="inpt__div">
                    <div className="input__block">
                        <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked
                        onChange={handleSwitch}
                        />
                        <Tooltip className="ml-1" placement="right" title={nocontent}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                </div>
              </div>

              <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {traslate("ShowViewInStore")} </span>
                </div>

                <div className="inpt__div">
                    <div className="input__block">
                        <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked
                        onChange={handleSwitch}
                        />
                        <Tooltip className="ml-1" placement="right" title={nocontent}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                </div>
              </div>

              <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {traslate("ShowPopUpOnDiamondSearch")} </span>
                </div>

                <div className="inpt__div">
                    <div className="input__block">
                        <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked
                        onChange={handleSwitch}
                        />
                        <Tooltip className="ml-1" placement="right" title={nocontent}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                </div>
              </div>

              <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {traslate("DiamondSearchResultPageSize")} </span>
                </div>

                <div className="inpt__div">
                    <div className="input__block">
                        <Select
                        className="border__grey selct__inpt"
                        showSearch
                        placeholder="25"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                            option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        >
                        <Option value="jack">10</Option>
                        <Option value="lucy">25</Option>
                        <Option value="tom">30</Option>
                        </Select>
                        <Tooltip className="ml-1" placement="right" title={nocontent}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                </div>
              </div>

              <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {traslate("Rings-SearchResultPageSize")} </span>
                </div>

                <div className="inpt__div">
                    <div className="input__block">
                        <Select
                        className="border__grey selct__inpt"
                        showSearch
                        placeholder="25"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                            option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        >
                        <Option value="jack">10</Option>
                        <Option value="lucy">25</Option>
                        <Option value="tom">30</Option>
                        </Select>
                        <Tooltip className="ml-1" placement="right" title={nocontent}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                </div>
              </div>

              <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {traslate("Rings-InitiallySortResultOn")} </span>
                </div>

                <div className="inpt__div">
                    <div className="input__block">
                        <Select
                        className="border__grey selct__inpt"
                        showSearch
                        placeholder="Price"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                            option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        >
                        <Option value="jack"> {traslate("Price")} </Option>
                        <Option value="lucy"> {traslate("MetalType")} </Option>
                        <Option value="tom"> {traslate("SettingNumber")} </Option>
                        </Select>
                        <Tooltip className="ml-1" placement="right" title={nocontent}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                    <div className="input__block mt-3">
                        <Select
                        className="border__grey selct__inpt"
                        showSearch
                        placeholder="Ascending"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                            option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        >
                        <Option value="jack"> {traslate("Ascending")} </Option>
                        <Option value="jack"> {traslate("Descending")} </Option>
                        </Select>
                        <Tooltip className="ml-1" placement="right" title={nocontent}>
                            <img src={UnionIcon} alt="" />
                        </Tooltip>
                    </div>
                </div>
              </div>

              <div className="col-lg-12 mt-4">
                <button class="primary-btn"> {traslate("UpdateSettings")} </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RingbuilderLegacy;
