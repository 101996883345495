import React, { Fragment } from "react";
import JewelryRetailerPermission from "./JewelryRetailerPermission";

const DiamondRetailerPermission = () => {
  return (
    <Fragment>
      <JewelryRetailerPermission type="Diamond" />
    </Fragment>
  );
};

export default DiamondRetailerPermission;
