import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table
} from "antd";

import Highlighter from "react-highlight-words";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";

import { Doughnut, Bar, Line, Scatter } from "react-chartjs-2";
import traslate from "../../../i18n/translate";

const GlobalExtendedDiamondChartReports = () => {
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;

  const style = <span>content not provided.</span>;

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };

  return (
    <React.Fragment>
      <div className="pendjewelreq__section">
        <div className="row form__fields magento__mapping">
          <div className="col-md-12">
            {/* <h4 className="workarea__heading">Jewelry Category Report</h4> */}
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
              {traslate("ExtendedDiamondChartReports")}
              </h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block">
              <label> {traslate("FromDate")} </label>
              <input type="date" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block">
              <label> {traslate("ToDate")} </label>
              <input type="date" />
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <label> {traslate("Vendor(s)")} </label>
              <Select
                showSearch
                className="border__grey"
                placeholder="Select one or more vendors "
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="jack"> {traslate("Roundtothenearest1")} </Option>
                <Option value="lucy"> {traslate("Roundtothenearest1")} </Option>
                <Option value="tom"> {traslate("Roundtothenearest1")} </Option>
              </Select>
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <label> {traslate("Retailer(s)")} </label>
              <Select
                showSearch
                className="border__grey"
                placeholder="Select one or more retailer "
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="jack"> {traslate("Roundtothenearest1")} </Option>
                <Option value="lucy"> {traslate("Roundtothenearest1")} </Option>
                <Option value="tom"> {traslate("Roundtothenearest1")} </Option>
              </Select>
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <label> {traslate("SelectDiamond")} </label>
              <Select
                showSearch
                className="border__grey"
                placeholder="All "
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="jack"> {traslate("All")} </Option>
                <Option value="lucy"> {traslate("MinedDiamond")} </Option>
                <Option value="lucy">{traslate("LabGrownDiamond")} </Option>
              </Select>
            </div>
          </div>

          <div className="col-md-4 align-self-end">
            <div className="input__block">
              <button className="primary-btn mt-2"> {traslate("ApplyFilter")} </button>
            </div>
          </div>

          <div className="col-lg-12 col-md-6 mt-4">
            <div className="input__block">
              <label>
                <b> {traslate("TotalClicks")} : 32</b>
                <Tooltip
                  placement="right"
                  title={traslate("Displaysthetotalnumberofjewelryclicksthroughthecurrentlyspecifieddaterangeabove")}
                >
                  <img src={UnionIcon} alt="" />
                </Tooltip>
              </label>
            </div>
          </div>
          <div className="col-lg-12 mt-4">
              <label>
                <b> {traslate("DiamondCutSearches")} </b>
              </label>
            <div className="chart__section">
              <Bar
                data={{
                  labels: [
                    "Red",
                    "Blue",
                    "Yellow",
                    "Green",
                    "Purple",
                    "Orange"
                  ],
                  datasets: [
                    {
                      label: "# of Votes",
                      data: [12, 19, 3, 5, 2, 3],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(54, 162, 235, 0.2)",
                        "rgba(255, 206, 86, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                        "rgba(153, 102, 255, 0.2)",
                        "rgba(255, 159, 64, 0.2)"
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                        "rgba(255, 206, 86, 1)",
                        "rgba(75, 192, 192, 1)",
                        "rgba(153, 102, 255, 1)",
                        "rgba(255, 159, 64, 1)"
                      ],
                      borderWidth: 1
                    }
                  ]
                }}
                width={300}
                height={300}
                options={{
                  maintainAspectRatio: false
                }}
              />
            </div>
          </div>

          <div className="col-lg-12 mt-4">
              <label>
                <b> {traslate("DiamondShapeSearches")} </b>
              </label>
            <div className="chart__section">
              <Line
                data={{
                  labels: [
                    "Red",
                    "Blue",
                    "Yellow",
                    "Green",
                    "Purple",
                    "Orange"
                  ],
                  datasets: [
                    {
                      label: "# of Votes",
                      data: [12, 19, 3, 5, 2, 3],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(54, 162, 235, 0.2)",
                        "rgba(255, 206, 86, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                        "rgba(153, 102, 255, 0.2)",
                        "rgba(255, 159, 64, 0.2)"
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                        "rgba(255, 206, 86, 1)",
                        "rgba(75, 192, 192, 1)",
                        "rgba(153, 102, 255, 1)",
                        "rgba(255, 159, 64, 1)"
                      ],
                      borderWidth: 1
                    }
                  ]
                }}
                width={300}
                height={300}
                options={{
                  maintainAspectRatio: false
                }}
              />
            </div>
          </div>

          <div className="col-lg-12 mt-4">
              <label>
                <b> {traslate("DiamondClaritySearches")} </b>
              </label>
            <div className="chart__section">
              <Doughnut
                data={{
                  labels: [
                    "Red",
                    "Blue",
                    "Yellow",
                    "Green",
                    "Purple",
                    "Orange"
                  ],
                  datasets: [
                    {
                      label: "# of Votes",
                      data: [12, 19, 3, 5, 2, 3],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(54, 162, 235, 0.2)",
                        "rgba(255, 206, 86, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                        "rgba(153, 102, 255, 0.2)",
                        "rgba(255, 159, 64, 0.2)"
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                        "rgba(255, 206, 86, 1)",
                        "rgba(75, 192, 192, 1)",
                        "rgba(153, 102, 255, 1)",
                        "rgba(255, 159, 64, 1)"
                      ],
                      borderWidth: 1
                    }
                  ]
                }}
                width={300}
                height={300}
                options={{
                  maintainAspectRatio: false
                }}
              />
            </div>
          </div>

          <div className="col-lg-12 mt-4">
              <label>
                <b> {traslate("DiamondCertificateSearches")} </b>
              </label>
            <div className="chart__section">
              <Scatter
                data={{
                  labels: [
                    "Red",
                    "Blue",
                    "Yellow",
                    "Green",
                    "Purple",
                    "Orange"
                  ],
                  datasets: [
                    {
                      label: "# of Votes",
                      data: [12, 19, 3, 5, 2, 3],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(54, 162, 235, 0.2)",
                        "rgba(255, 206, 86, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                        "rgba(153, 102, 255, 0.2)",
                        "rgba(255, 159, 64, 0.2)"
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                        "rgba(255, 206, 86, 1)",
                        "rgba(75, 192, 192, 1)",
                        "rgba(153, 102, 255, 1)",
                        "rgba(255, 159, 64, 1)"
                      ],
                      borderWidth: 1
                    }
                  ]
                }}
                width={300}
                height={300}
                options={{
                  maintainAspectRatio: false
                }}
              />
            </div>
          </div>

        </div>
      </div>
    </React.Fragment>
  );
};

export default GlobalExtendedDiamondChartReports;
