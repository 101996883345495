import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table
} from "antd";
import traslate from "../../../i18n/translate";

const { Option } = Select;

const DataRelated = () => {
  const columns = [
    {
      title: traslate("Name"),
      dataIndex: "name"
    },
    {
      title: traslate("DefaultMappingValues"),
      dataIndex: "defaultmappingvalues"
    },
    {
      title: traslate("Items"),
      dataIndex: "items",
      width: 120
    },
    {
      title: traslate("Heirachy"),
      dataIndex: "heirachy",
      width: 120
    }
  ];

  const data = [
    {
      key: "1",
      name: <span>2-Prong Ring</span>,
      defaultmappingvalues: <span>2-Prong Ring</span>,
      items: <span>0</span>,
      heirachy: <span>Sub</span>
    },
    {
      key: "2",
      name: <span>2-Prong Ring</span>,
      defaultmappingvalues: <span>2-Prong Ring</span>,
      items: <span>0</span>,
      heirachy: <span>Sub</span>
    },
    {
      key: "3",
      name: <span>2-Prong Ring</span>,
      defaultmappingvalues: <span>2-Prong Ring</span>,
      items: <span>0</span>,
      heirachy: <span>Sub</span>
    },
    {
        key: "4",
        name: <span>2-Prong Ring</span>,
        defaultmappingvalues: <span>2-Prong Ring</span>,
        items: <span>0</span>,
        heirachy: <span>Sub</span>
    }
  ];

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields magento__mapping">
        <div className="row">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
              {traslate("DataRelated")}
              </h4>
              <button className="primary-btn">
                <i className="fa fa-download mr-1"></i>
                {traslate("Download")}
              </button>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row">
              <div className="radio__block col">
                <input type="radio" id="1" name="filt" />
                <label for="1"> {traslate("Categories")} </label>
              </div>
              <div className="radio__block col">
                <input type="radio" id="2" name="filt" />
                <label for="2"> {traslate("MetalTypes")} </label>
              </div>
              <div className="radio__block col">
                <input type="radio" id="3" name="filt" />
                <label for="3"> {traslate("MetalColors")} </label>
              </div>
              <div className="radio__block col">
                <input type="radio" id="4" name="filt" />
                <label for="4"> {traslate("DiamondPairs")} </label>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="button__block my-4">
              <button className="primary-btn mr-2"> {traslate("ApplyFilter")} </button>
              {/* <button className="primary-btn mr-2">Download</button> */}
            </div>
          </div>

          <div className="col-lg-12 col-md-12 mt-4">
            <Table
              className="pendjewelreq__tbl"
              columns={columns}
              dataSource={data}
              scroll={{ x: 600 }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DataRelated;
