import { LOGIN_SUBMIT, SET_LOGIN_TOKEN, SET_IMPERSONATE_DATA } from "./types";
import LoginDataService from "../../services/login.service";

export const loginSubmit = (inputDate) => async (dispatch) => {
  try {
    const res = await LoginDataService.login(inputDate);
    dispatch({
      type: LOGIN_SUBMIT,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setLoginToken = (token) => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOGIN_TOKEN,
      payload: token,
    });
  } catch (err) {}
};
export const setImpersonateData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_IMPERSONATE_DATA,
      payload: data,
    });
  } catch (err) {}
};
