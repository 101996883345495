import React, { useEffect, useState } from "react";
import { Select, Modal, Table, Button } from "antd";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import { useLocation } from "react-router-dom";

const { Option } = Select;
const OutBoundSync = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [syncList, setSyncList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [synLoading, setSynLoading] = useState(false);
  const [forceSynLoading, setForceSynLoading] = useState(false);
  const [dealerID, setDealerId] = useState(0);
  const [dealerList, setDealerList] = useState([]);
  const [updateHistory, setUpdateHistory] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const [rowDetails, setRowDetails] = useState({});

  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      if (location.state.dealerID) {
        setDealerId(location.state.dealerID);
        handleGetUpdateHistoryList(location.state.dealerID, pageNo, pageSize);
      }
    } else {
    }

    handleGetUsersList();
  }, []);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setRowDetails({});
  };
  const handleGetUpdateHistoryList = (id, no, size) => {
    let inputData = {
      dealerId: id || dealerID,
      pageIndex: no,
      pageSize: size,
    };
    try {
      setLoading(true);
      AdminToolsDataService.GetUpdateHistoryList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            if (responseData) setTotalPage(responseData[0].totalRecordCount);
            setUpdateHistory(responseData);
          } else {
            setUpdateHistory([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleGetUsersList = () => {
    let inputData = {
      clientType: "",
    };
    try {
      AdminToolsDataService.GetUsersList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setDealerList(responseData);
          } else {
            setDealerList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleSyncProducts = () => {
    let inputData = {
      dealerId: dealerID || 0,
    };
    try {
      setSynLoading(true);
      AdminToolsDataService.SyncProducts(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setSynLoading(false);
          if (message == "Success") {
            NotificationManager.warning(responseData);
          } else {
            NotificationManager.error(responseData);
          }
        })
        .catch((error) => {
          setSynLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSynLoading(false);
      console.log(error);
    }
  };
  const handleSyncProductsForce = () => {
    let inputData = {
      dealerId: dealerID || 0,
    };
    try {
      setForceSynLoading(true);
      AdminToolsDataService.SyncProductsForce(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setForceSynLoading(false);
          if (message == "Success") {
            NotificationManager.warning(responseData);
          } else {
            NotificationManager.error(responseData);
          }
        })
        .catch((error) => {
          setForceSynLoading(false);
          console.log(error);
        });
    } catch (error) {
      setForceSynLoading(false);
      console.log(error);
    }
  };

  const handleOnChange = (e) => {
    setDealerId(e);
    handleGetUpdateHistoryList(e, pageNo, pageSize);
  };
  function handlePageNoChange(page, pageSize) {
    setPageNo(page);
    handleGetUpdateHistoryList(dealerID, page, pageSize);
  }
  function handlePageSizeChange(current, size) {
    setPageSize(size);
    handleGetUpdateHistoryList(dealerID, current, size);
  }
  const handleGetDetials = (row) => {
    setRowDetails(row);
    setIsModalVisible(true);
  };
  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv data__maping">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="workarea__heading">Sync Products To Your Website</h4>
                </div>
                <div className="col-md-4">
                  <div className="input__block">
                    <label>Select Dealer</label>
                    <Select
                      showSearch
                      className="border__grey"
                      placeholder="Select Dealer"
                      optionFilterProp="children"
                      value={dealerID}
                      onChange={handleOnChange}
                    >
                      <Option value="">Select Dealer</Option>
                      {dealerList.map((item, i) => {
                        return (
                          <Option key={i} value={Number(item.dealerID)}>
                            {item.dealerCompany + " ( " + item.dealerID + " )"}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="prodesc__div">
                    <Table
                      columns={[
                        {
                          title: "DealerID",
                          dataIndex: "dealerId",
                        },
                        {
                          title: "Dealer Company",
                          dataIndex: "dealerCompany",
                        },
                        {
                          title: "Type",
                          dataIndex: "typeDesc",
                        },
                        {
                          title: "Status",
                          dataIndex: "statusDesc",
                        },

                        {
                          title: "Initiated",
                          dataIndex: "createdDate",
                        },
                        {
                          title: "Completed",
                          dataIndex: "completedDate",
                        },

                        {
                          title: "Details",
                          render: (item, row) => {
                            return (
                              <a href="#" onClick={() => handleGetDetials(row)}>
                                Details
                              </a>
                            );
                          },
                        },
                        {
                          title: "Download",
                          render: (item, row) => {
                            return <a href="#">DownlaodFile</a>;
                          },
                        },
                      ]}
                      dataSource={updateHistory}
                      loading={loading}
                      scroll={{ y: 300 }}
                      pagination={{
                        current: pageNo,
                        pageSize: pageSize,
                        pageSizeOptions: [10, 20, 50, 100],
                        responsive: true,
                        showSizeChanger: true,
                        total: totalPage,
                        onChange: handlePageNoChange,
                        onShowSizeChange: handlePageSizeChange,
                      }}
                    />

                    <div class="col-md-6 mt-3">
                      <Button
                        className="primary-btn"
                        style={{ marginRight: "10px" }}
                        onClick={handleSyncProducts}
                        loading={synLoading}
                      >
                        <i
                          class="fa fa-cloud-upload"
                          style={{ color: "white", marginRight: "5px" }}
                        ></i>
                        Sync Products
                      </Button>
                      <Button
                        className="primary-btn"
                        style={{ marginRight: "10px" }}
                        onClick={handleSyncProductsForce}
                        loading={forceSynLoading}
                      >
                        Force Full Sync
                      </Button>
                      <Button
                        className="primary-btn"
                        onClick={() =>
                          handleGetUpdateHistoryList(dealerID.pageNo, pageSize)
                        }
                      >
                        <i
                          className="fa fa-refresh"
                          style={{ color: "white", marginRight: "5px" }}
                        ></i>
                        Update History
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Detail"
        visible={isModalVisible}
        className="forgot__password"
        onCancel={handleCancel}
      >
        <div className="form__fields p-0 border-0 mh-auto">
          <div className="input__block">
            <label>
              Total Products Processed = <b>{rowDetails.count}</b>
            </label>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default OutBoundSync;
