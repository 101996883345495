import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Switch,
  Table,
  Menu,
  Dropdown,
  Button,
  Space,
  Modal,
  Pagination,
  Spin,
} from "antd";
import Create_icon from "../../assets/images/create_icon.png";
import Upload_icon from "../../assets/images/upload_icon.png";
import Ellipsis_icon from "../../assets/images/ellipsis_icon.png";
import Filter_icon from "../../assets/images/filter_icon.png";
import Info_icon from "../../assets/images/info_icon.png";
import Video_icon from "../../assets/images/icons/video_icon.svg";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";
import Eye_icon from "../../assets/images/icons/eye_icon.svg";
import Close from "../../assets/images/close.png";
import MultiRangeSlider from "../contacts/MultiRangeSlider";
import { Link } from "react-router-dom";
import CatalogJewelryDataService from "../../services/catalog-jewelry.service";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import traslate from "../../i18n/translate";

const Allproducts = () => {
  const [viewItem, setViewItem] = useState("grid");
  const [hideShowFilter, setHideShowFilter] = useState(true);
  const initialState = {
    Catagory: [],
    MaterialColor: [],
    MaterialType: [],
    Gender: [],
    Collection: [],
    Vendor: [],
    minPrice: "",
    maxPrice: "",
    Image: null,
    PageNumber: 1,
    NoOfRow: 10,
    disabled: {},
    productChange: {},
    gridCheckList: {},
  };
  const [state, setState] = useState(initialState);

  const [minVal, setMinVal] = useState("");
  const [maxVal, setMaxVal] = useState("");
  const [DealerId, setDealerId] = useState(null);
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);

  const [ViewProductList, setViewProductList] = useState([]);
  const [CategotyList, setCategotyList] = useState([]);
  const [MaterialColor, setMaterialColor] = useState([]);
  const [MaterialType, setMaterialType] = useState([]);
  const [GenderList, setGenderList] = useState([]);
  const [CollectionList, setCollectionList] = useState([]);
  const [VendorList, setVendorList] = useState([]);
  const [ImageList, setImageList] = useState([]);
  const [applyLoading, setApplyLoading] = useState(false);
  const resetRange = useRef(null);
  const [TotalRowCount, setTotalRowCount] = useState(0);
  const [newCheck, setNewCheck] = useState(false);
  const [featureCheck, setFeatureCheck] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [visible3, setVisible3] = useState(false);
  const [selectActionRow, setSelectActionRow] = useState({});
  const [deleteId, setDeleteId] = useState("");
  const [GridCheckArray, setGridCheckArray] = useState([]);
  const [deleteType, setDeleteType] = useState("");

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        if (loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.responseData.dealerId);

          handleGetJewelryDataList(loginDetials.responseData.dealerId);
          // handleViewProduct(loginDetials.responseData.dealerId);
        }
      }
    }
  }, []);
  // useEffect(() => {
  //   handleViewProduct(loginDetials.responseData.dealerId);
  // }, []);

  const handleOnCheckGridAll = (e) => {
    debugger;
    var tempViewProductList = ViewProductList;
    tempViewProductList.forEach((element) => {
      element.isChecked = e.target.checked;
    });
    setViewProductList([...tempViewProductList]);
  };

  const handleOnCheckGrid = (e, id) => {
    debugger;
    var tempViewProductList = [];
    tempViewProductList = ViewProductList.map((x) => {
      if (x.gfInventoryID === id) {
        return { ...x, isChecked: e.target.checked };
      } else return x;
    });
    setViewProductList(tempViewProductList);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      debugger;
      setSelectedIDs(selectedRowKeys);
    },
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  function onShowSizeChange(current, pageSize) {
    debugger;
    console.log(current, pageSize);
    state.PageNumber = current;
    state.NoOfRow = pageSize;
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));

    handleViewProduct(DealerId);
  }

  const onchangeRange = (min, max) => {
    debugger;

    // let tempState = state;
    // tempState.minPrice=min;
    // tempState.maxPrice=max;
    // setState((prevState) => ({
    //   ...prevState,
    //   ...tempState,
    // }));
  };

  const onChangeDrop = (e, name) => {
    let tempState = state;
    tempState[name] = e;

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    console.log(state);
  };

  const onChangeMultiDrop = (e, name) => {
    debugger;

    let tempState = state;
    if (e.includes("")) {
      tempState.disabled[name] = true;
      tempState[name] = [];
      tempState[name].push("");
    } else {
      tempState.disabled[name] = false;
      tempState[name] = e;
    }

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    console.log(state);
  };

  const onResetFilter = () => {
    debugger;
    let tempState = state;
    setMinVal(0);
    setMaxVal(10000);
    tempState["Catagory"] = [];
    tempState["MaterialColor"] = [];
    tempState["MaterialType"] = [];
    tempState["Gender"] = [];
    tempState["Collection"] = [];
    tempState["Vendor"] = [];

    tempState["Image"] = null;
    tempState.disabled["Catagory"] = false;
    tempState.disabled["MaterialColor"] = false;
    tempState.disabled["MaterialType"] = false;
    tempState.disabled["Gender"] = false;
    tempState.disabled["Collection"] = false;
    tempState.disabled["Vendor"] = false;
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const handleGetJewelryDataList = (id) => {
    debugger;
    let inputData = {
      dealerID: id || DealerId,
      orderby: "GFInventoryID",
      order: "ASC",
      category: "",
      vendor: "",
      materialType: "",
      gender: "",
      collection: "",
      collectionID: "",
      materialColor: "",
      minRange: 0,
      maxRange: 999999,
      styleNumber: "",
      dealerType: "MyProduct",
      noOfRows: 0,
      offset: 0,
      gemstoneType: "",
      strSelectedInvID: "",
      image: "",
    };

    setApplyLoading(true);
    try {
      CatalogJewelryDataService.GetJewelryDataList(inputData)
        .then((response) => {
          debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;
          handleViewProduct(id);
          if (message == "Success") {
            setCategotyList(responseData.categoryTypes);
            setCollectionList(responseData.collections);
            setGenderList(responseData.genders);
            setImageList(responseData.hasImages);
            setMaterialColor(responseData.metalColors);
            setMaterialType(responseData.metalTypes);
            setVendorList(responseData.vendors);
          } else {
            setCategotyList([]);
            setCollectionList([]);
            setGenderList([]);
            setImageList([]);
            setMaterialColor([]);
            setMaterialType([]);
            setVendorList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewProduct = (id) => {
    debugger;
    setApplyLoading(true);
    let inputData = {
      dealerID: 2245,
      orderby: "GFInventoryID",
      pageNumber: state.PageNumber,
      order: "ASC",
      category: state.Catagory.join(","),
      vendor: state.Vendor.join(","),
      materialType: state.MaterialType.join(","),
      gender: state.Gender.join(","),
      collection: "",
      collectionIDs: state.Collection.join(","),
      materialColor: state.MaterialColor.join(","),
      minRange: (state.minPrice = "0" ? "" : state.minPrice + ""),
      maxRange: (state.maxPrice = 99999 ? "" : state.maxPrice + ""),
      styleNumber: "",
      dealerType: "MyProduct",
      noOfRows: state.NoOfRow + "",
      offset: "0",
      strSelectedInvID: "",
      hasImage: state.Image ? state.Image + "" : "",
      viewBy: "2",
    };

    try {
      CatalogJewelryDataService.ViewProduct(inputData)
        .then((response) => {
          debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;
          setApplyLoading(false);
          if (message == "Success") {
            responseData.forEach((element) => {
              element.isChecked = false;
            });
            setViewProductList(responseData);
            setTotalRowCount(responseData[0].jewelryTotalRecords);
          } else {
            setViewProductList([]);
          }
        })
        .catch((error) => {
          setApplyLoading(false);
          console.log(error);
        });
    } catch (error) {
      setApplyLoading(false);
      console.log(error);
    }
  };

  const handleUpdateProductFlag = (id, ProductId, flag, Value, VendorId) => {
    debugger;
    let inputData = {
      dealerID: id + "" || DealerId + "",
      productID: ProductId + "",
      flagValue: flag,
      value: Value,
      vendorId: VendorId + "",
    };
    setApplyLoading(true);
    try {
      CatalogJewelryDataService.UpdateProductFlag(inputData)
        .then((response) => {
          debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;
          setApplyLoading(false);
          if (message == "Success") {
            NotificationManager.success("Data Updated Successfully.");
          } else {
            NotificationManager.error("Data Not Updated.");
          }
        })
        .catch((error) => {
          setApplyLoading(false);
          console.log(error);
        });
    } catch (error) {
      setApplyLoading(false);
      console.log(error);
    }
  };

  const handleAllNewFeature = (e, name) => {
    debugger;
    let value = "0";
    let arrIds = [];
    for (let i = 0; i < ViewProductList.length; i++) {
      arrIds.push(ViewProductList[i].gfInventoryID);
    }
    let IDs = arrIds.join(",");

    if (e.target.checked) {
      value = "1";
    }
    if (name === "NW") {
      setNewCheck(e.target.checked);
      const temp = ViewProductList.map((x) => {
        return { ...x, isNew: e.target.checked };
      });
      setViewProductList(temp);
    }
    if (name === "FE") {
      setFeatureCheck(e.target.checked);
      const temp = ViewProductList.map((x) => {
        return { ...x, isFeatured: e.target.checked };
      });
      setViewProductList(temp);
    }
    handleUpdateProductFlag(DealerId, IDs, name, value, "0");
  };

  const handleOnEditChange = (row, e, name) => {
    debugger;

    let value = "0";
    if (e.target.checked) {
      value = "1";
    }
    if (name === "NW") {
      const temp = ViewProductList.map((x) => {
        if (x.gfInventoryID === row.gfInventoryID) {
          return { ...x, isNew: e.target.checked };
        } else return x;
      });
      setViewProductList(temp);
    }
    if (name === "FE") {
      const temp = ViewProductList.map((x) => {
        if (x.gfInventoryID === row.gfInventoryID) {
          return { ...x, isFeatured: e.target.checked };
        } else return x;
      });
      setViewProductList(temp);
    }

    handleUpdateProductFlag(DealerId, row.gfInventoryID, name, value, DealerId);
  };

  const handleDeleteJewelry = (id) => {
    debugger;
    if (deleteType === "Multi") {
      var dataLength = ViewProductList.filter(
        (x) => x.isChecked === true
      ).length;
      var tempdeleteProductStr = ViewProductList.filter(
        (x) => x.isChecked === true
      )
        .map((x) => x.gfInventoryID)
        .join(",");
    } else if (deleteType === "Single") {
      var dataLength = 1;
      var tempdeleteProductStr = deleteId;
    }

    let inputData = {
      dealerID: id + "" || DealerId + "",
      statusChangeArray: "",
      deleteProduct: dataLength > 1 ? "All" : tempdeleteProductStr,
      status: "",
      jewelryIdList: tempdeleteProductStr,
      statusUncheckedArray: "",
      statusUncheckedArraySpecific: "",
    };
    setApplyLoading(true);
    try {
      CatalogJewelryDataService.UpdateJewelryStatusOrDeleteJewelry(inputData)
        .then((response) => {
          debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            NotificationManager.success("Product Deleted Successfully.");
            setVisible3(false);

            handleViewProduct(DealerId);
          } else {
            NotificationManager.error("Product Not Deleted.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    setVisible3(false);
  };

  const handleCancel3 = () => {
    setVisible3(false);
  };

  const setDeleteData = (type, item) => {
    debugger;

    if (type === "All") {
      setDeleteType("Multi");
      var dataLength = ViewProductList.filter(
        (x) => x.isChecked === true
      ).length;
      if (dataLength > 0) {
        setVisible3(true);
      } else {
        alert("Please select atleast one record.");
      }
    } else if (type === "Single") {
      setDeleteType("Single");

      setDeleteId(item.gfInventoryID + "");
      setVisible3(true);
    }
  };

  const handleChangeJewelryDisplayOrder = (id, ProductId, Order) => {
    debugger;
    let inputData = {
      dealerID: id + "" || DealerId + "",
      productID: ProductId + "",
      displayOrder: Order + "",
    };
    setApplyLoading(true);
    try {
      CatalogJewelryDataService.ChangeJewelryDisplayOrder(inputData)
        .then((response) => {
          debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;
          setApplyLoading(false);
          if (message == "Success") {
            NotificationManager.success("Updated Successfully.");
            handleViewProduct(DealerId);
          } else {
            NotificationManager.error("Data Not Updated.");
          }
        })
        .catch((error) => {
          setApplyLoading(false);
          console.log(error);
        });
    } catch (error) {
      setApplyLoading(false);
      console.log(error);
    }
  };

  // const OnOrdeColumnChange = (e, item) => {
  //   const temp = ViewProductList.map((x) => {
  //     if (x.gfInventoryID === item.gfInventoryID) {
  //       return { ...x, displayOrder: e.target.value };
  //     } else return x;
  //   });
  //   setViewProductList(temp);
  //   handleChangeJewelryDisplayOrder(DealerId, item.gfInventoryID, e.target.value);
  // }

  const menu = (
    <Menu className="drpdwn__moreaction">
      <Menu.Item onClick={() => setDeleteData("All", "")}>Delete</Menu.Item>
      <Menu.Item>New</Menu.Item>
      <Menu.Item>Featured</Menu.Item>
    </Menu>
  );

  const { Option } = Select;

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }
  const handleProductChangeOpen = (gfInventoryID) => {
    debugger;
    //setProductChange(false);
    state.productChange = {};
    state.productChange[gfInventoryID] = true;
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  const handleProductChangeClose = (gfInventoryID) => {
    debugger;
    //setProductChange(true);
    state.productChange = {};
    state.productChange[gfInventoryID] = false;
    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  const handleHideShowFilter = () => {
    setHideShowFilter(!hideShowFilter);
  };

  const handleViewChange = (viewname) => {
    setViewItem(viewname);
  };

  return (
    <React.Fragment>
      <div className="allprod_section">
        <div className="row">
          <div className="col-md-12">
            <div className="btn_div">
              <button className="primary-btn">
                <Link to={"/addProducts"}>
                  <img src={Create_icon} alt="" />
                  {traslate("CreateProduct")}
                </Link>
              </button>

              <button className="primary-btn">
                <Link to={"/uploadProductFile"}>
                  <img src={Upload_icon} alt="" />
                  {traslate("UploadFile")}
                </Link>
              </button>

              <Dropdown
                className="primary-btn"
                overlay={menu}
                placement="bottomCenter"
              >
                <Button>
                  {" "}
                  <img src={Create_icon} alt="" />
                  {traslate("MoreActions")}
                </Button>
              </Dropdown>
            </div>
          </div>

          <div className="col-md-12">
            <div className="filter_section form__fields">
              <div className="col-md-12">
                <div className="filt_optndiv">
                  <div className="gview__div">
                    <span
                      className={
                        viewItem === "grid"
                          ? "fa fa-th grid_icon active"
                          : "fa fa-th grid_icon"
                      }
                      onClick={() => handleViewChange("grid")}
                    ></span>
                    <span
                      className={
                        viewItem === "list"
                          ? "fa fa-th-list list_icon active"
                          : "fa fa-th-list list_icon"
                      }
                      onClick={() => handleViewChange("list")}
                    ></span>
                    {/*<img src={Gridview_icon} alt="" />
                                        <img src={Listview_icon} alt="" className="ml-2" />*/}
                    {/* <span className="page_txt ml-2">Page </span>
                    <span className="fa fa-caret-left left_icon"></span>
                    <span className="pageno__txt"> 1 </span>
                    <span className="fa fa-caret-right right_icon"></span>
                    <span className="ml-2">of 38884 pages</span> */}
                    <span className="ml-2">
                      <Pagination
                        showSizeChanger="true"
                        defaultCurrent={1}
                        total={TotalRowCount}
                        onChange={onShowSizeChange}
                        onShowSizeChange={onShowSizeChange}
                      />
                    </span>
                  </div>
                  <div className="drpdwn__div">
                    {/* <span className="mr-2">View</span>
                    <Select className="select_inpt" placeholder="10">
                      <option>12</option>
                      <option value="1">11</option>
                      <option value="2">14</option>
                      <option value="3">16</option>
                    </Select>
                    <span className="ml-2">per page</span> */}
                    <span className="ml-2">
                      <b>
                        {" "}
                        {traslate("Total")} {TotalRowCount}{" "}
                        {traslate("Product")}{" "}
                      </b>
                    </span>
                  </div>
                  <div className="filtbtn__div">
                    <span className="select__all">
                      <input
                        type="checkbox"
                        id="sel"
                        onClick={(e) => handleOnCheckGridAll(e)}
                      />
                      <label htmlFor="sel"> {traslate("SelectAll")} </label>
                    </span>
                    <button
                      className="primary-btn ml-2"
                      onClick={handleHideShowFilter}
                    >
                      <img src={Filter_icon} alt="" width="14px" />
                      {hideShowFilter ? (
                        <span>
                          {traslate("HideFilter")}{" "}
                          <i className="fa fa-angle-up"></i>
                        </span>
                      ) : (
                        <span>
                          {traslate("ShowFilter")}{" "}
                          <i className="fa fa-angle-down"></i>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div
                  className={
                    hideShowFilter
                      ? "form__fields filter_inpsection"
                      : "form__fields filter_inpsection hide"
                  }
                >
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {traslate("Category")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            mode="multiple"
                            showArrow
                            placeholder="Select Category"
                            optionFilterProp="children"
                            value={state.Catagory}
                            onChange={(e) => onChangeMultiDrop(e, "Catagory")}
                          >
                            {CategotyList.length > 0 ? (
                              <Option value={""}>All</Option>
                            ) : null}
                            {CategotyList.map((item, i) => {
                              return (
                                <Option
                                  disabled={state.disabled.Catagory}
                                  value={item.jewelryCategoryID}
                                >
                                  {item.jewelryCategory}({item.productCount})
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {traslate("MaterialColor")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            mode="multiple"
                            showArrow
                            placeholder="Select Material Color"
                            optionFilterProp="children"
                            value={state.MaterialColor}
                            onChange={(e) =>
                              onChangeMultiDrop(e, "MaterialColor")
                            }
                          >
                            {MaterialColor.length > 0 ? (
                              <Option value={""}>All</Option>
                            ) : null}
                            {MaterialColor.map((item, i) => {
                              return (
                                <Option
                                  disabled={state.disabled.MaterialColor}
                                  value={item.metalColorID}
                                >
                                  {item.metalColor}({item.productCount})
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {traslate("MaterialType")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            mode="multiple"
                            showArrow
                            placeholder="Select Material Type"
                            optionFilterProp="children"
                            value={state.MaterialType}
                            onChange={(e) =>
                              onChangeMultiDrop(e, "MaterialType")
                            }
                          >
                            {MaterialType.length > 0 ? (
                              <Option value={""}>All</Option>
                            ) : null}
                            {MaterialType.map((item, i) => {
                              return (
                                <Option
                                  disabled={state.disabled.MaterialType}
                                  value={item.metalTypeID}
                                >
                                  {item.metalType}({item.productCount})
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {traslate("Gender")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            mode="multiple"
                            showArrow
                            placeholder="Select Gender"
                            optionFilterProp="children"
                            value={state.Gender}
                            onChange={(e) => onChangeMultiDrop(e, "Gender")}
                          >
                            {GenderList.length > 0 ? (
                              <Option value={""}>All</Option>
                            ) : null}
                            {GenderList.map((item, i) => {
                              return (
                                <Option
                                  disabled={state.disabled.Gender}
                                  value={item.genderID}
                                >
                                  {item.gender}({item.productCount})
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {traslate("Collection")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            mode="multiple"
                            showArrow
                            placeholder="Select Collection"
                            optionFilterProp="children"
                            value={state.Collection}
                            onChange={(e) => onChangeMultiDrop(e, "Collection")}
                          >
                            {CollectionList.length > 0 ? (
                              <Option value={""}>All</Option>
                            ) : null}
                            {CollectionList.map((item, i) => {
                              return (
                                <Option
                                  disabled={state.disabled.Collection}
                                  value={item.collectionID}
                                >
                                  {item.collectionNames}({item.productCount})
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {traslate("Vendor")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            mode="multiple"
                            showArrow
                            placeholder="Select Vendor"
                            optionFilterProp="children"
                            value={state.Vendor}
                            onChange={(e) => onChangeMultiDrop(e, "Vendor")}
                          >
                            {VendorList.length > 0 ? (
                              <Option value={""}>All</Option>
                            ) : null}

                            {VendorList.map((item, i) => {
                              return (
                                <Option
                                  disabled={state.disabled.Vendor}
                                  value={item.vendorNameID}
                                >
                                  {item.vendorName}({item.productCount})
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="sliderrange__maindiv">
                          <label> {traslate("Price")} </label>
                          <div className="col-md-12 mt-3">
                            {/* <MultiRangeSlider
                              // min={state.minPrice}
                              // max={state.maxPrice}
                              min={0}
                              max={10000}
                              onChange={(range) => onchangeRange(range)}
                            /> */}
                            <MultiRangeSlider
                              min={0}
                              max={99999}
                              resetRange={resetRange}
                              onChange={({ min, max }) => {
                                debugger;
                                setState({
                                  ...state,
                                  minPrice: min,
                                  maxPrice: max,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="input__block">
                          <label> {traslate("Images")} </label>
                          <Select
                            className="border__grey"
                            showSearch
                            placeholder="Select Image"
                            optionFilterProp="children"
                            value={state.Image}
                            onChange={(e) => onChangeDrop(e, "Image")}
                          >
                            {ImageList.map((item, i) => {
                              return (
                                <Option value={item.hasImage}>
                                  {item.productCount}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 filtbtn_div">
                        <button
                          className="primary-btn mr-2"
                          onClick={onResetFilter}
                        >
                          {" "}
                          {traslate("ResetFilters")}
                        </button>
                        <Button
                          className="primary-btn"
                          onClick={() => handleViewProduct(DealerId)}
                        >
                          {" "}
                          {traslate("ApplyFilters")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {viewItem === "grid" && (
                <div className="col-md-12">
                  <Spin spinning={applyLoading}>
                    <hr className="mt-4 mb-4" />
                    <div className="prod__section">
                      <div className="row">
                        {ViewProductList.map((item, i) => {
                          return (
                            <div className="col-lg-3 col-md-6">
                              {/* <Link
                                to={"/productDetails"}
                                state={{ productData: item }}
                              > */}
                              <div className="prod__maindiv">
                                <div className="chkbx__div">
                                  <Checkbox
                                    checked={item.isChecked}
                                    onChange={(e) =>
                                      handleOnCheckGrid(e, item.gfInventoryID)
                                    }
                                  />
                                </div>
                                <div className="prodimg__div">
                                  {/* <img src={Rectangle} alt="" /> */}
                                  <img src={item.imagepath} alt="" />
                                </div>
                                <div className="details__div">
                                  <span className="style__txt">
                                    {traslate("StyleNo")}: {item.styleNumber}
                                  </span>
                                  <span className="price__txt">
                                    ${item.retailPrice}
                                  </span>
                                </div>
                                <div className="editdel__div">
                                  <div>
                                    <img src={Video_icon} alt="" />
                                  </div>
                                  <div>
                                    <Link
                                      to={"/addProducts"}
                                      state={{
                                        key: item.gfInventoryID,
                                      }}
                                    >
                                      <img src={Edit_icon} alt="" />
                                    </Link>
                                  </div>

                                  <div
                                    onClick={() =>
                                      setDeleteData("Single", item)
                                    }
                                  >
                                    <img src={Delete_iconbl} alt="" />
                                  </div>
                                </div>
                                {!state.productChange[item.gfInventoryID] ? (
                                  <div
                                    className="info__div"
                                    onClick={() =>
                                      handleProductChangeOpen(
                                        item.gfInventoryID
                                      )
                                    }
                                  >
                                    <img src={Info_icon} alt="" />
                                  </div>
                                ) : (
                                  <div className="descr__div">
                                    <div>
                                      <p>
                                        <span> {traslate("StyleNo")} </span>
                                        <span>{item.styleNumber}</span>
                                      </p>
                                      <p>
                                        <span>
                                          {" "}
                                          {traslate("GFinventoryNo")}{" "}
                                        </span>
                                        <span>#{item.gfInventoryID}</span>
                                      </p>
                                      <p>
                                        <span> {traslate("ProductName")} </span>
                                        <span>{item.productName}</span>
                                      </p>
                                      <p>
                                        <span> {traslate("Category")} </span>
                                        <span> {item.categoryName}</span>
                                      </p>
                                      <p>
                                        <span> {traslate("Collection")} </span>
                                        <span>{item.collectionName}</span>
                                      </p>
                                      <p>
                                        <span> {traslate("VendorName")} </span>
                                        <span>{item.vendorName}</span>
                                      </p>
                                      <p>
                                        <span>
                                          {" "}
                                          {traslate("WholesalePrice")}{" "}
                                        </span>
                                        <span>${item.wholesaleprice}</span>
                                      </p>
                                      <p>
                                        <span> {traslate("RetailPrice")} </span>
                                        <span>${item.retailPrice}</span>
                                      </p>
                                    </div>
                                    <div
                                      className="close__div"
                                      onClick={() =>
                                        handleProductChangeClose(
                                          item.gfInventoryID
                                        )
                                      }
                                    >
                                      <img src={Close} alt="" />
                                    </div>
                                  </div>
                                )}
                              </div>
                              {/* </Link> */}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Spin>
                </div>
              )}
              {viewItem === "list" && (
                <div className="col-md-12 mt-4">
                  <div className="lviewprod__tbl">
                    {/* <Table columns={columns} dataSource={data} scroll={{ y: 300 }} /> */}

                    <Table
                      const
                      columns={[
                        {
                          title: "",
                          dataIndex: "isChecked",
                          key: "isChecked",
                          render: (row, item) => {
                            return (
                              <Checkbox
                                checked={item.isChecked}
                                onChange={(e) =>
                                  handleOnCheckGrid(e, item.gfInventoryID)
                                }
                              />
                            );
                          },
                        },
                        {
                          title: "",
                          dataIndex: "image",
                          key: "image",
                          render: (row, item) => {
                            return <img src={item.imagepath} alt="" />;
                          },
                          width: 60,
                        },
                        {
                          title: traslate("Product"),
                          dataIndex: "productName",
                          key: "productName",
                        },
                        {
                          title: traslate("StyleNumber"),
                          dataIndex: "styleNumber",
                          key: "styleNumber",
                        },
                        {
                          title: traslate("Gemfind#"),
                          dataIndex: "gfInventoryID",
                          key: "gfInventoryID",
                          render: (row, item) => {
                            return <span>#{item.gfInventoryID}</span>;
                          },
                        },
                        {
                          title: traslate("Category"),
                          dataIndex: "categoryName",
                          key: "categoryName",
                        },
                        {
                          title: traslate("Collection"),
                          dataIndex: "collectionName",
                          key: "collectionName",
                        },
                        {
                          title: traslate("VendorName"),
                          dataIndex: "vendorName",
                          key: "vendorName",
                        },
                        {
                          title: traslate("$cost"),
                          dataIndex: "wholesaleprice",
                          key: "wholesaleprice",
                          render: (row, item) => {
                            return <span>${item.wholesaleprice}</span>;
                          },
                        },
                        {
                          title: traslate("$Retail"),
                          dataIndex: "retail",
                          key: "retail",
                          render: (row, item) => {
                            return <span>${item.retailPrice}</span>;
                          },
                        },

                        {
                          title: (
                            <span>
                              <Checkbox
                                checked={newCheck}
                                onChange={(e) => handleAllNewFeature(e, "NW")}
                              />
                              {traslate("New")}
                            </span>
                          ),
                          dataIndex: "isNew",
                          key: "isNew",

                          render: (row, item) => {
                            return (
                              <Checkbox
                                checked={item.isNew}
                                onChange={(e) =>
                                  handleOnEditChange(item, e, "NW")
                                }
                              />
                            );
                          },
                        },

                        {
                          title: (
                            <span>
                              {" "}
                              <Checkbox
                                checked={featureCheck}
                                onChange={(e) => handleAllNewFeature(e, "FE")}
                              />
                              {traslate("Feature")}
                            </span>
                          ),
                          dataIndex: "isFeatured",
                          key: "isFeatured",

                          render: (row, item) => {
                            return (
                              <Checkbox
                                checked={item.isFeatured}
                                onChange={(e) =>
                                  handleOnEditChange(item, e, "FE")
                                }
                              />
                            );
                          },
                        },
                        // {
                        //   title: "Order",
                        //   dataIndex: "displayOrder",
                        //   key: "displayOrder",

                        //   render: (row, item) => {
                        //     return (
                        //       <input
                        //         type="number"
                        //         className="dmrkup__inpt"
                        //         value={item.displayOrder}
                        //         onChange={(e) => OnOrdeColumnChange(e, item)}
                        //       />
                        //     );
                        //   },
                        // },
                        {
                          title: "",
                          dataIndex: "actions",
                          key: "actions",
                          render: (row, item) => {
                            return (
                              <Dropdown
                                className=""
                                overlay={
                                  <Menu className="drpdwn__icons">
                                    <Menu.Item>
                                      <img src={Video_icon} alt="" />
                                    </Menu.Item>
                                    <Menu.Item>
                                      <Link
                                        to={"/AddProducts"}
                                        state={{
                                          key: item.gfInventoryID,
                                        }}
                                      >
                                        <div>
                                          <img src={Edit_icon} alt="" />
                                        </div>
                                      </Link>
                                    </Menu.Item>
                                    <Menu.Item
                                      onClick={() =>
                                        setDeleteData("Single", item)
                                      }
                                    >
                                      <img src={Delete_iconbl} alt="" />
                                    </Menu.Item>
                                    <Menu.Item>
                                      <Link
                                        to={"/productDetails"}
                                        state={{ productData: item }}
                                      >
                                        <div>
                                          <img src={Eye_icon} alt="" />
                                        </div>
                                      </Link>
                                    </Menu.Item>
                                  </Menu>
                                }
                                onClick={() => setSelectActionRow(item)}
                                placement="bottomRight"
                              >
                                <img
                                  src={Ellipsis_icon}
                                  className="ellipicon__img"
                                  alt=""
                                />
                              </Dropdown>
                            );
                          },
                          width: 30,
                        },
                      ]}
                      // rowKey={(record) => record.gfInventoryID}
                      // rowSelection={rowSelection}
                      pagination={false}
                      loading={applyLoading}
                      dataSource={ViewProductList}
                      // scroll={{ y: 300 }}
                    />
                  </div>
                  <div className="mt-4">
                    <span>
                      <Pagination
                        defaultCurrent={1}
                        total={TotalRowCount}
                        onChange={onShowSizeChange}
                        onShowSizeChange={onShowSizeChange}
                        showSizeChanger="true"
                      />
                    </span>
                  </div>
                </div>
              )}
            </div>

            <Modal
              title=""
              className="modalconsupld__section"
              centered
              visible={visible3}
              onOk={() => setVisible3(false)}
              onCancel={() => setVisible3(false)}
              width={400}
              footer={[
                <Button key="back" onClick={handleCancel3}>
                  {traslate("Cancel")}
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => handleDeleteJewelry(DealerId)}
                >
                  {traslate("Delete")}
                </Button>,
              ]}
            >
              <div className="col-lg-12">
                <div className="modal__body">
                  <div className="col-lg-12">
                    <div className="form__fields border-0 p-0">
                      <div className="row">
                        <div className="col-lg-12 mt-4 text-center">
                          <p>
                            {traslate(
                              "AreyousureyouwanttodeletethisProduct(s)?"
                            )}
                          </p>
                          <h5> {traslate("THISCANNOTBEUNDONE")} </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Allproducts;
