import React, { useState, useEffect } from "react";
import { Select, Button, Spin } from "antd";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../../i18n/translate";

const { Option } = Select;
const ReportsPermissions = () => {
  const initialState = {
    dealerId: null,
    clientType: null,
    diamondCharts: false,
    jewelryCharts: false,
    globalDiamondCharts: false,
    globalJewelryCharts: false,
  };
  const [dealerList, setDealerList] = useState([]);
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  useEffect(() => {
    GetUsersList();
  }, []);
  const onChange = (name, e) => {
    let tempState = state;

    if (name == "clientType") {
      tempState["dealerId"] = null;
      setDealerList([]);
      setTimeout(() => {
        GetUsersList();
      }, 1000);
    }
    tempState[name] = e;

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    if (name === "dealerId") {
      tempState["diamondCharts"] = false;
      tempState["jewelryCharts"] = false;
      tempState["globalDiamondCharts"] = false;
      tempState["globalJewelryCharts"] = false;
      setState((prevState) => ({
        ...prevState,
        ...tempState,
      }));
      setLoading(true);
      setTimeout(() => {
        handleGetReportPermissionsList();
      }, 500);
    }
  };

  const GetUsersList = () => {
    try {
      let inputData = {
        clientType: state.clientType || "",
      };
      AdminToolsDataService.GetUsersList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setDealerList(responseData);
          } else {
            setDealerList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetReportPermissionsList = () => {
    debugger;
    let inputData = {
      dealerID: state.dealerId,
    };
    try {
      setLoading(true);
      AdminToolsDataService.GetReportPermissionsList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            const tempState = state;
            tempState["diamondCharts"] = responseData[0].diamondCharts;
            tempState["globalDiamondCharts"] =
              responseData[0].globalDiamondCharts;
            tempState["globalJewelryCharts"] =
              responseData[0].globalJewelryCharts;
            tempState["jewelryCharts"] = responseData[0].jewelryCharts;
            setState((prevState) => ({
              ...prevState,
              ...tempState,
            }));
          } else {
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleSaveReportPermissions = () => {
    let inputData = {
      dealerId: state.dealerId,
      diamondCharts: state.diamondCharts,
      jewelryCharts: state.jewelryCharts,
      globalDiamondCharts: state.globalDiamondCharts,
      globalJewelryCharts: state.globalJewelryCharts,
    };
    try {
      setSubmitLoading(true);
      AdminToolsDataService.SaveReportPermissions(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setSubmitLoading(false);
          if (message == "Success") {
            NotificationManager.success("Record Submited Successfully.");
            setState(initialState);

          } else {
            NotificationManager.error("Record Not Submited.");
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };
  const handleCheckboxChange = (e, name) => {
    let tempState = state;
    tempState[name] = e.target.checked;
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };
  return (
    <React.Fragment>
      <div className="rpermission__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {/* {traslate("ReportsPermissions")} */}
                Report Permission Control
              </h4>
            </div>

            <div className="col-lg-3 col-md-6 mt-4">
              <div className="input__block">
                <label>
                  {" "}
                  {traslate("SelectClientType")} <span>*</span>
                </label>
                <Select
                  className="border__grey"
                  showSearch
                  placeholder="Select Dealer"
                  optionFilterProp="children"
                  notFoundContent="No Data Found"
                  value={state.clientType}
                  onChange={(e) => onChange("clientType", e)}
                >
                  <Option value={null}>{traslate("All")}</Option>
                  <Option value={"vendordiamond"}>
                    {traslate("DiamondDealers")}
                  </Option>
                  <Option value={"retailer"}>{traslate("Retailers")}</Option>
                  <Option value={"vendor"}>{traslate("Vendors")}</Option>
                </Select>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-4">
              <div className="input__block">
                <label>
                  {" "}
                  {traslate("ClientName")} <span>*</span>
                </label>
                <Select
                  className="border__grey"
                  showSearch
                  placeholder="Select Dealer"
                  optionFilterProp="children"
                  notFoundContent="No Data Found"
                  value={state.dealerId}
                  onChange={(e) => onChange("dealerId", e)}
                >
                  <Option value="">Select Dealer</Option>
                  {dealerList.map((item, i) => {
                    return (
                      <Option value={item.dealerID}>
                        {item.dealerCompany + " (" + item.dealerID + " )"}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
            {loading ? (
              <Spin size="large" />
            ) : state.dealerId ? (
              <React.Fragment>
                <div className="col-lg-12">
                  <div className="chckbox__maindiv">
                    <div>
                      <input
                        type="checkbox"
                        id="dr"
                        onChange={(e) =>
                          handleCheckboxChange(e, "diamondCharts")
                        }
                        checked={state.diamondCharts}
                      />
                      <label htmlFor="dr">{traslate("DiamondReports")}</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        id="jr"
                        onChange={(e) =>
                          handleCheckboxChange(e, "jewelryCharts")
                        }
                        checked={state.jewelryCharts}
                      />
                      <label htmlFor="jr">{traslate("JewelryReports")}</label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        id="gdr"
                        onChange={(e) =>
                          handleCheckboxChange(e, "globalDiamondCharts")
                        }
                        checked={state.globalDiamondCharts}
                      />
                      <label htmlFor="gdr">
                        {traslate("GlobalDiamondReports")}
                      </label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        id="gjr"
                        onChange={(e) =>
                          handleCheckboxChange(e, "globalJewelryCharts")
                        }
                        checked={state.globalJewelryCharts}
                      />
                      <label htmlFor="gjr">
                        {traslate("GlobalJewelryReports")}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 mt-4">
                  <Button
                    className="primary-btn"
                    loading={submitLoading}
                    onClick={handleSaveReportPermissions}
                  >
                   {traslate("Submit")} 
                  </Button>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReportsPermissions;
