import React, { useState } from "react";
import { connect } from "react-redux";
import { Input, Row, Col, Select, Checkbox, Button, Modal, Switch } from "antd";
import { UserAddOutlined } from "@ant-design/icons";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const MasterLinkEmailTemplate = (props) => {
    const [switchValue, setswitchValue] = useState(true);
    const { Option } = Select;

    const [visible, setVisible] = useState(false);

    function onChange(value) {
        console.log(`selected ${value}`);
    }

    function onSearch(val) {
        console.log("search:", val);
    }

    function onChange(e) {
        console.log(`checked = ${e.target.checked}`);
    }

    const handleSwitch = () => {
        setswitchValue(!switchValue);
    };

    console.log(`"Switch value":${switchValue}`);

    return (
        <div className="form__fields">
            <div className="row">
                <div className="col-md-12">
                    <h4 className="workarea__heading mt-0">Contact Emails (MasterLink)</h4>
                </div>
            </div>


            <div className="emailTemplate">
                <div className="row">
                    <div className="col-md-4 mb-4">
                        <div className="emailDetailBlock">
                            <h1>Customer Registration</h1>
                            <ul>
                                <li><a href="#" onClick={() => setVisible(true)}>Customer Registration</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-4 mb-4">
                        <div className="emailDetailBlock">
                            <h1>New Appointments</h1>
                            <ul>
                                <li><a href="#" onClick={() => setVisible(true)}>New Appointments</a></li>

                            </ul>
                        </div>
                    </div>

                    <div className="col-md-4 mb-4">
                        <div className="emailDetailBlock">
                            <h1>Inquires (Request More Info)</h1>
                            <ul>
                                <li><a href="#" onClick={() => setVisible(true)}>Send Mail to Retailer</a></li>
                                <li><a href="#" onClick={() => setVisible(true)}>Send Mail to Sender</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-4 mb-4">
                        <div className="emailDetailBlock">
                            <h1>Inquires (Request More Info)</h1>
                            <ul>
                                <li><a href="#" onClick={() => setVisible(true)}>Send Mail to Friend</a></li>
                                <li><a href="#" onClick={() => setVisible(true)}>Send Mail to Retailer</a></li>
                                <li><a href="#" onClick={() => setVisible(true)}>Send Mail to Sender</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-4 mb-4">
                        <div className="emailDetailBlock">
                            <h1>Drop A Hint</h1>
                            <ul>
                                <li><a href="#" onClick={() => setVisible(true)}>Send Mail to Recipient</a></li>
                                <li><a href="#" onClick={() => setVisible(true)}>Send Mail to Retailer</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-4 mb-4">
                        <div className="emailDetailBlock">
                            <h1>New Favourite List</h1>
                            <ul>
                                <li><a href="#" onClick={() => setVisible(true)}>New Favourite List</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-4 mb-4">
                        <div className="emailDetailBlock">
                            <h1>New Favourite Items Added</h1>
                            <ul>
                                <li><a href="#" onClick={() => setVisible(true)}>New Favourite Items Added</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-4 mb-4">
                        <div className="emailDetailBlock">
                            <h1>Conversations</h1>
                            <ul>
                                <li><a href="#" onClick={() => setVisible(true)}>Conversations</a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-4 mb-4">
                        <div className="emailDetailBlock">
                            <h1>Customer Reset Password</h1>
                            <ul>
                                <li><a href="#" onClick={() => setVisible(true)}>Customer Reset Password</a></li>
                            </ul>
                        </div>
                    </div>
                </div>


            </div>

            <Modal className="emailTemplateEditorModal"
                title="Send Email"
                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={800}
                hieght={600}
            >

                <div className="editor__section">
                    <CKEditor
                        editor={ClassicEditor}
                        data=""
                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            console.log({ event, editor, data });
                        }}
                        onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                        }}
                    />
                </div>

            </Modal>
        </div>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MasterLinkEmailTemplate);
