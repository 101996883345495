import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Modal, Select } from "antd";
import { Link } from "react-router-dom";
import logoImg from "../../assets/images/logo-img.png";
import footerLogoImg from "../../assets/images/footer-logo.png";
import footerCompanyLogo1 from "../../assets/images/footerlogo1.png";
import footerCompanyLogo2 from "../../assets/images/footerlogo2.png";
import footerCompanyLogo3 from "../../assets/images/footerlogo3.png";
import footerCompanyLogo4 from "../../assets/images/footerlogo4.png";
import footerCompanyLogo5 from "../../assets/images/footerlogo5.png";

const RequestAppointment = (props) => {
  const [visible, setVisible] = useState(false);

  const { Option } = Select;


  

  return (
    <div className="login__section register__section container-fluid">
      <div className="form__fields">
        <div className="row">
          <div className="col-md-12">
            <div className="login__header">
              <div className="logo__section">
                <img src={logoImg} />
              </div>

              <div className="right__text">
                <p>Social Product Network for the Jewelry Industry</p>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form__section">
              <div className="login__block">
                <h3>Request An Appointment To Learn More</h3>
                <div className="row">
                    <div className="col-md-6">
                        <div className="input__block">
                            <label>Name</label>
                            <input type="text" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input__block">
                            <label>Company</label>
                            <input type="text" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input__block">
                            <label>Title</label>
                            <input type="text" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input__block">
                            <label>E-Mail</label>
                            <input type="email" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input__block">
                            <label>Date</label>
                            <input type="date" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row align-items-center">
                            <div className="col-md-9">
                                <div className="input__block">
                                    <label>Time</label>
                                    <input type="time" />
                                </div>
                            </div>
                            <div className="col-md-3 input__block mb-0">
                                <div className="radio__block mb-0">
                                    <input type="radio" />
                                    <label>AM</label>
                                </div>
                                <div className="radio__block mb-0">
                                    <input type="radio" />
                                    <label>PM</label>
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>
                    <div className="col-md-6">
                        <div className="input__block">
                            <label>Phone</label>
                            <input type="number" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="input__block">
                            <label>Ext</label>
                            <input type="number" />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row input__block">
                            <div className="col-6">
                                <div className="radio__block">
                                    <input type="checkbox" />
                                    <label>Retail Jeweler</label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="radio__block">
                                    <input type="checkbox" />
                                    <label>Jewelry Supplier</label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="radio__block">
                                    <input type="checkbox" />
                                    <label>Diamond Supplier</label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="radio__block">
                                    <input type="checkbox" />
                                    <label>Watch Supplier</label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="radio__block">
                                    <input type="checkbox" />
                                    <label>Colored Gemstone Supplier</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 mx-auto mt-4">
                    <div className="input__block">
                      <Button
                        type="primary"
                        htmlType="button"
                        className="primary-btn w-100"
                      >
                        Schedule Appointment
                      </Button>
                    </div>
                  </div>


                </div>     
              </div>
            </div>
          </div>

          <div className="col-md-12 p-0">
            <div className="footer__section login__footer">
              <div className="left__block">
                <div className="footer__logo">
                  <img src={footerLogoImg} />
                </div>
                <div className="footer__menu">
                  <ul>
                    <li>
                      <a href="#">Terms of Service</a>
                    </li>
                    <li>
                      <a href="#">Privacy Statement</a>
                    </li>
                    <li>
                      <a href="#">Copyright 2021 GemFind</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="right__block">
                <ul>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo1} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo2} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo3} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo4} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo5} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestAppointment;
