import React, { useRef, useState } from "react";
import { Input, Row, Col, Select, Checkbox, Switch, Table, Menu, Dropdown, Button, Space, Tooltip,Slider } from "antd";
import Filter_icon from "../../assets/images/filter_icon.png";
import Rectangle from "../../assets/images/Rectangle.png";
import Info_icon from "../../assets/images/info_icon.png";
import Video_icon from "../../assets/images/icons/video_icon.svg";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";
import UnionIcon from "../../assets/images/icons/Union_5.svg";
import Close from "../../assets/images/close.png";
import { createBootstrapComponent } from "react-bootstrap/esm/ThemeProvider";
import MultiRangeSlider from "../contacts/MultiRangeSlider";
import traslate from "../../i18n/translate";

//Initial Watch Data
const myWatchData = {
    category: null,
    materialType: null,
    genderSelect: null,
    vendor: null,
    materialColor: null,
    gemstone: null,
    collectionSelect: null,
    priceMin: 0,
    priceMax: 2000
}

const JewelrySearch = () => {
    const [productChange, setProductChange] = useState(true)
    const [viewItem, setViewItem] = useState("list");
    const [hideShowFilter, setHideShowFilter] = useState(true)

    const resetRange = useRef(null);
    const [filterWatchValue, setfilterWatchValue] = useState(myWatchData);

    // Slider
    const [priceValue, setPriceValue] = useState([0, 2000]);

    //Onchange Event
    const handleOnChange = (eValue, name) => {
        setfilterWatchValue({
            ...filterWatchValue,
            [name]: eValue,
        });
    };

    //Apply Filters
    const applyFilters = () => {
        console.log(filterWatchValue);
    }

    //Reset Filters
    const resetFilters = () => {
        setfilterWatchValue({
            category: null,
            materialType: null,
            genderSelect: null,
            vendor: null,
            materialColor: null,
            gemstone: null,
            collectionSelect: null,
            priceMin: 0,
            priceMax: 2000
        });
        // resetRange.current();
        setPriceValue([filterWatchValue.priceMin, filterWatchValue.priceMax]);
    }

    const gender = (
        <span>{traslate("Genderisnotarequiredfieldforvendordata,sobyleavingthisblank,themajorityofproductresultswillbeconsideredLadies.")}</span>
    );

    const collection = (
        <span> {traslate("Pleaseselectavendorbeforechoosingacollection.")} </span>
    );

    const columns = [
        {
            title: '',
            dataIndex: 'image',
            width: 60
        },
        {
            title: traslate("RetailStock#"),
            dataIndex: 'retailstock',
            width: 300
        },
        {
            title: traslate("Category"),
            dataIndex: 'category',
        },
        {
            title: traslate("Vendor"),
            dataIndex: 'vendor',
        },
        {
            title: traslate("Collection"),
            dataIndex: 'collection',
        },
        {
            title: traslate("$cost"),
            dataIndex: 'cost',
        },
        {
            title: traslate("$Retail"),
            dataIndex: 'retail',
        }
    ];

    const data = [
        {
            key: '1',
            image: <img src={Rectangle} className="prod__img" alt="" />,
            retailstock: <span>14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>,
            category: <span>Pendants, Gemstone Pendant</span>,
            vendor: <span>Parlé Jewelry Design</span>,
            collection: <span>Opal</span>,
            cost: <span>$95.00</span>,
            retail: <span>$190.00</span>
        },

        {
            key: '2',
            image: <img src={Rectangle} className="prod__img" alt="" />,
            retailstock: <span>14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>,
            category: <span>Pendants, Gemstone Pendant</span>,
            vendor: <span>Parlé Jewelry Design</span>,
            collection: <span>Opal</span>,
            cost: <span>$95.00</span>,
            retail: <span>$190.00</span>
        },

        {
            key: '3',
            image: <img src={Rectangle} className="prod__img" alt="" />,
            retailstock: <span>14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>,
            category: <span>Pendants, Gemstone Pendant</span>,
            vendor: <span>Parlé Jewelry Design</span>,
            collection: <span>Opal</span>,
            cost: <span>$95.00</span>,
            retail: <span>$190.00</span>,
        },

        {
            key: '4',
            image: <img src={Rectangle} className="prod__img" alt="" />,
            retailstock: <span>14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>,
            category: <span>Pendants, Gemstone Pendant</span>,
            vendor: <span>Parlé Jewelry Design</span>,
            collection: <span>Opal</span>,
            cost: <span>$95.00</span>,
            retail: <span>$190.00</span>
        }
    ];

    const { Option } = Select;

    function onChange(value) {
        console.log(`selected ${value}`);
    }

    function onSearch(val) {
        console.log("search:", val);
    }

    function onChange(e) {
        console.log(`checked = ${e.target.checked}`);
    }
    const handleProductChangeOpen = () => {
        setProductChange(false)
    }

    const handleProductChangeClose = () => {
        setProductChange(true)
    }

    const handleHideShowFilter = () => {
        setHideShowFilter(!hideShowFilter)
    }

    const handleViewChange = (viewname) => {
        setViewItem(viewname)
    }

    //Table Rows Checkbox Logic
    const [selectedRows, setSelectedRows] = useState([]);

    const rowSelection = {
        onChange: (_, selectedRows) => {
            setSelectedRows(selectedRows);
            console.log(selectedRows);
        },

        selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    };

    return (
        <React.Fragment>
            <div className="allprod_section jwlsrch__section form__fields">
                <div className="row">

                    <div className="col-md-12">
                        <div className="filter_section p-0 m-0">

                            <div className="col-md-12">
                                <h4 className="workarea__heading mt-0 mb-0">
                                {traslate("JewelrySearch")}
                                </h4>
                            </div>

                            <div className="col-md-12 mt-5">
                                <div className="filt_optndiv">
                                    <div className="gview__div">
                                        <span className={viewItem === "grid" ? "fa fa-th grid_icon active" : "fa fa-th grid_icon"} onClick={() => handleViewChange("grid")}></span>
                                        <span className={viewItem === "list" ? "fa fa-th-list list_icon active" : "fa fa-th-list list_icon"} onClick={() => handleViewChange("list")}></span>

                                        <span className="rslsrch__txt"> {traslate("Thereare")} <span className="no__txt">166,297</span> {traslate("resultsforyoursearch")}.</span>
                                    </div>

                                    <div className="filtbtn__div">
                                        <span className="selct__txt"><i class="fa fa-list-ul"></i> {selectedRows.length}{" "}  {traslate("Selected")} </span>
                                        <button class="primary-btn ml-2"><i class="fa fa-eye"></i> {traslate("View")} </button>
                                        <button class="primary-btn ml-2"><i class="fa fa-print"></i> {traslate("Print")} </button>
                                        <button className="primary-btn ml-2" onClick={handleHideShowFilter}>
                                            <img src={Filter_icon} alt="" width="14px" />
                                            {hideShowFilter ? <span> {traslate("ShowFilter")} <i className="fa fa-angle-down"></i></span> :
                                                <span> {traslate("HideFilter")} <i className="fa fa-angle-up"></i></span>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-12">
                                <div className={hideShowFilter ? "form__fields filter_inpsection hide" : "form__fields filter_inpsection"}>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6">
                                                <div className="input__block">
                                                    <label> {traslate("Category")} </label>
                                                    <Select
                                                        className="border__grey"
                                                        placeholder="Select"
                                                        onSearch={onSearch}
                                                        showSearch
                                                        value={filterWatchValue.category}
                                                        onChange={eValue => handleOnChange(eValue, "category")}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value="categOne">Category 1</Option>
                                                        <Option value="categTwo">Category 2</Option>
                                                        <Option value="categThree">Category 3</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="input__block">
                                                    <label> {traslate("MaterialType")} </label>
                                                    <Select
                                                        className="border__grey"
                                                        showSearch
                                                        placeholder="Select Material Type"
                                                        optionFilterProp="children"
                                                        value={filterWatchValue.materialType}
                                                        onChange={eValue => handleOnChange(eValue, "materialType")}
                                                        onSearch={onSearch}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value="materialOne">Material 1</Option>
                                                        <Option value="materialTwo">Material 2</Option>
                                                        <Option value="tom">Material 3</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="input__block">
                                                    <label> {traslate("Gender")}
                                                        <Tooltip placement="right" title={gender}>
                                                            <img src={UnionIcon} alt="" />
                                                        </Tooltip>
                                                    </label>
                                                    <Select
                                                        className="border__grey"
                                                        showSearch
                                                        placeholder="Select Gender"
                                                        optionFilterProp="children"
                                                        value={filterWatchValue.genderSelect}
                                                        onChange={eValue => handleOnChange(eValue, "genderSelect")}
                                                        onSearch={onSearch}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value="all">All</Option>
                                                        <Option value="child">Child(0)</Option>
                                                        <Option value="ladies">Ladies(2344)</Option>
                                                        <Option value="men">Mens(1234)</Option>
                                                        <Option value="teens">Teen(4)</Option>
                                                        <Option value="unisex">Unisex(2172)</Option>
                                                        <Option value="women">Womens(5576)</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="input__block">
                                                    <label> {traslate("Vendor")} </label>
                                                    <Select
                                                        className="border__grey"
                                                        showSearch
                                                        placeholder="Select Vendor"
                                                        optionFilterProp="children"
                                                        value={filterWatchValue.vendor}
                                                        onChange={eValue => handleOnChange(eValue, "vendor")}
                                                        onSearch={onSearch}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value="vendorOne">Vendor 1</Option>
                                                        <Option value="vendorTwo">Vendor 2</Option>
                                                        <Option value="male">Abc</Option>
                                                        <Option value="female">Xyz</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="input__block">
                                                    <label> {traslate("MaterialColor")} </label>
                                                    <Select
                                                        className="border__grey"
                                                        showSearch
                                                        placeholder="Select Material Color"
                                                        optionFilterProp="children"
                                                        value={filterWatchValue.materialColor}
                                                        onChange={eValue => handleOnChange(eValue, "materialColor")}
                                                        onSearch={onSearch}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value="red">Red</Option>
                                                        <Option value="blue">Blue</Option>
                                                        <Option value="tom">Tom</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="input__block">
                                                    <label> {traslate("Gemstone")} </label>
                                                    <Select
                                                        className="border__grey"
                                                        showSearch
                                                        placeholder="Select Image"
                                                        optionFilterProp="children"
                                                        value={filterWatchValue.gemstone}
                                                        onChange={eValue => handleOnChange(eValue, "gemstone")}
                                                        onSearch={onSearch}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value="gemstoneOne">Gemstone 1</Option>
                                                        <Option value="gemstoneTwo">Gemstone 2</Option>
                                                        <Option value="gemstoneThree">Gemstone 3</Option>
                                                        <Option value="gemstoneFour">Xyz</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="input__block">
                                                    <label> {traslate("Collection")}
                                                        <Tooltip placement="right" title={collection}>
                                                            <img src={UnionIcon} alt="" />
                                                        </Tooltip>
                                                    </label>
                                                    <Select
                                                        className="border__grey"
                                                        showSearch
                                                        placeholder="Select Collection"
                                                        optionFilterProp="children"
                                                        value={filterWatchValue.collectionSelect}
                                                        onChange={eValue => handleOnChange(eValue, "collectionSelect")}
                                                        onSearch={onSearch}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value="collectionOne">Collection 1</Option>
                                                        <Option value="collectionTwo">Collection 2</Option>
                                                        <Option value="collectionThree">Collection 3</Option>
                                                        <Option value="collectionFour">Collection 4</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="sliderrange__maindiv">
                                                    <label> {traslate("Price")} </label>
                                                    <div className="col-md-12 mt-3">
                                                    <Slider
                                                        range
                                                        value={priceValue}
                                                        min={filterWatchValue.priceMin}
                                                        max={filterWatchValue.priceMax}
                                                        onChange={(e) => {
                                                        setPriceValue(e);
                                                        }}
                                                    />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 filtbtn_div">
                                                <button className="primary-btn mr-2" onClick={resetFilters}> {traslate("ResetFilters")} </button>
                                                <button className="primary-btn" onClick={applyFilters}> {traslate("ApplyFilters")} </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {viewItem === "grid" &&

                                <div className="col-md-12">
                                    <hr className="mt-4 mb-4" />
                                    <div className="prod__section">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6">
                                                <div className="prod__maindiv">
                                                    <div className="chkbx__div">
                                                        <input type="checkbox" />
                                                    </div>
                                                    <div className="prodimg__div">
                                                        <img src={Rectangle} alt="" />
                                                    </div>
                                                    <div className="details__div">
                                                        <span className="style__txt">14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>
                                                        <span className="price__txt">$190.00</span>
                                                    </div>
                                                    <div className="editdel__div">
                                                        <div>
                                                            <img src={Video_icon} alt="" />
                                                        </div>
                                                        <div>
                                                            <img src={Edit_icon} alt="" />
                                                        </div>
                                                        <div>
                                                            <img src={Delete_iconbl} alt="" />
                                                        </div>
                                                    </div>
                                                    {
                                                        productChange ? <div className="info__div" onClick={handleProductChangeOpen}>
                                                            <img src={Info_icon} alt="" />
                                                        </div>
                                                            :


                                                            <div className="descr__div">
                                                                <div>
                                                                <p><span> {traslate("RetaileStock#")} </span><span>POD267ADXCI</span></p>
                                                                    <p><span> {traslate("Category")} </span><span>Pendants, Gemstone Pendant</span></p>
                                                                    <p><span> {traslate("Collection")} </span><span>Opal</span></p>
                                                                    <p><span> {traslate("Vendor")} </span><span>Parlé Jewelry Design</span></p>
                                                                    <p><span> {traslate("Cost")} </span><span>$95.00</span></p>
                                                                    <p><span> {traslate("Retail")} </span><span>$190.00</span></p>
                                                                    <p><span> {traslate("MetalType")} </span><span>14K Yellow Gold Price</span></p>
                                                                    <p><span> {traslate("MetalColor")} </span><span>Yellow</span></p>
                                                                </div>
                                                                <div className="close__div" onClick={handleProductChangeClose}>
                                                                    <img src={Close} alt="" />
                                                                </div>
                                                            </div>

                                                    }

                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="prod__maindiv">
                                                    <div className="chkbx__div">
                                                        <input type="checkbox" />
                                                    </div>
                                                    <div className="prodimg__div">
                                                        <img src={Rectangle} alt="" />
                                                    </div>
                                                    <div className="details__div">
                                                        <span className="style__txt">14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>
                                                        <span className="price__txt">$190.00</span>
                                                    </div>
                                                    <div className="editdel__div">
                                                        <div>
                                                            <img src={Video_icon} alt="" />
                                                        </div>
                                                        <div>
                                                            <img src={Edit_icon} alt="" />
                                                        </div>
                                                        <div>
                                                            <img src={Delete_iconbl} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="info__div">
                                                        <img src={Info_icon} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="prod__maindiv">
                                                    <div className="chkbx__div">
                                                        <input type="checkbox" />
                                                    </div>
                                                    <div className="prodimg__div">
                                                        <img src={Rectangle} alt="" />
                                                    </div>
                                                    <div className="details__div">
                                                        <span className="style__txt">14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>
                                                        <span className="price__txt">$190.00</span>
                                                    </div>
                                                    <div className="editdel__div">
                                                        <div>
                                                            <img src={Video_icon} alt="" />
                                                        </div>
                                                        <div>
                                                            <img src={Edit_icon} alt="" />
                                                        </div>
                                                        <div>
                                                            <img src={Delete_iconbl} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="info__div">
                                                        <img src={Info_icon} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="prod__maindiv">
                                                    <div className="chkbx__div">
                                                        <input type="checkbox" />
                                                    </div>
                                                    <div className="prodimg__div">
                                                        <img src={Rectangle} alt="" />
                                                    </div>
                                                    <div className="details__div">
                                                        <span className="style__txt">14K Yellow Gold 5x7 Oval Australian Opal Doublet Pendant</span>
                                                        <span className="price__txt">$190.00</span>
                                                    </div>
                                                    <div className="editdel__div">
                                                        <div>
                                                            <img src={Video_icon} alt="" />
                                                        </div>
                                                        <div>
                                                            <img src={Edit_icon} alt="" />
                                                        </div>
                                                        <div>
                                                            <img src={Delete_iconbl} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="info__div">
                                                        <img src={Info_icon} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {viewItem === "list" &&
                                <div className="col-md-12 mt-4">
                                    <div className="lviewprod__tbl">
                                        <Table columns={columns} dataSource={data} scroll={{ y: 300 }} rowSelection={rowSelection} />
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default JewelrySearch
