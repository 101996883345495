import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table
} from "antd";


import prodImg from "../../../assets/images/prod__img.jpg";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";

import { Doughnut } from "react-chartjs-2";
import traslate from "../../../i18n/translate";

const JewelryRetailerClickReportMain = () => {
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;

  const style = <span>content not provided.</span>;

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };

  const columns = [
    {
      title: traslate("ProductImage"),
      dataIndex: "ProductImage",
      width: 120
    },
    {
      title: traslate("UserIPAddress"),
      dataIndex: "UserIPAddress",
      width: 120
    },
    {
      title: traslate("Vendor"),
      dataIndex: "Vendor",
      width: 120
    },
    {
      title: traslate("Retailer"),
      dataIndex: "Retailer",
      width: 120
    },
    {
      title: traslate("Collection"),
      dataIndex: "Collection",
      width: 120
    },
    {
      title: traslate("Category"),
      dataIndex: "Category",
      width: 120
    },
    {
      title: traslate("Date"),
      dataIndex: "Date",
      width: 120
    },
    {
      title: traslate("StyleNumber"),
      dataIndex: "StyleNumber",
      width: 120
    },
    {
      title: traslate("MetalType"),
      dataIndex: "MetalType",
      width: 120
    },
    {
      title: traslate("MetalColor"),
      dataIndex: "MetalColor",
      width: 120
    },
    {
      title: traslate("Price"),
      dataIndex: "Price",
      width: 120
    }
  ];

  const data = [
    {
      key: "1",
      ProductImage: <span><img src={prodImg} className="product__img" /></span>,
      UserIPAddress: <span>175.100.179.26</span>,
      Vendor: <span>RB</span>,
      Retailer: <span>GemFind</span>,
      Collection: <span>Solitaire</span>,
      Category: <span>Engagement Ring - Semi Mount</span>,
      Date: <span>01/01/2022</span>,
      StyleNumber: <span>F1626-6.5X4.5-18KW-1</span>,
      MetalType: <span>18K Gold</span>,
      MetalColor: <span>White</span>,
      Price: <span>Call For Price</span>
    },
    {
      key: "2",
      ProductImage: <span><img src={prodImg} className="product__img" /></span>,
      UserIPAddress: <span>175.100.179.26</span>,
      Vendor: <span>RB</span>,
      Retailer: <span>GemFind</span>,
      Collection: <span>Solitaire</span>,
      Category: <span>Engagement Ring - Semi Mount</span>,
      Date: <span>01/01/2022</span>,
      StyleNumber: <span>F1626-6.5X4.5-18KW-1</span>,
      MetalType: <span>18K Gold</span>,
      MetalColor: <span>White</span>,
      Price: <span>Call For Price</span>
    }
  ];

  return (
    <React.Fragment>
      <div className="pendjewelreq__section">
        <div className="row form__fields magento__mapping">
          <div className="col-md-12">
            {/* <h4 className="workarea__heading">Jewelry Category Report</h4> */}
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
              {traslate("JewelryRetailerClicksReport")}
              </h4>
              <button className="primary-btn">
                <i className="fa fa-download mr-1"></i>
                {traslate("DownloadCSV")}
              </button>
            </div>
          </div>
          <div className="col-md-3">
            <div className="input__block">
              <label> {traslate("FromDate")} </label>
              <input type="date" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="input__block">
              <label> {traslate("ToDate")} </label>
              <input type="date" />
            </div>
          </div>

          <div className="col-md-4 mt-4">
            <div className="input__block">
              <button className="primary-btn mt-2"> {traslate("ApplyFilter")} </button>
            </div>
          </div>

          
          
          <div className="col-lg-12 col-md-12 mt-4">
            <Table
              className="pendjewelreq__tbl"
              columns={columns}
              dataSource={data}
              scroll={{ x: 600 }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JewelryRetailerClickReportMain;
