import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table
} from "antd";

import Highlighter from "react-highlight-words";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";

import { Doughnut } from "react-chartjs-2";
import traslate from "../../../i18n/translate";

const GlobalDiamondSizeReport = () => {
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;

  const style = <span>content not provided.</span>;

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };

  const columns = [
    {
      title: traslate("UsersIPAddress"),
      dataIndex: "usersIPAddress",
      width: 150
    },
    {
      title: traslate("Date"),
      dataIndex: "date",
      width: 120
    },
    {
      title: traslate("Shape"),
      dataIndex: "shape",
      width: 120
    },
    {
      title: traslate("Size"),
      dataIndex: "size",
      width: 120
    },
    {
      title: traslate("Color"),
      dataIndex: "color",
      width: 120
    },
    {
      title: traslate("Cut"),
      dataIndex: "cut",
      width: 120
    },
    {
      title: traslate("Clarity"),
      dataIndex: "clarity",
      width: 120
    },
    {
      title: traslate("Certificate"),
      dataIndex: "certificate",
      width: 120
    },
    {
      title: traslate("RetailPrice"),
      dataIndex: "retailPrice",
      width: 120
    },
    {
      title: traslate("Cost"),
      dataIndex: "Cost",
      width: 120
    }
  ];

  const data = [
    {
      key: "1",
      usersIPAddress: <span>175.100.179.26	</span>,
      date: <span>2022-04-05 06:45:15</span>,
      shape: <span>Round</span>,
      size: <span>0.50</span>,
      color: <span>H</span>,
      cut: <span>Excellent</span>,
      clarity: <span>VS1</span>,
      certificate: <span>GIA</span>,
      retailPrice: <span>789</span>,
      Cost: <span>944</span>
    },
    {
      key: "2",
      usersIPAddress: <span>175.100.179.26	</span>,
      date: <span>2022-04-05 06:45:15</span>,
      shape: <span>Round</span>,
      size: <span>0.50</span>,
      color: <span>H</span>,
      cut: <span>Excellent</span>,
      clarity: <span>VS1</span>,
      certificate: <span>GIA</span>,
      retailPrice: <span>789</span>,
      Cost: <span>944</span>
    }
  ];

  return (
    <React.Fragment>
      <div className="pendjewelreq__section">
        <div className="row form__fields magento__mapping">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {traslate("DiamondSizeReport")}
              </h4>
            </div>
          </div>
          <div className="col-md-3">
            <div className="input__block">
              <label> {traslate("FromDate")} </label>
              <input type="date" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="input__block">
              <label> {traslate("ToDate")} </label>
              <input type="date" />
            </div>
          </div>

          <div className="col-md-3">
            <div className="input__block">
              <label> {traslate("SelectDiamond")} </label>
              <Select
                showSearch
                className="border__grey"
                placeholder="All "
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="jack"> {traslate("All")} </Option>
                <Option value="lucy"> {traslate("MinedDiamond")} </Option>
                <Option value="lucy"> {traslate("LabGrownDiamond")} </Option>
              </Select>
            </div>
          </div>

          <div className="col-lg-12 col-md-6 mt-4">
            <div className="input__block">
              <label>
                <b> {traslate("TotalClicks")} : 7</b>
              </label>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="chart__section">
              <Doughnut
                data={{
                  labels: [
                    "Red",
                    "Blue",
                    "Yellow",
                    "Green",
                    "Purple",
                    "Orange"
                  ],
                  datasets: [
                    {
                      label: "# of Votes",
                      data: [12, 19, 3, 5, 2, 3],
                      backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(54, 162, 235, 0.2)",
                        "rgba(255, 206, 86, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                        "rgba(153, 102, 255, 0.2)",
                        "rgba(255, 159, 64, 0.2)"
                      ],
                      borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                        "rgba(255, 206, 86, 1)",
                        "rgba(75, 192, 192, 1)",
                        "rgba(153, 102, 255, 1)",
                        "rgba(255, 159, 64, 1)"
                      ],
                      borderWidth: 1
                    }
                  ]
                }}
                width={300}
                height={300}
                options={{
                  maintainAspectRatio: false
                }}
              />
            </div>
          </div>
          
          <div className="col-lg-12 col-md-12">
            <Table
              className="pendjewelreq__tbl"
              columns={columns}
              dataSource={data}
              scroll={{ x: 600 }}
            />
          </div>

        </div>
      </div>
    </React.Fragment>
  );
};

export default GlobalDiamondSizeReport;
