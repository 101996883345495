import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Modal, Select } from "antd";
import { Link } from "react-router-dom";
import logoImg from "../../assets/images/logo-img.png";
import footerLogoImg from "../../assets/images/footer-logo.png";
import footerCompanyLogo1 from "../../assets/images/footerlogo1.png";
import footerCompanyLogo2 from "../../assets/images/footerlogo2.png";
import footerCompanyLogo3 from "../../assets/images/footerlogo3.png";
import footerCompanyLogo4 from "../../assets/images/footerlogo4.png";
import footerCompanyLogo5 from "../../assets/images/footerlogo5.png";
import { useNavigate, useLocation } from "react-router-dom";
import adminToolsService from "../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";

const ResetPassword = (props) => {
  const [Passwordobj, setPasswordObj] = useState({
    password: "",
    confirmPassword: "",
  });
  const [validations, setValidations] = useState({
    passwordVal: "",
    confirmPasswordVal: "",
    CheckSameVal: "",
  });
  const [visible, setVisible] = useState(false);
  const search = useLocation().search;
  const [token, setToken] = useState("");
  const [email, setemail] = useState("");
  const [loading, setLoading] = useState(false);

  const { Option } = Select;
  let navigate = useNavigate();
  useEffect(() => {
    const token = new URLSearchParams(search).get("token");
    const email = new URLSearchParams(search).get("email");
    setToken(token);
    setemail(email);
    if (token && email) {
      try {
        let inputData = {
          userEmail: email,
          resetToken: token,
        };
        debugger;
        adminToolsService
          .ForgotPasswordTokenVerify(inputData)
          .then((response) => {
            var message = response.data.message;
            var responseData = response.data.responseData;
            var isSucceed = response.data.responseData.isSucceed;
            if (message == "Success") {
              if (isSucceed) {
                NotificationManager.Success("Token Verificataion Successfull");
              } else {
                navigate("/");
              }
            } else {
              navigate("/");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      navigate("/");
    }
  }, []);

  const handleOnChange = (e) => {
    setPasswordObj({
      ...Passwordobj,
      [e.target.name]: e.target.value,
    });
  };

  const handleValidation = () => {
    const validations = {
      confirmPasswordVal: "",
      passwordVal: "",
      CheckSameVal: "",
    };
    let isValid = true;
    debugger;
    // var passwordRegex =
    //   "^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[@$!%*?&])[A-Za-zd@$!%*?&]{8,}$";
    if (!Passwordobj.password) {
      isValid = false;
      validations.passwordVal = "Password is compulsory";
    } else if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{0,9}$/.test(password)) {
      isValid = false;
      validations.passwordVal =
        "Password should have a special character,number,a uppercase letter , lowercase letter and minimum to 8 -10 values";
    } else {
      validations.passwordVal = "";
    }
    if (!Passwordobj.confirmPassword) {
      isValid = false;
      validations.confirmPasswordVal = "Confirm Password is compulsory";
    } else if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{0,9}$/.test(confirmPassword)) {
      isValid = false;
      validations.confirmPasswordVal =
        "Confirm Password should have a special character,number,a uppercase letter , lowercase letter and minimum to 8 -10 values";
    } else {
      validations.confirmPasswordVal = "";
    }
    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        isValid = false;
        validations.CheckSameVal = "Both the entered password should match";
      } else {
        validations.CheckSameVal = "";
      }
    }

    setValidations(validations);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        userEmail: email,
        resetToken: token,
        password: Passwordobj.password,
      };
      setLoading(true);
      adminToolsService
        .ForgotPasswordChangeNewPassword(inputData)
        .then((response) => {
          var message = response.data.message;
          var isSucceed = response.data.responseData.isSucceed;
          setLoading(false);
          if (message == "True") {
            if (isSucceed) {
              NotificationManager.success("Password Changed Successfully");
            } else {
              NotificationManager.error("Password Changed Fail");
            }
          } else {
            NotificationManager.error("Password Changed Fail");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setPasswordObj({
      password: "",
      confirmPassword: "",
    });
    setValidations({
      passwordVal: "",
      confirmPasswordVal: "",
    });
  };
  const { password, confirmPassword } = Passwordobj;
  const { passwordVal, confirmPasswordVal, CheckSameVal } = validations;
  return (
    <div className="login__section register__section container-fluid">
      <div className="form__fields">
        <div className="row">
          <div className="col-md-12">
            <div className="login__header">
              <div className="logo__section">
                <img src={logoImg} />
              </div>

              <div className="right__text">
                <p>Social Product Network for the Jewelry Industry</p>
              </div>
            </div>
          </div>
          <div className="col-lg-9 mx-auto">
            <div className="form__section">
              <div className="login__block">
                <h3>Reset Password</h3>
                <div>
                  {CheckSameVal && (
                    <p className="error-color-red">{CheckSameVal}</p>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input__block">
                      <label>Password</label>
                      <input
                        type="text"
                        placeholder="Enter Password"
                        name="password"
                        value={password}
                        onChange={handleOnChange}
                        className={passwordVal && "border__red"}
                      />
                    </div>
                    <div>
                      {passwordVal && (
                        <p className="error-color-red">{passwordVal}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input__block">
                      <label>Confirm Password</label>
                      <input
                        type="text"
                        placeholder="Enter Password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleOnChange}
                        className={confirmPasswordVal && "border__red"}
                      />
                    </div>
                    <div>
                      {confirmPasswordVal && (
                        <p className="error-color-red">{confirmPasswordVal}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 mx-auto mt-4">
                    <div className="input__block">
                      <Button
                        onClick={handleSubmit}
                        type="primary"
                        htmlType="button"
                        className="primary-btn w-100"
                      >
                        Update Password
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 p-0">
            <div className="footer__section login__footer">
              <div className="left__block">
                <div className="footer__logo">
                  <img src={footerLogoImg} />
                </div>
                <div className="footer__menu">
                  <ul>
                    <li>
                      <a href="#">Terms of Service</a>
                    </li>
                    <li>
                      <a href="#">Privacy Statement</a>
                    </li>
                    <li>
                      <a href="#">Copyright 2021 GemFind</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="right__block">
                <ul>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo1} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo2} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo3} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo4} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={footerCompanyLogo5} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
