import React, { useState } from "react";
import { Input, Row, Col, Select, Checkbox, Switch, Table, Menu, Dropdown, Button, Space } from "antd";

const ManageCurrency = () => {
    const { Option } = Select;
    return (
        <React.Fragment>
            <div className="form__fields">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="workarea__heading mb-0">Currency Selection for Platform and Tools</h4>
                            <p class="mt-3">Default Language is English (US)</p>
                        </div>
                        <div className="col-lg-12">
                            <div className="row">

                                <div className="col-lg-9">
                                    <div className="row">

                                        <div className="col-lg-6 mt-4">
                                            <div className="input__block">
                                                <label>
                                                Select Currency
                                                </label>
                                                <Select
                                                    name="brandName"
                                                    placeholder="US Dollar"
                                                    className="border__grey"

                                                >
                                                    <Option value="english">US Dollar</Option>
                                                    <Option value="lucy">Company Name 2</Option>
                                                    <Option value="tom">Company Name 3</Option>
                                                </Select>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 mt-3">
                                    <button class="primary-btn me-4">Apply</button>
                                    <button class="primary-btn">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ManageCurrency
