import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Select, Modal, Spin, notification } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AdminSettingsDataService from "../../../services/admin-settings.service";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../../i18n/translate";

export const EmailTemplate = (props) => {
  const { Option } = Select;
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState("");
  const [dealerId, setDealerId] = useState(0);
  const [dealerList, setDealerList] = useState([]);
  const [typeName, setTypeName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isCommunityEmails, setIsCommunityEmails] = useState(true);

  useEffect(() => {
    handleFillDealerDropDown();
  }, []);
  const onChange = (value) => {
    setDealerId(value);
    handleGetEmailDealerStatus(value);
  };

  const handleFillDealerDropDown = () => {
    try {
      AdminToolsDataService.FillDealerDropDown()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setDealerList(responseData);
          } else {
            setDealerList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetEmailTemplatesById = (name) => {
    try {
      let inputParams = {
        DealerID: dealerId,
        TypeName: name,
      };
      setContent("");
      setTypeName(name);
      setVisible(true);
      setLoading(true);
      AdminSettingsDataService.GetEmailTemplatesById(inputParams)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            if (responseData[0]) {
              setContent(responseData[0].emailContent);
            }
          } else {
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleSaveEmailTemplate = () => {
    try {
      let inputParams = {
        dealerID: dealerId,
        typeName: typeName,
        eMailContent: content,
      };

      AdminSettingsDataService.SaveEmailTemplate(inputParams)
        .then((response) => {
          var message = response.data.message;
          if (message == "Success") {
            handleCloseModal();
            NotificationManager.success("Email Templates Update Successfully.");
          } else {
            NotificationManager.error("Email Templates Not Update.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetEmailDealerStatus = (id) => {
    try {
      let inputParams = {
        dealerID: id,
      };

      AdminSettingsDataService.GetEmailDealerStatus(inputParams)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setIsCommunityEmails(responseData);
          } else {
            setIsCommunityEmails(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleCloseModal = () => {
    setContent("");
    setVisible(false);
  };
  return (
    <div className="form__fields">
      <div className="row">
        <div className="col-md-12">
          <h4 className="workarea__heading mt-0"> {traslate("EmailTemplates")} </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="input__block">
            <label> {traslate("AllDealers")} </label>
            <Select
              className="border__grey"
              showSearch
              placeholder="Select Dealer"
              optionFilterProp="children"
              notFoundContent="No Data Found"
              value={dealerId}
              onChange={onChange}
            >
              <Option value={0}>--All Dealer--</Option>
              {dealerList.map((item) => {
                return (
                  <Option value={item.dealerID}>
                    {item.dealerCompany + " (" + item.dealerID + " )"}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
      </div>

      <div className="emailTemplate">
        <div className="row">
          {dealerId == 0 ? (
            <div className="col-md-4 mb-4">
              <div className="emailDetailBlock">
                <h1> {traslate("RegistrationEmails")} </h1>
                <ul>
                  <li>
                    <a
                      onClick={() => handleGetEmailTemplatesById("NewAccount")}
                    >
                      {traslate("NewAccount")}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        handleGetEmailTemplatesById("ForgotPassword")
                      }
                    >
                      {traslate("ForgotPassword")}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        handleGetEmailTemplatesById("ResetPassword")
                      }
                    >
                      {traslate("ResetPassword")}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        handleGetEmailTemplatesById("ForgotUserName")
                      }
                    >
                      {traslate("ForgotUserName")}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        handleGetEmailTemplatesById("lockedAccount")
                      }
                    >
                      {traslate("AccountLocked")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          ) : null}

          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1> {traslate("RetailerEmails")} </h1>
              <ul>
                <li>
                  <a
                    onClick={() =>
                      handleGetEmailTemplatesById("InviteNewRetailer")
                    }
                  >
                    {traslate("InviteNewRetailer")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {dealerId == 0 || isCommunityEmails ? (
            <div className="col-md-4 mb-4">
              <div className="emailDetailBlock">
                <h1> {traslate("CommunityEmails")} </h1>
                <ul>
                  <li>
                    <a onClick={() => handleGetEmailTemplatesById("Active")}>
                    {traslate("RetailerRequeste-Mailto")} "{traslate("ACTIVE")}" {traslate("GFMembers")}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        handleGetEmailTemplatesById("CommunityManaged")
                      }
                    >
                      {traslate("Retailere-Mailto")} "{traslate("COMMUNITYMANAGED")}" {traslate("NonGFMembers")}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleGetEmailTemplatesById("ProfileOnly")}
                    >
                      {traslate("Retailere-Mailto")} "{traslate("PROFILEONLY")}" {traslate("NonGFMembers")}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        handleGetEmailTemplatesById("LimitedAccount")
                      }
                    >
                      {traslate("Retailere-Mailto")} "{traslate("LIMITEDACCOUNT")}" {traslate("GFMembers")}
                    </a>
                  </li>
                  <li>
                    <a onClick={() => handleGetEmailTemplatesById("Decline")}>
                    {traslate("VendorDeclineeMail")}
                    </a>
                  </li>
                  <li>
                    <a onClick={() => handleGetEmailTemplatesById("Accept")}>
                    {traslate("VendorAccepteMail")}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        handleGetEmailTemplatesById("UpdateProductData")
                      }
                    >
                      {traslate("RequesttoUpdateProductData")}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        handleGetEmailTemplatesById("VendorAppointment")
                      }
                    >
                      {traslate("VendorAppointment")}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        handleGetEmailTemplatesById("InviteNewVendor")
                      }
                    >
                      {traslate("InviteNewVendor")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          ) : null}

          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1> {traslate("ContactEmails(MasterLink)")}</h1>
              <ul>
                <li>
                  <a
                    onClick={() =>
                      handleGetEmailTemplatesById("CustomerRegistration")
                    }
                  >
                    {traslate("CustomerRegistration")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      handleGetEmailTemplatesById("CustNewAppointments")
                    }
                  >
                    {traslate("NewAppointments")}
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("Inquires(RequestMoreInfo)")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("CustMoreInfoRetailer")
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("CustMoreInfoSender")
                          }
                        >
                          {traslate("SendMailtoSender")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("SendEmail(EmailToFriend)")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("ToFriendInquires")
                          }
                        >
                          {traslate("SendMailtoFriend")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("CustInquires")
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("ToSenderInquires")
                          }
                        >
                          {traslate("SendMailtoSender")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("DropAHint")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("ToRecipientDropAHint")
                          }
                        >
                          {traslate("SendMailtoRecipient")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("ToRetailerDropAHint")
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      handleGetEmailTemplatesById("CustNewFavoriteList")
                    }
                  >
                    {traslate("NewFavoriteList")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      handleGetEmailTemplatesById("CustNewFavoriteItem")
                    }
                  >
                    {traslate("NewFavoriteItemsAdded")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      handleGetEmailTemplatesById("CustConversations")
                    }
                  >
                    {traslate("Conversations")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      handleGetEmailTemplatesById("CustResetPassword")
                    }
                  >
                    {traslate("CustomerResetPassword")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      handleGetEmailTemplatesById("CustResetPasswordNew")
                    }
                  >
                    {traslate("CustomerResetPassword(New)")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1> {traslate("DiamondSearchEmails")} </h1>
              <ul>
                <li>
                  <a href="#">
                    <h2> {traslate("RequestMoreInfo")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("DSToRetailerInquires")
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("DSToSenderInquires")
                          }
                        >
                          {traslate("SendMailtoSender")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("EmailToFriend")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("DSToFriendEmailF")
                          }
                        >
                          {traslate("SendMailtoFriend")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("DSToRetailerEmailF")
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("DSToSenderEmailF")
                          }
                        >
                           {traslate("SendMailtoSender")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("DropAHint")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "DSToRecipientDropAHint"
                            )
                          }
                        >
                          {traslate("SendMailtoRecipient")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("DSToRetailerDropAHint")
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("ScheduleView")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "DLToScheduleViewDiamondDetail"
                            )
                          }
                        >
                          {traslate("DiamondDetail")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      handleGetEmailTemplatesById("DiamondCompare")
                    }
                  >
                    {traslate("DiamondCompare")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() =>
                      handleGetEmailTemplatesById("DiamondCustomerCompare")
                    }
                  >
                    {traslate("DiamondCustomerCompare")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1> {traslate("FancyColorDiamondSearchEmails")} </h1>
              <ul>
                <li>
                  <a href="#">
                    <h2> {traslate("RequestMoreInfo")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("FCToRetailerInquires")
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("FCToSenderInquires")
                          }
                        >
                          {traslate("SendMailtoSender")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("EmailToFriend")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("FCToFriendEmailF")
                          }
                        >
                          {traslate("SendMailtoFriend")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("FCToRetailerEmailF")
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("FCToSenderEmailF")
                          }
                        >
                          {traslate("SendMailtoSender")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("DropAHint")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "FCToRecipientDropAHint"
                            )
                          }
                        >
                          {traslate("SendMailtoRecipient")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("FCToRetailerDropAHint")
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("ScheduleView")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "DLToScheduleViewColorDiamondDetail"
                            )
                          }
                        >
                          {traslate("ColorDiamondDetail")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1> {traslate("RingBuilderEmails")} </h1>
              <ul>
                <li>
                  <a href="#">
                    <h2> {traslate("RequestMoreInfo")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("RBToRetailerInquires")
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("RBToSenderInquires")
                          }
                        >
                          {traslate("SendMailtoSender")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("EmailToFriend")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("RBToFriendEmailF")
                          }
                        >
                          {traslate("SendMailtoFriend")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("RBToRetailerEmailF")
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("RBToSenderEmailF")
                          }
                        >
                          {traslate("SendMailtoSender")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("DropAHint")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "RBToRecipientDropAHint"
                            )
                          }
                        >
                          {traslate("SendMailtoRecipient")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("RBToRetailerDropAHint")
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("DropAHint(Infinity)")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "RBToRecipientDropAHintInfinity"
                            )
                          }
                        >
                          {traslate("SendMailtoFriend")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "RBToRetailerDropAHintInfinity"
                            )
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "RBToSenderDropAHintInfinity  "
                            )
                          }
                        >
                          {traslate("SendMailtoSender")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("BuyNow(Infinity)")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "RBToRetailerBuyNowInfinity"
                            )
                          }
                        >
                          {traslate("SendMailtoRecipient")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "RBToSenderBuyNowInfinity"
                            )
                          }
                        >
                          {traslate("SendMailtoSender")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("ScheduleView")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "RBToScheduleViewSetting"
                            )
                          }
                        >
                          {traslate("SettingDetail")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "RBToScheduleViewDiamondDetail"
                            )
                          }
                        >
                          {traslate("DiamondDetail")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "RBToScheduleViewComplete"
                            )
                          }
                        >
                          {traslate("CompleteRingSpecifications")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1> {traslate("PendantBuilderEmails")} </h1>
              <ul>
                <li>
                  <a href="#">
                    <h2> {traslate("DiamondRequestMoreInfo")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "PBDiamondToRetailerInquires"
                            )
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "PBDiamondToSenderInquires"
                            )
                          }
                        >
                          {traslate("SendMailtoSender")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("RequestMoreInfo")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("PBToRetailerInquires")
                          }
                        >
                          {traslate("SendMailtoFriend")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("PBToRetailerEmailF")
                          }
                        >
                          {traslate("SendMailtoSender")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("EmailToFriend")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("PBToFriendEmailF")
                          }
                        >
                          {traslate("SendMailtoFriend")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("PBToRetailerEmailF")
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("PBToSenderEmailF")
                          }
                        >
                          {traslate("SendMailtoSender")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("DropAHint")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "PBToRecipientDropAHint"
                            )
                          }
                        >
                          {traslate("SendMailtoRecipient")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("PBToRetailerDropAHint")
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>

                <li>
                  <a href="#">
                    <h2> {traslate("ScheduleView")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "PBToScheduleViewDiamondDetail"
                            )
                          }
                        >
                          {traslate("DiamondDetail")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "PBToScheduleViewComplete"
                            )
                          }
                        >
                          {traslate("ComplatePendantSetting")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1> {traslate("StudBuilderEmails")} </h1>
              <ul>
                <li>
                  <a href="#">
                    <h2> {traslate("RequestMoreInfo")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("SBToRetailerInquires")
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("SBToSenderInquires")
                          }
                        >
                          {traslate("SendMailtoSender")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("EmailToFriend")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("SBToFriendEmailF")
                          }
                        >
                          {traslate("SendMailtoFriend")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("SBToRetailerEmailF")
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("SBToSenderEmailF")
                          }
                        >
                          {traslate("SendMailtoSender")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("DropAHint")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "SBToRecipientDropAHint"
                            )
                          }
                        >
                          {traslate("SendMailtoRecipient")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("SBToRetailerDropAHint")
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("RequestMoreInfo")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "RBToRecipientDropAHintInfinity"
                            )
                          }
                        >
                          {traslate("RequestMoreRetailer")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "RBToRetailerDropAHintInfinity"
                            )
                          }
                        >
                          {traslate("SendMailtoSender")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>

                <li>
                  <a href="#">
                    <h2> {traslate("ScheduleView")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("SBToScheduleView")
                          }
                        >
                          {traslate("SBToScheduleView")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "SBToScheduleViewComplete"
                            )
                          }
                        >
                          {traslate("SBCompleteEarringToScheduleView")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("DiamondRequestMoreInfo")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "SBDiamondToRetailerInquires"
                            )
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "SBDiamondToSenderInquires"
                            )
                          }
                        >
                          {traslate("SendMailtoSender")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("DiamondEmailToFriend")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "SBDiamondToFriendEmailF"
                            )
                          }
                        >
                          {traslate("SendMailtoFriend")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "SBDiamondToRetailerEmailF"
                            )
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById(
                              "SBDiamondToSenderEmailF"
                            )
                          }
                        >
                          {traslate("SendMailtoSender")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <h2> {traslate("Store")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("SBToSenderStore")
                          }
                        >
                          {traslate("SendMailtoRecipient")}
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("SBToRetailerStore")
                          }
                        >
                          {traslate("SendMailtoRetailer")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="emailDetailBlock">
              <h1> {traslate("BigEmails")} </h1>
              <ul>
                <li>
                  <a href="#">
                    <h2> {traslate("EmailToCustomer")} </h2>
                    <ul>
                      <li>
                        <a
                          onClick={() =>
                            handleGetEmailTemplatesById("BGCustomermail")
                          }
                        >
                          {traslate("SendMailtoCustomer")}
                        </a>
                      </li>
                    </ul>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="emailTemplateEditorModal"
        title={traslate("SendEmail")}
        centered
        visible={visible}
        onOk={handleSaveEmailTemplate}
        onCancel={handleCloseModal}
        width={800}
        hieght={600}
      >
        <div className="editor__section">
          <Spin spinning={loading}>
            <CKEditor
              editor={ClassicEditor}
              data={content}
              id="editor"
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setContent(data);
              }}
            />
          </Spin>
        </div>
      </Modal>
    </div>
  );
};
export default EmailTemplate;
