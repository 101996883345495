import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Table,
  Modal,
  DatePicker,
  Switch,
  Space,
} from "antd";
import {
  EditOutlined,
  CalendarOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";

import userIcon from "../../../assets/images/daimond-bg.png";
import traslate from "../../../i18n/translate";

const { Option } = Select;

export const MinedDiamondMarkup = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const columns = [
    {
      title: traslate("ID"),
      dataIndex: "vendorName",
    },
    {
      title: traslate("Dealer"),
      dataIndex: "minedDiamonds",
    },
    {
      title: traslate("Markup"),
      dataIndex: "labGrown",
    },
  ];

  const data = [];

  const columns2 = [
    {
      title: traslate("MinPrice"),
      dataIndex: "minPrice",
    },
    {
      title: traslate("MaxPrice"),
      dataIndex: "maxPrice",
    },
    {
      title: traslate("Certs"),
      dataIndex: "certs",
    },
    {
      title: traslate("Markup"),
      dataIndex: "markup",
    },
    {
      title: "",
      fixed: "right",
      width: 100,
      render: () => (
        <div className="action__btns">
          <div className="image__block">
            <img src={Delete_iconbl} alt="" />
          </div>
        </div>
      ),
    },
  ];

  const data2 = [
    {
      key: "1",
      minPrice: "1.00",
      maxPrice: "1000.00",
      certs: "",
      markup: "70%",
    },
  ];

  return (
    <div className="form__fields markup__section">
      <div className="row ">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0">
              {" "}
              {traslate("MinedDiamondsMarkup")}{" "}
            </h4>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="input__block">
            <label> {traslate("Method")} </label>
            <Select
              className="border__grey"
              optionFilterProp="children"
              placeholder="Custom"
            >
              <Option value="jack">Option 1</Option>
              <Option value="lucy">Option 2</Option>
            </Select>
          </div>
        </div>

        <div className="col-md-4">
          <div className="input__block">
            <label> {traslate("Currency")} </label>
            <Select
              className="border__grey"
              optionFilterProp="children"
              placeholder="US Dollar $"
            >
              <Option value="jack">Option 1</Option>
              <Option value="lucy">Option 2</Option>
            </Select>
          </div>
        </div>

        <div className="col-md-4">
          <div className="input__block">
            <label> {traslate("CustomMethod")} </label>
            <Select
              className="border__grey"
              optionFilterProp="children"
              placeholder="By Price"
            >
              <Option value="jack">Option 1</Option>
              <Option value="lucy">Option 2</Option>
            </Select>
          </div>
        </div>
        <div className="col-md-12">
          <hr />
        </div>
      </div>

      <div className="row ">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0">
              {" "}
              {traslate("DefaultMarkup")}{" "}
            </h4>
          </div>
        </div>
        <div className="col-md-4">
          <div className="input__block">
            <input type="number" value="100" />
            <label className="mt-2">
              {" "}
              {traslate(
                "Inordertoprotecttheintegrityofthediamondandthejewelryindustry,theminimummarkupontheDiamondlinkshouldbenolessthan7%"
              )}{" "}
            </label>
          </div>
        </div>
        <div className="col-md-12">
          <hr />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0">
              {" "}
              {traslate("MarkupByVendor")}{" "}
            </h4>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="input__block">
                <Select
                  className="border__grey"
                  optionFilterProp="children"
                  placeholder="Select Manufacturer"
                >
                  <Option value="jack">Option 1</Option>
                  <Option value="lucy">Option 2</Option>
                </Select>
              </div>
            </div>

            <div className="col-md-4">
              <div className="input__block">
                <input type="text" placeholder="%" className="w-auto" />
                <button className="primary-btn ml-2">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 divForm">
          <Table columns={columns} dataSource={data} />
          <hr />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0">
              {" "}
              {traslate("MarkupByPriceRangeWithCerts")}{" "}
            </h4>
          </div>

          <div className="row">
            <div className="col-md-2">
              <div className="input__block d-flex align-items-center">
                <input type="text" className="mr-2" />
                <label> {traslate("CL")} </label>
              </div>
            </div>

            <div className="col-md-2">
              <div className="input__block d-flex align-items-center">
                <input type="text" className="mr-2" />
                <label> {traslate("CL")} </label>
              </div>
            </div>

            <div className="col-md-2">
              <div className="input__block d-flex align-items-center">
                <input type="text" className="mr-2" />
                <label>%</label>
              </div>
            </div>

            <div className="col-md-1">
              <div className="input__block d-flex">
                <button className="primary-btn ml-2">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
            </div>

            <div className="col-md-2">
              <div className="input__block">
                <button className="primary-btn ml-2">
                  {" "}
                  {traslate("UpdateMarkup")}{" "}
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="row mt-3 mb-3 markup__checkbkox">
                <div className="col-md-1 checkbox__block">
                  <input type="checkbox" className="mr-1" />
                  <span> {traslate("GIA")} </span>
                </div>

                <div className="col-md-1 checkbox__block">
                  <input type="checkbox" className="mr-1" />
                  <span> {traslate("AGS")} </span>
                </div>

                <div className="col-md-1 checkbox__block">
                  <input type="checkbox" className="mr-1" />
                  <span> {traslate("EGL")} </span>
                </div>

                <div className="col-md-1 checkbox__block">
                  <input type="checkbox" className="mr-1" />
                  <span> {traslate("IGI")} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 divForm">
          <Table columns={columns2} dataSource={data2} />
          <hr />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MinedDiamondMarkup);
