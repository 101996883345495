import React, { useEffect, useState, useRef } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Search,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Modal,
  Table,
} from "antd";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import userIcon from "../../../assets/images/daimond-bg.png";
import AdminSettingsService from "../../../services/admin-settings.service";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import traslate from "../../../i18n/translate";

const { Option } = Select;
const TradeShows = () => {
  const [visible, setVisible] = useState(false);
  const [isEdit, setIdEdit] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tradeShowList, setTradeShowList] = useState([]);
  const [deleteRecordId, setDeleteRecordId] = useState(null);
  const [tradeShowData, setTradeShowData] = useState({
    id: 0,
    image: "",
    showName: "",
    city: "",
    state: "",
    website: "",
    showStartTime: null,
    showEndTime: null,
    showStartDate: "",
    showEndDate: "",
  });
  const [validations, setValidations] = useState({
    imageVal: "",
    showNameVal: "",
    cityVal: "",
    stateVal: "",
    websiteVal: "",
    showStartTimeVal: "",
    showEndTimeVal: "",
    showStartDateVal: "",
    showEndDateVal: "",
    showsImagePath: "",
    fileVal: "",
  });

  useEffect(() => {
    handleGetShowsDetailsList();
  }, []);
  const handleCancel = () => {
    setVisible(false);
    setIdEdit(false);
    setTradeShowData({
      id: 0,
      image: "",
      showName: "",
      city: "",
      state: "",
      website: "",
      showStartTime: "",
      showEndTime: "",
      showStartDate: "",
      showEndDate: "",
    });
    setValidations({
      imageVal: "",
      showNameVal: "",
      cityVal: "",
      stateVal: "",
      websiteVal: "",
      showStartTimeVal: "",
      showEndTimeVal: "",
      showStartDateVal: "",
      showEndDateVal: "",
    });
  };

  const handleOk2 = () => {
    handleDeleteShowsDetailsByID();
  };

  const handleCancel2 = () => {
    setDeleteRecordId(null);
    setVisible2(false);
  };

  const handleOnChange = (e, isSelect, name) => {
    const tempValidations = validations;
    var validAlphabet = /^[A-Za-z]+$/;
    debugger;
    if (isSelect === "select") {
      setTradeShowData({
        ...tradeShowData,
        [name]: e,
      });
      if (e === "closed") {
        hideEndTime(e);
        // console.log("Closed Pressed");
      } else {
        showEndTime();
      }
    } else {
      if (e.target.name === "city") {
        if (!e.target.value.match(validAlphabet)) {
          tempValidations.cityVal = "Please Enter Only Alphabet.";
        } else {
          tempValidations.cityVal = "";
          setTradeShowData({
            ...tradeShowData,
            [e.target.name]: e.target.value,
          });
        }
      } else if (e.target.name === "state") {
        if (!e.target.value.match(validAlphabet)) {
          tempValidations.stateVal = "please Enter Only Alphabet.";
        } else {
          tempValidations.stateVal = "";
          setTradeShowData({
            ...tradeShowData,
            [e.target.name]: e.target.value,
          });
        }
      } else {
        setTradeShowData({
          ...tradeShowData,
          [e.target.name]: e.target.value,
        });
      }

      setValidations({
        ...validations,
        tempValidations,
      });
    }
  };
  const handleValidation = () => {
    debugger;
    const {
      file,
      showName,
      state,
      city,
      website,
      showStartTime,
      showEndTime,
      showStartDate,
      showEndDate,
      showsImagePath,
    } = tradeShowData;
    const validations = {
      imageVal: "",
      showNameVal: "",
      cityVal: "",
      stateVal: "",
      websiteVal: "",
      showStartTimeVal: "",
      showEndTimeVal: "",
      showStartDateVal: "",
      showEndDateVal: "",
      file: "",
    };
    let isValid = true;

    if (!showsImagePath && !file) {
      isValid = false;
      validations.file = "Please select the image";
    }

    if (!showName.trim()) {
      isValid = false;
      validations.showNameVal = "ShowName is compulsory";
    }
    if (!city.trim()) {
      isValid = false;
      validations.cityVal = "City is compulsory";
    }
    if (!website.trim()) {
      isValid = false;
      validations.websiteVal = "Website is compulsory";
    }
    if (!state.trim()) {
      isValid = false;
      validations.stateVal = "State is compulsory";
    }
    if (!showStartTime) {
      isValid = false;
      validations.showStartTimeVal = "Kindly select the Show Start Time ";
    }
    if (!showEndTime) {
      isValid = false;
      validations.showEndTimeVal = "Kindly select the Show End Time ";
    }
    if (!showStartDate) {
      isValid = false;
      validations.showStartDateVal = "Kindly select the Show Start Date ";
    }
    if (!showEndDate) {
      isValid = false;
      validations.showEndDateVal = "Kindly select the Show Start Date ";
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const {
    file,
    showNameVal,
    cityVal,
    stateVal,
    websiteVal,
    showStartTimeVal,
    showEndTimeVal,
    showStartDateVal,
    showEndDateVal,
    fileVal,
  } = validations;

  const handleOpenDeleteModal = (id) => {
    setDeleteRecordId(id);
    setVisible2(true);
  };
  const handleGetShowsDetailsList = () => {
    try {
      setLoading(true);
      AdminSettingsService.GetShowsDetailsList()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            setTradeShowList(responseData);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleDeleteShowsDetailsByID = () => {
    try {
      let inputData = {
        id: deleteRecordId,
      };
      setSubmitLoading(true);
      AdminSettingsService.DeleteShowsDetailsByID(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setSubmitLoading(false);
          if (message == "Success") {
            NotificationManager.success("Record Deleted Succssfully.");
            setDeleteRecordId(null);
            setVisible2(false);
            handleGetShowsDetailsList();
          } else {
            NotificationManager.error("Record Not Deleted.");
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };

  const handleGetShowsDetailsByID = (showId) => {
    try {
      let inputData = {
        id: showId,
      };
      setVisible(true);
      setIdEdit(true);
      AdminSettingsService.GetShowsDetailsByID(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              var tempData = responseData[0];
              var tempObj = {
                image: tempData.showsImagePath,
                id: tempData.id,
                showName: tempData.showName,
                city: tempData.city,
                state: tempData.state,
                website: tempData.website,
                showStartTime: convertTime(tempData.showOpenTime),
                showEndTime: convertTime(tempData.showCloseTime),
                showStartDate: moment(tempData.showStartDate)
                  .format("YYYY-MM-DD")
                  .toString(),
                showEndDate: moment(tempData.showEndDate)
                  .format("YYYY-MM-DD")
                  .toString(),
                showsImagePath: tempData.showsImagePath,
              };
              setTradeShowData((prevState) => ({
                ...prevState,
                ...tempObj,
              }));
            }
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };
  const handleSaveShowsDetailsByID = () => {
    if (handleValidation()) {
      try {
        const formData = new FormData();
        formData.append("ID", tradeShowData.id || 0);
        formData.append("ShowName", tradeShowData.showName);
        formData.append("City", tradeShowData.city);
        formData.append("website", tradeShowData.website);
        formData.append("State", tradeShowData.state);
        formData.append(
          "ShowStartDate",
          tradeShowData.showStartDate.replaceAll("-", "/")
        );
        formData.append(
          "showEndDate",
          tradeShowData.showEndDate.replaceAll("-", "/")
        );
        formData.append("ShowOpenTime", tradeShowData.showStartTime);
        formData.append("ShowCloseTime", tradeShowData.showEndTime);
        formData.append("IsClosed", false);
        if (tradeShowData.file) {
          formData.append("ShowsImagePath", tradeShowData.file || null);
        } else {
          formData.append(
            "ShowsImagePath",
            tradeShowData.showsImagePath || null
          );
        }
        setSubmitLoading(true);
        AdminSettingsService.SaveShowsDetailsByID(formData)
          .then((response) => {
            var message = response.data.message;
            var responseData = response.data.responseData;
            setSubmitLoading(false);
            if (message == "Success") {
              setVisible(false);
              handleGetShowsDetailsList();
              handleCancel();
              if (isEdit) {
                NotificationManager.success("Record Updated Successfully.");
              } else {
                NotificationManager.success("Record Saved Successfully.");
              }
            } else {
              if (isEdit) {
                NotificationManager.error("Record Not Updated.");
              } else {
                NotificationManager.error("Record Not Saved.");
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        setSubmitLoading(false);
        console.log(error);
      }
    }
  };
  const fileUpload = async (e) => {
    if (e.target.files[0].name) {
      if (
        e.target.files[0].name.toLowerCase().match(/.(jpg|jpeg|png|gif||PNG)$/i)
      ) {
        let img = new Image();
        img.src = window.URL.createObjectURL(e.target.files[0]);
        img.onload = () => {
          if (img.width <= 190 && img.height <= 190) {
            setTradeShowData({
              ...tradeShowData,
              ["file"]: e.target.files[0],
            });
            setValidations({
              ...validations,
              ["file"]: null,
            });
          } else {
            setValidations({
              ...validations,
              ["file"]: "Please Select Correct Image Size.",
            });
          }
        };
      }
    } else {
      setValidations({
        ...validations,
        ["file"]: "Please Select Only Images.",
      });
      setTradeShowData({
        ...tradeShowData,
        ["file"]: null,
      });
    }
  };
  const handleCancelImage = () => {
    debugger;
    setTradeShowData((prevState) => ({
      ...prevState,
      ["showsImagePath"]: null,
    }));

    setTradeShowData((prevState) => ({
      ...prevState,
      ["file"]: null,
    }));
  };

  function onSearch(val) {
    console.log("search:", val);
  }
  const closedOption = useRef();
  const hideEndTime = (elem) => {
    closedOption.current.style.display = "none";
  };
  const showEndTime = (elem) => {
    closedOption.current.style.display = "block";
  };
  return (
    <React.Fragment>
      <div className="tradeshow__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <h4 className="head__txt"> {traslate("TradeShows")} </h4>
                </div>
                <div className="col-lg-6">
                  <div className="addedit__btndiv">
                    <button
                      className="primary-btn"
                      onClick={() => setVisible(true)}
                    >
                      {" "}
                      <img src={Create_icon} /> {traslate("TradeShow")}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="tradeshow_tbldiv">
                <Table
                  columns={[
                    {
                      title: traslate("Show"),
                      dataIndex: "showName",
                    },
                    {
                      title: traslate("City"),
                      dataIndex: "city",
                      width: 120
                    },
                    {
                      title: traslate("State"),
                      dataIndex: "state",
                      width: 120
                    },
                    {
                      title: traslate("Dates"),
                      render: (item, rows) => {
                        return (
                          <>{rows.showStartDate + " - " + rows.showEndDate}</>
                        );
                      },
                    },
                    {
                      title: traslate("Action"),
                      dataIndex: "action",
                      render: (item, row) => {
                        return (
                          <div className="action__btns">
                            <div className="image__block">
                              <img
                                src={Edit_icon}
                                onClick={() =>
                                  handleGetShowsDetailsByID(row.id)
                                }
                                alt=""
                              />
                            </div>
                            <div className="image__block">
                              <img
                                src={Delete_iconbl}
                                onClick={() => handleOpenDeleteModal(row.id)}
                                alt=""
                              />
                            </div>
                          </div>
                        );
                      },
                      width: 100,
                    },
                  ]}
                  dataSource={tradeShowList}
                  scroll={{ x: 600 }}
                  loading={loading}
                />
              </div>
            </div>

            <div className="col-lg-12">
              <Modal
                title={
                  !isEdit ? traslate("AddTradeShow") : traslate("EditTradeShow")
                }
                className="modal__tradeshow"
                centered
                visible={visible}
                onOk={() => handleSaveShowsDetailsByID()}
                onCancel={() => handleCancel()}
                width={600}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => handleSaveShowsDetailsByID()}
                  >
                    {!isEdit ? traslate("Submit") : traslate("Save")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div class="col-lg-12">
                            <div className="col-md-6">
                              <div className="divimage_block w-100">
                                {tradeShowData.showsImagePath ||
                                tradeShowData.file ? (
                                  <div className="img__showdiv">
                                    {isEdit ? (
                                      tradeShowData.file ? (
                                        <img
                                          src={URL.createObjectURL(
                                            tradeShowData.file
                                          )}
                                        />
                                      ) : (
                                        <img
                                          src={tradeShowData.showsImagePath}
                                        />
                                      )
                                    ) : tradeShowData.file ? (
                                      <img
                                        src={URL.createObjectURL(
                                          tradeShowData.file
                                        )}
                                      />
                                    ) : null}
                                    <span onClick={handleCancelImage}>x</span>
                                  </div>
                                ) : (
                                  <div>
                                    <label
                                      htmlFor="file-upload"
                                      class="primary-btn choose_file_upload"
                                    >
                                      {traslate("ChooseImage")}
                                    </label>
                                    <input
                                      id="file-upload"
                                      type="file"
                                      onChange={(e) => fileUpload(e)}
                                    />
                                    {fileVal && (
                                      <p className="error-color-red">
                                        {fileVal}
                                      </p>
                                    )}
                                    <span class="mb-4">
                                      {" "}
                                      {traslate("NoFileChoosen")}{" "}
                                    </span>
                                    <span class="">
                                      190 X 190 {traslate("Pixels")}{" "}
                                    </span>
                                    <span class="">
                                      {" "}
                                      {/* {traslate("JPGFormat")}{" "} */}
                                      {".JPG | .JPEG | .PNG Format"}
                                    </span>
                                  </div>
                                )}
                                {validations["file"] ? (
                                  <span className="error-color-red">
                                    {validations["file"]}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("ShowName")}: <span>*</span>
                              </label>
                              <input
                                name="showName"
                                value={tradeShowData.showName}
                                onChange={handleOnChange}
                                type="text"
                                placeholder="Enter Show Name"
                                className={showNameVal && "border__red"}
                              />
                              {showNameVal && (
                                <p className="error-color-red">{showNameVal}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("City")}: <span>*</span>
                              </label>
                              <input
                                name="city"
                                value={tradeShowData.city}
                                onChange={handleOnChange}
                                type="text"
                                placeholder="Enter City"
                                className={cityVal && "border__red"}
                                autoComplete="off"
                              />
                              {cityVal && (
                                <p className="error-color-red">{cityVal}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("State")}: <span>*</span>
                              </label>
                              <input
                                name="state"
                                value={tradeShowData.state}
                                onChange={handleOnChange}
                                type="text"
                                placeholder="Enter State"
                                className={stateVal && "border__red"}
                                autoComplete="off"
                              />
                              {stateVal && (
                                <p className="error-color-red">{stateVal}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Website")}: <span>*</span>
                              </label>
                              <input
                                name="website"
                                value={tradeShowData.website}
                                onChange={handleOnChange}
                                type="text"
                                placeholder="Enter Website"
                                className={websiteVal && "border__red"}
                              />
                              {websiteVal && (
                                <p className="error-color-red">{websiteVal}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("ShowStartTime")}: <span>*</span>
                              </label>
                              {/* <input
                                name="showStartTime"
                                value={tradeShowData.showStartTime}
                                onChange={handleOnChange}
                                type="time"
                                placeholder="Enter Time"
                                className={showStartTimeVal && "border__red"}
                              /> */}
                              <Select
                                name="showStartTime"
                                className="border__grey"
                                showSearch
                                placeholder="Select Start Time"
                                optionFilterProp="children"
                                value={tradeShowData.showStartTime}
                                onChange={(e) =>
                                  handleOnChange(e, "select", "showStartTime")
                                }
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value="">Select Start Time</Option>
                                <Option value="8:00">8 {traslate("AM")}</Option>
                                <Option value="9:00">9 {traslate("AM")}</Option>
                                <Option value="10:00">
                                  10 {traslate("AM")}
                                </Option>
                                <Option value="11:00">
                                  11 {traslate("AM")}
                                </Option>
                                <Option value="closed">
                                  {traslate("Closed")}
                                </Option>
                              </Select>
                              {showStartTimeVal && (
                                <p className="error-color-red">
                                  {showStartTimeVal}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block" ref={closedOption}>
                              <label>
                                {" "}
                                {traslate("ShowEndTime")}: <span>*</span>
                              </label>
                              {/* <input
                                name="showEndTime"
                                value={tradeShowData.showEndTime}
                                onChange={handleOnChange}
                                type="time"
                                placeholder="Enter Time"
                                className={showEndTimeVal && "border__red"}
                              /> */}
                              <Select
                                name="showEndTime"
                                className="border__grey"
                                showSearch
                                placeholder="Select End Time"
                                optionFilterProp="children"
                                value={tradeShowData.showEndTime}
                                onChange={(e) =>
                                  handleOnChange(e, "select", "showEndTime")
                                }
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value="">Select End Time</Option>
                                <Option value="16:00">
                                  4 {traslate("PM")}
                                </Option>
                                <Option value="17:00">
                                  5 {traslate("PM")}
                                </Option>
                                <Option value="18:00">
                                  6 {traslate("PM")}
                                </Option>
                                <Option value="19:00">
                                  7 {traslate("PM")}
                                </Option>
                                <Option value="20:00">
                                  8 {traslate("PM")}
                                </Option>
                                <Option value="21:00">
                                  9 {traslate("PM")}
                                </Option>
                                <Option value="22:00">
                                  10 {traslate("PM")}
                                </Option>
                              </Select>
                              {showEndTimeVal && (
                                <p className="error-color-red">
                                  {showEndTimeVal}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("ShowStartDates")}: <span>*</span>
                              </label>
                              <input
                                name="showStartDate"
                                value={tradeShowData.showStartDate}
                                onChange={handleOnChange}
                                type="date"
                                placeholder="Enter Date"
                                className={showStartDateVal && "border__red"}
                              />
                              {showStartDateVal && (
                                <p className="error-color-red">
                                  {showStartDateVal}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("ShowEndDates")}: <span>*</span>
                              </label>
                              <input
                                name="showEndDate"
                                value={tradeShowData.showEndDate}
                                onChange={handleOnChange}
                                type="date"
                                placeholder="Enter Date"
                                className={showEndDateVal && "border__red"}
                              />
                              {showEndDateVal && (
                                <p className="error-color-red">
                                  {showEndDateVal}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                title={traslate("DeleteTradeShow")}
                className="modal__tradeshow"
                centered
                visible={visible2}
                onOk={() => setVisible2(false)}
                onCancel={() => setVisible2(false)}
                width={400}
                footer={[
                  <Button key="back" onClick={handleCancel2}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button key="submit" type="primary" onClick={handleOk2}>
                    {traslate("Delete")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div class="col-lg-12">
                            <p>{traslate("AreYouSureWanttodelete")} ?</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TradeShows;

const convertTime = (timeStr) => {
  const [time, modifier] = timeStr.split(" ");
  let [hours, minutes] = time.split(":");
  if (hours === "12") {
    hours = "00";
  }
  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }
  if (hours.toString().length == 1) {
    hours = "0" + hours;
  }
  return `${hours}:${minutes || "00"}`;
};
