import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { Link } from "react-router-dom";

import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Table,
  Modal,
  DatePicker,
  Switch,
  Space,
  Spin,
} from "antd";
import {
  EditOutlined,
  CalendarOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";
import AdminToolsDataService from "../../../services/admin-tools.service";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import traslate from "../../../i18n/translate";

const { Option } = Select;

export const ClientStatus = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [clientStatusData, setClientStatusData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [permisssionTypeMaster, setPermisssionTypeMaster] = useState([]);
  const [retailerStatusMaster, setRetailerStatusMaster] = useState([]);
  const [vendorStatusMaster, setVendorStatusMaster] = useState([]);
  const [productTypeMaster, setProductTypeMaster] = useState([]);
  const [sendID, setSendIdID] = useState("");
  const [editData, setEditData] = useState({
    dealerCompany: "",
    dealerId: "",
    verified: true,
    vendorStatusID: 0,
    retailerStatusId: 0,
    vendorTagNameID: 0,
    vendorMembershipType: "",
    retailerNameId: 0,
    vendorStatus: "",
    retailerStatus: "",
    productType: "",
    productTypeId: 0,
    vendorTagName: "",
    hasMasterLink: true,
    vendorApp: "",
    username: "",
    email: "",
    password: "",
    scheduledReminder: false,
  });
  const [dateData, setDateData] = useState({
    dealerID: "",
    changeDate: "",
    dealerCompany: "",
  });
  const dateFormatList = "YYYY/MM/DD";

  useEffect(() => {
    handleClientStatusList();
    GetPermisssionTypeMaster();
    GetRetailerStatusMaster();
    GetVendorStatusMaster();
  }, []);

  const GetPermisssionTypeMaster = () => {
    try {
      setLoading(true);
      AdminToolsDataService.GetPermisssionTypeMaster()
        .then((response) => {
          setLoading(false);
          var message = response.data.message;

          var responseData = response.data.responseData;
          if (message == "Success") {
            setPermisssionTypeMaster(responseData);
          } else {
            setPermisssionTypeMaster([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const GetRetailerStatusMaster = () => {
    try {
      setLoading(true);
      AdminToolsDataService.GetRetailerStatusMaster()
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setRetailerStatusMaster(responseData);
          } else {
            setRetailerStatusMaster([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const GetVendorStatusMaster = () => {
    try {
      setLoading(true);
      AdminToolsDataService.GetVendorStatusMaster()
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setVendorStatusMaster(responseData);
          } else {
            setVendorStatusMaster([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleClientStatusList = () => {
    debugger;
    let inputData = {
      clickChar: "",
      sortExpression: "",
    };
    try {
      setLoading(true);
      AdminToolsDataService.GetClientStatus(inputData)
        .then((response) => {
          debugger;
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData[0];
          var productTypeData = response.data.responseData[1];
          if (message == "Success") {
            setClientStatusData(responseData);
            setProductTypeMaster(productTypeData);
          } else {
            setClientStatusData([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const showModal = (row) => {
    setIsModalVisible(true);
    setEditData({
      dealerCompany: row.dealerCompany,
      dealerId: row.dealerId,
      verified: row.verified,
      vendorStatusID: row.vendorStatusID,
      retailerStatusId: row.retailerStatusId,
      vendorTagNameID: row.vendorTagNameID,
      vendorMembershipType: row.vendorMembershipType,
      retailerNameId: row.retailerNameId,
      vendorStatus: row.vendorStatus,
      retailerStatus: row.retailerStatus,
      productTypeId: row.productTypeId,
      productType: row.productType,
      vendorTagName: row.vendorTagName,
      hasMasterLink: true,
      vendorApp: row.vendorApp,
      username: row.username,
      email: row.email,
      password: row.password,
      scheduledReminder: row.scheduledReminder,
    });
    console.log(row);
  };

  const showModal2 = (row) => {
    debugger;

    setDateData((prevState) => ({
      ...prevState,
      dealerID: row.dealerId,
      dealerCompany: row.dealerCompany,
      changeDate: row.createdDate,
    }));

    setIsModalVisible2(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditData({});
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
    setDateData({
      dealerID: "",
      changeDate: "",
      dealerCompany: "",
    });
  };

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {traslate("Search")}
            </Button>
            <Button
              onClick={() => handleReset(clearFilters, confirm)}
              size="small"
              style={{ width: 90 }}
            >
              {traslate("Reset")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          // setTimeout(() => this.searchInput.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          text
        ),
    };
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters, confirm) {
    clearFilters();
    setSearchText("");
    confirm();
  }

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setEditData({
        ...editData,
        [name]: e,
      });
    } else {
      setEditData({
        ...editData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleDateChange = (e, name) => {
    setDateData({
      ...dateData,
      [name]: moment(e),
    });
  };

  const handleUpdate = () => {
    try {
      let inputData = {
        dealerCompany: editData.dealerCompany,
        dealerId: editData.dealerId,
        verified: editData.verified,
        vendorStatusID: editData.vendorStatusID,
        retailerStatusId: editData.retailerStatusId,
        vendorMembershipType: editData.vendorMembershipType,
        retailerNameId: editData.productTypeId,
        vendorStatus: "",
        retailerStatus: "",
        productTypeId: 0,
        productType: "",
        vendorTagName: "",
        hasMasterLink: true,
        vendorApp: "",
        username: "",
        email: "",
        password: "",
        ipAddress: "",
        logInUser: 0,
        logInUserName: "",
        scheduledReminder: editData.scheduledReminder,
      };
      setLoading(true);
      AdminToolsDataService.UpdateClientStatus(inputData)
        .then((response) => {
          var message = response.data.message;
          setLoading(false);
          if (message == "Success") {
            NotificationManager.success("Client status Updated Successfully.");
            handleClientStatusList();
          } else {
            NotificationManager.success("Client status Not Updated.");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleCancel();
  };

  const handleChangeDate = () => {
    debugger;
    try {
      let inputData = {
        dealerID: dateData.dealerID.toString(),
        createdDate: dateData.changeDate,
      };
      setLoading(true);
      AdminToolsDataService.ChangeDealerCreatedDate(inputData)
        .then((response) => {
          var message = response.data.message;
          setLoading(false);
          if (message == "Success") {
            NotificationManager.success(" Date Changed Successfully.");
            handleClientStatusList();
          } else {
            NotificationManager.success("Date Not Changed.");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleCancel2();
  };

  const onSelectDate = (e) => {
    debugger;
    setDateData((prevState) => ({
      ...prevState,
      changeDate: e.target.value,
    }));
  };
  return (
    <div className="form__fields">
      <div className="row">
        <div className="col-lg-12">
          <h4 class="workarea__heading mt-0 mb-0">
            {traslate("ClientStatus")}
          </h4>
        </div>

        <div className="divForm mt-4 ClientStatusTable">
          <Spin spinning={loading} size="large">
            <Table
              columns={[
                {
                  title: traslate("DealerID"),
                  ...getColumnSearchProps("dealerId"),
                  dataIndex: "dealerId",
                  key: "dealerId",
                  sorter: (a, b) => a.dealerId - b.dealerId,
                  width: 150,
                },
                {
                  title: traslate("DealerCompany"),
                  ...getColumnSearchProps("dealerCompany"),
                  dataIndex: "dealerCompany",
                  key: "dealerCompany",
                  sorter: (a, b) =>
                    a.dealerCompany.localeCompare(b.dealerCompany),
                  width: 180,
                },
                {
                  title: traslate("ClientStatus"),
                  dataIndex: "clientStatus",
                  filters: [
                    {
                      text: traslate("InActive"),
                      value: "In-Active",
                    },
                    {
                      text: traslate("Active"),
                      value: "Active",
                    },
                  ],
                  onFilter: (value, record) =>
                    record.clientStatus.indexOf(value) === 0,
                  sorter: (a, b) =>
                    a.clientStatus.length - b.clientStatus.length,
                  width: 150,
                },
                {
                  title: traslate("ApprovedApps"),
                  dataIndex: "vendorApp",
                  key: "1",
                  sorter: (a, b) => a.vendorApp.length - b.vendorApp.length,
                  width: 180,
                  render: (index, row) => (
                    <div>
                      <div>{row.vendorApp}</div>
                      <div>
                        <Link
                          to={"/applicationPermissions"}
                          state={{ key: row.dealerId }}
                        >
                          {traslate("Edit")}
                        </Link>
                      </div>
                    </div>
                  ),
                },
                {
                  title: traslate("PermissionType"),
                  dataIndex: "vendorMembershipType",
                  key: "2",
                  filters: [
                    {
                      text: traslate("VendorDiamond"),
                      value: "VendorDiamond",
                    },
                    {
                      text: traslate("Vendor"),
                      value: "Vendor",
                    },
                    {
                      text: traslate("Retailer"),
                      value: "Retailer",
                    },
                    {
                      text: traslate("Admin"),
                      value: "Admin",
                    },
                  ],
                  onFilter: (value, record) =>
                    record.vendorMembershipType === value,
                  sorter: (a, b) =>
                    a.vendorMembershipType.length -
                    b.vendorMembershipType.length,
                  width: 180,
                },
                {
                  title: traslate("VendorStatus"),
                  dataIndex: "vendorStatus",
                  key: "3",
                  width: 150,
                  filters: [
                    {
                      text: traslate("InActive"),
                      value: "InActive",
                    },
                    {
                      text: traslate("ProfileOnly"),
                      value: "Profile Only",
                    },
                    {
                      text: traslate("Active"),
                      value: "Active",
                    },
                    {
                      text: traslate("OutofNetwork"),
                      value: "Out of Network",
                    },
                  ],
                  onFilter: (value, record) =>
                    record.vendorStatus.indexOf(value) === 0,
                  sorter: (a, b) =>
                    a.vendorStatus.length - b.vendorStatus.length,
                },
                {
                  title: traslate("RetailerStatus"),
                  dataIndex: "retailerStatus",
                  key: "4",
                  width: 150,
                  filters: [
                    {
                      text: traslate("InActive"),
                      value: "In-Active",
                    },
                    {
                      text: traslate("Active"),
                      value: "Active",
                    },
                    {
                      text: "Active - No Profile",
                      value: "Active - No Profile",
                    },
                  ],
                  onFilter: (value, record) => {
                    debugger;
                    return record.retailerStatus === value;
                  },
                  sorter: (a, b) =>
                    a.retailerStatus.length - b.retailerStatus.length,
                },
                {
                  title: traslate("Verified"),
                  dataIndex: "verified",
                  key: "5",
                  width: 120,
                  render: (index, row) => (
                    <span>{row.verified ? "Yes" : "No"}</span>
                  ),
                  sorter: (a, b) => a.verified.length - b.verified.length,
                },
                {
                  title: traslate("ProductType"),
                  dataIndex: "productType",
                  key: "6",
                  width: 150,
                  sorter: (a, b) => a.productType.length - b.productType.length,
                },
                {
                  title: traslate("Scheduled Reminder"),
                  dataIndex: "scheduledReminder",
                  key: "5",
                  width: 150,
                  render: (index, row) => (
                    <span>{row.scheduledReminder ? "Yes" : "No"}</span>
                  ),
                  sorter: (a, b) =>
                    a.scheduledReminder.length - b.scheduledReminder.length,
                },

                // { title: 'Column 8', dataIndex: 'address', key: '8' },
                {
                  title: traslate("Action"),
                  key: "7",
                  width: 100,
                  render: (index, row) => (
                    // <a>
                    //   <EditOutlined onClick={showModal} />
                    //   <span className="mleft mx-2">
                    //     <CalendarOutlined onClick={showModal2} />
                    //   </span>
                    // </a>
                    <div className="action__btns">
                      <div className="image__block">
                        <img
                          src={Edit_icon}
                          onClick={() => showModal(row)}
                          alt=""
                        />
                      </div>
                      <div className="image__block">
                        {/* <img src={Delete_iconbl} onClick={showModal2} alt="" /> */}
                        <i
                          class="fa fa-calendar"
                          onClick={() => showModal2(row)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  ),
                },
              ]}
              dataSource={clientStatusData}
              scroll={{ x: 1400 }}
            />
          </Spin>
        </div>

        <Modal
          title={traslate("UpdateClientStatusInformation")}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              {traslate("Cancel")}
            </Button>,
            <Button key="submit" type="primary" onClick={handleUpdate}>
              {traslate("Save")}
            </Button>,
          ]}
        >
          <h6>
            {editData.dealerCompany}-{editData.dealerId}
          </h6>
          <div className="form__fields mt-3 p-0 border-0">
            <div className="row">
              <div className="col-md-6">
                <div className="input__block">
                  <label>{traslate("PermissionType")} </label>
                  <Select
                    showSearch
                    className="border__grey"
                    placeholder="Select Permission Type "
                    optionFilterProp="children"
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) =>
                      handleOnChange(e, "select", "vendorMembershipType")
                    }
                    name="vendorMembershipType"
                    value={
                      editData.vendorMembershipType
                        ? editData.vendorMembershipType
                        : null
                    }
                  >
                    {permisssionTypeMaster.map((item) => {
                      return (
                        <Option
                          key={item.membeshipTypeId}
                          value={item.membeshipTypeText}
                        >
                          {item.membeshipTypeText}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label>{traslate("Verified")} </label>
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    name="verified"
                    checked={editData.verified}
                    onChange={(e) => handleOnChange(e, "select", "verified")}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label>{traslate("VendorStatus")} </label>
                  <Select
                    showSearch
                    className="border__grey"
                    placeholder="Select Vendor Status"
                    optionFilterProp="children"
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) =>
                      handleOnChange(e, "select", "vendorStatusID")
                    }
                    name="vendorStatusID"
                    value={
                      editData.vendorStatusID ? editData.vendorStatusID : null
                    }
                  >
                    {vendorStatusMaster.map((item) => {
                      return (
                        <Option key={item.statusId} value={item.statusId}>
                          {item.statusText}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label> {traslate("RetailerStatus")} </label>
                  <Select
                    showSearch
                    className="border__grey"
                    placeholder="Select Retailer Status "
                    optionFilterProp="children"
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) =>
                      handleOnChange(e, "select", "retailerStatusId")
                    }
                    name="retailerStatusId"
                    value={
                      editData.retailerStatusId
                        ? editData.retailerStatusId
                        : null
                    }
                  >
                    {retailerStatusMaster.map((item) => {
                      return (
                        <Option key={item.statusId} value={item.statusId}>
                          {item.statusText}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label> {traslate("ProductType")} </label>
                  <Select
                    showSearch
                    className="border__grey"
                    placeholder="Select Product Type "
                    optionFilterProp="children"
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) =>
                      handleOnChange(e, "select", "productTypeId")
                    }
                    name="productTypeId"
                    value={
                      editData.productTypeId ? editData.productTypeId : null
                    }
                  >
                    {productTypeMaster.map((item) => {
                      return (
                        <Option
                          key={item.productTypeId}
                          value={item.productTypeId}
                        >
                          {item.productType}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__block">
                  <label>{traslate("ScheduledReminder")} </label>
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    name="scheduledReminder"
                    checked={editData.scheduledReminder}
                    onChange={(e) =>
                      handleOnChange(e, "select", "scheduledReminder")
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          title={traslate("UpdateClientStatusInformation")}
          visible={isModalVisible2}
          onCancel={handleCancel2}
          footer={[
            <Button key="back" onClick={handleCancel2}>
              {traslate("Cancel")}
            </Button>,
            <Button key="submit" type="primary" onClick={handleChangeDate}>
              {traslate("Save")}
            </Button>,
          ]}
        >
          {/* <h6>{traslate(`UpdateDateonwhich${dateData.dealerCompany}isCreated`)}</h6> */}
          <h6>Update Date on which {dateData.dealerCompany} is Created</h6>
          <div className="row">
            <div className="col-md-6">
              <div className="input__block">
                <input
                  name="changeDate"
                  value={dateData.changeDate ? dateData.changeDate : ""}
                  onChange={onSelectDate}
                  type="date"
                  placeholder="Enter Date"
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ClientStatus);
