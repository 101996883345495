import React, { useState } from "react";
import { Input, Row, Col, Select, Checkbox, Button, Switch, Modal } from "antd";
import { Tabs, Tab, } from 'react-bootstrap';
import Noun_arrow from "../../../assets/images/noun_arrow.png";
import Rectangle from "../../../assets/images/Rectangle.png";
import WatchBuilderImg from "../../../assets/images/WatchBuilderImage.jpg";
import Watch1 from "../../../assets/images/watch01.png";
import Watch2 from "../../../assets/images/watch02.png";
import Watch3 from "../../../assets/images/watch03.png";
import Bracelet1 from "../../../assets/images/braceletstyle01.png";
import Bracelet2 from "../../../assets/images/braceletstyle02.png";
import Bracelet3 from "../../../assets/images/braceletstyle03.png";
import BraceletMetal1 from "../../../assets/images/braceletmetal01.png";
import BraceletMetal2 from "../../../assets/images/braceletmetal02.png";
import BraceletMetal3 from "../../../assets/images/braceletmetal03.png";
import BraceletMetal4 from "../../../assets/images/braceletmetal04.png";
import BezelType1 from "../../../assets/images/bezeltype01.png";
import BezelType2 from "../../../assets/images/bezeltype02.png";

import { Accordion } from 'react-bootstrap';

const WatchCustomizer = () => {

    const [switchValue, setswitchValue] = useState(true);
    const { Option } = Select;

    // Modal Functions
    const [isModalVisible1, setIsModalVisible1] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);
    const [isModalVisible3, setIsModalVisible3] = useState(false);
    const [isModalVisible4, setIsModalVisible4] = useState(false);
    const [isModalVisible5, setIsModalVisible5] = useState(false);

    const showModal1 = () => {
        setIsModalVisible1(true);
    };
    const handleCancel1 = () => {
        setIsModalVisible1(false);
    };

    const showModal2 = () => {
        setIsModalVisible2(true);
    };
    const handleCancel2 = () => {
        setIsModalVisible2(false);
    };

    const showModal3 = () => {
        setIsModalVisible3(true);
    };
    const handleCancel3 = () => {
        setIsModalVisible3(false);
    };

    const showModal4 = () => {
        setIsModalVisible4(true);
    };
    const handleCancel4 = () => {
        setIsModalVisible4(false);
    };

    const showModal5 = () => {
        setIsModalVisible5(true);
    };
    const handleCancel5 = () => {
        setIsModalVisible5(false);
    };

    function onChange(value) {
        console.log(`selected ${value}`);
    }

    function onSearch(val) {
        console.log("search:", val);
    }

    function onChange(e) {
        console.log(`checked = ${e.target.checked}`);
    }

    const handleSwitch = () => {
        setswitchValue(!switchValue);
    };
    return (
        <React.Fragment>
            <div className="prodetail__section form__fields p-0 border-0">

                <div className="col-md-12">
                    <div className="prod__maindiv">
                        <div className="row">
                            <div class="col-md-12">
                                <div class="heading__block">
                                    <h4 class="workarea__heading mt-0">Watch Customizer</h4>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3">
                                <div className="prodimg__div">
                                    <p>Configure & Customize Your <br /> Pre-Owned Rolex Watch</p>
                                    <img src={WatchBuilderImg} alt="watch builder" />
                                    <div className="request-info">
                                        <div className="request-info-links">
                                            <a href="#" onClick={showModal1}>Request More Info</a>
                                            <a href="#" onClick={showModal2}>Drop A Hint</a>
                                            <a href="#" onClick={showModal3}>E-Mail A Friend</a>
                                            <a href="#" onClick={showModal4}>Print Details</a>
                                            <a href="#" onClick={showModal5}>Schedule Viewing</a>
                                        </div>
                                        <p>Have a question regarding this item? Our specialist are available to assist you. | Call us:</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-9">
                                <div className="prodesc__div">

                                    <Tabs defaultActiveKey="ItemsDetails" id="uncontrolled-tab-example" className="tab__div mb-3">
                                        <Tab eventKey="ItemsDetails" title="Items Details">
                                            <div className="accordian__section">
                                                <Accordion defaultActiveKey="0">
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>
                                                            <div>
                                                                <label>STEP 1 | Size & Bracelet Type <span>(36mm)</span></label>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="row">
                                                                <div className="selection-title">
                                                                    <h5>Choose Watch Type</h5>
                                                                </div>
                                                                <div className="selection__block row">
                                                                    <div className="selection__box selected col-md-4">
                                                                        <div className="watch-desc">
                                                                            <div className="category">
                                                                                <h4>Men's</h4>
                                                                            </div>
                                                                            <div className="size">
                                                                                <span>36mm</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="img-section">
                                                                            <img src={Watch1} alt="rolex" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="selection__box col-md-4">
                                                                        <div className="watch-desc">
                                                                            <div className="category">
                                                                                <h4>Ladies</h4>
                                                                            </div>
                                                                            <div className="size">
                                                                                <span>26mm</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="img-section">
                                                                            <img src={Watch2} alt="rolex" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="selection__box col-md-4">
                                                                        <div className="watch-desc">
                                                                            <div className="category">
                                                                                <h4>Mid Size</h4>
                                                                            </div>
                                                                            <div className="size">
                                                                                <span>31mm</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="img-section">
                                                                            <img src={Watch3} alt="rolex" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="row mt-4">
                                                                {/* <div className="selection-title">
                                                            <h5>Choose Watch Type</h5>
                                                            </div> */}
                                                                <div className="col-md-6">
                                                                    <div className="selection-title">
                                                                        <h5>Choose Bracelet Style</h5>
                                                                    </div>
                                                                    <div className="selection__block row m-0">

                                                                        <div className="selection__box selected col-md-4">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={Bracelet1} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>Jubilee</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-4">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={Bracelet2} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>Oyster</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-4">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={Bracelet3} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>President</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="selection-title">
                                                                        <h5>Choose Bracelet Metal</h5>
                                                                    </div>
                                                                    <div className="selection__block row m-0">

                                                                        <div className="selection__box selected col-md-3">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BraceletMetal1} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>Stainless Steel</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-3">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BraceletMetal2} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>18K Y/SS</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-3">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BraceletMetal3} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>18K Yellow</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-3">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BraceletMetal4} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>18K White</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </Accordion.Body>
                                                    </Accordion.Item>

                                                    <Accordion.Item eventKey="1">
                                                        <Accordion.Header>
                                                            <div>
                                                                <label>STEP 2 | Bezel Type</label>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="row mt-4">
                                                                {/* <div className="selection-title">
                                                            <h5>Choose Watch Type</h5>
                                                            </div> */}
                                                                <div className="col-md-6">
                                                                    <div className="selection-title">
                                                                        <h5>Choose Bezel Type</h5>
                                                                    </div>
                                                                    <div className="selection__block row m-0">

                                                                        <div className="selection__box selected col-md-4">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType1} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>Jubilee</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-4">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType2} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>Oyster</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-4">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType1} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>President</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="selection-title">
                                                                        <h5>Choose Bezel Metal</h5>
                                                                    </div>
                                                                    <div className="selection__block row m-0">

                                                                        <div className="selection__box selected col-md-3">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType1} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>Stainless Steel</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-3">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType1} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>18K Y/SS</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-3">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType2} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>18K Yellow</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-3">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType2} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>18K White</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="row mt-4">

                                                                <div className="col-md-6">
                                                                    <div className="selection-title">
                                                                        <h5>Choose Bezel Gemstone Type</h5>
                                                                    </div>
                                                                    <div className="selection__block row m-0">

                                                                        <div className="selection__box selected col-md-4">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType1} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>Jubilee</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-4">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType2} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>Oyster</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-4">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType1} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>President</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="selection-title">
                                                                        <h5>Choose Bezel Gemstone Shape</h5>
                                                                    </div>
                                                                    <div className="selection__block row m-0">

                                                                        <div className="selection__box selected col-md-3">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType1} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>Stainless Steel</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-3">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType2} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>18K Y/SS</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-3">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType1} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>18K Yellow</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-3">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType2} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>18K White</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="row mt-4">
                                                                <div className="col-md-6">
                                                                    <div className="selection-title">
                                                                        <h5>Choose Bezel Diamond Quality</h5>
                                                                    </div>
                                                                    <div className="selection__block row m-0">

                                                                        <div className="selection__box selected col-md-4">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType1} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>Jubilee</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-4">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType2} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>Oyster</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-4">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType1} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>President</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>

                                                    <Accordion.Item eventKey="2">
                                                        <Accordion.Header>
                                                            <div>
                                                                <label>STEP 3 | Dial Type</label>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="row mt-4">
                                                                {/* <div className="selection-title">
                                                            <h5>Choose Watch Type</h5>
                                                            </div> */}
                                                                <div className="col-md-12">
                                                                    <div className="selection-title">
                                                                        <h5>Choose Dial Type</h5>
                                                                    </div>
                                                                    <div className="selection__block row m-0">

                                                                        <div className="selection__box selected col-md-6">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType1} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>Jubilee</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-6">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType2} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>Oyster</h4>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="row mt-4">

                                                                <div className="col-md-12">
                                                                    <div className="selection-title">
                                                                        <h5>Marker Type</h5>
                                                                    </div>
                                                                    <div className="selection__block row m-0">

                                                                        <div className="selection__box selected col-md-2">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType1} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>Jubilee</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-2">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType2} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>Oyster</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-2">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType1} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>President</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col-md-12">
                                                                    <div className="selection-title">
                                                                        <h5>Custom Dial Colors</h5>
                                                                    </div>
                                                                    <div className="selection__block row m-0">

                                                                        <div className="selection__box selected col-md-2">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType1} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>Stainless Steel</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-2">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType2} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>18K Y/SS</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-2">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType1} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>18K Yellow</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="selection__box col-md-2">

                                                                            <div className="img-section img-section-sp">
                                                                                <img src={BezelType2} alt="rolex" />
                                                                            </div>
                                                                            <div className="watch-desc-sm">
                                                                                <h4>18K White</h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </Accordion.Body>
                                                    </Accordion.Item>

                                                </Accordion>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="specifications" title="Specifications">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="specifications-heading">
                                                        <h5>Size & Bracelet Type (STEP 1) <span>(36mm)</span></h5>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="vert-table">
                                                                <div className="row compare-row-sp">
                                                                    <div className="col-md-5">
                                                                        <label>SKU#</label>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div class="input__block mb-0">
                                                                            <input type="text" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="row compare-row-sp">
                                                                    <div className="col-md-5">
                                                                        <label>Watch Type</label>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div class="input__block mb-0">
                                                                            <Select
                                                                                className="border__grey"
                                                                                optionFilterProp="children"
                                                                                placeholder="Ladies"
                                                                            >
                                                                                <Option value="jack">Ladies</Option>
                                                                                <Option value="lucy">Men</Option>
                                                                                <Option value="lucy">MidSize</Option>
                                                                            </Select>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="row compare-row-sp">
                                                                    <div className="col-md-5">
                                                                        <label>Bracelet Style</label>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div class="input__block mb-0">
                                                                            <input type="text" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row compare-row-sp">
                                                                    <div className="col-md-5">
                                                                        <label>Bracelet Metal</label>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div class="input__block mb-0">
                                                                            <input type="text" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-4">
                                                <div className="col-md-12">
                                                    <div className="specifications-heading">
                                                        <h5>Bezel Type (STEP 2)</h5>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="vert-table">
                                                                <div className="row compare-row-sp">
                                                                    <div className="col-md-5">
                                                                        <label>Custom Bezel Type</label>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div class="input__block mb-0">
                                                                            <input type="text" />
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div className="row compare-row-sp">
                                                                    <div className="col-md-5">
                                                                        <label>Custom Bezel Metal</label>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div class="input__block mb-0">
                                                                            <input type="text" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row compare-row-sp">
                                                                    <div className="col-md-5">
                                                                        <label>Custom Bezel Gemstones</label>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div class="input__block mb-0">
                                                                            <input type="text" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row compare-row-sp">
                                                                    <div className="col-md-5">
                                                                        <label>Custom Bezel Gemstone Shape</label>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div class="input__block mb-0">
                                                                            <input type="text" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row compare-row-sp">
                                                                    <div className="col-md-5">
                                                                        <label>Custom Bezel Diamond Quality</label>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div class="input__block mb-0">
                                                                            <input type="text" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row compare-row-sp">
                                                                    <div className="col-md-5">
                                                                        <label>Total Carat Weight</label>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div class="input__block mb-0">
                                                                            <input type="text" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-4">
                                                <div className="col-md-12">
                                                    <div className="specifications-heading">
                                                        <h5>Dial Type (STEP 3)</h5>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="vert-table">
                                                                <div className="row compare-row-sp">
                                                                    <div className="col-md-5">
                                                                        <label>Dial Type</label>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div class="input__block mb-0">
                                                                            <input type="text" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row compare-row-sp">
                                                                    <div className="col-md-5">
                                                                        <label>Marker Type</label>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div class="input__block mb-0">
                                                                            <input type="text" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row compare-row-sp">
                                                                    <div className="col-md-5">
                                                                        <label>Custom Dial Color</label>
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <div class="input__block mb-0">
                                                                            <input type="text" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </Tab>
                                    </Tabs>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>

                {/* Modal Section Starts */}

                {/* Request More Info Modal Starts */}
                <Modal
                    title="Request More Info"
                    visible={isModalVisible1}
                    className="request_info"
                    onCancel={handleCancel1}
                    footer={[
                        <Button key="back" onClick={handleCancel1}>
                            Cancel
                </Button>,
                        <Button key="submit" type="primary">
                            Add
                </Button>,
                    ]}
                >
                    <div className="row mt-4">
                        <div class="col-md-12">
                            <div className="table__block">
                                <div className="row p-0 border-0 form__fields mh-auto">
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>Your Name</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>Your E-Mail Address</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>Your Company Name</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>Your Phone Number</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>Your Store Phone Number</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="input__block">
                                            <label>Write A Personal Message</label>
                                            <textarea></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* Request More Info Modal Ends */}

                {/* Drop A Hint Modal Starts */}
                <Modal
                    title="Drop A Hint"
                    visible={isModalVisible2}
                    className="drop_a_hint"
                    onCancel={handleCancel2}
                    footer={[
                        <Button key="back" onClick={handleCancel2}>
                            Cancel
                </Button>,
                        <Button key="submit" type="primary">
                            Send
                </Button>,
                    ]}
                >
                    <div className="row mt-4">
                        <div class="col-md-12">
                            <div className="table__block">
                                <div className="row p-0 border-0 form__fields mh-auto">
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>Hint Recipient's Name</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>Hint Recipient's E-Mail</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>Reason For This Gift</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>Gift Deadline</label>
                                            <input type="text" />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="input__block">
                                            <label>Add A Personal Message Here ...</label>
                                            <textarea></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* Drop A Hint Modal Ends */}

                {/* E-Mail A Friend Modal Starts */}
                <Modal
                    title="E-Mail A Friend"
                    visible={isModalVisible3}
                    className="email_a_friend"
                    onCancel={handleCancel3}
                    footer={[
                        <Button key="back" onClick={handleCancel3}>
                            Cancel
                </Button>,
                        <Button key="submit" type="primary">
                            Add
                </Button>,
                    ]}
                >
                    <div className="row mt-4">
                        <div class="col-md-12">
                            <div className="table__block">
                                <div className="row p-0 border-0 form__fields mh-auto">
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>Your Name</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>Your E-Mail Address</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>Friend E-Mail Address</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>Subject</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="input__block">
                                            <label>Write A Personal Message</label>
                                            <textarea></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* E-Mail A Friend Modal Ends */}

                {/* Print Details Modal Starts */}
                <Modal
                    title="Print Details"
                    visible={isModalVisible4}
                    className="print_details"
                    onCancel={handleCancel4}
                >
                    <div className="row mt-4">
                        <div class="col-md-12">
                            <div className="table__block">
                                <div className="row p-0 border-0 form__fields mh-auto">
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>Your Name</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>Your E-Mail Address</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>Your Company Name</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>Your Phone Number</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>Your Store Phone Number</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="input__block">
                                            <label>Write A Personal Message</label>
                                            <textarea></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* Print Details Modal Ends */}

                {/* Schedule Viewing Modal Starts */}
                <Modal
                    title="Schedule Viewing"
                    visible={isModalVisible5}
                    className="schedule_viewing"
                    onCancel={handleCancel5}
                    footer={[
                        <Button key="back" onClick={handleCancel5}>
                            Cancel
                </Button>,
                        <Button key="submit" type="primary">
                            Schedule
                </Button>,
                    ]}
                >
                    <div className="row mt-4">
                        <div class="col-md-12">
                            <div className="table__block">
                                <div className="row p-0 border-0 form__fields mh-auto">
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>Your Email</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>Appointment Date</label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="input__block">
                                            <label>Add A Message Here ....</label>
                                            <textarea></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="request-info-links">
                                            <a href="#">Get Directions</a>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* Schedule Viewing Modal Ends */}


                {/* Modal Section Ends */}

            </div>
        </React.Fragment>
    )
}

export default WatchCustomizer
