import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table
} from "antd";
import traslate from "../../../i18n/translate";

const { Option } = Select;

const JewelryLogReports = () => {
  const columns = [
    {
      title: traslate("DealerID"),
      dataIndex: "dealerID",
      width: 100
    },
    {
      title: traslate("DealerCompany"),
      dataIndex: "dealercompany"
    },
    {
      title: traslate("Count"),
      dataIndex: "count",
      width: 100
    },
    {
      title: traslate("LastUploadDate"),
      dataIndex: "lastuploadate"
    }
  ];

  const data = [
    {
      key: "1",
      dealerID: <span>399</span>,
      dealercompany: <span> Davids Ltd Jeweler's</span>,
      count: <span>25</span>,
      lastuploadate: <span>05-05-2021</span>
    },
    {
      key: "2",
      dealerID: <span>399</span>,
      dealercompany: <span> Davids Ltd Jeweler's</span>,
      count: <span>25</span>,
      lastuploadate: <span>05-05-2021</span>
    },
    {
      key: "3",
      dealerID: <span>399</span>,
      dealercompany: <span> Davids Ltd Jeweler's</span>,
      count: <span>25</span>,
      lastuploadate: <span>05-05-2021</span>
    },
    {
      dealerID: <span>399</span>,
      dealercompany: <span> Davids Ltd Jeweler's</span>,
      count: <span>25</span>,
      lastuploadate: <span>05-05-2021</span>
    }
  ];

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields magento__mapping">
        <div className="row">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
              {traslate("JewelryLogReports")}
              </h4>
              <button className="primary-btn">
                <i className="fa fa-download mr-1"></i>
                {traslate("Download")}
              </button>
            </div>
          </div>

          {/* <div className="col-md-12">
            <div className="button__block my-4">
              <button className="primary-btn">Download</button>
            </div>
          </div> */}

          <div className="col-lg-12 col-md-12 mt-4">
            <Table
              className="pendjewelreq__tbl"
              columns={columns}
              dataSource={data}
              scroll={{ x: 600 }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JewelryLogReports;
