import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import Login from "./component/login/login";
import Register from "./component/login/Register";
import ClientStatus from "./component/admin/tools/clientStatus";
import CreateNewClient from "./component/admin/tools/createNewClient";
import Catlog from "./component/Catlog/Catlog";
import Layout from "./component/main/layout";
import CatlogList from "./component/Catlog/CatlogList";
import Dashboard from "./component/dashboard/Dashboard";
import Allproducts from "./component/All Products/Allproducts";
import Appointment from "./component/Marketing/appointment";
import ProductDetails from "./component/All Products/ProductDetails";
import Administrationtools from "./component/admin/tools/administrationtools";
import ApplicationPermissions from "./component/admin/tools/ApplicationPermissions";
import DiamondRapNetPermission from "./component/admin/tools/DiamondRapNetPermission";
import AddNewRetailerToCore from "./component/admin/tools/AddNewRetailerToCore";
import VendorRatings from "./component/admin/tools/VendorRatings";
import ClientDataMapping from "./component/admin/tools/ClientDataMapping";
import Limits from "./component/admin/tools/Limits";
import Legacy from "./component/admin/tools/Legacy";
import ReportsPermissions from "./component/admin/tools/ReportsPermissions";
import Retailerlocator from "./component/admin/tools/Retailerlocator";
import BlockDesignersRetailer from "./component/admin/tools/BlockDesignersRetailer";
import DataMaps from "./component/admin/settings/DataMaps";
import MetalMarket from "./component/admin/settings/MetalMarket";
import LoginPreferences from "./component/admin/tools/LoginPreferences";
import Permissions from "./component/admin/settings/Permissions";
import EmailTemplate from "./component/admin/settings/EmailTemplate";
import MagentoMapping from "./component/admin/tools/MagentoMapping";
import ConsoleUpload from "./component/admin/tools/ConsoleUpload";
import TransferZZGemfind from "./component/admin/tools/TransferZZGemfind";
import TradeShows from "./component/admin/settings/TradeShows";
import MailingLists from "./component/admin/campaigns/MailingLists";
import CampaignData from "./component/admin/campaigns/CampaignData";
import ComposeMail from "./component/admin/campaigns/ComposeMail";
import ApiAccessToken from "./component/admin/settings/ApiAccessToken";
import Addcolor from "./component/admin/settings/Addcolor";
import UploadHistory from "./component/Catlog/UploadHistory";
import DiamondUploadHistory from "./component/Catlog/Diamonds/DiamondUploadHistory";
import UploadDiamondFile from "./component/Catlog/Diamonds/UploadDiamondFile";
import UploadProductFile from "./component/Catlog/UploadProductFile";
import ManageCollection from "./component/Catlog/ManageCollection";
import AddSignleDiamond from "./component/Catlog/Diamonds/AddSingleDiamond";
import DiamondMapping from "./component/Catlog/Diamonds/DiamondMapping";
import ProductMarkup from "./component/Catlog/ProductMarkup";
import AddCollection from "./component/Catlog/AddCollection";
import ViewMyMinedDiamond from "./component/Catlog/Diamonds/ViewMyMinedDiamond";
import ViewMyColorDiamond from "./component/Catlog/Diamonds/ViewMyColorDiamond";
import ViewMyLabDiamond from "./component/Catlog/Diamonds/ViewMyLabDiamond";
import JewelrySearch from "./component/SearchFeature/JewelrySearch";
import WatchesSearch from "./component/SearchFeature/WatchesSearch";

import DiamondSearch from "./component/SearchFeature/DiamondSearch";
import ColorDiamondSearch from "./component/SearchFeature/ColorDiamondSearch";
import LabDiamondSearch from "./component/SearchFeature/LabDiamondSearch";
import { NotificationContainer } from "react-notifications";
import { useSelector } from "react-redux";
import FTPCredential from "./component/Catlog/Diamonds/FTPCredential";
import FancyColorMarkup from "./component/Apps/DiamondLinks/FancyColorMarkup";
import LabGrowMarkup from "./component/Apps/DiamondLinks/LabGrowMarkup";
import Options from "./component/Apps/DiamondLinks/Options";
import ApplicationPreview from "./component/Apps/DiamondLinks/ApplicationPreview";
import Shipping from "./component/Apps/DiamondLinks/Shipping";
import Navigation from "./component/Apps/DiamondLinks/Navigation";
import DiamondLinkLegacy from "./component/Apps/DiamondLinks/DiamondLinkLegacy";
import ShoppingIntegrations from "./component/Apps/DiamondLinks/ShoppingIntegrations";
import Dealers from "./component/Apps/DiamondLinks/Dealers";
import MinedDiamondsMarkup from "./component/Apps/DiamondLinks/MinedDiamondMarkup";
import Markups from "./component/Apps/RingBuilder/Markups";
import Settings from "./component/Apps/RingBuilder/Settings";
import RingbuilderNavigation from "./component/Apps/RingBuilder/RingbuilderNavigation";
import RingbuilderLegacy from "./component/Apps/RingBuilder/RingbuilderLegacy";
import RingbuilderShoppingIntegrations from "./component/Apps/RingBuilder/RingbuilderShoppingIntegrations";
import AvailableFilterSettings from "./component/Apps/DiamondLinks/AvailableFilterSettings";
import InitialFilterSettings from "./component/Apps/DiamondLinks/InitialFilterSettings";
import RingBuilderOptions from "./component/Apps/RingBuilder/Options";
import RingBuilderEmailTemplate from "./component/Apps/RingBuilder/EmailTemplate";
import Vendors from "./component/Apps/RingBuilder/Vendors";
import StudBuilderManageVendors from "./component/Apps/StudBuilder/StudBuilderManageVendors";
import ArchiveVendors from "./component/Apps/RingBuilder/ArchiveVendors";
import RingBuilderManageVendors from "./component/Apps/RingBuilder/RingBuilderManageVendors";
import RingBuilderDaimondMarkup from "./component/Apps/RingBuilder/RingBuilderDaimondMarkup";
import MasterLinkLegacy from "./component/Apps/MasterLink/MasterLinkLegacy";
import OutGoingDataMap from "./component/admin/tools/OutGoingDataMap";
import OutBoundSync from "./component/admin/tools/OutBoundSync";
import PendingSync from "./component/admin/tools/PendingSync";
import StudBuilderApplicationPreview from "./component/Apps/StudBuilder/StudBuilderApplicationPreview";
import StudBuilderEmailTemplate from "./component/Apps/StudBuilder/StudBuilderEmailTemplate";
import MasterLinkOptions from "./component/Apps/MasterLink/MasterLinkOptions";
import MasterLinkShipping from "./component/Apps/MasterLink/MasterLinkShipping";
import MasterLinkEmailTemplate from "./component/Apps/MasterLink/MasterLinkEmailTemplate";
import MasterLinkShoppingIntegration from "./component/Apps/MasterLink/MasterLinkShoppingIntegration";
import PendantBuilderApplicationPreview from "./component/Apps/PendantBuilder/PendantBuilderApplicationPreview";
import StudBuilderOptions from "./component/Apps/StudBuilder/StudBuilderOptions";
import StudBuilderSettings from "./component/Apps/StudBuilder/StudBuilderSettings";
import StudBuilderShoppingIntegrations from "./component/Apps/StudBuilder/StudBuilderShoppingIntegrations";
import WatchCustomizer from "./component/Apps/WatchCustomizer/WatchCustomizer";
import PendantBuilderOptions from "./component/Apps/PendantBuilder/PendantBuilderOptions";
import PendantBuilderSettings from "./component/Apps/PendantBuilder/PendantBuilderSettings";
import PendantBuilderEmailTemplate from "./component/Apps/PendantBuilder/PendantBuilderEmailTemplate";
import PendantBuilderShoppingIntegrations from "./component/Apps/PendantBuilder/PendantBuilderShoppingIntegrations";
import PendantBuilderNavigation from "./component/Apps/PendantBuilder/PendantBuilderNavigation";
import Timepieces from "./component/Apps/Timepieces";
import MyVendors from "./component/Community/VendorManager/MyVendors";
import Network from "./component/Community/VendorManager/Network";
import Inviteyourvendor from "./component/Community/VendorManager/Inviteyourvendor";
import New from "./component/Community/VendorManager/New";
import OutOfNetwork from "./component/Community/VendorManager/OutOfNetwork";
import RecentlyUpdated from "./component/Community/VendorManager/RecentlyUpdated";
import VendorManagerPopular from "./component/Community/VendorManager/VendorManagerPopular";
import VendorManagerArchived from "./component/Community/VendorManager/VendorManagerArchived";
import RetailerProfile from "./component/Retailer/RetailerProfile";
import JewelryRetailerPermission from "./component/Retailer/JewelryRetailerPermission";
import PendingJewelryRequest from "./component/Retailer/PendingJewelryRequest";
import DiamondRetailerPermission from "./component/Retailer/DiamondRetailerPermission";
import PendingDiamondRequest from "./component/Retailer/PendingDiamondRequest";
import RingBuilderRetailerPermission from "./component/Retailer/RingBuilderRetailerPermission";
import PendingRingBuilderRequest from "./component/Retailer/PendingRingBuilderRequest";
// import VendorManagerPopular from "./component/Community/VendorManager/VendorManagerPopular";
// import VendorManagerArchived from "./component/Community/VendorManager/VendorManagerArchived";
import PurchaseOrder from "./component/Purchase Order/PurchaseOrder";
import PurchaseOrderHistory from "./component/Purchase Order/PurchaseOrderHistory";
import UpdatePurchaseOrder from "./component/Purchase Order/UpdatePurchaseOrder";
import VendorManagerPendingRequests from "./component/Community/VendorManager/VendorManagerPendingRequests";
import UploadMarketingMaterial from "./component/Marketing/UploadMarketingMaterial";
import AddProducts from "./component/Catlog/AddProducts";
import EditProfile from "./component/User Profile/EditProfile";
import JewelryCategoryReport from "./component/reports/jewelry/JewelryCategoryReport";
import JewelryChartReport from "./component/reports/jewelry/JeweleryChartReports";
import DataMaping from "./component/Catlog/DataMapping/DataMaping";
import ManageUser from "./component/User Profile/ManageUser";
import ManageAccount from "./component/User Profile/ManageAccount";
import ManageLanguage from "./component/User Profile/ManageLanguage";
import ManageCurrency from "./component/User Profile/ManageCurrency";

import RingBuilderApiEndPoint from "./component/Apps/RingBuilder/RingBuilderApiEndPoint";
import DiamondLinkApiEndPoint from "./component/Apps/DiamondLinks/DiamondLinkApiEndPoint";
import JewelryChartReportAdmin from "./component/admin/JewelryReport/JewelryChartReportAdmin";
import JewelryCategoryReportAdmin from "./component/admin/JewelryReport/JewelryCategoryReportAdmin";
import JewelryMetalTypeReport from "./component/admin/JewelryReport/JewelryMetalTypeReport";
import JewelryPriceReport from "./component/admin/JewelryReport/JewelryPriceReport";
import JewelryRetailerClicksReport from "./component/admin/JewelryReport/JewelryRetailerClicksReport";
import JewelryVendorClicksReport from "./component/admin/JewelryReport/JewelryVendorClicksReport";
import Syncs from "./component/admin/reports/Syncs";
import ToolsReports from "./component/admin/reports/ToolsReports";
import DataRelated from "./component/admin/reports/DataRelated";
import JewelryLogReports from "./component/admin/reports/JewelryLogReports";
import GlobalEvents from "./component/admin/reports/GlobalEvents";
import ClientsReports from "./component/admin/reports/ClientsReports";
import StudBuilderMarkup from "./component/Apps/StudBuilder/StudBuilderMarkup";
import StudBuilderDaimondMarkup from "./component/Apps/StudBuilder/StudBuilderDaimondMarkup";
import PendantBuilderManageVendors from "./component/Apps/PendantBuilder/PendantBuilderManageVendor";
import PendantBuilderSettingMarkup from "./component/Apps/PendantBuilder/PendantBuilderSettingMarkup";
import PendantBuilderDiamondMarkup from "./component/Apps/PendantBuilder/PendantBuilderDiamondMarkup";

import DiamondClicks from "./component/admin/DiamondReport/DiamondClicks";
import ExtendedDiamondChartReports from "./component/admin/DiamondReport/ExtendedDiamondChartReports";
import DiamondClarityReport from "./component/admin/DiamondReport/DiamondClarityReport";
import DiamondColor from "./component/admin/DiamondReport/DiamondColor";
import SyncHistory from "./component/Apps/WebsiteSync/SyncHistory";
import OutBoundMapping from "./component/Apps/WebsiteSync/OutBoundMapping";
import FtpDetails from "./component/Apps/WebsiteSync/FtpDetails";
import DiamondCutReport from "./component/admin/DiamondReport/DiamondCutReport";
import DiamondShapeReport from "./component/admin/DiamondReport/DiamondShapeReport";
import DiamondCertificateReport from "./component/admin/DiamondReport/DiamondCertificateReport";
import DiamondSizeReport from "./component/admin/DiamondReport/DiamondSizeReport";
// import { PendingSync } from "./component/admin/tools/PendingSync";

import { I18Provider, LOCALES } from "./i18n";
// import { FormattedMessage } from "react-intl";
import traslate from "./i18n/translate";
import RequestAppointment from "./component/login/RequestAppointment";
import JewelryMetalTypeReportMain from "./component/reports/jewelry/JewelryMetalTypeReportMain";
import JewelryPriceReportMain from "./component/reports/jewelry/JewelryPriceReportMain";
import JewelryRetailerClickReportMain from "./component/reports/jewelry/JewelryRetailerClickReportMain";
import JewelryVendorClickReportMain from "./component/reports/jewelry/JewelryVendorClickReportMain";
import JewelryLocationActivity from "./component/reports/jewelry/JewelryLocationActivity";
import GobalJewelryChartReports from "./component/reports/GlobalJewelry/GobalJewelryChartReports";

import DiamondChartReportsMain from "./component/reports/Diamonds/DiamondChartReportsMain";
import ExtendedDiamondChartReportsMain from "./component/reports/Diamonds/ExtendedDiamondChartReportsMain";
import DiamondClarityReportMain from "./component/reports/Diamonds/DiamondClarityReportMain";
import DiamondColorMain from "./component/reports/Diamonds/DiamondColorMain";
import DiamondCutGradeReportMain from "./component/reports/Diamonds/DiamondCutGradeReportMain";
import DiamondCertificateReportMain from "./component/reports/Diamonds/DiamondCertificateReportMain";
import DiamondShapeReportMain from "./component/reports/Diamonds/DiamondShapeReportMain";
import DiamondSizeReportMain from "./component/reports/Diamonds/DiamondSizeReportMain";
import LocationActivity from "./component/reports/Diamonds/LocationActivity";
import GlobalExtendedDiamondChartReports from "./component/reports/GlobalDiamonds/GlobalExtendedDiamondChartReports";
import GlobalDiamondClarityReport from "./component/reports/GlobalDiamonds/GlobalDiamondClarityReport";
import GlobalDiamondColorReport from "./component/reports/GlobalDiamonds/GlobalDiamondColorReport";
import GlobalDiamondCutReport from "./component/reports/GlobalDiamonds/GlobalDiamondCutReport";
import GlobalDiamondShapeReport from "./component/reports/GlobalDiamonds/GlobalDiamondShapeReport";
import GlobalDiamondCertificateReport from "./component/reports/GlobalDiamonds/GlobalDiamondCertificateReport";
import GlobalDiamondSizeReport from "./component/reports/GlobalDiamonds/GlobalDiamondSizeReport";
import GlobalJewelryCategoryReport from "./component/reports/GlobalJewelry/GlobalJewelryCategoryReport";
import GlobalJewelryMetalTypeReport from "./component/reports/GlobalJewelry/GlobalJewelryMetalTypeReport";
import WatchCustomizerOptions from "./component/Apps/WatchCustomizer/WatchCustomizerOptions";
import Conversation from "./component/contacts/conversation";
import Customer from "./component/contacts/customer";
import Appointments from "./component/contacts/Appointments";
import ResetPassword from "./component/login/ResetPassword";
import EditViewDataMapping from "./component/Catlog/Diamonds/EditViewDataMapping";
import MakeAppoitments from "./component/Retailer/MakeAppoitments";

const PrivateRoute = () => {
  const loginDetials = useSelector((state) => state.loginReducer);
  var logInSucceeded = false;
  if (loginDetials.loginDetials) {
    if (loginDetials.loginDetials.responseData.logInSucceeded) {
      if (loginDetials.loginDetials.responseData.logInSucceeded) {
        logInSucceeded = true;
      }
    }
    // else if (loginDetials.payload) {
    //   if (loginDetials.payload.responseData.logInSucceeded) {
    //     logInSucceeded = true;
    //   }
    // }
  }
  // else if (loginDetials.payload) {
  //   if (loginDetials.payload.responseData.logInSucceeded) {
  //     logInSucceeded = true;
  //   }
  // }
  else {
    logInSucceeded = false;
  }
  return logInSucceeded ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/" />
  );
};
export const App = () => {
  return (
    <I18Provider locale={LOCALES.ENGLISH}>
      {/* <FormattedMessage id="hello" /> */}
      {/* {traslate("hello")} */}
      <BrowserRouter>
        <React.Fragment>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/requestAppointment"
              element={<RequestAppointment />}
            />
            <Route path="/resetPassword" element={<ResetPassword />} />

            <Route exact path="/" element={<PrivateRoute />}>
              <Route
                exact
                path="/administrationTools"
                element={<Administrationtools />}
              />
              <Route
                exact
                path="/createNewClient"
                element={<CreateNewClient />}
              />
              <Route exact path="/clientStatus" element={<ClientStatus />} />
              <Route exact path="/catalog" element={<Catlog />} />
              <Route exact path="/catalogList" element={<CatlogList />} />
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/allProducts" element={<Allproducts />} />
              <Route exact path="/addProducts" element={<AddProducts />} />
              <Route exact path="/appointment" element={<Appointment />} />
              <Route
                exact
                path="/loginPreferences"
                element={<LoginPreferences />}
              />
              <Route
                exact
                path="/transferZZGemfind"
                element={<TransferZZGemfind />}
              />
              <Route exact path="/tradeShows" element={<TradeShows />} />
              <Route exact path="/mailingLists" element={<MailingLists />} />
              <Route exact path="/campaignData" element={<CampaignData />} />
              <Route exact path="/composeMail" element={<ComposeMail />} />
              <Route
                exact
                path="/apiAccessToken"
                element={<ApiAccessToken />}
              />
              <Route exact path="/addcolor" element={<Addcolor />} />
              <Route
                exact
                path="/productDetails"
                element={<ProductDetails />}
              />
              <Route
                exact
                path="/administrationTools"
                element={<Administrationtools />}
              />
              <Route
                exact
                path="/applicationPermissions"
                element={<ApplicationPermissions />}
              />
              <Route
                exact
                path="/diamondRapNetPermission"
                element={<DiamondRapNetPermission />}
              />
              <Route
                exact
                path="/addNewRetailerToCore"
                element={<AddNewRetailerToCore />}
              />
              <Route exact path="/vendorRatings" element={<VendorRatings />} />
              <Route
                exact
                path="/outGoingDataMap"
                element={<OutGoingDataMap />}
              />

              <Route exact path="/outBoundSync" element={<OutBoundSync />} />

              <Route
                exact
                path="/clientDataMapping"
                element={<ClientDataMapping />}
              />
              <Route exact path="/limit" element={<Limits />} />
              <Route exact path="/legacy" element={<Legacy />} />
              <Route
                exact
                path="/reportPermissions"
                element={<ReportsPermissions />}
              />
              <Route
                exact
                path="/retailerlocator"
                element={<Retailerlocator />}
              />
              <Route
                exact
                path="/blockDesignersForDemo"
                element={<BlockDesignersRetailer />}
              />
              <Route exact path="/dataMaps" element={<DataMaps />} />
              <Route exact path="/metalMarket" element={<MetalMarket />} />
              <Route exact path="/uploadHistory" element={<UploadHistory />} />
              <Route
                exact
                path="/diamonduploadHistory"
                element={<DiamondUploadHistory />}
              />

              <Route
                exact
                path="/uploadProductFile"
                element={<UploadProductFile />}
              />
              <Route
                exact
                path="/addSignleDiamond"
                element={<AddSignleDiamond />}
              />
              <Route
                exact
                path="/uploadDiamondFile"
                element={<UploadDiamondFile />}
              />
              <Route exact path="/ftpCredential" element={<FTPCredential />} />
              <Route
                exact
                path="/applicationPreview"
                element={<ApplicationPreview />}
              />
              <Route exact path="/dealers" element={<Dealers />} />
              <Route
                exact
                path="/minedDiamondsMarkup"
                element={<MinedDiamondsMarkup />}
              />
              <Route
                exact
                path="/fancyColorMarkup"
                element={<FancyColorMarkup />}
              />
              <Route exact path="/labGrowMarkup" element={<LabGrowMarkup />} />
              <Route
                exact
                path="/availableFilterSettings"
                element={<AvailableFilterSettings />}
              />
              <Route
                exact
                path="/initialFilterSettings"
                element={<InitialFilterSettings />}
              />
              <Route exact path="/options" element={<Options />} />
              <Route exact path="/shipping" element={<Shipping />} />
              <Route exact path="/navigation" element={<Navigation />} />
              <Route
                exact
                path="/diamondLinkLegacy"
                element={<DiamondLinkLegacy />}
              />
              <Route
                exact
                path="/diamondLinkApiEndPoint"
                element={<DiamondLinkApiEndPoint />}
              />
              <Route
                exact
                path="/shoppingIntegrations"
                element={<ShoppingIntegrations />}
              />
              <Route
                exact
                path="/diamondMapping"
                element={<DiamondMapping />}
              />
              <Route
                exact
                path="/editViewDataMapping"
                element={<EditViewDataMapping />}
              />
              <Route
                exact
                path="/manageCollection"
                element={<ManageCollection />}
              />
              <Route exact path="/metalMarket" element={<MetalMarket />} />
              <Route exact path="/permissions" element={<Permissions />} />
              <Route exact path="/emailTemplate" element={<EmailTemplate />} />
              <Route
                exact
                path="/magentoMapping"
                element={<MagentoMapping />}
              />
              <Route exact path="/pendingSync" element={<PendingSync />} />
              <Route exact path="/consoleUpload" element={<ConsoleUpload />} />
              <Route exact path="/vendorRating" element={<VendorRatings />} />
              <Route exact path="/productMarkup" element={<ProductMarkup />} />
              <Route exact path="/addCollection" element={<AddCollection />} />
              <Route
                exact
                path="/viewMyMinedDiamond"
                element={<ViewMyMinedDiamond />}
              />
              <Route
                exact
                path="/viewMyColorDiamond"
                element={<ViewMyColorDiamond />}
              />
              <Route
                exact
                path="/viewMyLabDiamond"
                element={<ViewMyLabDiamond />}
              />
              <Route exact path="/jewelrySearch" element={<JewelrySearch />} />
              <Route exact path="/watchesSearch" element={<WatchesSearch />} />
              <Route exact path="/diamondSearch" element={<DiamondSearch />} />
              <Route
                exact
                path="/colorDiamondSearch"
                element={<ColorDiamondSearch />}
              />
              <Route
                exact
                path="/labDiamondSearch"
                element={<LabDiamondSearch />}
              />
              <Route
                exact
                path="/colorDiamondSearch"
                element={<ColorDiamondSearch />}
              />
              <Route
                exact
                path="/labDiamondSearch"
                element={<LabDiamondSearch />}
              />
              <Route exact path="/markups" element={<Markups />} />
              <Route exact path="/settings" element={<Settings />} />
              <Route exact path="/vendors" element={<Vendors />} />
              <Route
                exact
                path="/studBuildermanageVendors"
                element={<StudBuilderManageVendors />}
              />
              <Route
                exact
                path="/studBuilderMarkup"
                element={<StudBuilderMarkup />}
              />
              <Route
                exact
                path="/studBuilderDaimondMarkup"
                element={<StudBuilderDaimondMarkup />}
              />

              <Route
                exact
                path="/pendantBuilderManageVendors"
                element={<PendantBuilderManageVendors />}
              />
              <Route
                exact
                path="/pendantBuilderSettingMarkup"
                element={<PendantBuilderSettingMarkup />}
              />
              <Route
                exact
                path="/pendantBuilderDiamondMarkup"
                element={<PendantBuilderDiamondMarkup />}
              />

              <Route exact path="/syncHistory" element={<SyncHistory />} />
              <Route
                exact
                path="/outBoundMapping"
                element={<OutBoundMapping />}
              />
              <Route exact path="/ftpDetails" element={<FtpDetails />} />

              <Route
                exact
                path="/archiveVendors"
                element={<ArchiveVendors />}
              />
              <Route
                exact
                path="/ringBuilderManageVendors"
                element={<RingBuilderManageVendors />}
              />
              <Route
                exact
                path="/ringBuilderDaimondMarkup"
                element={<RingBuilderDaimondMarkup />}
              />
              <Route
                exact
                path="/ringBuilderApiEndPoint"
                element={<RingBuilderApiEndPoint />}
              />
              <Route
                exact
                path="/ringbuilderNavigation"
                element={<RingbuilderNavigation />}
              />
              <Route
                exact
                path="/ringbuilderLegacy"
                element={<RingbuilderLegacy />}
              />
              <Route
                exact
                path="/ringbuilderShoppingIntegrations"
                element={<RingbuilderShoppingIntegrations />}
              />
              <Route
                exact
                path="/ringBuilderEmailTemplate"
                element={<RingBuilderEmailTemplate />}
              />

              <Route
                exact
                path="/ringBuilderOptions"
                element={<RingBuilderOptions />}
              />
              <Route
                exact
                path="/masterlinkLegacy"
                element={<MasterLinkLegacy />}
              />
              <Route
                exact
                path="/studBuilderApplicationPreview"
                element={<StudBuilderApplicationPreview />}
              />

              <Route
                exact
                path="/studBuilderShoppingIntegrations"
                element={<StudBuilderShoppingIntegrations />}
              />

              <Route
                exact
                path="/studBuilderOptions"
                element={<StudBuilderOptions />}
              />

              <Route
                exact
                path="/studBuilderSettings"
                element={<StudBuilderSettings />}
              />

              <Route
                exact
                path="/studBuilderEmailTemplate"
                element={<StudBuilderEmailTemplate />}
              />

              <Route
                exact
                path="/watchCustomizerOptions"
                element={<WatchCustomizerOptions />}
              />

              <Route
                exact
                path="/watchCustomizer"
                element={<WatchCustomizer />}
              />

              <Route exact path="/conversation" element={<Conversation />} />

              <Route exact path="/customer" element={<Customer />} />

              <Route exact path="/appointments" element={<Appointments />} />

              <Route
                exact
                path="/masterlinkOptions"
                element={<MasterLinkOptions />}
              />

              <Route
                exact
                path="/masterlinkShipping"
                element={<MasterLinkShipping />}
              />
              <Route
                exact
                path="/masterlinkEmailTemplate"
                element={<MasterLinkEmailTemplate />}
              />
              <Route
                exact
                path="/masterlinkShoppingIntegration"
                element={<MasterLinkShoppingIntegration />}
              />
              <Route
                exact
                path="/pendantBuilderApplicationPreview"
                element={<PendantBuilderApplicationPreview />}
              />
              <Route
                exact
                path="/pendantBuilderOptions"
                element={<PendantBuilderOptions />}
              />

              <Route
                exact
                path="/pendantBuilderSettings"
                element={<PendantBuilderSettings />}
              />

              <Route
                exact
                path="/pendantBuilderEmailTemplate"
                element={<PendantBuilderEmailTemplate />}
              />

              <Route
                exact
                path="/pendantBuilderShoppingIntegrations"
                element={<PendantBuilderShoppingIntegrations />}
              />
              <Route
                exact
                path="/pendantBuilderNavigation"
                element={<PendantBuilderNavigation />}
              />
              <Route exact path="/outOfNetwork" element={<OutOfNetwork />} />
              <Route exact path="/new" element={<New />} />
              <Route exact path="/timepieces" element={<Timepieces />} />
              <Route exact path="/myvendors" element={<MyVendors />} />
              <Route exact path="/network" element={<Network />} />
              <Route
                exact
                path="/inviteyourvendor"
                element={<Inviteyourvendor />}
              />
              <Route
                exact
                path="/recentlyUpdated"
                element={<RecentlyUpdated />}
              />
              <Route
                exact
                path="/vendorManagerPopular"
                element={<VendorManagerPopular />}
              />
              <Route
                exact
                path="/vendorManagerArchived"
                element={<VendorManagerArchived />}
              />
              <Route
                exact
                path="/retailerProfile"
                element={<RetailerProfile />}
              />
              <Route
                exact
                path="/jewelryRetailerPermission"
                element={<JewelryRetailerPermission />}
              />

              <Route
                exact
                path="/makeAppoitments"
                element={<MakeAppoitments />}
              />
              <Route
                exact
                path="/pendingJewelryRequest"
                element={<PendingJewelryRequest />}
              />
              <Route
                exact
                path="/diamondRetailerPermission"
                element={<DiamondRetailerPermission />}
              />
              <Route
                exact
                path="/pendingDiamondRequest"
                element={<PendingDiamondRequest />}
              />
              <Route
                exact
                path="/ringBuilderRetailerPermission"
                element={<RingBuilderRetailerPermission />}
              />
              <Route
                exact
                path="/pendingRingBuilderRequest"
                element={<PendingRingBuilderRequest />}
              />
              <Route
                exact
                path="/vendorManagerPendingRequests"
                element={<VendorManagerPendingRequests />}
              />

              <Route
                exact
                path="/JewelryMetalTypeReportMain"
                element={<JewelryMetalTypeReportMain />}
              />

              <Route
                exact
                path="/JewelryPriceReportMain"
                element={<JewelryPriceReportMain />}
              />

              <Route
                exact
                path="/jewelryRetailerClickReportMain"
                element={<JewelryRetailerClickReportMain />}
              />

              <Route
                exact
                path="/JewelryVendorClickReportMain"
                element={<JewelryVendorClickReportMain />}
              />

              <Route
                exact
                path="/jewelryLocationActivity"
                element={<JewelryLocationActivity />}
              />

              <Route
                exact
                path="/gobalJewelryChartReports"
                element={<GobalJewelryChartReports />}
              />

              <Route
                exact
                path="/globalJewelryCategoryReport"
                element={<GlobalJewelryCategoryReport />}
              />

              <Route
                exact
                path="/globalJewelryMetalTypeReport"
                element={<GlobalJewelryMetalTypeReport />}
              />

              {/* Purchase Order Starts */}
              <Route exact path="/purchaseOrder" element={<PurchaseOrder />} />
              <Route
                exact
                path="/purchaseOrderHistory"
                element={<PurchaseOrderHistory />}
              />
              <Route
                exact
                path="/updatePurchaseOrder"
                element={<UpdatePurchaseOrder />}
              />
              {/* Purchase Order Ends */}
              <Route
                exact
                path="/jewelryCategoryReport"
                element={<JewelryCategoryReport />}
              />

              <Route
                exact
                path="/jewelryChartReport"
                element={<JewelryChartReport />}
              />

              {/* Purchase Order Starts */}
              <Route exact path="/purchaseOrder" element={<PurchaseOrder />} />
              <Route
                exact
                path="/purchaseOrderHistory"
                element={<PurchaseOrderHistory />}
              />
              <Route
                exact
                path="/updatePurchaseOrder"
                element={<UpdatePurchaseOrder />}
              />
              {/* Purchase Order Ends */}

              {/* Marketing Starts */}
              <Route
                exact
                path="/uploadMarketingMaterial"
                element={<UploadMarketingMaterial />}
              />
              <Route
                exact
                path="/purchaseOrderHistory"
                element={<PurchaseOrderHistory />}
              />
              {/* Marketing Ends */}

              {/* Header Avatar Dropdown Starts */}
              <Route exact path="/editProfile" element={<EditProfile />} />
              <Route exact path="/manageUser" element={<ManageUser />} />
              <Route exact path="/manageAccount" element={<ManageAccount />} />
              <Route
                exact
                path="/manageLanguage"
                element={<ManageLanguage />}
              />
              <Route
                exact
                path="/manageCurrency"
                element={<ManageCurrency />}
              />
              {/* Header Avatar Dropdown Ends */}

              <Route exact path="/DataMaping" element={<DataMaping />} />
              <Route
                exact
                path="/jewelryChartReportAdmin"
                element={<JewelryChartReportAdmin />}
              />
              <Route
                exact
                path="/jewelryCategoryReportAdmin"
                element={<JewelryCategoryReportAdmin />}
              />
              <Route
                exact
                path="/jewelryMetalTypeReport"
                element={<JewelryMetalTypeReport />}
              />
              <Route
                exact
                path="/jewelryPriceReport"
                element={<JewelryPriceReport />}
              />

              <Route
                exact
                path="/jewelryRetailerClicksReport"
                element={<JewelryRetailerClicksReport />}
              />

              <Route
                exact
                path="/jewelryVendorClicksReport"
                element={<JewelryVendorClicksReport />}
              />

              <Route exact path="/globalEvents" element={<GlobalEvents />} />

              <Route exact path="/sync" element={<Syncs />} />

              <Route exact path="/toolsreports" element={<ToolsReports />} />

              <Route
                exact
                path="/clientsReports"
                element={<ClientsReports />}
              />

              <Route exact path="/dataRelated" element={<DataRelated />} />

              <Route
                exact
                path="/jewelryLogReports"
                element={<JewelryLogReports />}
              />

              <Route exact path="/diamondClicks" element={<DiamondClicks />} />

              <Route
                exact
                path="/extendedDiamondChartReports"
                element={<ExtendedDiamondChartReports />}
              />

              <Route
                exact
                path="/diamondClarityReport"
                element={<DiamondClarityReport />}
              />

              <Route exact path="/diamondColor" element={<DiamondColor />} />

              <Route
                exact
                path="/diamondCutReport"
                element={<DiamondCutReport />}
              />

              <Route
                exact
                path="/diamondShapeReport"
                element={<DiamondShapeReport />}
              />

              <Route
                exact
                path="/diamondCertificateReport"
                element={<DiamondCertificateReport />}
              />

              <Route
                exact
                path="/diamondSizeReport"
                element={<DiamondSizeReport />}
              />

              <Route
                exact
                path="/diamondChartReportsMain"
                element={<DiamondChartReportsMain />}
              />

              <Route
                exact
                path="/extendedDiamondChartReportsMain"
                element={<ExtendedDiamondChartReportsMain />}
              />

              <Route
                exact
                path="/diamondClarityReportMain"
                element={<DiamondClarityReportMain />}
              />

              <Route
                exact
                path="/diamondColorMain"
                element={<DiamondColorMain />}
              />

              <Route
                exact
                path="/diamondCutGradeReportMain"
                element={<DiamondCutGradeReportMain />}
              />

              <Route
                exact
                path="/diamondCertificateReportMain"
                element={<DiamondCertificateReportMain />}
              />

              <Route
                exact
                path="/diamondShapeReportMain"
                element={<DiamondShapeReportMain />}
              />

              <Route
                exact
                path="/diamondSizeReportMain"
                element={<DiamondSizeReportMain />}
              />

              <Route
                exact
                path="/locationActivity"
                element={<LocationActivity />}
              />

              <Route
                exact
                path="/globalExtendedDiamondChartReports"
                element={<GlobalExtendedDiamondChartReports />}
              />

              <Route
                exact
                path="/globalDiamondClarityReport"
                element={<GlobalDiamondClarityReport />}
              />

              <Route
                exact
                path="/globalDiamondColorReport"
                element={<GlobalDiamondColorReport />}
              />

              <Route
                exact
                path="/globalDiamondCutReport"
                element={<GlobalDiamondCutReport />}
              />

              <Route
                exact
                path="/globalDiamondShapeReport"
                element={<GlobalDiamondShapeReport />}
              />

              <Route
                exact
                path="/globalDiamondCertificateReport"
                element={<GlobalDiamondCertificateReport />}
              />

              <Route
                exact
                path="/globalDiamondSizeReport"
                element={<GlobalDiamondSizeReport />}
              />
            </Route>
          </Routes>
          <NotificationContainer />
        </React.Fragment>
      </BrowserRouter>
    </I18Provider>
  );
};

export default App;
