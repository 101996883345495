import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table
} from "antd";
import traslate from "../../../i18n/translate";

const { Option } = Select;

const Syncs = () => {
  

  const columns = [
    {
      title: traslate("DealerID"),
      dataIndex: "DealerID",
      width: 100
    },
    {
      title: traslate("DealerCompany"),
      dataIndex: "DealerCompany"
    },
    {
      title: traslate("Type"),
      dataIndex: "Type",
      width: 100
    },
    {
      title: traslate("Status"),
      dataIndex: "Status",
      width: 100
    },
    {
      title: traslate("Initiated"),
      dataIndex: "Initiated"
    },
    {
      title: traslate("Complete"),
      dataIndex: "Complete"
    }
  ];

  const data = [
    {
      key: "1",
      DealerID: <span>4055</span>,
      DealerCompany: <span>316 Jewelry and Watch Boutique</span>,
      Type: <span>Full</span>,
      Status: <span>Failed</span>,
      Initiated: <span>03-22-2021 07:06 PM</span>,
      Complete: <span>-</span>
    },
    {
      key: "1",
      DealerID: <span>4055</span>,
      DealerCompany: <span>316 Jewelry and Watch Boutique</span>,
      Type: <span>Full</span>,
      Status: <span>Failed</span>,
      Initiated: <span>03-22-2021 07:06 PM</span>,
      Complete: <span>-</span>
    },
    {
      key: "1",
      DealerID: <span>4055</span>,
      DealerCompany: <span>316 Jewelry and Watch Boutique</span>,
      Type: <span>Full</span>,
      Status: <span>Failed</span>,
      Initiated: <span>03-22-2021 07:06 PM</span>,
      Complete: <span>-</span>
    },
  ];

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields magento__mapping">
        <div className="row">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
              {traslate("Syncs")}
              </h4>
              <button className="primary-btn">
                <i className="fa fa-download mr-1"></i>
                {traslate("Download")}
              </button>
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
                <label> {traslate("Client")} </label>
                <Select
                    className="border__grey"
                    placeholder="Client"
                    name="productType"
                    optionFilterProp="children"
                >
                    <Option value="Option 1">Option 1</Option>
                    <Option value="Option 2">Option 2</Option>
                </Select>
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
                <label> {traslate("FromDate")} </label>
                <input type="date" />
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
                <label> {traslate("ToDate")} </label>
                <input type="date" />
            </div>
          </div>

          <div className="col-md-8">
            <div className="input__block">
                <label> {traslate("SyncType")} </label>
            </div>
            <div className="row">
                <div className="radio__block col-md-3">
                    <input type="checkbox" />
                    <label> {traslate("ShowFullSyncs")} </label>
                </div>
                <div className="radio__block col-md-3">
                    <input type="checkbox" />
                    <label> {traslate("ShowUpdateSyncs")} </label>
                </div>
                <div className="radio__block col-md-3">
                    <input type="checkbox" />
                    <label> {traslate("ShowDeleteSyncs")} </label>
                </div>
                <div className="radio__block col-md-3">
                    <input type="checkbox" />
                    <label> {traslate("ShowPriceSyncs")} </label>
                </div>
            </div>
          </div>

          <div className="col-md-8">
            <div className="input__block">
                <label> {traslate("SyncStatus")} </label>
            </div>
            <div className="row">
                <div className="radio__block col-md-3">
                    <input type="checkbox" />
                    <label> {traslate("ShowSuccessSyncs")} </label>
                </div>
                <div className="radio__block col-md-3">
                    <input type="checkbox" />
                    <label> {traslate("ShowFailedSyncs")} </label>
                </div>
                <div className="radio__block col-md-3">
                    <input type="checkbox" />
                    <label> {traslate("ShowPendingSyncs")} </label>
                </div>
            </div>
          </div>

          <div className="col-md-12">
              <div className="button__block my-4">
                <button className="primary-btn mr-2"> {traslate("ApplyFilter")} </button>
              </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <Table className="pendjewelreq__tbl" columns={columns} dataSource={data} scroll={{ x: 600 }} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Syncs;
