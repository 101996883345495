import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import {
    Input,
    Row,
    Col,
    Select,
    Checkbox,
    Button,
    Table,
    Modal,
    DatePicker,
    Switch,
    Tooltip,
    Space
} from "antd";
import {
    EditOutlined,
    CalendarOutlined,
    SearchOutlined
} from "@ant-design/icons";
import { Accordion } from "react-bootstrap";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";

import Highlighter from "react-highlight-words";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";

import userIcon from "../../../assets/images/daimond-bg.png";

const { Option } = Select;

export const StudBuilderOptions = props => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <div className="form__fields markup__section">
            <div className="row ">
                <div className="col-md-12">
                    <div className="heading__block">
                        <h4 class="workarea__heading mt-0">Options</h4>
                    </div>
                </div>
            </div>



            <div className="row ">
                <div className="col-md-12">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div>
                                    <label>Setup</label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            Stud Builder URL
                                <Tooltip placement="right" title={"Enter the URL of this tool on your site to enable social sharing and dynamic sizing."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text" />

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            Internal Use Link?
                                    <Tooltip placement="right" title={"Choose whether to display the “For Internal Use Only” link at the bottom of the Item Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed diamond pair."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <div>
                                    <label htmlFor="h1"> Display</label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            Show "Only Diamond Search"
                                        <Tooltip placement="right" title={"Choose whether to ONLY display the Diamond Search portion of Stud Builder which pairs together diamonds of similar attributes."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            Show Dealer SKU In Diamond Search
                                                <Tooltip placement="right" title={"Choose whether to display the Dealer/Vendor Stock # instead of the default unique GemFind SKU# (Ex. SKU# 108545370)."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            Schedule Viewing
                                        <Tooltip placement="right" title={"Choose whether to display the link which allows the customer the ability to schedule an appointment."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            Show Addresses
                                        <Tooltip placement="right" title={"Allow address to show on detailpage."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                <div>
                                    <label>Price</label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            Show "Call For Price" instead of the Price
                                                <Tooltip placement="right" title={"Choose whether to display “Call for Price” instead of the retail price or not."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Apply GST Tax
                                    <Tooltip placement="right" title={"Toggle the ability to apply international tax."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                GST Tax Value
                                                <Tooltip placement="right" title={"Set the value when applying the GST Tax option."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text" value="20" />

                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>



                        <Accordion.Item eventKey="5">
                            <Accordion.Header>
                                <div>
                                    <label>Social</label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Pinterest Share
                                <Tooltip placement="right" title={"Choose whether to display the Pinterest “Pin it” button or not."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Twitter Share
                                    <Tooltip placement="right" title={"Choose whether to display the Twitter “Tweet” button or not."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Facebook Share
                                    <Tooltip placement="right" title={"Choose whether to display the Facebook “Share” button or not."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Facebook Like
                                    <Tooltip placement="right" title={"Choose whether to display the Facebook “Like” button or not"}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Google Plus
                                    <Tooltip placement="right" title={"Choose whether to display the Google “G+1” button or not."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div> */}

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6">
                            <Accordion.Header>
                                <div>
                                    <label>E-Commerce</label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Shopping Cart Url
                                                <Tooltip placement="right" title={"Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page. Additional diamond detail parameters are passed in the URL when this value is present. Leave blank for default value."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            Show Add To Cart Button
                                            <Tooltip placement="right" title={"Choose whether to display the “Add to Cart” button or not."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            Show "View Cart" Button
                                                <Tooltip placement="right" title={"Choose whether to display the PayPal Shopping Cart's 'View Cart' Button or not."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </div>
            </div>

        </div>
    );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StudBuilderOptions);
