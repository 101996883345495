import React ,{useState} from "react";
import { Input, Row, Col, Select, Checkbox, Switch, Table, Menu, Dropdown, Button, Space } from "antd";

const MasterLinkShipping = () => {

    const columns = [
        {
            title: 'Configurable Attribute',
            dataIndex: 'config',
        },
        {
            title: 'Shipping Charge',
            dataIndex: 'shipping_charge',
        },
        {
            title: 'Active',
            dataIndex: 'active',
        }
    ];

    const data = [
        {
            key: '1',
            config: <span>Super Saver Shipping (5-9 business days)</span>,
            shipping_charge: <div className="inpt__div"><span>$</span><input type="number" placeholder="20" /></div>,
            active: <input type="checkbox" id="1" />
        },
        {
            key: '2',
            config: <span>Standard Shipping (3-5 business days)</span>,
            shipping_charge: <div className="inpt__div"><span>$</span><input type="number" placeholder="20" /></div>,
            active: <input type="checkbox" id="1" />
        },
        {
            key: '3',
            config: <span>Two-Day Shipping</span>,
            shipping_charge: <div className="inpt__div"><span>$</span><input type="number" placeholder="20" /></div>,
            active: <input type="checkbox" id="1" />
        },
        {
            key: '4',
            config: <span>One-Day Shipping</span>,
            shipping_charge: <div className="inpt__div"><span>$</span><input type="number" placeholder="20" /></div>,
            active: <input type="checkbox" id="1" />
        },
        {
            key: '4',
            config: <span>Overnight Shipping with Saturday Delivery</span>,
            shipping_charge: <div className="inpt__div"><span>$</span><input type="number" placeholder="20" /></div>,
            active: <input type="checkbox" id="1" />
        },
        {
            key: '4',
            config: <span>Overnight Shipping (No Saturday Delivery)</span>,
            shipping_charge: <div className="inpt__div"><span>$</span><input type="number" placeholder="20" /></div>,
            active: <input type="checkbox" id="1" />
        },
        {
            key: '4',
            config: <span>International Shipping (Outside the USA) with a Shipping Charge of TBD</span>,
            shipping_charge: <div className="inpt__div"><span>$</span><input type="number" placeholder="0" /></div>,
            active: <input type="checkbox" id="1" />
        },
    ];

    return (
        <React.Fragment>
            <div className="shipping_section form__fields">
                <div className="row">
                    <div className="col-lg-12">
                        <h4 className="workarea__heading mt-0 mb-0">Shipping</h4>
                    </div>

                    <div className="col-lg-12 mt-4">
                        <div className="shipping_tbldiv">
                            <Table columns={columns} dataSource={data} scroll={{ y: 300 }} />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <button class="primary-btn">Update Settings</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MasterLinkShipping
