import React, { useState, useEffect, Fragment, useDebugValue } from "react";
import axios from "axios";

import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Switch,
  Table,
  Menu,
  Dropdown,
  Button,
  Space,
  Modal,
  Radio,
  Spin,
} from "antd";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import moment from "moment";
import { DownloadFile } from "../../../helper/commanFunction";
import { useSelector, useDispatch } from "react-redux";
import traslate from "../../../i18n/translate";

const { Option } = Select;

const Limits = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [page, setPage] = useState("limitType");
  const [radioValue, setRadioValue] = useState("");
  const [getLimitData, setGetLimitData] = useState([]);
  const [retailerList, setRetailerList] = useState([]);
  const [secondaryList, setSecondaryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [datacount, setDatacount] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [selectedRows, setselectedRows] = useState([]);
  const [fileName, setFileName] = useState("Limits.xlsx");
  const [titleValue, setTitleValue] = useState("");
  const [isModalDelVisible, setIsModalDelVisible] = useState(false);
  const [clientData, setClientData] = useState({
    value: "",
    retailer: "",
    diamond: "",
    vendor: "",
  });
  const [validations, setValidations] = useState({
    valueVal: "",
    diamondVal: "",
    retailersVal: "",
    vendorVal: "",
  });
  const [checkbox, setCheckBox] = useState({
    LimitRetailerToDiamondDealer: true,
    LimitDiamondDealerToRetailer: true,
    LimitVendorToRetailer: true,
    LimitRetailerToVendor: true,
  });
  const [dealerId, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);

  useEffect(() => {
    debugger;
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetDealerLimits(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  useEffect(() => {
    if (clientData.value === "Limit Retailer To Diamond Dealer") {
      setRadioValue("diamond");
    }
    if (clientData.value === "Limit Diamond Dealer To Retailer") {
      setRadioValue("diamond");
    }
    if (clientData.value === "Limit Vendor To Retailer") {
      setRadioValue("vendor");
    }
    if (clientData.value === "Limit Retailer To Vendor") {
      setRadioValue("vendor");
    }
  }, [clientData.value]);

  const showDelModal = () => {
    if (!selectedRows.length) {
      NotificationManager.error("Please first select record to delete.");
    } else {
      setIsModalDelVisible(true);
    }
  };

  const handleDelCancel = () => {
    setIsModalDelVisible(false);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      var array = [];
      for (let i = 0; i < selectedRows.length; i++) {
        let selectrowID = selectedRows[i].limitLogID;
        let selectedrowdealerId = selectedRows[i].vendor_DealerID;
        let selectedrowRetailerId = selectedRows[i].retailerID;
        let selectedrowLimitSetFor = selectedRows[i].limitSetFor;
        const tempObj = {
          limitLogId: selectrowID,
          dealerId: selectedrowdealerId,
          retailerId: selectedrowRetailerId.toString(),
          limitType: selectedrowLimitSetFor,
        };
        array.push(tempObj);
      }
      setselectedRows(array);
    },
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setPage("limitType");
    setTitleValue("");
    setClientData({});
    setValidations({});
  };

  const handleValidation = () => {
    const { value, retailer, diamond, vendor } = clientData;
    const validations = {
      valueVal: "",
      diamondVal: "",
      retailersVal: "",
      vendorVal: "",
    };
    let isValid = true;

    if (page === "limitType") {
      if (!value) {
        isValid = false;
        validations.valueVal = "Limit Type is compulsory";
      }
    }
    if (page === "limitDropdown") {
      if (!retailer) {
        isValid = false;
        validations.retailersVal = "Kindly select the retailer ";
      }

      if (
        clientData.value === "Limit Diamond Dealer To Retailer" ||
        clientData.value === "Limit Retailer To Diamond Dealer"
      ) {
        if (!diamond) {
          isValid = false;
          validations.diamondVal = "Kindly select the Diamond Dealer ";
        }
      }

      if (
        clientData.value === "Limit Vendor To Retailer" ||
        clientData.value === "Limit Retailer To Vendor"
      ) {
        if (!vendor) {
          isValid = false;
          validations.vendorVal = "Kindly select the Vendor ";
        }
      }
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  function onSearch(val) {
    console.log("search:", val);
  }
  const handleNext = (e) => {
    e.preventDefault();
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    setPage("limitDropdown");
    setTitleValue(clientData.value);
    handleGetDealerForCreate();
  };

  const handleGetDealerForCreate = () => {
    try {
      let inputData = {
        listFor: clientData.value,
      };
      AdminToolsDataService.GetDealerForCreate(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData[0];
          var secondaryData = response.data.responseData[1];

          if (message == "Success") {
            setRetailerList(responseData);
            setSecondaryList(secondaryData);
          } else {
            setRetailerList([]);
            setSecondaryList([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setClientData({
        ...clientData,
        [name]: e,
      });
    } else {
      setClientData({
        ...clientData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleCheckBoxChange = (e, name) => {
    setCheckBox({
      ...checkbox,
      [name]: e.target.checked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    try {
      let inputData = {
        dealerID: dealerId.toString(),
        limitSetBy: (radioValue === "diamond"
          ? clientData.diamond
          : clientData.retailer
        ).toString(),
        limitSetFor: clientData.value,

        retailerId: clientData.retailer.toString(),
      };
      setLoading(true);
      console.log(inputData);
      AdminToolsDataService.CreateDealerLimit(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          if (message == "Success") {
            NotificationManager.success(" Data Added Successfully.");
            handleGetDealerLimits(dealerId);
          } else {
            NotificationManager.success(" Data Not Added");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleCancel();

    console.log(clientData);
  };

  const handleApplyFilters = () => {
    handleGetDealerLimits(dealerId);
  };

  const handleGetDealerLimits = (Id) => {
    const Checkboxs = Object.keys(checkbox)
      .filter((key) => checkbox[key])
      .join(",");
    try {
      let inputData = {
        dealerId: Number(Id),
        filterBy: Checkboxs,
      };
      setLoading(true);
      console.log(inputData);
      AdminToolsDataService.GetDealerLimits(inputData)
        .then((response) => {
          debugger;
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData[0];
          var TotalRowCount = response.data.responseData[1].TotalRecords;
          const longeur = response.data.responseData[0].length;
          if (message == "Success") {
            setGetLimitData(responseData);
            setDatacount(longeur);
            setTotalCount(TotalRowCount);
          } else {
            setGetLimitData([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDeleteRows = () => {
    try {
      let inputData = selectedRows;
      setLoading(true);
      console.log(inputData);
      AdminToolsDataService.DeleteDealerLimit(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          if (message == "Success") {
            NotificationManager.success("Data deleted successfully.");
            handleDelCancel();
            handleGetDealerLimits(dealerId);
            setselectedRows([]);
          } else {
            NotificationManager.success("Data not Deleted");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDownloadMailList = () => {
    DownloadFile(getLimitData, fileName);
  };

  return (
    <React.Fragment>
      <div className="limit_section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {traslate("Limits")}
              </h4>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="row">
                <div className="col-lg-6">
                  <div className="chckbox__div">
                    <input
                      checked={checkbox.LimitRetailerToDiamondDealer}
                      onChange={(e) =>
                        handleCheckBoxChange(e, "LimitRetailerToDiamondDealer")
                      }
                      type="checkbox"
                      name="LimitRetailerToDiamondDealer"
                      id="limit1"
                    />
                    <label htmlFor="limit1">
                      {traslate("LimitRetailerToDiamondDealer")}
                    </label>
                  </div>
                  <div className="chckbox__div">
                    <input
                      checked={checkbox.LimitDiamondDealerToRetailer}
                      onChange={(e) =>
                        handleCheckBoxChange(e, "LimitDiamondDealerToRetailer")
                      }
                      type="checkbox"
                      name="LimitDiamondDealerToRetailer"
                      id="limit2"
                    />
                    <label htmlFor="limit2">
                      {traslate("LimitDiamondDealerToRetailer")}
                    </label>
                  </div>
                  <div className="chckbox__div">
                    <input
                      checked={checkbox.LimitRetailerToVendor}
                      onChange={(e) =>
                        handleCheckBoxChange(e, "LimitRetailerToVendor")
                      }
                      type="checkbox"
                      name="LimitRetailerToVendor"
                      id="limit3"
                    />
                    <label htmlFor="limit3">
                      {traslate("LimitRetailerToVendor")}
                    </label>
                  </div>
                  <div className="chckbox__div">
                    <input
                      checked={checkbox.LimitVendorToRetailer}
                      onChange={(e) =>
                        handleCheckBoxChange(e, "LimitVendorToRetailer")
                      }
                      type="checkbox"
                      name="LimitVendorToRetailer"
                      id="limit4"
                    />
                    <label htmlFor="limit4">
                      {traslate("LimitVendorToRetailer")}
                    </label>
                  </div>
                </div>
                <div
                  className="col-lg-6 aplyfilt__btndiv"
                  onClick={() => handleApplyFilters()}
                >
                  <button className="primary-btn">
                    {" "}
                    <i className="fa fa-search"></i> {traslate("ApplyFilter")} 
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-5">
              <div className="options__div">
                <div className="row">
                  <div className="col-lg-4 rules__div">
                    <span className="rules__txt">
                      {" "}
                      <span className="rules__txtno">
                        {datacount == "-1" ? 0 : datacount} / {totalCount}
                      </span>{" "}
                      {traslate("Rules")}
                    </span>
                  </div>
                  <div className="col-lg-8 crud__div">
                    <span className="select__txt">
                      {" "}
                      <i className="fa fa-list-ul"></i> {selectedRows.length}{" "}
                      {traslate("Selected")}
                    </span>
                    <button className="primary-btn" onClick={showDelModal}>
                      {" "}
                      <i className="fa fa-trash-o"></i> {traslate("Delete")} 
                    </button>
                    <button className="primary-btn" onClick={showModal}>
                      {" "}
                      <i className="fa fa-plus-circle"></i> {traslate("Create")} 
                    </button>
                    <button
                      className="primary-btn"
                      onClick={handleDownloadMailList}
                    >
                      {" "}
                      <i className="fa fa-download"></i> {traslate("Download")}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="limit_tbldiv">
                <Spin spinning={loading} size="large">
                  <Table
                    columns={[
                      {
                        dataIndex: "limitSetFor",
                        title: traslate("RuleType") ,
                      },
                      {
                        dataIndex: "vendor_DealerName",
                        title: traslate("Vendor/Dealer") ,
                      },
                      {
                        dataIndex: "retailerName",
                        title: traslate("Retailer") ,
                      },

                      {
                        dataIndex: "createdDate",
                        title: traslate("CreatedDate") ,
                        render: (text, row) => {
                          return moment(row.createdDate).format(
                            "YYYY-MM-DD HH:mm:ss"
                          );
                        },
                      },
                      {
                        dataIndex: "adminName",
                        title: traslate("Admin") ,
                      },
                    ]}
                    scroll={{ x: 900 }}
                    dataSource={getLimitData}
                    rowSelection={rowSelection}
                    rowKey={(record) => record.limitLogID}
                  />
                </Spin>
              </div>
            </div>

            <div className="col-lg-12 ">
              <Modal
                title={titleValue ? titleValue : "Limits"}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={[]}
                className="modal__limit"
              >
                <div className="form__fields mt-3 border-0 p-0">
                  <div className="row">
                    <div className="col-md-12">
                      {page === "limitType" && (
                        <div className="">
                          <Radio.Group
                            onChange={(e) => {
                              handleOnChange(e);
                            }}
                            value={clientData.value}
                            name="value"
                          >
                            <Radio value={"Limit Retailer To Diamond Dealer"}>
                            {traslate("LimitRetailerToDiamondDealer")}
                            </Radio>
                            <Radio value={"Limit Diamond Dealer To Retailer"}>
                            {traslate("LimitDiamondDealerToRetailer")}
                            </Radio>
                            <Radio value={"Limit Retailer To Vendor"}>
                            {traslate("LimitRetailerToVendor")}
                            </Radio>
                            <Radio value={"Limit Vendor To Retailer"}>
                            {traslate("LimitVendorToRetailer")}
                            </Radio>
                          </Radio.Group>
                          {validations.valueVal && (
                            <p className="error-color-red">
                              {validations.valueVal}
                            </p>
                          )}
                          <button
                            onClick={handleNext}
                            className="primary-btn"
                            // onClick={() => {
                            //   setPage("limitDropdown");
                            // }}
                          >
                            {traslate("Next")}
                          </button>
                        </div>
                      )}
                      {page === "limitDropdown" && (
                        <Fragment>
                          <div className="col-md-12">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("Retailer")}
                                <span className="mandatory">*</span>{" "}
                              </label>
                              <Select
                                value={
                                  clientData.retailer
                                    ? clientData.retailer
                                    : null
                                }
                                name="retailer"
                                showSearch
                                placeholder="Select Retailer"
                                optionFilterProp="children"
                                onChange={(e) =>
                                  handleOnChange(e, "select", "retailer")
                                }
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                className={
                                  validations.retailersVal
                                    ? "border__red"
                                    : "border__grey"
                                }
                              >
                                {retailerList.map((item) => {
                                  return (
                                    <Option value={item.dealerID}>
                                      {item.dealerCompany +
                                        " (" +
                                        item.dealerID +
                                        " )"}
                                    </Option>
                                  );
                                })}
                              </Select>
                              <div>
                                {validations.retailersVal && (
                                  <p className="error-color-red">
                                    {validations.retailersVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          {radioValue === "diamond" && (
                            <div className="col-md-12">
                              <div className="input__block">
                                <label>
                                  {" "}
                                  {traslate("DiamondDealer")}
                                  <span className="mandatory">*</span>{" "}
                                </label>
                                <Select
                                  value={
                                    clientData.diamond
                                      ? clientData.diamond
                                      : null
                                  }
                                  name="diamond"
                                  showSearch
                                  placeholder="Select Diamond Dealer"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "diamond")
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  className={
                                    validations.diamondVal
                                      ? "border__red"
                                      : "border__grey"
                                  }
                                >
                                  {secondaryList.map((item) => {
                                    return (
                                      <Option value={item.dealerID}>
                                        {item.dealerCompany +
                                          " (" +
                                          item.dealerID +
                                          " )"}
                                      </Option>
                                    );
                                  })}
                                </Select>
                                {validations.diamondVal && (
                                  <p className="error-color-red">
                                    {validations.diamondVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                          {radioValue === "vendor" && (
                            <div className="col-md-12">
                              <div className="input__block">
                                <label>
                                  {" "}
                                  {traslate("Vendor")}
                                  <span className="mandatory">*</span>{" "}
                                </label>
                                <Select
                                  value={
                                    clientData.vendor ? clientData.vendor : null
                                  }
                                  name="vendor"
                                  showSearch
                                  placeholder="Select Vendor "
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "vendor")
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  className={
                                    validations.vendorVal
                                      ? "border__red"
                                      : "border__grey"
                                  }
                                >
                                  {secondaryList.map((item) => {
                                    return (
                                      <Option value={item.dealerID}>
                                        {item.dealerCompany +
                                          " (" +
                                          item.dealerID +
                                          " )"}
                                      </Option>
                                    );
                                  })}
                                </Select>
                                {validations.vendorVal && (
                                  <p className="error-color-red">
                                    {validations.vendorVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                          <button
                            className="primary-btn"
                            onClick={handleSubmit}
                          >
                            {traslate("Save")}
                          </button>
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                title={traslate("DeleteLimitRule")}
                visible={isModalDelVisible}
                onCancel={handleDelCancel}
                footer={[
                  <Button key="back" onClick={handleDelCancel}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={handleDeleteRows}
                  >
                    {traslate("Delete")}
                  </Button>,
                ]}
              >
                <p>{traslate("AreyousuretodeletethisRule")} </p>
                <p>{traslate("THISCANNOTBEUNDONE")}</p>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Limits;
