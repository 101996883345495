import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import {
    Input,
    Row,
    Col,
    Select,
    Checkbox,
    Button,
    Table,
    Modal,
    DatePicker,
    Switch,
    Tooltip,
    Space
} from "antd";
import {
    EditOutlined,
    CalendarOutlined,
    SearchOutlined
} from "@ant-design/icons";
import { Accordion } from "react-bootstrap";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";

import Highlighter from "react-highlight-words";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";

import userIcon from "../../../assets/images/daimond-bg.png";
import traslate from "../../../i18n/translate";

const { Option } = Select;

export const RingBuilderOptions = props => {
    const [isModalVisible, setIsModalVisible] = useState(false);





    return (
        <div className="form__fields markup__section">
            <div className="row ">
                <div className="col-md-12">
                    <div className="heading__block">
                        <h4 class="workarea__heading mt-0"> {traslate("Options")} </h4>
                    </div>
                </div>
            </div>



            <div className="row ">
                <div className="col-md-12">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div>
                                    <label> {traslate("Setup")} </label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("RingBuilderURL")}
                                <Tooltip placement="right" title={traslate("EntertheURLofthistoolonyoursitetoenablesocialsharinganddynamicsizing.")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text" />

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("InternalUseLink")}
                                    <Tooltip placement="right" title={traslate("ChooseWeathertodisplaythe“ForInternalUseOnly”linkatthebottomoftheSettingDetailspage,whichwillallowyoutolookupthevendor’sstock#andcontactinfofortheviewedsetting.")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <div>
                                    <label htmlFor="h1"> {traslate("Display")} </label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("RingSizeType")}
                                    <Tooltip placement="right" title={traslate("ChoosewhichRingSizeformatyouwouldliketodisplay.(American-1,1.5,2,etc.orBritish-A,A.5,B,B.5etc.).")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Select
                                                className="border__grey"
                                                placeholder="American"
                                                name="productType"
                                                optionFilterProp="children"
                                            >
                                                <Option value="american"> {traslate("American")} </Option>
                                                <Option value="british"> {traslate("British")} </Option>
                                                <Option value="roman"> {traslate("Roman")} </Option>
                                                <Option value="europe"> {traslate("Europe")} </Option>
                                            </Select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("DiamondInitiallySortResultOn")}
                                        <Tooltip placement="right" title={traslate("Selectadiamondattributethatyouwouldliketoinitiallysortthediamondsby.")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Select
                                                className="border__grey mb-4"
                                                placeholder="Price"
                                                name="productType"
                                                optionFilterProp="children"
                                            >
                                                <Option value="price"> {traslate("Price")} </Option>
                                                <Option value="carat"> {traslate("Carat")} </Option>
                                                
                                            </Select>

                                            <Select
                                                className="border__grey"
                                                placeholder="Ascending"
                                                name=""
                                                optionFilterProp="children"
                                            >
                                                <Option value="ascending"> {traslate("Ascending")} </Option>
                                                <Option value="descending"> {traslate("Descending")} </Option>
                                            </Select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("DefaultMetalTypeonSettingpage")}
                                    <Tooltip placement="right" title={traslate("ChoosewhichRingSizeformatyouwouldliketodisplay.(American-1,1.5,2,etc.orBritish-A,A.5,B,B.5etc.).")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Select
                                                className="border__grey"
                                                placeholder="14k White Gold"
                                                name="productType"
                                                optionFilterProp="children"
                                            >
                                                <Option value="white-gold"> {traslate("14KWhiteGold")} </Option>
                                                <Option value="yellow-gold"> {traslate("14KYellowGold")} </Option>
                                            </Select>
                                        </div></div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("ShowAdvancedSearchOptionsByDefault")}
                                        <Tooltip placement="right" title={traslate("ChooseWhethertodisplaytheAdvancedoptionalfilteringbydefault(Polish,Symmetry,Flourescence,Certs…etc.")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("Rings-ShowScheduleAViewing")}
                                        <Tooltip placement="right" title={traslate("ChooseWhethertodisplaythelinkwhichallowsthecustomertheabilitytoscheduleanappointment.")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("ShowAddresses")}
                                        <Tooltip placement="right" title={traslate("ChooseWhethertodisplayyourcompanyaddressandcontactinformationwithintheRingBuilder.")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("ShowCenterStoneSize")}
                                        <Tooltip placement="right" title={traslate("Warning:DisablingthisoptionwillremovetheCaratRangerestrictionforRingSettings,andmaycauseanincreaseincostpricesfromwhatisstatedfortheselectedRingSetting.FormoreinformationfollowupwithOvernightMountings.")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                <div>
                                    <label> {traslate("Price")} </label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("ShowPrice")}
                                    <Tooltip placement="right" title={traslate("ChooseWhethertoshowpricesforringsettingsornot.IfyouchooseNO,thenitwillinsteaddisplay“CallforPrice”.")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("ApplyGSTTax")}
                                    <Tooltip placement="right" title={traslate("Toggletheabilitytoapplyinternationaltax.")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("GSTTaxValue")}
                                    <Tooltip placement="right" title={traslate("SetthevaluewhenapplyingtheGSTTaxoption.")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text" value="20" />

                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>



                        <Accordion.Item eventKey="5">
                            <Accordion.Header>
                                <div>
                                    <label> {traslate("Social")} </label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("ShowPinterestShare")}
                                <Tooltip placement="right" title={traslate("ChoosewhethertodisplaythePinterest“Pinit”buttonornot.")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("ShowTwitterShare")}
                                    <Tooltip placement="right" title={traslate("ChoosewhethertodisplaytheTwitter“Tweet”buttonornot.")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("ShowFacebookShare")}
                                    <Tooltip placement="right" title={traslate("ChoosewhethertodisplaytheFacebook“Share”buttonornot.")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("ShowFacebookLike")}
                                    <Tooltip placement="right" title={traslate("ChoosewhethertodisplaytheFacebook“Like”buttonornot")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("ShowGooglePlus")}
                                    <Tooltip placement="right" title={traslate("ChoosewhethertodisplaytheGoogle“G+1”buttonornot.")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6">
                            <Accordion.Header>
                                <div>
                                    <label> {traslate("E-Commerce")} </label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("ShowAddToCartButton")}
                                <Tooltip placement="right" title={""}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("AlternateShoppingCart")}
                                    <Tooltip placement="right" title={traslate("Changethisvaluetoaltertheactualhyperlinktotheshoppingcart.Thiscouldbechangedtolinktoyourownshoppingcartpage.AdditionaldiamondandsettingdetailparametersarepassedintheURLwhenthisvalueispresent.Leaveblankfordefaultvalue.")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("ShowBuySettingOnly")}
                                    <Tooltip placement="right" title={traslate("ChooseWhethertodisplaythe“BuySettingOnly”buttonornot.ThisallowsthecustomertousetheRingBuildertoonlypurchasetheringsettingasopposedtothecompletedringwithdiamond.")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("ShowBuyDiamondOnly")}
                                    <Tooltip placement="right" title={traslate("ChooseWhethertodisplaythe“BuyDiamondOnly”buttonornot.ThisallowsthecustomertousetheRingBuildertoonlypurchasethediamondasopposedtothecompletedring.")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            {traslate("Show")} "{traslate("ViewCart")}" {traslate("Button")}
                                    <Tooltip placement="right" title={traslate("ChooseWhethertodisplaythePayPalshoppingcart'sviewbuttonorNot")}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </div>
            </div>

        </div>
    );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RingBuilderOptions);
