import {
  LOGIN_SUBMIT,
  SET_LOGIN_TOKEN,
  SET_IMPERSONATE_DATA,
} from "../../actions/login/types";
const initialState = {
  loginDetials: null,
  impresonateData: null,
};
function loginReducer(loginState = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUBMIT:
      return { ...loginState, loginDetials: payload };
    case SET_LOGIN_TOKEN:
      return { ...loginState, loginDetials: payload, impresonateData: null };
    case SET_IMPERSONATE_DATA:
      return { ...loginState, impresonateData: payload };
    default:
      return loginState;
  }
}
export default loginReducer;
