import React, { useState } from "react";
import { Input, Row, Col, Select, Checkbox, Switch, Table, Menu, Dropdown, Button, Space } from "antd";
import Create_icon from "../../assets/images/create_icon.png";
const ManageUser = () => {

    const columns = [
        {
            title: 'User Name ',
            dataIndex: 'userName',
        },
        {
            title: 'Role',
            dataIndex: 'role',
        },
        {
            title: 'Last Udpated Date ',
            dataIndex: 'lastUpdatedDate',
        },
        {
            title: 'Status',
            dataIndex: 'status',
        },
        {
            title: 'Action',
            dataIndex: 'action',
        }
    ];

    const data = [
        {
            key: '1',
            userName: <span>Giacomo Guilizzoni</span>,
            role: <span>Super</span>,
            lastUpdatedDate: <span>03/15/2018</span>,
            status: <input type="checkbox" id="1" />,
            action: <button class="primary-btn">Update</button>
        },
        {
            key: '2',
            userName: <span>Giacomo Guilizzoni</span>,
            role: <span>Super</span>,
            lastUpdatedDate: <span>03/15/2018</span>,
            status: <input type="checkbox" id="1" />,
            action: <button class="primary-btn">Update</button>
        },
        {
            key: '3',
            userName: <span>Giacomo Guilizzoni</span>,
            role: <span>Super</span>,
            lastUpdatedDate: <span>03/15/2018</span>,
            status: <input type="checkbox" id="1" />,
            action: <button class="primary-btn">Update</button>
        },
        {
            key: '4',
            userName: <span>Giacomo Guilizzoni</span>,
            role: <span>Super</span>,
            lastUpdatedDate: <span>03/15/2018</span>,
            status: <input type="checkbox" id="1" />,
            action: <button class="primary-btn">Update</button>
        },
        {
            key: '5',
            userName: <span>Giacomo Guilizzoni</span>,
            role: <span>Super</span>,
            lastUpdatedDate: <span>03/15/2018</span>,
            status: <input type="checkbox" id="1" />,
            action: <button class="primary-btn">Update</button>
        },
        {
            key: '6',
            userName: <span>Giacomo Guilizzoni</span>,
            role: <span>Super</span>,
            lastUpdatedDate: <span>03/15/2018</span>,
            status: <input type="checkbox" id="1" />,
            action: <button class="primary-btn">Update</button>
        },
        {
            key: '7',
            userName: <span>Giacomo Guilizzoni</span>,
            role: <span>Super</span>,
            lastUpdatedDate: <span>03/15/2018</span>,
            status: <input type="checkbox" id="1" />,
            action: <button class="primary-btn">Update</button>
        },
        {
            key: '8',
            userName: <span>Giacomo Guilizzoni</span>,
            role: <span>Super</span>,
            lastUpdatedDate: <span>03/15/2018</span>,
            status: <input type="checkbox" id="1" />,
            action: <button class="primary-btn">Update</button>
        }
    ];

    return (
        <React.Fragment>
            <div className="form__fields">
                <div className="row magento__mapping">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading__block">
                                <h4 class="workarea__heading mt-0 mb-0">User Role Detail</h4>
                                <button className="primary-btn">
                                    {" "}
                                    <img src={Create_icon} alt="" /> Add User
                            </button>
                            </div>
                        </div>

                        <div className="col-lg-12 mt-4">
                            <div className="shipping_tbldiv">
                                <Table columns={columns} dataSource={data} scroll={{ y: 300 }} pagination={false} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default ManageUser
