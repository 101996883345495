import React, { useState, useEffect } from "react";
import { Input, Row, Col, Select, Checkbox, Switch, Table, Menu, Dropdown, Button, Tooltip } from "antd";
import { Tabs, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import CatalogDiamondDataService from "../../../services/catalog-diamond.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../../i18n/translate";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";

const AddSignleDiamond = () => {
    // Diamond Data
    const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
    

    const initialState = {
        inventory: "",
        matchedPairStock: "",
        stones: "",
        separable: true,
        size: "",
        cost: "",
        shape: "",
        color: "",
        fancyCodes: "",
        fancyNames: "",
        other: "",
        clarity: "",
        cutGrade: "",
        certificate: "",
        certificateNum: "",
        depth: "",
        table: "",
        measurements: "",
        polish: "",
        symmetry: "",
        fluorescence: "",
        girdle: "",
        culet: "",
        culetCondition: "",
        crownAngle: "",
        pavillionAngle: "",
        comments: "",
        storeLocation: "",
        employeeName: "",
        employeeId: "",
        outOnMemo: true,
        seenByOtherDealers: true,
        addVideo: false,
        fileN: [],
        fileName: "",
        Error: {},
    };
    const [diamondData, setDiamondData] = useState(initialState);

    const [validations, setValidations] = useState({
        inventoryVal: "",
        matchedPairStockVal: "",
        stonesVal: "",
        separableVal: "",
        sizeVal: "",
        costVal: "",
        shapeVal: "",
        colorVal: "",
        fancyCodesVal: "",
        fancyNamesVal: "",
        otherVal: "",
        clarityVal: "",
        cutGradeVal: "",
        certificateVal: "",
        certificateNumVal: "",
        depthVal: "",
        tableVal: "",
        measurementsVal: "",
        polishVal: "",
        symmetryVal: "",
        fluorescenceVal: "",
        girdleVal: "",
        culetVal: "",
        culetConditionVal: "",
        crownAngleVal: "",
        pavillionAngleVal: "",
        commentsVal: "",
        storeLocationVal: "",
        employeeNameVal: "",
        employeeIdVal: "",
        outOnMemoVal: "",
        seenByOtherDealersVal: "",
        addVideoVal: "",
        fileNVal: ""
    });
    const [buttonLoading, setButtonLoading] = useState(false);
    const [DealerId, setDealerId] = useState(null);
    
    useEffect(() => {
        // debugger;
        if (loginDetials) {
            if (loginDetials.responseData) {
                setDealerId(loginDetials.responseData.dealerId);
            }
        }
    }, []);




    const onSearch = (val) => {
        console.log("search:", val);
    };
    //Onchange Event
    const handleOnChange = (e, isSelect, name) => {
        debugger;
        var validNmae = name + "Val";
        if (isSelect === "select") {
            setDiamondData({
                ...diamondData,
                [name]: e,
            });
            validations[validNmae] = "";
        } else {
            setDiamondData({
                ...diamondData,
                [e.target.name]: e.target.value,
            });
            validations[validNmae] = "";
        }

        setValidations((prevState) => ({
            ...prevState,
            ...validations,
        }));
    };

    //Handle Validations
    const handleValidation = () => {
        debugger;
        let isValid = true;

        if (!diamondData.inventory.trim()) {
            isValid = false;
            validations.inventoryVal = "Inventory is compulsory";
        } else {
            validations.inventoryVal = "";
        }
        if (!diamondData.matchedPairStock.trim()) {
            isValid = false;
            validations.matchedPairStockVal = "Matched Pair Stock is compulsory";
        } else {
            validations.matchedPairStockVal = "";
        }
        if (!diamondData.stones.trim()) {
            isValid = false;
            validations.stonesVal = "Stones are compulsory";
        } else {
            validations.stonesVal = "";
        }
        if (!diamondData.size.trim()) {
            isValid = false;
            validations.sizeVal = "Size is compulsory";
        } else {
            validations.sizeVal = "";
        }
        if (!diamondData.cost.trim()) {
            isValid = false;
            validations.costVal = "Cost is compulsory";
        } else {
            validations.costVal = "";
        }
        if (!diamondData.shape) {
            isValid = false;
            validations.shapeVal = "Kindly select the shape ";
        } else {
            validations.shapeVal = "";
        }
        if (!diamondData.color) {
            isValid = false;
            validations.colorVal = "Kindly select the color ";
        } else {
            validations.colorVal = "";
        }
        if (!diamondData.fancyCodes) {
            isValid = false;
            validations.fancyCodesVal = "Kindly select the fancy codes ";
        } else {
            validations.fancyCodesVal = "";
        }
        if (!diamondData.fancyNames) {
            isValid = false;
            validations.fancyNamesVal = "Kindly select the fancy names ";
        } else {
            validations.fancyNamesVal = "";
        }
        if (!diamondData.other.trim()) {
            isValid = false;
            validations.otherVal = "Other is compulsory";
        } else {
            validations.otherVal = "";
        }
        if (!diamondData.clarity) {
            isValid = false;
            validations.clarityVal = "Kindly select the clarity ";
        } else {
            validations.clarityVal = "";
        }
        if (!diamondData.cutGrade) {
            isValid = false;
            validations.cutGradeVal = "Kindly select the cut grade ";
        } else {
            validations.cutGradeVal = "";
        }
        if (!diamondData.certificate) {
            isValid = false;
            validations.certificateVal = "Kindly select the certificate ";
        } else {
            validations.certificateVal = "";
        }
        if (!diamondData.certificateNum) {
            isValid = false;
            validations.certificateNumVal = "Kindly select the certificate number";
        } else {
            validations.certificateNumVal = "";
        }
        if (!diamondData.depth.trim()) {
            isValid = false;
            validations.depthVal = "Depth is compulsory";
        } else {
            validations.depthVal = "";
        }
        if (!diamondData.table.trim()) {
            isValid = false;
            validations.tableVal = "Table is compulsory";
        } else {
            validations.tableVal = "";
        }
        if (!diamondData.measurements.trim()) {
            isValid = false;
            validations.measurementsVal = "Measurements are compulsory";
        } else {
            validations.measurementsVal = "";
        }
        if (!diamondData.polish) {
            isValid = false;
            validations.polishVal = "Kindly select the polish value ";
        } else {
            validations.polishVal = "";
        }
        if (!diamondData.symmetry) {
            isValid = false;
            validations.symmetryVal = "Kindly select the symmetry ";
        } else {
            validations.symmetryVal = "";
        }
        if (!diamondData.fluorescence) {
            isValid = false;
            validations.fluorescenceVal = "Kindly select the fluorescence";
        } else {
            validations.fluorescenceVal = "";
        }
        if (!diamondData.girdle) {
            isValid = false;
            validations.girdleVal = "Kindly select the girdle ";
        } else {
            validations.girdleVal = "";
        }
        if (!diamondData.culet) {
            isValid = false;
            validations.culetVal = "Kindly select the culet ";
        } else {
            validations.culetVal = "";
        }
        if (!diamondData.culetCondition) {
            isValid = false;
            validations.culetConditionVal = "Kindly select the culet condition ";
        } else {
            validations.culetConditionVal = "";
        }
        if (!diamondData.crownAngle.trim()) {
            isValid = false;
            validations.crownAngleVal = "Crown Angle is compulsory";
        } else {
            validations.crownAngleVal = "";
        }
        if (!diamondData.pavillionAngle.trim()) {
            isValid = false;
            validations.pavillionAngleVal = "Pavillion Angle is compulsory";
        } else {
            validations.pavillionAngleVal = "";
        }
        if (!diamondData.comments.trim()) {
            isValid = false;
            validations.commentsVal = "Comments are compulsory";
        } else {
            validations.commentsVal = "";
        }
        if (!diamondData.storeLocation.trim()) {
            isValid = false;
            validations.storeLocationVal = "Store Location is compulsory";
        } else {
            validations.storeLocationVal = "";
        }
        if (!diamondData.employeeName.trim()) {
            isValid = false;
            validations.employeeNameVal = "Employee Name is compulsory";
        } else {
            validations.employeeNameVal = "";
        }
        if (!diamondData.employeeId.trim()) {
            isValid = false;
            validations.employeeIdVal = "Employee ID is compulsory";
        }
        else if (/^[a-zA-Z!”$%&’()*\+,\/;\[\\\]\^_`{|}~]+$/.test(diamondData.employeeId)) {
            isValid = false;
            validations.employeeIdVal = "Employee ID is Invalid";
        }
        else {
            validations.employeeIdVal = "";
        }
        if (!diamondData.fileN || diamondData.fileN.length <= 0) {
            isValid = false;
            validations.fileNVal = "Please select file.";
        } else {
            validations.fileNVal = "";
        }
        //setValidations(validations);
        setValidations((prevState) => ({
            ...prevState,
            ...validations,
        }));
        console.log(diamondData);
        return isValid;
    };

    const { Option } = Select;
    const [switchValue, setswitchValue] = useState(true);

    const handleSwitch = () => {
        setswitchValue(!switchValue);
    };
    const handleSubmit = (e) => {
        var isValid = handleValidation();
        if (!isValid) {
            return false;
        }
        else {
            handleSaveDiamond();
        }
    };


    const fileUpload = async (e) => {
        debugger;
        let tempState = diamondData;
        var allFiles = [];
        var selectedFiles = e.target.files[0];
        if (e.target.files[0].name.match(/.(mp4)$/i)) {
            if (selectedFiles) {
                allFiles.push(selectedFiles);
                tempState["fileN"] = allFiles;
                tempState["fileName"] = allFiles[0].name;
                validations.fileNVal = "";
                tempState.Error["fileName"] ="";
            }
        }    
        else{
            tempState.Error["fileName"] = "Please select video file only.";
            tempState["fileName"] = null;
            tempState["fileN"] = null;
        }
        setDiamondData((prevState) => ({
            ...prevState,
            ...tempState,
        }));
        setValidations((prevState) => ({
            ...prevState,
            ...validations,
        }));
    };

    const handleSaveDiamond = () => {
        debugger;

        setButtonLoading(true);
        const formData = new FormData();

        formData.append("diamondDetailList.DInventoryID", parseInt(diamondData.inventory));
        formData.append("diamondDetailList.InventoryOnly", false);
        formData.append("diamondDetailList.PublicViewing", false);
        formData.append("diamondDetailList.OutonMemo", diamondData.outOnMemo ? "1" : "0");
        formData.append("diamondDetailList.Sold", false);
        formData.append("diamondDetailList.InventoryRegion", "");
        formData.append("diamondDetailList.InventoryPostDate", "");
        formData.append("diamondDetailList.DealerID", parseInt(DealerId));
        formData.append("diamondDetailList.Comments", diamondData.comments + "");
        formData.append("diamondDetailList.DealerInventoryNo", "");
        formData.append("diamondDetailList.EmployeeName", diamondData.employeeName + "");
        formData.append("diamondDetailList.EmployeeID", diamondData.employeeId + "");
        formData.append("diamondDetailList.DealerCost", "");
        formData.append("diamondDetailList.Cut", diamondData.cutGrade + "");
        formData.append("diamondDetailList.Measurements", diamondData.measurements + "");
        formData.append("diamondDetailList.MeasurementsHeight", "");
        formData.append("diamondDetailList.MeaurementsWidth", "");
        formData.append("diamondDetailList.Parcel", "");
        formData.append("diamondDetailList.TableMeasure", parseFloat(diamondData.table));
        formData.append("diamondDetailList.Depth", parseFloat(diamondData.depth));
        formData.append("diamondDetailList.Color", diamondData.color + "");
        formData.append("diamondDetailList.ClarityID", parseInt(diamondData.clarity));
        formData.append("diamondDetailList.Size", parseFloat(diamondData.size));
        formData.append("diamondDetailList.Polish", diamondData.polish + "");
        formData.append("diamondDetailList.Symmetry", diamondData.symmetry + "");
        formData.append("diamondDetailList.Flouresence", diamondData.fluorescence + "");
        formData.append("diamondDetailList.Girdle", diamondData.girdle + "");
        formData.append("diamondDetailList.Crown", diamondData.crownAngle + "");
        formData.append("diamondDetailList.Culet", diamondData.culet + "");
        formData.append("diamondDetailList.Pavillion", diamondData.pavillionAngle + "");
        formData.append("diamondDetailList.CertificateNo", diamondData.certificateNum + "");
        formData.append("diamondDetailList.Stones", parseInt(diamondData.stones));
        formData.append("diamondDetailList.OffRapaport", "");
        formData.append("diamondDetailList.CostperCarat", parseFloat(diamondData.cost));
        formData.append("diamondDetailList.SellingPrice", 0.0);
        formData.append("diamondDetailList.Certificate", diamondData.certificate + "");
        formData.append("diamondDetailList.Specials", 0);
        formData.append("diamondDetailList.USDWeekly", 0);
        formData.append("diamondDetailList.USDCurrent", 0);
        formData.append("diamondDetailList.LADWeekly", 0);
        formData.append("diamondDetailList.LADCurrent", 0);
        formData.append("diamondDetailList.BannerLink", "");
        formData.append("diamondDetailList.BannerURL", "");
        formData.append("diamondDetailList.WESTCOAST", 0);
        formData.append("diamondDetailList.DiamondCutImage", "");
        formData.append("diamondDetailList.Enhancements", "");
        formData.append("diamondDetailList.PairSeparable", "");
        formData.append("diamondDetailList.PairStockNumber", "");
        formData.append("diamondDetailList.Modified", "2022-04-06T12:27:20.298Z");
        formData.append("diamondDetailList.CutGrade", diamondData.cutGrade + "");
        formData.append("diamondDetailList.GirdleThickest", "");
        formData.append("diamondDetailList.GirdlePercentage", "");
        formData.append("diamondDetailList.CuletCondition", diamondData.culetCondition + "");
        formData.append("diamondDetailList.FluorescenceColor", "");
        formData.append("diamondDetailList.CrownHeight", "");
        formData.append("diamondDetailList.CrownPercentage", "");
        formData.append("diamondDetailList.PavilionHeight", "");
        formData.append("diamondDetailList.PavilionPercentage", "");
        formData.append("diamondDetailList.LaserIncription", "");
        formData.append("diamondDetailList.CertificateImage", "");
        formData.append("diamondDetailList.AdditionalImage", "");
        formData.append("diamondDetailList.FancyColorMainBody", "");
        formData.append("diamondDetailList.FancyColorIntensity", "");
        formData.append("diamondDetailList.FancyColorOvertone", "");
        formData.append("diamondDetailList.LotNumber", "");
        formData.append("diamondDetailList.IsActive", "");
        formData.append("diamondDetailList.AuthorizedPavilion", "");
        formData.append("diamondDetailList.CalculatedCutGrade", "");
        formData.append("diamondDetailList.IsBlockedByAdmin", false);
        formData.append("diamondDetailList.SellerName", "");
        formData.append("diamondDetailList.SourceID", "");
        formData.append("diamondDetailList.City", "");
        formData.append("diamondDetailList.State", "");
        formData.append("diamondDetailList.Country", "");
        formData.append("diamondDetailList.IsThirdParty", 0);
        formData.append("diamondDetailList.ContactName", "");
        formData.append("diamondDetailList.PolygonPhone", "");
        formData.append("diamondDetailList.PolygonEmail", "");
        formData.append("diamondDetailList.Origin", "");
        formData.append("diamondDetailList.IsInternalOnly", false);
        formData.append("diamondDetailList.price1", 0.0);
        formData.append("diamondDetailList.price2", 0.0);
        formData.append("diamondDetailList.DiamondBrand", "");
        formData.append("diamondDetailList.Ratio", "");
        formData.append("diamondDetailList.ImageFileName", "");
        formData.append("diamondDetailList.IsvideoUrl", diamondData.addVideo);
        formData.append("diamondDetailList.TextVideoUrl", "");
        formData.append("diamondDetailList.UploadFile", diamondData.fileN[0]);
        formData.append("diamondDetailList.VideoFileName", "");
        formData.append("diamondDetailList.DetailPageURL", "");
        formData.append("diamondDetailList.MeasurementsLength", "");
        formData.append("diamondDetailList.FancyColorOvertone2", "");
        formData.append("diamondDetailList.Shade", "");
        formData.append("diamondDetailList.Treatment", "");
        formData.append("diamondDetailList.KeyToSymbol", "");
        formData.append("diamondDetailList.BlackInclusion", "");
        formData.append("diamondDetailList.CentralInclusion", "");
        formData.append("diamondDetailList.MilkyInclusion", "");
        formData.append("diamondDetailList.EyeCleanInclusion", "");
        formData.append("diamondDetailList.CertificateComment", "");
        formData.append("diamondDetailList.DiamondBucket", "");
        formData.append("DiamondDealer.dealerId", parseInt(DealerId));
        try {
            CatalogDiamondDataService.SaveDiamond(formData)
                .then((response) => {
                    debugger;
                    var message = response.data.message;
                    var responseData = response.data.responseData;

                    setButtonLoading(false);
                    if (message == "Success") {
                        NotificationManager.success("Record added successfully.");
                        setDiamondData(initialState);
                    } else {
                        NotificationManager.error("Record not added.");
                    }
                })
                .catch((error) => {
                    setButtonLoading(false);
                    console.log(error);
                });
        } catch (error) {
            setButtonLoading(false);
            console.log(error);
        }

    };

    /*Checkbox change starts*/
    function onChangeCheck(e, name) {
        let tempState = diamondData;

        tempState[name] = e.currentTarget.checked;

        setDiamondData((prevState) => ({
            ...prevState,
            ...tempState,
        }));
    }
    /*Checkbox change ends*/
    
    return (
        <React.Fragment>
            <div className="prodetail__section">
                <div className="form__fields">
                    <div className="col-md-12">
                        <div className="prod__maindiv data__maping">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="workarea__heading mt-0"> {traslate("AddDiamond")} </h4>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("Inventory#")} </label>
                                        <input
                                            type="number"
                                            placeholder="Enter Inventory"
                                            name="inventory"
                                            value={diamondData.inventory}
                                            onChange={(e) => handleOnChange(e, "", "inventory")}
                                            className={validations.inventoryVal && "border__red"}
                                        />
                                        <div>
                                            {validations.inventoryVal && (
                                                <p className="error-color-red">{validations.inventoryVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("MatchedPairStock#")} </label>
                                        <input type="text"
                                            placeholder="Enter matched stock pair"
                                            name="matchedPairStock"
                                            value={diamondData.matchedPairStock}
                                            onChange={(e) => handleOnChange(e, "", "matchedPairStock")}
                                            className={validations.matchedPairStockVal && "border__red"} />
                                        <div>
                                            {validations.matchedPairStockVal && (
                                                <p className="error-color-red">{validations.matchedPairStockVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("Stones(Parcel)")} </label>
                                        <input type="number"


                                            placeholder="Enter stones"
                                            name="stones"
                                            value={diamondData.stones}
                                            onChange={(e) => handleOnChange(e, "", "stones")}
                                            className={validations.stonesVal && "border__red"} />
                                        <div>
                                            {validations.stonesVal && (
                                                <p className="error-color-red">{validations.stonesVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("Separable")} </label>
                                        <Switch
                                            name="separable"
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            checked={diamondData.separable}
                                            onChange={(e) => handleOnChange(e, "select", "separable")}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("Size")} <span className="mandatory">*</span></label>
                                        <input type="number"
                                            placeholder="Enter the size"
                                            name="size"
                                            value={diamondData.size}
                                            onChange={(e) => handleOnChange(e, "", "size")}
                                            className={validations.sizeVal && "border__red"} />
                                        <div>
                                            {validations.sizeVal && (
                                                <p className="error-color-red">{validations.sizeVal}</p>
                                            )}
                                        </div>

                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("Cost")} / {traslate("Carat")} </label>
                                        <input type="number"
                                            placeholder="Enter the cost"
                                            name="cost"
                                            value={diamondData.cost}
                                            onChange={(e) => handleOnChange(e, "", "cost")}
                                            className={validations.costVal && "border__red"} />
                                        <div>
                                            {validations.costVal && (
                                                <p className="error-color-red">{validations.costVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("Shape")} </label>
                                        <Select
                                            value={diamondData.shape ? diamondData.shape : null}
                                            name="shape"
                                            placeholder="Select"
                                            onChange={(e) => handleOnChange(e, "select", "shape")}
                                            onSearch={onSearch}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            className={validations.shapeVal ? "border__red" : "border__grey"}
                                        >
                                            <Option value="round">Round</Option>
                                            <Option value="pear">Pear</Option>
                                            <Option value="three">Option 3</Option>
                                        </Select>
                                        <div>
                                            {validations.shapeVal && (
                                                <p className="error-color-red">{validations.shapeVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("Color")} </label>
                                        <Select
                                            value={diamondData.color ? diamondData.color : null}
                                            name="shape"
                                            placeholder="Select"
                                            onChange={(e) => handleOnChange(e, "select", "color")}
                                            onSearch={onSearch}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            className={validations.colorVal ? "border__red" : "border__grey"}
                                        >
                                            <Option value="golden">Golden</Option>
                                            <Option value="silver">Silver</Option>
                                            <Option value="three">Option 3</Option>
                                        </Select>
                                        <div>
                                            {validations.colorVal && (
                                                <p className="error-color-red">{validations.colorVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("FancyCodes")} </label>
                                        <Select
                                            value={diamondData.fancyCodes ? diamondData.fancyCodes : null}
                                            name="fancyCodes"
                                            placeholder="Select"
                                            onChange={(e) => handleOnChange(e, "select", "fancyCodes")}
                                            onSearch={onSearch}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            className={validations.fancyCodesVal ? "border__red" : "border__grey"}
                                        >
                                            <Option value="fb">FB</Option>
                                            <Option value="fbgny">FBGNY</Option>
                                            <Option value="3">Option 3</Option>
                                        </Select>
                                        <div>
                                            {validations.fancyCodesVal && (
                                                <p className="error-color-red">{validations.fancyCodesVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("FancyNames")} </label>
                                        <Select
                                            value={diamondData.fancyNames ? diamondData.fancyNames : null}
                                            name="fancyNames"
                                            placeholder="Select"
                                            onChange={(e) => handleOnChange(e, "select", "fancyNames")}
                                            onSearch={onSearch}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            className={validations.fancyNamesVal ? "border__red" : "border__grey"}
                                        >
                                            <Option value="blue">Blue</Option>
                                            <Option value="brown">Brown</Option>
                                            <Option value="">Option 3</Option>
                                        </Select>
                                        <div>
                                            {validations.fancyNamesVal && (
                                                <p className="error-color-red">{validations.fancyNamesVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("Other")} </label>
                                        <input type="text"
                                            placeholder="Enter other"
                                            name="other"
                                            value={diamondData.other}
                                            onChange={(e) => handleOnChange(e, "", "other")}
                                            className={validations.otherVal && "border__red"} />
                                        <div>
                                            {validations.otherVal && (
                                                <p className="error-color-red">{validations.otherVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("Clarity")} </label>
                                        <Select
                                            value={diamondData.clarity ? diamondData.clarity : null}
                                            name="clarity"
                                            placeholder="Select"
                                            onChange={(e) => handleOnChange(e, "select", "clarity")}
                                            onSearch={onSearch}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            className={validations.clarityVal ? "border__red" : "border__grey"}
                                        >
                                            <Option value="1">FL</Option>
                                            <Option value="2">VVS1</Option>
                                            <Option value="3">Option 3</Option>
                                        </Select>
                                        <div>
                                            {validations.clarityVal && (
                                                <p className="error-color-red">{validations.clarityVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("CutGrade")} </label>
                                        <Select
                                            value={diamondData.cutGrade ? diamondData.cutGrade : null}
                                            name="cutGrade"
                                            placeholder="Select"
                                            onChange={(e) => handleOnChange(e, "select", "cutGrade")}
                                            onSearch={onSearch}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            className={validations.cutGradeVal ? "border__red" : "border__grey"}
                                        >
                                            <Option value="excellent">Excellent 1</Option>
                                            <Option value="good">Good</Option>
                                            <Option value="">Option 3</Option>
                                        </Select>
                                        <div>
                                            {validations.cutGradeVal && (
                                                <p className="error-color-red">{validations.cutGradeVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("Certificate")} </label>
                                        <Select
                                            value={diamondData.certificate ? diamondData.certificate : null}
                                            name="certificate"
                                            placeholder="Select"
                                            onChange={(e) => handleOnChange(e, "select", "certificate")}
                                            onSearch={onSearch}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            className={validations.certificateVal ? "border__red" : "border__grey"}
                                        >
                                            <Option value="gia">GIA</Option>
                                            <Option value="egl_usa">EGL USA</Option>
                                            <Option value="">Option 3</Option>
                                        </Select>
                                        <div>
                                            {validations.certificateVal && (
                                                <p className="error-color-red">{validations.certificateVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("Certificate#")} </label>
                                        <input type="text"
                                            placeholder="Enter certificate number"
                                            name="certificateNum"
                                            value={diamondData.certificateNum}
                                            onChange={(e) => handleOnChange(e, "", "certificateNum")}
                                            className={validations.certificateNumVal && "border__red"} />
                                        <div>
                                            {validations.certificateNumVal && (
                                                <p className="error-color-red">{validations.certificateNumVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("Depth")} </label>
                                        <input type="number"
                                            placeholder="Enter depth"
                                            name="depth"
                                            value={diamondData.depth}
                                            onChange={(e) => handleOnChange(e, "", "depth")}
                                            className={validations.depthVal && "border__red"} />
                                        <div>
                                            {validations.depthVal && (
                                                <p className="error-color-red">{validations.depthVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("Table")} </label>
                                        <input type="number"
                                            placeholder="Enter table"
                                            name="table"
                                            value={diamondData.table}
                                            onChange={(e) => handleOnChange(e, "", "table")}
                                            className={validations.tableVal && "border__red"} />
                                        <div>
                                            {validations.tableVal && (
                                                <p className="error-color-red">{validations.tableVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("Measurements")} </label>
                                        <input type="text"
                                            placeholder="Enter the measurements"
                                            name="measurements"
                                            value={diamondData.measurements}
                                            onChange={(e) => handleOnChange(e, "", "measurements")}
                                            className={validations.measurementsVal && "border__red"} />
                                        <div>
                                            {validations.measurementsVal && (
                                                <p className="error-color-red">{validations.measurementsVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("Polish")} </label>
                                        <Select
                                            value={diamondData.polish ? diamondData.polish : null}
                                            name="polish"
                                            placeholder="Select"
                                            onChange={(e) => handleOnChange(e, "select", "polish")}
                                            onSearch={onSearch}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            className={validations.polishVal ? "border__red" : "border__grey"}
                                        >
                                            <Option value="excellent">Excellent</Option>
                                            <Option value="good">Good</Option>
                                            <Option value="">Option 3</Option>
                                        </Select>
                                        <div>
                                            {validations.polishVal && (
                                                <p className="error-color-red">{validations.polishVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("Symmetry")} </label>
                                        <Select
                                            value={diamondData.symmetry ? diamondData.symmetry : null}
                                            name="symmetry"
                                            placeholder="Select"
                                            onChange={(e) => handleOnChange(e, "select", "symmetry")}
                                            onSearch={onSearch}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            className={validations.symmetryVal ? "border__red" : "border__grey"}
                                        >
                                            <Option value="excellent">Excellent</Option>
                                            <Option value="good">Good</Option>
                                            <Option value="">Option 3</Option>
                                        </Select>
                                        <div>
                                            {validations.symmetryVal && (
                                                <p className="error-color-red">{validations.symmetryVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("Fluorescence")} </label>
                                        <Select
                                            value={diamondData.fluorescence ? diamondData.fluorescence : null}
                                            name="fluorescence"
                                            placeholder="Select"
                                            onChange={(e) => handleOnChange(e, "select", "fluorescence")}
                                            onSearch={onSearch}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            className={validations.fluorescenceVal ? "border__red" : "border__grey"}
                                        >
                                            <Option value="strongBlue">Strong Blue</Option>
                                            <Option value="mediumBlue">Medium Blue</Option>
                                            <Option value="3">Option 3</Option>
                                        </Select>
                                        <div>
                                            {validations.fluorescenceVal && (
                                                <p className="error-color-red">{validations.fluorescenceVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("Girdle")} </label>
                                        <Select
                                            value={diamondData.girdle ? diamondData.girdle : null}
                                            name="girdle"
                                            placeholder="Select"
                                            onChange={(e) => handleOnChange(e, "select", "girdle")}
                                            onSearch={onSearch}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            className={validations.girdleVal ? "border__red" : "border__grey"}
                                        >
                                            <Option value="extremely">Extremely</Option>
                                            <Option value="thin">Thin</Option>
                                            <Option value="3">Option 3</Option>
                                        </Select>
                                        <div>
                                            {validations.girdleVal && (
                                                <p className="error-color-red">{validations.girdleVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("Culet")} </label>
                                        <Select
                                            value={diamondData.culet ? diamondData.culet : null}
                                            name="certificate"
                                            placeholder="Select"
                                            onChange={(e) => handleOnChange(e, "select", "culet")}
                                            onSearch={onSearch}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            className={validations.culetVal ? "border__red" : "border__grey"}
                                        >
                                            <Option value="large">Large</Option>
                                            <Option value="medium">Medium</Option>
                                            <Option value="3">Option 3</Option>
                                        </Select>
                                        <div>
                                            {validations.culetVal && (
                                                <p className="error-color-red">{validations.culetVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("CuletCondition")} </label>
                                        <Select
                                            value={diamondData.culetCondition ? diamondData.culetCondition : null}
                                            name="culetCondition"
                                            placeholder="Select"
                                            onChange={(e) => handleOnChange(e, "select", "culetCondition")}
                                            onSearch={onSearch}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            className={validations.culetConditionVal ? "border__red" : "border__grey"}
                                        >
                                            <Option value="chipped">Chipped</Option>
                                            <Option value="pointed">Pointed</Option>
                                            <Option value="3">Option 3</Option>
                                        </Select>
                                        <div>
                                            {validations.culetConditionVal && (
                                                <p className="error-color-red">{validations.culetConditionVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("CrownAngle")} </label>
                                        <input type="number"
                                            placeholder="Enter crown angle"
                                            name="crownAngle"
                                            value={diamondData.crownAngle}
                                            onChange={(e) => handleOnChange(e, "", "crownAngle")}
                                            className={validations.crownAngleVal && "border__red"} />
                                        <div>
                                            {validations.crownAngleVal && (
                                                <p className="error-color-red">{validations.crownAngleVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("PavillionAngle")} </label>
                                        <input type="number"
                                            placeholder="Enter pavillion angle"
                                            name="pavillionAngle"
                                            value={diamondData.pavillionAngle}
                                            onChange={(e) => handleOnChange(e, "", "pavillionAngle")}
                                            className={validations.pavillionAngleVal && "border__red"} />
                                        <div>
                                            {validations.pavillionAngleVal && (
                                                <p className="error-color-red">{validations.pavillionAngleVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label>
                                            {traslate("Comments")}
                                            <Tooltip placement="right" title={"Do not include pricing informatin in comments."}>
                                                <img src={UnionIcon} alt="" />
                                            </Tooltip>
                                        </label>
                                        <input type="text"
                                            placeholder="Enter comments"
                                            name="comments"
                                            value={diamondData.comments}
                                            onChange={(e) => handleOnChange(e, "", "comments")}
                                            className={validations.commentsVal && "border__red"} />
                                        <div>
                                            {validations.commentsVal && (
                                                <p className="error-color-red">{validations.commentsVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("StoreLocation")} </label>
                                        <input type="text"
                                            placeholder="Enter location"
                                            name="storeLocation"
                                            value={diamondData.storeLocation}
                                            onChange={(e) => handleOnChange(e, "", "storeLocation")}
                                            className={validations.storeLocationVal && "border__red"} />
                                        <div>
                                            {validations.storeLocationVal && (
                                                <p className="error-color-red">{validations.storeLocationVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("EmployeeName")} </label>
                                        <input type="text"
                                            placeholder="Enter employee name"
                                            name="employeeName"
                                            value={diamondData.employeeName}
                                            
                                            onChange={(e) => handleOnChange(e, "", "employeeName")}
                                            className={validations.employeeNameVal && "border__red"} />
                                        <div>
                                            {validations.employeeNameVal && (
                                                <p className="error-color-red">{validations.employeeNameVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("EmployeeID")} </label>
                                        <input type="text"
                                            placeholder="Enter employee id"
                                            name="employeeId"
                                            value={diamondData.employeeId}
                                            onChange={(e) => handleOnChange(e, "", "employeeId")}
                                            className={validations.employeeIdVal && "border__red"} />
                                        <div>
                                            {validations.employeeIdVal && (
                                                <p className="error-color-red">{validations.employeeIdVal}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("OutOnMemo")} </label>
                                        <Switch
                                            name="out_on_memo"
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            checked={diamondData.outOnMemo}
                                            onChange={(e) => handleOnChange(e, "select", "outOnMemo")}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label>  {traslate("SeenbyOtherDealers")} </label>
                                        <Switch
                                            name="seen_by_other_dealers"
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            checked={diamondData.seenByOtherDealers}
                                            onChange={(e) => handleOnChange(e, "select", "seenByOtherDealers")}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("AddVideo")} </label>
                                        {/* <Switch
                                            name="add_video"
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            checked={diamondData.addVideo}
                                            onChange={(e) => handleOnChange(e, "select", "addVideo")}
                                        /> */}
                                        <div className="radio__block">
                                            <input type="checkbox"
                                                className="w-auto"
                                                value={diamondData.addVideo}
                                                onChange={(e) => onChangeCheck(e, "addVideo")}
                                            />
                                            <label>Video Url</label>
                                        </div>
                                        {diamondData.addVideo &&
                                            <div className="input__block">
                                                <input type="text"
                                                // className="w-auto"
                                                // value={diamondData.addVideo}
                                                // onChange={(e) => onChangeCheck(e, "addVideo")}
                                                />
                                            </div>
                                        }

                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="input__block">
                                        <label> {traslate("File")} </label>
                                        <input
                                            type="file"
                                            onChange={(e) => fileUpload(e)}
                                            // className={validations.fileNVal && "border__red"}
                                            className="p-1 d-none"
                                            id="video"
                                        />
                                        <div className="choose_videobtn">
                                            <label className="" for="video">Choose Video</label>

                                            {diamondData.fileName ? diamondData.fileName :
                                                <span>No file chosen</span>
                                            }
                                            
                                            
                                        </div>
                                        {diamondData.Error ? (
                                        <div className="text-danger">
                                            {diamondData.Error["fileName"]}
                                        </div>
                                        ) : null}

                                        {/* <div>
                                            <span className="">{diamondData.fileName}</span>
                                        </div> */}
                                        <div>
                                            {validations.fileNVal && (
                                                <p className="error-color-red">{validations.fileNVal}</p>
                                            )}
                                        </div>

                                        <input type="text" className="d-none" />
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-2">
                                    <Button
                                        className="primary-btn"
                                        onClick={handleSubmit}
                                        loading={buttonLoading}
                                    >
                                        {traslate("Create")}

                                    </Button>
                                    <Button
                                        className="primary-btn ml-2"
                                    >
                                        {traslate("Cancel")}

                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddSignleDiamond;
