import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table,
  Spin,
} from "antd";
import traslate from "../../i18n/translate";
import RetailerDataService from "../../services/retailer-retailer.service";
import { NotificationManager } from "react-notifications";

const PendingJewelryRequest = (props) => {
  const [pendingJewelryRequest, setPendingJewelryRequest] = useState([]);
  const [loading, setLoading] = useState(false);
  const { Option } = Select;

  const style = <span>content not provided.</span>;

  const columns = [
    {
      title: traslate("StoreName"),
      dataIndex: "dealerCompany",
    },
    {
      title: traslate("RetailerType"),
      dataIndex: "retailerType",
    },
    {
      title: traslate("City"),
      dataIndex: "dealerCity",
    },
    {
      title: traslate("State"),
      dataIndex: "dealerState",
    },
    {
      title: traslate("RequestedDate"),
      dataIndex: "reqdDate",
    },
    {
      title: traslate("ActionRequest"),
      render: (index, row) => (
        <div className="action__btns">
          <div className="icn__div">
            <i
              className="fa fa-check chck_icn"
              onClick={() => {
                console.log(row.dealerID);
              }}
              alt=""
            ></i>
          </div>
          <div className="image__block">
            <i
              className="fa fa-close clse_icn"
              onClick={() => {
                console.log(row.dealerID);
              }}
              alt=""
            ></i>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (props.type) {
    } else {
      handleGetPendingRequests();
    }
  }, []);

  const handleGetPendingRequests = () => {
    debugger;
    try {
      let inputData = {
        dealerID: 2704,
        sales: "",
      };
      setLoading(true);
      RetailerDataService.GetPendingRequests(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setPendingJewelryRequest(responseData);
          } else {
            setPendingJewelryRequest([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const data = [
    {
      key: "1",
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
      actionrequest: (
        <div className="icn__div">
          <i className="fa fa-check chck_icn"></i>
          <i className="fa fa-close clse_icn"></i>
        </div>
      ),
    },
    {
      key: "2",
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
      actionrequest: (
        <div className="icn__div">
          <i className="fa fa-check chck_icn"></i>
          <i className="fa fa-close clse_icn"></i>
        </div>
      ),
    },
    {
      key: "3",
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
      actionrequest: (
        <div className="icn__div">
          <i className="fa fa-check chck_icn"></i>
          <i className="fa fa-close clse_icn"></i>
        </div>
      ),
    },
    {
      key: "4",
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
      actionrequest: (
        <div className="icn__div">
          <i className="fa fa-check chck_icn"></i>
          <i className="fa fa-close clse_icn"></i>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="pendjewelreq__section">
        <div className="form__fields">
          <div className="col-md-12">
            <h4 className="workarea__heading mt-0">
              Pending {props.type ? "Diamond" : "Jewelry"} Request
              {/* {traslate("PendingJewelryRequest")} */}
            </h4>
          </div>
          <div className="col-lg-12 col-md-12">
            <Table
              className="pendjewelreq__tbl"
              columns={columns}
              dataSource={pendingJewelryRequest}
              scroll={{ x: 600 }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PendingJewelryRequest;
