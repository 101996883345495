import React, { useState, useEffect, useRef } from "react";
import { Select, Checkbox, Button, Tooltip, Modal, Table } from "antd";
import { Tabs, Tab } from "react-bootstrap";
import UnionIcon from "../../assets/images/icons/Union_5.svg";
import Create_icon from "../../assets/images/create_icon.png";
import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";
import CatalogJewelryDataService from "../../services/catalog-jewelry.service";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import traslate from "../../i18n/translate";

const { Option } = Select;
const ProductMarkup = () => {
  const refMarkUpDataList = useRef();
  const initialState = {
    Currency: null,
    Rounding: null,
    DefaultMarkup: null,
    MinPrice: null,
    MaxPrice: null,
    MarkUP: null,
    Error: {}
  };
  const [state, setState] = useState(initialState);
  const [CategotyList, setCategotyList] = useState([]);
  const [RoundingList, setRoundingList] = useState([]);
  const [DealerId, setDealerId] = useState(null);
  const [MarkUpDataList, setMarkUpDataList] = useState([]);
  const [MarkUpPriceRangeList, setMarkUpPriceRangeList] = useState([]);
  const [OverrideDataList, setOverrideDataList] = useState([]);
  const [Defaultloading, setDefaultloading] = useState(false);
  const [Overrideloading, setOverrideloading] = useState(false);
  const [submitLoading1, setSubmitLoading1] = useState(false);
  const [selectedTab, setSelectedTab] = useState("general");
  const [delOverrideData, setDelOverrideData] = useState({
    recordId: "",
    overRideValue: "",
  });
  const [visible3, setVisible3] = useState(false);
  const [CustomMarkupID, setCustomMarkupID] = useState(null);
  const [LastID, setLastID] = useState(null);
  const [IsDelete, setIsDelete] = useState(false);
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);

  const style = <span> {traslate("contentnotprovided.")} </span>;

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        setDealerId(loginDetials.responseData.dealerId);
        handleGetMarkUpDataList(loginDetials.responseData.dealerId);
        handleDefaultDataMarkupForDealerList(
          loginDetials.responseData.dealerId
        );
        handleGetCurrencyList();
        handleGetRoundingList();
        handleGetOverRideDataList(loginDetials.responseData.dealerId);

      }
    }
  }, []);

  const handleValidation = () => {
    debugger;

    var isValid = true;
    if (!state.Currency) {
      state.Error["Currency"] = "Please select currency.";
      isValid = false;
    }
    if (!state.Rounding) {
      state.Error["Rounding"] = "Please select rounding.";
      isValid = false;
    }
    if (!state.DefaultMarkup) {
      debugger
      state.Error["DefaultMarkup"] = "Please enter default markup.";
      isValid = false;
    } else if (state.DefaultMarkup > 100) {
      state.Error["DefaultMarkup"] = "Please enter default markup value less then 100%.";
      isValid = false;
    }



    setState((prevState) => ({
      ...prevState,
      ...state,
    }));

    return isValid;
  };



  const handleOnClickRow = (record, recordIndex) => {
    debugger;
    if (IsDelete) {
      setCustomMarkupID(null);
      setIsDelete(false);
    } else {
      setCustomMarkupID(record.customMarkupID);
    }

    state.MinPrice = record.minRange;
    state.MaxPrice = record.maxRange;
    state.MarkUP = record.markup;

    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  const handleAddMarkUpPrice = (e, CustomMarkupID) => {
    debugger;
    var tempState = [];
    let custID = 0;
    if (CustomMarkupID) {

      const temp = MarkUpDataList.map((x) => {
        if (x.customMarkupID === CustomMarkupID) {
          return {
            ...x,
            minRange: Number(state.MinPrice).toFixed(2),
            maxRange: Number(state.MaxPrice).toFixed(2),
            markup: Number(state.MarkUP).toFixed(2),
          };
        } else return x;
      });
      setMarkUpDataList(temp);
      if (LastID === CustomMarkupID) {
        setCustomMarkupID(null);
        state.MinPrice = state.MaxPrice;
        state.MarkUP = "";
        setState((prevState) => ({
          ...prevState,
          ...state,
        }));

      }

    } else {

      if (MarkUpDataList.length > 0) {
        custID = MarkUpDataList[MarkUpDataList.length - 1].customMarkupID || 0;
      } else {
        tempState = [];
      }
      let inputData = {
        customMarkupID: parseInt(custID) + 1,
        dealerID: 0,
        minRange: Number(state.MinPrice).toFixed(2), //parseFloat(state.MinPrice),
        maxRange: Number(state.MaxPrice).toFixed(2),//parseFloat(state.MaxPrice),
        markup: Number(state.MarkUP).toFixed(2), //parseFloat(state.MarkUP),
      };
      tempState.push(inputData);
      var arr1 = MarkUpDataList || [];
      var arr2 = tempState;
      var arr3 = [...arr1, ...arr2];
      setMarkUpDataList(arr3);
      setCustomMarkupID(parseInt(custID) + 1);
      setLastID(parseInt(custID) + 1);
    }

    console.log(MarkUpDataList);
  };

  const handleDelete = (row) => {
    debugger;
    setCustomMarkupID(null);
    setIsDelete(true);
    let tempArray = MarkUpDataList.filter(
      (x) => x.customMarkupID != row.customMarkupID
    );
    setMarkUpDataList(tempArray);

  };

  const handleDeleteModal = (row) => {
    debugger;
    setDelOverrideData({
      recordId: row.id,
      overRideValue: row.overrideType,
    });
    setVisible3(true);
  };

  const handleCancel3 = () => {
    setVisible3(false);
  };

  const handleOnEditChange = (row, e, name) => {
    if (name === "enabled") {
      const temp = OverrideDataList.map((x) => {
        if (x.id === row.id) {
          return { ...x, enabled: e.target.checked };
        } else return x;
      });
      setOverrideDataList(temp);
    }
  };

  const OnSaveButton = (e, TabName) => {
    debugger;
    if (selectedTab === "general") {

      //var data = JSON.stringify(MarkUpDataList);

      handleSaveMarkUpDataList(MarkUpDataList);

    }
    if (selectedTab === "overrides") {
      var data = JSON.stringify(OverrideDataList);
      handleUpdateOverrideValue(data);
    }
  };

  const handleTabsChange = (e) => {
    setSelectedTab(e);
    console.log(selectedTab);
  };

  const onChange = (e, name) => {
    let tempState = state;
    tempState[name] = e.target.value;

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const onChangeDrop = (e, name) => {
    let tempState = state;
    tempState[name] = e;

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const handleGetCurrencyList = () => {
    try {
      CatalogJewelryDataService.GetCurrencyList()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            //var data =JSON.parse(responseData);
            setCategotyList(responseData);
          } else {
            setCategotyList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetRoundingList = () => {
    debugger;
    try {
      CatalogJewelryDataService.GetRoundingList()
        .then((response) => {
          debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            setRoundingList(responseData);
          } else {
            setRoundingList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDefaultDataMarkupForDealerList = (id) => {
    let inputData = {
      dealerId: id || DealerId,
    };
    try {
      CatalogJewelryDataService.GetDefaultDataMarkupForDealerList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            state.DefaultMarkup = responseData;
            setState((prevState) => ({
              ...prevState,
              ...state,
            }));
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetMarkUpDataList = (id) => {
    debugger;
    setDefaultloading(true);
    let inputData = {
      dealerId: id || DealerId,
    };
    try {
      CatalogJewelryDataService.GetMarkUpDataList(inputData)
        .then((response) => {
          debugger;

          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            state.Currency = parseInt(responseData.currency) || null;
            state.Rounding = parseInt(responseData.roundingoption) || null;
            if (responseData.markUpValLst.length > 0) {
              var data = responseData.markUpValLst[responseData.markUpValLst.length - 1];
              setCustomMarkupID(data.customMarkupID);
              setLastID(data.customMarkupID)
              state.MinPrice = parseFloat(data.minRange) || null;
              state.MaxPrice = parseFloat(data.maxRange) || null;
              state.MarkUP = parseFloat(data.markup) || null;
            } else {
              setCustomMarkupID(null);
              state.MinPrice = parseFloat(0.00);
              state.MaxPrice = parseFloat(1000000.00);
              state.MarkUP = null;
            }
            setState((prevState) => ({
              ...prevState,
              ...state,
            }));
            setMarkUpDataList(responseData.markUpValLst);
            setDefaultloading(false);
          } else {
            state.Currency = null;
            state.Rounding = null;
            setState((prevState) => ({
              ...prevState,
              ...state,
            }));
            setDefaultloading(false);
            setMarkUpDataList([]);
          }
        })
        .catch((error) => {
          state.Currency = null;
          state.Rounding = null;
          setState((prevState) => ({
            ...prevState,
            ...state,
          }));
          setDefaultloading(false);
          console.log(error);
        });
    } catch (error) {
      state.Currency = null;
      state.Rounding = null;
      setState((prevState) => ({
        ...prevState,
        ...state,
      }));
      setDefaultloading(false);
      console.log(error);
    }
  };

  const handleGetOverRideDataList = (id) => {
    setOverrideloading(true);
    debugger;
    let inputData = {
      dealerId: id || DealerId,
    };
    try {
      CatalogJewelryDataService.GetOverRideDataList(inputData)
        .then((response) => {
          debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;
          setOverrideloading(false);
          if (message == "Success") {
            setOverrideDataList(responseData);
          } else {
            setOverrideDataList([]);
          }
        })
        .catch((error) => {
          setOverrideloading(false);
          console.log(error);
        });
    } catch (error) {
      setOverrideloading(false);
      console.log(error);
    }
  };

  const handleUpdateOverrideValue = (inputData) => {
    debugger;
    setOverrideloading(true);
    try {
      CatalogJewelryDataService.UpdateOverrideValue(inputData)
        .then((response) => {
          debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;
          setOverrideloading(false);
          if (message == "Success") {
            NotificationManager.success("Record Updated Successfully.");
            handleGetOverRideDataList();
          } else {
            NotificationManager.error("Record Not Updated.");
          }
        })
        .catch((error) => {
          setOverrideloading(false);
          console.log(error);
        });
    } catch (error) {
      setOverrideloading(false);
      console.log(error);
    }
  };

  const handleDeleteOverrideValue = () => {
    debugger;
    let inputData = [
      {
        recordId: delOverrideData.recordId,
        overRideValue: delOverrideData.overRideValue,
      },
    ];
    setOverrideloading(true);
    try {
      CatalogJewelryDataService.DeleteOverrideValue(inputData)
        .then((response) => {
          debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;
          setOverrideloading(false);
          if (message == "Success") {
            NotificationManager.success("Record Deleted Successfully.");
            handleGetOverRideDataList();
          } else {
            NotificationManager.error("Record Not Deleted.");
          }
        })
        .catch((error) => {
          setOverrideloading(false);
          console.log(error);
        });
    } catch (error) {
      setOverrideloading(false);
      console.log(error);
    }
    setVisible3(false);
    setDelOverrideData({});
  };

  const handleSaveMarkUpDataList = (JsonData) => {
    debugger;
    if (handleValidation()) {
      let inputData = {
        dealerId: loginDetials.responseData.dealerId,
        defaultMarkUp: state.DefaultMarkup,
        currency: state.Currency + "",
        markupDataList: JsonData,
      };
      setSubmitLoading1(true);
      try {
        CatalogJewelryDataService.SaveMarkUpDataList(inputData)
          .then((response) => {
            debugger;
            var message = response.data.message;
            var responseData = response.data.responseData;
            setSubmitLoading1(false);
            if (message == "Success") {
              NotificationManager.success("Record Updated Successfully.");
              handleGetMarkUpDataList();
              state.Error["DefaultMarkup"] = ""
            } else {
              NotificationManager.error("Record Not Updated.");
            }
          })
          .catch((error) => {
            setSubmitLoading1(false);
            console.log(error);
          });
      } catch (error) {
        setSubmitLoading1(false);
        console.log(error);
      }
    }

  };
  const data1 = [
    {
      markup: <span>100%</span>,
      cost: <span>$1,000</span>,
      retail: <span>$2,000</span>,
      mrk: <span>(Key)</span>,
    },
    {
      markup: <span>120%</span>,
      cost: <span>$1,000</span>,
      retail: <span>$2,200</span>,
      mrk: <span>(2.2)</span>,
    },
    {
      markup: <span>150%</span>,
      cost: <span>$1,000</span>,
      retail: <span>$2,500</span>,
      mrk: <span>(2.5)</span>,
    },
    {
      markup: <span>80%</span>,
      cost: <span>$1,000</span>,
      retail: <span>$1,800</span>,
      mrk: <span>(1.8)</span>,
    },
  ];

  const onKeyPressEvent = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };
  // const onKeyPressEvent = (e) => {
  //   debugger
  //   if (e.code === 'Minus' || e.target.value.length > 2 || e.target.value > 100) {
  //     e.preventDefault();
  //   }
  // };

  // const onKeyPressEvent = (event) => {
  //   debugger
  //   if (!/^(100|[1-9][0-9]?)$/.test(event.target.value)) {
  //     event.preventDefault();
  //   }
  // };

  // const onKeyPressEvent = (e) => {
  //   if (e.code === 'Minus' || state.DefaultMarkup > 100) {
  //     e.preventDefault();
  //   }
  //   console.log(state.DefaultMarkup);
  // };


  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv prodmarkup__secion">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="workarea__heading mt-0"> {traslate("ProductMarkup")} </h4>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="prodesc__div">
                    <Tabs
                      // defaultActiveKey="general"
                      id="uncontrolled-tab-example"
                      className="tab__div mb-3"
                      onSelect={(e) => {
                        handleTabsChange(e);
                      }}
                      activeKey={selectedTab}
                    >
                      <Tab eventKey="general" title={traslate("General")}>
                        <div className="tab__contentdiv">
                          <div className="col-lg-12">
                            <div className="head__div">
                              <h4> {traslate("GeneralSettings")} </h4>
                            </div>
                            <div className="desc__maindiv">
                              <div className="col-lg-12 p-3">
                                <div className="row">
                                  <div className="col-lg-3 mt-4">
                                    <div className="input__block">
                                      <label>
                                      {traslate("Currency")}
                                        <Tooltip
                                          placement="right"
                                          title={style}
                                        >
                                          <img src={UnionIcon} alt="" />
                                        </Tooltip>{" "}
                                        <span>*</span>
                                      </label>
                                      <Select
                                        showSearch
                                        className="border__grey"
                                        placeholder="Select Currency "
                                        optionFilterProp="children"
                                        value={state.Currency}
                                        onChange={(e) =>
                                          onChangeDrop(e, "Currency")
                                        }
                                      >
                                        <Option value="">Select Currency</Option>
                                        {CategotyList.map((item, i) => {
                                          return (
                                            <Option value={item.currencyId}>
                                              {item.currencyText}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                      {state.Error ? (
                                        <div className="text-danger">
                                          {
                                            state.Error["Currency"]

                                          }
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-lg-3 mt-4">
                                    <div className="input__block">
                                      <label>
                                      {traslate("Rounding")}
                                        <Tooltip
                                          placement="right"
                                          title={style}
                                        >
                                          <img src={UnionIcon} alt="" />
                                        </Tooltip>{" "}
                                        <span>*</span>
                                      </label>
                                      <Select
                                        showSearch
                                        className="border__grey"
                                        placeholder="Select Rounding "
                                        optionFilterProp="children"
                                        value={state.Rounding}
                                        onChange={(e) =>
                                          onChangeDrop(e, "Rounding")
                                        }
                                      >
                                        <Option value="">Select Rounding</Option>
                                        {RoundingList.map((item, i) => {
                                          return (
                                            <Option value={item.roundingId}>
                                              {item.roundingText}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                      {state.Error ? (
                                        <div className="text-danger">
                                          {
                                            state.Error["Rounding"]

                                          }
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="head__div">
                              <h4> {traslate("DefaultMarkup")} </h4>
                            </div>
                            <div className="desc__maindiv">
                              <div className="col-lg-12 p-3">
                                <div className="row">
                                  <div className="col-lg-3 mt-4">
                                    <div className="input__block">
                                      <label>
                                      {traslate("DefaultMarkup")}
                                        <Tooltip
                                          placement="right"
                                          title={style}
                                        >
                                          <img src={UnionIcon} alt="" />
                                        </Tooltip>{" "}
                                        <span>*</span>
                                      </label>
                                      <div className="mrkup__inptdiv">
                                        <input
                                          type="number"
                                          min="0"
                                          max="100"
                                          // onKeyDown={onKeyDownEvent}
                                          onKeyPress={onKeyPressEvent}
                                          // maxLength={3}                                                             

                                          placeholder="Default Markup"
                                          className="dmrkup__inpt"
                                          value={state.DefaultMarkup}
                                          onChange={(e) => {
                                            e.target.value = e.target.value.slice(0, 3);
                                            onChange(e, "DefaultMarkup")
                                          }

                                          }
                                        />
                                        <span className="ampcent__txt">%</span>
                                        {state.Error ? (
                                          <div className="text-danger">
                                            {
                                              state.Error["DefaultMarkup"]

                                            }
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-12 mt-4">
                                    <Table
                                      const
                                      columns={[
                                        {
                                          title: traslate("Markup"),

                                          dataIndex: "markup",
                                          key: "markup",
                                          fixed: "left",
                                        },
                                        {
                                          title: traslate("Cost"),
                                          dataIndex: "cost",
                                          key: "cost",
                                        },
                                        {
                                          title: traslate("Retail"),
                                          dataIndex: "retail",
                                          key: "retail",
                                        },
                                        {
                                          title: traslate("Mrk"),
                                          dataIndex: "mrk",
                                          key: "mrk",
                                        },
                                      ]}
                                      dataSource={data1}
                                      scroll={{ x: 600 }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="head__div">
                              <h4> {traslate("MarkupByPriceRange")} </h4>
                            </div>
                            <div className="desc__maindiv">
                              <div className="col-lg-12 p-3">
                                <div className="row">
                                  <div className="col-lg-2 mt-4">
                                    <div className="input__block">
                                      <div className="mrkup__inptdiv">
                                        <span className="dollr__txt">$</span>
                                        <input
                                          type="number"
                                          min="0"
                                          max="100"
                                          onKeyPress={onKeyPressEvent}
                                          placeholder="Min Price"
                                          className="mrkup__rangeinpt"
                                          //disabled
                                          value={state.MinPrice}
                                          onChange={(e) =>
                                            onChange(e, "MinPrice")
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 mt-4">
                                    <div className="input__block">
                                      <div className="mrkup__inptdiv">
                                        <span className="dollr__txt">$</span>
                                        <input
                                          type="number"
                                          min="0"
                                          max="100"
                                          onKeyPress={onKeyPressEvent}
                                          placeholder="Max Price"
                                          className="mrkup__rangeinpt"
                                          value={state.MaxPrice}
                                          onChange={(e) =>
                                            onChange(e, "MaxPrice")
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 mt-4">
                                    <div className="input__block">
                                      <div className="mrkup__inptdiv">
                                        <input
                                          type="number"
                                          min="0"
                                          max="100"
                                          onKeyPress={onKeyPressEvent}
                                          placeholder="MarkUP"
                                          className="dmrkup__inpt"
                                          value={state.MarkUP}
                                          onChange={(e) =>
                                            onChange(e, "MarkUP")
                                          }
                                        />
                                        <span className="ampcent__txt">%</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 mt-4">
                                    <button
                                      className="primary-btn"
                                      onClick={(e) => handleAddMarkUpPrice(e, CustomMarkupID)}
                                    >
                                      {" "}
                                      <img src={Create_icon} alt="" />
                                      {traslate("Add")}
                                    </button>
                                  </div>
                                  <div className="col-lg-12 mt-4">
                                    <Table
                                      columns={[
                                        {
                                          title: traslate("MinPrice"),
                                          dataIndex: "minRange",
                                          render: (row, item) => {
                                            return (
                                              <span> ${item.minRange}  </span>
                                            );
                                          },
                                        },
                                        {
                                          title: traslate("MaxPrice"),
                                          dataIndex: "maxRange",
                                          render: (row, item) => {
                                            return (
                                              <span> ${item.maxRange}  </span>
                                            );
                                          },
                                        },
                                        {
                                          title: traslate("MarkUp"),
                                          dataIndex: "markup",
                                          render: (row, item) => {
                                            return (
                                              <span> {item.markup} %  </span>
                                            );
                                          },
                                        },
                                        {
                                          title: traslate("Action"),
                                          width: 100,
                                          render: (item, row) => {
                                            return (
                                              <div className="action__btns">
                                                <div className="image__block">
                                                  <img
                                                    src={Delete_iconbl}
                                                    alt=""
                                                    onClick={(e) =>
                                                      handleDelete(row)
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            );
                                          },
                                        },
                                      ]}
                                      onRow={(record, recordIndex) => ({
                                        onClick: (event) => {
                                          handleOnClickRow(record, recordIndex);
                                        },
                                      })}
                                      loading={Defaultloading}
                                      dataSource={MarkUpDataList}
                                      scroll={{ x: 600 }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="overrides" title={traslate("Overrides")}>
                        <div className="tab__contentdiv">
                          <div className="desc__maindiv">
                            <div className="col-lg-12 p-3">
                              <Table
                                const
                                columns={[
                                  {
                                    title: "",
                                    dataIndex: "enabled",
                                    key: "enabled",
                                    render: (item, row) => {
                                      return (
                                        <Checkbox
                                          checked={row.enabled}
                                          onChange={(e) =>
                                            handleOnEditChange(
                                              row,
                                              e,
                                              "enabled"
                                            )
                                          }
                                        />
                                      );
                                    },
                                    width: 100,
                                  },
                                  {
                                    title: traslate("Name"),
                                    dataIndex: "overrideName",
                                    key: "overrideName",
                                  },
                                  {
                                    title: traslate("Type"),
                                    dataIndex: "overrideType",
                                    key: "overrideType",
                                  },
                                  {
                                    title: traslate("Override"),
                                    dataIndex: "override",
                                    key: "override",
                                    render: (row, item) => {
                                      return (
                                        <span>
                                          {item.enabled
                                            ? `${item.override} %`
                                            : "Disabled"}
                                        </span>
                                      );
                                    },
                                  },
                                  {
                                    title: traslate("Updated"),
                                    dataIndex: "updated",
                                    key: "updated",
                                  },
                                  {
                                    title: traslate("Action"),
                                    dataIndex: "action",
                                    key: "action",
                                    width: 100,
                                    render: (item, row) => {
                                      return (
                                        <div className="action__btns">
                                          <div className="image__block">
                                            <img
                                              src={Delete_iconbl}
                                              alt=""
                                              onClick={() =>
                                                handleDeleteModal(row)
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    },
                                  },
                                ]}
                                // rowKey={(record) => record.id}
                                // rowSelection={rowSelection}
                                loading={Overrideloading}
                                dataSource={OverrideDataList}
                                scroll={{ x: 600 }}
                              />
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>

                    <div class="col-md-6 mt-3">
                      <Button
                        className="primary-btn"
                        onClick={(e) => OnSaveButton()}
                        loading={submitLoading1}
                      >
                        {traslate("SavePricing")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <Modal
                title=""
                className="modalconsupld__section"
                centered
                visible={visible3}
                onOk={() => setVisible3(false)}
                onCancel={() => setVisible3(false)}
                width={400}
                footer={[
                  <Button key="back" onClick={handleCancel3}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={handleDeleteOverrideValue}
                  >
                    {traslate("Delete")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-12 mt-4 text-center">
                            <p>
                            {traslate("Areyousureyouwanttodelete")} "
                              {delOverrideData.recordId}" {traslate("DealerData")}
                            </p>
                            <h5> {traslate("THISCANNOTBEUNDONE")} </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductMarkup;
