import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Switch,
  Table,
  Menu,
  Dropdown,
  Button,
  Space,
  Spin,
} from "antd";
import traslate from "../../../i18n/translate";
import { useSelector, useDispatch } from "react-redux";
import CatalogDiamondDataService from "../../../services/catalog-diamond.service";

const FTPCredential = () => {
  const [ftpData, setFtpData] = useState({
    ftpUrl: "",
    userName: "",
    password: "",
  });

  const { ftpUrl, userName, password, isActive } = ftpData;
  const [dealerId, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    debugger;
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleMangeFtpDetails(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  const handleMangeFtpDetails = (Id) => {
    try {
      let inputData = {
        dealerID: Id,
      };
      setloading(true);
      CatalogDiamondDataService.MangeFtpDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setloading(false);
              setFtpData({
                ftpUrl: responseData[0].path,
                userName: responseData[0].userName,
                password: responseData[0].password,
              });
            } else {
              setFtpData({
                ftpUrl: "",
                userName: "",
                password: "",
              });
              setloading(false);
            }
          } else {
            setFtpData({
              ftpUrl: "",
              userName: "",
              password: "",
            });
            setloading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setloading(false);
        });
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  /* Custom Binding Ends */
  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv data__maping diamond__mapping">
              <Spin spinning={loading} size="large">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="workarea__heading mt-0">
                      {" "}
                      {traslate("ManageFTPCredential")}{" "}
                    </h4>
                  </div>

                  <div className="col-md-6">
                    <div className="input__block">
                      <lable> {traslate("FTPURL")} </lable>
                      <input
                        type="text"
                        placeholder="Enter Inventory"
                        name="ftpUrl"
                        value={ftpUrl}
                      />
                    </div>

                    <div className="input__block">
                      <label> {traslate("Username")} </label>
                      <input
                        type="text"
                        placeholder="Enter UserName"
                        name="userName"
                        value={userName}
                      />
                    </div>

                    <div className="input__block">
                      <label> {traslate("Password")} </label>
                      <input
                        type="text"
                        placeholder="Enter Password"
                        name="password"
                        value={password}
                      />
                    </div>
                  </div>
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FTPCredential;
