import { http } from "../helper/http-common";
class AdminCampaignsService {
    GetMailingList() {
        return http.get("/Campaign/GetMailingList");
      }
      GetRecepientList(inputData) {
        return http.post("/Campaign/GetRecepientList",inputData);
      }
      GetRecepientListByMailingListName(inputData) {
        return http.post("/Campaign/GetRecepientListByMailingListName",inputData);
      }
      SendCampaignMail(inputData) {
      
        return http.post("/Campaign/SendCampaignMail",inputData);
      }
      GetCampaignList() {
       
        return http.get("/Campaign/GetCampaignList");
      }
      GetMailingListByCampaignName(inputData) {
      
        return http.post("/Campaign/GetMailingListByCampaignName",inputData);
      }
}

export default new AdminCampaignsService();