import React, { useState, useEffect, useRef } from "react";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";

import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Switch,
  Table,
  Menu,
  Dropdown,
  Button,
  Space,
  Spin,
} from "antd";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import traslate from "../../../i18n/translate";

const VendorRatings = () => {
  const [vendorRatingData, setVendorRatingData] = useState([]);
  const ref = useRef(null);
  const [cursor, setCursor] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(
    (e) => {
      const input = ref.current;
      if (input) input.setSelectionRange(cursor, cursor);
    },
    [ref, vendorRatingData]
  );

  useEffect(() => {
    handleGetVendorRatingList();
  }, []);

  const handleGetVendorRatingList = () => {
    try {
      setLoading(true);
      AdminToolsDataService.GetVendorRatingList()
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setVendorRatingData(responseData);
          } else {
            setVendorRatingData([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleInputChange = (row, name, e) => {
    setCursor(e.target.selectionStart);
    var tempvalue = e.target.value
      .replace(/[^0-9.-]/g, "")
      .replace(/(\..*)\./g, "$1")
      .replace(/(\-.*)\-/g, "$1");
    vendorRatingData.filter((x) => x.id === row.id)[0].manualRating = tempvalue;
    setVendorRatingData([...vendorRatingData]);
  };

  const handleSave = (row) => {
    try {
      let inputData = {
        id: row.id,
        vendorID: row.vendorID,
        systemRating: row.systemRating,
        manualRating: parseFloat(row.manualRating),
        dealerId: 720,
        ipAddress: "",
      };
      setLoading(true);
      console.log(inputData);
      AdminToolsDataService.SaveVendorRating(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          if (message == "Success") {
            NotificationManager.success("Vendor Data Added Successfully.");
            handleGetVendorRatingList();
          } else {
            NotificationManager.success("Vendor Data Not Added");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      <div className="vendorate_section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {traslate("VendorRatings")}
              </h4>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="vendor_tbldiv">
                <Spin spinning={loading} size="large">
                  <Table
                    columns={[
                      {
                        dataIndex: "vendorID",
                        title: traslate("VendorID"),
                        width: 100,
                      },
                      {
                        dataIndex: "dealerName",
                        title: traslate("VendorName"),
                      },
                      {
                        dataIndex: "systemRating",
                        title: traslate("SystemRatings"),
                        width: 150,
                      },
                      {
                        dataIndex: "manualRating",
                        title: traslate("ManualRatings"),
                        render: (item, row) => {
                          return (
                            <input
                              inputmode="text"
                              ref={ref}
                              value={row.manualRating}
                              onChange={(e) =>
                                handleInputChange(row, "manualRating", e)
                              }
                            />
                          );
                        },
                      },
                      {
                        dataIndex: "dateModified",
                        title: traslate("ModifiedDate"),
                      },

                      {
                        title: traslate("SaveVendorRating"),
                        key: "action",
                        render: (text, row) => (
                          <Space size="middle">
                            <button
                              className="primary-btn"
                              onClick={() => {
                                handleSave(row);
                              }}
                            >
                              {traslate("Save")}
                            </button>
                          </Space>
                        ),
                      },
                    ]}
                    dataSource={vendorRatingData}
                    scroll={{ x: 900 }}
                  />
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VendorRatings;
