import React, { useState, useEffect } from "react";
import { Select, Button, Tooltip, Modal } from "antd";
import { ProgressBar } from "react-bootstrap";

import userIcon from "../../assets/images/user-icon.png";
import UnionIcon from "../../assets/images/icons/Union_5.svg";
import DownloadBox from "../../assets/images/download_sample_icon.png";
import HelpBook from "../../assets/images/help_docs.png";
import CatalogJewelryDataService from "../../services/catalog-jewelry.service";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { httpCatalog } from "../../helper/http-common";
import traslate from "../../i18n/translate";

const UploadProductFile = () => {
  const initialState = {
    ImagefileName: null,
    Imagefile: [],
    DatafileName: null,
    Datafile: [],
    Delimiter: null,
    IsServer: false,
    rbtnRemove: false,
    Error: {},
  };
  const [state, setState] = useState(initialState);
  const [submitLoading1, setSubmitLoading1] = useState(false);
  const [submitLoading2, setSubmitLoading2] = useState(false);
  const [DealerId, setDealerId] = useState(null);
  const [DealerUseName, setDealerDealerUseName] = useState(null);
  const { Option } = Select;
  const [imageMessage1, setImageMessage1] = useState(false);
  const [imageMessage2, setImageMessage2] = useState(false);
  const [imageProgress, setImageProgress] = useState();
  const [progressStart, setProgressStart] = useState(false);
  const [dataMessage1, setDataMessage1] = useState(false);
  const [dataMessage2, setDataMessage2] = useState(false);

  const [progressFileName, setProgressFileName] = useState(null);

  const location = useLocation();
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  useEffect(() => {
    if (location.state) {
      if (location.state.dealerData) {
        setDealerId(location.state.dealerData.dealerID);
      }
    } else {
      if (loginDetials) {
        if (loginDetials.responseData) {
          setDealerId(loginDetials.responseData.dealerId);
          //setDealerDealerUseName(loginDetials.responseData.userName);
        }
      }
    }
  }, []);
  const loadImg = (
    <span>
      {traslate("Ifyouwanttoaddjewelryimagesdirectfromserverlike:https://www.gemfind.com/zoomimages/80785.jpgthenmarkthischeckboxaschecked.AlsoverifythatyouhavealsosetcolumnIsImageFromUrlinyourfile.Ifyouhaveset1tothiscolumnthenimagewillloaddirectfromserverandifyouhaveset0thenimagepathwillbetreatedassimple")}      
    </span>
  );

  const replaceDetail = (
    <span>
      {" "}
      {traslate("InventorywithDealerStock#notincludedintheuploadwillbedeleted.")}      
    </span>
  );

  const addDetail = (
    <span>
      {" "}
      {traslate("InventorywithNewDealerStock#willbeaddedandexistingDealerStock#willbeupdated.DealerStock#notincludedwillremainuntouched.")}      
    </span>
  );
  const DropDownData = [
    {
      dataID: "1",
      dataName: "Data1",
    },
    {
      dataID: "2",
      dataName: "Data2",
    },
    {
      dataID: "3",
      dataName: "Data3",
    },
  ];

  function onChangeDrop(e, name) {
    let tempState = state;

    tempState[name] = e;
    tempState.Error["Delimiter"] = null;
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  }
  function onChangeCheck(e, name) {
    let tempState = state;

    tempState[name] = e.currentTarget.checked;

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  }
  function onChangeRadio(e, name) {
    let tempState = state;
    if (name === "ReplaceAll") {
      // alert("This option will override the data.");
      NotificationManager.error("This option will override the data.");
      tempState["rbtnRemove"] = true;
    } else {
      tempState["rbtnRemove"] = false;
    }

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  }

  const handleValidation = (upName) => {
    var isValid = true;
    if (upName === "Upload1") {
      if (state.Imagefile === null) {
        state.Error["Imagefile"] = "Please select image.";
        isValid = false;
      } else {
        if (state.Imagefile.length === 0) {
          state.Error["Imagefile"] = "Please select image.";
          isValid = false;
        }
      }
    } else if (upName === "Upload2") {
      if (state.Datafile === null) {
        state.Error["Datafile"] = "Please select file.";
        isValid = false;
      } else {
        if (state.Datafile.length === 0) {
          state.Error["Datafile"] = "Please select file.";
          isValid = false;
        }
      }
      if (state.Delimiter === null) {
        state.Error["Delimiter"] = "Please select delimiter.";
        isValid = false;
      }
    }

    setState((prevState) => ({
      ...prevState,
      ...state,
    }));

    return isValid;
  };

  const fileUpload = async (e, name) => {
    let tempState = state;
    var allFiles = [];
    var selectedFiles = e.target.files[0];
    if (name == "Image") {
      if (e.target.files[0].name.match(/.(jpg|jpeg|png|gif|zip|rar)$/i)) {
        if (selectedFiles) {
          allFiles.push(selectedFiles);

          // tempState["Image"] = URL.createObjectURL(selectedFiles);
          tempState["Imagefile"] = allFiles;
          tempState["ImagefileName"] = allFiles[0].name;
          tempState.Error["Imagefile"] = null;
          setProgressFileName(allFiles[0].name);
        }
      } else {
        tempState.Error["Imagefile"] = "Please select image file only.";
        tempState["Imagefile"] = null;
        tempState["ImagefileName"] = null;
      }
    } else if (name == "Data") {
      if (e.target.files[0].name.match(/.(csv|xls|txt)$/i)) {
        if (selectedFiles) {
          allFiles.push(selectedFiles);
          // tempState["Image"] = URL.createObjectURL(selectedFiles);
          tempState["Datafile"] = allFiles;
          tempState["DatafileName"] = allFiles[0].name;
          tempState.Error["Datafile"] = null;
          setProgressFileName(allFiles[0].name);
        }
      } else {
        tempState.Error["Datafile"] = "Please select .csv|xls|txt file only.";
        tempState["DatafileName"] = null;
        tempState["Datafile"] = null;
      }
    }

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };
  const option = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.round((100 * loaded) / total);
      setImageProgress(percent);
    },
  };
  // const onUploadProgress= data => {
  //   //Set the progress value to show the progress bar
  //   setImageProgress(Math.round((100 * data.loaded) / data.total))
  // };

  const handleUploadJewelryImageFiles = (name) => {
    if (handleValidation(name)) {
      setSubmitLoading1(true);
      setImageMessage1(true);
      setProgressStart(true);
      const formData = new FormData();

      formData.append("file", state.Imagefile[0]);
      formData.append("dealerId", DealerId);
      formData.append("fileType", "");

      const headers = {
        "Content-Type": "multipart/form-data",
      };

      try {
        httpCatalog
          .post(
            "/Jewelry/UploadJewelryImageFiles",
            formData,

            option
          )
          .then((response) => {
            var message = response.data.message;
            var responseData = response.data.responseData;

            setSubmitLoading1(false);
            setImageMessage1(false);
            if (message == "Success") {
              NotificationManager.success("File Uploded Succssfully.");

              state["Imagefile"] = null;
              state["ImagefileName"] = null;
              state.Error["Imagefile"] = null;

              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
              setProgressStart(false);
              setImageMessage2(true);
            } else {
              NotificationManager.error("File Not Uploded.");
              state["Imagefile"] = null;
              state["ImagefileName"] = null;
              state.Error["Imagefile"] = null;
              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
            }
          })
          .catch((error) => {
            setSubmitLoading1(false);
            setProgressStart(false);
            setImageMessage1(false);
            console.log(error);
          });
      } catch (error) {
        setSubmitLoading1(false);
        setProgressStart(false);
        setImageMessage1(false);
        console.log(error);
      }
    }
  };

  const handleUploadJewelryDataFile = (name) => {
    if (handleValidation(name)) {
      setSubmitLoading2(true);
      const formData = new FormData();

      formData.append("file", state.Datafile[0]);
      formData.append("dealerId", DealerId);
      formData.append("fileType", "");
      formData.append("userName", ""); //DealerUseName
      formData.append("rbtnRemoveJew", state.rbtnRemove);
      formData.append("SummaryIDJewLog", 0);
      formData.append("SummaryIDJew", 0);
      formData.append("ddlDELIMITERJew", state.Delimiter);
      formData.append("chkflgIsServer", state.IsServer);
      formData.append("PriceType", "");
      formData.append("IsManufacturer", false);
      try {
        CatalogJewelryDataService.UploadJewelryDataFile(formData)
          .then((response) => {
            var message = response.data.message;
            var responseData = response.data.responseData;

            setSubmitLoading2(false);
            if (message == "Success") {
              NotificationManager.success("File Uploded Succssfully.");

              state["Datafile"] = null;
              state["DatafileName"] = null;
              state.Error["Datafile"] = null;
              state["Delimiter"] = null;
              state["IsServer"] = false;
              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
            } else {
              NotificationManager.error("File Not Uploaded.");
              state["Datafile"] = null;
              state["DatafileName"] = null;
              state.Error["Datafile"] = null;
              state["Delimiter"] = null;
              state["IsServer"] = false;
              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
            }
          })
          .catch((error) => {
            setSubmitLoading2(false);
            console.log(error);
          });
      } catch (error) {
        setSubmitLoading2(false);
        console.log(error);
      }
    }
  };

  const handleUploadJewelryDataFile1 = (name) => {
    if (handleValidation(name)) {
      setSubmitLoading2(true);
      setDataMessage1(true);
      setProgressStart(true);
      const formData = new FormData();

      formData.append("file", state.Datafile[0]);
      formData.append("dealerId", DealerId);
      formData.append("fileType", "");
      formData.append("userName", ""); //DealerUseName
      formData.append("rbtnRemoveJew", state.rbtnRemove);
      formData.append("SummaryIDJewLog", 0);
      formData.append("SummaryIDJew", 0);
      formData.append("ddlDELIMITERJew", state.Delimiter);
      formData.append("chkflgIsServer", state.IsServer);
      formData.append("PriceType", "");
      formData.append("IsManufacturer", false);
      try {
        httpCatalog
          .post(
            "/Jewelry/UploadJewelryDataFile",
            formData,

            option
          )
          .then((response) => {
            var message = response.data.message;
            var responseData = response.data.responseData;

            setSubmitLoading2(false);
            setDataMessage1(false);
            if (message == "Success") {
              NotificationManager.success("File Uploded Succssfully.");

              state["Datafile"] = null;
              state["DatafileName"] = null;
              state.Error["Datafile"] = null;
              state["Delimiter"] = null;
              state["IsServer"] = false;
              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
              setProgressStart(false);
              setDataMessage2(true);
            } else {
              NotificationManager.error("File Not Uploaded.");
              state["Datafile"] = null;
              state["DatafileName"] = null;
              state.Error["Datafile"] = null;
              state["Delimiter"] = null;
              state["IsServer"] = false;
              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
            }
          })
          .catch((error) => {
            setSubmitLoading2(false);
            setProgressStart(false);
            setDataMessage1(false);
            console.log(error);
          });
      } catch (error) {
        setSubmitLoading2(false);
        setProgressStart(false);
        setDataMessage1(false);
        console.log(error);
      }
    }
  };

  const handleUploadJewelryImageFiles1 = (name) => {
    if (handleValidation(name)) {
      setSubmitLoading1(true);
      setImageMessage1(true);
      setProgressStart(true);
      const formData = new FormData();

      formData.append("file", state.Imagefile[0]);
      formData.append("dealerId", DealerId);
      formData.append("fileType", "");
      try {
        CatalogJewelryDataService.UploadJewelryImageFiles(formData)
          .then((response) => {
            var message = response.data.message;
            var responseData = response.data.responseData;

            setSubmitLoading1(false);
            setImageMessage1(false);
            if (message == "Success") {
              NotificationManager.success("File Uploded Succssfully.");

              state["Imagefile"] = null;
              state["ImagefileName"] = null;
              state.Error["Imagefile"] = null;

              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
              setProgressStart(false);
              setImageMessage2(true);
            } else {
              NotificationManager.error("File Not Uploded.");
              state["Imagefile"] = null;
              state["ImagefileName"] = null;
              state.Error["Imagefile"] = null;
              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
            }
          })
          .catch((error) => {
            setSubmitLoading1(false);
            setProgressStart(false);
            setImageMessage1(false);
            console.log(error);
          });
      } catch (error) {
        setSubmitLoading1(false);
        setProgressStart(false);
        setImageMessage1(false);
        console.log(error);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv data__maping uplaod__file">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="workarea__heading mt-0"> {traslate("UploadYourImages")} </h4>
                </div>
                <div className="col-lg-8">
                  <div className="input__block">
                    {/* <label> Upload Logo Image for Designers</label> */}
                    <div className="col-md-12 image_block">
                      <div class="row">
                        <div class="col-lg-5 col-md-12">
                          <div className="divimage_block">
                            <label
                              htmlFor="file-upload"
                              class="primary-btn choose_file_upload"
                            >
                              {traslate("ChooseImage")}
                            </label>
                            <input
                              id="file-upload"
                              type="file"
                              onChange={(e) => fileUpload(e, "Image")}
                            />
                            {state.Error ? (
                              <div className="text-danger">
                                {state.Error["Imagefile"]}
                              </div>
                            ) : null}
                            {imageMessage1 ? (<span className=""> {traslate("PleasewaitwhilefileisUploading..")} </span>) : null}
                            <span className="mb-4">
                            {traslate("NoFileChoosen")}{" "}
                              <span className="text-red">*</span>
                            </span>
                            <span className="">{state.ImagefileName}</span>
                            <span className=""> 500 x 500 {traslate("Pixels")} </span>
                            <span className=""> {traslate("Jpg/PngFormat")} </span>
                          </div>

                          <div className="preview__img choose_file_divimg d-none">
                            <img src={userIcon} alt="img" />
                          </div>
                        </div>
                        <div class="col-lg-7 col-md-12">
                          <p>
                          - {traslate("Compressimagestogether&uploadina.zipformat.")}
                          </p>
                          <p > - {traslate("Backgroundsshouldbewhiteortransparent.")} </p>
                          <p>
                          - {traslate("Imagesshouldbelargerthan500x500pixels.")}
                          </p>
                          <p>
                          - {traslate("Productsshouldoccupymorethan80%ofcanvas.")}
                          </p>
                          <div className="help-doc-sp">
                            <img src={HelpBook} width="15px" height="15px" />
                            <p>
                            {traslate("Questions?Viewthe")}
                              <a
                                href="https://gemfindtwostorage.blob.core.windows.net/gemfind/StaticFiles/UploadJewelry/ImageGuideline%20(1).pdf"
                                target="_blank"
                                download
                              > {traslate("ImageGuidelines")} </a>
                              {traslate("HelpDoc")}.
                            </p>
                          </div>

                          <Button
                            className="primary-btn"
                            onClick={(e) =>
                              handleUploadJewelryImageFiles("Upload1")
                            }
                            loading={submitLoading1}
                          >
                            {traslate("UploadData")}
                          </Button>
                          {imageMessage2 ? (
                            <>
                              <p className="mt-2 mb-0 text-success"> {traslate("YourFileisInProgress")}. </p>
                              <Link
                                to={"/UploadHistory"}
                                //state={{ dealerData: item }}
                              >
                                <span className=""> {traslate("Pleaseclickheretoseehistory")}. </span>
                              </Link>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="input__block">
                    <h4 className="workarea__heading"> {traslate("UploadYourDataFile")} </h4>
                    <div className="col-md-12 image_block">
                      <div class="row">
                        <div class="col-lg-5 col-md-12">
                          <div className="divimage_block">
                            <label
                              htmlFor="file-upload1"
                              class="primary-btn choose_file_upload"
                            >
                              {traslate("ChooseFile")}
                            </label>
                            <input
                              id="file-upload1"
                              type="file"
                              onChange={(e) => fileUpload(e, "Data")}
                            />
                            {state.Error ? (
                              <div className="text-danger">
                                {state.Error["Datafile"]}
                              </div>
                            ) : null}

                            <span className="mb-4">
                            {traslate("NoFileChoosen")}{" "}
                              <span className="text-red">*</span>
                            </span>
                            <span> {traslate("Xls/Txt/CsvFormat")} </span>
                            <span className="">{state.DatafileName}</span>
                          </div>

                          <div className="choose_file_divimg d-none">
                            <img src={userIcon} alt="img" />
                            <span>x</span>
                          </div>
                        </div>
                        <div class="col-lg-7 col-md-12">
                          <div>
                            <Select
                              className="border__grey"
                              optionFilterProp="children"
                              placeholder="Comma"
                              value={state.Delimiter}
                              onChange={(e) => onChangeDrop(e, "Delimiter")}
                            >
                              <Option value="">Select</Option>
                              <Option value="COMMA">Comma</Option>
                              <Option value="TAB">Tab</Option>
                            </Select>
                            {state.Error ? (
                              <div className="text-danger">
                                {state.Error["Delimiter"]}
                              </div>
                            ) : null}
                            <div className="load__img">
                              <label>
                                <input
                                  type="checkbox"
                                  value={state.IsServer}
                                  onChange={(e) => onChangeCheck(e, "IsServer")}
                                />
                                <span> {traslate("LoadImagesFromMyServer")} </span>
                                <Tooltip placement="right" title={loadImg}>
                                  <img src={UnionIcon} alt="" />
                                </Tooltip>
                              </label>
                            </div>

                            <div className="radio__block">
                              <div className="radio__btn">
                                <input
                                  type="radio"
                                  id="dr"
                                  name="mt"
                                  className="mr-1"
                                  onChange={(e) =>
                                    onChangeRadio(e, "ReplaceAll")
                                  }
                                />
                                <label htmlFor="dr">
                                {traslate("ReplaceAll")}
                                  <Tooltip
                                    placement="right"
                                    title={replaceDetail}
                                  >
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip>
                                </label>
                              </div>
                              <div className="radio__btn">
                                <input
                                  type="radio"
                                  id="jr"
                                  name="mt"
                                  className="mr-1"
                                  defaultChecked
                                  onChange={(e) =>
                                    onChangeRadio(e, "AddUpdate")
                                  }
                                />
                                <label htmlFor="jr">
                                {traslate("Add&Update")}
                                  <Tooltip placement="right" title={addDetail}>
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip>
                                </label>
                              </div>
                            </div>
                            <div className="help-doc-sp">
                              <img src={HelpBook} width="15px" height="15px" />
                              <p>
                              {traslate("Questions?Viewthe")} <a href="#"> {traslate("JewelryImport")} </a> {traslate("HelpDoc")}.
                              </p>
                            </div>
                            <Button
                              className="primary-btn"
                              onClick={(e) =>
                                handleUploadJewelryDataFile("Upload2")
                              }
                              loading={submitLoading2}
                            >
                              {traslate("UploadData")}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="right__sec">
                    <h5>
                    {traslate("Addnewjewelryitemsinbulkormakemassitemupdates.")}
                    </h5>
                    <h5> {traslate("Firsttimeimporting?")} </h5>
                    <ul>
                      <li>
                        <b> {traslate("ImportYourContent")} </b>
                        <p>
                        {traslate("Choose&uploadyourdataorconnecttoa3rdparty")}.
                        </p>
                      </li>
                      <li>
                        <b> {traslate("WaitForConfirmation")} </b>
                        <p>
                        {traslate("Youwillreceiveane-mailconfirmationwhenyourcontenthasbeenuploaded.")}
                        </p>
                      </li>
                      <li>
                        <b> {traslate("MapYourContent")} </b>
                        <p>
                        {traslate("MatchyourcategorieswiththeJewelCloudindustrystandardmakingyourcontentcompatiblewithournetworkandapplications.")}
                        </p>
                        <p> {traslate("Requiredifyourdatafileisnotinourformat.")} </p>
                      </li>
                    </ul>
                    <br />
                    <div className="row download__block">
                      <h5> {traslate("DownloadTemplates")} </h5>
                      <br />
                      <div className="col-md-2">
                        <img src={DownloadBox} />
                      </div>
                      <div className="col-md-10">
                        <a
                          //href="GuideLines/JC Data Form - Required Fields.xlsx"
                          href="https://gemfindtwostorage.blob.core.windows.net/gemfind/StaticFiles/UploadJewelry/JC%20Data%20Form%20-%20Required%20Fields.xlsx"
                          //target="_blank"
                          download
                        >
                          {traslate("RequiredFieldsForm")}
                        </a>
                        <br />
                        <a
                          href="https://gemfindtwostorage.blob.core.windows.net/gemfind/StaticFiles/UploadJewelry/JC%20Data%20Form%20-%20Simple%20Product.xlsx"
                          // target="_blank"
                          download
                        >
                          {traslate("SimpleProductForms")}{" "}
                        </a>
                        <br />
                        <a
                          href="https://gemfindtwostorage.blob.core.windows.net/gemfind/StaticFiles/UploadJewelry/JC%20Data%20Form%20-%20Ring%20Builder.xlsx"
                          // target="_blank"
                          download
                        >
                          {traslate("RingBuilderDataForm")}{" "}
                        </a>
                        <br />
                        <a
                          href="https://gemfindtwostorage.blob.core.windows.net/gemfind/StaticFiles/UploadJewelry/JC%20Data%20Form%20-%20Configurable%20Product.xlsx"
                          //target="_blank"
                          download
                        >
                          {traslate("ConfigurableProductForm")}
                        </a>
                      </div>

                      <br />
                    </div>
                    <br />
                    <div className="row download__block">
                      <h5> {traslate("HelpDocs")} </h5>
                      <br />
                      <div className="col-md-2">
                        <img src={HelpBook} />
                      </div>
                      <div className="col-md-10 ">
                        <a
                          href="https://gemfindtwostorage.blob.core.windows.net/gemfind/StaticFiles/UploadJewelry/ImageGuideline%20(1).pdf"
                          //target="_blank"
                          download
                        >

                          {traslate("ImageGuideline")}
                        </a>
                        <br />
                      </div>

                      <br />
                    </div>
                  </div>
                </div>
              </div>

              {/* <button onClick={()=> setProgressStart(true)}>Progress Modal</button> */}
              <Modal
                title={traslate("Progress")}
                className="modalconsupld__section"
                centered
                visible={progressStart}
                onOk={() => setProgressStart(false)}
                onCancel={() => setProgressStart(false)}
                width={400}
                footer={[
                  <Button key="back" onClick={()=> setProgressStart(false)}
                  >
                    {traslate("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => setProgressStart(false)}
                  >
                    {traslate("Ok")}
                  </Button>
                ]}
              >
                <div>

                  <ProgressBar now={imageProgress} label={`${imageProgress}%`} />
                  <span> {traslate("Uploaded")}  {imageProgress}%  {traslate("Total")}  100%</span><br></br>
                  <span> {traslate("Uploadingfile")}: {progressFileName}</span>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UploadProductFile;
