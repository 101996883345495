import React, { useState, useRef, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Slider,
  Tooltip,
  Table,
  Modal
} from "antd";
import jcd_emaraldsmall from "../../assets/images/jcd_emaraldsmall.png";
import jcd_dealer from "../../assets/images/jcd_dealer.png";
import emerald from "../../assets/images/emerald.jpg";
import Roundimg from "../../assets/images/Round_65x65.jpg";
import Blue from "../../assets/images/Blue.png";
import imageIcon from "../../assets/images/imageIcon.png";
import Filter_icon from "../../assets/images/filter_icon.png";
import MultiRangeSlider from "../contacts/MultiRangeSlider";
import CatalogDiamondDataService from "../../services/catalog-diamond.service";
import { width } from "dom-helpers";
import traslate from "../../i18n/translate";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

const ColorDiamondSearch = () => {

  const [permissionsForDiamondCatalogList, setPermissionsForDiamondCatalogList] = useState([]);
  const [dealersForDiamondCatalogList, setDealersForDiamondCatalogList] = useState([]);
  const [fancyDiamondList, setFancyDiamondList] = useState([]);
  const [certificatesForDiamondCatalogList, setCertificatesForDiamondCatalogList] = useState([]);
  const [overToneForDiamondCatalogList, setOverToneForDiamondCatalogList] = useState([]);
  const [fancyIntensityList, setFancyIntensityList] = useState([]);
  const [locationsForDiamondCatalogList, setLocationsForDiamondCatalogList] = useState([]);
  const [diamondPolishList, setDiamondPolishList] = useState([]);
  const [diamondSymmetryList, setDiamondSymmetryList] = useState([]);
  const [diamondCutGradeList, setDiamondCutGradeList] = useState([]);
  const [diamondClarityList, setClarityList] = useState([]);
  const [diamondFluorescenceColorList, setDiamondFluorescenceColorList] = useState([]);
  const [disableBtn, setDisableBtn] = useState(true);

  const [caratSlideValue, setCaratSlideValue] = useState([0, 22]);
  const [overallPriceValue, setOverallPriceValue] = useState([0, 100000]);
  const [pricePerCaratValue, setPricePerCaratValue] = useState([0, 100000]);
  const [lWRatioMeasurementsValue, setLRatioMeasurementsValue] = useState([
    0, 10,
  ]);
  const [dynamicData, setDynamicData] = useState([]);

  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;
  const [hideShowFilter, setHideShowFilter] = useState(true);

  
  // const [selectedItem, setSelectedItem] = useState(0);
  // const [selectedRows, setSelectedRows] = useState([]);

  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);

  const [ViewMyColorData, setViewMyColorData] = useState({
    colors: [],
    colorIsSelected: {
      Blue: false,
      Orange: false,
      Green: false,
      Yellow: false,
      Red: false,
      Maroon: false,
      All: false,
    },
    Shapes: [],
    ShapeIsSelected: {
      Round: false,
      Oval: false,
      Circle: false,
      Heart: false,
      Emarald: false,
      Radiant: false,
      All: false,
    },
    permission: null,
    minCarat: 0,
    maxCarat: 22,
    fancyIntensity: null,
    overtone1: [],
    overtone2: [],
    clarityFrom: null,
    clarityTo: null,
    polishTo: null,
    polishFrom: null,
    symmetryFrom: null,
    symmetryTo: null,
    cutFrom: null,
    cutTo: null,
    minOverallPrice: 0,
    maxOverallPrice: 100000,
    minPriceperCaretRange: 0,
    maxPriceperCaretRange: 100000,
    minLWRatioMeasurements: 0,
    maxLWRatioMeasurements: 10,
    location: null,
    eyeClean: [],
    fluorescenceIntensityFrom: null,
    fluorescenceIntensityTo: null,
    fluorescenceColor: [],
    dealers: [],
    certificates: [],
    lengthFrom: null,
    lengthTo: null,
    widthFrom: null,
    widthTo: null,
    heightFrom: null,
    heightTo: null,

    Radio: "",
    shade: [],
    centralInclusionRange: [],
    blackInclusionRange: [],
    milkyInclusionRange: [],
    girdleRangeFrom: null,
    girdleRangeTo: null,
    crownAngleRangeFrom: "",
    crownAngleRangeTo: "",
    pavilionAngleRangeFrom: "",
    pavilionAngleRangeTo: "",
    keytoSymbols: [],
    culetRangeFrom: null,
    culetRangeTo: null,
    culetConditionRangeFrom: null,
    culetConditionRangeTo: null,
    treatment: [],
    depthFrom: "",
    depthTo: "",
    tableFrom: "",
    tableTo: "",
    matchedPairs: [],
    hasVideo: true,
  });
  

  const handleOnChangeRadio = (event) => {
    setViewMyColorData({
      ...ViewMyColorData,
      Radio: event.target.name,
    });
    console.log(event.target.name);
  };
  const handleOnChange = (e, isSelect, name) => {
    setDisableBtn(false);
    if (isSelect === "select") {
      setViewMyColorData({
        ...ViewMyColorData,
        [name]: e,
      });
    } else {
      setViewMyColorData({
        ...ViewMyColorData,
        [e.target.name]: e.target.value,
      });
    }
    // console.log(e, isSelect, name);
  };

  const onNumberChange = (e, name) => {
    // debugger
    let tempState = { ...ViewMyColorData };
    tempState[name] = e.target.value;

    setViewMyColorData((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk1 = () => {
    setVisible1(false);
  };

  const handleCancel1 = () => {
    setVisible1(false);
  };

  const handleOk2 = () => {
    setVisible2(false);
  };

  const handleCancel2 = () => {
    setVisible2(false);
  };

  const style = <span>content not provided.</span>;

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      for (let i = 0; i < selectedRows.length; i++) {
        let selectrowforapi = selectedRows[i].intDiamondId;
        console.log(selectedRows);
      }
    },
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };
  //Table Rows Checkbox Logic
  const [selectedRows, setSelectedRows] = useState([]);

  // const rowSelection = {
  //   onChange: (_, selectedRows) => {
  //     setSelectedRows(selectedRows);
  //     console.log(selectedRows);
  //   },

  //   selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  // };

  const columns = [
    // {
    //   title: <input type="checkbox" id="0" />,
    //   dataIndex: "chkbox",
    //   width: 40
    // },
    {
      title: "",
      dataIndex: "view",
      width: 40
    },
    {
      title: traslate("Stock#"),
      dataIndex: "stock"
    },
    {
      title: traslate("Shape"),
      dataIndex: "shape"
    },
    {
      title: traslate("Size"),
      dataIndex: "size"
    },
    {
      title: traslate("Color"),
      dataIndex: "color"
    },
    {
      title: traslate("Clarity"),
      dataIndex: "clarity"
    },
    {
      title: traslate("Polish"),
      dataIndex: "polish"
    },
    {
      title: traslate("price(Cost)"),
      dataIndex: "cost"
    },
    {
      title: traslate("Dep."),
      dataIndex: "dep"
    },
    {
      title: traslate("Table"),
      dataIndex: "table"
    },
    {
      title: traslate("Seller"),
      dataIndex: "seller"
    },
    {
      title: traslate("Location"),
      dataIndex: "location"
    },
    {
      title: traslate("Image/Video"),
      dataIndex: "imgvid"
    },
  ];

  const data = [
    {
      key: "1",
      chkbox: <input type="checkbox" id="1" />,
      view: <div className="infoicon__div" onClick={setVisible}><i className="fa fa-info-circle"></i></div>,
      stock: <span>G36053</span>,
      shape: (
        <span>
          <img src={jcd_emaraldsmall} alt="" /> Emerald
        </span>
      ),
      size: <span>0.25</span>,
      color: <span>Fancy</span>,
      clarity: <span>VS1</span>,
      polish: <span>VG</span>,
      cost: <span>$161.25</span>,
      dep: <span>74%</span>,
      table: <span>83%</span>,
      seller: (
        <span className="dealer__txt" onClick={setVisible1}>
          <img src={jcd_dealer} alt="" /> 2875
        </span>
      ),
      location: <span>USA</span>,
      imgvid: <div className="vidicn__div" onClick={setVisible2}><i className="fa fa-video-camera"></i></div>
    },
    {
      key: "2",
      chkbox: <input type="checkbox" id="2" />,
      view: <div className="infoicon__div" onClick={setVisible}><i className="fa fa-info-circle"></i></div>,
      stock: <span>G36053</span>,
      shape: (
        <span>
          <img src={jcd_emaraldsmall} alt="" /> Emerald
        </span>
      ),
      size: <span>0.25</span>,
      color: <span>Fancy</span>,
      clarity: <span>VS1</span>,
      polish: <span>VG</span>,
      cost: <span>$161.25</span>,
      dep: <span>74%</span>,
      table: <span>83%</span>,
      seller: (
        <span className="dealer__txt" onClick={setVisible1}>
          <img src={jcd_dealer} alt="" /> 2875
        </span>
      ),
      location: <span>USA</span>,
      imgvid: <div className="vidicn__div" onClick={setVisible2}><i className="fa fa-video-camera"></i></div>
    },
    {
      key: "3",
      chkbox: <input type="checkbox" id="3" />,
      view: <div className="infoicon__div" onClick={setVisible}><i className="fa fa-info-circle"></i></div>,
      stock: <span>G36053</span>,
      shape: (
        <span>
          <img src={jcd_emaraldsmall} alt="" /> Emerald
        </span>
      ),
      size: <span>0.25</span>,
      color: <span>Fancy</span>,
      clarity: <span>VS1</span>,
      polish: <span>VG</span>,
      cost: <span>$161.25</span>,
      dep: <span>74%</span>,
      table: <span>83%</span>,
      seller: (
        <span className="dealer__txt" onClick={setVisible1}>
          <img src={jcd_dealer} alt="" /> 2875
        </span>
      ),
      location: <span>USA</span>,
      imgvid: <div className="vidicn__div" onClick={setVisible2}><i className="fa fa-video-camera"></i></div>
    },
    {
      key: "4",
      chkbox: <input type="checkbox" id="4" />,
      view: <div className="infoicon__div" onClick={setVisible}><i className="fa fa-info-circle"></i></div>,
      stock: <span>G36053</span>,
      shape: (
        <span>
          <img src={jcd_emaraldsmall} alt="" /> Emerald
        </span>
      ),
      size: <span>0.25</span>,
      color: <span>Fancy</span>,
      clarity: <span>VS1</span>,
      polish: <span>VG</span>,
      cost: <span>$161.25</span>,
      dep: <span>74%</span>,
      table: <span>83%</span>,
      seller: (
        <span className="dealer__txt" onClick={setVisible1}>
          <img src={jcd_dealer} alt="" /> 2875
        </span>
      ),
      location: <span>USA</span>,
      imgvid: (
      <div className="vidicn__div" onClick={setVisible2}><i className="fa fa-video-camera"></i>
      </div>
      ),
    }
  ];

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };

  const handleHideShowFilter = () => {
    setHideShowFilter(!hideShowFilter);
  };

  /*Custom Binding Starts*/

  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [dealerId, setDealerId] = useState(0);
  useEffect(() => {
    // debugger
    if (loginDetials) {
      if (loginDetials.responseData) {
        setDealerId(loginDetials.responseData.dealerId);
        handleGetFancyDiamondList(loginDetials.responseData.dealerId);
        handleGetDealersForDiamondCatalogList(loginDetials.responseData.dealerId);
      }
    }
  }, []);

  useEffect(() => {
    handleGetPermissionsForDiamondCatalogList();
    handleGetCertificatesForDiamondCatalogList();
    handleGetOverToneForDiamondCatalogList();
    handleGetAllFancyDropDownList();
    handleGetLocationsForDiamondCatalogList();
  }, []);

  const handleDeleteDiamondByIds = () => {
    try {
      let inputData = {
        dealerId: dealerId,
        diamondIds: 0,
      };
      CatalogDiamondDataService
        .DeleteDiamondByIds(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleSendInviteMailForDiamonds = () => {
    try {
      let inputData = {
        dealerId: 0,
        diamondDetailList: [],
        name: "",
        eMail: "",
        markUp: 0,
        message: "",
        mailType: "",
      };
      CatalogDiamondDataService
        .SendInviteMailForDiamonds(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetFancyDiamondList = (id) => {
    try {
      // let inputData = {
      //   "dealerID": id,
      //   "shapes": (shapesData === ',') ? '' : shapesData,
      //   "colorMin": "string",
      //   "colorMax": "string",
      //   "clarityMin": (ViewMyColorData.clarityFrom === null) ? "" : (ViewMyColorData.clarityFrom),
      //   "clarityMax": (ViewMyColorData.clarityTo === null) ? "" : (ViewMyColorData.clarityTo),
      //   "caratMin": (ViewMyColorData.minCarat).toString(),
      //   "caratMax": (ViewMyColorData.maxCarat).toString(),
      //   "priceMin": "string",
      //   "priceMax": "string",
      //   "certificate": (ViewMyColorData.certificates).toString(),
      //   "cutGradeMin": (ViewMyColorData.cutFrom === null) ? "" : (ViewMyColorData.cutFrom),
      //   "cutGradeMax": (ViewMyColorData.cutTo === null) ? "" : (ViewMyColorData.cutTo),
      //   "symmetryMin": (ViewMyColorData.symmetryFrom === null) ? "" : (ViewMyColorData.symmetryFrom),
      //   "symmetryMax": (ViewMyColorData.symmetryTo === null) ? "" : (ViewMyColorData.symmetryTo),
      //   "polishMin": (ViewMyColorData.polishFrom === null) ? "" : (ViewMyColorData.polishFrom),
      //   "polishMax": (ViewMyColorData.polishTo === null) ? "" : (ViewMyColorData.polishTo),
      //   "depthMin": ViewMyColorData.depthFrom,
      //   "depthMax": ViewMyColorData.depthTo,
      //   "tableMin": ViewMyColorData.tableFrom,
      //   "tableMax": ViewMyColorData.tableTo,
      //   "fluorescenceMin": (ViewMyColorData.fluorescenceIntensityFrom === null) ? "" : (ViewMyColorData.fluorescenceIntensityFrom),
      //   "fluorescenceMax": (ViewMyColorData.fluorescenceIntensityTo === null) ? "" : (ViewMyColorData.fluorescenceIntensityTo),
      //   "pairCode": "string",
      //   "orderBy": "string",
      //   "orderType": "string",
      //   "pageNumber": "string",
      //   "pageSize": "string",
      //   "invenID": "string",
      //   "strDetailLinkURL": "string",
      //   "did": "string",
      //   "caratPriceMin": (ViewMyColorData.minCarat).toString(),
      //   "caratPriceMax": (ViewMyColorData.maxCarat).toString(),
      //   "ratioMin": (ViewMyColorData.minLWRatioMeasurements).toString(),
      //   "ratioMax": (ViewMyColorData.maxLWRatioMeasurements).toString(),
      //   "polishList": "string",
      //   "symmetryList": "string",
      //   "fluorescenceList": "string",
      //   "cutGradeList": "string",
      //   "colorList": "string",
      //   "clarityList": "string",
      //   "sOrigin": "string",
      //   "intOptimize": 0,
      //   "intCulet1": (ViewMyColorData.culetRangeFrom === null) ? 0 : parseInt(ViewMyColorData.culetRangeFrom),
      //   "intCulet2": (ViewMyColorData.culetRangeTo === null) ? 0 : parseInt(ViewMyColorData.culetRangeTo),
      //   "intCuletCondition1": (ViewMyColorData.culetConditionRangeFrom === null) ? 0 : parseInt(ViewMyColorData.culetConditionRangeFrom),
      //   "intCuletCondition2": (ViewMyColorData.culetConditionRangeTo === null) ? 0 : parseInt(ViewMyColorData.culetConditionRangeTo),
      //   "fltCrownAngle1": (ViewMyColorData.crownAngleRangeFrom === "") ? 0 : parseInt(ViewMyColorData.crownAngleRangeFrom),
      //   "fltCrownAngle2": (ViewMyColorData.crownAngleRangeTo === "") ? 0 : parseInt(ViewMyColorData.crownAngleRangeTo),
      //   "fltPavilionAngle1": (ViewMyColorData.pavilionAngleRangeFrom === "") ? 0 : parseInt(ViewMyColorData.pavilionAngleRangeFrom),
      //   "fltPavilionAngle2": (ViewMyColorData.pavilionAngleRangeTo === "") ? 0 : parseInt(ViewMyColorData.pavilionAngleRangeTo),
      //   "intGirdle1": (ViewMyColorData.girdleRangeFrom === null) ? 0 : parseInt(ViewMyColorData.girdleRangeFrom),
      //   "intGirdle2": (ViewMyColorData.girdleRangeTo === null) ? 0 : parseInt(ViewMyColorData.girdleRangeTo),
      //   "intCutGrade": 0,
      //   "uploadDateMin": "string",
      //   "uploadDateMax": "string",
      //   "strSelectedDealers": (ViewMyColorData.dealers).toString(),
      //   "selectedDealerConunt": 0,
      //   // "fancyColor": colorData,
      //   "fancyColor": (colorData === ',') ? '' : colorData,
      //   "intIntensity": (ViewMyColorData.fancyIntensity === null) ? 0 : parseInt(ViewMyColorData.fancyIntensity),
      //   "overtone": (ViewMyColorData.overtone1).toString(),
      //   "overtone2": (ViewMyColorData.overtone2).toString(),
      //   "strMatchedPairs": "string",
      //   "fltWidth1": (ViewMyColorData.widthFrom === null) ? "" : (ViewMyColorData.widthFrom),
      //   "fltWidth2": (ViewMyColorData.widthTo === null) ? "" : (ViewMyColorData.widthTo),
      //   "fltHeight1": (ViewMyColorData.heightFrom === null) ? "" : (ViewMyColorData.heightFrom),
      //   "fltHeight2": (ViewMyColorData.heightTo === null) ? "" : (ViewMyColorData.heightTo),
      //   "fltLength1": (ViewMyColorData.lengthFrom === null) ? "" : (ViewMyColorData.lengthFrom),
      //   "fltLength2": (ViewMyColorData.lengthTo === null) ? "" : (ViewMyColorData.lengthTo),
      //   "strSelectedInvID": "string",
      //   "flagDD": "string",
      //   "checkInventory": "string",
      //   "country": (ViewMyColorData.location === null) ? "" : (ViewMyColorData.location),
      //   "shade": (ViewMyColorData.shade).toString(),
      //   "treatment": (ViewMyColorData.treatment).toString(),
      //   "keytosymbol": (ViewMyColorData.keytoSymbols).toString(),
      //   "fluorescenceColor": (ViewMyColorData.fluorescenceColor).toString(),
      //   "blackInclusion": (ViewMyColorData.blackInclusionRange).toString(),
      //   "centralInclusion": (ViewMyColorData.centralInclusionRange).toString(),
      //   "milkyInclusion": (ViewMyColorData.milkyInclusionRange).toString(),
      //   "eyeclean": (ViewMyColorData.eyeClean).toString(),
      //   "ratioType": ViewMyColorData.Radio,
      //   "permission": (ViewMyColorData.permission === null) ? "" : (ViewMyColorData.fancyIntensity),
      //   "video": ViewMyColorData.hasVideo === true ? "1" : "0"
      // }
      let inputData = {
        "dealerID": 720,
        "shapes": "",
        "colorMin": "-1",
        "colorMax": "-1",
        "clarityMin": "-1",
        "clarityMax": "-1",
        "caratMin": "0",
        "caratMax": "20",
        "priceMin": "0",
        "priceMax": "1000000",
        "certificate": "",
        "cutGradeMin": "-1",
        "cutGradeMax": "-1",
        "symmetryMin": "-1",
        "symmetryMax": "-1",
        "polishMin": "-1",
        "polishMax": "-1",
        "depthMin": "-1",
        "depthMax": "-1",
        "tableMin": "-1",
        "tableMax": "-1",
        "fluorescenceMin": "-1",
        "fluorescenceMax": "-1",
        "pairCode": "",
        "orderBy": "tmpColumn Asc",
        "orderType": "Asc",
        "pageNumber": "1",
        "pageSize": "50",
        "invenID": "",
        "strDetailLinkURL": "dealerlink",
        "did": "",
        "caratPriceMin": "0",
        "caratPriceMax": "100000",
        "ratioMin": "0",
        "ratioMax": "1000",
        "polishList": "-1",
        "symmetryList": "-1",
        "fluorescenceList": "-1",
        "cutGradeList": "-1",
        "colorList": "-1",
        "clarityList": "-1",
        "sOrigin": "",
        "intOptimize": 0,
        "intCulet1": 0,
        "intCulet2": 20,
        "intCuletCondition1": 0,
        "intCuletCondition2": 20,
        "fltCrownAngle1": 0,
        "fltCrownAngle2": 100,
        "fltPavilionAngle1": 0,
        "fltPavilionAngle2": 100,
        "intGirdle1": 0,
        "intGirdle2": 100,
        "intCutGrade": 0,
        "uploadDateMin": "",
        "uploadDateMax": "",
        "strSelectedDealers": "720",
        "selectedDealerConunt": 1,
        "fancyColor": "",
        "intIntensity": -1,
        "overtone": "",
        "overtone2": "",
        "strMatchedPairs": "0",
        "fltWidth1": "-1",
        "fltWidth2": "-1",
        "fltHeight1": "-1",
        "fltHeight2": "-1",
        "fltLength1": "-1",
        "fltLength2": "-1",
        "strSelectedInvID": "",
        "flagDD": "0",
        "checkInventory": "Catalog",
        "country": "",
        "shade": "",
        "treatment": "",
        "keytosymbol": "",
        "fluorescenceColor": "",
        "blackInclusion": "",
        "centralInclusion": "",
        "milkyInclusion": "",
        "eyeclean": "",
        "ratioType": "",
        "permission": "",
        "video": ""
      }
      CatalogDiamondDataService.GetFancyDiamondList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData.result;
          if (message == "Success") {
            if (responseData) {
              setFancyDiamondList(responseData);
            } else {
              setFancyDiamondList([]);
            }
          } else {
            setFancyDiamondList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetPermissionsForDiamondCatalogList = () => {
    try {
      let inputData = {
        type: ""
      };
      CatalogDiamondDataService.GetPermissionsForDiamondCatalogList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setPermissionsForDiamondCatalogList(responseData);
            } else {
              setPermissionsForDiamondCatalogList([]);
            }
          } else {
            setPermissionsForDiamondCatalogList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetLocationsForDiamondCatalogList = () => {
    // debugger
    try {
      let inputData = {
        type: ""
      };
      CatalogDiamondDataService.GetLocationsForDiamondCatalogList(inputData)
        .then((response) => {
          // debugger
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setLocationsForDiamondCatalogList(responseData);
            } else {
              setLocationsForDiamondCatalogList([]);
            }
          } else {
            setLocationsForDiamondCatalogList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDynamicData = (id) => {
    try {
      let inputData = {
        dealerId: id,
      };
      CatalogDiamondDataService.GetDynamicData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setDynamicData(responseData);
            } else {
              setDynamicData([]);
            }
          } else {
            setDynamicData([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDealersForDiamondCatalogList = (id) => {
    try {
      let inputData = {
        type: "Fancy",
        dealerId: id,
      };
      CatalogDiamondDataService.GetDealersForDiamondCatalogList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setDealersForDiamondCatalogList(responseData);
            } else {
              setDealersForDiamondCatalogList([]);
            }
          } else {
            setDealersForDiamondCatalogList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetCertificatesForDiamondCatalogList = () => {
    try {
      let inputData = {
        type: ""
      };
      CatalogDiamondDataService.GetCertificatesForDiamondCatalogList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setCertificatesForDiamondCatalogList(responseData);
            } else {
              setCertificatesForDiamondCatalogList([]);
            }
          } else {
            setCertificatesForDiamondCatalogList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetOverToneForDiamondCatalogList = () => {
    try {
      let inputData = {
        type: ""
      };
      CatalogDiamondDataService.GetOverToneForDiamondCatalogList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setOverToneForDiamondCatalogList(responseData);
            } else {
              setOverToneForDiamondCatalogList([]);
            }
          } else {
            setOverToneForDiamondCatalogList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetAllFancyDropDownList = () => {
    try {
      CatalogDiamondDataService.GetAllFancyDropDownList()
        .then((response) => {
          // debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;
          var fancyIntensity = response.data.responseData.diamondIntensity;
          var diamondPolish = response.data.responseData.diamondPolish;
          var diamondSymmetry = response.data.responseData.diamondSymmetry;
          var diamondCutGrade = response.data.responseData.diamondCutGrade;
          var clarity = response.data.responseData.clarity;
          var diamondFluorescenceColor = response.data.responseData.diamondFluorescenceColor;

          if (message == "Success") {
            if (responseData) {
              setFancyIntensityList(fancyIntensity);
              setDiamondPolishList(diamondPolish);
              setDiamondSymmetryList(diamondSymmetry);
              setDiamondCutGradeList(diamondCutGrade);
              setClarityList(clarity);
              setDiamondFluorescenceColorList(diamondFluorescenceColor);

            } else {
              setFancyIntensityList([]);
              setDiamondPolishList([]);
            }
          } else {
            setFancyIntensityList([]);
            setDiamondPolishList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const applyFilters = () => {
    handleGetFancyDiamondList(720);
    console.log(ViewMyColorData);
  }


  //Initial Watch Data
  

  const resetFilters = () => {
    setViewMyColorData(
      {
        colors: [],
        colorIsSelected: {
          Blue: false,
          Orange: false,
          Green: false,
          Yellow: false,
          Red: false,
          Maroon: false,
          All: false,
        },
        Shapes: [],
        ShapeIsSelected: {
          Round: false,
          Oval: false,
          Circle: false,
          Heart: false,
          Emarald: false,
          Radiant: false,
          All: false,
        },
        permission: null,
        minCarat: 0,
        maxCarat: 20,
        fancyIntensity: null,
        overtone1: [],
        overtone2: [],
        clarityFrom: null,
        clarityTo: null,
        polishTo: null,
        polishFrom: null,
        symmetryFrom: null,
        symmetryTo: null,
        cutFrom: null,
        cutTo: null,
        minOverallPrice: 0,
        maxOverallPrice: 100000,
        minPriceperCaretRange: 0,
        maxPriceperCaretRange: 100000,
        location: null,
        eyeClean: [],
        fluorescenceIntensityFrom: null,
        fluorescenceIntensityTo: null,
        fluorescenceColor: [],
        dealers: [],
        certificates: [],
        lengthFrom: null,
        lengthTo: null,
        widthFrom: null,
        widthTo: null,
        heightFrom: null,
        heightTo: null,
        minLWRatioMeasurements: 0,
        maxLWRatioMeasurements: 10,
        Radio: "",
        shade: [],
        centralInclusionRange: [],
        blackInclusionRange: [],
        milkyInclusionRange: [],
        girdleRangeFrom: null,
        girdleRangeTo: null,
        crownAngleRangeFrom: "",
        crownAngleRangeTo: "",
        pavilionAngleRangeFrom: "",
        pavilionAngleRangeTo: "",
        keytoSymbols: [],
        culetRangeFrom: null,
        culetRangeTo: null,
        culetConditionRangeFrom: null,
        culetConditionRangeTo: null,
        treatment: [],
        depthFrom: "",
        depthTo: "",
        tableFrom: "",
        tableTo: "",
        matchedPairs: [],
        hasVideo: true,
      }
    );
    setCaratSlideValue([ViewMyColorData.minCarat, ViewMyColorData.maxCarat]);
    setOverallPriceValue([
      ViewMyColorData.minOverallPrice,
      ViewMyColorData.maxOverallPrice,
    ]);
    setPricePerCaratValue([
      ViewMyColorData.minPriceperCaretRange,
      ViewMyColorData.maxPriceperCaretRange,
    ]);
    setLRatioMeasurementsValue([
      ViewMyColorData.minLWRatioMeasurements,
      ViewMyColorData.maxLWRatioMeasurements,
    ]);
  };

  const onShapeClick = (shape) => {
    setDisableBtn(false);
    // debugger;

    if (shape === "All") {
      ViewMyColorData.ShapeIsSelected.All = !ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Circle = ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Emarald = ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Heart = ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Oval = ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Radiant = ViewMyColorData.ShapeIsSelected.All;
      ViewMyColorData.ShapeIsSelected.Round = ViewMyColorData.ShapeIsSelected.All;
    } else {
      ViewMyColorData.ShapeIsSelected.All = false;
      ViewMyColorData.ShapeIsSelected[shape] = !ViewMyColorData.ShapeIsSelected[shape];
    }
    setViewMyColorData((prevState) => ({
      ...prevState,
      ...ViewMyColorData,
    }));
    console.log(ViewMyColorData.Shapes);
  };

  const onColorClick = (color) => {
    setDisableBtn(false);

    if (color === "All") {
      ViewMyColorData.colorIsSelected.All = !ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Blue = ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Orange = ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Green = ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Yellow = ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Red = ViewMyColorData.colorIsSelected.All;
      ViewMyColorData.colorIsSelected.Maroon = ViewMyColorData.colorIsSelected.All;
    } else {
      ViewMyColorData.colorIsSelected.All = false;
      ViewMyColorData.colorIsSelected[color] = !ViewMyColorData.colorIsSelected[color];
    }

    setViewMyColorData((prevState) => ({
      ...prevState,
      ...ViewMyColorData,
    }));
    console.log(ViewMyColorData.colors);
  };

  var shapesObj = ViewMyColorData.ShapeIsSelected;
  var shapesKeys = Object.keys(shapesObj);
  var filteredShape = shapesKeys.filter(function (key) {
    return shapesObj[key];
  });
  var shapesData = filteredShape + ",";
  // console.log(shapesData);

  var colorObj = ViewMyColorData.colorIsSelected;
  var colorKeys = Object.keys(colorObj);
  var filteredColor = colorKeys.filter(function (key) {
    return colorObj[key];
  });
  var colorData = filteredColor + ",";
  console.log(colorData);

  


  /*Converting into String Format ends*/
  



  const resetRange = useRef(null);
  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };
  /*Routing On Table Buttons Starts*/
  let navigate = useNavigate();
  function handleAdd() {
    navigate('/addSignleDiamond');
  };
  function handleUpload() {
    navigate('/uploadDiamondFile');
  };
  /*Routing On Table Buttons Ends*/

  /*Custom Binding Ends*/

  return (
    <React.Fragment>
      <div className="vdiamond_section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="workarea__heading mt-0 mb-0">
                    {traslate("ColorDiamondSearch")}
                    </h4>
                  </div>
                  <div className="col-md-6 ml-auto text-right">
                    <button
                      className="primary-btn ml-2"
                      onClick={handleHideShowFilter}
                    >
                      <img src={Filter_icon} alt="" width="14px" />
                      {hideShowFilter ? (
                        <span>
                          {traslate("ShowFilter")} <i className="fa fa-angle-down"></i>
                        </span>
                      ) : (
                        <span>
                          {traslate("HideFilter")} <i className="fa fa-angle-up"></i>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="filter_section">
                  <div
                    className={
                      hideShowFilter
                        ? "form__fields filter_inpsection hide"
                        : "form__fields filter_inpsection"
                    }
                  >
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Colors")} </label>
                            <div className="imgselct__div">
                              <div 
                              className={ViewMyColorData.colorIsSelected.Blue 
                                ? "selected"
                                : null
                                }
                                onClick={() => onColorClick("Blue")}
                              >
                                <img src={Blue} alt="" />
                                <span> {traslate("Blue")} </span>
                              </div>
                              <div
                              className={ViewMyColorData.colorIsSelected.Orange 
                                ? "selected"
                                : null
                                }
                                onClick={() => onColorClick("Orange")}
                              
                              >
                                <img src={Blue} alt="" />
                                <span> {traslate("Orange")} </span>
                              </div>
                              <div
                              className={ViewMyColorData.colorIsSelected.Green 
                                ? "selected"
                                : null
                                }
                                onClick={() => onColorClick("Green")}
                              >
                                <img src={Blue} alt="" />
                                <span> {traslate("Green")} </span>
                              </div>
                              <div
                              className={ViewMyColorData.colorIsSelected.Yellow 
                                ? "selected"
                                : null
                                }
                                onClick={() => onColorClick("Yellow")}
                              >
                                <img src={Blue} alt="" />
                                <span> {traslate("Yellow")} </span>
                              </div>
                              <div
                              className={ViewMyColorData.colorIsSelected.Red 
                                ? "selected"
                                : null
                                }
                                onClick={() => onColorClick("Red")}
                              >
                                <img src={Blue} alt="" />
                                <span> {traslate("Red")} </span>
                              </div>
                              <div
                              className={ViewMyColorData.colorIsSelected.Maroon 
                                ? "selected"
                                : null
                                }
                                onClick={() => onColorClick("Maroon")}
                              >
                                <img src={Blue} alt="" />
                                <span> {traslate("Maroon")} </span>
                              </div>
                              <div 
                              className={ViewMyColorData.colorIsSelected.All 
                                ? "selected"
                                : null
                                }
                                onClick={() => onColorClick("All")}
                              >
                                <span> {traslate("All")} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Shapes")} </label>
                            <div className="imgselct__div">
                            <div
                                className={ViewMyColorData.ShapeIsSelected.Round 
                                ? "selected"
                                : null
                                }
                                onClick={() => onShapeClick("Round")}
                              >
                                <img src={Roundimg} alt="" />
                                <span> {traslate("Round")} </span>
                              </div>
                              <div
                                className={ViewMyColorData.ShapeIsSelected.Oval
                                ? "selected"
                                : null
                                
                                }
                                onClick={() => onShapeClick("Oval")}
                              >
                                <img src={Roundimg} alt="" />
                                <span> {traslate("Oval")} </span>
                              </div>
                              <div
                                className={ViewMyColorData.ShapeIsSelected.Circle
                                ? "selected"
                                : null
                                }
                                onClick={() => onShapeClick("Circle")}
                              >
                                <img src={Roundimg} alt="" />
                                <span> {traslate("Circel")} </span>
                              </div>
                              <div
                                className={ViewMyColorData.ShapeIsSelected.Heart
                                ? "selected"
                                : null
                                }
                                onClick={() => onShapeClick("Heart")}
                              >
                                <img src={Roundimg} alt="" />
                                <span> {traslate("Heart")} </span>
                              </div>
                              <div
                                className={ViewMyColorData.ShapeIsSelected.Emarald
                                ? "selected"
                                : null
                                }
                                onClick={() => onShapeClick("Emarald")}
                              >
                                <img src={Roundimg} alt="" />
                                <span> {traslate("Emarald")} </span>
                              </div>
                              <div
                                className={ViewMyColorData.ShapeIsSelected.Radiant
                                ? "selected"
                                : null
                                }
                                onClick={() => onShapeClick("Radiant")}
                              >
                                <img src={Roundimg} alt="" />
                                <span> {traslate("Radiant")} </span>
                              </div>
                              <div
                                className={ViewMyColorData.ShapeIsSelected.All
                                ? "selected"
                                : null}
                                onClick={() => onShapeClick("All")}
                              >
                                <span> {traslate("All")} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Permission")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              mode="multiple"
                              showArrow={true}
                              placeholder="Any"
                              name="permission"
                              value={ViewMyColorData.permission ? ViewMyColorData.permission : []}
                              defaultvalue = ""
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "permission")
                              }
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                        
                              {permissionsForDiamondCatalogList.map((item) => {
                                return (
                                  <React.Fragment>
                                    <Option value={item.value}>{item.text}</Option>
                                  </React.Fragment>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="sliderrange__maindiv">
                            <label> {traslate("Carat")} </label>
                            <div className="col-md-12 mt-3">
                              <Slider
                                range
                                value={caratSlideValue}
                                // value={dynamicData}
                                min={ViewMyColorData.minCarat}
                                max={ViewMyColorData.maxCarat}
                                onChange={(e) => {
                                  setCaratSlideValue(e);
                                }}
                              />
                              <div className="rangeSlider__values">
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={caratSlideValue[0]}
                                  />
                                  {/* <span className="dollar__symbol">$</span> */}
                                </div>
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={caratSlideValue[1]}
                                  />
                                  {/* <span className="dollar__symbol">$</span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("FancyIntensity")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              showArrow={true}
                              mode="multiple"
                              placeholder="Any"
                              name="fancyIntensity"
                              value={ViewMyColorData.fancyIntensity ? ViewMyColorData.fancyIntensity : []}
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "fancyIntensity")
                              }
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              
                              {fancyIntensityList.map((item) => {
                                return (
                                  <React.Fragment>
                                    <Option value={item.intensityValue} key={item.intensityId}>{item.intensityValue}</Option>
                                  </React.Fragment>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Overtone1")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Select Overtone 1"
                              name="overtone1"
                              value={ViewMyColorData.overtone1 ? ViewMyColorData.overtone1 : []}
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "overtone1")
                              }
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              
                              {overToneForDiamondCatalogList.map((item) => {
                                return (
                                  <React.Fragment>
                                    <Option value={item.overtoneValue} key={item.overtoneID}>{item.overtoneValue}</Option>
                                  </React.Fragment>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Overtone2")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              value={ViewMyColorData.overtone2 ? ViewMyColorData.overtone2 : []}
                              placeholder="Select Category"
                              name="overtone2"
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "overtone2")
                              }
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              
                              {overToneForDiamondCatalogList.map((item) => {
                                return (
                                  <React.Fragment>
                                    <Option value={item.overtoneValue} key={item.overtoneID}>{item.overtoneValue}</Option>
                                  </React.Fragment>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt"> {traslate("Clarity")} </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="clarityFrom"
                                  value={ViewMyColorData.clarityFrom ? ViewMyColorData.clarityFrom : null}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "clarityFrom")
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  
                                  {diamondClarityList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <Option value={item.claritydetails} key={item.clarityID}>{item.claritydetails}</Option>
                                      </React.Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="clarityTo"
                                  value={ViewMyColorData.clarityTo ? ViewMyColorData.clarityTo : null}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "clarityTo")
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {/* <Option value="jack">Any</Option>
                                  <Option value="lucy">Ideal</Option>
                                  <Option value="tom">Excellent</Option> */}
                                  {diamondClarityList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <Option value={item.claritydetails} key={item.clarityID}>{item.claritydetails}</Option>
                                      </React.Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt"> {traslate("Polish")} </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="polishFrom"
                                  value={ViewMyColorData.polishFrom ? ViewMyColorData.polishFrom : null}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "polishFrom")
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {diamondPolishList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <Option value={item.polishValue} key={item.polishID}>{item.polishValue}</Option>
                                      </React.Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                   className="border__grey"
                                   showSearch
                                   placeholder="Any"
                                   name="polishTo"
                                   value={ViewMyColorData.polishTo ? ViewMyColorData.polishTo: null}
                                   optionFilterProp="children"
                                   // onChange={onChange}
                                   onChange={(e) =>
                                     handleOnChange(e, "select", "polishTo")
                                   }
                                   onSearch={onSearch}
                                   filterOption={(input, option) =>
                                     option.children
                                       .toLowerCase()
                                       .indexOf(input.toLowerCase()) >= 0
                                   }
                                >
                                  
                                  {diamondPolishList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <Option value={item.polishValue} key={item.polishID}>{item.polishValue}</Option>
                                      </React.Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt"> {traslate("Symmetry")} </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                              <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="symmetryFrom"
                                  value={ViewMyColorData.symmetryFrom ? ViewMyColorData.symmetryFrom : null}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "symmetryFrom")
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {diamondSymmetryList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <Option value={item.symmetryValue} key={item.symmetryID}>{item.symmetryValue}</Option>
                                      </React.Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                              <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="symmetryTo"
                                  value={ViewMyColorData.symmetryTo ? ViewMyColorData.symmetryTo : null}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "symmetryTo")
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {diamondSymmetryList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <Option value={item.symmetryValue} key={item.symmetryID}>{item.symmetryValue}</Option>
                                      </React.Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt"> {traslate("Cut")} </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                              <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="cutFrom"
                                  value={ViewMyColorData.cutFrom ? ViewMyColorData.cutFrom : null}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "cutFrom")
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {diamondCutGradeList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <Option value={item.cutGradeValue} key={item.cutGradeId}>{item.cutGradeValue}</Option>
                                      </React.Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                              <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="cutTo"
                                  value={ViewMyColorData.cutTo ? ViewMyColorData.cutTo : null}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "cutTo")
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {diamondCutGradeList.map((item) => {
                                    return (
                                      <React.Fragment>
                                        <Option value={item.cutGradeValue} key={item.cutGradeId}>{item.cutGradeValue}</Option>
                                      </React.Fragment>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="sliderrange__maindiv">
                            <label> {traslate("OverallPrice")} </label>
                            <div className="col-md-12 mt-3">
                              <Slider
                                range
                                value={overallPriceValue}
                                min={ViewMyColorData.minOverallPrice}
                                max={ViewMyColorData.maxOverallPrice}
                                onChange={(e) => {
                                  setOverallPriceValue(e);
                                }}
                              />
                              <div className="rangeSlider__values">
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={overallPriceValue[0]}
                                  />
                                  {/* <span className="dollar__symbol">$</span> */}
                                </div>
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={overallPriceValue[1]}
                                  />
                                  {/* <span className="dollar__symbol">$</span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="sliderrange__maindiv">
                            <label> {traslate("PriceperCaretRange")} </label>
                            <div className="col-md-12 mt-3">
                              <Slider
                                range
                                value={pricePerCaratValue}
                                min={ViewMyColorData.minPriceperCaretRange}
                                max={ViewMyColorData.maxPriceperCaretRange}
                                onChange={(e) => {
                                  setPricePerCaratValue(e);
                                }}
                              />
                              <div className="rangeSlider__values">
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={pricePerCaratValue[0]}
                                  />
                                  {/* <span className="dollar__symbol">$</span> */}
                                </div>
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={pricePerCaratValue[1]}
                                  />
                                  {/* <span className="dollar__symbol">$</span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Location")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Any"
                              name="location"
                              optionFilterProp="children"
                              value={ViewMyColorData.location ? ViewMyColorData.location : null}
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "location")
                              }
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {locationsForDiamondCatalogList.map((item) => {
                                return (
                                  <React.Fragment>
                                    <Option value={item.name} key={item.countryID}>{item.name}</Option>
                                  </React.Fragment>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("EyeClean")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Any"
                              name="eyeClean"
                              value={ViewMyColorData.eyeClean ? ViewMyColorData.eyeClean : []}
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "eyeClean")
                              }
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option value="0">Any</Option>
                              <Option value="1">Yes</Option>
                              <Option value="2">Borderline</Option>
                              <Option value="3">No</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt"> {traslate("FluorescenceIntensity")} </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                              <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="fluorescenceIntensityFrom"
                                  value={ViewMyColorData.fluorescenceIntensityFrom ? ViewMyColorData.fluorescenceIntensityFrom : []}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(
                                      e,
                                      "select",
                                      "fluorescenceIntensityFrom"
                                    )
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  <Option value="0">Any</Option>
                                  <Option value="1">None</Option>
                                  <Option value="2">Very Slight</Option>
                                  <Option value="3">Slight</Option>
                                  <Option value="4">Faint</Option>
                                  <Option value="5">Medium</Option>
                                  <Option value="6">Strong</Option>
                                  <Option value="7">Very Strong</Option>
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                              <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="fluorescenceIntensityTo"
                                  value={ViewMyColorData.fluorescenceIntensityTo ? ViewMyColorData.fluorescenceIntensityTo : []}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(
                                      e,
                                      "select",
                                      "fluorescenceIntensityTo"
                                    )
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  <Option value="0">Any</Option>
                                  <Option value="1">None</Option>
                                  <Option value="2">Very Slight</Option>
                                  <Option value="3">Slight</Option>
                                  <Option value="4">Faint</Option>
                                  <Option value="5">Medium</Option>
                                  <Option value="6">Strong</Option>
                                  <Option value="7">Very Strong</Option>
                                </Select>
                              
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("FluorescenceColor")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Any"
                              name="fluorescenceColor"
                              value={ViewMyColorData.fluorescenceColor ? ViewMyColorData.fluorescenceColor : null}
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "fluorescenceColor")
                              }
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {diamondFluorescenceColorList.map((item) => {
                                return (
                                  <React.Fragment>
                                    <Option value={item.fluorescenceValue} key={item.fluorescenceID}>{item.fluorescenceValue}</Option>
                                  </React.Fragment>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Dealers")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              showArrow={true}
                              mode="multiple"
                              placeholder="Any"
                              name="dealers"
                              value={ViewMyColorData.dealers ? ViewMyColorData.dealers : null}
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "dealers")
                              }
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {dealersForDiamondCatalogList.map((item) => {
                                return (
                                  <React.Fragment>
                                    {/* <Option value={item.dealerCompany} key={item.dealerId}>{item.dealerCompany}</Option> */}
                                    <Option value={item.dealerID} key={item.dealerID}>
                                      {item.dealerCompany + " (" + item.dealerID + " )"}
                                    </Option>
                                  </React.Fragment>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Certificates")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              showArrow={true}
                              mode="multiple"
                              placeholder="Any"
                              name="certificates"
                              value={ViewMyColorData.certificates ? ViewMyColorData.certificates : null}
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "certificates")
                              }
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {certificatesForDiamondCatalogList.map((item) => {
                                return (
                                  <React.Fragment>
                                    <Option value={item.certificateID} key={item.certificateID}>{item.certificateValue}</Option>
                                  </React.Fragment>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-2">
                      <div className="row">
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt"> {traslate("Length(mm)")} </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="lengthFrom"
                                  value={ViewMyColorData.lengthFrom ? ViewMyColorData.lengthFrom : []}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "lengthFrom")
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  <Option>Any</Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="lengthTo"
                                  value={ViewMyColorData.lengthTo ? ViewMyColorData.lengthTo : []}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "lengthTo")
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  <Option>Any</Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt"> {traslate("Width(mm)")} </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="widthFrom"
                                  value={ViewMyColorData.widthFrom ? ViewMyColorData.widthFrom : []}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "widthFrom")
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  <Option>Any</Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="widthTo"
                                  value={ViewMyColorData.widthTo ? ViewMyColorData.widthTo : []}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "widthTo")
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  
                                >
                                  <Option>Any</Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt"> {traslate("height(mm)")} </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="heightFrom"
                                  value={ViewMyColorData.heightFrom ? ViewMyColorData.heightFrom : []}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "heightFrom")
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="heightTo"
                                  value={ViewMyColorData.heightTo ? ViewMyColorData.heightTo : []}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "heightTo")
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="sliderrange__maindiv">
                            <label> {traslate("L/WRatioMeasurements")} </label>
                            <div className="col-md-12 mt-3">
                              <Slider
                                range
                                value={lWRatioMeasurementsValue}
                                min={ViewMyColorData.minLWRatioMeasurements}
                                max={ViewMyColorData.maxLWRatioMeasurements}
                                onChange={(e) => {
                                  setLRatioMeasurementsValue(e);
                                }}
                              />
                              <div className="rangeSlider__values">
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={lWRatioMeasurementsValue[0]}
                                  />
                                  {/* <span className="dollar__symbol">$</span> */}
                                </div>
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={lWRatioMeasurementsValue[1]}
                                  />
                                  {/* <span className="dollar__symbol">$</span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("LWRatio")} </label>
                            <div className="radio__div">
                              <div>
                                <input
                                  type="radio"
                                  id="lwRatioAny"
                                  name="Any"
                                  value={ViewMyColorData.Radio}
                                  checked={
                                    ViewMyColorData.Radio === "Any"
                                      ? true
                                      : false
                                  }
                                  onChange={handleOnChangeRadio}
                                />
                                <label for="any"> {traslate("Any")} </label>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  id="lwRatioSquare"
                                  name="Square"
                                  value={ViewMyColorData.Radio}
                                  checked={
                                    ViewMyColorData.Radio === "Square"
                                      ? true
                                      : false
                                  }
                                  onChange={handleOnChangeRadio}
                                />
                                <label for="sqr"> {traslate("Square")} </label>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  id="lwRatioRec"
                                  name="Rectangle"
                                  value={ViewMyColorData.Radio}
                                  checked={
                                    ViewMyColorData.Radio === "Rectangle"
                                      ? true
                                      : false
                                  }
                                  onChange={handleOnChangeRadio}
                                />
                                <label for="rec">
                                  {" "}
                                  {traslate("Rectangle")}{" "}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Shade")}  </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Any"
                              name="shade"
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "shade")
                              }
                              value={ViewMyColorData.shade ? ViewMyColorData.shade : null}
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option value="0">Any</Option>
                              <Option value="1">None</Option>
                              <Option value="2">No Yellow</Option>
                              <Option value="3">No Green</Option>
                              <Option value="4">No Grey</Option>
                              <Option value="5">No Black</Option>
                              <Option value="6">No Pink</Option>
                              <Option value="7">No Blue</Option>
                              <Option value="8">No Brown</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("CentralInclusionRange")}  </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Any"
                              name="centralInclusionRange"
                              value={ViewMyColorData.centralInclusionRange ? ViewMyColorData.centralInclusionRange : []}
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(
                                  e,
                                  "select",
                                  "centralInclusionRange"
                                )
                              }
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option value="0">Any</Option>
                              <Option value="1">None</Option>
                              <Option value="2">Light</Option>
                              <Option value="3">Medium</Option>
                              <Option value="4">Heavy</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("BlackInclusionRange")}  </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Any"
                              name="blackInclusionRange"
                              value={ViewMyColorData.blackInclusionRange ? ViewMyColorData.blackInclusionRange : []}
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(
                                  e,
                                  "select",
                                  "blackInclusionRange"
                                )
                              }
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option value="0">Any</Option>
                              <Option value="1">None</Option>
                              <Option value="2">Light</Option>
                              <Option value="3">Medium</Option>
                              <Option value="4">Heavy</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("MilkyInclusionRange")}  </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Any"
                              name="milkyInclusionRange"
                              value={ViewMyColorData.milkyInclusionRange ? ViewMyColorData.milkyInclusionRange : []}
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(
                                  e,
                                  "select",
                                  "milkyInclusionRange"
                                )
                              }
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option value="0">Any</Option>
                              <Option value="1">None</Option>
                              <Option value="2">Light</Option>
                              <Option value="3">Medium</Option>
                              <Option value="4">Heavy</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt"> {traslate("GirdleRange")} </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="girdleRangeFrom"
                                  value={ViewMyColorData.girdleRangeFrom ? ViewMyColorData.girdleRangeFrom : []}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(
                                      e,
                                      "select",
                                      "girdleRangeFrom"
                                    )
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  <Option value="0">Any</Option>
                                  <Option value="1">Extremely Thin</Option>
                                  <Option value="2">Very Thin</Option>
                                  <Option value="3">Thin</Option>
                                  <Option value="4">Slightly Thin</Option>
                                  <Option value="5">Medium</Option>
                                  <Option value="6">Slightly Thick</Option>
                                  <Option value="7">Thick</Option>
                                  <Option value="8">Very Thick</Option>
                                  <Option value="9">Extremely Thick</Option>
                                  <Option value="10">Extra Thin</Option>
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                              <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="girdleRangeTo"
                                  value={ViewMyColorData.girdleRangeTo ? ViewMyColorData.girdleRangeTo : []}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "girdleRangeTo")
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  <Option value="0">Any</Option>
                                  <Option value="1">Extremely Thin</Option>
                                  <Option value="2">Very Thin</Option>
                                  <Option value="3">Thin</Option>
                                  <Option value="4">Slightly Thin</Option>
                                  <Option value="5">Medium</Option>
                                  <Option value="6">Slightly Thick</Option>
                                  <Option value="7">Thick</Option>
                                  <Option value="8">Very Thick</Option>
                                  <Option value="9">Extremely Thick</Option>
                                  <Option value="10">Extra Thin</Option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt"> {traslate("CrownAngle%Range")} </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <input type="number" placeholder="6%" />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <input type="number" placeholder="6%" />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("KeytoSymbols")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Any"
                              name="keytoSymbols"
                              value={ViewMyColorData.keytoSymbols ? ViewMyColorData.keytoSymbols : []}
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "keytoSymbols")
                              }
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option value="0">Any</Option>
                              <Option value="1">Bearding</Option>
                              <Option value="2">Brown Patch of Color</Option>
                              <Option value="3">Bruise</Option>
                              <Option value="4">Cavity</Option>
                              <Option value="5">Chip</Option>
                              <Option value="6">Cleavage</Option>
                              <Option value="7">Cloud</Option>
                              <Option value="8">Crystal</Option>
                              <Option value="9">Crystal Surface</Option>
                              <Option value="10">Etch Channel</Option>
                              <Option value="11">Extra Facet</Option>
                              <Option value="12">Feather</Option>
                              <Option value="13">Flux Remnant</Option>
                              <Option value="14">Indented Natural</Option>
                              <Option value="15">Internal Graining</Option>
                              <Option value="16">Internal Inscription</Option>
                              <Option value="17">Internal Laser Drilling</Option>
                              <Option value="18">Knot</Option>
                              <Option value="19">Laser Drill Hole</Option>
                              <Option value="20">Manufacturing Remnant</Option>
                              <Option value="21">Minor Details of Polish</Option>
                              <Option value="22">Natural</Option>
                              <Option value="23">Needly</Option>
                              <Option value="24">No Inclusion</Option>
                              <Option value="25">Pinpoint</Option>
                              <Option value="26">Reflecting Surface Graining</Option>
                              <Option value="27">Surface Graining</Option>
                              <Option value="28">Twinning Wisp</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt"> {traslate("CuletRange")} </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                              <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="culetRangeFrom"
                                  value={ViewMyColorData.culetRangeFrom ? ViewMyColorData.culetRangeFrom : []}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(
                                      e,
                                      "select",
                                      "culetRangeFrom"
                                    )
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  <Option value="0">Any</Option>
                                  <Option value="1">None</Option>
                                  <Option value="2">Small</Option>
                                  <Option value="3">Very Small</Option>
                                  <Option value="4">Medium</Option>
                                  <Option value="5">Large</Option>
                                  <Option value="6">Very Large</Option>
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                              <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="culetRangeTo"
                                  value={ViewMyColorData.culetRangeTo ? ViewMyColorData.culetRangeTo : []}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "culetRangeTo")
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  <Option value="0">Any</Option>
                                  <Option value="1">None</Option>
                                  <Option value="2">Small</Option>
                                  <Option value="3">Very Small</Option>
                                  <Option value="4">Medium</Option>
                                  <Option value="5">Large</Option>
                                  <Option value="6">Very Large</Option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt"> {traslate("CuletConditionRange")} </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                              <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="culetConditionRangeFrom"
                                  value={ViewMyColorData.culetConditionRangeFrom ? ViewMyColorData.culetConditionRangeFrom : []}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(
                                      e,
                                      "select",
                                      "culetConditionRangeFrom"
                                    )
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  <Option value="0">Any</Option>
                                  <Option value="1">None</Option>
                                  <Option value="2">Abraded</Option>
                                  <Option value="3">Chipped</Option>
                                  <Option value="4">Pointed</Option>
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                              <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  name="culetConditionRangeTo"
                                  value={ViewMyColorData.culetConditionRangeTo ? ViewMyColorData.culetConditionRangeTo : []}
                                  optionFilterProp="children"
                                  // onChange={onChange}
                                  onChange={(e) =>
                                    handleOnChange(
                                      e,
                                      "select",
                                      "culetConditionRangeTo"
                                    )
                                  }
                                  onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  <Option value="0">Any</Option>
                                  <Option value="1">None</Option>
                                  <Option value="2">Abraded</Option>
                                  <Option value="3">Chipped</Option>
                                  <Option value="4">Pointed</Option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Treatment")}  </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Any"
                              name="treatment"
                              value={ViewMyColorData.treatment ? ViewMyColorData.treatment : []}
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "treatment")
                              }
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option value="0">Laser Drilled</Option>
                              <Option value="1">Clarity Enhanced</Option>
                              <Option value="2">Color Enhanced</Option>
                              <Option value="3">Irradiated</Option>
                              <Option value="4">HPHT</Option>
                              <Option value="5">Other</Option>
                              <Option value="6">Any</Option>
                              <Option value="7">None</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt"> {traslate("Depth")} </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <input
                                  type="text"
                                  onKeyPress={onKeyPressEvent}
                                  placeholder="6%"
                                  name="depthFrom"
                                  value={ViewMyColorData.depthFrom}
                                  onChange={(e) => {
                                    e.target.value = e.target.value.slice(0, 3);
                                    onNumberChange(e, "depthFrom")
                                  }
                                  }
                                />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <input
                                  type="text"
                                  onKeyPress={onKeyPressEvent}
                                  placeholder="6%"
                                  name="depthTo"
                                  value={ViewMyColorData.depthTo}
                                  onChange={(e) => {
                                    e.target.value = e.target.value.slice(0, 3);
                                    onNumberChange(e, "depthTo")
                                  }
                                  }
                                />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt"> {traslate("Table")} </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <input
                                  type="text"
                                  onKeyPress={onKeyPressEvent}
                                  placeholder="6%"
                                  name="tableFrom"
                                  value={ViewMyColorData.tableFrom}
                                  onChange={(e) => {
                                    e.target.value = e.target.value.slice(0, 3);
                                    onNumberChange(e, "tableFrom")
                                  }
                                  }
                                />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <input
                                  type="text"
                                  onKeyPress={onKeyPressEvent}
                                  placeholder="6%"
                                  name="tableTo"
                                  value={ViewMyColorData.tableTo}
                                  onChange={(e) => {
                                    e.target.value = e.target.value.slice(0, 3);
                                    onNumberChange(e, "tableTo")
                                  }
                                  }
                                />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("MatchedPairs")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              showArrow={true}
                              mode="multiple"
                              placeholder="Search All Stones"
                              name="matchedPairs"
                              value={ViewMyColorData.matchedPairs ? ViewMyColorData.matchedPairs : []}
                              optionFilterProp="children"
                              // onChange={onChange}
                              onChange={(e) =>
                                handleOnChange(e, "select", "matchedPairs")
                              }
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option value="0">Search All Stones</Option>
                              <Option value="1">Search Single Stone</Option>
                              <Option value="2">Search Match Pairs</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("HasVideo")} </label>
                            <Switch
                              name="hasVideo"
                              checkedChildren="Yes"
                              unCheckedChildren="No"
                              checked={ViewMyColorData.hasVideo}
                              // onChange={(e) => (e, "select", "visibility")}
                              onChange={(e) =>
                                handleOnChange(e, "select", "hasVideo")
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 filtbtn_div">
                          <button 
                          type="submit"
                          className="primary-btn mr-2"
                          onClick={resetFilters}
                          >
                            {" "}
                            {traslate("ResetFilters")}
                          </button>
                          
                          <Button
                            className={disableBtn ? "disabled-btn" : "primary-btn"}
                            disabled={disableBtn}
                            onClick={applyFilters}
                            
                          >
                            {traslate("ApplyFilters")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mt-5">
                <div className="options__div">
                  <div className="row">
                    <div className="col-lg-6 add__div">
                      <span className="diamnd__txt">
                        {traslate("YouHave")}{" "}
                        <span className="txt__no">13131</span>{" "}
                        {traslate("Diamonds")}
                      </span>
                      <button className="primary-btn" onClick={handleAdd}>
                        <i className="fa fa-plus-circle"></i> {traslate("Add")}
                      </button>
                      <button className="primary-btn" onClick={handleUpload}>
                        <i className="fa fa-cloud-upload"></i>{" "}
                        {traslate("Upload")}
                      </button>
                      <button className="primary-btn" onClick={() => {
                        resetFilters();
                        handleGetFancyDiamondList(720);
                      }}>
                        <i className="fa fa-rotate-left"></i>{" "}
                        {traslate("Reset")}
                      </button>
                    </div>
                    <div className="col-lg-6 print__div">
                      <span className="select__txt">
                        <i class="fa fa-list-ul"></i> {selectedRows.length}{" "}
                        {traslate("Selected")}
                      </span>
                      <button className="primary-btn">
                        <i className="fa fa-eye"></i> {traslate("View")}
                      </button>
                      <button className="primary-btn">
                        <i className="fa fa-print"></i> {traslate("Print")}
                      </button>
                      <button className="primary-btn">
                        <i className="fa fa-envelope"></i> {traslate("Send")}
                      </button>
                      <button className="primary-btn">
                        <i className="fa fa-trash-o"></i> {traslate("Delete")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                {/*there is a search filter in react for table*/}
                <Table
                  className="vdiamond__table"
                  // columns={columns}
                  columns={[
                    {
                      title: traslate("Stock#"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "txtStockNo",
                      key: "txtStockNo",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 150,
                    },
                    {
                      title: traslate("Shape"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "txtShape",
                      key: "txtShape",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 150,
                    },
                    {
                      title: traslate("Size"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "txtStockNo",
                      key: "txtStockNo",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 150,
                    },
                    {
                      title: traslate("Color"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "txtColor",
                      key: "txtColor",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 150,
                    },
                    {
                      title: traslate("Clarity"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "txtClarity",
                      key: "txtClarity",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 150,
                    },
                    {
                      title: traslate("Polish"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "txtPolish",
                      key: "txtPolish",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 150,
                    },
                    {
                      title: traslate("price(Cost)"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "txtStockNo",
                      key: "txtStockNo",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 150,
                    },
                    {
                      title: traslate("Dep."),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "fltDepth",
                      key: "fltDepth",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 150,
                    },
                    {
                      title: traslate("Table"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "fltTable",
                      key: "fltTable",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 150,
                    },
                    {
                      title: traslate("Seller"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "txtStockNo",
                      key: "txtStockNo",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 150,
                    },
                    {
                      title: traslate("Location"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "location",
                      key: "location",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 150,
                    },
                    {
                      title: traslate("Image/Video"),
                      // ...getColumnSearchProps("dealerId"),
                      dataIndex: "videoFileName",
                      key: "videoFileName",
                      // sorter: (a, b) => a.dealerId - b.dealerId,
                      width: 150,
                    },

                  ]}
                  dataSource={fancyDiamondList}
                  rowSelection={rowSelection}
                  scroll={{ y: 300 }}
                />
              </div>
              <div className="col-lg-12">
                <Modal
                  title={traslate("ViewDetails")}
                  className="modalvdiamond__section"
                  centered
                  visible={visible}
                  onOk={() => setVisible(false)}
                  onCancel={() => setVisible(false)}
                  width={1000}
                  footer={[
                    <Button key="back" onClick={handleCancel}>
                      {traslate("Cancel")}
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                      {traslate("ContactDealer")}
                    </Button>,
                  ]}
                >
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="row">
                            <div className="col-lg-2 mt-4 text-center">
                              <img src={emerald} />
                            </div>
                            <div className="col-lg-10 mt-4">
                              <div className="info__div">
                                <div>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("GemFind")}#:
                                    </span>{" "}
                                    <span className="val__txt">178443267</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("RetailPrice")}:
                                    </span>{" "}
                                    <span className="val__txt">$290.00</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Color")}:
                                    </span>{" "}
                                    <span className="val__txt">E</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Depth")}:
                                    </span>{" "}
                                    <span className="val__txt">74%</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("CaratWeight")}:
                                    </span>{" "}
                                    <span className="val__txt">0.25</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Symmetry")}:
                                    </span>{" "}
                                    <span className="val__txt">F</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Measurements")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      3.92X3.14X2.33
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Treatment")}:
                                    </span>{" "}
                                    <span className="val__txt"></span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Shape")}:
                                    </span>{" "}
                                    <span className="val__txt">Emerald</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Clarity")}:
                                    </span>{" "}
                                    <span className="val__txt">SI1</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Table")}:
                                    </span>{" "}
                                    <span className="val__txt">83%</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Polish")}:
                                    </span>{" "}
                                    <span className="val__txt">VG</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Crown")}:
                                    </span>{" "}
                                    <span className="val__txt"></span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Lab")}:
                                    </span>{" "}
                                    <a href="#" className="val__txt">
                                      <img src={imageIcon} alt="" />
                                      GIA Website
                                    </a>
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("MatchedPairStock")}#:
                                    </span>{" "}
                                    <span className="val__txt"></span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Seller")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      B&H Diamonds
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Stock")}:
                                    </span>{" "}
                                    <span className="val__txt">G36053</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Phone")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      888-655-4804
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Location")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      Chicago, IL - 60603
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Updated")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      5/4/2021 2:13:28 PM
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                <Modal
                  title={traslate("MessagetoB&HDiamonds")}
                  className="modalvdiamond__section"
                  centered
                  visible={visible1}
                  onOk={() => setVisible1(false)}
                  onCancel={() => setVisible1(false)}
                  width={600}
                  footer={[
                    <Button key="back" onClick={handleCancel1}>
                      {traslate("Cancel")}
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk1}>
                      {traslate("Send")}
                    </Button>,
                  ]}
                >
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="row">
                            <div className="col-lg-12 mt-4">
                              <p>
                                {traslate("Contactthedealerregarding")}.{" "}
                                <a href="#">G36053.</a>
                              </p>
                            </div>
                            <div className="col-lg-12">
                              <div class="input__block">
                                <textarea
                                  id=""
                                  name=""
                                  rows="6"
                                  cols="50"
                                  placeholder="Write your message here..."
                                  class=""
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                <Modal
                  title={traslate("Video")}
                  className="modalvdiamond__section"
                  centered
                  visible={visible2}
                  onOk={() => setVisible2(false)}
                  onCancel={() => setVisible2(false)}
                  width={600}
                  footer={[
                    <Button key="back" onClick={handleCancel2}>
                      {traslate("Close")}
                    </Button>,
                  ]}
                >
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="row">
                            <div className="col-lg-12 mt-4">
                              <iframe
                                id="VideoIframesrc"
                                src="https://certimage.s3-accelerate.amazonaws.com/V360_viewers/v4.0/Vision360.html?surl=https://certimage.s3-accelerate.amazonaws.com/V360/_v4.0/&amp;d=YG-2493&amp;sv=0,1,2,3,4,5"
                                class="VideoIframe"
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ColorDiamondSearch;
