import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table
} from "antd";


import prodImg from "../../../assets/images/prod__img.jpg";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";

import { Doughnut } from "react-chartjs-2";

const WatchCustomizerOptions = () => {

  return (
    <React.Fragment>
      <div className="form__fields">
        <div className="row">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0">
              Watch Customizer Options
              </h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block">
            <label>
                Watch Customizer URL
                <Tooltip
                  placement="right"
                  title="Enter the URL of this tool on your site to enable social sharing and dynamic sizing."
                >
                  <img src={UnionIcon} alt="" />
                </Tooltip>
              </label>
              <input type="text" />
            </div>
          </div>

          <div className="col-md-4 mt-4">
            <div className="input__block">
              <button className="primary-btn mt-1">Update Setting</button>
            </div>
          </div>
          
        </div>
      </div>
    </React.Fragment>
  );
};

export default WatchCustomizerOptions;
