import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import {
    Input,
    Row,
    Col,
    Select,
    Checkbox,
    Button,
    Table,
    Modal,
    DatePicker,
    Switch,
    Space
} from "antd";
import {
    EditOutlined,
    CalendarOutlined,
    SearchOutlined
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";

import userIcon from "../../../assets/images/daimond-bg.png";
import traslate from "../../../i18n/translate";

const { Option } = Select;

export const OutOfNetwork = props => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
    setIsModalVisible(true);
    };
    
    const handleCancel = () => {
    setIsModalVisible(false);
    };

    const columns = [
        {
            title: traslate("Vendor"),
            dataIndex: "vendor",
        },
        {
            title: traslate("Status"),
            dataIndex: "status",
        },
        {
            title: traslate("DataRating"),
            dataIndex: "dataRating",
        },
        {
            title: traslate("Items"),
            dataIndex: "items",
        },
        {
            title: traslate("Updated"),
            dataIndex: "updated",
        },
        {
            title: traslate("Action"),
            dataIndex: "action",
        },

    ];

    const data = [
        {
            key: "1",
            vendor: "A&D Gem Corp",
            status: "Out of Network",
            dataRating:
                <div className='star-ratings'>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star-half-o" aria-hidden="true"></i>
                </div>,
            items: "38",
            updated: "Over 90 Days",
            action: <a href="#" onClick={showModal}>Request Access</a>
        },
        {
            key: "2",
            vendor: "A&D Gem Corp",
            status: "Out of Network",
            dataRating:
                <div className='star-ratings'>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star-half-o" aria-hidden="true"></i>
                </div>,
            items: "38",
            updated: "Over 90 Days",
            action: <a href="#" onClick={showModal}>Request Access</a>
        }
    ];



    return (
        <div className="form__fields">
            <div className="row magento__mapping">
                <div className="col-md-12">
                    <div className="heading__block">
                        <h4 class="workarea__heading mt-0 mb-0"> {traslate("OutOfNetwork")} </h4>
                    </div>
                </div>
                <div className="divForm vendors-section">
                    <Table columns={columns} dataSource={data} scroll={{ x: 600 }} />
                </div>
            </div>

            <Modal
            title={traslate("RetailerAffirmation")}
            visible={isModalVisible}
            className="forgot__password"
            onCancel={handleCancel}
            >
            <div className="form__fields p-0 border-0 mh-auto">
                <div>
                    <div className="radio__block">
                        <input type="checkbox" />
                        <label> {traslate("CurrentAuthorizedReseller")} </label>
                    </div>
                    <p> {traslate("BycheckingthisboxandclickingthesubmitbuttonbelowIherebyaffirmthatGemFindhasanopenandactiveaccountwithA&DGemCorp,thatIamanauthorizedresellerofA&DGemCorp,andthatIampermittedtohavetheirvirtualinventoryonmywebsite.")} </p>
                </div>
                <div>
                    <div className="radio__block">
                        <input type="checkbox" />
                        <label> {traslate("NewAccountRequest")} </label>
                    </div>
                    <p> {traslate("IaminterestedinopeninganewaccountwithA&DGemCorpandbecominganauthorizedreseller.Bycheckingthisboxandclickingthesubmitbuttonbelow,yournameandstoreinformationwillbesenttoA&DGemCorp.")} </p>
                </div>
            </div>
            </Modal>
        </div>         


    );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OutOfNetwork);
