import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table,
  Spin,
} from "antd";
import { Tabs, Tab } from "react-bootstrap";
import traslate from "../../i18n/translate";
import RetailerLogo from "../../assets/images/retailer-logo.jpeg";
import RetailerDataService from "../../services/retailer-retailer.service";
import { NotificationManager } from "react-notifications";
import { useSelector, useDispatch } from "react-redux";

const JewelryRetailerPermission = (props) => {
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [rowDetails, setRowDetails] = useState({});
  const [selectedColumnId, setSelectedColumnId] = useState(0);

  const [MyRetailerData, setMyRetailerData] = useState([
    {
      // key: "1",
      id: 1,
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
      jewelry: true,
      porder: false,
      jewelryapi: true,
    },
    {
      // key: "2",
      id: 2,
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
      jewelry: true,
      porder: false,
      jewelryapi: true,
    },
    {
      //key: "3",
      id: 3,
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
      jewelry: true,
      porder: false,
      jewelryapi: true,
    },
    {
      // key: "4",
      id: 4,
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
      jewelry: true,
      porder: false,
      jewelryapi: true,
    },
  ]);
  const [jewelryList, setJewelryList] = useState([]);
  const [allJewelryList, setAlljewelryList] = useState([]);
  const [dealerId, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);

  useEffect(() => {
    debugger;
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (props.type) {
    } else {
      handleGetMyRetailerList();
      handleGetALLRetailerList();
    }
  }, []);

  const handleGetMyRetailerList = () => {
    try {
      let inputData = {
        dealerID: 2704,
        sales: "",
      };
      setLoading(true);
      RetailerDataService.GetMyRetailerList(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setJewelryList(responseData);
          } else {
            setJewelryList([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleGetALLRetailerList = () => {
    try {
      let inputData = {
        dealerID: 2704,
        sales: "",
      };
      setLoading(true);
      RetailerDataService.GetALLRetailerList(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setAlljewelryList(responseData);
          } else {
            setAlljewelryList([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const style = <span>content not provided.</span>;

  function onSearch(val) {
    console.log("search:", val);
  }

  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };

  const handleEnableDisableIsRestricted = (saveRow) => {
    try {
      let inputData = {
        dealerID: "2704",
        retailerid: saveRow.dealerID,
        type: saveRow.isrestricted === "1" ? true : false,
      };
      RetailerDataService.EnableDisableIsRestricted(inputData)
        .then((response) => {
          var message = response.data.message;
          setLoading(true);
          if (message == "Success") {
            NotificationManager.success("Record Updated Succssfully.");
            handleGetMyRetailerList();
          } else {
            NotificationManager.error("Record Not Updated.");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEnableDisablePrice = (saveRow) => {
    try {
      let inputData = {
        dealerID: "2704",
        retailerid: saveRow.dealerID,
        type: saveRow.pricedisabled === "1" ? true : false,
      };
      RetailerDataService.EnableDisablePrice(inputData)
        .then((response) => {
          var message = response.data.message;
          setLoading(true);
          if (message == "Success") {
            NotificationManager.success("Record Updated Succssfully.");
            handleGetMyRetailerList();
          } else {
            NotificationManager.error("Record Not Updated.");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleEnableDisableMarkup = (saveRow) => {
    try {
      let inputData = {
        dealerID: "2704",
        retailerid: saveRow.dealerID,
        type: saveRow.markuprestricted === "1" ? true : false,
      };
      RetailerDataService.EnableDisableMarkup(inputData)
        .then((response) => {
          var message = response.data.message;
          setLoading(true);
          if (message == "Success") {
            NotificationManager.success("Record Updated Succssfully.");
            handleGetMyRetailerList();
          } else {
            NotificationManager.error("Record Not Updated.");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleEnableDisableRingBuilder = (saveRow) => {
    try {
      let inputData = {
        dealerID: "2704",
        retailerid: saveRow.dealerID,
        type: saveRow.ringbuilder === "1" ? true : false,
      };
      RetailerDataService.EnableDisableRingBuilder(inputData)
        .then((response) => {
          var message = response.data.message;
          setLoading(true);
          if (message == "Success") {
            NotificationManager.success("Record Updated Succssfully.");
            handleGetMyRetailerList();
          } else {
            NotificationManager.error("Record Not Updated.");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleEnableDisableIsAutoPermission = (saveRow) => {
    try {
      let inputData = {
        dealerID: "2704",
        retailerid: saveRow.dealerID,
        type: saveRow.isautopermission === "1" ? true : false,
      };
      RetailerDataService.EnableDisableIsAutoPermission(inputData)
        .then((response) => {
          var message = response.data.message;
          setLoading(true);
          if (message == "Success") {
            NotificationManager.success("Record Updated Succssfully.");
            handleGetMyRetailerList();
          } else {
            NotificationManager.error("Record Not Updated.");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleEnableDisablePurchaseOrder = (saveRow) => {
    try {
      let inputData = {
        dealerID: "2704",
        retailerid: saveRow.dealerID,
        type: saveRow.purchaseoder === "1" ? true : false,
      };
      RetailerDataService.EnableDisablePurchaseOrder(inputData)
        .then((response) => {
          var message = response.data.message;
          setLoading(true);
          if (message == "Success") {
            NotificationManager.success("Record Updated Succssfully.");
            handleGetMyRetailerList();
          } else {
            NotificationManager.error("Record Not Updated.");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (row, e, name) => {
    debugger;
    if (name === "isrestricted") {
      const temp = jewelryList.map((x) => {
        if (x.dealerID === row.dealerID) {
          return { ...x, isrestricted: e == true ? "1" : "0" };
        } else return x;
      });
      let saveRow = temp.filter((obj) => obj.dealerID == row.dealerID)[0];
      setJewelryList(temp);
      console.log(saveRow);
      handleEnableDisableIsRestricted(saveRow);
    }
    if (name === "pricedisabled") {
      const temp = jewelryList.map((x) => {
        if (x.dealerID === row.dealerID) {
          return { ...x, pricedisabled: e == true ? "1" : "0" };
        } else return x;
      });
      let saveRow = temp.filter((obj) => obj.dealerID == row.dealerID)[0];
      setJewelryList(temp);
      console.log(saveRow);
      handleEnableDisablePrice(saveRow);
    }
    if (name === "markuprestricted") {
      const temp = jewelryList.map((x) => {
        if (x.dealerID === row.dealerID) {
          return { ...x, markuprestricted: e == true ? "1" : "0" };
        } else return x;
      });
      let saveRow = temp.filter((obj) => obj.dealerID == row.dealerID)[0];
      setJewelryList(temp);
      console.log(saveRow);
      handleEnableDisableMarkup(saveRow);
    }
    if (name === "ringbuilder") {
      const temp = jewelryList.map((x) => {
        if (x.dealerID === row.dealerID) {
          return { ...x, ringbuilder: e == true ? "1" : "0" };
        } else return x;
      });
      let saveRow = temp.filter((obj) => obj.dealerID == row.dealerID)[0];
      setJewelryList(temp);
      console.log(saveRow);
      handleEnableDisableRingBuilder(saveRow);
    }
    if (name === "isautopermission") {
      const temp = jewelryList.map((x) => {
        if (x.dealerID === row.dealerID) {
          return { ...x, isautopermission: e == true ? "1" : "0" };
        } else return x;
      });
      let saveRow = temp.filter((obj) => obj.dealerID == row.dealerID)[0];
      setJewelryList(temp);
      console.log(saveRow);
      handleEnableDisableIsAutoPermission(saveRow);
    }
    if (name === "purchaseoder") {
      const temp = jewelryList.map((x) => {
        if (x.dealerID === row.dealerID) {
          return { ...x, purchaseoder: e == true ? "1" : "0" };
        } else return x;
      });
      let saveRow = temp.filter((obj) => obj.dealerID == row.dealerID)[0];
      setJewelryList(temp);
      console.log(saveRow);
      handleEnableDisablePurchaseOrder(saveRow);
    }
  };

  const handleGetRetailerProfile = (id) => {
    try {
      let inputData = {
        retailerID: id,
      };
      setSelectedColumnId(id);
      RetailerDataService.GetRetailerProfile(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setRowDetails(responseData[0]);
            } else {
              setRowDetails({});
            }
          } else {
            setRowDetails({});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const columnAllJewelry = [
    {
      title: traslate("StoreName"),
      dataIndex: "dealerCompany",
    },
    {
      title: traslate("RetailerType"),
      dataIndex: "retailerType",
    },
    {
      title: traslate("City"),
      dataIndex: "dealerCity",
    },
    {
      title: traslate("State"),
      dataIndex: "dealerState",
    },
    {
      title: traslate("RequestedDate"),
      dataIndex: "reqdDate",
    },
  ];

  const columnJewelry = [
    {
      title: traslate("StoreName"),
      dataIndex: "dealerCompany",
    },
    {
      title: traslate("RetailerType"),
      dataIndex: "retailerType",
    },
    {
      title: traslate("City"),
      dataIndex: "dealerCity",
    },
    {
      title: traslate("State"),
      dataIndex: "dealerState",
    },
    {
      title: traslate("RequestedDate"),
      dataIndex: "reqdDate",
    },
    {
      title: "Jewelry Access",
      dataIndex: "isrestricted",
      render: (item, row) => {
        return (
          <div className="action__btns">
            <Switch
              name="isrestricted"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={row.isrestricted === "1" ? true : false}
              onChange={(e) => handleOnChange(row, e, "isrestricted")}
            />
          </div>
        );
      },
    },
    {
      title: "RingBuilder Access",
      dataIndex: "ringbuilder",
      render: (item, row) => {
        return (
          <div className="action__btns">
            <Switch
              name="ringbuilder"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={row.ringbuilder === "1" ? true : false}
              onChange={(e) => handleOnChange(row, e, "ringbuilder")}
            />
          </div>
        );
      },
    },
    {
      title: "Purchase Order",
      dataIndex: "purchaseoder",
      render: (item, row) => {
        return (
          <div className="action__btns">
            <Switch
              name="purchaseoder"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={row.purchaseoder === "1" ? true : false}
              onChange={(e) => handleOnChange(row, e, "purchaseoder")}
            />
          </div>
        );
      },
    },
    {
      title: "Hide Price",
      dataIndex: "pricedisabled",
      render: (item, row) => {
        return (
          <div className="action__btns">
            <Switch
              name="pricedisabled"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={row.pricedisabled === "1" ? true : false}
              onChange={(e) => handleOnChange(row, e, "pricedisabled")}
            />
          </div>
        );
      },
    },
    {
      title: "Enforce MSRP",
      dataIndex: "markuprestricted",
      render: (item, row) => {
        return (
          <div className="action__btns">
            <Switch
              name="markuprestricted"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={row.markuprestricted === "1" ? true : false}
              onChange={(e) => handleOnChange(row, e, "markuprestricted")}
            />
          </div>
        );
      },
    },
    {
      title: "Auto Sync",
      dataIndex: "isautopermission",
      render: (item, row) => {
        return (
          <div className="action__btns">
            <Switch
              name="isautopermission"
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={row.isautopermission === "1" ? true : false}
              onChange={(e) => handleOnChange(row, e, "isautopermission")}
            />
          </div>
        );
      },
    },
  ];

  const data1 = [
    {
      key: "1",
      storename: <span>Store 1</span>,
      retailertype: <span>Jewelry</span>,
      city: <span>Mumbai</span>,
      state: <span>Maha</span>,
      requesteddate: <span>01/01/2022</span>,
      JewelryAccess: (
        <div className="input__block">
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </div>
      ),
      RingBuilderAccess: (
        <div className="input__block">
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </div>
      ),
      PurchaseOrder: (
        <div className="input__block">
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </div>
      ),
      HidePrice: (
        <div className="input__block">
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </div>
      ),
      EnforceMSRP: (
        <div className="input__block">
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </div>
      ),
      AutoSync: (
        <div className="input__block">
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </div>
      ),
    },
  ];

  const columnsDiamond = [
    {
      title: traslate("StoreName"),
      dataIndex: "storename",
    },
    {
      title: traslate("RetailerType"),
      dataIndex: "retailertype",
    },
    {
      title: traslate("City"),
      dataIndex: "city",
    },
    {
      title: traslate("State"),
      dataIndex: "state",
    },
    {
      title: traslate("RequestedDate"),
      dataIndex: "requesteddate",
    },
    {
      title: traslate("Jewelry"),
      dataIndex: "jewelry",
    },
    {
      title: traslate("PurchaseOrder"),
      dataIndex: "porder",
    },
    {
      title: traslate("JewelryAPI"),
      dataIndex: "jewelryapi",
    },
  ];

  const columns = [
    {
      title: traslate("StoreName"),
      dataIndex: "storename",
      key: "storename",
    },
    {
      title: traslate("RetailerType"),
      dataIndex: "retailertype",
      key: "retailertype",
    },
    {
      title: traslate("City"),
      dataIndex: "city",
      key: "city",
    },
    {
      title: traslate("State"),
      dataIndex: "state",
      key: "state",
    },
    {
      title: traslate("RequestedDate"),
      dataIndex: "requesteddate",
      key: "requesteddate",
    },
    {
      title: traslate("Jewelry"),
      dataIndex: "jewelry",
      key: "jewelry",
      render: (item, row) => {
        return <Checkbox checked={row.jewelry} />;
      },
    },
    {
      title: traslate("PurchaseOrder"),
      dataIndex: "porder",
      key: "porder",
      render: (item, row) => {
        return <Checkbox checked={row.porder} />;
      },
    },
    {
      title: traslate("JewelryAPI"),
      dataIndex: "jewelryapi",
      key: "porder",
      render: (item, row) => {
        return <Checkbox checked={row.jewelryapi} />;
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv prodmarkup__secion permission__section">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="workarea__heading mt-0">
                    {" "}
                    {props.type ? "Diamond" : "Jewelry"} Retailer & Permission
                    {/* {traslate("JewelryRetailer&Permission")}{" "} */}
                  </h4>
                </div>
                <div className="col-lg-9 col-md-9">
                  <div className="prodesc__div">
                    <Tabs
                      defaultActiveKey="myretailer"
                      id="uncontrolled-tab-example"
                      className="tab__div mb-3"
                    >
                      <Tab eventKey="myretailer" title={traslate("MyRetailer")}>
                        <div className="tab__contentdiv">
                          <div className="col-lg-12">
                            <div className="desc__maindiv">
                              <div className="col-lg-12">
                                <Spin spinning={loading} size="large">
                                  {/* <Table
                                  columns={
                                    props.type ? columnsDiamond : columnJewelry
                                  }
                                  className="d-none"
                                  dataSource={MyRetailerData}
                                  scroll={{ x: 600 }}
                                /> */}
                                  <Table
                                    columns={
                                      props.type
                                        ? columnsDiamond
                                        : columnJewelry
                                    }
                                    dataSource={
                                      props.type ? data1 : jewelryList
                                    }
                                    onRow={(record, recordIndex) => ({
                                      onClick: (event) => {
                                        handleGetRetailerProfile(
                                          record.dealerID
                                        );
                                      },
                                    })}
                                    rowClassName={(record) =>
                                      record.dealerID == selectedColumnId
                                        ? "row-active"
                                        : ""
                                    }
                                    loading={loading}
                                    scroll={{ x: 800 }}
                                  />
                                </Spin>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="allretailer"
                        title={traslate("AllRetailer")}
                      >
                        <div className="tab__contentdiv">
                          <div className="desc__maindiv">
                            <div className="col-lg-12">
                              <Table
                                columns={
                                  props.type ? columnsDiamond : columnAllJewelry
                                }
                                dataSource={props.type ? data1 : allJewelryList}
                                onRow={(record, recordIndex) => ({
                                  onClick: (event) => {
                                    handleGetRetailerProfile(record.dealerID);
                                  },
                                })}
                                rowClassName={(record) =>
                                  record.dealerID == selectedColumnId
                                    ? "row-active"
                                    : ""
                                }
                                loading={loading}
                                scroll={{ x: 600 }}
                              />
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3">
                  <div className="right__section">
                    <div className="profile__block">
                      <a href="#" className="retailer__name">
                        {rowDetails.dealerCompany
                          ? rowDetails.dealerCompany
                          : null}
                      </a>
                      <div className="logoImg__block">
                        <img src={RetailerLogo} className="img-fluid" />
                      </div>
                      <p className="activeFrom">
                        Active |{" "}
                        {rowDetails.activity ? rowDetails.activity : null}
                      </p>
                    </div>

                    <div className="retailer__dtls mb-4">
                      <p>
                        {rowDetails.dealerName ? rowDetails.dealerName : null}
                      </p>
                      <p>
                        {rowDetails.dealerEmail ? rowDetails.dealerEmail : null}
                      </p>
                      <p>
                        {rowDetails.dealerPhone ? rowDetails.dealerPhone : null}
                      </p>
                      <a href={rowDetails.websiteAddress}>
                        {rowDetails.websiteAddress
                          ? rowDetails.websiteAddress
                          : null}
                      </a>
                      <hr />
                    </div>

                    <div className="links__block">
                      <ul>
                        <li>
                          <Link
                            to={"/retailerProfile"}
                            state={{
                              key: "Profile",
                              id: selectedColumnId,
                            }}
                          >
                            <i
                              class="fa fa-address-book-o"
                              aria-hidden="true"
                            ></i>{" "}
                            View Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/retailerProfile"}
                            state={{
                              key: "Conversation",
                              id: selectedColumnId,
                            }}
                          >
                            <i class="fa fa-comments-o" aria-hidden="true"></i>{" "}
                            Conversation
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/retailerProfile"}
                            state={{
                              key: "Appointments",
                              id: selectedColumnId,
                            }}
                          >
                            <i
                              class="fa fa-calendar-check-o"
                              aria-hidden="true"
                            ></i>{" "}
                            Make Appointment
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/retailerProfile"}
                            state={{
                              key: "Notes",
                              id: selectedColumnId,
                            }}
                          >
                            <i
                              class="fa fa-pencil-square-o"
                              aria-hidden="true"
                            ></i>{" "}
                            Store Notes
                          </Link>
                        </li>
                      </ul>
                      <hr />
                    </div>

                    <div className="bottom__block">
                      <div className="input__block">
                        <label>Sales Rep</label>
                        <Select
                          className="border__grey"
                          placeholder="Sales Rep"
                          name="productType"
                          optionFilterProp="children"
                        >
                          <Option value="Option 1">Option 1</Option>
                          <Option value="Option 2">Option 2</Option>
                        </Select>
                      </div>
                      <div className="input__block">
                        <label>Disconnect Retailer</label>
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                          onChange={handleSwitch}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JewelryRetailerPermission;
