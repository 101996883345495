import { http, httpDealer } from "../helper/http-common";
class AdminToolsDataService {
  GetGFLinkDealer() {
    return http.post("/Tools/GetGFLinkDealer");
  }
  AddGFLinksDealer(inputData) {
    return http.post("/Tools/AddGFLinksDealer", inputData);
  }
  RemoveGFLinksDealer(inputData) {
    return http.post("/Tools/RemoveGFLinksDealer", inputData);
  }
  AddGFLinksLabDiamondDealer(inputData) {
    return http.post("/Tools/AddGFLinksLabDiamondDealer", inputData);
  }
  RemoveGFLinksLabDiamondDealer(inputData) {
    return http.post("/Tools/RemoveGFLinksLabDiamondDealer", inputData);
  }
  FillDealerDropDown() {
    return http.post("/Tools/FillDealerDropDown");
  }
  GetMenuList(inputData) {
    return http.post("/Tools/GetMenuList", inputData);
  }
  SaveMenuList(inputData) {
    return http.post("/Tools/SaveMenuList", inputData);
  }
  GetReportPermissionsList(inputData) {
    return http.post("/Tools/GetReportPermissionsList", inputData);
  }
  SaveReportPermissions(inputData) {
    return http.post("/Tools/SaveReportPermissions", inputData);
  }
  GetBlockDesignersRetailer(inputData) {
    return http.post("/Tools/GetBlockDesignersRetailer", inputData);
  }
  SaveBlockDesignersRetailer(inputData) {
    return http.post("/Tools/SaveBlockDesignersRetailer", inputData);
  }
  SaveMasterLinkTheme(inputData) {
    return http.post("/Tools/SaveMasterLinkTheme", inputData);
  }
  GetMasterlinkThemvalue(inputData) {
    return http.post("/Tools/GetMasterlinkThemvalue", inputData);
  }
  GetDesignerList() {
    return http.get("/Tools/GetDesignerForLoginPage");
  }
  AddDesignerToLogInPage(inputData) {
    return http.post("/Tools/AddDesignerToLogInPage", inputData);
  }
  SetUnsetDesignerForLoginPage(inputData) {
    return http.post("/Tools/SetUnsetDesignerForLoginPage", inputData);
  }
  UploadLogoForDesignerForLoginPage(inputData) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return http.post("/Tools/UploadLogoForDesignerForLoginPage", inputData, {
      headers,
    });
  }
  /* For Solar Core client  */
  GetRetailerCoreList() {
    return http.post("/Tools/GetRetailerCoreList");
  }
  SaveRetailerCore(inputData) {
    return http.post("/Tools/SaveRetailerCore", inputData);
  }
  GetDealerDetails(inputData) {
    return httpDealer.post("/Dealer/GetDealerDetails", inputData);
  }

  /*Vendor Rating*/
  GetVendorRatingList() {
    return http.post("/Tools/GetVendorRatingList");
  }
  SaveVendorRating(inputData) {
    return http.post("/Tools/SaveVendorRating", inputData);
  }

  /* Settings -> Permissions  */
  GetDynamicPageAccessDetailsList() {
    return http.post("/Setting/GetDynamicPageAccessDetailsList");
  }
  SaveDynamicPageAccessDetails(inputData) {
    return http.post("/Setting/SaveDynamicPageAccessDetails", inputData);
  }

  /* Magento Mapping */
  GetOpenSourceMappingList() {
    return http.post("/Tools/GetOpenSourceMappingList");
  }
  SaveOpenSourceMapping(inputData) {
    return http.post("/Tools/SaveOpenSourceMapping", inputData);
  }
  UpdateOpenSourceMapping(inputData) {
    return http.post("/Tools/UpdateOpenSourceMapping", inputData);
  }
  DeleteOpenSourceMapping(inputData) {
    return http.post("/Tools/DeleteOpenSourceMapping", inputData);
  }

  /* Limits  */
  GetDealerForCreate(inputData) {
    return http.post("/Tools/GetDealerForCreate", inputData);
  }

  GetDealerLimits(inputData) {
    return http.post("/Tools/GetDealerLimits", inputData);
  }
  CreateDealerLimit(inputData) {
    return http.post("/Tools/CreateDealerLimit", inputData);
  }
  DeleteDealerLimit(inputData) {
    return http.post("/Tools/DeleteDealerLimit", inputData);
  }

  /*Create New Client  */
  GetPermisssionTypeMaster() {
    return http.get("/Tools/GetPermisssionTypeMaster");
  }
  GetRetailerStatusMaster() {
    return http.get("/Tools/GetRetailerStatusMaster");
  }
  GetVendorStatusMaster() {
    return http.get("/Tools/GetVendorStatusMaster");
  }
  GetHTMLMailTemplet(inputData) {
    return http.post("/Tools/GetHTMLMailTemplet", inputData);
  }
  CreateNewClient(inputData) {
    return http.post("/Tools/CreateNewClient", inputData);
  }
  GetCountryDetail() {
    return httpDealer.post("Dealer/GetCountryDetail");
  }
  CheckDealerCompanyName(inputData) {
    return httpDealer.post("Comman/CheckDealerCompanyName", inputData);
  }
  CheckDealerEmail(inputData) {
    return httpDealer.post("Comman/CheckDealerEmail", inputData);
  }
  CheckDealerUser(inputData) {
    return httpDealer.post("Comman/CheckDealerUser", inputData);
  }
  /*Console Upload */
  GetConsoleUploadList() {
    return http.post("/Tools/GetConsoleUploadList");
  }

  SaveConsoleUpload(inputData) {
    return http.post("/Tools/SaveConsoleUpload", inputData);
  }
  UpdateConsoleUpload(inputData) {
    return http.post("/Tools/UpdateConsoleUpload", inputData);
  }
  DeleteConsoleUpload(inputData) {
    return http.post("/Tools/DeleteConsoleUpload", inputData);
  }

  CreateFTP(inputData) {
    return http.post("/Tools/CreateFTP", inputData);
  }

  /*Client Status */
  GetClientStatus(inputData) {
    return http.post("/Tools/GetClientStatus", inputData);
  }

  UpdateClientStatus(inputData) {
    return http.post("/Tools/UpdateClientStatus", inputData);
  }

  ChangeDealerCreatedDate(inputData) {
    return http.post("/Tools/ChangeDealerCreatedDate", inputData);
  }
  /*Diamond imports  */
  GetDiamondImportRightsList() {
    return http.post("/Tools/GetDiamondImportRightsList");
  }

  SaveDiamondImportRights(inputData) {
    return http.post("Tools/SaveDiamondImportRights", inputData);
  }
  GetClientDataMappingList() {
    return http.get("/Tools/GetClientDataMappingList");
  }
  SetDefaultMapping(inputData) {
    return http.post("/Tools/SetDefaultMapping", inputData);
  }

  // Administration Tools
  GetUsersList(inputData) {
    return http.post("/Tools/GetUsersList", inputData);
  }
  GetUsersListByDealerId(inputData) {
    return http.post("/Tools/GetUsersListByDealerId", inputData);
  }
  ImporsonateToJewelCloud(inputData) {
    return http.post("/Tools/ImporsonateToJewelCloud", inputData);
  }
  GetAdminDealerProfile(inputData) {
    return http.post("/Tools/GetAdminDealerProfile", inputData);
  }
  AdminToolsDownloadInventoryFile(inputData) {
    return http.post("/Tools/AdminToolsDownloadInventoryFile", inputData, {
      responseType: "arraybuffer",
    });
  }

  //Client Data Maps
  SyncProducts(inputData) {
    return http.post("/Tools/SyncProducts", inputData);
  }
  SyncProductsForce(inputData) {
    return http.post("/Tools/SyncProductsForce", inputData);
  }
  GetUpdateHistoryList(inputData) {
    debugger;
    return http.post("/Tools/GetUpdateHistoryList ", inputData);
  }
  UploadMagentoMapping(inputData) {
    return http.post("/Tools/UploadMagentoMapping", inputData);
  }
  GetUpdateHistory(inputData) {
    return http.post("/Tools/GetUpdateHistory", inputData);
  }
  CreateXML(inputData) {
    return http.post("/Tools/CreateXML", inputData);
  }
  GetMegentoMappingForXML(inputData) {
    return http.post("/Tools/GetMegentoMappingForXML", inputData);
  }
  SaveMegentoMappingForXML(inputData) {
    return http.post("/Tools/SaveMegentoMappingForXML", inputData);
  }
  /*Pending Sync */
  GetDealerDropDownList(inputData) {
    return httpDealer.post("/Dealer/GetDealerDropDownList", inputData);
  }

  GetPendingJewelrySyncList(inputData) {
    return http.post("/Tools/GetPendingJewelrySyncList", inputData);
  }

  UpdateDiscardStatus(inputData) {
    return http.post("/Tools/UpdateDiscardStatus", inputData);
  }
  RunSyncForce(inputData) {
    return http.post("/Tools/RunSyncForce", inputData);
  }

  //Login Preferenc
  GetDesignerData() {
    return http.post("/Tools/GetDesignerData");
  }

  //Transfer ZZ Gemfind
  TransferBrand(inputData) {
    return http.post("/Tools/TransferBrand", inputData);
  }
  TransferRequests(inputData) {
    return http.post("/Tools/TransferRequests", inputData);
  }
  TransferProfile(inputData) {
    return http.post("/Tools/TransferProfile", inputData);
  }
  TransferColumnAndAttributemapping(inputData) {
    return http.post("/Tools/TransferColumnAndAttributemapping", inputData);
  }
  TransferProductData(inputData) {
    return http.post("/Tools/TransferProductData", inputData);
  }
  TransferProductImagesVideo(inputData) {
    return http.post("/Tools/TransferProductImagesVideo", inputData);
  }
  ForgotPasswordGeneration(inputData) {
    return http.post("/Identity/ForgotPasswordGeneration", inputData);
  }
  ForgotPasswordChangeNewPassword(inputData) {
    return http.post("/Identity/ForgotPasswordChangeNewPassword", inputData);
  }
}

export default new AdminToolsDataService();
