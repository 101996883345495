import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import {
    Input,
    Row,
    Col,
    Select,
    Checkbox,
    Button,
    Table,
    Modal,
    DatePicker,
    Switch,
    Space
} from "antd";
import {
    EditOutlined,
    CalendarOutlined,
    SearchOutlined
} from "@ant-design/icons";

const { Option } = Select;

export const PurchaseOrder = props => {

    const columns = [
        {
            title: "Purchase Order",
            dataIndex: "purchaseOrder",
        },
        {
            title: "Total items",
            dataIndex: "totalItems",
        },
        {
            title: "Total Cost",
            dataIndex: "totalCost",
        },
        {
            title: "Updated",
            dataIndex: "updated",
        },
        {
            title: "Status",
            dataIndex: "status",
        }
    ];

    const data = [
        {
            key: "1",
            purchaseOrder: "123456",
            totalItems: "3",
            totalCost: "$440.00",
            updated: "03/18/2020",
            status: "Shipped",
        },
        {
            key: "2",
            purchaseOrder: "Testing001",
            totalItems: "3",
            totalCost: "$1999.00",
            updated: "12/13/2018",
            status: "Submitted",
        }
    ];



    return (
        <div className="form__fields">
            <div className="row magento__mapping">
                <div className="col-md-12">
                    <div className="heading__block">
                        <h4 class="workarea__heading mt-0 mb-0">Purchase Order</h4>
                    </div>
                </div>
                <div className="divForm">
                    <Table columns={columns} dataSource={data} scroll={{ x: 600 }} />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PurchaseOrder);
