import React ,{useState} from "react";
import { Input, Row, Col, Select, Checkbox, Switch, Table, Menu, Dropdown, Button, Space, Tooltip } from "antd";
import Filter_icon from "../../../assets/images/filter_icon.png";
import Rectangle from "../../../assets/images/Rectangle.png";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import Close from "../../../assets/images/close.png";
import traslate from "../../../i18n/translate";

const DiamondLinkApiEndPoint = () => {
    
    return (
        <React.Fragment>
            <div className="form__fields apiendpoint">
                <div className="row">
                    <div className="col-md-12">
                        <h4 className="workarea__heading mt-0">
                            {traslate("ApiEndPointAndAuthentication.")}
                        </h4>
                    </div>

                    <div className="col-md-4">
                        <div className="input__block">
                            <label> {traslate("GenerateAPIKeytogetdata")} </label>
                            <input type="text" />
                        </div>
                        <div className="button__block">
                            <button className="primary-btn mr-2"> {traslate("Generate")} </button>
                            <button className="primary-btn"> {traslate("APIDocumentation")} </button>
                            {/* <a href="#">API Documentation</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default DiamondLinkApiEndPoint
