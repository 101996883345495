import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table,
} from "antd";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import Create_icon from "../../../assets/images/create_icon.png";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import { width } from "dom-helpers";
import AppsRingBuilderService from "../../../services/apps-ringbuilder.service";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import traslate from "../../../i18n/translate";

const Markups = () => {
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;
  const style = <span> {traslate("contentnotprovided.")} </span>;
  const initialState = {
    Currency: null,
    Rounding: null,
    DefaultMarkup: null,
    MinPrice: null,
    MaxPrice: null,
    MarkUP: null,
    Error: {},
  };
  const [state, setState] = useState(initialState);
  const [CategotyList, setCategotyList] = useState([]);
  const [RoundingList, setRoundingList] = useState([]);
  const [DealerId, setDealerId] = useState(null);
  const [MarkUpDataList, setMarkUpDataList] = useState([]);
  const [CustomMarkupID, setCustomMarkupID] = useState(null);
  const [LastID, setLastID] = useState(null);
  const [IsDelete, setIsDelete] = useState(false);
  const [Defaultloading, setDefaultloading] = useState(false);

  useEffect(() => {
    handleGetCurrencyList();
    handleGetRoundingList();
    if (loginDetials) {
      if (loginDetials.responseData) {
        setDealerId(loginDetials.responseData.dealerId);
      }
    }
  }, []);

  const onChange = (e, name) => {
    let tempState = state;
    tempState[name] = e.target.value;

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const onChangeDrop = (e, name) => {
    let tempState = state;
    tempState[name] = e;

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const handleOnClickRow = (record, recordIndex) => {
    debugger;
    if (IsDelete) {
      setCustomMarkupID(null);
      setIsDelete(false);
    } else {
      setCustomMarkupID(record.customMarkupID);
    }

    state.MinPrice = record.minRange;
    state.MaxPrice = record.maxRange;
    state.MarkUP = record.markup;

    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };

  const handleAddMarkUpPrice = (e, CustomMarkupID) => {
    debugger;
    var tempState = [];
    let custID = 0;
    if (CustomMarkupID) {
      const temp = MarkUpDataList.map((x) => {
        if (x.customMarkupID === CustomMarkupID) {
          return {
            ...x,
            minRange: Number(state.MinPrice).toFixed(2),
            maxRange: Number(state.MaxPrice).toFixed(2),
            markup: Number(state.MarkUP).toFixed(2),
          };
        } else return x;
      });
      setMarkUpDataList(temp);
      if (LastID === CustomMarkupID) {
        setCustomMarkupID(null);
        state.MinPrice = state.MaxPrice;
        state.MarkUP = "";
        setState((prevState) => ({
          ...prevState,
          ...state,
        }));
      }
    } else {
      if (MarkUpDataList.length > 0) {
        custID = MarkUpDataList[MarkUpDataList.length - 1].customMarkupID || 0;
      } else {
        tempState = [];
      }
      let inputData = {
        customMarkupID: parseInt(custID) + 1,
        dealerID: 0,
        minRange: Number(state.MinPrice).toFixed(2), //parseFloat(state.MinPrice),
        maxRange: Number(state.MaxPrice).toFixed(2), //parseFloat(state.MaxPrice),
        markup: Number(state.MarkUP).toFixed(2), //parseFloat(state.MarkUP),
      };
      tempState.push(inputData);
      var arr1 = MarkUpDataList || [];
      var arr2 = tempState;
      var arr3 = [...arr1, ...arr2];
      setMarkUpDataList(arr3);
      setCustomMarkupID(parseInt(custID) + 1);
      setLastID(parseInt(custID) + 1);
    }

    console.log(MarkUpDataList);
  };

  const handleGetCurrencyList = () => {
    debugger;
    try {
      AppsRingBuilderService.GetCurrencyList()
        .then((response) => {
          debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            //var data =JSON.parse(responseData);
            setCategotyList(responseData);
          } else {
            setCategotyList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetRoundingList = () => {
    debugger;
    try {
      AppsRingBuilderService.GetRoundingList()
        .then((response) => {
          debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            setRoundingList(responseData);
          } else {
            setRoundingList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (row) => {
    debugger;
    setCustomMarkupID(null);
    setIsDelete(true);
    let tempArray = MarkUpDataList.filter(
      (x) => x.customMarkupID != row.customMarkupID
    );
    setMarkUpDataList(tempArray);
  };

  const columns = [
    {
      title: traslate("Markup="),
      dataIndex: "markup",
    },
    {
      title: traslate("Cost"),
      dataIndex: "cost",
    },
    {
      title: traslate("Retail"),
      dataIndex: "retail",
    },
    {
      title: traslate("Mrk"),
      dataIndex: "mrk",
    },
  ];

  const data = [
    {
      markup: <span>100%</span>,
      cost: <span>$1,000</span>,
      retail: <span>$2,000</span>,
      mrk: <span>(Key)</span>,
    },
    {
      markup: <span>120%</span>,
      cost: <span>$1,000</span>,
      retail: <span>$2,200</span>,
      mrk: <span>(2.2)</span>,
    },
    {
      markup: <span>150%</span>,
      cost: <span>$1,000</span>,
      retail: <span>$2,500</span>,
      mrk: <span>(2.5)</span>,
    },
    {
      markup: <span>80%</span>,
      cost: <span>$1,000</span>,
      retail: <span>$1,800</span>,
      mrk: <span>(1.8)</span>,
    },
  ];

  const columns1 = [
    {
      title: traslate("MinPrice"),
      dataIndex: "min_price",
    },
    {
      title: traslate("MaxPrice"),
      dataIndex: "max_price",
    },
    {
      title: traslate("Markup"),
      dataIndex: "markup",
    },
    {
      title: traslate("Action"),
      dataIndex: "action",
    },
  ];

  const data1 = [
    {
      key: "1",
      min_price: <span>$0.01</span>,
      max_price: <span>$200</span>,
      markup: <span>100.00%</span>,
      action: (
        <div className="action__btns">
          <div className="image__block">
            <img src={Delete_iconbl} alt="" />
          </div>
        </div>
      ),
    },
    {
      key: "2",
      min_price: <span>$0.01</span>,
      max_price: <span>$200</span>,
      markup: <span>100.00%</span>,
      action: (
        <div className="action__btns">
          <div className="image__block">
            <img src={Delete_iconbl} alt="" />
          </div>
        </div>
      ),
    },
    {
      key: "3",
      min_price: <span>$0.01</span>,
      max_price: <span>$200</span>,
      markup: <span>100.00%</span>,
      action: (
        <div className="action__btns">
          <div className="image__block">
            <img src={Delete_iconbl} alt="" />
          </div>
        </div>
      ),
    },
    {
      key: "4",
      min_price: <span>$0.01</span>,
      max_price: <span>$200</span>,
      markup: <span>100.00%</span>,
      action: (
        <div className="action__btns">
          <div className="image__block">
            <img src={Delete_iconbl} alt="" />
          </div>
        </div>
      ),
    },
  ];

  function onSearch(val) {
    console.log("search:", val);
  }

  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };

  return (
    <React.Fragment>
      <div className="markups__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <h4 className="workarea__heading mt-0">
                  {" "}
                  {traslate("Markups")}{" "}
                </h4>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="content__div">
                  <div className="col-lg-12">
                    <div className="head__div">
                      <h4> {traslate("GeneralSettings")} </h4>
                    </div>
                    <div className="desc__maindiv">
                      <div className="col-lg-12 p-3">
                        <div className="row">
                          <div className="col-lg-3 mt-4">
                            <div className="input__block">
                              <label>
                                {traslate("Currency")}
                                <Tooltip placement="right" title={style}>
                                  <img src={UnionIcon} alt="" />
                                </Tooltip>{" "}
                                <span>*</span>
                              </label>
                              <Select
                                showSearch
                                className="border__grey"
                                placeholder="Select Currency "
                                optionFilterProp="children"
                                value={state.Currency}
                                onChange={(e) => onChangeDrop(e, "Currency")}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value="">Select Currency</Option>
                                {CategotyList.map((item, i) => {
                                  return (
                                    <Option value={item.currencyId}>
                                      {item.currencyText}
                                    </Option>
                                  );
                                })}
                              </Select>
                              {state.Error ? (
                                <div className="text-danger">
                                  {state.Error["Currency"]}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-3 mt-4">
                            <div className="input__block">
                              <label>
                              {traslate("Rounding")}
                                <Tooltip placement="right" title={style}>
                                  <img src={UnionIcon} alt="" />
                                </Tooltip>{" "}
                                <span>*</span>
                              </label>
                              <Select
                                showSearch
                                className="border__grey"
                                placeholder="Select Rounding "
                                optionFilterProp="children"
                                value={state.Rounding}
                                onChange={(e) => onChangeDrop(e, "Rounding")}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value="">Select Rounding</Option>
                                {RoundingList.map((item, i) => {
                                  return (
                                    <Option value={item.roundingId}>
                                      {item.roundingText}
                                    </Option>
                                  );
                                })}
                              </Select>
                              {state.Error ? (
                                <div className="text-danger">
                                  {state.Error["Rounding"]}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="head__div">
                      <h4> {traslate("DefaultMarkup")} </h4>
                    </div>
                    <div className="desc__maindiv">
                      <div className="col-lg-12 p-3">
                        <div className="row">
                          <div className="col-lg-3 mt-4">
                            <div className="input__block">
                              <label>
                              {traslate("DefaultMarkup")}
                                <Tooltip placement="right" title={style}>
                                  <img src={UnionIcon} alt="" />
                                </Tooltip>{" "}
                                <span>*</span>
                              </label>
                              <div className="mrkup__inptdiv">
                                <input
                                  type="number"
                                  placeholder="147"
                                  className="dmrkup__inpt"
                                  value={state.DefaultMarkup}
                                  onChange={(e) => onChange(e, "DefaultMarkup")}
                                />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 mt-4">
                            <Table
                              columns={columns}
                              dataSource={data}
                              scroll={{ y: 300 }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="head__div">
                      <h4> {traslate("MarkupByPriceRange")} </h4>
                    </div>
                    <div className="desc__maindiv">
                      <div className="col-lg-12 p-3">
                        <div className="row">
                          <div className="col-lg-2 mt-4">
                            <div className="input__block">
                              <div className="mrkup__inptdiv">
                                <span className="dollr__txt">$</span>
                                <input
                                  type="number"
                                  placeholder="147"
                                  className="mrkup__rangeinpt"
                                  //disabled
                                  value={state.MinPrice}
                                  onChange={(e) => onChange(e, "MinPrice")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 mt-4">
                            <div className="input__block">
                              <div className="mrkup__inptdiv">
                                <span className="dollr__txt">$</span>
                                <input
                                  type="number"
                                  placeholder="147"
                                  className="mrkup__rangeinpt"
                                  value={state.MaxPrice}
                                  onChange={(e) => onChange(e, "MaxPrice")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 mt-4">
                            <div className="input__block">
                              <div className="mrkup__inptdiv">
                                <input
                                  type="number"
                                  placeholder="147"
                                  className="dmrkup__inpt"
                                  value={state.MarkUP}
                                  onChange={(e) => onChange(e, "MarkUP")}
                                />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mt-4">
                            <button
                              className="primary-btn"
                              onClick={(e) =>
                                handleAddMarkUpPrice(e, CustomMarkupID)
                              }
                            >
                              {" "}
                              <img src={Create_icon} alt="" />
                              {traslate("Add")}
                            </button>
                          </div>
                          <div className="col-lg-12 mt-4">
                            <Table
                              columns={[
                                {
                                  title: traslate("MinPrice"),
                                  dataIndex: "minRange",
                                  render: (row, item) => {
                                    return <span> ${item.minRange} </span>;
                                  },
                                },
                                {
                                  title: traslate("MaxPrice"),
                                  dataIndex: "maxRange",
                                  render: (row, item) => {
                                    return <span> ${item.maxRange} </span>;
                                  },
                                },
                                {
                                  title: traslate("MarkUp"),
                                  dataIndex: "markup",
                                  render: (row, item) => {
                                    return <span> {item.markup} % </span>;
                                  },
                                },
                                {
                                  title: traslate("Action"),
                                  render: (item, row) => {
                                    return (
                                      <div className="action__btns">
                                        <div className="image__block">
                                          <img
                                            src={Delete_iconbl}
                                            alt=""
                                            onClick={(e) => handleDelete(row)}
                                          />
                                        </div>
                                      </div>
                                    );
                                  },
                                },
                              ]}
                              onRow={(record, recordIndex) => ({
                                onClick: (event) => {
                                  handleOnClickRow(record, recordIndex);
                                },
                              })}
                              loading={Defaultloading}
                              dataSource={MarkUpDataList}
                              scroll={{ y: 300 }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <button class="primary-btn"> {traslate("SavePricing")} </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Markups;
