import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table
} from "antd";
import traslate from "../../i18n/translate";


import prodImg from "../../assets/images/prod__img.jpg";
import UnionIcon from "../../assets/images/icons/Union_5.svg";

import { Doughnut } from "react-chartjs-2";

const Customer = () => {
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;

  const columns = [
    {
      title: traslate("Contact"),
      dataIndex: "Contact",
    },
    {
      title: traslate("Activity"),
      dataIndex: "Activity",
    },
    {
      title: <i class="fa fa-heart" aria-hidden="true"></i>,
      dataIndex: "Heart",
    },
    {
      title: traslate("Conversation"),
      dataIndex: "Conversation",
    },
    {
      title: traslate("Appointment"),
      dataIndex: "Appointment",
    }
  ];

  const data = [
    {
      key: "1",
      Contact: <span>Bhavin Patel</span>,
      Activity: <span>12/14/2020 @ 11:25PM</span>,
      Heart: <span>0</span>,
      Conversation: <span>Respond</span>,
      Appointment: <span>Schedule</span>,
    },
    {
      key: "1",
      Contact: <span>Bhavin Patel</span>,
      Activity: <span>12/14/2020 @ 11:25PM</span>,
      Heart: <span>0</span>,
      Conversation: <span>Respond</span>,
      Appointment: <span>Schedule</span>,
    },
  ];

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields">
        <div className="row  magento__mapping">
          <div className="col-md-12">
            {/* <h4 className="workarea__heading">Jewelry Category Report</h4> */}
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
              {traslate("Customer")}
              </h4>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <Table
              className="pendjewelreq__tbl"
              columns={columns}
              dataSource={data}
              scroll={{ x: 600 }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Customer;
