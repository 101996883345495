import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table,
} from "antd";
import { Tabs, Tab } from "react-bootstrap";
import UnionIcon from "../../assets/images/icons/Union_5.svg";
import Create_icon from "../../assets/images/create_icon.png";
import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";
import { width } from "dom-helpers";
import CatalogJewelryDataService from "../../services/catalog-jewelry.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../i18n/translate";

const AddCollection = () => {
  const [AddCollectionData, setAddCollectionData] = useState({
    collectionID: 0,
    collectionName: "",
    brandID: 0,
    collectionDescription: "",
    collectionDisplayOrder: "",
    bridal: false,
    fashion: true,
    watches: false,
    giftware: false,
    file: null,
    showsImagePath: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [validations, setValidations] = useState({
    collectionNameVal: "",
    brandNameVal: "",
    NavigationVal: "",
    fileVal: "",
  });
  const [editData, setEditData] = useState({});
  const [loading, setLoading] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [dealerId, setDealerId] = useState();
  const [dealerCompany, setdealerCompany] = useState("");
  const loginDetials = useSelector((state) => state.loginReducer);

  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      if (location.state.key) {
        handleGetCollectionStatusById(location.state.key);
      }
    }
  }, []);

  useEffect(() => {
    debugger;
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          setdealerCompany(
            loginDetials.loginDetials.responseData.dealerCompany
          );
          handleGetBrandListId(loginDetials.loginDetials.responseData.dealerId);
        }
      }
    }
  }, []);

  const { Option } = Select;

  const handleInputOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setAddCollectionData({
        ...AddCollectionData,
        [name]: e,
      });
    } else {
      setAddCollectionData({
        ...AddCollectionData,
        [e.target.name]: e.target.value,
        [e.target.id]: e.target.checked,
      });
    }
    console.log(e, isSelect, name);
  };

  const handleGetBrandListId = (value) => {
    try {
      let inputData = {
        dealerID: value,
      };
      setLoading(true);
      console.log(inputData);
      CatalogJewelryDataService.GetBrandList(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              const tempAddCollectionData = AddCollectionData;

              tempAddCollectionData.brandID = responseData[0].designerID + "";
              setBrandList(responseData);
              setAddCollectionData(tempAddCollectionData);
            }
          } else {
            setBrandList([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleValidation = () => {
    const {
      collectionName,
      brandID,
      collectionDescription,
      collectionDisplayOrder,
      bridal,
      fashion,
      watches,
      giftware,
    } = AddCollectionData;
    const validations = {};
    let isValid = true;

    if (!collectionName.trim()) {
      isValid = false;
      validations.collectionNameVal = "Collection name is compulsory";
    }
    if (!brandID) {
      isValid = false;
      validations.brandNameVal = "Kindly select the Brand Name ";
    }
    if (
      bridal == false &&
      fashion == false &&
      watches == false &&
      giftware == false
    ) {
      isValid = false;
      validations.NavigationVal = "Need to select atleast one category";
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const handleGetCollectionStatusById = (ID) => {
    debugger;
    setIsEdit(true);
    try {
      let inputData = {
        collectionID: ID,
      };
      setLoading(true);
      console.log(inputData);
      CatalogJewelryDataService.GetCollectionStatusById(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          debugger;
          if (message == "Success") {
            if (responseData) {
              let tempString = responseData[0].collectionCategory.toLowerCase();
              var tempObj = {
                collectionID: responseData[0].collectionID,
                collectionName: responseData[0].collection,
                brandID: responseData[0].brandID + "",
                collectionDescription: responseData[0].description,
                collectionDisplayOrder: responseData[0].collectionDisplayOrder,
                bridal: tempString.includes("bridal") ? true : false,
                fashion: tempString.includes("fashion") ? true : false,
                watches: tempString.includes("watches") ? true : false,
                giftware: tempString.includes("giftware") ? true : false,
                showsImagePath: responseData[0].collectionImage,
              };
              setAddCollectionData((prevState) => ({
                ...prevState,
                ...tempObj,
              }));
            }
          } else {
            setAddCollectionData({
              collectionID: "",
              collectionName: "",
              brandID: "",
              collectionDescription: "",
              collectionDisplayOrder: "",
              bridal: false,
              fashion: false,
              watches: false,
              giftware: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  let navigate = useNavigate();
  function handleRedirect() {
    navigate("/manageCollection", { state: { key: "HomeOnly" } });
  }

  const handleSuccessRedirect = () => {
    if (isEdit) {
      navigate("/manageCollection", { state: { key: "Vendor" } });
    } else {
      navigate("/manageCollection", { state: { key: "HomeOnly" } });
    }
  };
  // const handleRedirect = () => {
  //   debugger
  //   <Link to={"/manageCollection"} state={{ key: "HomeOnly" }}></Link>;
  // };
  const handleOk = () => {
    const {
      collectionID,
      collectionName,
      brandID,
      collectionDescription,
      collectionDisplayOrder,
      bridal,
      fashion,
      watches,
      giftware,
      file,
      showsImagePath,
    } = AddCollectionData;
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    console.log(AddCollectionData);
    let str = "";
    if (bridal == true) {
      str += "bridal" + "|";
    }
    if (fashion == true) {
      str += "fashion" + "|";
    }
    if (watches == true) {
      str += "watches" + "|";
    }
    if (giftware == true) {
      str += "giftware" + "|";
    }
    const formData = new FormData();
    debugger;
    formData.append("collectionID", collectionID || 0);
    formData.append("dealerID", dealerId);
    formData.append("dealerCompany", dealerCompany);
    formData.append("collectionName", collectionName);
    formData.append("collectionDescription", collectionDescription);
    formData.append("brandID", Number(brandID));
    formData.append("collectionCategory", str);
    formData.append("collectionDisplayOrder", Number(collectionDisplayOrder));
    if (file) {
      formData.append("collectionImage", file || null);
    } else {
      formData.append("collectionImage", showsImagePath || null);
    }
    try {
      setLoading(true);
      CatalogJewelryDataService.SaveCollection(formData)
        .then((response) => {
          var message = response.data.message;
          setLoading(false);
          debugger;
          if (message == "Success") {
            NotificationManager.success("Collection Data Added Successfully.");
            handleSuccessRedirect();
          } else {
            NotificationManager.error("Collection Data Not Added.");
            handleRedirect();
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }

    setAddCollectionData({
      collectionID: 0,
      collectionName: "",
      brandID: 0,
      collectionDescription: "",
      collectionDisplayOrder: "",
      bridal: false,
      fashion: true,
      watches: false,
      giftware: false,
      file: null,

      showsImagePath: "",
    });
    setValidations({
      collectionNameVal: "",
      brandNameVal: "",
      NavigationVal: "",
      fileVal: "",
    });
  };

  const fileUpload = async (e) => {
    if (e.target.files[0].name.match(/.(jpg|jpeg|png|gif)$/i)) {
      let img = new Image();
      img.src = window.URL.createObjectURL(e.target.files[0]);
      img.onload = () => {
        if (img.width <= 500 && img.height <= 500) {
          setAddCollectionData({
            ...AddCollectionData,
            ["file"]: e.target.files[0],
          });
          setValidations({
            ...validations,
            ["file"]: null,
          });
        } else {
          setValidations({
            ...validations,
            ["file"]: "Please Select Correct Image Size.",
          });
        }
      };
    } else {
      setValidations({
        ...validations,
        ["file"]: "Please Select Only Images.",
      });
      setAddCollectionData({
        ...AddCollectionData,
        ["file"]: null,
      });
    }
  };

  const handleCancelImage = () => {
    debugger;
    setAddCollectionData((prevState) => ({
      ...prevState,
      ["showsImagePath"]: null,
    }));

    setAddCollectionData((prevState) => ({
      ...prevState,
      ["file"]: null,
    }));
  };

  const onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9]").test(keyValue)) event.preventDefault();
    return;
  };

  const style = <span>content not provided.</span>;

  return (
    <React.Fragment>
      <div className="addcollection__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <h4 className="workarea__heading mt-0 mb-0">
                  {isEdit ? traslate("EditCollection") : traslate("CreateACollection")}
                </h4>
                <p className="mt-3">
                {traslate("Itemsyouaddforyourstore'spersonaluse.CollectionscanbeaddedtoaJewelCloudVendorasanExtensionoftheirofficialprofileoryourownHouseBrand")}.{" "}
                  <a href="#" className="ml-1">
                  {traslate("ViewTheTrainingVideo")}{" "}
                  </a>
                </p>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="input__block mt-4">
                      <div className="divimage_block w-100">
                        {AddCollectionData.showsImagePath ||
                        AddCollectionData.file ? (
                          <div className="img__showdiv addCollection-img-sp">
                            {isEdit ? (
                              AddCollectionData.file ? (
                                <img
                                  src={URL.createObjectURL(
                                    AddCollectionData.file
                                  )}
                                />
                              ) : (
                                <img src={AddCollectionData.showsImagePath} />
                              )
                            ) : AddCollectionData.file ? (
                              <img
                                src={URL.createObjectURL(
                                  AddCollectionData.file
                                )}
                              />
                            ) : null}
                            <span onClick={handleCancelImage}>x</span>
                          </div>
                        ) : (
                          <div>
                            <label
                              htmlFor="file-upload"
                              class="primary-btn choose_file_upload"
                            >
                              {traslate("ChooseImage")}
                            </label>
                            <input
                              id="file-upload"
                              type="file"
                              onChange={(e) => fileUpload(e)}
                            />
                            {validations.fileVal && (
                              <p className="error-color-red">
                                {validations.fileVal}
                              </p>
                            )}
                            <span class="mb-4"> {traslate("NoFileChoosen")} </span>
                            <span class="">500 X 500 {traslate("Pixels")} </span>
                            <span class="">{".JPG | .JPEG | .PNG Format"}</span>
                          </div>
                        )}
                        {validations["file"] ? (
                          <span className="error-color-red">
                            {validations["file"]}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-lg-6 mt-4">
                        <div className="input__block">
                          <label>
                          {traslate("CollectionName")}
                            {/* <Tooltip placement="right" title={style}>
                              <img src={UnionIcon} alt="" />
                            </Tooltip>{" "} */}
                            <span>*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Collection Name"
                            name="collectionName"
                            value={AddCollectionData.collectionName}
                            onChange={handleInputOnChange}
                            className={
                              validations.collectionNameVal && "border__red"
                            }
                          />
                          <div>
                            {validations.collectionNameVal && (
                              <p className="error-color-red">
                                {validations.collectionNameVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-4">
                        <div className="input__block">
                          <label>
                          {traslate("BrandName")} <span>*</span>
                          </label>
                          <Select
                            name="brandID"
                            placeholder="Select Brand Name"
                            optionFilterProp="children"
                            onChange={(e) =>
                              handleInputOnChange(e, "select", "brandID")
                            }
                            className={
                              validations.brandNameVal
                                ? "border__red"
                                : "border__grey"
                            }
                            value={AddCollectionData.brandID}
                          >
                            {brandList.map((item) => {
                              return (
                                <Option
                                  key={item.designerID}
                                  value={item.designerID + ""}
                                >
                                  {item.designerName}
                                </Option>
                              );
                            })}
                          </Select>
                          <div>
                            {validations.brandNameVal && (
                              <p className="error-color-red">
                                {validations.brandNameVal}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 mt-4">
                        <div className="input__block">
                          <label> {traslate("CollectionDescription")} </label>
                          {/* <input
                            name="collectionDescription"
                            value={AddCollectionData.collectionDescription}
                            onChange={handleInputOnChange}
                            placeholder="Enter Collection Description"
                          /> */}
                          <textarea
                            name="collectionDescription"
                            value={AddCollectionData.collectionDescription}
                            onChange={handleInputOnChange}
                            placeholder="Enter Collection Description"
                            className="textarea__height"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-4">
                        <div className="input__block">
                          <label> {traslate("CollectionDisplayOrder")} </label>
                          <input
                            type="number"
                            onKeyPress={onKeyPressEvent}
                            placeholder="Enter Collection Order"
                            name="collectionDisplayOrder"
                            value={AddCollectionData.collectionDisplayOrder}
                            onChange={handleInputOnChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 mt-4">
                        <div className="input__block">
                          <label>
                          {traslate("NavigationCategory")} <span>*</span>
                            <div>
                              {validations.NavigationVal && (
                                <p className="error-color-red">
                                  {validations.NavigationVal}
                                </p>
                              )}
                            </div>
                          </label>
                          <div className="chkbox__div">
                            <div>
                              <input
                                type="checkbox"
                                name="bridal"
                                checked={AddCollectionData.bridal}
                                onChange={handleInputOnChange}
                                id="bridal"
                              />
                              <label htmlFor="bridal"> {traslate("Bridal")} </label>
                            </div>

                            <div>
                              <input
                                type="checkbox"
                                name="fashion"
                                checked={AddCollectionData.fashion}
                                onChange={handleInputOnChange}
                                id="fashion"
                              />
                              <label htmlFor="fashion"> {traslate("Fashion")} </label>
                            </div>

                            <div>
                              <input
                                type="checkbox"
                                name="watches"
                                checked={AddCollectionData.watches}
                                onChange={handleInputOnChange}
                                id="watches"
                              />
                              <label htmlFor="watches"> {traslate("Watches")} </label>
                            </div>

                            <div>
                              <input
                                type="checkbox"
                                name="giftware"
                                checked={AddCollectionData.giftware}
                                onChange={handleInputOnChange}
                                id="giftware"
                              />
                              <label htmlFor="giftware"> {traslate("Giftware")} </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 mt-4">
                        <button className="primary-btn" onClick={handleOk}>
                          {" "}
                          {/* <img src={Create_icon} alt="" />  */}
                          {isEdit ? traslate("SaveCollection") : traslate("CreateCollection")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddCollection;
