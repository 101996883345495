import React, { useState, useEffect } from "react";
import AdminSettingsDataService from "../../../services/admin-settings.service";
import { NotificationManager } from "react-notifications";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Modal,
  Table,
  Spin,
} from "antd";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import traslate from "../../../i18n/translate";

const Addcolor = () => {
  const [getColorData, setgetColorData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [colorData, setColorData] = useState({
    metalColorID: 0,
    metalColor: "",
    enabled: true,
    deleteRow: 0,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [validations, setValidations] = useState({
    colorNameVal: "",
  });
  const [delData, setDelData] = useState({
    metalColorID: 0,
  });

  useEffect(() => {
    handleGetMetalColors();
  }, []);

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    setColorData({
      metalColorID: 0,
      metalColor: "",
      enabled: true,
      deleteRow: 0,
    });
    setValidations({
      colorNameVal: "",
    });
    setIsEdit(false);
  };

  const handleOk1 = () => {
    setVisible1(false);
  };

  const handleOk2 = () => {
    setVisible2(false);
  };

  const { Option } = Select;

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  const handleGetMetalColors = () => {
    let inputData = {
      metalColorID: 0,
    };
    try {
      setLoading(true);
      AdminSettingsDataService.GetMetalColors(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            setgetColorData(responseData);
          } else {
            setgetColorData([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setColorData({
        ...colorData,
        [name]: e,
      });
    } else {
      setColorData({
        ...colorData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleValidation = () => {
    const { metalColor } = colorData;
    const validations = {
      colorNameVal: "",
    };
    let isValid = true;

    if (!metalColor.trim()) {
      isValid = false;
      validations.colorNameVal = "Color Name is compulsory";
    } else {
      validations.colorNameVal = "";
    }
    setValidations(validations);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    console.log(colorData);
    try {
      let inputData = {
        metalColorID: colorData.metalColorID,
        metalColor: colorData.metalColor,
        enabled: colorData.enabled,
      };
      setLoading(true);
      AdminSettingsDataService.SaveMetalColor(inputData)
        .then((response) => {
          debugger;
          var message = response.data.message;
          setLoading(false);
          if (message == "Success") {
            if (isEdit) {
              NotificationManager.success("Data Updated SuccessFully");
            } else {
              NotificationManager.success("Data Added SuccessFully");
            }
            handleGetMetalColors();
          } else {
            NotificationManager.error("Data Already Exists");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleCancel();
  };

  const handleEditModal = (row) => {
    setVisible(true);
    setIsEdit(true);
    setColorData({
      metalColorID: row.metalColorID,
      metalColor: row.metalColor,
      enabled: row.enabled,
    });
  };

  const handleDeleteModal = (row) => {
    setVisible2(true);
    setDelData({
      metalColorID: row.metalColorID,
    });
    console.log(row);
  };

  const handleCancel2 = () => {
    setVisible2(false);
    setDelData({
      metalColorID: 0,
    });
  };

  const handleDelSubmit = () => {
    try {
      let inputData = {
        metalColorID: parseInt(delData.metalColorID),
      };
      setLoading(true);
      AdminSettingsDataService.DeleteMetalColors(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(true);
          if (message == "Success") {
            NotificationManager.success("Record Deleted Succssfully.");
            handleGetMetalColors();
            setLoading(false);
          } else {
            NotificationManager.error("Record Not Deleted.");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    handleCancel2();
  };

  const { metalColor, enabled } = colorData;

  return (
    <React.Fragment>
      <div className="addcolor__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <h4 className="workarea__heading mt-0 mb-0">
                    {" "}
                    {traslate("AddColor")}{" "}
                  </h4>
                </div>
                <div className="col-lg-6">
                  <div className="addcolor__btndiv">
                    <button
                      className="primary-btn"
                      onClick={() => setVisible(true)}
                    >
                      {" "}
                      <img src={Create_icon} alt="" /> {traslate("AddColor")}{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="addcolor_tbldiv">
                <Spin spinning={loading} size="large">
                  <Table
                    pagination={{
                      onChange(current) {
                        setPage(current);
                      },
                      showSizeChanger: true,
                      responsive: true,
                      // showQuickJumper: true,
                    }}
                    columns={[
                      {
                        title: traslate("Sr.No."),
                        width: 100,
                        render: (value, item, index) =>
                          (page - 1) * 10 + index + 1,
                      },
                      {
                        title: traslate("ColorName"),
                        dataIndex: "metalColor",
                      },
                      {
                        title: traslate("Active"),
                        dataIndex: "enabled",
                        Key: "enabled",
                        render: (item, row) => {
                          return item ? "Yes" : "No";
                        },
                        width: 190,
                      },
                      {
                        title: traslate("Action"),
                        dataIndex: "action",
                        width: 100,
                        render: (index, row) => (
                          <div className="action__btns">
                            <div className="image__block">
                              <img
                                src={Edit_icon}
                                onClick={() => {
                                  handleEditModal(row);
                                }}
                                alt=""
                              />
                            </div>
                            <div className="image__block">
                              <img
                                src={Delete_iconbl}
                                onClick={() => {
                                  handleDeleteModal(row);
                                }}
                                alt=""
                              />
                            </div>
                          </div>
                        ),
                      },
                    ]}
                    dataSource={getColorData}
                    scroll={{ x: 600 }}
                  />
                </Spin>
              </div>
            </div>

            <div className="col-lg-12">
              <Modal
                title={
                  isEdit === false
                    ? traslate("AddColor")
                    : traslate("EditColor")
                }
                className="modal__addcolor"
                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={handleCancel}
                width={600}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button key="submit" type="primary" onClick={handleSubmit}>
                    {isEdit === false ? traslate("Add") : traslate("Update")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>Color Name</label>
                              <input
                                type="text"
                                placeholder="Enter Color"
                                name="metalColor"
                                value={metalColor}
                                onChange={handleOnChange}
                                className={
                                  validations.colorNameVal && "border__red"
                                }
                              />
                            </div>
                            <div>
                              {validations.colorNameVal && (
                                <p className="error-color-red">
                                  {validations.colorNameVal}
                                </p>
                              )}
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="input__block">
                                <label> {traslate("Active")}</label>
                                <Switch
                                  name="enabled"
                                  checkedChildren="Yes"
                                  unCheckedChildren="No"
                                  checked={enabled}
                                  onChange={(e) =>
                                    handleOnChange(e, "select", "enabled")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>

              <Modal
                className="datamapping__modal"
                centered
                visible={visible2}
                onOk={() => setVisible2(false)}
                onCancel={() => setVisible2(false)}
                width={400}
                footer={[
                  <Button key="back" onClick={handleCancel2}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button key="submit" type="primary" onClick={handleDelSubmit}>
                    {traslate("Delete")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-12 text-center">
                            <p className="subheading">
                              {traslate("Areyousureyouwanttodelete")} ?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Addcolor;
