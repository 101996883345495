import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table
} from "antd";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import Create_icon from "../../../assets/images/create_icon.png";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import { width } from "dom-helpers";

const PendantBuilderSettingMarkup = () => {
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;

  const style = <span>content not provided.</span>;

  const columns = [
    {
      title: "Markup=",
      dataIndex: "markup"
    },
    {
      title: "Cost",
      dataIndex: "cost"
    },
    {
      title: "Retail",
      dataIndex: "retail"
    },
    {
      title: "Mrk",
      dataIndex: "mrk"
    }
  ];

  const data = [
    {
      key: "1",
      markup: <span>100%</span>,
      cost: <span>$1000</span>,
      retail: <span>$2,000</span>,
      mrk: <span>(Key)</span>
    },
    {
      key: "2",
      markup: <span>100%</span>,
      cost: <span>$1000</span>,
      retail: <span>$2,000</span>,
      mrk: <span>(Key)</span>
    },
    {
      key: "3",
      markup: <span>100%</span>,
      cost: <span>$1000</span>,
      retail: <span>$2,000</span>,
      mrk: <span>(Key)</span>
    },
    {
      key: "4",
      markup: <span>100%</span>,
      cost: <span>$1000</span>,
      retail: <span>$2,000</span>,
      mrk: <span>(Key)</span>
    }
  ];

  const columns1 = [
    {
      title: "Min Price",
      dataIndex: "min_price"
    },
    {
      title: "Max Price",
      dataIndex: "max_price"
    },
    {
      title: "Markup",
      dataIndex: "markup"
    },
    {
      title: "Action",
      dataIndex: "action"
    }
  ];

  const data1 = [
    {
      key: "1",
      min_price: <span>$0.01</span>,
      max_price: <span>$200</span>,
      markup: <span>100.00%</span>,
      action: (
        <div className="action__btns">
          <div className="image__block">
            <img src={Delete_iconbl} alt="" />
          </div>
        </div>
      )
    },
    {
      key: "2",
      min_price: <span>$0.01</span>,
      max_price: <span>$200</span>,
      markup: <span>100.00%</span>,
      action: (
        <div className="action__btns">
          <div className="image__block">
            <img src={Delete_iconbl} alt="" />
          </div>
        </div>
      )
    },
    {
      key: "3",
      min_price: <span>$0.01</span>,
      max_price: <span>$200</span>,
      markup: <span>100.00%</span>,
      action: (
        <div className="action__btns">
          <div className="image__block">
            <img src={Delete_iconbl} alt="" />
          </div>
        </div>
      )
    },
    {
      key: "4",
      min_price: <span>$0.01</span>,
      max_price: <span>$200</span>,
      markup: <span>100.00%</span>,
      action: (
        <div className="action__btns">
          <div className="image__block">
            <img src={Delete_iconbl} alt="" />
          </div>
        </div>
      )
    }
  ];

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };
  return (
    <React.Fragment>
      <div className="markups__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <h4 className="workarea__heading mt-0">Markups</h4>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="content__div">
                  <div className="col-lg-12">
                    <div className="head__div">
                      <h4>General Settings</h4>
                    </div>
                    <div className="desc__maindiv">
                      <div className="col-lg-12 p-3">
                        <div className="row">
                          <div className="col-lg-3 mt-4">
                            <div className="input__block">
                              <label>
                                Currency
                                <Tooltip placement="right" title={style}>
                                  <img src={UnionIcon} alt="" />
                                </Tooltip>{" "}
                                <span>*</span>
                              </label>
                              <Select
                                showSearch
                                className="border__grey"
                                placeholder="Select Currency "
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value="jack">
                                  United States - Dollar - $
                                </Option>
                                <Option value="lucy">
                                  United States - Dollar - $
                                </Option>
                                <Option value="tom">
                                  United States - Dollar - $
                                </Option>
                              </Select>
                            </div>
                          </div>
                          <div className="col-lg-3 mt-4">
                            <div className="input__block">
                              <label>
                                Rounding
                                <Tooltip placement="right" title={style}>
                                  <img src={UnionIcon} alt="" />
                                </Tooltip>{" "}
                                <span>*</span>
                              </label>
                              <Select
                                showSearch
                                className="border__grey"
                                placeholder="Select Rounding "
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value="jack">
                                  Round to the nearest 1
                                </Option>
                                <Option value="lucy">
                                  Round to the nearest 1
                                </Option>
                                <Option value="tom">
                                  Round to the nearest 1
                                </Option>
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="head__div">
                      <h4>Default Markup</h4>
                    </div>
                    <div className="desc__maindiv">
                      <div className="col-lg-12 p-3">
                        <div className="row">
                          <div className="col-lg-3 mt-4">
                            <div className="input__block">
                              <label>
                                Default Markup
                                <Tooltip placement="right" title={style}>
                                  <img src={UnionIcon} alt="" />
                                </Tooltip>{" "}
                                <span>*</span>
                              </label>
                              <div className="mrkup__inptdiv">
                                <input
                                  type="number"
                                  placeholder="147"
                                  className="dmrkup__inpt"
                                />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 mt-4">
                            <Table
                              columns={columns}
                              dataSource={data}
                              scroll={{ x: 600 }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="head__div">
                      <h4>Markup By Price Range</h4>
                    </div>
                    <div className="desc__maindiv">
                      <div className="col-lg-12 p-3">
                        <div className="row">
                          <div className="col-lg-2 mt-4">
                            <div className="input__block">
                              <div className="mrkup__inptdiv">
                                <span className="dollr__txt">$</span>
                                <input
                                  type="number"
                                  placeholder="147"
                                  className="mrkup__rangeinpt"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 mt-4">
                            <div className="input__block">
                              <div className="mrkup__inptdiv">
                                <span className="dollr__txt">$</span>
                                <input
                                  type="number"
                                  placeholder="147"
                                  className="mrkup__rangeinpt"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2 mt-4">
                            <div className="input__block">
                              <div className="mrkup__inptdiv">
                                <input
                                  type="number"
                                  placeholder="147"
                                  className="dmrkup__inpt"
                                />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mt-4">
                            <button className="primary-btn">
                              {" "}
                              <img src={Create_icon} alt="" />
                              Add
                            </button>
                          </div>
                          <div className="col-lg-12 mt-4">
                            <Table
                              columns={columns1}
                              dataSource={data1}
                              scroll={{ x: 600 }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <button class="primary-btn">Save Pricing</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PendantBuilderSettingMarkup;
