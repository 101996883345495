import React, { useState } from "react";
import { Input, Row, Col, Select, Checkbox, Button, Switch, Table } from "antd";
import { NavigationData } from "../../../helper/commanData";
import traslate from "../../../i18n/translate";

const Navigation = () => {
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;
  const [ViewNavigationData, setViewNavigationData] = useState({
    StandardSearch: true,
    AdvancedSearch: true,
    FancyColoredSearch: true,
    LabGrownSearch: true,
    RequestaDiamond: true,
    CompareDiamonds: true,
  });
  const [NaviDataTable, setNaviDataTable] = useState(NavigationData);
  const [selectNaviRow, setSelectNaviRow] = useState([]);

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  const handleSwitch = () => {
    setswitchValue(!switchValue);
    console.log(switchValue);
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setViewNavigationData({
        ...ViewNavigationData,
        [name]: e,
      });
    } else {
      setViewNavigationData({
        ...ViewNavigationData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleNaviTableChange = (name, row, e) => {
    if (name === "TextBox") {
      const tempDealerColumnList = NaviDataTable.map((x) => {
        console.log(row);
        if (x.key === row.key) {
          return { ...x, TextBox: e.target.value };
        } else return x;
      });
      setNaviDataTable(tempDealerColumnList);
    }
    if (name === "Dropdown") {
      const tempDealerColumnList = NaviDataTable.map((x) => {
        if (x.key === row.key) {
          return { ...x, Dropdown: e };
        } else return x;
      });
      setNaviDataTable(tempDealerColumnList);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectNaviRow(selectedRows);
      console.log(selectNaviRow);
    },
  };

  function onChangeCheck(e, name) {
    let tempState = ViewNavigationData;

    tempState[name] = e.currentTarget.checked;

    setViewNavigationData((prevState) => ({
      ...prevState,
      ...tempState,
    }));

    console.log(e.target.value);
  }

  const handleSubmit = async (e) => {
    console.log(ViewNavigationData);

    setViewNavigationData({
      StandardSearch: true,
      AdvancedSearch: true,
      FancyColoredSearch: true,
      LabGrownSearch: true,
      RequestaDiamond: true,
      CompareDiamonds: true,
    });
    setNaviDataTable([
      {
        key: 1,
        data: "Loose Diamonds",
        TextBox: "",
        Dropdown: null,
      },
      {
        key: 2,
        data: "Our Signature Diamond",
        TextBox: "",
        Dropdown: null,
      },
      {
        key: 3,
        data: "Best Value Diamonds ",
        TextBox: "",
        Dropdown: null,
      },
      {
        key: 4,
        data: "Private Reserve Diamonds",
        TextBox: "",
        Dropdown: null,
      },
      {
        key: 5,
        data: "HeartStar Diamonds",
        TextBox: "",
        Dropdown: null,
      },
    ]);
  };

  return (
    <React.Fragment>
      <div className="navigation__section">
        <div className="form__fields">
          <div className="col-md-12 mt-2">
            <h4 className="workarea__heading mt-0 mb-0">
              {" "}
              {traslate("Navigation")}{" "}
            </h4>
          </div>

          <div className="col-lg-12 mt-4">
            <div className="form__section">
              <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {traslate("StandardSearch")} </span>
                </div>

                <div className="inpt__div">
                  <Switch
                    name="StandardSearch"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={ViewNavigationData.StandardSearch}
                    // onChange={handleSwitch}
                    onChange={(e) =>
                      handleOnChange(e, "select", "StandardSearch")
                    }
                  />
                </div>
              </div>
              <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {traslate("AdvancedSearch")} </span>
                </div>

                <div className="inpt__div">
                  <Switch
                    name="AdvanvedSearch"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={ViewNavigationData.AdvancedSearch}
                    // onChange={handleSwitch}
                    onChange={(e) =>
                      handleOnChange(e, "select", "AdvancedSearch")
                    }
                  />
                </div>
              </div>
              <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {traslate("FancyColoredSearch")} </span>
                </div>

                <div className="inpt__div">
                  <Switch
                    name="FancyColoredSearch"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={ViewNavigationData.FancyColoredSearch}
                    // onChange={handleSwitch}
                    onChange={(e) =>
                      handleOnChange(e, "select", "FancyColoredSearch")
                    }
                  />
                </div>
              </div>
              <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {traslate("LabGrownSearch")} </span>
                </div>

                <div className="inpt__div">
                  <Switch
                    name="LabGrownSearch"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={ViewNavigationData.LabGrownSearch}
                    // onChange={handleSwitch}
                    onChange={(e) =>
                      handleOnChange(e, "select", "LabGrownSearch")
                    }
                  />
                </div>
              </div>
              <div className="desc__div">
                <div className="headtxt__div">
                  <span className="">RequestaDiamond </span>
                </div>

                <div className="inpt__div">
                  <Switch
                    name="RequestaDiamond"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={ViewNavigationData.RequestaDiamond}
                    // onChange={handleSwitch}
                    onChange={(e) =>
                      handleOnChange(e, "select", "RequestaDiamond")
                    }
                  />
                </div>
              </div>
              <div className="desc__div">
                <div className="headtxt__div">
                  <span className=""> {traslate("CompareDiamonds")} </span>
                </div>

                <div className="inpt__div">
                  <Switch
                    name="CompareDiamonds"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={ViewNavigationData.CompareDiamonds}
                    // onChange={handleSwitch}
                    onChange={(e) =>
                      handleOnChange(e, "select", "CompareDiamonds")
                    }
                  />
                </div>
              </div>

              <div className="col-lg-12 mt-5">
                <p className="subheading">
                  {" "}
                  {traslate("LeftNavigationforMasterLink")}{" "}
                </p>
              </div>

              {/* <div className="desc__div">
                <div className="headtxt__div">
                  <input 
                    name="LooseCheckbox"
                    type="checkbox"
                    checked={ViewNavigationData.LooseCheckbox}
                    value={ViewNavigationData.LooseDiamondText}
                    onChange={(e) => onChangeCheck(e, "LooseCheckbox")}

                   />
                  <label htmlFor="1">Loose Diamonds </label>
                </div>

                <div className="inpt__div selct__div">
                  <div className="input__block">
                    <input
                      type="text"
                      name="LooseDiamondText"
                      value={ViewNavigationData.LooseDiamondText}
                      onChange={handleOnChange}
                    />
                  </div>
                  <div className="input__block">
                    <Select
                      className="border__grey"
                      value={ViewNavigationData.LooseDiamondDropdown ? ViewNavigationData.LooseDiamondDropdown : null}
                      name="LooseDiamondDropdown"
                      placeholder="Choose From Below"
                      onChange={(e) => handleOnChange(e, "select", "LooseDiamondDropdown")}
                      
                    >
                      <Option value="jack"> {traslate("SameWindow")} </Option>
                      <Option value="lucy"> {traslate("NewWindow")} </Option>
                      <Option value="tom"> {traslate("Inframe")} </Option>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="desc__div">
                <div className="headtxt__div">
                  <input 
                    name="SignatureCheckbox"
                    type="checkbox"
                    value={ViewNavigationData.SignatureDiamondText}
                    onChange={(e) => onChangeCheck(e, "SignatureCheckbox")}
                   />
                  <label htmlFor="2">Our Signature Diamonds </label>
                </div>

                <div className="inpt__div selct__div">
                  <div className="input__block">
                    <input 
                      type="text"
                      name="SignatureDiamondText"
                      value={ViewNavigationData.SignatureDiamondText}
                      onChange={handleOnChange}
                     />
                  </div>
                  <div className="input__block">
                    <Select
                      className="border__grey"
                      value={ViewNavigationData.SignatureDiamondDropdown ? ViewNavigationData.SignatureDiamondDropdown : null}
                      name="SignatureDiamondDropdown"
                      placeholder="Choose From Below"
                      onChange={(e) => handleOnChange(e, "select", "SignatureDiamondDropdown")}
                      
                    >
                      <Option value="jack"> {traslate("SameWindow")} </Option>
                      <Option value="lucy"> {traslate("NewWindow")} </Option>
                      <Option value="tom"> {traslate("Inframe")} </Option>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="desc__div">
                <div className="headtxt__div">
                  <input type="checkbox" id="3" />
                  <label htmlFor="3"> {traslate("BestValueDiamonds")} </label>
                </div>

                <div className="inpt__div selct__div">
                  <div className="input__block">
                    <input 
                      type="text"
                      name="BestValueDiamondText"
                      value={ViewNavigationData.BestValueDiamondText}
                      onChange={handleOnChange}
                     />
                  </div>
                  <div className="input__block">
                    <Select
                      className="border__grey"
                      value={ViewNavigationData.BestValueDiamondDropdown ? ViewNavigationData.BestValueDiamondDropdown : null}
                      name="BestValueDiamondDropdown"
                      placeholder="Choose From Below"
                      onChange={(e) => handleOnChange(e, "select", "BestValueDiamondDropdown")}
                        
                      
                    >
                      <Option value="jack"> {traslate("SameWindow")} </Option>
                      <Option value="lucy"> {traslate("NewWindow")} </Option>
                      <Option value="tom"> {traslate("Inframe")} </Option>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="desc__div">
                <div className="headtxt__div">
                  <input type="checkbox" id="4" />
                  <label htmlFor="4"> {traslate("PrivateReserveDiamonds")} </label>
                </div>

                <div className="inpt__div selct__div">
                  <div className="input__block">
                    <input 
                      type="text"
                      name="ReserveDiamondText"
                      value={ViewNavigationData.ReserveDiamondText}
                      onChange={handleOnChange}

                     />
                  </div>
                  <div className="input__block">
                    <Select
                      className="border__grey"
                      value={ViewNavigationData.ReserveDiamondDropdown ? ViewNavigationData.ReserveDiamondDropdown : null}
                      name="ReserveDiamondDropdown"
                      placeholder="Choose From Below"
                      onChange={(e) => handleOnChange(e, "select", "ReserveDiamondDropdown")}
                      
                    >
                      <Option value="jack"> {traslate("SameWindow")} </Option>
                      <Option value="lucy"> {traslate("NewWindow")} </Option>
                      <Option value="tom"> {traslate("Inframe")} </Option>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="desc__div">
                <div className="headtxt__div">
                  <input type="checkbox" id="5" />
                  <label htmlFor="5"> {traslate("HeartStarDiamonds")} </label>
                </div>

                <div className="inpt__div selct__div">
                  <div className="input__block">
                    <input 
                      type="text"
                      name="HeartStarText"
                      value={ViewNavigationData.HeartStarText}
                      onChange={handleOnChange}

                     />
                  </div>
                  <div className="input__block">
                    <Select
                      className="border__grey"
                      value={ViewNavigationData.HeartStarDropdown ? ViewNavigationData.HeartStarDropdown : null}
                      name="HeartStarDropdown"
                      placeholder="Choose From Below"
                      onChange={(e) => handleOnChange(e, "select", "HeartStarDropdown")}
                    >
                      <Option value="jack"> {traslate("SameWindow")} </Option>
                      <Option value="lucy"> {traslate("NewWindow")} </Option>
                      <Option value="tom"> {traslate("Inframe")} </Option>
                    </Select>
                  </div>
                </div>
              </div> */}
              <Table
                className="vdiamond__table"
                columns={[
                  {
                    // title: traslate("Size"),
                    dataIndex: "data",
                  },
                  {
                    // title: traslate("AdditionalPrice"),
                    dataIndex: "TextBox",
                    width: 250,
                    render: (item, row) => {
                      return (
                        <div className="input__block mb-0">
                          <input
                            name="TextBox"
                            input="text"
                            value={row.TextBox}
                            onChange={(e) =>
                              handleNaviTableChange("TextBox", row, e)
                            }
                          />
                        </div>
                      );
                    },
                  },
                  {
                    // title: traslate("PriceType"),
                    dataIndex: "Dropdown",
                    width: 250,
                    render: (item, row) => {
                      return (
                        <div className="input__block mb-0">
                          <Select
                            name="Dropdown"
                            showSearch
                            placeholder="Select Option"
                            optionFilterProp="children"
                            value={row.Dropdown ? row.Dropdown : null}
                            onChange={(e) =>
                              handleNaviTableChange("Dropdown", row, e)
                            }
                            className="border__grey"
                          >
                            <Option value="jack">Same Window</Option>
                            <Option value="lucy">New Window</Option>
                            <Option value="tom">Inframe</Option>
                          </Select>
                        </div>
                      );
                    },
                  },
                ]}
                dataSource={NaviDataTable}
                scroll={{ y: 300 }}
                pagination={false}
                rowSelection={rowSelection}
              ></Table>
              <div className="col-lg-12 mt-4">
                <button class="primary-btn" onClick={handleSubmit}>
                  {" "}
                  {traslate("UpdateSettings")}{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Navigation;
