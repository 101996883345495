import React, { useState, useRef } from "react";
import { Input, Row, Col, Select, Checkbox, Switch, Table, Menu, Dropdown, Button, Space } from "antd";
import { Tabs, Tab } from "react-bootstrap";
import MultiRangeSlider from "../../contacts/MultiRangeSlider"


import roundDiamond from "../../../assets/images/Round.jpg";
import colorDiamond from "../../../assets/images/yellow-diamond.jpg";

import yourDiamond from "../../../assets/images/your_diamonds.png";
import yourEarrings from "../../../assets/images/your_earrrings.png";
import yourMounting from "../../../assets/images/your_mounting.png";

import { Accordion } from "react-bootstrap";

const { Option } = Select;

const StudBuilderApplicationPreview = () => {

    const columns = [
        {
            title: '',
            dataIndex: 'checkbox',
            width: 50,
        },
        {
            title: 'Shape',
            dataIndex: 'shape',
            
        },
        {
            title: 'Carat',
            dataIndex: 'carat',
            width: 100,
        },
        {
            title: 'Color',
            dataIndex: 'color',
            width: 100,
        },
        {
            title: 'Clarity',
            dataIndex: 'clarity',
            width: 100,
        },
        {
            title: 'Cut',
            dataIndex: 'cut',
            width: 100,
        },
        {
            title: 'InHouse',
            dataIndex: 'inhouse',
            width: 100,
        },
        {
            title: 'Depth',
            dataIndex: 'depth',
            width: 100,
        },
        {
            title: 'Table',
            dataIndex: 'table',
            width: 100,
        },
        {
            title: 'Polish',
            dataIndex: 'polish',
            width: 100,
        },
        {
            title: 'Measurement',
            dataIndex: 'measurement',
            width: 150,
        },
        {
            title: 'Cert',
            dataIndex: 'cert',
            width: 100,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            width: 100,
        },
        {
            title: 'View',
            dataIndex: 'view',
            width: 100,
        },
    ];

    const data = [
        {
            key: '1',
            checkbox: <input type="checkbox" />,
            shape:<div className="d-flex"><img src={roundDiamond} width="20" /><span>Round</span></div>,
            carat: <span>0.31</span>,
            color: <span>J</span>,
            clarity: <span>IF</span>,
            cut: <span>Excellent</span>,
            inhouse: <span>No</span>,
            depth: <span>60</span>,
            table: <span>50</span>,
            polish: <span>Good</span>,
            measurement: <span>4.86X4.79X2.86</span>,
            cert: <span>GIA</span>,
            price: <span>US$1,358</span>,
            view:<i class="fa fa-eye" aria-hidden="true"></i>,
        },
        {
            key: '1',
            checkbox: <input type="checkbox" />,
            shape:<div><img src={roundDiamond} width="20" /><span>Round</span></div>,
            sku: <span>177782295</span>,
            carat: <span>0.31</span>,
            color: <span>J</span>,
            clarity: <span>IF</span>,
            cut: <span>Excellent</span>,
            inhouse: <span>No</span>,
            depth: <span>60</span>,
            table: <span>50</span>,
            polish: <span>Good</span>,
            measurement: <span>4.86X4.79X2.86</span>,
            cert: <span>GIA</span>,
            price: <span>US$1,358</span>,
            view:<i class="fa fa-eye" aria-hidden="true"></i>,
        },
    ];


    // for image upload

    const columns2 = [
        {
            title: 'Date',
            dataIndex: 'date',
        },
        {
            title: 'Time',
            dataIndex: 'time',
        },
        {
            title: 'File',
            dataIndex: 'file',
        },
        // {
        //     title: 'Type',
        //     dataIndex: 'type',
        // },
        {
            title: 'Status',
            dataIndex: 'status',
        },
        {
            title: 'Records',
            dataIndex: 'records',
        },
        {
            title: 'Report',
            dataIndex: 'report',
        }
    ];

    const data2 = [
        {
            key: '1',
            date: <span>07/30/2019</span>,
            time: <span>11:50 AM</span>,
            file: <span>720_07302019110702.zip</span>,
            // type: <span>Add & Update</span>,
            status: <span>Completed</span>,
            records: <span>15 of 15</span>,
            report: <a id="Download" href="https://platform.stage.jewelcloud.com/DownloadReport.aspx?SummaryID=117857&amp;Type=Jewel&amp;DealerID=720">Download</a>,
        },
    ];

    // for daimond

    const columns3 = [
        {
            title: 'Date',
            dataIndex: 'date',
        },
        {
            title: 'Time',
            dataIndex: 'time',
        },
        {
            title: 'File',
            dataIndex: 'file',
        },
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: 'Status',
            dataIndex: 'status',
        },
        {
            title: 'Records',
            dataIndex: 'records',
        },
        {
            title: 'Report',
            dataIndex: 'report',
        }
    ];

    const data3 = [
        {
            key: '1',
            date: <span>10/06/2020</span>,
            time: <span>12:30 PM</span>,
            file: <span>JsonTestFile2-ItemList.json</span>,
            type: <span>Add & Update</span>,
            status: <span>Completed</span>,
            records: <span>9 of 22</span>,
            report: <a id="Download" href="https://platform.stage.jewelcloud.com/DownloadReport.aspx?SummaryID=117857&amp;Type=Jewel&amp;DealerID=720">Download</a>,
        },
    ];

    const resetRange = useRef(null);
    return (
        <React.Fragment>
            <div className="prodetail__section">
                <div className="form__fields">

                    <div className="col-md-12">
                        <div className="prod__maindiv app__preview p-0 border-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4 className="workarea__heading mt-0">Application Preview</h4>
                                </div>
                            </div>

                            <div className="row steps__section">
                                <div className="col-md-12">
                                     <h4 className="workarea__heading">Create Your Own Diamond Studs in 3 easy steps with Stud Builder</h4>
                                </div>
                                <div className="col-md-4">
                                    <div className="steps__block">
                                        <h4>1. Select Your Diamonds</h4>
                                        <img src={yourDiamond} />
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="steps__block">
                                        <h4>2. Choose Your Mounting</h4>
                                        <img src={yourMounting} />
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="steps__block">
                                        <h4>3. Complete Your Earrings</h4>
                                        <img src={yourEarrings} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                
                                <div className="col-lg-12 col-md-12">
                                    <div className="prodesc__div">
                                        <Tabs
                                            defaultActiveKey="standard"
                                            id="uncontrolled-tab-example"
                                            className="tab__div mb-3"
                                        >
                                            <Tab
                                                eventKey="standard"
                                                title="Standard"
                                            >
                                                <div className="tabs__content">
                                                    <div className="diamond__details">
                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <div className="row text__block">
                                                                    <div className="diamond__imgs col-md-12">
                                                                        <div className="img__block selected">
                                                                            <img src={roundDiamond} />
                                                                            <p>Round</p>
                                                                        </div>
                                                                       
                                                                        <div className="img__block">
                                                                            <img src={roundDiamond} />
                                                                            <p>Princess</p>
                                                                        </div>
                                                                        
                                                                        
                                                                    </div>
                                                                
                                                                    <div className="col-lg-6 col-md-6 mb-5">
                                                                        <div className="sliderrange__maindiv">
                                                                            <label> Carat </label>
                                                                            <div className="col-md-12 mt-3">
                                                                            <MultiRangeSlider
                                                                                min={0}
                                                                                max={37440}
                                                                                resetRange={resetRange}
                                                                                onChange={({ min, max }) =>
                                                                                console.log(`min = ${min}, max = ${max}`)
                                                                                }
                                                                            />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 mb-5">
                                                                        <div className="sliderrange__maindiv">
                                                                            <label> Price </label>
                                                                            <div className="col-md-12 mt-3">
                                                                            <MultiRangeSlider
                                                                                min={0}
                                                                                max={37440}
                                                                                resetRange={resetRange}
                                                                                onChange={({ min, max }) =>
                                                                                console.log(`min = ${min}, max = ${max}`)
                                                                                }
                                                                            />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12 mt-4">
                                                                        <label>Cut</label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p>Ideal</p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p>Excellent</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>Very Good</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>Good</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>Fair</p>
                                                                            </div>                                              
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12 mt-4">
                                                                        <label>Color</label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p>D</p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p>E</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>F</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>G</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>I</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>J</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>K</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>L</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>M</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>N</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>0</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>P</p>
                                                                            </div>                                              
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12 mt-4">
                                                                        <label>Clarity</label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p>FL</p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p>IF</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VVS1</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VVS2</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VS1</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VVS0</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI1</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI2</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI3</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI4</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI12</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI15</p>
                                                                            </div>                                              
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="image__block">
                                                                    <h3>Diamond Preview</h3>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <img src={roundDiamond} className="w-75" />
                                                                            <p>Stock Number: 78442524</p>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <img src={roundDiamond} className="w-75" />
                                                                            <p>Stock Number: 78442524</p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <h4>US$789</h4>
                                                                    <a href="#">Add To Stud</a>
                                                                    <button className="primary-btn">View Details</button>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <div className="table__block">
                                                                    <h5>2103 SIMILAR DIAMONDS</h5>
                                                                     <Table columns={columns} dataSource={data} />
                                                                </div>
                                                                <div className="compare__btn">
                                                                    <button className="primary-btn">Compare</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </Tab>

                                             <Tab
                                                eventKey="Advanced"
                                                title="Advanced"
                                            >
                                               <div className="tabs__content">
                                                    <div className="diamond__details">
                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <div className="row text__block">
                                                                    <div className="diamond__imgs col-md-12">
                                                                        <div className="img__block selected">
                                                                            <img src={roundDiamond} />
                                                                            <p>Round</p>
                                                                        </div>
                                                                       
                                                                        <div className="img__block">
                                                                            <img src={roundDiamond} />
                                                                            <p>Princess</p>
                                                                        </div>
                                                                        
                                                                        
                                                                    </div>
                                                                
                                                                    <div className="col-lg-6 col-md-6 mb-5">
                                                                        <div className="sliderrange__maindiv">
                                                                            <label> Carat </label>
                                                                            <div className="col-md-12 mt-3">
                                                                            <MultiRangeSlider
                                                                                min={0}
                                                                                max={37440}
                                                                                resetRange={resetRange}
                                                                                onChange={({ min, max }) =>
                                                                                console.log(`min = ${min}, max = ${max}`)
                                                                                }
                                                                            />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 mb-5">
                                                                        <div className="sliderrange__maindiv">
                                                                            <label> Price </label>
                                                                            <div className="col-md-12 mt-3">
                                                                            <MultiRangeSlider
                                                                                min={0}
                                                                                max={37440}
                                                                                resetRange={resetRange}
                                                                                onChange={({ min, max }) =>
                                                                                console.log(`min = ${min}, max = ${max}`)
                                                                                }
                                                                            />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12 mt-4">
                                                                        <label>Cut</label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p>Ideal</p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p>Excellent</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>Very Good</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>Good</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>Fair</p>
                                                                            </div>                                              
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12 mt-4">
                                                                        <label>Color</label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p>D</p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p>E</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>F</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>G</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>I</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>J</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>K</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>L</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>M</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>N</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>0</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>P</p>
                                                                            </div>                                              
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12 mt-4">
                                                                        <label>Clarity</label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p>FL</p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p>IF</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VVS1</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VVS2</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VS1</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VVS0</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI1</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI2</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI3</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI4</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI12</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI15</p>
                                                                            </div>                                              
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-6 mt-4">
                                                                        <label>Polish</label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p>Excellent</p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p>Very Good</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>Good</p>
                                                                            </div>                                             
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-6 mt-4">
                                                                        <label>Symmetry</label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p>Excellent</p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p>Very Good</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>Good</p>
                                                                            </div>                                             
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-6 mt-4">
                                                                        <label>Fluorescence</label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p>N</p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p>FNT</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>MED</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>ST</p>
                                                                            </div> 

                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-3 mt-4">
                                                                        <div className="input__block">
                                                                            <label>Depth</label> 
                                                                            <input type="number" />                          
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-3 mt-4">
                                                                        <div className="input__block">
                                                                            <label>Table</label> 
                                                                            <input type="number" />                          
                                                                        </div>
                                                                    </div>

                                                                    

                                                                        <div className="col-md-6 mt-4">
                                                                        <div className="input__block">
                                                                            <label>Certificates</label>
                                                                            <Select
                                                                                className="border__grey"
                                                                                placeholder="Certificates"
                                                                                name="productType"
                                                                                optionFilterProp="children"
                                                                            >
                                                                                <Option value="Option 1">Option 1</Option>
                                                                                <Option value="Option 2">Option 2</Option>
                                                                            </Select>                        
                                                                            </div>
                                                                        </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="image__block">
                                                                    <h3>Diamond Preview</h3>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <img src={roundDiamond} className="w-75" />
                                                                            <p>Stock Number: 78442524</p>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <img src={roundDiamond} className="w-75" />
                                                                            <p>Stock Number: 78442524</p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <h4>US$789</h4>
                                                                    <a href="#">Add To Stud</a>
                                                                    <button className="primary-btn">View Details</button>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <div className="table__block">
                                                                    <h5>2103 SIMILAR DIAMONDS</h5>
                                                                     <Table columns={columns} dataSource={data} />
                                                                </div>
                                                                <div className="compare__btn">
                                                                    <button className="primary-btn">Compare</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </Tab>
                                        
                                
                                            <Tab
                                                eventKey="Request"
                                                title="Request"
                                            >
                                                <div className="tabs__content">
                                                    <div className="diamond__details">
                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <div className="row text__block">
                                                                    <div className="diamond__imgs col-md-12">
                                                                        <div className="img__block selected">
                                                                            <img src={roundDiamond} />
                                                                            <p>Round</p>
                                                                        </div>
                                                                        <div className="img__block">
                                                                            <img src={roundDiamond} />
                                                                            <p>Radiant</p>
                                                                        </div>
                                                                        <div className="img__block">
                                                                            <img src={roundDiamond} />
                                                                            <p>Princess</p>
                                                                        </div>
                                                                        <div className="img__block">
                                                                            <img src={roundDiamond} />
                                                                            <p>Pear</p>
                                                                        </div>
                                                                        <div className="img__block">
                                                                            <img src={roundDiamond} />
                                                                            <p>Oval</p>
                                                                        </div>
                                                                        <div className="img__block">
                                                                            <img src={roundDiamond} />
                                                                            <p>Marquise</p>
                                                                        </div>
                                                                        <div className="img__block">
                                                                            <img src={roundDiamond} />
                                                                            <p>Heart</p>
                                                                        </div>
                                                                        <div className="img__block">
                                                                            <img src={roundDiamond} />
                                                                            <p>Princess</p>
                                                                        </div>
                                                                        <div className="img__block">
                                                                            <img src={roundDiamond} />
                                                                            <p>Pear</p>
                                                                        </div>
                                                                        <div className="img__block">
                                                                            <img src={roundDiamond} />
                                                                            <p>Oval</p>
                                                                        </div>
                                                                        <div className="img__block">
                                                                            <img src={roundDiamond} />
                                                                            <p>Princess</p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                
                                                                    <div className="col-lg-6 col-md-6 mb-5">
                                                                        <div className="sliderrange__maindiv">
                                                                            <label> Carat </label>
                                                                            <div className="col-md-12 mt-3">
                                                                            <MultiRangeSlider
                                                                                min={0}
                                                                                max={37440}
                                                                                resetRange={resetRange}
                                                                                onChange={({ min, max }) =>
                                                                                console.log(`min = ${min}, max = ${max}`)
                                                                                }
                                                                            />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 mb-5">
                                                                        <div className="sliderrange__maindiv">
                                                                            <label> Price </label>
                                                                            <div className="col-md-12 mt-3">
                                                                            <MultiRangeSlider
                                                                                min={0}
                                                                                max={37440}
                                                                                resetRange={resetRange}
                                                                                onChange={({ min, max }) =>
                                                                                console.log(`min = ${min}, max = ${max}`)
                                                                                }
                                                                            />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12 mt-4">
                                                                        <label>Cut</label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p>Ideal</p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p>Excellent</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>Very Good</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>Good</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>Fair</p>
                                                                            </div>                                              
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12 mt-4">
                                                                        <label>Color</label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p>D</p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p>E</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>F</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>G</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>I</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>J</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>K</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>L</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>M</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>N</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>0</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>P</p>
                                                                            </div>                                              
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-12 mt-4">
                                                                        <label>Clarity</label>
                                                                        <div className="selection__block row">
                                                                            <div className="selection__box selected col">
                                                                                <p>FL</p>
                                                                            </div>
                                                                            <div className="selection__box selected col">
                                                                                <p>IF</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VVS1</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VVS2</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VS1</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>VVS0</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI1</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI2</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI3</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI4</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI12</p>
                                                                            </div>
                                                                            <div className="selection__box col">
                                                                                <p>SI15</p>
                                                                            </div>                                              
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="image__block">
                                                                    <p className="mt-5">Please enter the details of the type of diamond you are looking for and your contact information.</p>
                                                                    <p>Your information will be submitted to one of our diamond specialists that will contact you.</p>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <div className="table__block">
                                                                     <div className="row p-0 border-0 form__fields mh-auto">
                                                                        <div className="col-md-6">
                                                                            <div className="input__block">
                                                                                <label>First Name</label>
                                                                                <input type="text" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="input__block">
                                                                                <label>Last Name</label>
                                                                                <input type="text" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="input__block">
                                                                                <label>Phone Number</label>
                                                                                <input type="text" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="input__block">
                                                                                <label>Email Address</label>
                                                                                <input type="text" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="input__block">
                                                                                <label>Add Comments Here</label>
                                                                                <textarea></textarea>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="save__form">
                                                                                <button className="primary-btn mr-2">Reset</button>
                                                                                <button className="primary-btn">Request</button>
                                                                            </div>
                                                                        </div>
                                                                     </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </Tab>
                                           
                                        </Tabs>

                                        {/* <div class="col-md-6 mt-3">
                                        <button class="primary-btn">Save Details</button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default StudBuilderApplicationPreview;
