import React from "react";
import DiamondSearch from "./DiamondSearch";

const LabDiamondSearch = (props) => {
  return (
    <React.Fragment>
      <DiamondSearch type="LAB" />
    </React.Fragment>
  );
};

export default LabDiamondSearch;
