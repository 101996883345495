import React, { useEffect, useState } from "react";
import { Switch, Table, Button } from "antd";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import traslate from "../../../i18n/translate";

const BlockDesignersRetailer = () => {
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [blockDesignersList, setBlockDesignersList] = useState([]);
  const [disableBtn, setDisableBtn] = useState(true);
  useEffect(() => {
    handleGetBlockDesignersRetailer();
  }, []);

  const handleSwitch = (e, id) => {
    setDisableBtn(false);
    const tempBlockDesignersList = blockDesignersList.map((x) => {
      if (x.designerID === id) {
        return { ...x, isSelected: e };
      } else return x;
    });
    setBlockDesignersList(tempBlockDesignersList);
  };
  const handleGetBlockDesignersRetailer = () => {
    try {
      setLoading(true);
      AdminToolsDataService.GetBlockDesignersRetailer()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message == "Success") {
            setBlockDesignersList(responseData);
          } else {
            setBlockDesignersList([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleSaveBlockDesignersRetailer = () => {
    // var selectedList = [];
    // selectedList = blockDesignersList.filter((x) => x.isSelected === true);
    if (blockDesignersList.length > 0) {
      try {
        setSubmitLoading(true);
        AdminToolsDataService.SaveBlockDesignersRetailer(blockDesignersList)
          .then((response) => {
            var message = response.data.message;
            setSubmitLoading(false);
            if (message == "Success") {
              NotificationManager.success("Record Updated Successfully.");
              handleGetBlockDesignersRetailer();
            } else {
              NotificationManager.error("Record Not Updated");
            }
          })
          .catch((error) => {
            setSubmitLoading(false);
            console.log(error);
          });
      } catch (error) {
        setSubmitLoading(false);
        console.log(error);
      }
    }
    setDisableBtn(true);
  };
  return (
    <React.Fragment>
      <div className="blockdesign_section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
              {traslate("BlockDesignersForDemoRetailer")}
              </h4>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="blockdesign_tbldiv">
                <Table
                  columns={[
                    {
                      title: traslate("DesignerName") ,
                      dataIndex: "designername",
                    },
                    {
                      title: traslate("IsBlocked?") ,
                      dataIndex: "isSelected",
                      render: (row, item) => {
                        return (
                          <React.Fragment>
                            <div className="input__block">
                              <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={item.isSelected}
                                onChange={(e) =>
                                  handleSwitch(e, item.designerID)
                                }
                              />
                            </div>
                          </React.Fragment>
                        );
                      },
                      width: 150
                    },
                  ]}
                  dataSource={blockDesignersList}
                  scroll={{ x: 300 }}
                  loading={loading}
                />
              </div>
            </div>

            <div className="col-lg-12">
              <Button
                className={disableBtn ? "disabled-btn" : "primary-btn"}
                disabled={disableBtn}
                onClick={handleSaveBlockDesignersRetailer}
                loading={submitLoading}
              >
                {traslate("UpdateSelection")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlockDesignersRetailer;
