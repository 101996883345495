import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import axios from "axios";
import AdminSettingsDataService from "../../../services/admin-settings.service";
import { NotificationManager } from "react-notifications";
import { Input, Select, Table } from "antd";
import {
  EditOutlined,
  CalendarOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import traslate from "../../../i18n/translate";

const { Option } = Select;

export const ApiAccessToken = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [isModalVisible3, setIsModalVisible3] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [ApiAccessTokenData, setApiAccessTokenData] = useState({
    apiType: "",
  });
  const ref = useRef(null);
  const [cursor, setCursor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
    handleGetApiAccessTokenList();
  }, []);

  const handleGetApiAccessTokenList = () => {
    try {
      setLoading(true);
      AdminSettingsDataService.GetApiAccessTokenList()
        .then((response) => {
          var responseData = response.data.responseData;
          var message = response.data.message;
          setLoading(false);
          if (message == "Success") {
            setData(responseData);
          } else {
            setData([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleSaveApiAccessToken = (Id) => {
    var selectedList = [];
    selectedList = data.filter((x) => x.dealerID === Id);
    console.log(selectedList);
    try {
      setSubmitLoading(true);
      AdminSettingsDataService.SaveApiAccessToken(
        selectedList[0].apiAccessToken
      )
        .then((response) => {
          var message = response.data.message;
          setSubmitLoading(false);
          if (message == "Success") {
            NotificationManager.success("Record Updated Successfully.");
            handleGetApiAccessTokenList();
          } else {
            NotificationManager.error("Record Not Updated");
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
    }
  };

  const handleOnChange = (e, isSelect, Id, name) => {
    debugger;

    if (isSelect === "select") {
      const checkData = data.filter((x) => x.dealerID === Id);
      if (checkData[0].apiAccessToken === null) {
        var ObjData = {
          dealerId: Id.toString(),
          apiType: e,
          createdBy: 0,
        };

        data.filter((x) => x.dealerID === Id)[0].apiAccessToken = ObjData;
      } else {
        data.filter((x) => x.dealerID === Id)[0].apiAccessToken.apiType = e;
      }
    } else {
      setCursor(e.target.selectionStart);
      const checkData = data.filter((x) => x.dealerID === Id);
      if (checkData[0].apiAccessToken === null) {
        var ObjData = {
          dealerId: Id.toString(),
          accessToken: e.target.value,
          createdBy: 0,
        };

        data.filter((x) => x.dealerID === Id)[0].apiAccessToken = ObjData;
      } else {
        data.filter((x) => x.dealerID === Id)[0].apiAccessToken.accessToken =
          e.target.value;
      }
    }
    setData(data);
    console.log(data, "data");
  };

  function onSearch(val) {
    console.log("search:", val);
  }

  return (
    <div className="row form__fields magento__mapping">
      <div className="col-md-12">
        <div className="heading__block">
          <h4 class="workarea__heading mt-0 mb-0">
            {" "}
            {traslate("API Access Token")}{" "}
          </h4>
        </div>
      </div>
      <div className="divForm form__fields border-0 bg-transparent">
        <Table
          columns={[
            {
              title: traslate("RetailerID"),
              dataIndex: "dealerID",
              key: "dealerID",
              fixed: "left",
              width: 120,
            },
            {
              title: traslate("RetailerName"),
              dataIndex: "dealerCompany",
              key: "retailername",
              fixed: "left",
            },
            {
              title: traslate("APIType"),
              dataIndex: "apiAccessToken",
              key: "apiAccessToken",
              render: (data, item) => {
                return (
                  <div className="input__block mb-0">
                    <Select
                      name="apiType"
                      showSearch
                      // placeholder="Select"
                      optionFilterProp="children"
                      onChange={(e) =>
                        handleOnChange(e, "select", item.dealerID)
                      }
                      className="border__grey"
                      value={
                        item.apiAccessToken?.apiType
                          ? item.apiAccessToken?.apiType
                          : null
                      }
                    >
                      <Option className="" value={null}>
                        Select
                      </Option>
                      <Option value="Diamond">Diamond</Option>
                      <Option value="Setting">Setting</Option>
                      <Option value="Jewelry">Jewelry</Option>
                    </Select>
                  </div>
                );
              },
            },
            {
              title: traslate("APIKey"),
              dataIndex: "accessToken",
              key: "apiAccessToken",
              render: (data, item) => {
                return (
                  <Input.Password
                    ref={ref}
                    className="apikeytxt"
                    value={item?.apiAccessToken?.accessToken}
                    onChange={(e) => handleOnChange(e, "input", item.dealerID)}
                  />
                );
              },
            },
            {
              title: traslate("Update"),
              dataIndex: "update",
              key: "update",
              render: (row, data) => {
                return (
                  <button
                    className="primary-btn"
                    onClick={() => handleSaveApiAccessToken(data.dealerID)}
                    loading={submitLoading}
                  >
                    {traslate("Save")}
                  </button>
                );
              },
              width: 120,
            },
          ]}
          dataSource={data}
          loading={loading}
          scroll={{ x: 600 }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ApiAccessToken);
