import { httpCatalog } from "../helper/http-common";

class AppsRingBuilderService{
//Diamond Markup
    
GetCurrencyList() {
    debugger;
 return httpCatalog.get("/ProductMarkUp/GetCurrencyList");
}
      
GetRoundingList() {
  return httpCatalog.get("/ProductMarkUp/GetRoundingList");
}

}

export default new AppsRingBuilderService();