import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table
} from "antd";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { Tabs, Tab } from "react-bootstrap";
import traslate from "../../i18n/translate";


import RetailerLogo from "../../assets/images/retailer-logo.jpeg";


const MakeAppoitments = () => {
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;

  const style = <span>content not provided.</span>;

  

  function onSearch(val) {
    console.log("search:", val);
  }

  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };


  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
            <div className="row">
                <div className="col-md-12">
                    <h4 className="workarea__heading mt-0">Book Appointment </h4>
                </div>
            </div>

            <div className="row">
                <div className="col-md-4">
                    <div className="input__block">
                        <label>Company Name</label>
                        <Select
                            className="border__grey"
                            placeholder="Company Name"
                            name="productType"
                            optionFilterProp="children"
                        >
                            <Option value="Option 1">Option 1</Option>
                            <Option value="Option 2">Option 2</Option>
                        </Select>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input__block">
                        <label>Location</label>
                        <Select
                            className="border__grey"
                            placeholder="Location"
                            name="productType"
                            optionFilterProp="children"
                        >
                            <Option value="Option 1">Option 1</Option>
                            <Option value="Option 2">Option 2</Option>
                        </Select>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="input__block">
                        <label>Buyer Name</label>
                        <input type="text" />
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="input__block">
                    <label>Appointment Description</label>
                    <CKEditor
                        editor={ClassicEditor}
                        // id="editor"
                        onReady={(editor) => {
                           
                        }}
                        />
                    </div>
                </div>
            </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MakeAppoitments;
