import React, { useState, useEffect } from "react";
import { Select, Button, Switch, Table, Modal, Slider } from "antd";
import emerald from "../../assets/images/emerald.jpg";
import Roundimg from "../../assets/images/Round_65x65.jpg";
import imageIcon from "../../assets/images/imageIcon.png";
import { Link } from "react-router-dom";
import traslate from "../../i18n/translate";
import Filter_icon from "../../assets/images/filter_icon.png";
import { useSelector } from "react-redux";
import catalogDiamondService from "../../services/catalog-diamond.service";
const { Option } = Select;
const DiamondSearch = (props) => {
  const [hideShowFilter, setHideShowFilter] = useState(true);

  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [visible4, setVisible4] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [caratSlideValue, setCaratSlideValue] = useState([0, 22]);
  const [priceValue, setPriceValue] = useState([0, 2000]);
  const [priceCaratValue, setPriceCaratValue] = useState([0, 22000]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [diamondList, setDiamondList] = useState([]);
  const [selectedRowDetails, setSelectedRowDetails] = useState({});

  const [fancyColorList, setFancyColorList] = useState([]);
  const [intensityList, setIntensityList] = useState([]);
  const [overtoneList, setOverToneList] = useState([]);
  // For Apply Filter Button
  const [disableBtn, setDisableBtn] = useState(true);

  const initialState = {
    DealerID: [],
    CertificateID: [],
    Shapes: [],
    CutFrom: null,
    CutTo: null,
    CaratMin: 0,
    CaratMax: 22,
    PriceMin: 0,
    PriceMax: 2000,
    PriceCaratMin: 0,
    PriceCaratMax: 22000,
    ClarityFrom: null,
    ClarityTo: null,
    ColorFrom: null,
    ColorTo: null,
    DepthMin: 0,
    DepthMax: 22,
    TableMin: null,
    TableMax: null,
    PolishFrom: null,
    PolishTo: null,
    LengthMin: null,
    LengthMax: null,
    WidthMin: null,
    WidthMax: null,
    HeigthMin: null,
    HeigthMax: null,
    SummetryFrom: null,
    SummetryTo: null,
    GirdleFrom: null,
    GirdleTo: null,
    CrownAngleMin: null,
    CrownAngleMax: null,
    PavilionAngleMin: null,
    PavilionAngleMax: null,
    CuletFrom: null,
    CuletTo: null,
    CuletCondFrom: null,
    CuletCondTo: null,
    FluorescenceFrom: null,
    FluorescenceTo: null,
    MatchPairID: null,
    HasVideo: false,
    ShapeIsSelected: {
      Round: false,
      Oval: false,
      Circle: false,
      Heart: false,
      Emarald: false,
      Radiant: false,
      All: false,
    },
    FancyColor: null,
    Overtone: null,
    Intensity: null,
  };
  const [state, setState] = useState(initialState);
  const [selectedItem, setSelectedItem] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);

  const [dealerList, setDealerList] = useState([]);
  const [certificateList, setCertificateList] = useState([]);
  const [cutList, setCutList] = useState([]);
  const [clarityList, setClarityList] = useState([]);
  const [colorList] = useState([
    {
      colorID: null,
      colorName: "Any",
    },
    {
      colorID: 68,
      colorName: "D",
    },
    {
      colorID: 69,
      colorName: "E",
    },
    {
      colorID: 70,
      colorName: "F",
    },
    {
      colorID: 71,
      colorName: "G",
    },
    {
      colorID: 72,
      colorName: "H",
    },
    {
      colorID: 73,
      colorName: "I",
    },
    {
      colorID: 74,
      colorName: "J",
    },
    {
      colorID: 75,
      colorName: "K",
    },
    {
      colorID: 76,
      colorName: "L",
    },
    {
      colorID: 77,
      colorName: "M",
    },
    {
      colorID: 78,
      colorName: "N",
    },
    {
      colorID: 79,
      colorName: "O",
    },
    {
      colorID: 80,
      colorName: "P",
    },
    {
      colorID: 81,
      colorName: "Q",
    },
    {
      colorID: 82,
      colorName: "R",
    },
    {
      colorID: 83,
      colorName: "S",
    },
    {
      colorID: 84,
      colorName: "T",
    },
    {
      colorID: 85,
      colorName: "U",
    },
    {
      colorID: 86,
      colorName: "V",
    },
    {
      colorID: 87,
      colorName: "W",
    },
    {
      colorID: 88,
      colorName: "X",
    },
    {
      colorID: 89,
      colorName: "Y",
    },
    {
      colorID: 90,
      colorName: "Z",
    },
  ]);
  const [polishList, setPolishList] = useState([]);
  const [summetryList, setSummetryList] = useState([]);
  const [girdleList, setGirdleList] = useState([]);
  const [culetList, setCuletList] = useState([]);
  const [culetCondList, setCuletCondList] = useState([]);
  const [fluorescenceList] = useState([
    {
      fluorescenceID: 1,
      fluorescenceName: "None",
    },
    {
      fluorescenceID: 2,
      fluorescenceName: "Very Slight",
    },
    {
      fluorescenceID: 3,
      fluorescenceName: "Slight",
    },
    {
      fluorescenceID: 4,
      fluorescenceName: "Faint",
    },
    {
      fluorescenceID: 5,
      fluorescenceName: "Medium",
    },
    {
      fluorescenceID: 6,
      fluorescenceName: "Strong",
    },
    {
      fluorescenceID: 7,
      fluorescenceName: "Very Strong",
    },
  ]);
  const [matchedPairList] = useState([
    {
      matchedPairID: 1,
      matchedPairName: "Search Single Stone",
    },
    {
      matchedPairID: 2,
      matchedPairName: "Search Match Pairs",
    },
  ]);
  const [dealerId, setDealerId] = useState(0);
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.responseData) {
        handleGetDealersForDiamondCatalogList(
          loginDetials.responseData.dealerId
        );
        setDealerId(loginDetials.responseData.dealerId);
      }
    }
  }, []);
  const handleGetDealersForDiamondCatalogList = async (id) => {
    try {
      let inputData = {
        type: props.type || "Mined",
        dealerId: id,
      };
      await catalogDiamondService
        .GetDealersForDiamondCatalogList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          handleGetCertificatesForDiamondCatalogList(id);
          if (message == "Success") {
            setDealerList(responseData);
          } else {
            setDealerList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetCertificatesForDiamondCatalogList = async (id) => {
    try {
      let inputData = {
        type: props.type || "Mined",
      };
      await catalogDiamondService
        .GetCertificatesForDiamondCatalogList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          handleGetAllMINEDropDownList();
          handleGetDynamicData(id);
          if (message == "Success") {
            setCertificateList(responseData);
          } else {
            setCertificateList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetAllMINEDropDownList = async () => {
    try {
      let inputData = {
        type: props.type || "Mined",
      };
      await catalogDiamondService
        .GetAllMINEDropDownList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          handleGetAllDropdownData();
          if (message == "Success") {
            setCutList(responseData[0]);
            setClarityList(responseData[1]);
            setPolishList(responseData[2]);
            setSummetryList(responseData[3]);
            setGirdleList(responseData[4]);
            setCuletList(responseData[5]);
            setCuletCondList(responseData[6]);
          } else {
            setCutList([]);
            setClarityList([]);
            setPolishList([]);
            setSummetryList([]);
            setGirdleList([]);
            setCuletList([]);
            setCuletCondList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk1 = () => {
    setVisible1(true);
  };

  const handleCancel1 = () => {
    setVisible1(false);
  };

  const handleCancel3 = () => {
    setVisible3(false);
  };

  const handleCancel4 = () => {
    setVisible4(false);
  };

  const handleDeleteModal = () => {
    if (selectedRows.length > 0) {
      setVisible3(true);
    } else {
      alert("Please select diamonds from list to delete.");
    }
  };
  const handleSendMailModal = () => {
    if (selectedRows.length > 0) {
    } else {
      alert("Please Select Diamonds From List To Send Via Email.");
    }
  };

  const onChangeMultiDrop = (e, name) => {
    let tempState = state;
    tempState[name] = e;

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    setDisableBtn(false);
  };

  const onChangeDrop = (e, name) => {
    let tempState = state;
    tempState[name] = e;

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));

    setDisableBtn(false);
  };

  const onChange = (e, name) => {
    let tempState = state;
    tempState[name] = e.target.value;

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    setDisableBtn(false);
  };

  const onSwitchChange = (e, name) => {
    let tempState = state;
    tempState[name] = e;

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    setDisableBtn(false);
  };

  const onResetFilter = () => {
    debugger;
    let tempState = state;
    tempState.DealerID = [];
    tempState.CertificateID = [];
    tempState.CutFrom = null;
    tempState.CutTo = null;
    tempState.ClarityFrom = null;
    tempState.ClarityTo = null;
    tempState.ColorFrom = null;
    tempState.ColorTo = null;
    tempState.DepthMin = 0;
    tempState.DepthMax = 22;
    tempState.TableMin = "";
    tempState.TableMax = "";
    tempState.PolishFrom = null;
    tempState.PolishTo = null;
    tempState.LengthMin = null;
    tempState.LengthMax = null;
    tempState.WidthMin = null;
    tempState.WidthMax = null;
    tempState.HeigthMin = null;
    tempState.HeigthMax = null;
    tempState.SummetryFrom = null;
    tempState.SummetryTo = null;
    tempState.GirdleFrom = null;
    tempState.GirdleTo = null;
    tempState.CrownAngleMin = "";
    tempState.CrownAngleMax = "";
    tempState.PavilionAngleMin = "";
    tempState.PavilionAngleMax = "";
    tempState.CuletFrom = null;
    tempState.CuletTo = null;
    tempState.CuletCondFrom = null;
    tempState.CuletCondTo = null;
    tempState.FluorescenceFrom = null;
    tempState.FluorescenceTo = null;
    tempState.MatchPairID = null;
    tempState.HasVideo = false;
    state.ShapeIsSelected.All = false;
    state.ShapeIsSelected.Circle = false;
    state.ShapeIsSelected.Emarald = false;
    state.ShapeIsSelected.Heart = false;
    state.ShapeIsSelected.Oval = false;
    state.ShapeIsSelected.Radiant = false;
    state.ShapeIsSelected.Round = false;

    setCaratSlideValue([state.CaratMin, state.CaratMax]);
    setPriceValue([state.PriceMin, state.PriceMax]);
    setPriceCaratValue([state.PriceCaratMin, state.PriceCaratMax]);
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    setDisableBtn(true);
  };

  const onShapeClick = (shape) => {
    setDisableBtn(false);
    if (shape === "All") {
      state.ShapeIsSelected.All = !state.ShapeIsSelected.All;
      state.ShapeIsSelected.Circle = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Emarald = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Heart = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Oval = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Radiant = state.ShapeIsSelected.All;
      state.ShapeIsSelected.Round = state.ShapeIsSelected.All;
    } else {
      state.ShapeIsSelected.All = false;
      state.ShapeIsSelected[shape] = !state.ShapeIsSelected[shape];
    }

    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
    console.log(state.Shapes);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedItem(selectedRows.length);
      setSelectedRows(selectedRows);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };

  const handleHideShowFilter = () => {
    setHideShowFilter(!hideShowFilter);
  };
  const handleSendInviteMailForDiamonds = async () => {
    try {
      let inputData = {
        dealerId: 0,
        diamondDetailList: [],
        name: "",
        eMail: "",
        markUp: 0,
        message: "",
        mailType: "",
      };
      await catalogDiamondService
        .SendInviteMailForDiamonds(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteDiamondByIds = async () => {
    try {
      let inputData = {
        dealerId: dealerId,
        diamondIds: 0,
      };
      await catalogDiamondService
        .DeleteDiamondByIds(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetDiamondList = async (id) => {
    var inputData = {
      dealerID: id ? id + "" : dealerId + "",
      shapes: "",
      colorMin: state.ColorFrom ? state.ColorFrom.toString() : "-1",
      colorMax: state.ColorTo ? state.ColorTo.toString() : "-1",
      clarityMin: state.ClarityFrom ? state.ClarityFrom.toString() : "-1",
      clarityMax: state.ClarityTo ? state.ClarityTo.toString() : "-1",
      caratMin: caratSlideValue[0] ? caratSlideValue[0].toString() : "0",
      caratMax: caratSlideValue[1] ? caratSlideValue[1].toString() : "20",
      priceMin: priceValue[0] ? priceValue[0].toString() : "0",
      priceMax: priceValue[1] ? priceValue[1].toString() : "1000000",
      certificate: state.CertificateID
        ? state.CertificateID.map((x) => x).join(",")
        : "",
      cutGradeMin: state.CutFrom ? state.CutFrom.toString() : "-1",
      cutGradeMax: state.CutTo ? state.CutTo.toString() : "-1",
      symmetryMin: state.SummetryFrom ? state.SummetryFrom.toString() : "-1",
      symmetryMax: state.SummetryTo ? state.SummetryTo.toString() : "-1",
      polishMin: state.PolishFrom ? state.PolishFrom.toString() : "-1",
      polishMax: state.PolishTo ? state.PolishTo.toString() : "-1",
      depthMin: state.DepthMin ? state.DepthMin.toString() : "-1",
      depthMax: state.DepthMax ? state.DepthMax.toString() : "-1",
      tableMin: state.TableMin ? state.TableMin.toString() : "-1",
      tableMax: state.TableMax ? state.TableMax.toString() : "-1",
      fluorescenceMin: state.FluorescenceFrom
        ? state.FluorescenceFrom.toString()
        : "-1",
      fluorescenceMax: state.FluorescenceTo
        ? state.FluorescenceTo.toString()
        : "-1",
      pairCode: "",
      orderBy: "tmpColumn Asc",
      orderType: "Asc",
      pageNumber: pageNo + "",
      pageSize: pageSize + "",
      invenID: "",
      strDetailLinkURL: "dealerlink",
      did: "",
      caratPriceMin: priceCaratValue[0] ? priceCaratValue[0].toString() : "0",
      caratPriceMax: priceCaratValue[1]
        ? priceCaratValue[1].toString()
        : "100000",
      polishList: "-1",
      symmetryList: "-1",
      fluorescenceList: "-1",
      cutGradeList: "-1",
      colorList: "-1",
      clarityList: "-1",
      sOrigin: "",
      intOptimize: 0,
      intCulet1: state.CuletFrom ? state.CuletFrom : 0,
      intCulet2: state.CuletTo ? state.CuletTo : 20,
      intCuletCondition1: state.CuletCondFrom ? state.CuletCondFrom : 0,
      intCuletCondition2: state.CuletCondTo ? state.CuletCondTo : 20,
      fltCrownAngle1: state.CrownAngleMin ? state.CrownAngleMin : 0,
      fltCrownAngle2: state.CrownAngleMax ? state.CrownAngleMax : 100,
      fltPavilionAngle1: state.PavilionAngleMin ? state.PavilionAngleMin : 0,
      fltPavilionAngle2: state.PavilionAngleMax ? state.PavilionAngleMax : 100,
      intGirdle1: state.GirdleFrom ? state.GirdleFrom : 0,
      intGirdle2: state.GirdleTo ? state.GirdleTo : 100,
      intCutGrade: 0,
      uploadDateMin: "",
      uploadDateMax: "",
      strSelectedDealers: "3504,3388,2875,3688,2419,1843,720,2139,1550",
      // strSelectedDealers: state.DealerID
      //   ? state.DealerID.map((x) => x).join(",")
      //   : "",
      selectedDealerConunt: 9,
      fancyColor: "",
      intIntensity: 0,
      overtone: "",
      overtone2: "",
      strMatchedPairs: state.MatchPairID ? state.MatchPairID.toString() : "0",
      fltWidth1: state.WidthMin ? state.WidthMin.toString() : "-1",
      fltWidth2: state.WidthMax ? state.WidthMax.toString() : "-1",
      fltHeight1: state.HeigthMin ? state.HeigthMin.toString() : "-1",
      fltHeight2: state.HeigthMax ? state.HeigthMax.toString() : "-1",
      fltLength1: state.LengthMin ? state.LengthMin.toString() : "-1",
      fltLength2: state.LengthMax ? state.LengthMax.toString() : "-1",
      strSelectedInvID: "",
      flagDD: "0",
      checkInventory: "Catalog",
      video: state.HasVideo ? "Yes" : "",
      isComm: 0,
    };

    try {
      await catalogDiamondService
        .GetDiamonds(inputData, props.type)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            setDiamondList(responseData);
          } else {
            setDiamondList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDynamicData = async (id) => {
    try {
      let inputData = {
        dealerId: id,
      };
      await catalogDiamondService
        .GetDynamicData(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          handleGetDiamondList(id);
          if (message == "Success") {
            const tempState = state;
            tempState.CaratMax = parseFloat(responseData.sldCaratMax);
            tempState.CaratMin = parseFloat(responseData.sldCaratMin);
            tempState.PriceMax = parseFloat(responseData.sldPriceMax);
            tempState.PriceMin = parseFloat(responseData.sldPriceMin);
            tempState.DepthMax = parseFloat(responseData.sldDepthMax);
            tempState.DepthMin = parseFloat(responseData.sldDepthMin);
            tempState.TableMax = parseFloat(responseData.sldTableMax);
            tempState.TableMin = parseFloat(responseData.sldTableMin);
            setCaratSlideValue([tempState.CaratMin, tempState.CaratMax]);
            setPriceValue([tempState.PriceMin, tempState.PriceMax]);
            setPriceCaratValue([0, tempState.PriceMax]);
            setState((prevState) => ({
              ...prevState,
              ...tempState,
            }));
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      title: "",
      dataIndex: "view",
      width: 40,
    },
    {
      title: traslate("Stock#"),
      dataIndex: "stock",
    },
    {
      title: traslate("Shape"),
      dataIndex: "shape",
    },
    {
      title: traslate("Size"),
      dataIndex: "size",
    },
    {
      title: traslate("Cut"),
      dataIndex: "cut",
    },
    {
      title: traslate("Color"),
      dataIndex: "color",
    },
    {
      title: traslate("Clarity"),
      dataIndex: "clarity",
    },
    {
      title: traslate("Off%"),
      dataIndex: "off",
    },
    {
      title: traslate("Cost"),
      dataIndex: "cost",
    },
    {
      title: traslate("Flour"),
      dataIndex: "flour",
    },
    {
      title: traslate("Dep."),
      dataIndex: "dep",
    },
    {
      title: traslate("Table"),
      dataIndex: "table",
    },
    {
      title: traslate("Measure"),
      dataIndex: "measure",
    },
    {
      title: traslate("Cert."),
      dataIndex: "cert",
    },
    {
      title: traslate("Polish"),
      dataIndex: "polish",
    },
    {
      title: traslate("Sym"),
      dataIndex: "sym",
    },
    {
      title: traslate("Dealer"),
      dataIndex: "dealer",
    },
    {
      title: traslate("Image/Video"),
      dataIndex: "imgvid",
      width: 100,
    },
  ];
  const handleCancel2 = () => {
    setVisible2(false);
  };
  const handleGetOverToneForDiamondCatalogList = async () => {
    try {
      let inputData = {
        type: props.type || "Mined",
      };
      await catalogDiamondService
        .GetOverToneForDiamondCatalogList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message == "Success") {
            setOverToneList(responseData);
          } else {
            setOverToneList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllDropdownData = async () => {
    try {
      await catalogDiamondService
        .GetAllDropdownData()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          handleGetOverToneForDiamondCatalogList();
          if (message == "Success") {
            if (responseData) {
              var Color = responseData.tblStoneColor;
              var diamondintensity = responseData.diamondIntensity;
              setFancyColorList(Color);
              setIntensityList(diamondintensity);
            } else {
              setFancyColorList([]);
              setIntensityList([]);
            }
          } else {
            setFancyColorList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  /*Diamond search custom binding ends*/
  return (
    <React.Fragment>
      <div className="vdiamond_section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="workarea__heading mt-0 mb-0">
                      {props.type === "LAB" ? "Lab " : null}
                      {traslate("DiamondSearch")}
                    </h4>
                  </div>
                  <div className="col-md-6 ml-auto text-right">
                    <button
                      className="primary-btn ml-2"
                      onClick={handleHideShowFilter}
                    >
                      <img src={Filter_icon} alt="" width="14px" />
                      {hideShowFilter ? (
                        <span>
                          {traslate("ShowFilter")}{" "}
                          <i className="fa fa-angle-down"></i>
                        </span>
                      ) : (
                        <span>
                          {traslate("HideFilter")}{" "}
                          <i className="fa fa-angle-up"></i>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="filter_section">
                  <div
                    className={
                      hideShowFilter
                        ? "form__fields filter_inpsection hide"
                        : "form__fields filter_inpsection"
                    }
                  >
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Shapes")} </label>
                            <div className="imgselct__div">
                              <div
                                className={
                                  state.ShapeIsSelected.Round
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Round")}
                              >
                                <img src={Roundimg} alt="" />
                                <span> {traslate("Round")} </span>
                              </div>
                              <div
                                className={
                                  state.ShapeIsSelected.Oval ? "selected" : null
                                }
                                onClick={() => onShapeClick("Oval")}
                              >
                                <img src={Roundimg} alt="" />
                                <span> {traslate("Oval")} </span>
                              </div>
                              <div
                                className={
                                  state.ShapeIsSelected.Circle
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Circle")}
                              >
                                <img src={Roundimg} alt="" />
                                <span> {traslate("Circel")} </span>
                              </div>
                              <div
                                className={
                                  state.ShapeIsSelected.Heart
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Heart")}
                              >
                                <img src={Roundimg} alt="" />
                                <span> {traslate("Heart")} </span>
                              </div>
                              <div
                                className={
                                  state.ShapeIsSelected.Emarald
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Emarald")}
                              >
                                <img src={Roundimg} alt="" />
                                <span> {traslate("Emarald")} </span>
                              </div>
                              <div
                                className={
                                  state.ShapeIsSelected.Radiant
                                    ? "selected"
                                    : null
                                }
                                onClick={() => onShapeClick("Radiant")}
                              >
                                <img src={Roundimg} alt="" />
                                <span> {traslate("Radiant")} </span>
                              </div>
                              <div
                                className={
                                  state.ShapeIsSelected.All ? "selected" : null
                                }
                                onClick={() => onShapeClick("All")}
                              >
                                <span> {traslate("All")} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Dealers")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              mode="multiple"
                              showArrow
                              placeholder="Select Dealer"
                              optionFilterProp="children"
                              onChange={(e) => onChangeMultiDrop(e, "DealerID")}
                              value={state.DealerID}
                            >
                              {dealerList.map((item, i) => {
                                return (
                                  <Option value={item.dealerID}>
                                    {item.dealerCompany}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("Certificates")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              mode="multiple"
                              showArrow
                              placeholder="Select Certificate"
                              optionFilterProp="children"
                              onChange={(e) =>
                                onChangeMultiDrop(e, "CertificateID")
                              }
                              value={state.CertificateID}
                            >
                              {certificateList.map((item, i) => {
                                return (
                                  <Option value={item.certificateID}>
                                    {item.certificateValue}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Cut")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "CutFrom")}
                                  value={state.CutFrom}
                                >
                                  <Option>Any</Option>
                                  {cutList.map((item, i) => {
                                    return (
                                      <Option value={item.cutGradeId}>
                                        {item.cutGradeValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "CutTo")}
                                  value={state.CutTo}
                                >
                                  <Option>Any</Option>
                                  {cutList.map((item, i) => {
                                    return (
                                      <Option value={item.cutGradeId}>
                                        {item.cutGradeValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="sliderrange__maindiv">
                            <label> {traslate("Carat")} </label>
                            <div className="col-md-12 mt-3">
                              <Slider
                                range
                                value={caratSlideValue}
                                min={state.CaratMin}
                                max={state.CaratMax}
                                step={0.01}
                                onChange={(e) => {
                                  setCaratSlideValue(e);
                                }}
                              />
                              <div className="rangeSlider__values">
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={caratSlideValue[0]}
                                  />
                                  {/* <span className="dollar__symbol">$</span> */}
                                </div>
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={caratSlideValue[1]}
                                  />
                                  {/* <span className="dollar__symbol">$</span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="sliderrange__maindiv">
                            <label> {traslate("Price")} </label>
                            <div className="col-md-12 mt-3">
                              <Slider
                                range
                                value={priceValue}
                                min={state.PriceMin}
                                max={state.PriceMax}
                                onChange={(e) => {
                                  setPriceValue(e);
                                }}
                              />
                              <div className="rangeSlider__values">
                                <div className="input__block">
                                  <input type="text" value={priceValue[0]} />
                                  {/* <span className="dollar__symbol">$</span> */}
                                </div>
                                <div className="input__block">
                                  <input type="text" value={priceValue[1]} />
                                  {/* <span className="dollar__symbol">$</span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="sliderrange__maindiv">
                            <label>
                              {traslate("Price")} / {traslate("Caret")}{" "}
                            </label>
                            <div className="col-md-12 mt-3">
                              <Slider
                                range
                                value={priceCaratValue}
                                min={state.PriceCaratMin}
                                max={state.PriceCaratMax}
                                onChange={(e) => {
                                  setPriceCaratValue(e);
                                }}
                              />
                              <div className="rangeSlider__values">
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={priceCaratValue[0]}
                                  />
                                  {/* <span className="dollar__symbol">$</span> */}
                                </div>
                                <div className="input__block">
                                  <input
                                    type="text"
                                    value={priceCaratValue[1]}
                                  />
                                  {/* <span className="dollar__symbol">$</span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Clarity")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    onChangeDrop(e, "ClarityFrom")
                                  }
                                  value={state.ClarityFrom}
                                >
                                  <Option>Any</Option>
                                  {clarityList.map((item, i) => {
                                    return (
                                      <Option value={item.clarityID}>
                                        {item.claritydetails}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "ClarityTo")}
                                  value={state.ClarityTo}
                                >
                                  <Option>Any</Option>
                                  {clarityList.map((item, i) => {
                                    return (
                                      <Option value={item.clarityID}>
                                        {item.claritydetails}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Color")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "ColorFrom")}
                                  value={state.ColorFrom}
                                >
                                  {/* <Option>Any</Option> */}
                                  {colorList.map((item, i) => {
                                    return (
                                      <Option value={item.colorID}>
                                        {item.colorName}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "ColorTo")}
                                  value={state.ColorTo}
                                >
                                  {/* <Option>Any</Option> */}
                                  {colorList.map((item, i) => {
                                    return (
                                      <Option value={item.colorID}>
                                        {item.colorName}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Depth")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <input
                                  type="number"
                                  placeholder="6%"
                                  value={state.DepthMin}
                                  onChange={(e) => onChange(e, "DepthMin")}
                                />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <input
                                  type="number"
                                  placeholder="6%"
                                  value={state.DepthMax}
                                  onChange={(e) => onChange(e, "DepthMax")}
                                />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Table")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <input
                                  type="number"
                                  placeholder="6%"
                                  value={state.TableMin}
                                  onChange={(e) => onChange(e, "TableMin")}
                                />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <input
                                  type="number"
                                  placeholder="6%"
                                  value={state.TableMax}
                                  onChange={(e) => onChange(e, "TableMax")}
                                />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Polish")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    onChangeDrop(e, "PolishFrom")
                                  }
                                  value={state.PolishFrom}
                                >
                                  <Option>Any</Option>
                                  {polishList.map((item, i) => {
                                    return (
                                      <Option value={item.polishID}>
                                        {item.polishValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "PolishTo")}
                                  value={state.PolishTo}
                                >
                                  <Option>Any</Option>
                                  {polishList.map((item, i) => {
                                    return (
                                      <Option value={item.polishID}>
                                        {item.polishValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-2">
                      <div className="row">
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Length(mm)")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "LengthMin")}
                                  value={state.LengthMin}
                                >
                                  <Option>Any</Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "LengthMax")}
                                  value={state.LengthMax}
                                >
                                  <Option>Any</Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Width(mm)")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "WidthMin")}
                                  value={state.WidthMin}
                                >
                                  <Option>Any</Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "WidthMax")}
                                  value={state.WidthMax}
                                >
                                  <Option>Any</Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("height(mm)")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "HeigthMin")}
                                  value={state.HeigthMin}
                                >
                                  <Option>Any</Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "HeigthMax")}
                                  value={state.HeigthMax}
                                >
                                  <Option>Any</Option>
                                  {Array(15)
                                    .fill(1)
                                    .map((item, i) => {
                                      return (
                                        <Option value={i + 1}>{i + 1}</Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Symmetry")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    onChangeDrop(e, "SummetryFrom")
                                  }
                                  value={state.SummetryFrom}
                                >
                                  <Option>Any</Option>
                                  {summetryList.map((item, i) => {
                                    return (
                                      <Option value={item.symmetryID}>
                                        {item.symmetryValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    onChangeDrop(e, "SummetryTo")
                                  }
                                  value={state.SummetryTo}
                                >
                                  <Option>Any</Option>
                                  {summetryList.map((item, i) => {
                                    return (
                                      <Option value={item.symmetryID}>
                                        {item.symmetryValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Girdle")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    onChangeDrop(e, "GirdleFrom")
                                  }
                                  value={state.GirdleFrom}
                                >
                                  <Option>Any</Option>
                                  {girdleList.map((item, i) => {
                                    return (
                                      <Option value={item.diamondGirdleID}>
                                        {item.girdleValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "GirdleTo")}
                                  value={state.GirdleTo}
                                >
                                  <Option>Any</Option>
                                  {girdleList.map((item, i) => {
                                    return (
                                      <Option value={item.diamondGirdleID}>
                                        {item.girdleValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("CrownAngle")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <input
                                  type="number"
                                  placeholder="6%"
                                  value={state.CrownAngleMin}
                                  onChange={(e) => onChange(e, "CrownAngleMin")}
                                />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <input
                                  type="number"
                                  placeholder="6%"
                                  value={state.CrownAngleMax}
                                  onChange={(e) => onChange(e, "CrownAngleMax")}
                                />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("PavilionAngle")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <input
                                  type="number"
                                  placeholder="6%"
                                  value={state.PavilionAngleMin}
                                  onChange={(e) =>
                                    onChange(e, "PavilionAngleMin")
                                  }
                                />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <input
                                  type="number"
                                  placeholder="6%"
                                  value={state.PavilionAngleMax}
                                />
                                <span className="ampcent__txt">%</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Culet")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "CuletFrom")}
                                  value={state.CuletFrom}
                                >
                                  <Option>Any</Option>
                                  {culetList.map((item, i) => {
                                    return (
                                      <Option value={item.diamondCuletID}>
                                        {item.diamondCuletID}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) => onChangeDrop(e, "CuletTo")}
                                  value={state.CuletTo}
                                >
                                  <Option>Any</Option>
                                  {culetList.map((item, i) => {
                                    return (
                                      <Option value={item.diamondCuletID}>
                                        {item.culetValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("CuletCondition")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    onChangeDrop(e, "CuletCondFrom")
                                  }
                                  value={state.CuletCondFrom}
                                >
                                  <Option>Any</Option>
                                  {culetCondList.map((item, i) => {
                                    return (
                                      <Option
                                        value={item.diamondCuletConditionID}
                                      >
                                        {item.culetConditionValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    onChangeDrop(e, "CuletCondTo")
                                  }
                                  value={state.CuletCondTo}
                                >
                                  <Option>Any</Option>
                                  {culetCondList.map((item, i) => {
                                    return (
                                      <Option
                                        value={item.diamondCuletConditionID}
                                      >
                                        {item.culetConditionValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="row">
                            <div className="col-lg-12">
                              <label className="lbl__txt">
                                {" "}
                                {traslate("Fluorescence")}{" "}
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    onChangeDrop(e, "FluorescenceFrom")
                                  }
                                  value={state.FluorescenceFrom}
                                >
                                  <Option>Any</Option>
                                  {fluorescenceList.map((item, i) => {
                                    return (
                                      <Option value={item.fluorescenceID}>
                                        {item.fluorescenceName}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input__block">
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Any"
                                  optionFilterProp="children"
                                  onChange={(e) =>
                                    onChangeDrop(e, "FluorescenceTo")
                                  }
                                  value={state.FluorescenceTo}
                                >
                                  <Option>Any</Option>
                                  {fluorescenceList.map((item, i) => {
                                    return (
                                      <Option value={item.fluorescenceID}>
                                        {item.fluorescenceName}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        {props.type !== "LAB" ? (
                          <React.Fragment>
                            <div className="col-lg-3 col-md-6">
                              <div className="input__block">
                                <label> {traslate("FancyColor")} </label>
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Select Fancy Color"
                                  optionFilterProp="children"
                                  value={state.FancyColor}
                                  onChange={(e) =>
                                    onChangeDrop(e, "FancyColor")
                                  }
                                >
                                  <Option>Any</Option>
                                  {fancyColorList.map((item, i) => {
                                    return (
                                      <Option value={item.intColorId}>
                                        {item.txtColorName}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="input__block">
                                <label> {traslate("Intensity")} </label>
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Search Intensity"
                                  optionFilterProp="children"
                                  value={state.Intensity}
                                  onChange={(e) => onChangeDrop(e, "Intensity")}
                                >
                                  <Option>Any</Option>
                                  {intensityList.map((item, i) => {
                                    return (
                                      <Option value={item.intensityId}>
                                        {item.intensityValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <div className="input__block">
                                <label> {traslate("Overtone")} </label>
                                <Select
                                  className="border__grey"
                                  showSearch
                                  placeholder="Select Overtone"
                                  optionFilterProp="children"
                                  value={state.Overtone}
                                  onChange={(e) => onChangeDrop(e, "Overtone")}
                                >
                                  {overtoneList.map((item, i) => {
                                    return (
                                      <Option value={item.overtoneID}>
                                        {item.overtoneValue}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : null}
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("MatchedPairs")} </label>
                            <Select
                              className="border__grey"
                              showSearch
                              placeholder="Search Matched Pairs"
                              optionFilterProp="children"
                              onChange={(e) => onChangeDrop(e, "MatchPairID")}
                              value={state.MatchPairID}
                            >
                              {matchedPairList.map((item, i) => {
                                return (
                                  <Option value={item.matchedPairID}>
                                    {item.matchedPairName}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="input__block">
                            <label> {traslate("HasVideo")} </label>
                            <Switch
                              name="visibility"
                              checkedChildren="Yes"
                              unCheckedChildren="No"
                              //defaultChecked
                              value={state.HasVideo}
                              onChange={(e) => onSwitchChange(e, "HasVideo")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 filtbtn_div">
                          <button
                            className="primary-btn mr-2"
                            onClick={onResetFilter}
                          >
                            {" "}
                            {traslate("ResetFilters")}
                          </button>
                          <button
                            className={
                              disableBtn ? "disabled-btn" : "primary-btn"
                            }
                            disabled={disableBtn}
                          >
                            {" "}
                            {traslate("ApplyFilters")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mt-5">
                <div className="options__div">
                  <div className="row">
                    <div className="col-lg-6 add__div">
                      <span className="diamnd__txt">
                        {traslate("YouHave")}{" "}
                        <span className="txt__no">13131</span>{" "}
                        {traslate("Diamonds")}
                      </span>
                      <button className="primary-btn">
                        <i className="fa fa-rotate-left"></i>{" "}
                        {traslate("Reset")}
                      </button>
                    </div>
                    <div className="col-lg-6 print__div">
                      <span className="select__txt">
                        <i className="fa fa-list-ul"></i>
                        {selectedRows.length} {traslate("Selected")}
                      </span>
                      {/* <button className="primary-btn">
                        <i className="fa fa-eye"></i> {traslate("View")}
                      </button> */}
                      {/* <button className="primary-btn">
                        <i className="fa fa-print"></i> {traslate("Print")}
                      </button> */}
                      <button className="primary-btn">
                        <i className="fa fa-envelope"></i> {traslate("Send")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                {/*there is a search filter in react for table*/}
                <Table
                  className="vdiamond__table"
                  columns={columns}
                  dataSource={diamondList}
                  scroll={{ x: 1600 }}
                  rowSelection={rowSelection}
                />
              </div>
              <div className="col-lg-12">
                <Modal
                  title={traslate("ViewDetails")}
                  className="modalvdiamond__section"
                  centered
                  visible={visible}
                  onOk={() => setVisible(false)}
                  onCancel={() => setVisible(false)}
                  width={1000}
                  footer={[
                    <Button key="back" onClick={handleCancel}>
                      {traslate("Cancel")}
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                      {traslate("ContactDealer")}
                    </Button>,
                  ]}
                >
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="row">
                            <div className="col-lg-2 mt-4 text-center">
                              <img src={emerald} />
                            </div>
                            <div className="col-lg-10 mt-4">
                              <div className="info__div">
                                <div>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("GemFind")}#:
                                    </span>{" "}
                                    <span className="val__txt">178443267</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("RetailPrice")}:
                                    </span>{" "}
                                    <span className="val__txt">$290.00</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Color")}:
                                    </span>{" "}
                                    <span className="val__txt">E</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Depth")}:
                                    </span>{" "}
                                    <span className="val__txt">74%</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("CaratWeight")}:
                                    </span>{" "}
                                    <span className="val__txt">0.25</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Symmetry")}:
                                    </span>{" "}
                                    <span className="val__txt">F</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Measurements")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      3.92X3.14X2.33
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Treatment")}:
                                    </span>{" "}
                                    <span className="val__txt"></span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Shape")}:
                                    </span>{" "}
                                    <span className="val__txt">Emerald</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Clarity")}:
                                    </span>{" "}
                                    <span className="val__txt">SI1</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Table")}:
                                    </span>{" "}
                                    <span className="val__txt">83%</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Polish")}:
                                    </span>{" "}
                                    <span className="val__txt">VG</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Crown")}:
                                    </span>{" "}
                                    <span className="val__txt"></span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Lab")}:
                                    </span>{" "}
                                    <a href="#" className="val__txt">
                                      <img src={imageIcon} alt="" />
                                      GIA Website
                                    </a>
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("MatchedPairStock")}#:
                                    </span>{" "}
                                    <span className="val__txt"></span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Seller")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      B&H Diamonds
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Stock")}:
                                    </span>{" "}
                                    <span className="val__txt">G36053</span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Phone")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      888-655-4804
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Location")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      Chicago, IL - 60603
                                    </span>
                                  </p>
                                  <p>
                                    <span className="head__txt">
                                      {" "}
                                      {traslate("Updated")}:
                                    </span>{" "}
                                    <span className="val__txt">
                                      5/4/2021 2:13:28 PM
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                <Modal
                  title={traslate("MessagetoB&HDiamonds")}
                  className="modalvdiamond__section"
                  centered
                  visible={visible1}
                  onOk={() => setVisible1(false)}
                  onCancel={() => setVisible1(false)}
                  width={600}
                  footer={[
                    <Button key="back" onClick={handleCancel1}>
                      {traslate("Cancel")}
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk1}>
                      {traslate("Send")}
                    </Button>,
                  ]}
                >
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="row">
                            <div className="col-lg-12 mt-4">
                              <p>
                                {" "}
                                {traslate("Contactthedealerregarding")}.{" "}
                                <a href="#">G36053.</a>
                              </p>
                            </div>
                            <div className="col-lg-12">
                              <div class="input__block">
                                <textarea
                                  id=""
                                  name=""
                                  rows="6"
                                  cols="50"
                                  placeholder="Write your message here..."
                                  class=""
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
                <Modal
                  title={traslate("Video")}
                  className="modalvdiamond__section"
                  centered
                  visible={visible2}
                  onOk={() => setVisible2(false)}
                  onCancel={() => setVisible2(false)}
                  width={600}
                  footer={[
                    <Button key="back" onClick={handleCancel2}>
                      {traslate("Close")}
                    </Button>,
                  ]}
                >
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="form__fields border-0 p-0">
                          <div className="row">
                            <div className="col-lg-12 mt-4">
                              <iframe
                                id="VideoIframesrc"
                                src="https://certimage.s3-accelerate.amazonaws.com/V360_viewers/v4.0/Vision360.html?surl=https://certimage.s3-accelerate.amazonaws.com/V360/_v4.0/&amp;d=YG-2493&amp;sv=0,1,2,3,4,5"
                                class="VideoIframe"
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiamondSearch;
