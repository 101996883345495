import React, { useState, useEffect } from "react";
import { Select, Button, Tooltip } from "antd";
import { saveAs } from "file-saver";
import userIcon from "../../../assets/images/user-icon.png";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import Create_icon from "../../../assets/images/create_icon.png";
import Upload_icon from "../../../assets/images/upload_icon.png";
import { NotificationManager } from "react-notifications";
import CatalogJewelryDataService from "../../../services/catalog-jewelry.service";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import traslate from "../../../i18n/translate";
import DownloadBox from "../../../assets/images/download_sample_icon.png";
import HelpBook from "../../../assets/images/help_docs.png";

const UploadDiamondFile = () => {
  const { Option } = Select;

  const loadImg = (
    <span>
      If you want to add jewelry images direct from server like:
      https://www.gemfind.com/zoomimages/80785.jpg then mark this checkbox as
      checked. Also verify that you have also set column IsImageFromUrl in your
      file. If you have set 1 to this column then image will load direct from
      server and if you have set 0 then imagepath will be treated as simple
    </span>
  );

  const replaceDetail = (
    <span>
      {" "}
      {traslate(
        "InventorywithDealerStock#notincludedintheuploadwillbedeleted."
      )}{" "}
    </span>
  );

  const addDetail = (
    <span>
      {" "}
      {traslate(
        "InventorywithNewDealerStock#willbeaddedandexistingDealerStock#willbeupdated.DealerStock#notincludedwillremainuntouched."
      )}{" "}
    </span>
  );

  /*Custom Binding Starts*/
  const [submitLoading2, setSubmitLoading2] = useState(false);
  const [submitLoading1, setSubmitLoading1] = useState(false);
  const [DealerId, setDealerId] = useState(null);
  const location = useLocation();
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  useEffect(() => {
    if (location.state) {
      if (location.state.dealerData) {
        setDealerId(location.state.dealerData.dealerID);
      }
    } else {
      if (loginDetials) {
        if (loginDetials.responseData) {
          setDealerId(loginDetials.responseData.dealerId);
          //setDealerDealerUseName(loginDetials.responseData.userName);
        }
      }
    }
  }, []);
  const initialState = {
    ImagefileName: null,
    Imagefile: [],
    DatafileName: null,
    Datafile: [],
    Delimiter: null,
    IsServer: false,
    rbtnRemove: false,
    Error: {},
  };
  const [state, setState] = useState(initialState);
  const [thirdPartyData, setThirdPartyData] = useState({
    linkSelect: "directlink",
    linkText: "",
    directLinkUrl: "https://www.bijandiamonds.com/uploads/Rapnet.txt",
    polygonFileName: "",
    idexLinkUrl: "",
    rapnetLinkUrl: "",
    rapnetlinkUserName: "",
    rapnetlinkPassword: "",
    rapnetlinkCheckBox: false
  });
  const handleCheckBoxChange = (e, name) => {
    setThirdPartyData({
      ...thirdPartyData,
      [name]: e.target.checked,
    });
  };
  const fileUpload = async (e, name) => {
    debugger;
    let tempState = state;
    var allFiles = [];
    var selectedFiles = e.target.files[0];
    if (name == "Image") {
      if (e.target.files[0].name.match(/.(zip)$/i)) {
        if (selectedFiles) {
          allFiles.push(selectedFiles);

          // tempState["Image"] = URL.createObjectURL(selectedFiles);
          tempState["Imagefile"] = allFiles;
          tempState["ImagefileName"] = allFiles[0].name;
          tempState.Error["Imagefile"] = null;
        }
      } else {
        tempState.Error["Imagefile"] = "Please select zip file only.";
        tempState["Imagefile"] = null;
        tempState["ImagefileName"] = null;
      }
    } else if (name == "Data") {
      if (e.target.files[0].name.match(/.(csv|xls|txt)$/i)) {
        if (selectedFiles) {
          allFiles.push(selectedFiles);
          // tempState["Image"] = URL.createObjectURL(selectedFiles);
          tempState["Datafile"] = allFiles;
          tempState["DatafileName"] = allFiles[0].name;
          tempState.Error["Datafile"] = null;
        }
      } else {
        tempState.Error["Datafile"] = "Please select .csv|xls|txt file only.";
        tempState["DatafileName"] = null;
        tempState["Datafile"] = null;
      }
    }

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const handleUploadJewelryImageFiles = (name) => {
    console.log(state);
    if (handleValidation(name)) {
      setSubmitLoading1(true);
      const formData = new FormData();

      formData.append("file", state.Imagefile[0]);
      formData.append("dealerId", DealerId);
      formData.append("fileType", "");
      try {
        CatalogJewelryDataService.UploadJewelryImageFiles(formData)
          .then((response) => {
            var message = response.data.message;
            var responseData = response.data.responseData;

            setSubmitLoading1(false);
            if (message == "Success") {
              NotificationManager.success("File Uploded Succssfully.");

              state["Imagefile"] = null;
              state["ImagefileName"] = null;
              state.Error["Imagefile"] = null;

              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
            } else {
              NotificationManager.error("File Not Uploded.");
              state["Imagefile"] = null;
              state["ImagefileName"] = null;
              state.Error["Imagefile"] = null;
              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
            }
          })
          .catch((error) => {
            setSubmitLoading1(false);
            console.log(error);
          });
      } catch (error) {
        setSubmitLoading1(false);
        console.log(error);
      }
    }
  };

  const handleUploadJewelryDataFile = (name) => {
    console.log(state);
    if (handleValidation(name)) {
      setSubmitLoading2(true);
      const formData = new FormData();

      formData.append("file", state.Datafile[0]);
      formData.append("dealerId", DealerId);
      formData.append("fileType", "");
      formData.append("userName", ""); //DealerUseName
      formData.append("rbtnRemoveJew", state.rbtnRemove);
      formData.append("SummaryIDJewLog", 0);
      formData.append("SummaryIDJew", 0);
      formData.append("ddlDELIMITERJew", state.Delimiter);
      formData.append("chkflgIsServer", state.IsServer);
      formData.append("PriceType", "");
      formData.append("IsManufacturer", false);
      try {
        CatalogJewelryDataService.UploadJewelryDataFile(formData)
          .then((response) => {
            var message = response.data.message;
            var responseData = response.data.responseData;

            setSubmitLoading2(false);
            if (message == "Success") {
              NotificationManager.success("File Uploded Succssfully.");

              state["Datafile"] = null;
              state["DatafileName"] = null;
              state.Error["Datafile"] = null;
              state["Delimiter"] = null;
              state["IsServer"] = false;
              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
            } else {
              NotificationManager.error("File Not Uploaded.");
              state["Datafile"] = null;
              state["DatafileName"] = null;
              state.Error["Datafile"] = null;
              state["Delimiter"] = null;
              state["IsServer"] = false;
              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
            }
          })
          .catch((error) => {
            setSubmitLoading2(false);
            console.log(error);
          });
      } catch (error) {
        setSubmitLoading2(false);
        console.log(error);
      }
    }
  };

  const handleValidation = (upName) => {
    var isValid = true;
    if (upName === "Upload1") {
      if (state.Imagefile === null) {
        state.Error["Imagefile"] = "Please select image.";
        isValid = false;
      } else {
        if (state.Imagefile.length === 0) {
          state.Error["Imagefile"] = "Please select image.";
          isValid = false;
        }
      }
    } else if (upName === "Upload2") {
      if (state.Datafile === null) {
        state.Error["Datafile"] = "Please select file.";
        isValid = false;
      } else {
        if (state.Datafile.length === 0) {
          state.Error["Datafile"] = "Please select file.";
          isValid = false;
        }
      }
      if (state.Delimiter === null) {
        state.Error["Delimiter"] = "Please select delimiter.";
        isValid = false;
      }
    }

    setState((prevState) => ({
      ...prevState,
      ...state,
    }));

    return isValid;
  };

  function onChangeRadio(e, name) {
    let tempState = state;
    if (name === "ReplaceAll") {
      // alert("This option will override the data.");
      NotificationManager.error("This option will override the data.");
      tempState["rbtnRemove"] = true;
    } else {
      tempState["rbtnRemove"] = false;
    }

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  }
  function onChangeDrop(e, name) {
    let tempState = state;

    tempState[name] = e;
    tempState.Error["Delimiter"] = null;
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  }
  const handleSelectOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setThirdPartyData({
        ...thirdPartyData,
        [name]: e,
      });
    }
  };
  const handleOnChange = (e, name) => {
    setThirdPartyData({
      ...thirdPartyData,
      [e.target.name]: e.target.value,
    });
  };
  function onSearch(val) {
    console.log("search:", val);
  }
  /*Custom Binding Ends*/
  /*Download data file download starts*/
  // const saveFile = () => {
  //   saveAs(
  //     "https://platform.stage.jewelcloud.com/public_app/CONTENT/searchinven/CreateCSV_Diamonds.aspx"
  //   );
  // };
  const downloadFile = () => {
    window.location.href = "https://platform.stage.jewelcloud.com/public_app/CONTENT/searchinven/CreateCSV_Diamonds.aspx"
  }
  /*Download data file download ends*/
  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv data__maping uplaod__file magento__mapping">
              <div className="row">
                <div className="col-md-12">
                  <div className="heading__block">
                    <h4 class="workarea__heading mt-0 mb-0">
                      {" "}
                      {traslate("UploadYourDataFile")}{" "}
                    </h4>
                    <div className="right__btn">
                      <Link to={"/addSignleDiamond"}>
                        <button className="primary-btn mr-2">
                          <img src={Create_icon} alt="" />{" "}
                          {traslate("AddIndividual")}
                        </button>
                      </Link>
                      {/* <button className="primary-btn mr-2">
                        <img src={Upload_icon} alt="" />{" "}
                        {traslate("UploadCerts")}
                      </button> */}
                      <button className="primary-btn" onClick={downloadFile}>
                        <i className="fa fa-download"></i>{" "}
                        {traslate("DownloadData")}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="input__block">
                    <div className="col-md-12 image_block">
                      <div class="row">
                        <div className="col-lg-12">
                          <p>
                            {" "}
                            {traslate(
                              "Pleasekeepyourinventorycurrent.Yourdiamondinventorywillberemovedifnotupdatedatleastevery48hours."
                            )}{" "}
                          </p>
                        </div>
                        <div class="col-lg-5 col-md-12">
                          <div className="divimage_block">
                            <label
                              htmlFor="file-upload1"
                              class="primary-btn choose_file_upload"
                            >
                              {traslate("ChooseFile")}
                            </label>
                            <input
                              id="file-upload1"
                              type="file"
                              onChange={(e) => fileUpload(e, "Data")}
                            />
                            {state.Error ? (
                              <div className="text-danger">
                                {state.Error["Datafile"]}
                              </div>
                            ) : null}

                          {/* For No file choosen  */}
                            {state.DatafileName ? "" :
                            <span className="mb-4">
                              {traslate("NoFileChoosen")}{" "}
                              <span className="text-red">*</span>
                            </span>
                            }

                          {/* End */}
                            <span> {traslate("Xls/Txt/CsvFormat")} </span>
                            <span className="">{state.DatafileName}</span>
                          </div>

                          <div className="choose_file_divimg d-none">
                            <img src={userIcon} alt="img" />
                            <span>x</span>
                          </div>
                        </div>
                        <div class="col-lg-7 col-md-12">
                          <div>
                            <Select
                              className="border__grey"
                              optionFilterProp="children"
                              placeholder="Comma"
                              value={state.Delimiter}
                              onChange={(e) => onChangeDrop(e, "Delimiter")}
                            >
                              <Option value="">Select</Option>
                              <Option value="COMMA">Comma</Option>
                              <Option value="TAB">Tab</Option>
                            </Select>
                            {state.Error ? (
                              <div className="text-danger">
                                {state.Error["Delimiter"]}
                              </div>
                            ) : null}
                            {/* <div className="load__img">
                                                            <label>
                                                                <input type="checkbox" />
                                                                <span>Load Images From My Server</span>
                                                                <Tooltip placement="right" title={loadImg}>
                                                                <img src={UnionIcon} alt="" />
                                                                </Tooltip>
                                                            </label>
                                                        </div> */}

                            <div className="radio__block mt-3">
                              <div className="radio__btn">
                                <input
                                  type="radio"
                                  id="dr"
                                  name="mt"
                                  className="mr-1"
                                  defaultChecked
                                  onChange={(e) =>
                                    onChangeRadio(e, "ReplaceAll")
                                  }
                                />
                                <label htmlFor="dr">
                                  {" "}
                                  {traslate("ReplaceAll")}
                                  <Tooltip
                                    placement="right"
                                    title={replaceDetail}
                                  >
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip>
                                </label>
                              </div>
                              <div className="radio__btn">
                                <input
                                  type="radio"
                                  id="jr"
                                  name="mt"
                                  className="mr-1"
                                  // defaultChecked
                                  onChange={(e) =>
                                    onChangeRadio(e, "AddUpdate")
                                  }
                                />
                                <label htmlFor="jr">
                                  {" "}
                                  {traslate("Add&Update")}
                                  <Tooltip placement="right" title={addDetail}>
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip>
                                </label>
                              </div>
                            </div>
                            <div className="help-doc-sp">
                              <img src={HelpBook} width="15px" height="15px" />
                              <p>
                                {traslate("Questions?Viewthe")}
                                <a
                                  href="https://gemfindtwostorage.blob.core.windows.net/gemfind/StaticFiles/UploadJewelry/ImageGuideline%20(1).pdf"
                                  target="_blank"
                                  download
                                > {traslate("Diamond Import")} </a>
                                {traslate("HelpDocs")}.
                              </p>
                            </div>
                            <Button
                              className="primary-btn"
                              onClick={(e) =>
                                handleUploadJewelryDataFile("Upload2")
                              }
                              loading={submitLoading2}
                            >
                              {traslate("UploadData")}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="input__block">
                    {/* <label> Upload Logo Image for Designers</label> */}
                    <div class="col-md-12">
                      <h4 className="workarea__heading">
                        {" "}
                        {traslate("UploadDiamondImages&Videos")}{" "}
                      </h4>
                    </div>
                    <div className="col-md-12 image_block">
                      <div class="row">
                        <div class="col-lg-5 col-md-12">
                          <div className="divimage_block">
                            <label
                              htmlFor="file-upload"
                              class="primary-btn choose_file_upload"
                            >
                              {traslate("ChooseFile")}
                            </label>
                            <input
                              id="file-upload"
                              type="file"
                              onChange={(e) => fileUpload(e, "Image")}
                            />
                            {state.Error ? (
                              <div className="text-danger">
                                {state.Error["Imagefile"]}
                              </div>
                            ) : null}
                            {state.ImagefileName ? state.ImagefileName : 
                            
                              <span className="mb-4">
                                {" "}
                                {traslate("NoFileChoosen")}{" "}
                                <span className="text-red">*</span>
                              </span>
                            }
                            <span className="">
                              {" "}
                              500 x 500 {traslate("Pixels")}{" "}
                            </span>
                            <span className="">
                              {" "}
                              {traslate("Jpg/PngFormat")}{" "}
                            </span>
                          </div>

                          <div className="preview__img choose_file_divimg d-none">
                            <img src={userIcon} alt="img" />
                          </div>
                        </div>
                        <div class="col-lg-7 col-md-12">
                          <p>
                            -{" "}
                            {traslate(
                              "Compressimagestogether&uploadina.zipformat."
                            )}{" "}
                          </p>
                          <p>
                            <a
                              id="Download"
                              href="https://platform.stage.jewelcloud.com/Catalog/GuideLines/ImageGuideline.pdf"
                              download
                            >
                              {traslate(
                                "Downloadourimageguidelinesdocumentation"
                              )}
                              .
                            </a>
                          </p>
                          <div className="help-doc-sp">
                            <img src={HelpBook} width="15px" height="15px" />
                            <p>
                              {traslate("Questions?Viewthe")}
                              <a
                                href="https://platform.stage.jewelcloud.com/Catalog/GuideLines/ImageGuideline.pdf"
                                target="_blank"
                                download
                              > {traslate("Image Guidelines")} </a>
                              {traslate("HelpDocs")}.
                            </p>
                          </div>
                          <Button
                            className="primary-btn"
                            onClick={(e) =>
                              handleUploadJewelryImageFiles("Upload1")
                            }
                            loading={submitLoading1}
                          >
                            {traslate("UploadData")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="input__block">
                    <h4 className="workarea__heading mt-0">
                      {" "}
                      {traslate("ImportDataFrom3rdPartyProvider")}{" "}
                    </h4>
                    {/* <p>
                      {" "}
                      {traslate(
                        "Diamonduploadsarelimitedto50,000diamondstoensuresystemspeedandreliability.DiamondFileswithmorethan50,000diamondswillbecutoffsopleasereviewyourdiamondvendorstoendureyouarewithinthediamondlimit."
                      )}{" "}
                    </p> */}
                    <div className="w-50">
                      <Select
                        className="border__grey"
                        optionFilterProp="children"
                        placeholder="DIRECTLINK"
                        value={thirdPartyData.linkSelect}
                        name="linkSelect"
                        // defaultValue = "directlink"
                        onChange={(e) =>
                          handleSelectOnChange(e, "select", "linkSelect")
                        }
                        onSearch={onSearch}
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value="rapnet">RAPNET</Option>
                        <Option value="dex">IDEX</Option>
                        <Option value="polygon">POLYGON</Option>
                        <Option value="directlink">DIRECTLINK</Option>
                      </Select>

                      {thirdPartyData.linkSelect === "dex" && <input
                        type="text"
                        className="mt-3"
                        name="idexLinkUrl"
                        placeholder="Link URL"
                        value={thirdPartyData.idexLinkUrl}
                        onChange={handleOnChange}
                      />
                      }
                      {thirdPartyData.linkSelect === "directlink" && <input
                        type="text"
                        className="mt-3"
                        name="directLinkUrl"
                        placeholder="Link URL"
                        value={thirdPartyData.directLinkUrl}
                        onChange={handleOnChange}

                      />
                      }
                      {thirdPartyData.linkSelect === "polygon" && <input
                        type="text"
                        className="mt-3"
                        name="polygonFileName"
                        placeholder="File Name"
                        value={thirdPartyData.polygonFileName}
                        onChange={handleOnChange}
                      />
                      }
                      {thirdPartyData.linkSelect === "rapnet" &&
                        <div>
                          <input
                            type="text"
                            className="mt-3"
                            name="rapnetLinkUrl"
                            placeholder="Link URl"
                            value={thirdPartyData.rapnetLinkUrl}
                            onChange={handleOnChange}
                          />
                          <input
                            type="text"
                            className="mt-3"
                            name="rapnetlinkUserName"
                            placeholder="User Name"
                            value={thirdPartyData.rapnetlinkUserName}
                            onChange={handleOnChange}
                          />
                          <input
                            type="password"
                            className="mt-3"
                            name="rapnetlinkPassword"
                            placeholder="Password"
                            value={thirdPartyData.rapnetlinkPassword}
                            onChange={handleOnChange}
                          />
                          <div className="chckbox__div uploadDiamondCheckbox-sp mt-3">
                            <input
                              checked={thirdPartyData.rapnetlinkCheckBox}
                              onChange={(e) =>
                                handleCheckBoxChange(e, "rapnetlinkCheckBox")
                              }
                              type="checkbox"
                              name="rapnetlinkCheckBox"
                              id="rapnetlinkCheckBox"
                            />
                            <label htmlFor="rapnetlinkCheckBox">
                              Use Rapnet Instant Inventory Service.
                            </label>
                          </div>
                        </div>
                      }
                      <button
                        className="primary-btn mt-3"
                        onClick={() => console.log(thirdPartyData)}
                      >
                        {" "}
                        {traslate("Save")}{" "}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="right__sec">
                    <h5>
                      {" "}
                      {traslate(
                        "Addnewdiamondsinbulkormakemassitemupdates"
                      )}.{" "}
                    </h5>
                    <h5> {traslate("Firsttimeimporting?")} </h5>
                    <ul>
                      <li>
                        <b> {traslate("ImportYourContent")} </b>
                        <p>
                          {" "}
                          {traslate(
                            "Choose&uploadyourdataorconnecttoa3rdparty"
                          )}
                          .{" "}
                        </p>
                      </li>
                      <li>
                        <b> {traslate("WaitForConfirmation")} </b>
                        <p>
                          {" "}
                          {traslate(
                            "Youwillreceiveane-mailconfirmationwhenyourcontenthasbeenuploaded."
                          )}{" "}
                        </p>
                      </li>
                      {/* <li>
                                                <b>Map Your Content</b>
                                                <p>Match your categories with the JewelCloud industry standard making your content compatible with our network and applications.</p>
                                                <p>Required if your data file is not in our format.</p>

                                            </li> */}
                    </ul>
                    <div className="row download__block">
                      <h5> {traslate("DownloadTemplates")} </h5>
                      <br />
                      <div className="col-md-2">
                        <img src={DownloadBox} alt="download-box" />
                      </div>
                      <div className="col-md-10">
                        <a
                          //href="GuideLines/JC Data Form - Required Fields.xlsx"
                          href="https://platform.stage.jewelcloud.com/Catalog/GuideLines/JC%20Diamond%20Data%20Form.xlsx"
                          //target="_blank"
                          download
                        >
                          {traslate("Standard Diamond Form")}
                        </a>
                        <br />
                      </div>

                      <br />
                    </div>
                    <br />
                    <div className="row download__block">
                      <h5> {traslate("HelpDocs")} </h5>
                      <br />
                      <div className="col-md-2">
                        <img src={HelpBook} alt="help-book" />
                      </div>
                      <div className="col-md-10 ">
                        <a
                          href="https://gemfindtwostorage.blob.core.windows.net/gemfind/StaticFiles/UploadJewelry/ImageGuideline%20(1).pdf"
                          //target="_blank"
                          download
                        >
                          {traslate("Diamond Importing")}
                        </a>
                        <br />
                      </div>

                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UploadDiamondFile;
