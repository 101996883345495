import React from "react";
// import { Input, Row, Col, Select, Checkbox, Button, Switch } from "antd";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import sliderbgImg from "../../assets/images/slider-bg.png";
import tacoriImg from "../../assets/images/tacori-logo.png";
import hoverBg1 from "../../assets/images/daimond-bg.png";
import hoverBg2 from "../../assets/images/jewelry-bg.png";
import hoverBg3 from "../../assets/images/watch-bg.png";
import traslate from "../../i18n/translate";


const CatlogList = () => {

    const options = {
        margin: 20,
        loop: true,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 2000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
            1200: {
                items: 4,
            }
        },
    };

    return (
        <React.Fragment>
            <div className="catalog__list">
                <div className="row">
                    <div className="col-md-12">
                        <div className="slider__section">
                            <OwlCarousel className='owl-theme' {...options}>
                                <div class='item'>
                                    <div className="slider__block">
                                        <div className="bgimage">
                                            <img src={sliderbgImg} />
                                        </div>
                                        <div className="slider__content">
                                            <div className="logo__img">
                                                <img src={tacoriImg} />
                                            </div>
                                            <div className="slider__btns">
                                                <button class="primary-btn"> {traslate("ViewProfile")} </button>
                                                <button class="primary-btn"> {traslate("RequestAccess")} </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class='item'>
                                    <div className="slider__block">
                                        <div className="bgimage">
                                            <img src={sliderbgImg} />
                                        </div>
                                        <div className="slider__content">
                                            <div className="logo__img">
                                                <img src={tacoriImg} />
                                            </div>
                                            <div className="slider__btns">
                                                <button class="primary-btn"> {traslate("ViewProfile")} </button>
                                                <button class="primary-btn"> {traslate("RequestAccess")} </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class='item'>
                                    <div className="slider__block">
                                        <div className="bgimage">
                                            <img src={sliderbgImg} />
                                        </div>
                                        <div className="slider__content">
                                            <div className="logo__img">
                                                <img src={tacoriImg} />
                                            </div>
                                            <div className="slider__btns">
                                                <button class="primary-btn"> {traslate("ViewProfile")} </button>
                                                <button class="primary-btn"> {traslate("RequestAccess")} </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class='item'>
                                    <div className="slider__block">
                                        <div className="bgimage">
                                            <img src={sliderbgImg} />
                                        </div>
                                        <div className="slider__content">
                                            <div className="logo__img">
                                                <img src={tacoriImg} />
                                            </div>
                                            <div className="slider__btns">
                                                <button class="primary-btn"> {traslate("ViewProfile")} </button>
                                                <button class="primary-btn"> {traslate("RequestAccess")} </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class='item'>
                                    <div className="slider__block">
                                        <div className="bgimage">
                                            <img src={sliderbgImg} />
                                        </div>
                                        <div className="slider__content">
                                            <div className="logo__img">
                                                <img src={tacoriImg} />
                                            </div>
                                            <div className="slider__btns">
                                                <button class="primary-btn"> {traslate("ViewProfile")} </button>
                                                <button class="primary-btn"> {traslate("RequestAccess")} </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                

                               

                                
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="subheading"> {traslate("Category")} </h3>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4">
                        <div className="whole__block">
                            <div className="top__image">
                                <img src={hoverBg1} className="img-fluid" />
                                <h3> {traslate("Diamonds")} </h3>
                            </div>
                            <div className="bottom__details">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="content__block">
                                            <h3 className="count">519</h3>
                                            <h4 className="text"> {traslate("YourDiamonds")} </h4>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="content__block">
                                            <h3 className="count">46,296</h3>
                                            <h4 className="text"> {traslate("ConnectedDiamonds")} </h4>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="content__block">
                                            <h3 className="count">46,296</h3>
                                            <h4 className="text"> {traslate("TotalDiamonds")} </h4>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="content__block">
                                            <h3 className="count">296</h3>
                                            <h4 className="text"> {traslate("LabDiamonds")} </h4>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="content__block">
                                            <h3 className="count">345</h3>
                                            <h4 className="text"> {traslate("MinedDiamond")} </h4>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="content__block">
                                            <h3 className="count">4629</h3>
                                            <h4 className="text"> {traslate("ColorDiamondCount")} </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="whole__block">
                            <div className="top__image">
                                <img src={hoverBg2} className="img-fluid" />
                                <h3> {traslate("Jewelry")} </h3>
                            </div>
                            <div className="bottom__details">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="content__block">
                                            <h3 className="count">1,164</h3>
                                            <h4 className="text"> {traslate("YourProducts")} </h4>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="content__block">
                                            <h3 className="count">0</h3>
                                            <h4 className="text"> {traslate("ConnectedProducts")} </h4>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="content__block">
                                            <h3 className="count">1,132</h3>
                                            <h4 className="text"> {traslate("ActiveProducts")} </h4>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="content__block">
                                            <h3 className="count"> {traslate("EDGE")} </h3>
                                            <h4 className="text"> {traslate("ImportType")} </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="whole__block">
                            <div className="top__image">
                                <img src={hoverBg3} className="img-fluid" />
                                <h3> {traslate("Watches")} </h3>
                            </div>
                            <div className="bottom__details">
                                <div className="row">
                                <div className="col-md-6">
                                        <div className="content__block">
                                            <h3 className="count">1,164</h3>
                                            <h4 className="text"> {traslate("YourProducts")} </h4>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="content__block">
                                            <h3 className="count">0</h3>
                                            <h4 className="text"> {traslate("ConnectedProducts")} </h4>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="content__block">
                                            <h3 className="count">1,132</h3>
                                            <h4 className="text"> {traslate("ActiveProducts")} </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default CatlogList
