import React, { useState } from "react";
import { Input, Row, Col, Select, Checkbox, Switch, Table, Menu, Dropdown, Button, Space } from "antd";
import { Tabs, Tab } from "react-bootstrap";

import { Accordion } from "react-bootstrap";

const FtpDetails = () => {

const { Option } = Select;
const [switchValue, setswitchValue] = useState(true);

const handleSwitch = () => {
setswitchValue(!switchValue);
};


  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv data__maping diamond__mapping">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="workarea__heading mt-0">Enter FTP Detail for SYNC File Below</h4>
                </div>

                <div className="col-md-6">
                    <div className="input__block">
                       <lable>Path</lable>
                       <input type="text" />
                    </div>

                    <div className="input__block">
                       <lable>User Id</lable>
                       <input type="text" />
                    </div>

                    <div className="input__block">
                       <lable>Password</lable>
                       <input type="password" />
                    </div>

                    <div className="input__block">
                       <lable>Port No</lable>
                       <input type="password" />
                    </div>

                     <div className="input__block">
                       <lable className="d-block mb-2">Is SSL?</lable>
                       <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked
                        onChange={handleSwitch}
                    />
                    </div>
                </div>

                <div className="col-lg-12">
                    <button className="primary-btn">Save Details</button>
                </div>
                
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FtpDetails;
