import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Switch,
  Table,
  Menu,
  Dropdown,
  Button,
  Space,
  Modal,
  Spin,
} from "antd";
import Create_icon from "../../../assets/images/create_icon.png";
import { NotificationManager } from "react-notifications";
import AdminToolsDataService from "../../../services/admin-tools.service";
import traslate from "../../../i18n/translate";

const Permissions = () => {
  const [visible, setVisible] = useState(false);
  const [switchValue, setswitchValue] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isedit, setIsEdit] = useState(true);
  const [PermissionData, setPermissionData] = useState([]);

  const [newPermission, setNewPermission] = useState({
    pageNumber: "",
    firstTierPageName: "",
    secondTierPageName: "",
    thirdTierPageName: "",
    vendorSuperSwitch: true,
    vendorAdminSwitch: true,
    vendorUserSwitch: true,
    vendorDiamondSuperSwitch: true,
    VendorDiamondAdminSwitch: true,
    VendorDiamondUserSwitch: true,
    retailerAdminSwitch: true,
    retailerSuperSwitch: true,
    retailerUserSwitch: true,
    adminAdminSwitch: true,
    adminSuperSwitch: true,
    adminUserSwitch: true,
    combinerAdminSwitch: true,
    combinerSuperSwitch: true,
    combinerUserSwitch: true,
    organizationSuperSwitch: true,
  });
  const [validations, setValidations] = useState({
    pageNumberVal: "",
    firstTierPageNameVal: "",
    secondTierPageNameVal: "",
    thirdTierPageNameVal: "",
  });
  const ref = useRef(null);
  const [cursor, setCursor] = useState(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, PermissionData]);

  useEffect(() => {
    handleGetDynamicPageAccessDetailsList();
  }, []);

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };

  const handleModalOpen = () => {
    setVisible(true);
    setIsEdit(false);
  };

  const handleCancel = () => {
    setVisible(false);
    setIsEdit(true);
    setNewPermission({
      pageNumber: "",
      firstTierPageName: "",
      secondTierPageName: "",
      thirdTierPageName: "",
      vendorSuperSwitch: true,
      vendorAdminSwitch: true,
      vendorUserSwitch: true,
      vendorDiamondSuperSwitch: true,
      VendorDiamondAdminSwitch: true,
      VendorDiamondUserSwitch: true,
      retailerAdminSwitch: true,
      retailerSuperSwitch: true,
      retailerUserSwitch: true,
      adminAdminSwitch: true,
      adminSuperSwitch: true,
      adminUserSwitch: true,
      combinerAdminSwitch: true,
      combinerSuperSwitch: true,
      combinerUserSwitch: true,
      organizationSuperSwitch: true,
    });
    setValidations({
      pageNumberVal: "",
      firstTierPageNameVal: "",
      secondTierPageNameVal: "",
      thirdTierPageNameVal: "",
    });
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setNewPermission({
        ...newPermission,
        [name]: e,
      });
    } else {
      setNewPermission({
        ...newPermission,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleInputChange = (row, name, e) => {
    setCursor(e.target.selectionStart);
    if (name == "pageNumber") {
      PermissionData.filter((x) => x.id === row.id)[0].pageNumber =
        e.target.value;
    }
    if (name == "oneTierPageName") {
      PermissionData.filter((x) => x.id === row.id)[0].oneTierPageName =
        e.target.value;
    }
    if (name == "twoTierPageName") {
      PermissionData.filter((x) => x.id === row.id)[0].twoTierPageName =
        e.target.value;
    }
    if (name == "threeTierPageName") {
      PermissionData.filter((x) => x.id === row.id)[0].threeTierPageName =
        e.target.value;
    }
    setNewPermission(PermissionData);
  };

  const handleOnEditChange = (row, e, name) => {
    if (name === "vendorSuper") {
      const temp = PermissionData.map((x) => {
        if (x.id === row.id) {
          return { ...x, vendorSuper: e.target.checked };
        } else return x;
      });
      setPermissionData(temp);
    }

    if (name === "vendorAdmin") {
      const temp = PermissionData.map((x) => {
        if (x.id === row.id) {
          return { ...x, vendorAdmin: e.target.checked };
        } else return x;
      });
      setPermissionData(temp);
    }
    if (name === "vendorUser") {
      const temp = PermissionData.map((x) => {
        if (x.id === row.id) {
          return { ...x, vendorUser: e.target.checked };
        } else return x;
      });
      setPermissionData(temp);
    }
    if (name === "vendorDiamondSuper") {
      const temp = PermissionData.map((x) => {
        if (x.id === row.id) {
          return { ...x, vendorDiamondSuper: e.target.checked };
        } else return x;
      });
      setPermissionData(temp);
    }
    if (name === "vendorDiamondAdmin") {
      const temp = PermissionData.map((x) => {
        if (x.id === row.id) {
          return { ...x, vendorDiamondAdmin: e.target.checked };
        } else return x;
      });
      setPermissionData(temp);
    }
    if (name === "vendorDiamondUser") {
      const temp = PermissionData.map((x) => {
        if (x.id === row.id) {
          return { ...x, vendorDiamondUser: e.target.checked };
        } else return x;
      });
      setPermissionData(temp);
    }
    if (name === "retailerSuper") {
      const temp = PermissionData.map((x) => {
        if (x.id === row.id) {
          return { ...x, retailerSuper: e.target.checked };
        } else return x;
      });
      setPermissionData(temp);
    }
    if (name === "retailerAdmin") {
      const temp = PermissionData.map((x) => {
        if (x.id === row.id) {
          return { ...x, retailerAdmin: e.target.checked };
        } else return x;
      });
      setPermissionData(temp);
    }
    if (name === "retailerUser") {
      const temp = PermissionData.map((x) => {
        if (x.id === row.id) {
          return { ...x, retailerUser: e.target.checked };
        } else return x;
      });
      setPermissionData(temp);
    }
    if (name === "adminSuper") {
      const temp = PermissionData.map((x) => {
        if (x.id === row.id) {
          return { ...x, adminSuper: e.target.checked };
        } else return x;
      });
      setPermissionData(temp);
    }
    if (name === "adminAdmin") {
      const temp = PermissionData.map((x) => {
        if (x.id === row.id) {
          return { ...x, adminAdmin: e.target.checked };
        } else return x;
      });
      setPermissionData(temp);
    }
    if (name === "adminUser") {
      const temp = PermissionData.map((x) => {
        if (x.id === row.id) {
          return { ...x, adminUser: e.target.checked };
        } else return x;
      });
      setPermissionData(temp);
    }
    if (name === "combinedSuper") {
      const temp = PermissionData.map((x) => {
        if (x.id === row.id) {
          return { ...x, combinedSuper: e.target.checked };
        } else return x;
      });
      setPermissionData(temp);
    }
    if (name === "combinedAdmin") {
      const temp = PermissionData.map((x) => {
        if (x.id === row.id) {
          return { ...x, combinedAdmin: e.target.checked };
        } else return x;
      });
      setPermissionData(temp);
    }
    if (name === "combinedUser") {
      const temp = PermissionData.map((x) => {
        if (x.id === row.id) {
          return { ...x, combinedUser: e.target.checked };
        } else return x;
      });
      setPermissionData(temp);
    }
    if (name === "organizationSuper") {
      const temp = PermissionData.map((x) => {
        if (x.id === row.id) {
          return { ...x, organizationSuper: e.target.checked };
        } else return x;
      });
      setPermissionData(temp);
    }
  };

  const handleValidation = () => {
    const {
      pageNumber,
      firstTierPageName,
      secondTierPageName,
      thirdTierPageName,
    } = newPermission;
    const validations = {
      pageNumberVal: "",
      firstTierPageNameVal: "",
      secondTierPageNameVal: "",
      thirdTierPageNameVal: "",
    };
    let isValid = true;

    if (!pageNumber.trim()) {
      isValid = false;
      validations.pageNumberVal = "Page number is compulsory";
    }
    if (!firstTierPageName.trim()) {
      isValid = false;
      validations.firstTierPageNameVal = "First Tier Page Name is compulsory ";
    }
    if (!secondTierPageName.trim()) {
      isValid = false;
      validations.secondTierPageNameVal =
        "Second Tier Page Name is compulsory ";
    }
    if (!thirdTierPageName.trim()) {
      isValid = false;
      validations.thirdTierPageNameVal = "Third Tier Page Name is compulsory ";
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const handleGetDynamicPageAccessDetailsList = () => {
    debugger;
    try {
      setLoading(true);
      AdminToolsDataService.GetDynamicPageAccessDetailsList()
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setPermissionData(responseData);
          } else {
            setPermissionData([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSubmit = (row) => {
    debugger;
    if (!isedit) {
      const isValid = handleValidation();
      if (!isValid) {
        return false;
      }
    }
    console.log(row);
    try {
      let inputData = {
        id: parseInt(isedit ? row.id : 0),
        pageNumber: isedit ? row.pageNumber : pageNumber,
        oneTierPageName: isedit ? row.oneTierPageName : firstTierPageName,
        twoTierPageName: isedit ? row.twoTierPageName : secondTierPageName,
        threeTierPageName: isedit ? row.threeTierPageName : thirdTierPageName,
        vendorSuper: isedit ? row.vendorSuper : vendorSuperSwitch,
        vendorAdmin: isedit ? row.vendorAdmin : vendorAdminSwitch,
        vendorUser: isedit ? row.vendorUser : vendorUserSwitch,
        retailerSuper: isedit ? row.retailerSuper : retailerSuperSwitch,
        retailerAdmin: isedit ? row.retailerAdmin : retailerAdminSwitch,
        retailerUser: isedit ? row.retailerUser : retailerUserSwitch,
        adminSuper: isedit ? row.adminSuper : adminSuperSwitch,
        adminAdmin: isedit ? row.adminAdmin : adminAdminSwitch,
        adminUser: isedit ? row.adminUser : adminUserSwitch,
        combinedSuper: isedit ? row.combinedSuper : combinerSuperSwitch,
        combinedAdmin: isedit ? row.combinedAdmin : combinerAdminSwitch,
        combinedUser: isedit ? row.combinedUser : combinerUserSwitch,
        organizationSuper: isedit
          ? row.organizationSuper
          : organizationSuperSwitch,
        vendorDiamondSuper: isedit
          ? row.vendorDiamondSuper
          : vendorDiamondSuperSwitch,
        vendorDiamondAdmin: isedit
          ? row.vendorDiamondAdmin
          : VendorDiamondAdminSwitch,
        vendorDiamondUser: isedit
          ? row.vendorDiamondUser
          : VendorDiamondUserSwitch,
      };
      setLoading(true);
      console.log(inputData);
      AdminToolsDataService.SaveDynamicPageAccessDetails(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          if (message == "Success") {
            NotificationManager.success("Permissions Added Successfully.");
            handleGetDynamicPageAccessDetailsList();
          } else {
            NotificationManager.success("Permissions Not Added");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    if (!isedit) {
      handleCancel();
    }
  };

  const {
    pageNumber,
    firstTierPageName,
    secondTierPageName,
    thirdTierPageName,
    vendorSuperSwitch,
    vendorAdminSwitch,
    vendorUserSwitch,
    vendorDiamondSuperSwitch,
    VendorDiamondAdminSwitch,
    VendorDiamondUserSwitch,
    retailerAdminSwitch,
    retailerSuperSwitch,
    retailerUserSwitch,
    adminAdminSwitch,
    adminSuperSwitch,
    adminUserSwitch,
    combinerAdminSwitch,
    combinerSuperSwitch,
    combinerUserSwitch,
    organizationSuperSwitch,
  } = newPermission;

  const {
    pageNumberVal,
    firstTierPageNameVal,
    secondTierPageNameVal,
    thirdTierPageNameVal,
  } = validations;

  return (
    <React.Fragment>
      <div className="permission_section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <h4 className="workarea__heading mt-0 mb-0">{traslate("Permissions")}</h4>
                </div>
                <div className="col-lg-6">
                  <div className="add__btndiv">
                    <button className="primary-btn" onClick={handleModalOpen}>
                      {" "}
                      <img src={Create_icon} alt="" />
                      {traslate("AddNew")}{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="permission_tbldiv">
                <Spin spinning={loading} size="large">
                  <Table
                    columns={[
                      {
                        title: traslate("PageNumber") ,
                        dataIndex: "pageNumber",
                        Key: "pageNumber",
                        fixed: "left",
                        render: (item, row) => {
                          return (
                            <div className="input__block">
                              <input
                                ref={ref}
                                type="text"
                                value={row.pageNumber}
                                onChange={(e) =>
                                  handleInputChange(row, "pageNumber", e)
                                }
                              />
                            </div>
                          );
                        },
                        width: 100,
                      },
                      {
                        title: traslate("1TierPageName") ,
                        dataIndex: "oneTierPageName",
                        Key: "oneTierPageName",
                        fixed: "left",
                        render: (item, row) => {
                          return (
                            <div className="input__block">
                              <input
                                type="text"
                                value={row.oneTierPageName}
                                onChange={(e) =>
                                  handleInputChange(row, "oneTierPageName", e)
                                }
                              />
                            </div>
                          );
                        },
                        width: 120,
                      },
                      {
                        title: traslate("2TierPageName") ,
                        dataIndex: "twoTierPageName",
                        Key: "twoTierPageName",
                        fixed: "left",
                        render: (item, row) => {
                          return (
                            <div className="input__block">
                              <input
                                type="text"
                                value={row.twoTierPageName}
                                onChange={(e) =>
                                  handleInputChange(row, "twoTierPageName", e)
                                }
                              />
                            </div>
                          );
                        },
                        width: 120,
                      },
                      {
                        title: traslate("3TierPageName") ,
                        dataIndex: "threeTierPageName",
                        Key: "threeTierPageName",
                        fixed: "left",
                        render: (item, row) => {
                          return (
                            <div className="input__block">
                              <input
                                type="text"
                                value={row.threeTierPageName}
                                onChange={(e) =>
                                  handleInputChange(row, "threeTierPageName", e)
                                }
                              />
                            </div>
                          );
                        },
                        width: 120,
                      },
                      {
                        title: traslate("VendorSuper") ,
                        dataIndex: "vendorSuper",
                        Key: "vendorSuper",
                        render: (item, row) => {
                          return (
                            <Checkbox
                              checked={row.vendorSuper}
                              onChange={(e) =>
                                handleOnEditChange(row, e, "vendorSuper")
                              }
                            />
                          );
                        },
                        width: 100,
                      },
                      {
                        title: traslate("VendorAdmin") ,
                        dataIndex: "vendorAdmin",
                        Key: "vendorAdmin",
                        render: (item, row) => {
                          return (
                            <Checkbox
                              checked={row.vendorAdmin}
                              onChange={(e) =>
                                handleOnEditChange(row, e, "vendorAdmin")
                              }
                            />
                          );
                        },
                        width: 100,
                      },
                      {
                        title: traslate("VendorUser") ,
                        dataIndex: "vendorUser",
                        Key: "vendorUser",
                        render: (item, row) => {
                          return (
                            <Checkbox
                              checked={row.vendorUser}
                              onChange={(e) =>
                                handleOnEditChange(row, e, "vendorUser")
                              }
                            />
                          );
                        },
                        width: 100,
                      },
                      {
                        title: traslate("Vendor-DiamondSuper") ,
                        dataIndex: "vendorDiamondSuper",
                        Key: "vendorDiamondSuper",
                        render: (item, row) => {
                          return (
                            <Checkbox
                              checked={row.vendorDiamondSuper}
                              onChange={(e) =>
                                handleOnEditChange(row, e, "vendorDiamondSuper")
                              }
                            />
                          );
                        },
                        width: 100,
                      },
                      {
                        title: traslate("Vendor-DiamondAdmin") ,
                        dataIndex: "vendorDiamondAdmin",
                        Key: "vendorDiamondAdmin",
                        render: (item, row) => {
                          return (
                            <Checkbox
                              checked={row.vendorDiamondAdmin}
                              onChange={(e) =>
                                handleOnEditChange(row, e, "vendorDiamondAdmin")
                              }
                            />
                          );
                        },
                        width: 100,
                      },
                      {
                        title: traslate("Vendor-DiamondUser") ,
                        dataIndex: "vendorDiamondUser",
                        Key: "vendorDiamondUser",
                        render: (item, row) => {
                          return (
                            <Checkbox
                              checked={row.vendorDiamondUser}
                              onChange={(e) =>
                                handleOnEditChange(row, e, "vendorDiamondUser")
                              }
                            />
                          );
                        },
                        width: 100,
                      },
                      {
                        title: traslate("RetailerSuper") ,
                        dataIndex: "retailerSuper",
                        Key: "retailerSuper",
                        render: (item, row) => {
                          return (
                            <Checkbox
                              checked={row.retailerSuper}
                              onChange={(e) =>
                                handleOnEditChange(row, e, "retailerSuper")
                              }
                            />
                          );
                        },
                        width: 100,
                      },
                      {
                        title: traslate("RetailerAdmin") ,
                        dataIndex: "retailerAdmin",
                        Key: "retailerAdmin",
                        render: (item, row) => {
                          return (
                            <Checkbox
                              checked={row.retailerAdmin}
                              onChange={(e) =>
                                handleOnEditChange(row, e, "retailerAdmin")
                              }
                            />
                          );
                        },
                        width: 100,
                      },
                      {
                        title: traslate("RetailerUser") ,
                        dataIndex: "retailerUser",
                        Key: "retailerUser",
                        render: (item, row) => {
                          return (
                            <Checkbox
                              checked={row.retailerUser}
                              onChange={(e) =>
                                handleOnEditChange(row, e, "retailerUser")
                              }
                            />
                          );
                        },
                        width: 100,
                      },
                      {
                        title: traslate("AdminSuper") ,
                        dataIndex: "adminSuper",
                        Key: "adminSuper",
                        render: (item, row) => {
                          return (
                            <Checkbox
                              checked={row.adminSuper}
                              onChange={(e) =>
                                handleOnEditChange(row, e, "adminSuper")
                              }
                            />
                          );
                        },
                        width: 100,
                      },
                      {
                        title: traslate("AdminAdmin") ,
                        dataIndex: "adminAdmin",
                        Key: "adminAdmin",
                        render: (item, row) => {
                          return (
                            <Checkbox
                              checked={row.adminAdmin}
                              onChange={(e) =>
                                handleOnEditChange(row, e, "adminAdmin")
                              }
                            />
                          );
                        },
                        width: 100,
                      },
                      {
                        title: traslate("AdminUser") ,
                        dataIndex: "adminUser",
                        Key: "adminUser",
                        render: (item, row) => {
                          return (
                            <Checkbox
                              checked={row.adminUser}
                              onChange={(e) =>
                                handleOnEditChange(row, e, "adminUser")
                              }
                            />
                          );
                        },
                        width: 100,
                      },
                      {
                        title: traslate("CombinedSuper") ,
                        dataIndex: "combinedSuper",
                        Key: "combinedSuper",
                        render: (item, row) => {
                          return (
                            <Checkbox
                              checked={row.combinedSuper}
                              onChange={(e) =>
                                handleOnEditChange(row, e, "combinedSuper")
                              }
                            />
                          );
                        },
                        width: 100,
                      },
                      {
                        title: traslate("CombinedAdmin") ,
                        dataIndex: "combinedAdmin",
                        Key: "combinedAdmin",
                        render: (item, row) => {
                          return (
                            <Checkbox
                              checked={row.combinedAdmin}
                              onChange={(e) =>
                                handleOnEditChange(row, e, "combinedAdmin")
                              }
                            />
                          );
                        },
                        width: 100,
                      },
                      {
                        title: traslate("CombinedUser") ,
                        dataIndex: "combinedUser",
                        Key: "combinedUser",
                        render: (item, row) => {
                          return (
                            <Checkbox
                              checked={row.combinedUser}
                              onChange={(e) =>
                                handleOnEditChange(row, e, "combinedUser")
                              }
                            />
                          );
                        },
                        width: 100,
                      },
                      {
                        title: traslate("OrganizationSuper") ,
                        dataIndex: "organizationSuper",
                        Key: "organizationSuper",
                        render: (item, row) => {
                          return (
                            <Checkbox
                              checked={row.organizationSuper}
                              onChange={(e) =>
                                handleOnEditChange(row, e, "organizationSuper")
                              }
                            />
                          );
                        },
                        width: 100,
                      },
                      {
                        title: traslate("Save") ,
                        dataIndex: "save",
                        fixed: "right",
                        render: (text, row) => (
                          <Space size="middle">
                            <button
                              className="primary-btn"
                              onClick={(e) => {
                                handleSubmit(row, e);
                              }}
                            >
                              {traslate("Save")}
                            </button>
                          </Space>
                        ),
                        width: 80,
                      },
                    ]}
                    dataSource={PermissionData}
                    scroll={{ y: 300 }}
                  />
                </Spin>
              </div>
            </div>

            <div className="col-lg-12">
              <Modal
                title={traslate("Permissions")}
                className="modal__permission"
                centered
                visible={visible}
                width={600}
                onCancel={() => setVisible(false)}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    {traslate("Cancel")}
                  </Button>,
                  <Button key="submit" type="primary" onClick={handleSubmit}>
                    {traslate("Submit")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="form__fields border-0 p-0">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("PageNumber")} : <span>*</span>
                              </label>
                              <input
                                type="int"
                                placeholder="Enter pageNumber"
                                name="pageNumber"
                                value={pageNumber}
                                onChange={handleOnChange}
                                className={pageNumberVal && "border__red"}
                              />
                              <div>
                                {pageNumberVal && (
                                  <p className="error-color-red">
                                    {pageNumberVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("1TierPageName")} : <span>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter 1st Tier PageName: "
                                name="firstTierPageName"
                                value={firstTierPageName}
                                onChange={handleOnChange}
                                className={
                                  firstTierPageNameVal && "border__red"
                                }
                              />
                              <div>
                                {firstTierPageNameVal && (
                                  <p className="error-color-red">
                                    {firstTierPageNameVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("2TierPageName")} : <span>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter 2nd Tier PageName"
                                name="secondTierPageName"
                                value={secondTierPageName}
                                onChange={handleOnChange}
                                className={
                                  secondTierPageNameVal && "border__red"
                                }
                              />
                              <div>
                                {secondTierPageNameVal && (
                                  <p className="error-color-red">
                                    {secondTierPageNameVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>
                                {" "}
                                {traslate("3TierPageName")} : <span>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter 3rd Tier PageName: "
                                name="thirdTierPageName"
                                value={thirdTierPageName}
                                onChange={handleOnChange}
                                className={
                                  thirdTierPageNameVal && "border__red"
                                }
                              />
                              <div>
                                {thirdTierPageNameVal && (
                                  <p className="error-color-red">
                                    {thirdTierPageNameVal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label>{traslate("VendorSuper")} :</label>
                              <Switch
                                name="vendorSuperSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={vendorSuperSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "vendorSuperSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("VendorAdmin")} :</label>
                              <Switch
                                name="vendorAdminSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={vendorAdminSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "vendorAdminSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("VendorUser")} :</label>
                              <Switch
                                name="vendorUserSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={vendorUserSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "vendorUserSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("Vendor-DiamondSuper")} :</label>
                              <Switch
                                name="vendorDiamondSuperSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={vendorDiamondSuperSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "vendorDiamondSuperSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("Vendor-DiamondAdmin")} :</label>
                              <Switch
                                name="VendorDiamondAdminSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={VendorDiamondAdminSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "VendorDiamondAdminSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("Vendor-DiamondUser")} :</label>
                              <Switch
                                name="VendorDiamondUserSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={VendorDiamondUserSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "VendorDiamondUserSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("RetailerSuper")} :</label>
                              <Switch
                                name="retailerSuperSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={retailerSuperSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "retailerSuperSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("RetailerAdmin")} :</label>
                              <Switch
                                name="retailerAdminSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={retailerAdminSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "retailerAdminSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("RetailerUser")} :</label>
                              <Switch
                                name="retailerUserSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={retailerUserSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "retailerUserSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("AdminAdmin")} :</label>
                              <Switch
                                name="adminAdminSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={adminAdminSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "adminAdminSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("AdminSuper")} :</label>
                              <Switch
                                name="adminSuperSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={adminSuperSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "adminSuperSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("AdminUser")} :</label>
                              <Switch
                                name="adminUserSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={adminUserSwitch}
                                onChange={(e) =>
                                  handleOnChange(e, "select", "adminUserSwitch")
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("CombinedSuper")} :</label>
                              <Switch
                                name="combinerSuperSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={combinerSuperSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "combinerSuperSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("CombinedAdmin")} :</label>
                              <Switch
                                name="combinerAdminSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={combinerAdminSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "combinerAdminSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("CombinedUser")} :</label>
                              <Switch
                                name="combinerUserSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={combinerUserSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "combinerUserSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input__block">
                              <label> {traslate("OrganizationSuper")} :</label>
                              <Switch
                                name="organizationSuperSwitch"
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={organizationSuperSwitch}
                                onChange={(e) =>
                                  handleOnChange(
                                    e,
                                    "select",
                                    "organizationSuperSwitch"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Permissions;
