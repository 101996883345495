import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
  Table
} from "antd";
import traslate from "../../../i18n/translate";

const { Option } = Select;

const ToolsReports = () => {
  

  const columns = [
    {
      title: traslate("DealerID"),
      dataIndex: "DealerID",
      width: 100
    },
    {
      title: traslate("DealerCompany"),
      dataIndex: "DealerCompany"
    },
    {
      title: traslate("Version"),
      dataIndex: "Version"
    }
  ];

  const data = [
    {
      key: "1",
      DealerID: <span>399</span>,
      DealerCompany: <span>Davids Ltd Jeweler's</span>,
      Version: <span>ML3</span>
    },
    {
    key: "1",
    DealerID: <span>399</span>,
    DealerCompany: <span>Davids Ltd Jeweler's</span>,
    Version: <span>ML3</span>
    },
    {
    key: "1",
    DealerID: <span>399</span>,
    DealerCompany: <span>Davids Ltd Jeweler's</span>,
    Version: <span>ML3</span>
    },

  ];

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields magento__mapping">
        <div className="row">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
              {traslate("ToolsReports")}
              </h4>
              <button className="primary-btn">
                <i className="fa fa-download mr-1"></i>
                {traslate("Download")}
              </button>
            </div>
          </div>

          

         

          <div className="col-md-12">
            {/* <div className="input__block">
                <label>Tools Reports</label>
            </div> */}
            <div className="row">
                <div className="radio__block col">
                    <input type="checkbox" />
                    <label> {traslate("Mastelink")} </label>
                </div>
                <div className="radio__block col">
                    <input type="checkbox" />
                    <label> {traslate("RingBuilder")} </label>
                </div>
                <div className="radio__block col">
                    <input type="checkbox" />
                    <label> {traslate("StudBuilder")} </label>
                </div>
                <div className="radio__block col">
                    <input type="checkbox" />
                    <label> {traslate("PendantBuilder")} </label>
                </div>
                <div className="radio__block col">
                    <input type="checkbox" />
                    <label> {traslate("WatchBuilder")} </label>
                </div>
            </div>
          </div>

          

          <div className="col-md-12">
              <div className="button__block my-4">
                <button className="primary-btn mr-2"> {traslate("ApplyFilter")} </button>
              </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <Table className="pendjewelreq__tbl" columns={columns} dataSource={data} scroll={{ x: 600 }} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ToolsReports;
