import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Switch,
  Tooltip,
} from "antd";
import userIcon from "../../../assets/images/user-icon.png";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import { Accordion } from "react-bootstrap";

const OutBoundMapping = () => {
  return (
    <React.Fragment>
      <div className="form__fields mh-auto">
        <div className="row">
          <div className="col-md-12">
            <h3 className="subheading">OutBound Mapping</h3>
          </div>
          {/* <div className="col-md-4">
                    <div className="input__block">
                        <label>API Url</label>
                        <input type="text" />
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="input__block">
                        <label>Port No:</label>
                        <input type="text" />
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="input__block">
                        <label>Username </label>
                        <input type="text" />
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="input__block">
                        <label>Password  </label>
                        <input type="text" />
                    </div>
                </div> */}

          <div className="col-md-4">
            <div className="input__block">
              <label>Jewelry Category </label>
              <input type="text" />
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <label>Designer Category </label>
              <input type="text" />
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <label>Bridal Category </label>
              <input type="text" />
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <label>SKU Prefix </label>
              <input type="text" />
            </div>
          </div>

          {/* <div className="col-md-4">
                    <div className="radio__block mt-4">
                        <input type="checkbox" />
                        <label>Enable Sync </label>
                        
                    </div>
                </div> */}
        </div>
      </div>

      <div className="form__fields outgoing__mapping mh-auto mt-4">
        <div className="row">
          <div className="col-md-12">
            <h3 className="subheading">Category Mapping</h3>
          </div>

          <div className="col-md-6">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div>
                    <input type="checkbox" id="h1" />
                    <label for="h1"> Bracelets</label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="col-md-12">
                    <div className="accordian_chkbxdiv">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input__block">
                            <b> GemFind Category</b>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <b> Website Category</b>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <input type="checkbox" id="Bangles" />
                          <label for="Bangles"> Bangles</label>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <input type="text" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <input type="checkbox" id="Big" />
                          <label for="Big"> Big & important Bracelets</label>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <input type="text" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <input type="checkbox" id="Chain" />
                          <label for="Chain"> Chain Bracelets</label>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <input type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div>
                    <input type="checkbox" id="h2" />
                    <label for="h2"> Earrings</label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="col-md-12">
                    <div className="accordian_chkbxdiv">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input__block">
                            <b> GemFind Category</b>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <b> Website Category</b>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <input type="checkbox" id="Bangles" />
                          <label for="Bangles"> Bangles</label>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <input type="text" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <input type="checkbox" id="Big" />
                          <label for="Big"> Big & important Bracelets</label>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <input type="text" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <input type="checkbox" id="Chain" />
                          <label for="Chain"> Chain Bracelets</label>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <input type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <div>
                    <input type="checkbox" id="h3" />
                    <label for="h3"> Necklaces</label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="col-md-12">
                    <div className="accordian_chkbxdiv">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input__block">
                            <b> GemFind Category</b>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <b> Website Category</b>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <input type="checkbox" id="Bangles" />
                          <label for="Bangles"> Bangles</label>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <input type="text" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <input type="checkbox" id="Big" />
                          <label for="Big"> Big & important Bracelets</label>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <input type="text" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <input type="checkbox" id="Chain" />
                          <label for="Chain"> Chain Bracelets</label>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <input type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          <div className="col-md-6">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div>
                    <input type="checkbox" id="h11" />
                    <label for="h11"> Watches</label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="col-md-12">
                    <div className="accordian_chkbxdiv">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input__block">
                            <b> GemFind Category</b>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <b> Website Category</b>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <input type="checkbox" id="Bangles" />
                          <label for="Bangles"> Bangles</label>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <input type="text" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <input type="checkbox" id="Big" />
                          <label for="Big"> Big & important Bracelets</label>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <input type="text" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <input type="checkbox" id="Chain" />
                          <label for="Chain"> Chain Bracelets</label>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <input type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div>
                    <input type="checkbox" id="h22" />
                    <label for="h22"> Charms</label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="col-md-12">
                    <div className="accordian_chkbxdiv">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input__block">
                            <b> GemFind Category</b>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <b> Website Category</b>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <input type="checkbox" id="Bangles" />
                          <label for="Bangles"> Bangles</label>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <input type="text" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <input type="checkbox" id="Big" />
                          <label for="Big"> Big & important Bracelets</label>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <input type="text" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <input type="checkbox" id="Chain" />
                          <label for="Chain"> Chain Bracelets</label>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <input type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <div>
                    <input type="checkbox" id="h33" />
                    <label for="h33"> Chains</label>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="col-md-12">
                    <div className="accordian_chkbxdiv">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input__block">
                            <b> GemFind Category</b>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <b> Website Category</b>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <input type="checkbox" id="Bangles" />
                          <label for="Bangles"> Bangles</label>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <input type="text" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <input type="checkbox" id="Big" />
                          <label for="Big"> Big & important Bracelets</label>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <input type="text" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <input type="checkbox" id="Chain" />
                          <label for="Chain"> Chain Bracelets</label>
                        </div>

                        <div className="col-md-6">
                          <div className="input__block">
                            <input type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>

      <div className="form__fields outgoing__mapping mh-auto mt-4">
        <div className="row">
          <div className="col-md-12">
            <h3 className="subheading">Column Mapping</h3>
          </div>

          <div className="col-md-12">
            <div className="mapping_chkbxdiv">
              <div className="row">
                <div className="col-md-6">
                  <div className="input__block">
                    <b> GemFind Category</b>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <b> Website Category</b>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <input type="checkbox" id="Bangles" />
                  <label for="Bangles"> Dealer Stock Number</label>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <input type="text" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <input type="checkbox" id="Big" />
                  <label for="Big"> Product Description</label>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <input type="text" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <input type="checkbox" id="Chain" />
                  <label for="Chain"> Gender</label>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <input type="text" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="form__fields outgoing__mapping mh-auto mt-4">
        <div className="row">
          <div className="col-md-12">
            <h3 className="subheading">Designers Mapping</h3>
          </div>

          <div className="col-md-12">
            <div className="mapping_chkbxdiv">
              <div className="row">
                <div className="col-md-6">
                  <div className="input__block">
                    <b> GemFind Designers</b>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <b> Website Category</b>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <input type="checkbox" id="Bangles" />
                  <label for="Bangles"> Overnight Mountings Ring Builder</label>
                </div>

                <div className="col-md-6">
                  <div className="input__block">
                    <input type="text" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-md-12 mt-4">
                                <button className="primary-btn mt-md-3">Create XML</button>
                            </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default OutBoundMapping;
