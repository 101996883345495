import React from "react";
// import { Input, Row, Col, Select, Checkbox, Button, Switch } from "antd";
import Groupbg from "../../assets/images/group_bg.png";
import Tacorilogo from "../../assets/images/tacori-logo.png";
import PathOutline from "../../assets/images/Path_13085_Outline.png";
import Union19 from "../../assets/images/Union_19.png";
import Noundiamond from "../../assets/images/noundiamond.png";
import Noundiamond1 from "../../assets/images/noundiamond1.png";
import Nouncall from "../../assets/images/noun_call.png";
import Nounheart from "../../assets/images/nounheart.png";
import Noun_profile_12 from "../../assets/images/noun_profile_12.png";
import Noun_rss_prple from "../../assets/images/noun_rss_prple.png";
import VendorbgImg1 from "../../assets/images/Rectangle1.png";
import VendorbgImg2 from "../../assets/images/Rectangle2.png";
import VendorbgImg3 from "../../assets/images/Rectangle3.png";
import VendorbgImg4 from "../../assets/images/Rectangle4.png";
import VendorbgImg5 from "../../assets/images/Rectangle5.png";
import Vendor_logoImg1 from "../../assets/images/Group_1.png";
import Vendor_logoImg2 from "../../assets/images/Group_2.png";
import Vendor_logoImg3 from "../../assets/images/Group_3.png";
import Vendor_logoImg4 from "../../assets/images/Group_4.png";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const Dashboard = () => {

    const options = {
        margin: 20,
        loop: true,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 2000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
            },
            1200: {
                items: 1,
            }
        },
    };

    return (
        <React.Fragment>
            <div className="catalog__list dashbo_maindiv">
                <div className="row">
                    <div className="col-md-12">
                        <div className="slider__section">
                            <OwlCarousel className='owl-theme' {...options}>
                                <div class='item'>
                                    <div className="slider__item">
                                        <div className="bgimage">
                                            <img src={Groupbg} />
                                        </div>
                                        <div className="slider__content">
                                            <div className="logo__img">
                                                <img src={Tacorilogo} />
                                            </div>
                                            <div className="slider__btns">
                                                <button class="primary-btn mr-2">View Profile</button>
                                                <button class="primary-btn">Request Access</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='item'>
                                    <div className="slider__item">
                                        <div className="bgimage">
                                            <img src={Groupbg} />
                                        </div>
                                        <div className="slider__content">
                                            <div className="logo__img">
                                                <img src={Tacorilogo} />
                                            </div>
                                            <div className="slider__btns">
                                                <button class="primary-btn mr-2">View Profile</button>
                                                <button class="primary-btn">Request Access</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='item'>
                                    <div className="slider__item">
                                        <div className="bgimage">
                                            <img src={Groupbg} />
                                        </div>
                                        <div className="slider__content">
                                            <div className="logo__img">
                                                <img src={Tacorilogo} />
                                            </div>
                                            <div className="slider__btns">
                                                <button class="primary-btn mr-2">View Profile</button>
                                                <button class="primary-btn">Request Access</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='item'>
                                    <div className="slider__item">
                                        <div className="bgimage">
                                            <img src={Groupbg} />
                                        </div>
                                        <div className="slider__content">
                                            <div className="logo__img">
                                                <img src={Tacorilogo} />
                                            </div>
                                            <div className="slider__btns">
                                                <button class="primary-btn mr-2">View Profile</button>
                                                <button class="primary-btn">Request Access</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-lg-2 col-md-4 mt-4">
                                <div className="count_div">
                                    <div>
                                        <span className="">04</span>
                                    </div>
                                    <div>
                                        <span className="">Jewelry vendors</span>
                                        <img src={PathOutline} className="" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 mt-4">
                                <div className="count_div">
                                    <div>
                                        <span className="">22</span>
                                    </div>
                                    <div>
                                        <span className="">Active Jewelry</span>
                                        <img src={Union19} className="" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 mt-4">
                                <div className="count_div">
                                    <div>
                                        <span className="">11</span>
                                    </div>
                                    <div>
                                        <span className="">Diamnond vendors</span>
                                        <img src={Noundiamond} className="" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 mt-4">
                                <div className="count_div">
                                    <div>
                                        <span className="">45,354</span>
                                    </div>
                                    <div>
                                        <span className="">Active Diamnonds</span>
                                        <img src={Noundiamond1} className="" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 mt-4">
                                <div className="count_div">
                                    <div>
                                        <span className="">30</span>
                                    </div>
                                    <div>
                                        <span className="">Contact Us</span>
                                        <img src={Nouncall} className="" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 mt-4">
                                <div className="count_div">
                                    <div>
                                        <span className="">06</span>
                                    </div>
                                    <div>
                                        <span className="">Favorites</span>
                                        <img src={Nounheart} className="" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <h3 className="subheading">New & Recently Updated Vendors</h3>
                    </div>

                    <div className="col-md-12">
                        <div className="vendors_section">
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="slider__block">
                                        <div className="bgimage">
                                            <img src={VendorbgImg2} />
                                        </div>
                                        <div className="slider__content">
                                            <div className="logo__img">
                                                <img src={Vendor_logoImg2} />
                                            </div>
                                            <div className="slider__btns">
                                                <button class="primary-btn mr-2">View More</button>
                                                <button class="primary-btn">Request Access</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="slider__block">
                                        <div className="bgimage">
                                            <img src={VendorbgImg1} />
                                        </div>
                                        <div className="slider__content">
                                            <div className="logo__img">
                                                <img src={Vendor_logoImg1} />
                                            </div>
                                            <div className="slider__btns">
                                                <button class="primary-btn mr-2">View More</button>
                                                <button class="primary-btn">Request Access</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="slider__block">
                                        <div className="bgimage">
                                            <img src={VendorbgImg3} />
                                        </div>
                                        <div className="slider__content">
                                            <div className="logo__img">
                                                <img src={Vendor_logoImg3} />
                                            </div>
                                            <div className="slider__btns">
                                                <button class="primary-btn mr-2">View More</button>
                                                <button class="primary-btn">Request Access</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="slider__block">
                                        <div className="bgimage">
                                            <img src={VendorbgImg4} />
                                        </div>
                                        <div className="slider__content">
                                            <div className="logo__img">
                                                <img src={Vendor_logoImg4} />
                                            </div>
                                            <div className="slider__btns">
                                                <button class="primary-btn mr-2">View More</button>
                                                <button class="primary-btn">Request Access</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="slider__block">
                                        <div className="bgimage">
                                            <img src={VendorbgImg2} />
                                        </div>
                                        <div className="slider__content">
                                            <div className="logo__img">
                                                <img src={Vendor_logoImg2} />
                                            </div>
                                            <div className="slider__btns">
                                                <button class="primary-btn mr-2">View More</button>
                                                <button class="primary-btn">Request Access</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="slider__block">
                                        <div className="bgimage">
                                            <img src={VendorbgImg5} />
                                        </div>
                                        <div className="slider__content">
                                            <div className="logo__img">
                                                <img src={Vendor_logoImg3} />
                                            </div>
                                            <div className="slider__btns">
                                                <button class="primary-btn mr-2">View More</button>
                                                <button class="primary-btn">Request Access</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="slider__block">
                                        <div className="bgimage">
                                            <img src={VendorbgImg1} />
                                        </div>
                                        <div className="slider__content">
                                            <div className="logo__img">
                                                <img src={Vendor_logoImg1} />
                                            </div>
                                            <div className="slider__btns">
                                                <button class="primary-btn mr-2">View More</button>
                                                <button class="primary-btn">Request Access</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="slider__block">
                                        <div className="bgimage">
                                            <img src={VendorbgImg3} />
                                        </div>
                                        <div className="slider__content">
                                            <div className="logo__img">
                                                <img src={Vendor_logoImg4} />
                                            </div>
                                            <div className="slider__btns">
                                                <button class="primary-btn mr-2">View More</button>
                                                <button class="primary-btn">Request Access</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="slider__block">
                                        <div className="bgimage">
                                            <img src={VendorbgImg4} />
                                        </div>
                                        <div className="slider__content">
                                            <div className="logo__img">
                                                <img src={Vendor_logoImg3} />
                                            </div>
                                            <div className="slider__btns">
                                                <button class="primary-btn mr-2">View More</button>
                                                <button class="primary-btn">Request Access</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center mt-4">
                                    <button class="primary-btn">Load More</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <h3 className="subheading">Notifications</h3>
                    </div>

                    <div className="col-md-12">
                        <div className="Notif_section">
                            <div className="msg_div">
                                <div>
                                    <p className="msg_date">Monday, December 14, 2020</p>
                                </div>
                                <div className="msg_divtext">
                                    <span className="msg_txt"> <img src={Noun_profile_12} alt="abc" /> <span className="msg_nametxt">Bhavin Patel</span> registered with the website </span>
                                    
                                    <span className="msg_timetxt">11:25PM</span>
                                </div>
                                <div className="msg_divtext">
                                    <span className="msg_txt"> <img src={Noun_rss_prple} alt="abc" /> <span className="msg_nametxt">Bhavin Patel</span> Drop A hint for <span className="msg_nametxt">Diamond SKU# 173444</span> using diamond link</span>

                                    <span className="msg_timetxt">11:25PM</span>
                                </div>
                            </div>

                            <div className="msg_div">
                                <div>
                                    <p className="msg_date">Monday, October 5, 2020</p>
                                </div>
                                <div className="msg_divtext">
                                    <span className="msg_txt"> <img src={Noun_profile_12} alt="abc" /> <span className="msg_nametxt">Bhavin Patel</span> registered with the website </span>
                                    
                                    <span className="msg_timetxt">11:25PM</span>
                                </div>
                                <div className="msg_divtext">
                                    <span className="msg_txt"> <img src={Noun_rss_prple} alt="abc" /> <span className="msg_nametxt">Bhavin Patel</span> Drop A hint for <span className="msg_nametxt">Diamond SKU# 173444</span> using diamond link</span>

                                    <span className="msg_timetxt">11:25PM</span>
                                </div>
                            </div>

                            <div className="msg_div">
                                <div>
                                    <p className="msg_date">Tuesday, July 28, 2020</p>
                                </div>
                                <div className="msg_divtext">
                                    <span className="msg_txt"> <img src={Noun_profile_12} alt="abc" /> <span className="msg_nametxt">Test</span> shared with the website </span>
                                    
                                    <span className="msg_timetxt">11:25PM</span>
                                </div>
                                <div className="msg_divtext">
                                    <span className="msg_txt"> <img src={Noun_rss_prple} alt="abc" /> <span className="msg_nametxt">Test</span> Drop A hint for <span className="msg_nametxt">Diamond SKU# 173444</span> using diamond link</span>

                                    <span className="msg_timetxt">11:25PM</span>
                                </div>
                                <div className="msg_divtext">
                                    <span className="msg_txt"> <img src={Noun_rss_prple} alt="abc" /> <span className="msg_nametxt">Test</span> Drop A hint for <span className="msg_nametxt">Diamond SKU# 173444</span> using diamond link</span>

                                    <span className="msg_timetxt">11:25PM</span>
                                </div>
                                <div className="msg_divtext">
                                    <span className="msg_txt"> <img src={Noun_rss_prple} alt="abc" /> <span className="msg_nametxt">Test</span> Drop A hint for <span className="msg_nametxt">Diamond SKU# 173444</span> using diamond link</span>

                                    <span className="msg_timetxt">11:25PM</span>
                                </div>
                            </div>

                            <div>
                                <button className="primary-btn mt-3">Load More</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Dashboard
