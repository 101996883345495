import React, { useState, useEffect } from "react";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import { Button, Modal } from "antd";
import traslate from "../../../i18n/translate";
const TransferZZGemfind = () => {
  const initialState = {
    sourceJCID: "",
    destinationJCID: "",
    sourceJCName: null,
    destinationJCName: null,
    type: 2,
    isReplace: false,
    isAddUpdate: true,
    errorMessage: {},
  };
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [transerModal, setTranserModal] = useState(false);
  const [messageState, setMessageState] = useState({
    TransferBrand: "",
    SuccessBrandTransfer: "",
    TransferRequests: "",
    TransferSuccessful: "",
    TransferCollection: "",
    TransferCollectionSuccessful: "",
    TransferColumnAndAttributemapping: "",
    TransferColumnAndAttributemappingSuccessful: "",
    TransferProductData: "",
    TransferProductDataSuccessful: "",
    ImagesTransfer: "",
    ImagesTransferSuccessful: "",
  });

  const onChange = (e, name) => {
    let tempState = state;
    debugger;

    if (name == "type") {
      if (e.target.value == "1") {
        tempState["isReplace"] = true;
        tempState["isAddUpdate"] = false;
      } else {
        tempState["isReplace"] = false;
        tempState["isAddUpdate"] = true;
      }
    }
    tempState[name] = e.target.value;
    tempState["errorMessage"][name] = false;

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };
  const handleValidation = () => {
    var valid = true;
    var tempState = state;
    if (tempState["sourceJCID"]) {
      tempState["errorMessage"].sourceJCID = false;
    } else {
      tempState["errorMessage"].sourceJCID = true;
      valid = false;
    }
    if (tempState["destinationJCID"]) {
      tempState["errorMessage"].destinationJCID = false;
    } else {
      tempState["errorMessage"].destinationJCID = true;
      valid = false;
    }
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    return valid;
  };
  const handleTransferOpenModal = () => {
    debugger;
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    if (
      state.sourceJCName == "Dealer Not Found" ||
      state.destinationJCName == "Dealer Not Found"
    ) {
      state["errorMessage"].providerName = true;
      return false;
    }
    setTranserModal(true);
    state["errorMessage"].providerName = false;
  };
  const handleTransferCloseModal = () => {
    setTranserModal(false);
  };

  const handleGetDealerDropDownList = (id, type) => {
    if (!id) {
      return false;
    }
    var inputData = {
      dealerID: Number(id),
    };

    try {
      AdminToolsDataService.GetDealerDropDownList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          let tempState = state;
          if (message == "Success") {
            tempState[type] = responseData[0].dealerCompany || null;
            setState((prevState) => ({
              ...prevState,
              ...tempState,
            }));
          } else {
            tempState[type] = "Dealer Not Found";
            setState((prevState) => ({
              ...prevState,
              ...tempState,
            }));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleTransferBrand = () => {
    if (handleValidation()) {
      try {
        setLoading(true);
        let inputData = {
          srcDealerId: Number(state.sourceJCID),
          destDealerId: Number(state.destinationJCID),
          tranType: parseInt(state.type),
        };
        setMessageState((prevState) => ({
          ...prevState,
          TransferBrand: "Started Transfer Brand...",
        }));

        // NotificationManager.success("Started Transfer Brand...");
        AdminToolsDataService.TransferBrand(inputData)
          .then((response) => {
            var message = response.data.message;
            setLoading(false);
            if (message == "Success") {
              handleTransferRequests();
              setMessageState((prevState) => ({
                ...prevState,
                SuccessBrandTransfer: "Brand Transfer Successful...",
              }));

              // NotificationManager.success("Brand Transfer Successful...");
            } else {
              NotificationManager.error("Brand Not Transfer.");
            }
            setTranserModal(false);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  const handleTransferRequests = () => {
    if (handleValidation()) {
      try {
        setLoading(true);
        let inputData = {
          srcDealerId: Number(state.sourceJCID),
          destDealerId: Number(state.destinationJCID),
          tranType: parseInt(state.type),
        };
        setMessageState((prevState) => ({
          ...prevState,
          TransferRequests: "Started Transfer Requests...",
        }));

        // NotificationManager.success("Started Transfer Requests...");
        AdminToolsDataService.TransferRequests(inputData)
          .then((response) => {
            var message = response.data.message;
            setLoading(false);
            if (message == "Success") {
              handleTransferProfile();
              setMessageState((prevState) => ({
                ...prevState,
                TransferSuccessful: "Requests Transfer Successful...",
              }));

              // NotificationManager.success("Requests Transfer Successful...");
            } else {
              NotificationManager.error("Requests Not Transfer.");
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };
  const handleTransferProfile = () => {
    if (handleValidation()) {
      try {
        setLoading(true);
        let inputData = {
          srcDealerId: Number(state.sourceJCID),
          destDealerId: Number(state.destinationJCID),
          tranType: parseInt(state.type),
        };
        setMessageState((prevState) => ({
          ...prevState,
          TransferCollection: "Started Transfer Collection...",
        }));

        // NotificationManager.success("Started Transfer Collection...");
        AdminToolsDataService.TransferProfile(inputData)
          .then((response) => {
            var message = response.data.message;
            setLoading(false);
            if (message == "Success") {
              handleTransferColumnAndAttributemapping();
              setMessageState((prevState) => ({
                ...prevState,
                TransferCollectionSuccessful:
                  "Collection Transfer Successful...",
              }));

              // NotificationManager.success("Collection Transfer Successful...");
            } else {
              NotificationManager.error("Profile Not Transfer.");
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };
  const handleTransferColumnAndAttributemapping = () => {
    if (handleValidation()) {
      try {
        setLoading(true);
        let inputData = {
          srcDealerId: Number(state.sourceJCID),
          destDealerId: Number(state.destinationJCID),
          tranType: parseInt(state.type),
        };
        setMessageState((prevState) => ({
          ...prevState,
          TransferCollection:
            "Started Transfer Column And Attribute mapping...",
        }));

        // NotificationManager.success(
        //   "Started Transfer Column And Attribute mapping..."
        // );
        AdminToolsDataService.TransferColumnAndAttributemapping(inputData)
          .then((response) => {
            var message = response.data.message;
            setLoading(false);
            if (message == "Success") {
              handleTransferProductData();
              setMessageState((prevState) => ({
                ...prevState,
                TransferCollection:
                  "Started Transfer Column And Attribute mapping...",
              }));

              // NotificationManager.success(
              //   "Column And Attribute Mapping Transfer Successful..."
              // );
            } else {
              NotificationManager.error(
                "Column And Attribute Mapping Not Transfer."
              );
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };
  const handleTransferProductData = () => {
    if (handleValidation()) {
      try {
        setLoading(true);
        let inputData = {
          srcDealerId: Number(state.sourceJCID),
          destDealerId: Number(state.destinationJCID),
          tranType: parseInt(state.type),
        };
        setMessageState((prevState) => ({
          ...prevState,
          TransferProductData: "Started Transfer Product Data...",
        }));

        // NotificationManager.success("Started Transfer Product Data...");
        AdminToolsDataService.TransferProductData(inputData)
          .then((response) => {
            var message = response.data.message;
            setLoading(false);
            if (message == "Success") {
              handleTransferProductImagesVideo();
              setMessageState((prevState) => ({
                ...prevState,
                TransferProductDataSuccessful:
                  "Product Data Transfer Successful...(Wait for sometime to get indexed)",
              }));

              // NotificationManager.success(
              //   "Product Data Transfer Successful...(Wait for sometime to get indexed)"
              // );
            } else {
              NotificationManager.error("Product Data Not Transfer.");
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };
  const handleTransferProductImagesVideo = () => {
    if (handleValidation()) {
      try {
        setLoading(true);
        let inputData = {
          srcDealerId: Number(state.sourceJCID),
          destDealerId: Number(state.destinationJCID),
          tranType: parseInt(state.type),
        };
        setMessageState((prevState) => ({
          ...prevState,
          ImagesTransfer: "Started Transfer Product Images & Videos...",
        }));

        // NotificationManager.success(
        //   "Started Transfer Product Images & Videos..."
        // );
        AdminToolsDataService.TransferProductImagesVideo(inputData)
          .then((response) => {
            var message = response.data.message;
            setLoading(false);
            if (message == "Success") {
              setMessageState((prevState) => ({
                ...prevState,
                ImagesTransfer:
                  "Product Images & Videos Transfer Successful... (Wait for sometime to get uploaed to CDN)",
              }));

              // NotificationManager.success(
              //   "Product Images & Videos Transfer Successful... (Wait for sometime to get uploaed to CDN)"
              // );

              handleTransferCloseModal();
            } else {
              NotificationManager.error("Product Images Video Not Transfer.");
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };
  return (
    <React.Fragment>
      <div className="transfzz__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-0">
                {traslate("TransferZZ.Gemfind")}
              </h4>
            </div>

            <div className="col-lg-12">
              <div className="col-lg-3 mt-4">
                <div className="input__block">
                  <label>
                    {" "}
                    {traslate("SourceJCID")} : <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="mb-2"
                    placeholder="Enter Source JC ID"
                    onChange={(e) => onChange(e, "sourceJCID")}
                    onBlur={() =>
                      handleGetDealerDropDownList(
                        state.sourceJCID,
                        "sourceJCName"
                      )
                    }
                    value={state.sourceJCID || null}
                  />
                  <span
                    className={
                      state.sourceJCName == "Dealer Not Found"
                        ? "error-color-red"
                        : null
                    }
                  >
                    {state.sourceJCName}
                  </span>
                  {state.errorMessage["sourceJCID"] ? (
                    <span className="error-color-red">
                      Enter valid DealerID
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-3 mt-4">
                <div className="input__block">
                  <label>
                    {" "}
                    {traslate("DestinationJCID")} : <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="mb-2"
                    placeholder="Enter Destination JC ID"
                    onChange={(e) => onChange(e, "destinationJCID")}
                    onBlur={() =>
                      handleGetDealerDropDownList(
                        state.destinationJCID,
                        "destinationJCName"
                      )
                    }
                    value={state.destinationJCID || null}
                  />
                  <span
                    className={
                      state.destinationJCName == "Dealer Not Found"
                        ? "error-color-red"
                        : null
                    }
                  >
                    {state.destinationJCName}
                  </span>
                  {state.errorMessage["destinationJCID"] ? (
                    <span className="error-color-red">
                      Enter valid DealerID
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-7 mt-4">
                <div className="replall__radidiv">
                  <div>
                    <input
                      type="radio"
                      id="rpa"
                      name="abc"
                      className="mr-1"
                      value={"1"}
                      checked={state.isReplace}
                      onChange={(e) => onChange(e, "type")}
                    />
                    <label htmlFor="rpa"> {traslate("ReplaceAll")} </label>
                  </div>

                  <div>
                    <input
                      type="radio"
                      id="adu"
                      name="abc"
                      className="mr-1"
                      value={"2"}
                      checked={state.isAddUpdate}
                      onChange={(e) => onChange(e, "type")}
                    />
                    <label htmlFor="adu"> {traslate("Add&Update")}</label>
                  </div>
                </div>
              </div>
              {state.errorMessage["providerName"] ? (
                <span className="error-color-red">
                  {" "}
                  Provide Proper DealerID{" "}
                </span>
              ) : null}
              <div className="col-lg-12 mt-4">
                <div className="addcons__btndiv">
                  <Button
                    className="primary-btn"
                    loading={loading}
                    onClick={handleTransferOpenModal}
                  >
                    {" "}
                    {traslate("Transfer")}{" "}
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="consupld_tbldiv"></div>
            </div>
          </div>
          <div className="display__block">
            <p>{messageState.TransferBrand && messageState.TransferBrand}</p>
            <p>
              {messageState.SuccessBrandTransfer &&
                messageState.SuccessBrandTransfer}
            </p>
            <p>
              {messageState.TransferRequests && messageState.TransferRequests}
            </p>
            <p>
              {messageState.TransferSuccessful &&
                messageState.TransferSuccessful}
            </p>
            <p>
              {messageState.TransferCollection &&
                messageState.TransferCollection}
            </p>
            <p>
              {messageState.TransferCollectionSuccessful &&
                messageState.TransferCollectionSuccessful}
            </p>
            <p>
              {messageState.TransferColumnAndAttributemapping &&
                messageState.TransferColumnAndAttributemapping}
            </p>
            <p>
              {messageState.TransferColumnAndAttributemappingSuccessful &&
                messageState.TransferColumnAndAttributemappingSuccessful}
            </p>
            <p>
              {messageState.TransferProductData &&
                messageState.TransferProductData}
            </p>
            <p>
              {messageState.TransferProductDataSuccessful &&
                messageState.TransferProductDataSuccessful}
            </p>
            <p>{messageState.ImagesTransfer && messageState.ImagesTransfer}</p>
            <p>
              {messageState.ImagesTransferSuccessful &&
                messageState.ImagesTransferSuccessful}
            </p>
          </div>
        </div>
      </div>

      <Modal
        className="datamapping__modal"
        width={"55%"}
        visible={transerModal}
        onOk={handleTransferBrand}
        onCancel={handleTransferCloseModal}
        footer={[
          <Button
            key="submit"
            loading={loading}
            type="primary"
            onClick={handleTransferBrand}
          >
            {traslate("Transfer")}
          </Button>,
          <Button key="close" onClick={handleTransferCloseModal}>
            {traslate("Cancel")}
          </Button>,
        ]}
      >
        <div className="form__fields row border-0 p-0">
          <div className="col-lg-12">
            <div className="alert__popup">
              <p>
                {traslate("Areyousureyouwanttomovedatafrom")}{" "}
                <b>
                  {state.sourceJCName} ({Number(state.sourceJCID)})
                </b>{" "}
                {traslate("accountto")}{" "}
                <b>
                  {" "}
                  {state.destinationJCName} ( {Number(state.destinationJCID)} )
                </b>{" "}
                account ?
              </p>
              <span>
                {" "}
                - You have select{" "}
                <b> {state.type == 1 ? " Replace All " : " Add & Update "}</b>
                {traslate("Option")}.{" "}
                {state.type == 1
                  ? " This Option Will remove Existing data and Transfer new data From Source Account."
                  : " This Option Will update Existing data and Transfer new data From Source Account."}
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default TransferZZGemfind;
