import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Table,
  Modal,
  DatePicker,
  Switch,
  Space,
  Tooltip,
} from "antd";

import Highlighter from "react-highlight-words";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import userIcon from "../../../assets/images/user-icon.png";
import loginImg1 from "../../../assets/images/loginslider1.png";
import close_red from "../../../assets/images/icons/close_red.svg";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import traslate from "../../../i18n/translate";

const { Option } = Select;
export const LoginPreferences = (props) => {
  const initialState = {
    designerID: null,
    designerID2: null,
    fileName: null,
    fileN: [],
    Image: null,
    Error: {},
    checkValue: {},
  };
  const [switchValue, setswitchValue] = useState(true);
  const [designerList, setdesignerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [state, setState] = useState(initialState);
  useEffect(() => {
    handleGetDesignerList();
  }, []);

  //const retail = <span>This will feature a specific vendor for the "New to JewelCloud" section on the login page.</span>;

  const designerImg = <img src={loginImg1} />;

  const handleGetDesignerList = () => {
    try {
      AdminToolsDataService.GetDesignerList()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var id =
            responseData.filter((x) => x.isAsNewDesigner === true)[0]
              .designerID || 0;
          if (message == "Success") {
            setdesignerList(responseData);
            state.designerID = id;
            setState((prevState) => ({
              ...prevState,
              ...state,
            }));
          } else {
            setdesignerList([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddDesigner = () => {
    debugger;
    let inputData = {
      designerID: state.designerID,
    };
    try {
      AdminToolsDataService.AddDesignerToLogInPage(inputData)
        .then((response) => {
          debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            NotificationManager.success("Record Added Successfully.");
          } else {
            NotificationManager.error("Record Not Added.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSetUnsetDesigner = (isSelect, ID) => {
    debugger;
    let inputData = {
      designerID: ID,
      isSelected: isSelect,
    };
    try {
      AdminToolsDataService.SetUnsetDesignerForLoginPage(inputData)
        .then((response) => {
          debugger;
          var message = response.data.message;
          var responseData = response.data.responseData;
          NotificationManager.success(responseData.returnMessage);
          if (message == "Success") {
            handleGetDesignerList();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadLogoForDesigner = () => {
    debugger;
    if (handleValidation()) {
      setSubmitLoading(true);
      const formData = new FormData();

      formData.append("designerID", state.designerID2);
      formData.append("logoImage", state.fileN[0]);
      try {
        AdminToolsDataService.UploadLogoForDesignerForLoginPage(formData)
          .then((response) => {
            var message = response.data.message;
            var responseData = response.data.responseData;
            setSubmitLoading(false);
            NotificationManager.success(responseData.returnMessage);
            if (message == "Success") {
              handleGetDesignerList();
              state["fileN"] = null;
              state["fileName"] = null;
              state["Image"] = null;
              state.Error["file"] = null;
              state.designerID2 = null;
              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
            } else {
              // NotificationManager.error("Logo Not Updated.");
            }
          })
          .catch((error) => {
            setSubmitLoading(false);
            console.log(error);
          });
      } catch (error) {
        setSubmitLoading(false);
        console.log(error);
      }
    }
  };
  const handleValidation = () => {
    var designerID2 = state.designerID2;
    var file = state.fileN;
    var isValid = true;
    if (designerID2 === null || designerID2 === 0) {
      state.Error["designerID2"] = "Please select designer.";
      isValid = false;
    }
    if (file === null) {
      state.Error["file"] = "Please select image.";
      isValid = false;
    } else {
      if (file.length === 0) {
        state.Error["file"] = "Please select image.";
        isValid = false;
      }
    }

    setState((prevState) => ({
      ...prevState,
      ...state,
    }));

    return isValid;
  };

  const handleCancelImage = () => {
    debugger;
    state["fileN"] = null;
    state["fileName"] = null;
    state["Image"] = null;
    state.Error["file"] = null;

    setState((prevState) => ({
      ...prevState,
      ...state,
    }));
  };
  function onChange(value) {
    console.log(`selected ${value}`);
  }

  function onSearch(val) {
    console.log("search:", val);
  }

  function onChange(name, e) {
    let tempState = state;

    tempState[name] = e;

    if (name === "designerID2") {
      tempState.Error["designerID2"] = null;
      tempState["Image"] =
        designerList.filter((x) => x.designerID === e)[0].logoImage || null;
    }

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    if (name === "designerID") {
      setTimeout(() => {
        handleAddDesigner();
      }, 500);
    }
  }
  const handleCheckboxChange = (e, ID) => {
    debugger;

    const temp = designerList.map((x) => {
      if (x.designerID === ID) {
        return { ...x, isSelected: e.target.checked };
      } else return x;
    });

    setdesignerList(temp);

    // let tempState = state;

    // tempState.checkValue[ID] = e.target.checked;
    // setState((prevState) => ({
    //   ...prevState,
    //   ...tempState,
    // }));
    handleSetUnsetDesigner(e.target.checked, ID);
  };

  const fileUpload = async (e) => {
    debugger;

    let tempState = state;
    var allFiles = [];
    var selectedFiles = e.target.files[0];
    if (e.target.files[0].name.match(/.(png)$/i)) {
      let img = new Image();
      img.src = window.URL.createObjectURL(e.target.files[0]);
      img.onload = () => {
        debugger;
        if (img.width <= 200 && img.height <= 80) {
          if (selectedFiles) {
            allFiles.push(selectedFiles);

            tempState["Image"] = URL.createObjectURL(selectedFiles);
            tempState["fileN"] = allFiles;
            tempState["fileName"] = allFiles[0].name;
            tempState.Error["file"] = null;
          }
        } else {
          tempState.Error["file"] = "Please Select Correct Image Size.";
          tempState["fileN"] = null;
          tempState["fileName"] = null;
        }
        setState((prevState) => ({
          ...prevState,
          ...tempState,
        }));
      };
    } else {
      tempState.Error["file"] = "Please select .png file only.";
      tempState["fileN"] = null;
      tempState["fileName"] = null;
    }

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };

  console.log(`"Switch value":${switchValue}`);

  return (
    <div className="form__fields loginpreference__section">
      <div className="row">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0">
              {traslate("LoginPreferences")}
            </h4>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4">
          <div className="input__block">
            <label>
              {traslate("SelectDesignerforNew")}
              {/* <Tooltip placement="right" title={retail}>
                                <img src={UnionIcon} alt="" className="tooltip__img" />
                            </Tooltip> */}
            </label>
            <Select
              className="border__grey"
              showSearch
              placeholder="All Dealers"
              optionFilterProp="children"
              value={state.designerID}
              onChange={(e) => onChange("designerID", e)}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">All Dealers</Option>
              {designerList.map((item, i) => {
                return (
                  <Option value={item.designerID}>{item.designername}</Option>
                );
              })}
            </Select>
            <p className="mt-4">
              <b> {traslate("Note")}: </b>{" "}
              {traslate("Thiswillfeatureaspecificvendorforthe")} "{" "}
              {traslate("NewtoJewelCloud")} "{traslate("sectionontheloginpage")}
              .
            </p>
          </div>
        </div>

        <div className="col-lg-8">
          <div className="input__block">
            <label> {traslate("UploadLogoImageforDesigners")} </label>
            <div className="col-md-12 image_block">
              <div className="row">
                <div className="col-lg-5 col-md-12">
                  {state.Image ? (
                    <div className="divimage_block">
                      <span onClick={handleCancelImage} className="close_image">
                        <img src={close_red} />
                      </span>
                      <div className="img__div">
                        <img src={state.Image} className="img-fluid" />
                      </div>
                    </div>
                  ) : (
                    <div className="divimage_block">
                      <label
                        htmlFor="file-upload"
                        class="primary-btn choose_file_upload"
                      >
                        {traslate("ChooseImage")}
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        onChange={(e) => fileUpload(e)}
                      />
                      {state.Error ? (
                        <div className="text-danger">{state.Error["file"]}</div>
                      ) : null}
                      <span className="mb-4">
                        {traslate("NoFileChoosen")}{" "}
                        <span className="requires_txt">*</span>
                      </span>
                      <span className="">{state.fileName}</span>
                      <span className="">200 X 80 {traslate("Pixels")}</span>
                      <span className="">{traslate("PngFormat")}</span>
                    </div>
                  )}

                  <div className="preview__img choose_file_divimg d-none">
                    <img src={userIcon} alt="img" />
                  </div>
                </div>
                <div class="col-lg-7 col-md-12">
                  <label className="mt-2"> {traslate("SelectDesigner")} </label>
                  <Select
                    className="border__grey"
                    showSearch
                    placeholder="All Designer"
                    optionFilterProp="children"
                    value={state.designerID2}
                    onChange={(e) => onChange("designerID2", e)}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="">All Designer</Option>
                    {designerList.map((item, i) => {
                      return (
                        <Option value={item.designerID}>
                          {item.designername}
                        </Option>
                      );
                    })}
                  </Select>
                  {state.Error ? (
                    <div className="text-danger">
                      {state.Error["designerID2"]}
                    </div>
                  ) : null}
                  <Button
                    className="primary-btn mt-3"
                    onClick={handleUploadLogoForDesigner}
                    loading={submitLoading}
                  >
                    {traslate("UploadImage")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="input__block">
            <label> {traslate("SelectDesignerToShowonLoginPage")} </label>
          </div>
          <div className="designer__list">
            {designerList.map((item, i) => {
              return (
                <div className="designer__block">
                  <input
                    type="checkbox"
                    id={item.designerID}
                    checked={item.isSelected}
                    value={state.checkValue[item.designerID]}
                    onChange={(e) => handleCheckboxChange(e, item.designerID)}
                  />
                  <Tooltip
                    placement="right"
                    title={<img src={item.logoImage} width="200" height="80" />}
                  >
                    <label for={item.designerID}> {item.designername}</label>
                  </Tooltip>
                </div>
              );
            })}

            {/* <div className="designer__block">
                            <input type="checkbox" id="h1" />
                            <Tooltip placement="right" title={designerImg}>
                            <label htmlFor="h1"> 316 Jewelry and Watch Boutique (1825)</label>
                            </Tooltip>
                        </div>

                        <div className="designer__block">
                            <input type="checkbox" id="h2" />
                            <Tooltip placement="right" title={designerImg}>
                            <label htmlFor="h2"> A & D Gem Corp(279)</label>
                            </Tooltip>
                        </div>

                        <div className="designer__block">
                            <input type="checkbox" id="h3" />
                            <Tooltip placement="right" title={designerImg}>
                            <label htmlFor="h3"> A. Jaffe(1682)</label>
                            </Tooltip>
                        </div>

                        <div className="designer__block">
                            <input type="checkbox" id="h4" />
                            <Tooltip placement="right" title={designerImg}>
                            <label htmlFor="h4"> A. Link(221)</label>
                            </Tooltip>
                        </div>

                        <div className="designer__block">
                            <input type="checkbox" id="h5" />
                            <Tooltip placement="right" title={designerImg}>
                            <label htmlFor="h5"> Accutron(332)</label>
                            </Tooltip>
                        </div>

                        <div className="designer__block">
                            <input type="checkbox" id="h6" />
                            <Tooltip placement="right" title={designerImg}>
                            <label htmlFor="h6"> A. V. Diamonds(726)</label>
                            </Tooltip>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPreferences);
