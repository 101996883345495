import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import {
    Input,
    Row,
    Col,
    Select,
    Checkbox,
    Button,
    Table,
    Modal,
    DatePicker,
    Switch,
    Tooltip,
    Space
} from "antd";
import {
    EditOutlined,
    CalendarOutlined,
    SearchOutlined
} from "@ant-design/icons";
import { Accordion } from "react-bootstrap";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";

import Highlighter from "react-highlight-words";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";

import userIcon from "../../../assets/images/daimond-bg.png";

const { Option } = Select;

export const MasterLinkOptions = props => {
    const [isModalVisible, setIsModalVisible] = useState(false);





    return (
        <div className="form__fields markup__section">
            <div className="row ">
                <div className="col-md-12">
                    <div className="heading__block">
                        <h4 class="workarea__heading mt-0">Options</h4>
                    </div>
                </div>
            </div>



            <div className="row ">
                <div className="col-md-12">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div>
                                    <label>Setup</label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Internal Use Link
                                    <Tooltip placement="right" title={"Choose whether to display the “For Internal Use Only” link at the bottom of the Diamond Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed diamond."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Sticky Header Height
                                    <Tooltip placement="right" title={"Enter a Sticky Header Height."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text" />

                                        </div>
                                    </div>


                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <div>
                                    <label htmlFor="h1"> Display</label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Platinum Education
                                        <Tooltip placement="right" title={"Provides a link to copy for platinum education in Ring Builder."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Ring Size Guide URL
                                    <Tooltip placement="right" title={"Provides a link to copy for ring sizing guides provided by GemFind."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text" />

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Breadcrumbs
                                        <Tooltip placement="right" title={"Shows navigation path a customer took to get to page they're on."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                "Create Profile" text
                                    <Tooltip placement="right" title={"Provide alternate text for user registration Button."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text" />

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Sort Products by Price Initially By
                                        <Tooltip placement="right" title={"Allow Customers to Sort Product By Price."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Select
                                                className="border__grey"
                                                placeholder="Price: Low - High"
                                                name="productType"
                                                optionFilterProp="children"
                                            >
                                                <Option value="Option 1">Price: Low - High</Option>
                                                <Option value="Option 2">Price: High - Low</Option>
                                            </Select>
                                        </div>
                                    </div>



                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Collection Filtering
                                        <Tooltip placement="right" title={"When navigating Designer Landing pages - show collections instead of categories."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="On"
                                                unCheckedChildren="Off"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Schedule Viewing
                                        <Tooltip placement="right" title={"Allow Customers to schedule a meeting with you."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Designer Tile Layout
                                        <Tooltip placement="right" title={"Show Back to Top Button."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Top"
                                                unCheckedChildren="Bottom"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Enabled Back To Top
                                        <Tooltip placement="right">
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Addresses
                                        <Tooltip placement="right" title={"Allow address to show on detailpage"}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>
                                </div>


                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                <div>
                                    <label>Price</label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                "Call For Price" Text
                                <Tooltip placement="right" title={"(When prices have been turned off, by default, it will display “Call for Price”. This option allows you to replace “Call for Price” with alternate text (Ex. “Call for Availability)."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text" />

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                "Starting At Price" Text
                                <Tooltip placement="right" title={"Provide prefix text (it will include price) when a product uses Price Type 5 (Products with “Starting At Price” PriceType (5) cannot be added to the shopping cart)."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text" />

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show "Call For Price" instead of the Price
                                    <Tooltip placement="right" title={"Hides the retail price for products using Price Type 1,2,or 3 ."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Apply GST Tax
                                    <Tooltip placement="right" title={"Toggle the ability to apply international tax."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                GST Tax Value
                                            <Tooltip placement="right" title={"Set the value when applying the GST Tax option."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text" />

                                        </div>
                                    </div>

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>
                                <div>
                                    <label>Social</label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Facebook App ID
                                    <Tooltip placement="right" title={"Connect MasterLink to your Facebook page, using your Facebook App ID."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Facebook Like Page URL
                                    <Tooltip placement="right" title={"Allow Like access to your facebook account by providing the URL."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Pinterest Share
                                <Tooltip placement="right" title={"Allow Customers to post your products to their Pinterest boards."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Twitter Share
                                <Tooltip placement="right" title={"Allow Customers to share your products to their Twitter account."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Facebook Share
                                <Tooltip placement="right" title={"Allow Customers to share your products to their Facebook page."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Facebook Like
                                <Tooltip placement="right" title={"Allow Customers to like your products directly from your website."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                </div>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>
                                <div>
                                    <label>SEO</label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>

                                <div className="row">
                                    
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            Masterlink Default Title
                                    <Tooltip placement="right" title={"Enter a title for search engine optimization."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            Masterlink Meta Keywords
                                    <Tooltip placement="right" title={"Enter a Keywords for search engine optimization."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text" />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                            Masterlink Meta Description
                                    <Tooltip placement="right" title={"Enter a description for search engine optimization."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text" />
                                        </div>
                                    </div>
                                    

                                    

                                    

                                    

                                </div>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6">
                            <Accordion.Header>
                                <div>
                                    <label>E-Commerce</label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Alternate Shopping Cart
                                    <Tooltip placement="right" title={"Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text"/>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show "View Cart" Button
                                <Tooltip placement="right" title={"Enable the View Cart button for customers to review their products selections."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </div>
            </div>

        </div >
    );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MasterLinkOptions);
