import { http } from "../helper/http-common";
class LoginDataService {
  login(inputData) {
    return http.post("/Identity/LogIn", inputData);
  }
  AdminResetPassword(inputData) {
    return http.post("/Identity/AdminResetPassword", inputData);
  }
}

export default new LoginDataService();
