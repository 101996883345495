import React, { useState, useRef } from "react";
import {
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Switch,
  Table,
  Menu,
  Dropdown,
  Button,
  Space,
} from "antd";
import { Tabs, Tab } from "react-bootstrap";
import MultiRangeSlider from "../../contacts/MultiRangeSlider";

import roundDiamond from "../../../assets/images/Round.jpg";
import colorDiamond from "../../../assets/images/yellow-diamond.jpg";

import { Accordion } from "react-bootstrap";
import traslate from "../../../i18n/translate";

const { Option } = Select;

const InitialFilterSettings = () => {
  const resetRange = useRef(null);
  return (
    <React.Fragment>
      <div className="prodetail__section">
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv app__preview p-0 border-0">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="workarea__heading mt-0 mb-0">
                    {" "}
                    {traslate("InitialFilterSettings")}{" "}
                  </h4>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="prodesc__div">
                    <div className="tabs__content">
                      <div className="diamond__details">
                        <div className="row">
                          <div className="col-md-10">
                            <div className="row text__block">
                              <div className="col-md-6 mt-4">
                                <div className="input__block">
                                  <label> {traslate("Type")} </label>
                                  <Select
                                    className="border__grey"
                                    placeholder="Select Type"
                                    name="productType"
                                    optionFilterProp="children"
                                  >
                                    <Option value="Option 1">Option 1</Option>
                                    <Option value="Option 2">Option 2</Option>
                                  </Select>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <label className="mb-2">
                                  {traslate("Shape")}
                                </label>
                              </div>

                              <div className="diamond__imgs col-md-12">
                                <div className="img__block selected">
                                  <img src={roundDiamond} />
                                  <p> {traslate("Round")} </p>
                                </div>
                                <div className="img__block">
                                  <img src={roundDiamond} />
                                  <p> {traslate("Radiant")} </p>
                                </div>
                                <div className="img__block">
                                  <img src={roundDiamond} />
                                  <p> {traslate("Princess")} </p>
                                </div>
                                <div className="img__block">
                                  <img src={roundDiamond} />
                                  <p> {traslate("Pear")} </p>
                                </div>
                                <div className="img__block">
                                  <img src={roundDiamond} />
                                  <p> {traslate("Oval")} </p>
                                </div>
                                <div className="img__block">
                                  <img src={roundDiamond} />
                                  <p> {traslate("Marquise")} </p>
                                </div>
                                <div className="img__block">
                                  <img src={roundDiamond} />
                                  <p> {traslate("Heart")} </p>
                                </div>
                                <div className="img__block">
                                  <img src={roundDiamond} />
                                  <p> {traslate("Princess")} </p>
                                </div>
                                <div className="img__block">
                                  <img src={roundDiamond} />
                                  <p> {traslate("Pear")} </p>
                                </div>
                                <div className="img__block">
                                  <img src={roundDiamond} />
                                  <p> {traslate("Oval")} </p>
                                </div>
                                <div className="img__block">
                                  <img src={roundDiamond} />
                                  <p> {traslate("Princess")} </p>
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6 mb-5">
                                <div className="sliderrange__maindiv">
                                  <label> {traslate("Carat")} </label>
                                  <div className="col-md-12 mt-3">
                                    <MultiRangeSlider
                                      min={0}
                                      max={37440}
                                      resetRange={resetRange}
                                      onChange={({ min, max }) =>
                                        console.log(
                                          `min = ${min}, max = ${max}`
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 mb-5">
                                <div className="sliderrange__maindiv">
                                  <label> {traslate("Price")} </label>
                                  <div className="col-md-12 mt-3">
                                    <MultiRangeSlider
                                      min={0}
                                      max={37440}
                                      resetRange={resetRange}
                                      onChange={({ min, max }) =>
                                        console.log(
                                          `min = ${min}, max = ${max}`
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12 mt-4">
                                <label> {traslate("Cut")} </label>
                                <div className="selection__block row">
                                  <div className="selection__box selected col">
                                    <p> {traslate("Ideal")} </p>
                                  </div>
                                  <div className="selection__box selected col">
                                    <p> {traslate("Excellent")} </p>
                                  </div>
                                  <div className="selection__box col">
                                    <p> {traslate("VeryGood")} </p>
                                  </div>
                                  <div className="selection__box col">
                                    <p> {traslate("Good")} </p>
                                  </div>
                                  <div className="selection__box col">
                                    <p> {traslate("Fair")} </p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12 mt-4">
                                <label> {traslate("Color")} </label>
                                <div className="selection__block row">
                                  <div className="selection__box selected col">
                                    <p> {traslate("D")} </p>
                                  </div>
                                  <div className="selection__box selected col">
                                    <p> {traslate("E")} </p>
                                  </div>
                                  <div className="selection__box col">
                                    <p> {traslate("F")} </p>
                                  </div>
                                  <div className="selection__box col">
                                    <p> {traslate("G")} </p>
                                  </div>
                                  <div className="selection__box col">
                                    <p> {traslate("I")} </p>
                                  </div>
                                  <div className="selection__box col">
                                    <p> {traslate("J")} </p>
                                  </div>
                                  <div className="selection__box col">
                                    <p> {traslate("K")} </p>
                                  </div>
                                  <div className="selection__box col">
                                    <p> {traslate("L")} </p>
                                  </div>
                                  <div className="selection__box col">
                                    <p> {traslate("M")} </p>
                                  </div>
                                  <div className="selection__box col">
                                    <p> {traslate("N")} </p>
                                  </div>
                                  <div className="selection__box col">
                                    <p> {traslate("O")} </p>
                                  </div>
                                  <div className="selection__box col">
                                    <p> {traslate("P")} </p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12 mt-4">
                                <label> {traslate("Clarity")} </label>
                                <div className="selection__block row">
                                  <div className="selection__box selected col">
                                    <p>FL</p>
                                  </div>
                                  <div className="selection__box selected col">
                                    <p>IF</p>
                                  </div>
                                  <div className="selection__box col">
                                    <p>VVS1</p>
                                  </div>
                                  <div className="selection__box col">
                                    <p>VVS2</p>
                                  </div>
                                  <div className="selection__box col">
                                    <p>VS1</p>
                                  </div>
                                  <div className="selection__box col">
                                    <p>VVS0</p>
                                  </div>
                                  <div className="selection__box col">
                                    <p>SI1</p>
                                  </div>
                                  <div className="selection__box col">
                                    <p>SI2</p>
                                  </div>
                                  <div className="selection__box col">
                                    <p>SI3</p>
                                  </div>
                                  <div className="selection__box col">
                                    <p>SI4</p>
                                  </div>
                                  <div className="selection__box col">
                                    <p>SI12</p>
                                  </div>
                                  <div className="selection__box col">
                                    <p>SI15</p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 mt-4">
                                <label> {traslate("Polish")} </label>
                                <div className="selection__block row">
                                  <div className="selection__box selected col">
                                    <p> {traslate("Excellent")} </p>
                                  </div>
                                  <div className="selection__box selected col">
                                    <p> {traslate("VeryGood")} </p>
                                  </div>
                                  <div className="selection__box col">
                                    <p> {traslate("Good")} </p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 mt-4">
                                <label> {traslate("Symmetry")} </label>
                                <div className="selection__block row">
                                  <div className="selection__box selected col">
                                    <p> {traslate("Excellent")} </p>
                                  </div>
                                  <div className="selection__box selected col">
                                    <p> {traslate("VeryGood")} </p>
                                  </div>
                                  <div className="selection__box col">
                                    <p> {traslate("Good")} </p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6 mt-4">
                                <label> {traslate("Fluorescence")} </label>
                                <div className="selection__block row">
                                  <div className="selection__box selected col">
                                    <p> {traslate("N")} </p>
                                  </div>
                                  <div className="selection__box selected col">
                                    <p> {traslate("FNT")} </p>
                                  </div>
                                  <div className="selection__box col">
                                    <p> {traslate("MED")} </p>
                                  </div>
                                  <div className="selection__box col">
                                    <p> {traslate("ST")} </p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-3 mt-4">
                                <div className="input__block">
                                  <label> {traslate("Depth")} </label>
                                  <input type="number" />
                                </div>
                              </div>

                              <div className="col-md-3 mt-4">
                                <div className="input__block">
                                  <label> {traslate("Table")} </label>
                                  <input type="number" />
                                </div>
                              </div>

                              <div className="col-md-12 mt-4">
                                <div className="input__block row">
                                  <label> {traslate("Certificate")} </label>
                                  <div className="radio__block col-2">
                                    <input type="checkbox" />
                                    <label className="mb-0">
                                      {" "}
                                      {traslate("ALL")}{" "}
                                    </label>
                                  </div>
                                  <div className="radio__block col-2">
                                    <input type="checkbox" />
                                    <label className="mb-0">
                                      {" "}
                                      {traslate("GIA")}{" "}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InitialFilterSettings;
