import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import {
    Input,
    Row,
    Col,
    Select,
    Checkbox,
    Button,
    Table,
    Modal,
    DatePicker,
    Switch,
    Radio,
    Tooltip,
    Space
} from "antd";
import {
    EditOutlined,
    CalendarOutlined,
    SearchOutlined
} from "@ant-design/icons";
import { Accordion } from "react-bootstrap";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";

import Highlighter from "react-highlight-words";
import Create_icon from "../../../assets/images/create_icon.png";
import Edit_icon from "../../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../../assets/images/icons/delete_iconbl.svg";

import userIcon from "../../../assets/images/daimond-bg.png";

const { Option } = Select;
const initialOptionsData = {
    diamondLinkURL: "",
    internalUseLink: true,
    acceptNewDiamondDealers: true,
    sortSearchResultsInitiallyBy: null,
    orderSearchResultsInitiallyBy: null,
    showInHouseDiamondsSKU: true,
    showInHouseDiamondsFirst: true,
    showGIAReportCheckLink: true,
    showAdvanceOptionsAsDefault: true,
    showCertificateInDiamondSearch: true,
    showEGLCertificateVariants: true,
    showRequestCertificate: true,
    disableCertificateLinks: true,
    showColorItemsOutOfRange: true,
    showClarityItemsOutOfRange: true,
    acceptTotalPriceForDiamond: true,
    scheduleViewing: true,
    dropAHint: true,
    emailAFriend: true,
    showOriginOnDiamondSearch: true,
    showVendorCommentsOnDetailsPage: true,
    showContactNumber: true,
    showAddresses: true,
    alternateDiamondDetailsLinkURL: "",
    showDiamondPrices: true,
    markupYourOwnInventory: true,
    showPricePerCarat: true,
    applyGstTax: true,
    gstTaxValue: "",
    showPinterestShare: true,
    showTwitterShare: true,
    showFacebookShare: true,
    showFacebookLike: true,
    ctaButton: true,
    alternateShoppingCartURL: "",
    myOptionRadio: "",
    // addToCartButton: null,
    // requestMoreInfo : null,
    // scheduleAViewing: null,
    showViewCartButton: true,
    showAddToCartButton: true,
}

export const Options = props => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [myOptionsData, setMyOptionsData] = useState(initialOptionsData);
    const handleOnChange = (e, isSelect, name) => {
        if (isSelect === "select") {
            setMyOptionsData({
                ...myOptionsData,
                [name]: e,
            });
        } else {
            setMyOptionsData({
                ...myOptionsData,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleOnChangeRadio = (event) => {
        // setViewMyColorData({ Radio: event.target.name });
        setMyOptionsData({
            ...myOptionsData,
            myOptionRadio: event.target.name,
        });
        // console.log(event.target.name);
    };
    return (
        <div className="form__fields markup__section">
            <div className="row ">
                <div className="col-md-12">
                    <div className="heading__block">
                        <h4 class="workarea__heading mt-0">Options</h4>
                    </div>
                </div>
            </div>



            <div className="row ">
                <div className="col-md-12">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div>
                                    <label>Setup</label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Diamond Link URL
                                                <Tooltip placement="right" title={"Enter the URL of this tool on your site to enable social sharing and dynamic sizing."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text"
                                                name="diamondLinkURL"
                                                value={myOptionsData.diamondLinkURL}
                                                onChange={(e) => handleOnChange(e, "", "diamondLinkURL")}
                                            />

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Internal Use Link
                                                <Tooltip placement="right" title={"Choose whether to display the “For Internal Use Only” link at the bottom of the Diamond Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed diamond."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="internalUseLink"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.internalUseLink}
                                                onChange={(e) => handleOnChange(e, "select", "internalUseLink")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Automatically Accept New Diamond Dealers
                                                <Tooltip placement="right" title={"When a new Diamond Dealer joins GemFind, they will automatically be added to your connected Diamond Dealer List. Toggle this option to 'No', if you would prefer not to have Diamond Dealers automatically added to your account."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="acceptNewDiamondDealers"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.acceptNewDiamondDealers}
                                                onChange={(e) => handleOnChange(e, "select", "acceptNewDiamondDealers")}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <div>
                                    <label htmlFor="h1"> Display</label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Sort Search Results Initially By
                                                <Tooltip placement="right" title={"Change this value to modify the initial sorting field of the search results. Leave blank for default value (none)."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Select
                                                className="border__grey"
                                                optionFilterProp="children"
                                                value={myOptionsData.sortSearchResultsInitiallyBy ? myOptionsData.sortSearchResultsInitiallyBy : ""}
                                                name="sortSearchResultsInitiallyBy"
                                                placeholder="Select"
                                                onChange={(e) => handleOnChange(e, "select", "sortSearchResultsInitiallyBy")}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="cut-shape">Cut/Shape</Option>
                                                <Option value="Color">Color</Option>
                                            </Select>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Order Search Results Initially By
                                                <Tooltip placement="right" title={"Change this value to modify the initial sorting order of the initial sorting field. Leave blank for default value (none)."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Select
                                                className="border__grey"
                                                value={myOptionsData.orderSearchResultsInitiallyBy ? myOptionsData.orderSearchResultsInitiallyBy : ""}
                                                name="orderSearchResultsInitiallyBy"
                                                placeholder="Select"
                                                onChange={(e) => handleOnChange(e, "select", "orderSearchResultsInitiallyBy")}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                optionFilterProp="children"
                                            >
                                                <Option value="ascending">Ascending</Option>
                                                <Option value="descending">Descending</Option>
                                            </Select>
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show In House Diamonds Column with SKU (for New GF Link
                                                <Tooltip placement="right" title={"Choose whether to display in house diamonds columns in search result. It will also display In House SKU in result pop up and details page."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="showInHouseDiamondsSKU"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showInHouseDiamondsSKU}
                                                onChange={(e) => handleOnChange(e, "select", "showInHouseDiamondsSKU")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show In House Diamonds First
                                                <Tooltip placement="right" title={"Choose whether to display in house diamonds first in the search result."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="showInHouseDiamondsFirst"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showInHouseDiamondsFirst}
                                                onChange={(e) => handleOnChange(e, "select", "showInHouseDiamondsFirst")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show GIA report check link
                                                <Tooltip placement="right" title={"When enabled this will display a link to the GIA diamond cert. on GIA’s website, when disabled it will display a link to the GIA certificate image."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="showGIAReportCheckLink"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showGIAReportCheckLink}
                                                onChange={(e) => handleOnChange(e, "select", "showGIAReportCheckLink")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Advance options as Default in Diamond Search
                                                <Tooltip placement="right" title={"Choose whether to display the Advanced optional filtering by default (Polish, Symmetry, Flourescence, Certs… etc."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="showAdvanceOptionsAsDefault"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showAdvanceOptionsAsDefault}
                                                onChange={(e) => handleOnChange(e, "select", "showAdvanceOptionsAsDefault")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Certificate in Diamond Search
                                                <Tooltip placement="right" title={"Choose whether to display a link to the diamond certificate or not."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="showCertificateInDiamondSearch"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showCertificateInDiamondSearch}
                                                onChange={(e) => handleOnChange(e, "select", "showCertificateInDiamondSearch")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show EGL Certificate Variants
                                                <Tooltip placement="right" title={"Choose whether to display EGL Certificate Variants or not."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="showEGLCertificateVariants"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showEGLCertificateVariants}
                                                onChange={(e) => handleOnChange(e, "select", "showEGLCertificateVariants")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Request Certificate
                                                <Tooltip placement="right" title={"When “Show GIA report check link” is set to NO, this option allows you to choose whether to display the “Request Certificate” link or not. This link will display the GIA cert. image (if available) as opposed to the cert. on GIA’s website, and if “Disable Certificate Links in Diamond Search” is set to YES, this link will pop-up a “Request More Information” form."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="showRequestCertificate"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showRequestCertificate}
                                                onChange={(e) => handleOnChange(e, "select", "showRequestCertificate")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Disable Certificate Links in Diamond Search
                                                <Tooltip placement="right" title={"Hide certificates and show only request form."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="disableCertificateLinks"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.disableCertificateLinks}
                                                onChange={(e) => handleOnChange(e, "select", "disableCertificateLinks")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Color Items out of Range
                                                <Tooltip placement="right" title={"Choose whether the last displayed Diamond Color filter button will also display any remaining color of that type or higher, all the way to ‘Z’."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="showColorItemsOutOfRange"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showColorItemsOutOfRange}
                                                onChange={(e) => handleOnChange(e, "select", "showColorItemsOutOfRange")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Clarity Items out of Range
                                                <Tooltip placement="right" title={"Choose whether the last displayed Diamond Clarity filter button will also display any remaining clarities of that type or higher, all the way to “I3”."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showClarityItemsOutOfRange}
                                                onChange={(e) => handleOnChange(e, "select", "showClarityItemsOutOfRange")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Accept Total Price for Diamond
                                                <Tooltip placement="right" title={"When uploading diamonds into JewelCloud with a data sheet, the default process to calculate Cost Price is by multiplying CostPerCarat by the Carat value. When this option is set to YES, the prices on the uploaded diamond data sheet will be accepted as total cost price."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="acceptTotalPriceForDiamond"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.acceptTotalPriceForDiamond}
                                                onChange={(e) => handleOnChange(e, "select", "acceptTotalPriceForDiamond")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Schedule Viewing
                                                <Tooltip placement="right" title={"Choose whether to display the link which allows the customer the ability to schedule an appointment."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="scheduleViewing"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.scheduleViewing}
                                                onChange={(e) => handleOnChange(e, "select", "scheduleViewing")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Drop a Hint
                                                <Tooltip placement="right" title={"Choose whether to display the link which allows the customer the ability to Drop a Hint."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="dropAHint"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.dropAHint}
                                                onChange={(e) => handleOnChange(e, "select", "dropAHint")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Email A Friend
                                                <Tooltip placement="right" title={"Choose whether to display the link which allows the customer the ability to Email A Friend."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="emailAFriend"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.emailAFriend}
                                                onChange={(e) => handleOnChange(e, "select", "emailAFriend")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Origin On Diamond Search
                                                <Tooltip placement="right" title={"Choose whether to display the “Origin” drop-down menu within the Advanced Search filters. (Ex. All, Canadian, Lab-Created)."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="showOriginOnDiamondSearch"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showOriginOnDiamondSearch}
                                                onChange={(e) => handleOnChange(e, "select", "showOriginOnDiamondSearch")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show vendor comments on details page
                                                <Tooltip placement="right" title={"Choose whether to use show comments made by diamond vendors on the details page for each stone in the diamond search results."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="showVendorCommentsOnDetailsPage"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showVendorCommentsOnDetailsPage}
                                                onChange={(e) => handleOnChange(e, "select", "showVendorCommentsOnDetailsPage")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Contact Number
                                                <Tooltip placement="right" title={"Choose whether to use show contact Number or not."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="showContactNumber"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showContactNumber}
                                                onChange={(e) => handleOnChange(e, "select", "showContactNumber")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Addresses
                                                <Tooltip placement="right" title={"Choose whether to use show addresses or not."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="showAddresses"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showAddresses}
                                                onChange={(e) => handleOnChange(e, "select", "showAddresses")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                <div>
                                    <label>Custom</label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Alternate diamond details link URL
                                                <Tooltip placement="right" title={"Change this value to alter the actual hyperlink to the diamond details. This could be changed to link to your own diamond details page or your own shopping cart. Additional diamond detail parameters are passed in the URL when this value is present. Leave blank for default value."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text"
                                                name="alternateDiamondDetailsLinkURL"
                                                value={myOptionsData.alternateDiamondDetailsLinkURL}
                                                onChange={(e) => handleOnChange(e, "", "alternateDiamondDetailsLinkURL")}
                                            />

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Use Custom Detail URL for Diamonds?
                                                <Tooltip placement="right" title={"Choose whether to enable or disable the “Alternate diamond details link URL” option."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                <div>
                                    <label>Price</label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Diamond Prices
                                                <Tooltip placement="right" title={"You may choose not to show diamond prices in search results if desired. Price range field in search form is not displayed when prices are not displayed."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="showDiamondPrices"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showDiamondPrices}
                                                onChange={(e) => handleOnChange(e, "select", "showDiamondPrices")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Markup Your Own Inventory
                                                <Tooltip placement="right" title={"Choose whether to apply markup on diamond inventory uploaded by you."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="markupYourOwnInventory"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.markupYourOwnInventory}
                                                onChange={(e) => handleOnChange(e, "select", "markupYourOwnInventory")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Price Per Carat
                                                <Tooltip placement="right" title={"Choose whether to display the price per carat value on the diamond details page “Specifications” tab."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="showPricePerCarat"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showPricePerCarat}
                                                onChange={(e) => handleOnChange(e, "select", "showPricePerCarat")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Apply GST Tax
                                                <Tooltip placement="right" title={"Toggle the ability to apply international tax."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="applyGstTax"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.applyGstTax}
                                                onChange={(e) => handleOnChange(e, "select", "applyGstTax")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                GST Tax Value
                                                <Tooltip placement="right" title={"Set the value when applying the GST Tax option."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text" value={myOptionsData.gstTaxValue}
                                                onChange={(e) => handleOnChange(e, "", "gstTaxValue")}
                                                name="gstTaxValue"

                                            />

                                        </div>
                                    </div>

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>
                                <div>
                                    <label>Social</label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>

                                <div className="row">



                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Pinterest Share
                                                <Tooltip placement="right" title={"Choose whether to display the Pinterest “Pin it” button or not."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="showPinterestShare"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showPinterestShare}
                                                onChange={(e) => handleOnChange(e, "select", "showPinterestShare")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Twitter Share
                                                <Tooltip placement="right" title={"Choose whether to display the Twitter “Tweet” button or not."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="showTwitterShare"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showTwitterShare}
                                                onChange={(e) => handleOnChange(e, "select", "showTwitterShare")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Facebook Share
                                                <Tooltip placement="right" title={"Choose whether to display the Facebook “Share” button or not."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="showFacebookShare"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showFacebookShare}
                                                onChange={(e) => handleOnChange(e, "select", "showFacebookShare")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Facebook Like
                                                <Tooltip placement="right" title={"Choose whether to display the Facebook “Like” button or not."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="showFacebookLike"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showFacebookLike}
                                                onChange={(e) => handleOnChange(e, "select", "showFacebookLike")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show Instagram Share
                                                <Tooltip placement="right" title={"Choose whether to display Instagram Share or not."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="visibility"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                            />
                                        </div>
                                    </div>

                                </div>

                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>
                                <div>
                                    <label>E-Commerce</label>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Call to Action Button
                                                <Tooltip placement="right" title={`Choose whether to display the “Add to Cart” button or "Request More Info" or "Schedule a Viewing".`}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="ctaButton"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.ctaButton}
                                                onChange={(e) => handleOnChange(e, "select", "ctaButton")}
                                            />
                                            <div className="row mt-4">
                                                <div className="col-md-4">
                                                    <div className="radio__block">
                                                        <input
                                                            type="radio"
                                                            id="AddToCartBtn"
                                                            name="addToCartBtn"
                                                            value={myOptionsData.myOptionRadio}
                                                            checked={
                                                                myOptionsData.myOptionRadio === "addToCartBtn"
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={handleOnChangeRadio}
                                                        />
                                                        <label>AddToCart Button</label>
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="radio__block">
                                                        <input type="radio"
                                                            id="RequestMoreInfo"
                                                            name="requestMoreInfo"
                                                            value={myOptionsData.myOptionRadio}
                                                            checked={
                                                                myOptionsData.myOptionRadio === "requestMoreInfo"
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={handleOnChangeRadio}
                                                        />
                                                        <label>Request More Info</label>
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="radio__block">
                                                        <input type="radio"
                                                            id="ScheduleAViewing"
                                                            name="scheduleAViewing"
                                                            value={myOptionsData.myOptionRadio}
                                                            checked={
                                                                myOptionsData.myOptionRadio === "scheduleAViewing"
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={handleOnChangeRadio}
                                                        />
                                                        <label>Schedule a Viewing</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Alternate Shopping Cart URL
                                                <Tooltip placement="right" title={"Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page. Additional diamond detail parameters are passed in the URL when this value is present. Leave blank for default value."}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <input type="text"
                                                name="alternateShoppingCartURL"
                                                value={myOptionsData.alternateShoppingCartURL}
                                                onChange={(e) => handleOnChange(e, "", "alternateShoppingCartURL")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show "View Cart" Button
                                                <Tooltip placement="right" title={`Choose whether to display the PayPal Shopping Cart's "View Cart" Button or not.`}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="showViewCartButton"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showViewCartButton}
                                                onChange={(e) => handleOnChange(e, "select", "showViewCartButton")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input__block">
                                            <label>
                                                Show AddToCart Button On Search Page
                                                <Tooltip placement="right" title={`Choose whether to display the “Add to Cart” button on Search Page or not.`}>
                                                    <img src={UnionIcon} alt="" />
                                                </Tooltip>
                                            </label>
                                            <Switch
                                                name="showAddToCartButton"
                                                checkedChildren="Yes"
                                                unCheckedChildren="No"
                                                defaultChecked
                                                checked={myOptionsData.showAddToCartButton}
                                                onChange={(e) => handleOnChange(e, "select", "showAddToCartButton")}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mt-4">
                    <Button className="primary-btn" onClick={(() => console.log(myOptionsData))}>
                        Update Settings
                    </Button>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Options);
